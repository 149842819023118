import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as de from './locales/de-DE.json';
import * as en from './locales/en-EN.json';
import * as es from './locales/es-ES.json';
import * as fr from './locales/fr-FR.json';
import * as it from './locales/it-IT.json';
import * as nl from './locales/nl-NL.json';
import * as ru from './locales/ru-RU.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    preload: ['de', 'en', 'es', 'fr', 'it', 'nl', 'ru'],
    debug: process.env.REACT_APP_IS_PRODUCTION === 'false',
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
    fallbackLng: 'en',
    resources: {
      de: de,
      en: en,
      es: es,
      fr: fr,
      it: it,
      nl: nl,
      ru: ru,
    },
  });

export default i18n;
