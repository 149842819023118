import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';

const InvoicesTable = ({ invoices, handleOnDownload }: any) => {
  const { t, i18n } = useTranslation();
  const { currentAccount } = useAuth();
  const onDownload = (row: any) => {
    const currentRowData = row.original;
    handleOnDownload(currentRowData.monthYear);
  };

  const columns = [
    {
      accessorKey: 'monthYear',
      header: t('settings.invoices.date'),
      cell: (props: any) => <p>{getItemInvoiceDate(props.getValue())}</p>,
    },
    {
      accessorKey: 'description',
      header: t('settings.invoices.description'),
      cell: () => (
        <p className="font-bold">{t('settings.invoices.cozyup_fees')}</p>
      ),
    },
    {
      accessorKey: 'numberOfOrders',
      header: '#' + t('settings.invoices.orders'),
      cell: (props: any) => <p className="font-bold">{props.getValue()}</p>,
    },

    {
      accessorKey: 'totalAmountFeeHost',
      header: t('settings.invoice_amount'),
      cell: (props: any) => (
        <>
          {props.row.original.currencies === 'multiple' ? (
            <span>-</span>
          ) : (
            <p>
              {displayPrice(
                props.getValue(),
                getCurrencyInfo(props.row.original.currencies).symbol,
                {
                  thousandSeparator: ' ',
                },
              )}
            </p>
          )}
        </>
      ),
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: (props: any) => (
        <div className="flex justify-end">
          {checkInvoiceAvailable(props.row.original.monthYear) ? (
            <>
              {props.row.original.currencies === 'multiple' ? (
                <a
                  href={`mailto:admin@cozyup.fr?subject=[Invoice] CozyUp - Invoice request - id account : ${currentAccount._id} - ${props.row.original.monthYear}`}
                  className="flex tems-center btn py-1 border rounded-2xl border-primary"
                >
                  <span className="text-primary">Demander au support</span>
                </a>
              ) : (
                <button
                  className="flex tems-center btn py-1 rounded-2xl btn-primary"
                  onClick={() => onDownload(props.row)}
                >
                  {t('download')}
                </button>
              )}
            </>
          ) : (
            <div className="bg-lightGrey py-2 px-3 rounded-2xl">
              <span className="text-grey">
                {t('settings.invoices.upcoming')}
              </span>
            </div>
          )}
        </div>
      ),
    },
  ];

  const checkInvoiceAvailable = (monthYear: string): boolean => {
    // Convertir monthYear en Date
    const [invoiceYear, invoiceMonth] = monthYear.split('-').map(Number);

    // Obtenir la date actuelle
    const currentDate = new Date();

    // Calculer le 8ème jour du mois suivant la facture
    const eighthOfNextMonth = new Date(invoiceYear, invoiceMonth, 8);

    // Vérifier si la date actuelle est après le 8ème jour du mois suivant la facture
    const isAfterEighthOfNextMonth = currentDate >= eighthOfNextMonth;

    // La facture est disponible si la date actuelle est après le 8ème jour du mois suivant la facture
    return isAfterEighthOfNextMonth;
  };

  const getItemInvoiceDate = (dateString: any) => {
    const [year, month] = dateString.split('-');

    // Créer une nouvelle date (les mois sont indexés à partir de 0 en JavaScript, donc soustrayez 1)
    const date = new Date(year, month - 1);
    // Formatter la date
    return new Intl.DateTimeFormat(i18n.language, {
      month: 'short',
      year: 'numeric',
    }).format(date);
  };
  const table = useReactTable({
    data: invoices ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table className="cozyup table w-full">
      <thead>
        {table.getHeaderGroups().map((headerGroup: any) => (
          <tr className="tr  text-left" key={headerGroup.id}>
            {headerGroup.headers.map((header: any) => (
              <th className="th pl-3" key={header.id}>
                {header.column.columnDef.header}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row: any) => (
          <tr className="bg-lightBlue text-left" key={row.id}>
            {row.getVisibleCells().map((cell: any) => (
              <td className="td" key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoicesTable;
