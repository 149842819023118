import useTrackMixpanelEvent from './useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../contexts/authContext';

const useTrackOnboardingWithMixpanel = () => {
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const { currentUser, currentWorkspace, currentAccount } = useAuth();

  const trackOnboardingEvent = (eventName: string) => {
    const mixpanelProperties = {
      account_id: currentAccount?._id,
      account_name: currentAccount?.name,
      user_id: currentUser?._id,
      workspace_id: currentWorkspace?._id,
    };

    trackMixpanelEvent(eventName, mixpanelProperties);
  };

  return {
    trackOnboardingEvent,
  };
};

export default useTrackOnboardingWithMixpanel;
