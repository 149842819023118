import React from 'react';

import { Icon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

interface Props {
  searchText: string;
  setSearchText?: any;
  onKeyDown?: any;
}

const SearchBar = ({ searchText, setSearchText, ...props }: Props) => {
  const { t } = useTranslation();
  const handleClearSearch = () => {
    setSearchText('');
    if (props.onKeyDown) {
      props.onKeyDown();
    }
  };
  return (
    <div className="flex-1">
      <div className="border border-gray-400 rounded-2xl py-3 px-1 flex">
        <Icon
          className="search-bar-icon icon-transparent-hover text-gray-400 mr-2"
          fontSize="small"
          component={SearchIcon}
        />
        <input
          className="focus:outline-none w-full outline-none"
          placeholder={`${t('search')}...`}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          {...props}
        />
        {!searchText ? null : (
          <Icon
            className="text-grey"
            fontSize="small"
            component={CancelIcon}
            onClick={() => handleClearSearch()}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
