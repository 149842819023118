import React, { useEffect, useState } from 'react';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabContent from '../../components/tab-content/tab-content.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import { useMutation, useQuery } from 'react-query';
import { getStripeConnectAccount } from '../../api/stripe';
import { findUserByEmail } from '../../api/users';
import { useAuth } from '../../contexts/authContext';
import { capitalizeFirstLetter } from '../../utils/helpers';
import Input from '../../components/input/input.component';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getHomesByWorkspace } from '../../api/homes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { Home } from '../../types/home.model';
import { formatDateToLocaleDateString } from '../../utils/date';
import { Icon, Tooltip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OrderRaw from '../../components/_main/orders/order-raw/order-raw';
import { getOrders } from '../../api/orders';
import { getCheckoutAreasByWorkspace } from '../../api/checkoutAreas';
import { CheckoutArea } from '../../types/checkout-area.model';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProductsByWorkspace } from '../../api/products';
import { Product } from '../../types/product.model';
import { Emoji } from 'emoji-mart';
import { getOrdersStatsByWorkspace } from '../../api/stats';
import { useTranslation } from 'react-i18next';
import Filters from '../../components/filters/filters.component';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import GraphComponent from '../../components/graph/graph.component';
import Pagination from '../../components/pagination/pagination.component';
import SearchBar from '../../components/search-bar/search-bar.component';
import Loader from '../../components/loader/loader.component';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';

const UserStatistics = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [searchUser, setSearchUser] = useState<string>(params?.email || '');
  const { currentUser } = useAuth();

  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const {
    data: apiUser,
    mutate: fetchApiUser,
    isLoading: loadingApiUser,
  } = useMutation(
    () => {
      return findUserByEmail(currentUser.accessToken, searchUser);
    },
    {
      onSuccess: (data: any) => {
        const workspaceId = data.data.accounts[0].workspaces[0]._id;
        setSelectedWorkspace(workspaceId);
      },
    },
  );

  const currency = getCurrencyInfo('eur');

  useEffect(() => {
    if (searchUser) {
      fetchApiUser(searchUser);
    }
  }, []);

  const [activePage, setActivePage] = useState(1);
  const [searchedOrder, setSearchedOrder] = useState('');

  const {
    data: orders,
    isLoading: isFetchingOrders,
    mutate: fetchOrders,
  } = useMutation(
    ['ordersKey', activePage, currentUser, apiUser, searchedOrder],
    // @ts-ignore
    (workspaceId: string) => {
      return getOrders(
        currentUser.accessToken,
        workspaceId,
        activePage,
        searchedOrder,
        {
          direction: '',
          field: '',
        },
      );
    },
  );

  const [rangeDate, setRangeDate] = useState('7_day');

  const dateFilters = [
    { label: t('filters.last_days', { days: 7 }), value: '7_day' },
    { label: t('filters.last_weeks', { weeks: 4 }), value: '28_day' },
    { label: t('filters.last_months', { months: 12 }), value: '12_month' },
  ];

  const {
    data: orderStats,
    isLoading: isLoadingOrderStats,
    mutate: refetchOrderStats,
  } = useMutation(
    ['userStatsKey', currentUser, apiUser, rangeDate],
    // @ts-ignore
    (workspaceId: string) =>
      getOrdersStatsByWorkspace(
        currentUser.accessToken,
        workspaceId,
        rangeDate.split('_')[0],
        rangeDate.split('_')[1],
      ),
  );

  const { data: homesData, mutate: refetchHome } = useMutation(
    ['adminHomes', currentUser, apiUser],
    (workspaceId: string) =>
      getHomesByWorkspace(currentUser.accessToken, workspaceId, null),
  );

  const { data: stripeConnectAccount } = useQuery(
    ['adminStripeConnectKey', currentUser, apiUser],
    // @ts-ignore
    () =>
      getStripeConnectAccount(
        currentUser.accessToken,
        apiUser.data.stripeConnectId,
      ),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (selectedWorkspace) {
      refetchCheckoutArea(selectedWorkspace);
      refetchHome(selectedWorkspace);
      refetchOrderStats(selectedWorkspace);
      fetchOrders(selectedWorkspace);
      refetchProduct(selectedWorkspace);
    }
  }, [selectedWorkspace]);

  const { data: checkoutAreasData, mutate: refetchCheckoutArea } = useMutation(
    ['adminCheckoutAreas', currentUser, apiUser],
    (workspaceId: string) =>
      getCheckoutAreasByWorkspace(currentUser.accessToken, workspaceId),
  );

  const { data: productsData, mutate: refetchProduct } = useMutation(
    ['adminProducts', currentUser, apiUser],
    (workspaceId: string) =>
      getProductsByWorkspace(currentUser.accessToken, workspaceId),
  );

  const getCheckoutUrl = (homeId: string) =>
    document.location.origin + `/c/${homeId}`;

  const checkStripeAccount = (stripeAccount: any, t: any) => {
    if (stripeAccount) {
      if (stripeAccount.charges_enabled) {
        return (
          <>
            <div className="text-left rounded-3xl text-green bg-lightGreen px-4 py-1 w-fit font-semibold">
              <span>{t('settings.stripe.active')}</span>
            </div>
            <p className="text-left my-4 text-gray-800">
              {t('settings.stripe.account_currently_active')}
            </p>
          </>
        );
      }
      if (
        !stripeAccount.details_submitted ||
        stripeAccount.requirements?.disabled_reason == 'requirements.past_due'
      ) {
        return (
          <>
            <div className="text-left rounded-3xl text-red-900 bg-red-200 px-4 py-1 w-fit font-semibold">
              <span>{t('settings.stripe.inactive')}</span>
            </div>
            <p className="text-left my-4 text-gray-800">
              {t('settings.stripe.missing_info')}
            </p>
          </>
        );
      }
      if (
        stripeAccount.requirements.disabled_reason ==
        'requirements.pending_verification'
      ) {
        return (
          <>
            <div className="text-left rounded-3xl text-orange bg-lightOrange px-4 py-1 w-fit font-semibold">
              <span>{t('settings.stripe.pending')}</span>
            </div>
            <p className="text-left my-4 text-gray-800">
              {t('settings.stripe.pending_verification')}
            </p>
          </>
        );
      }
    } else {
      return (
        <Tooltip title={t('settings.stripe.unusable_dsc')}>
          <div className="flex items-center">
            <div className="text-center gap-2 h-full rounded-3xl cursor-pointer text-gray-50 bg-red-700 px-3 py-1 font-semibold flex items-center justify-center">
              {t('settings.stripe.unusable')}
            </div>
          </div>
        </Tooltip>
      );
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      fetchApiUser(searchUser);
    }
  };

  return (
    <div>
      <TabWrapper>
        <>
          <TabContentHeader
            title={'Admin'}
            rootPage="/admin/stats"
            subRoute={'Statistics'}
          />
          <TabContent>
            <>
              <Input
                type="email"
                key="email"
                onChange={(e: any) => setSearchUser(e.target.value)}
                onKeyDown={handleKeyDown}
                value={searchUser}
                onBlur={() => fetchApiUser()}
                label={`${capitalizeFirstLetter('User email')} *`}
              />
              {searchUser ? null : <h1>Search user</h1>}
              {loadingApiUser ? <p>LOADING</p> : null}
              {apiUser && isEmpty(apiUser?.data) && searchUser ? (
                <h1>No user found.</h1>
              ) : null}

              {/*STRIPE STATUS*/}
              {isEmpty(stripeConnectAccount) ||
              isEmpty(apiUser?.data) ? null : (
                <>
                  <br />
                  <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
                    <h5
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      Stripe
                    </h5>
                  </div>
                  <div className="line-container">
                    {!stripeConnectAccount ? null : (
                      <div className="stripe-connect-wrapper">
                        <div className="stripe-connect-status">
                          <div className="content">
                            {checkStripeAccount(stripeConnectAccount, t)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* WORKSPACES */}
              <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
                <h5
                  className="lg:text-[36px]"
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Workspaces
                </h5>
              </div>
              <div className="flex">
                {apiUser?.data.accounts[0].workspaces.map(
                  (w: any, index: number) => (
                    <div
                      className={`mx-4 my-10 py-4 px-6 cursor-pointer rounded-3xl text-xl font-satoshiBold  ${
                        selectedWorkspace === w._id
                          ? 'border border-primary text-primary'
                          : 'border border-grey'
                      }`}
                      key={index}
                      onClick={() => setSelectedWorkspace(w._id)}
                    >
                      {w.title}
                    </div>
                  ),
                )}
              </div>
              {/*ORDERS*/}
              <TabContentHeader title={'Orders Stats'} rootPage="/stats" />
              <div className="lg:px-4 lg:flex flex-col-inverse lg:flex-row items-end mt-4">
                <div className="py-4 2xl:w-1/2 flex items-end xl:flex-1">
                  <Filters
                    filtersList={dateFilters}
                    active={rangeDate}
                    setActive={setRangeDate}
                  />
                </div>
              </div>
              <div>
                {!orderStats?.orders?.length ? null : (
                  <div className="w-full h-96 relative mt-10">
                    {!isLoadingOrderStats ? null : (
                      <FontAwesomeIcon
                        style={{ marginRight: 15 }}
                        icon={faSpinnerThird}
                        size={'5x'}
                        spin
                      />
                    )}
                    <GraphComponent
                      orders={orderStats?.orders}
                      unitType={rangeDate.split('_')[1]}
                      currency={currency}
                    />
                  </div>
                )}
              </div>
              {isEmpty(orders) ? null : (
                <>
                  <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
                    <h5
                      className="lg:text-[36px]"
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      Orders
                    </h5>
                    <div className="w-1/3">
                      <SearchBar
                        searchText={searchedOrder || ''}
                        setSearchText={setSearchedOrder}
                      />
                    </div>
                  </div>

                  <div className="tab-container">
                    <br />

                    <div className="flex">
                      <Pagination
                        totalPagesCount={orders?.metadata?.totalPages}
                        currentPage={orders?.metadata?.currentPage}
                        totalItems={orders?.metadata?.totalCount}
                        onChangePage={setActivePage}
                      />
                    </div>
                    <table className="cozyup table">
                      <thead>
                        <tr className="order-table-row">
                          <th className="table-head">
                            ID <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Home <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Purchase <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Arrival date <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Customer <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Total <FontAwesomeIcon icon={faSort} />
                          </th>
                          <th className="table-head">
                            Payment <FontAwesomeIcon icon={faSort} />
                          </th>

                          {/*<th className="table-head">*/}
                          {/*  Fullfilement <FontAwesomeIcon icon={faSort} />*/}
                          {/*</th>*/}
                        </tr>
                      </thead>
                      <tbody>
                        {isEmpty(orders)
                          ? null
                          : orders?.items?.map((order: any, index: number) => (
                              <OrderRaw order={order} key={index} />
                            ))}
                      </tbody>
                    </table>
                  </div>

                  {!isFetchingOrders ? null : (
                    <div className="flex justify-center items-center height-[150px]">
                      <Loader />
                    </div>
                  )}
                </>
              )}

              {/*HOMES*/}
              <br />
              {isEmpty(homesData) ? null : (
                <>
                  <TabContentHeader title={'Homes'} rootPage="/stats" />
                  <table className="cozyup table-auto home-table">
                    <thead className="home-table-header-container">
                      <tr className="home-table-row">
                        <th className="home-table-th">
                          ID <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Name <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          City <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Checkout Area <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Creation Date <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">Checkout URL</th>
                        <th className="home-table-th" />
                      </tr>
                    </thead>
                    <tbody>
                      {homesData?.map((home: Home, i: number) => (
                        <tr
                          key={home._id}
                          className={'home-table-row row ' + home.status}
                          onClick={() =>
                            window.open(
                              `${document.location.origin}/homes/${home._id}`,
                              '_blank',
                            )
                          }
                        >
                          <td className="home-table-cell">{i + 1}</td>
                          <td className="home-table-cell">{home.name}</td>
                          <td className="home-table-cell">
                            {home.address?.city}
                          </td>
                          <td className="home-table-cell">
                            <span className="status grey">
                              {home?.checkoutArea?.name || 'N/A'}
                            </span>
                          </td>
                          <td className="home-table-cell">
                            {formatDateToLocaleDateString(home?.createdAt)}
                          </td>

                          <td className="home-table-cell">
                            {home.status === 'archived' ? (
                              'N/A'
                            ) : (
                              <Tooltip
                                title="Copy URL"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(
                                    getCheckoutUrl(home._id),
                                  );
                                }}
                              >
                                <Icon
                                  className="icon margin-left"
                                  fontSize="small"
                                  component={LinkIcon}
                                />
                              </Tooltip>
                            )}
                          </td>
                          <td className="home-table-cell center">
                            {home.status === 'archived' ? null : (
                              <Tooltip
                                title="Preview"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(`/c/${home._id}`);
                                }}
                              >
                                <Icon
                                  className="icon margin-left"
                                  fontSize="small"
                                  component={VisibilityIcon}
                                />
                              </Tooltip>
                            )}

                            {home.status === 'active' ? (
                              <Tooltip title="Archive">
                                <Icon
                                  className="icon margin-left orange"
                                  fontSize="small"
                                  component={ArchiveIcon}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Activate">
                                <Icon
                                  className="icon margin-left blue"
                                  fontSize="small"
                                  component={UnarchiveIcon}
                                />
                              </Tooltip>
                            )}

                            <Icon
                              className="icon margin-left"
                              fontSize="small"
                              component={MoreVertIcon}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}

              {/*CHECKOUT AREA*/}
              {isEmpty(checkoutAreasData) ? null : (
                <>
                  <br />
                  <TabContentHeader title={'CheckoutAreas'} rootPage="/stats" />
                  <table className="cozyup table-auto home-table">
                    <thead className="home-table-header-container">
                      <tr className="home-table-row">
                        <th className="home-table-th">
                          ID <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Collection Name <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Products <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Creation Date <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Last Update <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th" />
                      </tr>
                    </thead>
                    <tbody>
                      {checkoutAreasData?.map(
                        (checkoutArea: CheckoutArea, i: number) => (
                          <tr
                            key={checkoutArea._id}
                            className="home-table-row row"
                            onClick={() =>
                              window.open(
                                `${document.location.origin}/checkout-areas/${checkoutArea._id}`,
                                '_blank',
                              )
                            }
                          >
                            <td className="home-table-cell">{i + 1}</td>
                            <td className="home-table-cell">
                              {checkoutArea.name}
                            </td>
                            <td className="home-table-cell">
                              {checkoutArea.checkoutAreaProducts.length}
                            </td>
                            <td className="home-table-cell">
                              {formatDateToLocaleDateString(
                                checkoutArea.createdAt,
                              )}
                            </td>
                            <td className="home-table-cell">
                              {formatDateToLocaleDateString(
                                checkoutArea.lastUpdatedAt,
                              )}
                            </td>
                            <td className="home-table-cell">
                              <Tooltip title="Delete">
                                <Icon
                                  className="icon margin-left red"
                                  fontSize="small"
                                  component={DeleteIcon}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </>
              )}

              {/*PRODUCTS*/}
              {isEmpty(productsData) ? null : (
                <>
                  <br />
                  <TabContentHeader title={'Products'} rootPage="/stats" />
                  <table className="cozyup table-auto home-table">
                    <thead className="home-table-header-container">
                      <tr className="product-table-row">
                        <th className="home-table-th">
                          ID <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">Emoji</th>
                        <th className="home-table-th">
                          Name <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Cost price <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Selling price <FontAwesomeIcon icon={faSort} />
                        </th>
                        <th className="home-table-th">
                          Last update <FontAwesomeIcon icon={faSort} />
                        </th>

                        <th className="home-table-th" />
                      </tr>
                    </thead>
                    <tbody>
                      {productsData?.map((product: Product, i: number) => (
                        <tr
                          key={product._id}
                          className="product-table-row row"
                          onClick={() =>
                            window.open(
                              `${document.location.origin}/products/${product._id}`,
                              '_blank',
                            )
                          }
                        >
                          <td className="home-table-cell">{i + 1}</td>
                          <td className="home-table-cell">
                            <Emoji emoji={product.emoji} size={52} />
                          </td>
                          <td className="home-table-cell">{product.name}</td>
                          <td className="home-table-cell">
                            {displayPrice(product.costPrice, '€', {
                              thousandSeparator: ' ',
                            })}
                          </td>
                          <td className="home-table-cell">
                            {displayPrice(product.sellingPrice, '€', {
                              thousandSeparator: ' ',
                            })}
                          </td>
                          <td className="home-table-cell">
                            {formatDateToLocaleDateString(product.updated_at)}
                          </td>
                          {/*<td className="home-table-cell">14/07/2022</td>*/}
                          {/*<td className="home-table-cell">29/07/2022</td>*/}
                          <td className="home-table-cell center">
                            <Tooltip title="Delete">
                              <Icon
                                className="icon margin-left red"
                                fontSize="small"
                                component={DeleteIcon}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          </TabContent>
        </>
      </TabWrapper>
    </div>
  );
};

export default UserStatistics;
