import { api } from '../utils/request';

export const getGlobalstats = async (authToken: string) => {
  try {
    const res = await api.get(`/stats`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getUpcomingRevenues = async (authToken: string) => {
  try {
    const res = await api.get(`/stats/upcoming/revenues`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getOrdersStats = async (
  authToken: string,
  dateRange: string,
  unit: string,
) => {
  try {
    const res = await api.get(`/stats/orders`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        interval: dateRange,
        unit: unit,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getOrdersStatsByWorkspace = async (
  authToken: string,
  workspaceId: string,
  dateRange: string,
  unit: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders/stats`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        interval: dateRange,
        unit: unit,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getUsersStats = async (authToken: string, page: number) => {
  try {
    const res = await api.get(`/stats/users`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getReferralsStats = async (authToken: string, page: number) => {
  try {
    const res = await api.get(`/stats/referrals`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getAllKeyMetrics = async (authToken: string) => {
  try {
    const res = await api.get(`/stats/key-metrics`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getMRR = async (authToken: string) => {
  try {
    const res = await api.get(`/stats/mrr`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getFunnelConversion = async (
  authToken: string,
  workspaceId: string,
  interval: string,
  unit: string,
) => {
  try {
    const res = await api.get(
      `/stats/workspaces/${workspaceId}/funnel-conversion`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          unit,
          interval,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
