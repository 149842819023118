import React from 'react';
import { useTranslation } from 'react-i18next';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../loader/loader.component';
import { calculateDaysRemaining } from '../../utils/date';
import { useNavigate } from 'react-router-dom';
import OrderTypeEmoji from '../_main/order-type-emoji/order-type-emoji.constante';

type PendingOrdersProps = {
  ordersPending: any;
  isLoading: boolean;
  isFetching: boolean;
};

const PendingOrders = ({
  ordersPending,
  isLoading,
  isFetching,
}: PendingOrdersProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOnSelectOrder = (order: any) => {
    navigate('/orders?_orderId=' + order.order_id);
  };

  return (
    <div className="flex flex-col h-full">
      {ordersPending?.length || isLoading ? null : (
        <div className="flex-row my-5 lg:mt-10">
          <FontAwesomeIcon
            className="opacity-20 mb-4"
            color="#b6b9ce"
            icon={faCheckCircle}
            size="4x"
          />
          <div className="text-sm font-semibold text-[#6B7194] opacity-20">
            {t('dashboard.empty_pending_orders')}
          </div>
        </div>
      )}
      <div className="flex-grow relative">
        {(isLoading || isFetching) && (
          <div className="flex justify-center items-center height-[150px]">
            <Loader />
          </div>
        )}
        {!ordersPending?.length || isLoading || isFetching ? null : (
          <div className="h-full lg:overflow-auto">
            {ordersPending?.map((order: any, index: number) => {
              return (
                <div key={index} className="mt-2">
                  <button
                    className="flex flex-col justify-between rounded-2xl p-2 bg-lightblue w-full hover:shadow"
                    onClick={() => handleOnSelectOrder(order)}
                  >
                    <div className="relative flex justify-between gap-3 px-2 py-2 w-full">
                      <div className="flex w-full items-center justify-between gap-1">
                        <div className="flex text-md font-semibold text-dark w-2/3">
                          #{order.order_id}
                          <span className="ml-2 truncate">
                            {order.home.name}
                          </span>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <OrderTypeEmoji
                            isStayExtension={order?.isStayExtension}
                          />
                          <span> ⏳ </span>
                        </div>
                      </div>
                    </div>
                    <div className="my-3 rounded-xl border border-orange px-3 bg-lightOrange">
                      <span className="first-letter:uppercase py-1 text-orange font-semibold rounded-2xl">
                        {calculateDaysRemaining(order.createdAt, 7)}
                      </span>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingOrders;
