import React, { useCallback, useEffect, useState } from 'react';
import '../../../../styles/button.styles.scss';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import _ from 'lodash';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import SubmitButton from '../../../submit-button/submit-button.component';
import TextArea from '../../../text-area/text-area.component';
import Select from '../../../select/select.component';
import { useQuery } from 'react-query';
import { getCheckoutAreasByWorkspace } from '../../../../api/checkoutAreas';
import { useAuth } from '../../../../contexts/authContext';
import { CheckoutArea } from '../../../../types/checkout-area.model';
import { useTranslation } from 'react-i18next';
import useSocket from '../../../../hooks/useSocket';
import Button from '../../../button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FlagIcons } from '../../../flag-icons/flag-icons.component';
import { jobActions } from '../../../../redux/jobs/jobActions';
import { JobStatus } from '../../../../redux/jobs/jobSlice';
import { STORE_TRANSLATION_ACTION } from '../../../../constantes/jobs';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/useStore';

interface IHomeTranslation {
  title: string;
  description: string;
  languageCode: string;
  _id: string;
}

const HomeCheckoutArea = ({
  home,
  updateHome,
  refresh,
  customIdError,
}: any) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const jobs = useAppSelector((state) => state.jobs);
  const isGeneratingTranslations =
    jobs.jobs[`${STORE_TRANSLATION_ACTION}:${home._id}`] ===
    JobStatus.IN_PROGRESS;
  const [disabled, setDisabled] = useState(true);
  const [checkoutAreasOptions, setCheckoutAreasOptions] = useState([]);
  const { currentUser, currentWorkspace } = useAuth();
  const backendErrors = '';
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language.slice(0, 2),
  );
  const { sendEvent } = useSocket();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  useEffect(() => {
    if (!customIdError) return;
    setDisabled(false);
    setError('customId', { type: 'custom', message: t(customIdError) });
  }, [customIdError]);

  const submit = async (data: any) => {
    if (data.translations) {
      data.translations = Object.entries(data.translations).map(
        ([key, value]: [string, any]) => ({
          languageCode: key,
          title: value.name,
          description: value.description,
          _id: value._id,
        }),
      );
    }
    updateHome({
      headline: data.headline,
      description: data.description,
      checkoutArea: data.checkoutArea,
      customId: data.customId || null,
      translations: data.translations,
      hasToRegenerateTranslations: data.hasToRegenerateTranslations ?? false,
      currentLanguage: currentLanguage ?? null,
    });
    setDisabled(true);
  };

  const handleCancelEdit = () => {
    reset();
    setDisabled(true);
  };

  useQuery(
    ['checkoutAreas'],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    { onSuccess: (data: any) => handleCheckoutAreasOptions(data) },
  );

  const handleCheckoutAreasOptions = (checkoutAreas: any) => {
    const caOptions = checkoutAreas?.map((ca: CheckoutArea) => {
      return { label: ca.name, key: ca._id };
    });
    setCheckoutAreasOptions(caOptions);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
  };

  const sendGenerateTranslationsEvent = () => {
    sendEvent('home:translation:start', {
      homeIds: [home._id],
      lang: i18n.language.slice(0, 2),
    });
    dispatch(
      jobActions.addJob({
        type: STORE_TRANSLATION_ACTION,
        id: `${STORE_TRANSLATION_ACTION}:${home._id}`,
        status: JobStatus.IN_PROGRESS,
        callback: () => refresh(),
      }),
    );
  };

  useCallback(() => {
    if (home?.translations && home?.translations.length === 1) {
      setCurrentLanguage(home?.translations[0].languageCode);
    }
  }, [home]);

  return (
    <div className="card mb-4 lg:mr-4 mt-4 ">
      <div className="flex justify-between items-center">
        <h5 style={{ textAlign: 'left', margin: 0 }}>
          {t('listings.checkout-page')}
        </h5>
        <div className="flex items-center">
          {home?.translations?.length ? null : (
            <Button
              buttonWrapperClass="ai-button-wrapper"
              className={`ai-button ${
                isGeneratingTranslations ? '' : 'pointer'
              }`}
              disabled={isGeneratingTranslations}
              onClick={sendGenerateTranslationsEvent}
            >
              <span>{t('translate')}</span>
              {!isGeneratingTranslations ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </Button>
          )}
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisabled(false)}
          >
            {t('edit')}
          </div>
        </div>
      </div>
      <div>
        <div className="my-2">
          {home?.translations.length > 1 ? (
            <div className="flex flex-wrap justify-start gap-6 ">
              {home.translations
                .sort((a: any, b: any) => {
                  return b.dateCreated - a.dateCreated;
                })
                .map((lang: any) => (
                  <button
                    className={`rounded-full bg-gray-200 px-3 hover:opacity-100 py-1 cursor-pointer ${
                      lang.languageCode === currentLanguage
                        ? 'bg-lightPrimary opacity-100'
                        : 'opacity-25'
                    }`}
                    onClick={() => changeLanguage(lang.languageCode)}
                    key={lang.id}
                  >
                    <FlagIcons country={lang.languageCode} />
                  </button>
                ))}
            </div>
          ) : null}
        </div>
        <form onSubmit={handleSubmit(submit)}>
          {home?.translations.length ? (
            <div>
              {home?.translations.map((homeTranslation: IHomeTranslation) => (
                <div
                  className={`${
                    homeTranslation.languageCode === currentLanguage
                      ? ''
                      : 'hidden'
                  }`}
                  key={homeTranslation._id}
                >
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    defaultValue={homeTranslation?._id}
                    render={({ field: { value, onChange } }: any) => (
                      <Input
                        type="hidden"
                        key="_id"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name={
                      'translations.' +
                      homeTranslation.languageCode.toString() +
                      '._id'
                    }
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    defaultValue={homeTranslation?.title}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        disabled={disabled}
                        key="name"
                        label={'Name'}
                        size="small"
                        onChange={onChange}
                        value={value}
                        errors={[
                          _.get(errors, 'name.message'),
                          ...backendErrorsForAttribute('name'),
                        ]}
                      />
                    )}
                    name={
                      'translations.' +
                      homeTranslation.languageCode.toString() +
                      '.name'
                    }
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    defaultValue={homeTranslation?.description}
                    render={({ field: { onChange, value } }: any) => (
                      <TextArea
                        disabled={disabled}
                        key="description"
                        label="Description"
                        onChange={onChange}
                        value={value}
                        errors={[
                          _.get(errors, 'description.message'),
                          ...backendErrorsForAttribute('description'),
                        ]}
                      />
                    )}
                    name={
                      'translations.' +
                      homeTranslation.languageCode.toString() +
                      '.description'
                    }
                  />
                </div>
              ))}
              {disabled ? null : (
                <div className="mb-6">
                  <Controller
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, ref, onChange } }) => (
                      <label
                        htmlFor="hasToRegenerateTranslations"
                        className="cursor-pointer flex gap-3"
                      >
                        <input
                          type="checkbox"
                          ref={ref}
                          id="hasToRegenerateTranslations"
                          value={value}
                          onChange={onChange}
                        />
                        {t('update_other_translations')}
                      </label>
                    )}
                    name={'hasToRegenerateTranslations'}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={home?.headline}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    disabled={disabled}
                    key="headline"
                    label={t('listings.headline')}
                    size="small"
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'headline.message'),
                      ...backendErrorsForAttribute('headline'),
                    ]}
                  />
                )}
                name={'headline'}
              />
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={home?.description}
                render={({ field: { onChange, value } }: any) => (
                  <TextArea
                    disabled={disabled}
                    key="description"
                    label={t('listings.description')}
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'description.message'),
                      ...backendErrorsForAttribute('description'),
                    ]}
                  />
                )}
                name={'description'}
              />
            </div>
          )}
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={home?.checkoutArea?._id}
            render={({ field: { onChange, value } }: any) => (
              <Select
                disabled={disabled}
                key="checkoutArea"
                label={`${t('listings.available_extras')}`}
                selectLabel={`${t('checkout-areas.select_store')}`}
                onChange={onChange}
                value={value}
                tooltip={`${t('listings.checkout_area_tooltip')}`}
                options={checkoutAreasOptions}
                errors={[
                  _.get(errors, 'checkoutArea.message'),
                  ...backendErrorsForAttribute('checkoutArea'),
                ]}
              />
            )}
            name={'checkoutArea'}
          />
          <Controller
            control={control}
            rules={(Validation.noSpace, Validation.isUrlValid)}
            defaultValue={home?.customId || null}
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="customId"
                disabled={disabled}
                size="small"
                label={`${t('listings.custom_id')} (${t('optional')})`}
                tooltip={`${t('listings.custom_id_help')}`}
                onChange={onChange}
                value={value}
                helperText={`${document.location.origin}/cid/${
                  value || t('listings.your_custom_url')
                }`}
                errors={[_.get(errors, 'customId.message')]}
              />
            )}
            name={'customId'}
          />
          {disabled ? null : (
            <div className="flex justify-end mt-6 mb-2">
              <button
                title={'Cancel'}
                className="btn rounded-3xl btn-primary-outlined"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => handleCancelEdit()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary'}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default HomeCheckoutArea;
