import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import QRCodeBackground from '../../assets/images/qr_code_background.png';

type QRCodeCanevaProps = {
  url: string;
  homeName: string;
};

const QRCodeCaneva: React.FC<QRCodeCanevaProps> = ({ url, homeName }) => {
  const [isVisible] = useState(false);

  return (
    <div
      id="qr-content"
      style={{
        display: isVisible ? 'block' : 'none',
        width: '1024px',
        height: '683px',
        backgroundImage: `url(${QRCodeBackground})`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          paddingTop: 100,
        }}
      >
        <QRCode value={url} size={250} />
      </div>
      <div
        style={{
          position: 'absolute',
          fontWeight: 'bold',
          bottom: '10px',
          left: '10px',
          fontSize: '24px',
          color: '#7e82e5',
        }}
      >
        #{homeName}
      </div>
    </div>
  );
};

export default QRCodeCaneva;
