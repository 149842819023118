import React, { useEffect } from 'react';
import '../../../App.css';
import './sign-up-from-invitation-form.styles.scss';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/input/input.component';
import Validation from '../../../utils/validation';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../../utils/helpers';
import { useAuth } from '../../../contexts/authContext';
import SubmitButton from '../../../components/submit-button/submit-button.component';
import { useMutation } from 'react-query';
import ErrorMessage from '../../../components/error-message/error-message.component';
import ProcessingAction from '../../../components/_main/processing-action/processing-action.component';

const SignUpFromInvitationForm = ({
  accountInvitation,
}: {
  accountInvitation: any;
}) => {
  const { t } = useTranslation();

  let {
    signupFromInvitation,
    signUpError,
    setSignUpError,
    priceLookupKey,
    isRegisteringNewUser,
    processingAction,
  } = useAuth();
  const backendErrors = '';

  useEffect(() => {
    // priceLookupKey.current = searchParams.get('price') || 'free_with_fees';
    priceLookupKey.current = 'free_with_fees';
  }, []);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const { mutate: signUp } = useMutation(
    (data: any) =>
      signupFromInvitation({
        email: data.email,
        password: data.password,
        invitationId: accountInvitation._id,
      }),
    {
      onError: (err: any) => setSignUpError(err),
    },
  );

  const submit = async (data: any) => {
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: t('auth.password-mismatch'),
      });
      return;
    }
    try {
      await signUp(data);
    } catch (e) {
      console.error(e);
    }
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="sign-up-form">
      <Controller
        control={control}
        rules={{ ...Validation.email, ...Validation.anyRequired }}
        defaultValue={accountInvitation.to}
        render={({ field: { onBlur, onChange, value } }: any) => (
          <Input
            disabled={true}
            type="email"
            key="email"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label={`${capitalizeFirstLetter(t('email'))}`}
            errors={[
              _.get(errors, 'email.message'),
              ...backendErrorsForAttribute('email'),
            ]}
          />
        )}
        name={'email'}
      />

      <Controller
        rules={Validation.password}
        control={control}
        defaultValue=""
        render={({ field: { onBlur, onChange, value } }: any) => (
          <Input
            type="password"
            key="password"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label={`${capitalizeFirstLetter(t('password'))} *`}
            errors={[
              _.get(errors, 'password.message'),
              ...backendErrorsForAttribute('password'),
            ]}
          />
        )}
        name={'password'}
      />

      <Controller
        control={control}
        rules={Validation.password}
        defaultValue=""
        render={({ field: { onBlur, onChange, value } }: any) => (
          <Input
            type="password"
            key="confirmPassword"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label={`${capitalizeFirstLetter(t('confirm-password'))} *`}
            errors={[
              _.get(errors, 'confirmPassword.message'),
              ...backendErrorsForAttribute('confirmPassword'),
            ]}
          />
        )}
        name={'confirmPassword'}
      />
      {!processingAction.length ? null : (
        <ProcessingAction message={t(processingAction)} />
      )}
      <ErrorMessage error={signUpError} displayDot={false} />
      <div className="submit-button-wrapper">
        <SubmitButton
          title={t('auth.sign-up')}
          customClass="submit-button-input"
          disabled={isRegisteringNewUser.current}
          isLoading={isRegisteringNewUser.current}
        />
      </div>
    </form>
  );
};

export default SignUpFromInvitationForm;
