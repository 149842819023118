import React from 'react';
import YoutubeDemo from '../../components/youtube-demo/youtube-demo.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import { useTranslation } from 'react-i18next';
import TabContent from '../../components/tab-content/tab-content.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';

const Tutorial = () => {
  const { t } = useTranslation();
  return (
    <TabWrapper>
      <>
        <TabContentHeader title={t('menu.how-it-works')} rootPage="/homes" />

        <TabContent>
          <YoutubeDemo />
        </TabContent>
      </>
    </TabWrapper>
  );
};

export default Tutorial;
