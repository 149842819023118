import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import { Emoji } from 'emoji-mart';
import { daysDifference, formatDateToWeekdayDayMonth } from '../../utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faDown,
  faTruck,
} from '@fortawesome/pro-regular-svg-icons';

import Loader from '../loader/loader.component';
import OrderTypeEmoji from '../_main/order-type-emoji/order-type-emoji.constante';

type PendingOrdersProps = {
  upcomingOrders: any;
  isLoading: boolean;
  isFetching: boolean;
};

const UpcomingOrders = ({
  upcomingOrders,
  isLoading,
  isFetching,
}: PendingOrdersProps) => {
  const { t, i18n } = useTranslation();
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<Number>(-1);

  const toggleDate = (date: string) => {
    setActiveDate(activeDate === date ? null : date);
  };

  const openExpandTable = (i: number) => {
    if (i == selectedRow) {
      return setSelectedRow(-1);
    }
    setSelectedRow(i);
  };

  return (
    <div className="flex flex-col h-full">
      {upcomingOrders?.length || isLoading ? null : (
        <div className="flex-row my-5 lg:mt-10">
          <FontAwesomeIcon
            className="opacity-20 mb-4"
            color="#b6b9ce"
            icon={faTruck}
            size="4x"
          />
          <div className="text-sm font-semibold text-[#6B7194] opacity-20">
            {t('dashboard.empty_upcoming_orders')}
          </div>
        </div>
      )}
      <div className="flex-grow relative">
        {isLoading ||
          (isFetching && (
            <div className="flex justify-center items-center height-[150px]">
              <Loader />
            </div>
          ))}
        {!upcomingOrders || isLoading || isFetching ? null : (
          <div className=" h-full lg:overflow-auto lg:px-4 ">
            {upcomingOrders?.map(({ deliveryDate, orders, count }: any) => {
              const dayDiffrence = daysDifference(
                new Date().toString(),
                deliveryDate,
              );
              return (
                <div key={deliveryDate} className="mb-2">
                  <button
                    className="min-h-[52px] flex flex-row justify-between w-full mt-2 py-2 px-2 sticky top-0 bg-white"
                    onClick={() => toggleDate(deliveryDate)}
                  >
                    <span className="text-left text-[14px] font-bold first-letter:capitalize">
                      <span className="mr-3">
                        <FontAwesomeIcon
                          icon={
                            activeDate === deliveryDate
                              ? faAngleUp
                              : faAngleDown
                          }
                          size="lg"
                        />
                      </span>
                      {formatDateToWeekdayDayMonth(deliveryDate)}
                    </span>
                    <div className="relative">
                      <span
                        className="capitalize p-2 text-xs text-greyText font-semibold rounded-2xl"
                        style={{
                          backgroundColor:
                            dayDiffrence === 0 ? '#FECACA' : '#F2F7FA',
                        }}
                      >
                        {dayDiffrence === 0
                          ? t('dashboard.today')
                          : t('dashboard.day_difference', {
                              count: dayDiffrence,
                            })}
                      </span>
                      <span className="mr-2 absolute top-[-12px] w-[16px] h-[16px] right-[-12px] capitalize text-center text-[10px] bg-checkoutPrimary text-white font-semibold rounded-2xl">
                        {count}
                      </span>
                    </div>
                  </button>
                  {activeDate === deliveryDate ? null : (
                    <div className="border-b border-grey" />
                  )}
                  {activeDate === deliveryDate && (
                    <div className="mt-2 mx-2 lg:mx-0">
                      {orders.map(
                        (
                          { order, home, isStayExtension, stayExtensions }: any,
                          index: number,
                        ) => (
                          <div
                            key={index}
                            className="py-2 px-2 bg-lightblue cursor-pointer rounded-2xl mb-2"
                            onClick={() => openExpandTable(index)}
                          >
                            <div className="flex flex-col">
                              <div className="flex justify-between items-center mb-2">
                                <span className="mx-1">#{order.order_id}</span>
                                <div className="flex justify-center items-center gap-1">
                                  {isStayExtension}
                                  <OrderTypeEmoji
                                    isStayExtension={isStayExtension}
                                  />
                                  <span className="capitalize py-0.5 px-3 text-xs bg-lightGreen text-green font-semibold rounded-2xl">
                                    {t(`orders.paid`)}
                                  </span>
                                </div>
                              </div>
                              <div className="flex justify-start">
                                <span className="w-3/4 font-bold text-black text-sm mx-2 text-left text-ellipsis overflow-hidden whitespace-nowrap">
                                  {home.name}
                                </span>
                              </div>

                              <div className="flex justify-between items-center py-2">
                                <span className="font-bold text-black text-sm mx-2 w-3/4 first-letter:capitalize text-left text-ellipsis overflow-hidden whitespace-nowrap">
                                  {order.customer}
                                </span>
                                <div
                                  className="text-center text-xs"
                                  style={{ width: '15%' }}
                                >
                                  {displayPrice(
                                    order.totalAmount,
                                    getCurrencyInfo('eur').symbol,
                                    {
                                      thousandSeparator: ' ',
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                            {selectedRow !== index ? (
                              <span className="text-primary text-xs underline">
                                {t('see_more')}
                              </span>
                            ) : (
                              <>
                                <span className="text-primary text-xs underline">
                                  {t('see_less')}
                                </span>
                                <div
                                  className="rounded-xl mt-4"
                                  style={{ background: '#ddeeff' }}
                                >
                                  <div className="px-2 min-h-[48px] flex flex-col">
                                    {!order?.comment ? null : (
                                      <div className="mt-2 flex flex-col text-left">
                                        <div className="font-bold underline text-xs">
                                          {t('orders.comment')}:
                                        </div>
                                        <p className="text-xs">
                                          {order.comment}
                                        </p>
                                      </div>
                                    )}
                                    {!isStayExtension ? (
                                      <>
                                        {order.products?.map((product: any) => (
                                          <div
                                            key={product._id}
                                            className="p-4 flex justify-between items-center w-full"
                                          >
                                            <div
                                              className="text-left flex text-xs items-center"
                                              style={{ width: '8%' }}
                                            >
                                              x{product.quantity}
                                            </div>
                                            <div
                                              className="text-left flex items-center"
                                              style={{ width: '8%' }}
                                            >
                                              <Emoji
                                                emoji={
                                                  product.item.emoji ||
                                                  'grey_question'
                                                }
                                                size={20}
                                              />
                                            </div>
                                            <div
                                              className="text-left text-xs flex items-center"
                                              style={{ width: '32%' }}
                                            >
                                              {'isValidated' in product ? (
                                                <>
                                                  {!product.isValidated ? (
                                                    <del>
                                                      {product.item.translations?.filter(
                                                        (t: any) =>
                                                          t.languageCode ===
                                                          i18n.language.slice(
                                                            0,
                                                            2,
                                                          ),
                                                      ).length
                                                        ? product.item.translations.filter(
                                                            (t: any) =>
                                                              t.languageCode ===
                                                              i18n.language.slice(
                                                                0,
                                                                2,
                                                              ),
                                                          )[0].title
                                                        : product.item.name}
                                                    </del>
                                                  ) : (
                                                    <span>
                                                      {product.item.translations?.filter(
                                                        (t: any) =>
                                                          t.languageCode ===
                                                          i18n.language.slice(
                                                            0,
                                                            2,
                                                          ),
                                                      ).length
                                                        ? product.item.translations.filter(
                                                            (t: any) =>
                                                              t.languageCode ===
                                                              i18n.language.slice(
                                                                0,
                                                                2,
                                                              ),
                                                          )[0].title
                                                        : product.item.name}
                                                    </span>
                                                  )}
                                                </>
                                              ) : (
                                                <span>
                                                  {product.item.translations?.filter(
                                                    (t: any) =>
                                                      t.languageCode ===
                                                      i18n.language.slice(0, 2),
                                                  ).length
                                                    ? product.item.translations.filter(
                                                        (t: any) =>
                                                          t.languageCode ===
                                                          i18n.language.slice(
                                                            0,
                                                            2,
                                                          ),
                                                      )[0].title
                                                    : product.item.name}
                                                </span>
                                              )}
                                            </div>
                                            <div
                                              className="text-left flex items-center text-xs"
                                              style={{ width: '15%' }}
                                            >
                                              {displayPrice(
                                                product.item.sellingPrice,
                                                getCurrencyInfo('eur').symbol,
                                                {
                                                  thousandSeparator: ' ',
                                                },
                                              )}
                                            </div>
                                            <div
                                              className="text-left flex items-center text-xs"
                                              style={{ width: '8%' }}
                                            >
                                              {'isValidated' in product ? (
                                                <>
                                                  {product.isValidated
                                                    ? '✅'
                                                    : '❌'}
                                                </>
                                              ) : (
                                                '✅'
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        {stayExtensions?.map(
                                          (stayExtension: any) => (
                                            <div
                                              key={stayExtension._id}
                                              className="flex gap-4 flex-col justify-between w-full p-2"
                                            >
                                              <div className="text-left flex flex-col justify-start gap-1 text-xs">
                                                <div className="flex gap-3">
                                                  <span className="">
                                                    {t(
                                                      'booking_success.origin_reservation',
                                                    )}{' '}
                                                    :
                                                  </span>
                                                  <span className="text-weight-700">
                                                    {
                                                      stayExtension.reservationId
                                                    }
                                                  </span>
                                                </div>
                                                <div className="flex gap-3">
                                                  <span className="">
                                                    {t(
                                                      'booking_success.new_reservation',
                                                    )}{' '}
                                                    :
                                                  </span>
                                                  <span className="text-weight-700">
                                                    {
                                                      stayExtension.newReservationId
                                                    }
                                                  </span>
                                                </div>
                                                <div className="flex gap-3">
                                                  <span className="">
                                                    {t('booking_success.name')}{' '}
                                                    :
                                                  </span>
                                                  <span>
                                                    {stayExtension.guestName}
                                                  </span>
                                                </div>
                                                <div className="flex gap-3">
                                                  <span className="">
                                                    {t(
                                                      'booking_success.number_guests',
                                                    )}{' '}
                                                    :
                                                  </span>
                                                  <span>
                                                    {stayExtension.totalGuests}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="inline-flex gap-1 items-center items-st flex-col text-sm">
                                                <div>
                                                  <div className="text-left">
                                                    <span className="text-sm text-grey">
                                                      {t(
                                                        'booking_success.arrival',
                                                      )}{' '}
                                                      :
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      {new Date(
                                                        stayExtension.newArrivalDate,
                                                      ).toLocaleDateString()}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div className="text-left">
                                                    <span className="line-through text-sm text-grey">
                                                      {t(
                                                        'booking_success.departure',
                                                      )}{' '}
                                                      :
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span className="line-through text-grey">
                                                      {new Date(
                                                        stayExtension.originalDepartureDate,
                                                      ).toLocaleDateString()}
                                                    </span>
                                                  </div>
                                                </div>
                                                <span>
                                                  <FontAwesomeIcon
                                                    className="text-green"
                                                    icon={faDown}
                                                  />
                                                </span>
                                                <div>
                                                  <div className="text-left">
                                                    <span className="text-sm text-green">
                                                      {t(
                                                        'booking_success.new_departure',
                                                      )}{' '}
                                                      :
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <span>
                                                      {new Date(
                                                        stayExtension.newDepartureDate,
                                                      ).toLocaleDateString()}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ),
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingOrders;
