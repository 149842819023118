import { useState } from 'react';
import html2canvas from 'html2canvas';
import useTrackMixpanelEvent from './useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../contexts/authContext';
import { Home } from '../types/home.model';

const useQRGenerator = () => {
  const { currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [imageSrc, setImageSrc] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const generateQRCode = async (home: Home) => {
    trackMixpanelEvent('QR Code - Display', {
      home_id: home._id,
      home_name: home.name,
      workspace_id: currentWorkspace._id,
      account_id: currentAccount._id,
      account_name: currentAccount.name,
    });
    const content = document.getElementById('qr-content');
    if (!content) return;

    content.style.display = 'block';

    html2canvas(content)
      .then((canvas) => {
        const imageSrc = canvas.toDataURL();
        setImageSrc(imageSrc);
        setIsModalOpen(true);

        // Convert dataURL to blob
        fetch(imageSrc)
          .then((res) => res.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            // link.setAttribute('download', 'generated-image.png');
            // link.click();
          });

        // Hide the content again
        content.style.display = 'none';
      })
      .catch((error) => {
        console.error('Error generating canvas:', error);
      });
  };

  return {
    generateQRCode,
    imageSrc,
    isModalOpen,
    setIsModalOpen,
  };
};

export default useQRGenerator;
