import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description?: string;
  isVisible: boolean;
  setIsVisible: any;
  successAction: any;
  successLabel: string;
  cancelLabel: string | null;
  children?: any;
}

const AlertBeforeAction = ({
  title,
  description,
  isVisible,
  setIsVisible,
  successAction,
  successLabel,
  cancelLabel,
  children,
}: Props) => {
  const { t } = useTranslation();
  const onClose = () => {
    setIsVisible(false);
  };

  const onSuccess = () => {
    successAction();
    setIsVisible(false);
  };

  return !isVisible ? null : (
    <div className="modal-overlay">
      <div className="modal-wrapper">
        <OnClickOut callback={() => setIsVisible(false)} classname={'modal'}>
          <button
            type="button"
            className="modal-close-button"
            onClick={onClose}
          >
            <span>&times;</span>
          </button>
          <div className="my-4">
            <h3 className="font-bold text-2xl lg:text-4xl tracking-tighter">
              {title}
            </h3>
          </div>
          {children ? children : null}
          {description && <div className="my-2 text-md">{description}</div>}
          <div className="flex my-2">
            {cancelLabel === null ? null : (
              <button
                title={t('cancel')}
                className="btn rounded-3xl btn-primary-outlined"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 12,
                }}
                onClick={() => setIsVisible(false)}
              >
                {t(cancelLabel)}
              </button>
            )}
            <button
              title={t('listings.confirm')}
              className={'btn rounded-3xl btn-primary'}
              onClick={() => onSuccess()}
            >
              {t(successLabel)}
            </button>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};

AlertBeforeAction.defaultProps = {
  successLabel: 'confirm',
  cancelLabel: 'cancel',
};

export default AlertBeforeAction;
