import React, { useEffect, useState } from 'react';
import Validation from '../../../utils/validation';
import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../../utils/helpers';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/input/input.component';
import { useAuth } from '../../../contexts/authContext';
import SubmitButton from '../../../components/submit-button/submit-button.component';
import ErrorMessage from '../../../components/error-message/error-message.component';
import { handleFirebaseLoginError } from '../../../utils/errors';

const SignInForm = () => {
  const { t } = useTranslation();
  let { signin, isSigningIn, loginError } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const backendErrors = '';
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let message;
    if (loginError?.code) {
      message = handleFirebaseLoginError(loginError?.code);
      setErrorMessage(message);
    }
  }, [loginError]);

  const submit = (data: any) => {
    signin({ email: data.email, password: data.password });
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="sign-in-form">
      <Controller
        control={control}
        rules={{ ...Validation.email, ...Validation.anyRequired }}
        defaultValue={process.env.REACT_APP_LOGIN_EMAIL}
        render={({ field: { onBlur, onChange, value } }: any) => (
          <Input
            type="email"
            key="email"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label={`${capitalizeFirstLetter(t('email'))} *`}
            errors={[
              _.get(errors, 'email.message'),
              ...backendErrorsForAttribute('email'),
            ]}
          />
        )}
        name={'email'}
      />
      <div>
        <Controller
          rules={Validation.password}
          control={control}
          defaultValue={process.env.REACT_APP_LOGIN_PASSWORD}
          render={({ field: { onBlur, onChange, value } }: any) => (
            <Input
              type="password"
              key="password"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              label={`${capitalizeFirstLetter(t('password'))} *`}
              errors={[
                _.get(errors, 'password.message'),
                ...backendErrorsForAttribute('password'),
              ]}
              customComponent={() => (
                <Link
                  to="/forgot-password"
                  className="span-underline text-primary text-[12px] self-center mt-2 mb-4"
                >
                  {t('auth.forgot-password')}
                </Link>
              )}
            />
          )}
          name={'password'}
        />
      </div>
      {errorMessage && (
        <div className="mt-6">
          <ErrorMessage error={t(errorMessage)} displayDot={false} />
        </div>
      )}

      <div className="submit-button-wrapper">
        <SubmitButton
          title={t('auth.sign-in-submit')}
          customClass="submit-button-input"
          isLoading={isSigningIn}
          disabled={isSigningIn}
        />
      </div>
      <div className="account-return-detail ">
        {t('auth.sign-in-has-account')}
        <Link to="/sign-up" className="span-underline text-primary">
          {t('auth.register')}
        </Link>
      </div>
    </form>
  );
};

export default SignInForm;
