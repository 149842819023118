import React, { useEffect, useState } from 'react';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import AccountSettings from './account-settings/AccountSettings.page';
import ProfilSettings from './profile-settings/ProfilSettings.page';
import WorkspaceSettings from './workspace-settings/WorkspaceSettings.page';
import BillingSettings from './billing-settings/BillingSettings.page';
import { useRole } from '../../hooks/useRole';
import { useAppSelector } from '../../hooks/useStore';
import ReferralsSettings from './referrals-settings/ReferralsSettings.page';

const Settings = () => {
  const { t } = useTranslation();
  const [subRoute, setSubRoute] = useState('');
  const { pathname } = useLocation();

  const { hasAuthorization } = useRole();
  const userCurrentRole = useAppSelector((state) => state.global.currentRole);
  const [headerDescription, setHeaderDescription] = useState();

  useEffect(() => {
    switch (pathname) {
      case '/settings/account':
        setHeaderDescription(t('menu.account_description'));
        break;
      case '/settings/profile':
        setHeaderDescription(t('menu.profile_description'));
        break;
      case '/settings/workspaces':
        setHeaderDescription(t('menu.workspaces_description'));
        break;
      case '/settings/referrals':
        setHeaderDescription(t('menu.referrals_description'));
        break;
      case '/settings/billing':
        setHeaderDescription(t('menu.subscription_description'));
        break;
      default:
        console.warn('No description');
        break;
    }
  }, [pathname]);

  return (
    <TabWrapper>
      <>
        <TabContentHeader
          title={t('menu.settings')}
          description={headerDescription}
          rootPage="/settings"
          subRoute={subRoute}
        />
        <div className="mt-6">
          <Routes>
            <Route
              path="account"
              element={
                !hasAuthorization(userCurrentRole, [
                  'ADMIN',
                  'OWNER',
                ]) ? null : (
                  <AccountSettings setSubRoute={setSubRoute} />
                )
              }
            />
            <Route
              path="profile"
              element={<ProfilSettings setSubRoute={setSubRoute} />}
            />

            <Route
              path="workspaces/*"
              element={
                !hasAuthorization(userCurrentRole, [
                  'ADMIN',
                  'OWNER',
                ]) ? null : (
                  <WorkspaceSettings setSubRoute={setSubRoute} />
                )
              }
            />
            <Route
              path="referrals"
              element={
                !hasAuthorization(userCurrentRole, [
                  'ADMIN',
                  'OWNER',
                  'MANAGER',
                ]) ? null : (
                  <ReferralsSettings setSubRoute={setSubRoute} />
                )
              }
            />

            <Route
              path="billing"
              element={
                !hasAuthorization(userCurrentRole, [
                  'ADMIN',
                  'OWNER',
                ]) ? null : (
                  <BillingSettings setSubRoute={setSubRoute} />
                )
              }
            />
          </Routes>
        </div>
      </>
    </TabWrapper>
  );
};

export default Settings;
