import React from 'react';
import './extra.styles.scss';
import { Emoji } from 'emoji-mart';
import { Icon } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useTranslation } from 'react-i18next';
import { displayPrice } from '../../utils/currency';
import { displayExpirationDate, hasProductExpired } from '../../utils/date';

const Extra = (props: any) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={`extra border-b last:border-none ${
          hasProductExpired(props.arrivalDate, props.preparationTime)
            ? 'opacity-50'
            : ''
        }`}
      >
        <div className="ml-4 pt-4 flex justify-between">
          {!props.icon ? null : <Emoji emoji={props.icon} size={52} />}
          <div className="mr-2">
            <div>
              {!props?.arrivalDate && props.preparationTime ? (
                <div
                  className={`${
                    props.showingDatePicker && 'animate-bounce'
                  } flex items-center justify-between py-2 px-4 bg-gray-100 text-gray-700 rounded-3xl cursor-pointer`}
                  onClick={() => props.focusSelectArrivalDate()}
                >
                  {t('checkout.enter_arrival_date')}
                  <svg
                    className="h-5 w-5 text-gray-400 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              ) : null}
              {props.arrivalDate && props.preparationTime !== 0 ? (
                <span className="py-2 px-4 bg-gray-100 text-gray-700 rounded-3xl">
                  {displayExpirationDate(
                    props.arrivalDate,
                    props.preparationTime,
                  )}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="description-wrapper">
            <div className="description-container">
              <div className="">
                <h3 className="extra-title">
                  {props.translations.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  ).length
                    ? props.translations.filter(
                        (t: any) =>
                          t.languageCode === i18n.language.slice(0, 2),
                      )[0].title
                    : props.title}
                </h3>
              </div>
              <p className="extra-description">
                {props.translations.filter(
                  (t: any) => t.languageCode === i18n.language.slice(0, 2),
                ).length
                  ? props.translations.filter(
                      (t: any) => t.languageCode === i18n.language.slice(0, 2),
                    )[0].description
                  : props.description}
              </p>

              {!props?.pictures?.length ? null : (
                <p
                  className="m-0 text-checkout underline cursor-pointer"
                  onClick={() => props.callback(props.pictures)}
                >
                  {t('see_more')}
                </p>
              )}
              {props?.validationRequired && (
                <div className="flex items-end mt-3 opacity-50">
                  <span className=" text-checkoutPrimary text-xs">
                    *{t('checkout.if_available')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="quantity-wrapper">
            <div className="quantity-container">
              <div className="price">
                {displayPrice(props.price, props.currency.symbol, {
                  thousandSeparator: ' ',
                })}
              </div>
              {props.isProcessingPayment ||
              hasProductExpired(
                props.arrivalDate,
                props.preparationTime,
              ) ? null : (
                <div className="quantity-selector-wrapper flex justify-between">
                  <Icon
                    onClick={props.onDecrementQuantity}
                    fontSize="small"
                    className="quantity-icon minus"
                    component={RemoveOutlinedIcon}
                  />
                  <div className="quantity">{props.quantity}</div>
                  <Icon
                    onClick={props.onIncrementQuantity}
                    fontSize="small"
                    className="quantity-icon plus"
                    component={AddOutlinedIcon}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Extra;
