import React from 'react';

interface CURowTextProps {
  label: string;
  value: string | number;
  labelClass?: string;
  valueClass?: string;
}
const CURowText: React.FC<CURowTextProps> = ({
  label,
  labelClass,
  value,
  valueClass,
}) => {
  return (
    <div className="w-full flex flex-row justify-between mb-1">
      <div className="w-1/3 text-greyTextDarker text-left">
        <span className={labelClass ?? 'text-xs font-bold'}>{label}</span>
      </div>
      <div className="text-right w-3/5 overflow-ellipsis">
        <span className={valueClass ?? 'text-xs'}>{value}</span>
      </div>
    </div>
  );
};

export default CURowText;
