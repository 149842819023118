import { CheckoutAreaProduct } from '../types/checkout-area-product.model';
import { CheckoutArea } from '../types/checkout-area.model';
import { api } from '../utils/request';

export const createCheckoutAreasByWorkspace = async (
  authToken: string,
  workspaceId: string,
  data: CheckoutArea,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/checkout-areas`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getCheckoutAreas = async (userToken: string) => {
  try {
    const res = await api.get(`/checkout-areas`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getCheckoutAreaById = async (
  userToken: string,
  workspaceId: string,
  checkoutAreaId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/checkout-areas/${checkoutAreaId}`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getCheckoutAreasByWorkspace = async (
  userToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/checkout-areas`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createCheckoutAreaProduct = async (
  authToken: string,
  workspaceId: string,
  checkoutAreaId: string,
  data: CheckoutAreaProduct,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/checkout-areas/${checkoutAreaId}/products`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateCheckoutArea = async (
  authToken: string,
  workspaceId: string,
  checkoutAreaId: string,
  data: CheckoutArea,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/checkout-areas/${checkoutAreaId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteCheckoutArea = async (
  authToken: string,
  workspaceId: string,
  checkoutAreaId: string,
) => {
  try {
    const res = await api.delete(
      `/workspaces/${workspaceId}/checkout-areas/${checkoutAreaId}`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res;
  } catch (err: any) {
    console.log(err);
  }
};
