import React from 'react';
import AirbnbLogo from '../../assets/images/airbnb-logo.svg';
import Beds24Logo from '../../assets/images/beds24-logo.svg';
import BookingSyncLogo from '../../assets/images/bookingsync-logo.svg';
import HostawayLogo from '../../assets/images/hostaway-logo.svg';
import HospitableLogo from '../../assets/images/hospitable-logo.svg';
import LodgifyLogo from '../../assets/images/lodgify-logo.svg';
import SmoobuLogo from '../../assets/images/smoobu-logo.png';
import LodgixLogo from '../../assets/images/lodgix-logo.svg';
import TokeetLogo from '../../assets/images/tokeet-logo.svg';
import FantasticStayLogo from '../../assets/images/fantasticstay-logo.svg';
import HostifyLogo from '../../assets/images/hostify-logo.svg';
import AvaibookLogo from '../../assets/images/avaibook-logo.svg';
import HostfullyLogo from '../../assets/images/hostfully-logo.svg';
import UplistingLogo from '../../assets/images/uplisting-logo.svg';
import OwnerRezLogo from '../../assets/images/ownerrez-logo.svg';
import GuestyLogo from '../../assets/images/guesty-logo.svg';
import SuperHoteLogo from '../../assets/images/superhote-logo.svg';

interface ChannelManagerLogoProps {
  type: string;
}

const ChannelManagerLogo: React.FC<ChannelManagerLogoProps> = ({
  type,
}): React.ReactElement => {
  switch (type) {
    case 'CALRY_BEDS24' || 'BEDS24':
      return (
        <img className="object-contain h-10 w-auto" src={Beds24Logo}></img>
      );
    case 'CALRY_UPLISTING' || 'UPLISTING':
      return (
        <img className="object-contain h-10 w-auto" src={UplistingLogo}></img>
      );
    case 'CALRY_TOKEET' || 'TOKEET':
      return (
        <img className="object-contain h-10 w-auto" src={TokeetLogo}></img>
      );
    case 'CALRY_LOGIX' || 'LODGIX':
      return (
        <img className="object-contain h-10 w-auto" src={LodgixLogo}></img>
      );
    case 'CALRY_AVAIBOOK' || 'AVAIBOOK':
      return (
        <img className="object-contain h-10 w-auto" src={AvaibookLogo}></img>
      );
    case 'CALRY_FANTASTICSTAY' || 'FANTASTICSTAY':
      return (
        <img
          className="object-contain h-10 w-auto"
          src={FantasticStayLogo}
        ></img>
      );
    case 'CALRY_HOSTIFY' || 'HOSTIFY':
      return (
        <img className="object-contain h-10 w-auto" src={HostifyLogo}></img>
      );
    case 'CALRY_HOSTFULLY' || 'HOSTFULLY':
      return (
        <img className="object-contain h-10 w-auto" src={HostfullyLogo}></img>
      );
    case 'CALRY_OWNERREZ' || 'OWNERREZ':
      return (
        <img className="object-contain h-10 w-auto" src={OwnerRezLogo}></img>
      );
    case 'CALRY_GUESTY' || 'GUESTY':
      return (
        <img className="object-contain h-10 w-auto" src={GuestyLogo}></img>
      );
    case 'CALRY_LODGIFY' || 'LODGIFY':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={LodgifyLogo}
        ></img>
      );

    case 'HOSPITABLE':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={HospitableLogo}
        ></img>
      );
    case 'CALRY_HOSTAWAY':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={HostawayLogo}
        ></img>
      );
    case 'HOSTAWAY':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={HostawayLogo}
        ></img>
      );
    case 'SUPERHOTE':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={SuperHoteLogo}
        ></img>
      );
    case 'BOOKING_SYNC':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={BookingSyncLogo}
        ></img>
      );
    case 'SMOOBU':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={SmoobuLogo}
        ></img>
      );
    case 'HOSPITABLE_CONNECT_AIRBNB':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={AirbnbLogo}
        ></img>
      );
    case 'AIRBNB':
      return (
        <img
          className="object-contain"
          width="100%"
          height="100%"
          src={AirbnbLogo}
        ></img>
      );
    default:
      return <div></div>;
  }
};

export default ChannelManagerLogo;
