/* eslint-disable no-unused-vars */

import i18n from '../i18n';

export enum RolesLevel {
  ROOT = 10000,
  OWNER = 900,
  ADMIN = 800,
  MANAGER = 500,
  GUEST = 200,
  VISITOR = 100,
}

export enum RolesLabel {
  ROOT = 'ROOT',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  GUEST = 'GUEST',
  VISITOR = 'VISITOR',
  CLEANING_STAFF = 'CLEANING_STAFF',
}

type RoleOption = {
  label: string;
  desc: string;
  value: string;
  key: string;
};

export const RolesList: RoleOption[] = [
  {
    label: i18n.t('role_list.manager'),
    desc: i18n.t('role_list.manager_desc'),
    value: RolesLabel.MANAGER,
    key: RolesLabel.MANAGER,
  },
  {
    label: i18n.t('role_list.cleaning_staff'),
    desc: i18n.t('role_list.cleaning_staff_desc'),
    value: RolesLabel.CLEANING_STAFF,
    key: RolesLabel.CLEANING_STAFF,
  },

  // {
  //   label: 'Admin',
  //   value: RolesLabel.ADMIN,
  // },
];

export const Roles = {
  root: {
    level: RolesLevel.ROOT,
    label: RolesLabel.ROOT,
  },
  owner: {
    level: RolesLevel.OWNER,
    label: RolesLabel.OWNER,
  },
  admin: {
    level: RolesLevel.ADMIN,
    label: RolesLabel.ADMIN,
  },
  manager: {
    level: RolesLevel.MANAGER,
    label: RolesLabel.MANAGER,
  },
  guest: {
    level: RolesLevel.GUEST,
    label: RolesLabel.GUEST,
  },
  visitor: {
    level: RolesLevel.VISITOR,
    label: RolesLabel.VISITOR,
  },
};
