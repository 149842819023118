import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-tailwindcss-select';
import ErrorMessage from '../error-message/error-message.component';

interface IProps {
  options: IOption[];
  value: IOption | IOption[] | null;
  onChange: any;
  primaryColor: string;
  label?: string;
  disabled?: boolean;
  containerStyle?: string;
  errors?: any;
}

export interface IOption {
  label: string;
  value: any;
}

export default function MultiSelect({
  options,
  onChange,
  value,
  label,
  disabled = false,
  errors,
  containerStyle,
}: IProps) {
  const { t } = useTranslation();
  return (
    <div className={containerStyle}>
      <label className={`text-left text-[#6B7194] mb-1 `}>{label}</label>
      <Select
        primaryColor=""
        options={options}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        isMultiple={true}
        isSearchable={true}
        placeholder={t('select')}
        classNames={{
          menu: 'absolute z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700',
          menuButton: ({ isDisabled }: any) =>
            `w-full text-gray-700 flex items-center border hover:border-primary focus:border-primary focus-visible:border-primary outline-none py-1 px-1 flex flex-wrap h-auto min-h-10 bg-white border-gray-400 rounded-3xl mb-0  ${
              isDisabled
                ? 'opacity-50 hover:border-gray-400 pointer-events-none'
                : ''
            }`,
        }}
      />
      {errors &&
        errors.map((err: string) => {
          if (err) {
            return <ErrorMessage key={err} error={err} />;
          }
        })}
    </div>
  );
}
