import React, { useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import _ from 'lodash';
import SubmitButton from '../../../submit-button/submit-button.component';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import { Emoji } from 'emoji-mart';
import { useLocation, useNavigate } from 'react-router-dom';
import { Product } from '../../../../types/product.model';
import { useAuth } from '../../../../contexts/authContext';
import { useMutation, useQuery } from 'react-query';
import { Icon } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckoutArea } from '../../../../types/checkout-area.model';
import { createCheckoutAreasByWorkspace } from '../../../../api/checkoutAreas';
import { getProductsByWorkspace } from '../../../../api/products';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/useStore';
import { fetchOnboardingStatus } from '../../../../redux/global/globalActions';
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faCirclePlus,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTrackMixpanelEvent from '../../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { displayPrice, getCurrencyInfo } from '../../../../utils/currency';

const NewCheckoutArea = ({ setSubRoute, setRefresh }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedProducts, setCheckedProducts] = useState<any[]>([]);
  const [availableProducts, setAvailableProducts] = useState<any[]>([]);
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const backendErrors = '';
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSubRoute(t('add'));
  }, []);

  useEffect(() => {
    if (location.state?.checkoutArea) {
      setCheckedProducts(location.state.checkoutArea.products);
    }
  }, [location.state]);

  const { data: productsData } = useQuery(
    ['products'],
    () => getProductsByWorkspace(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  useEffect(() => {
    if (productsData) {
      const products = [...productsData];
      const productsFiltered = products.filter(
        (product: any) =>
          !checkedProducts
            .map((product: any) => product._id)
            .includes(product._id),
      );
      setAvailableProducts(productsFiltered);
    }
  }, [checkedProducts, productsData]);

  const { mutate: createCheckoutArea, isLoading: isLoadingCheckoutArea } =
    useMutation(
      (data: CheckoutArea) =>
        createCheckoutAreasByWorkspace(
          currentUser.accessToken,
          currentWorkspace._id,
          data,
        ),
      {
        onSuccess: () => {
          setRefresh(true);
          trackMixpanelEvent(
            'CheckoutArea - creation success',
            mixpanelProperties,
          );
          dispatch(
            fetchOnboardingStatus(
              currentUser.accessToken,
              currentWorkspace._id,
            ),
          );
          navigate('/checkout-areas');
        },
      },
    );

  const handleDeleteProduct = (productIdToDelete: string) => {
    const productsListUpdated = [
      ...checkedProducts.filter(
        (product: any) => product._id !== productIdToDelete,
      ),
    ];
    setCheckedProducts(productsListUpdated);
  };

  const submit = async (data: any) => {
    createCheckoutArea({
      ...data,
      products: checkedProducts.length ? checkedProducts.map((p) => p._id) : [],
    });
  };

  const handleCancel = () => {
    navigate('/checkout-areas');
  };

  const handleSelect = (e: any, productToAdd: any) => {
    if (!checkedProducts.includes(productToAdd)) {
      setCheckedProducts((prev: string[]) => [...prev, productToAdd]);
      setAvailableProducts((prev: string[]) =>
        prev.filter((product: any) => product._id !== productToAdd._id),
      );
    }
  };

  const moveItemInArrayFromIndexToIndex = (
    array: any[],
    fromIndex: number,
    toIndex: number,
  ) => {
    const newArray = [...array];
    // remove `from` item and store it
    const f = newArray.splice(fromIndex, 1)[0];
    // insert stored item into position `to`
    newArray.splice(toIndex, 0, f);
    return newArray;
  };

  const onChangeOrderProduct = (oldIndex: number, newIndex: number) => {
    const products = moveItemInArrayFromIndexToIndex(
      checkedProducts.map((product: Product) => product),
      oldIndex,
      newIndex,
    );
    setCheckedProducts(products);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mt-4 card">
          <div className="mb-6 mt-2">
            <h5 style={{ textAlign: 'left', margin: 0 }}>
              {t('checkout-areas.informations')}
            </h5>
          </div>
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={location.state?.checkoutArea?.name || ''}
            render={({ field: { onChange, value } }: any) => (
              <Input
                style={{ width: '60%' }}
                disabled={false}
                key="name"
                label={t('checkout-areas.name')}
                tooltip={t('checkout-areas.name_tooltip')}
                helperText={t('checkout-areas.name_of_the_ca')}
                size="small"
                onChange={onChange}
                value={value}
                errors={[
                  _.get(errors, 'name.message'),
                  ...backendErrorsForAttribute('name'),
                ]}
              />
            )}
            name={'name'}
          />
          <div className="mb-6 mt-6">
            <h5 style={{ textAlign: 'left', margin: 0 }}>
              {t('checkout-areas.products')}
            </h5>
          </div>

          {!productsData?.length ? (
            <div className="mt-10 flex items-center">
              <div className="mr-4">
                <span className="text-sm font-semibold text-[#6B7194]">
                  {t('tables.empty-products')}
                </span>
              </div>
              <button
                onClick={() =>
                  navigate('/products', {
                    state: {
                      displayTemplates: true,
                    },
                  })
                }
                className="btn btn-primary-outlined rounded-3xl"
              >
                {t('add')}
              </button>
            </div>
          ) : (
            <div className="lg:flex">
              <div className="lg:flex-1 w-full">
                <h2 className="lg:text-xl text-center lg:text-left font-bold my-4">
                  {t('checkout-areas.available')}
                </h2>
                <div className="h-96 overflow-auto rounded-xl bg-white px-2 lg:px-0">
                  {availableProducts?.map((product: Product) => (
                    <label
                      key={product._id}
                      htmlFor={product._id}
                      className="group cursor-pointer flex items-center py-4 px-8 border justify-between hover:border-primary border-gray-200 bg-white my-2 rounded-2xl w-full lg:w-5/6"
                    >
                      <div className="flex flex-row items-center">
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          size="lg"
                          className="text-middleGrey group-hover:text-primary"
                        />
                        <input
                          className="hidden"
                          type="checkbox"
                          id={product._id}
                          name={product._id}
                          disabled={false}
                          checked={checkedProducts.includes(product._id)}
                          onChange={(e) => e.target.checked}
                          onClick={(e) => handleSelect(e, product)}
                        />
                        <div className="ml-4 mr-2 text-center">
                          <Emoji emoji={product.emoji} size={36} />
                        </div>
                        <div className="mx-2 text-left">
                          <span>
                            {product.translations?.filter(
                              (t: any) =>
                                t.languageCode === i18n.language.slice(0, 2),
                            ).length
                              ? product.translations.filter(
                                  (t: any) =>
                                    t.languageCode ===
                                    i18n.language.slice(0, 2),
                                )[0].title
                              : product.name}
                          </span>
                        </div>
                      </div>
                      <span className="font-satoshi">
                        {displayPrice(
                          product.sellingPrice,
                          getCurrencyInfo(currentWorkspace.currency).symbol,
                          { thousandSeparator: ' ' },
                        )}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="my-4 lg:mx-4 h-1 w-full lg:h-96 lg:w-1 bg-gray-100" />
              <div className="lg:flex-1 w-full">
                <h2 className="lg:text-xl text-center lg:text-left font-bold my-4">
                  {t('checkout-areas.selected')}
                </h2>
                <div className="h-96 overflow-auto rounded-xl bg-white px-2 lg:px-0">
                  {checkedProducts.map((caProduct: Product, index: number) => (
                    <div key={index}>
                      <div className="flex items-center py-4  border border-gray-200 bg-white my-2 rounded-2xl w-full justify-between">
                        <div className="flex flex-col mx-4 justify-center items-center ">
                          <div>
                            {index !== 0 && (
                              <FontAwesomeIcon
                                className="text-middleGrey hover:text-primary cursor-pointer"
                                icon={faChevronCircleUp}
                                size="lg"
                                onClick={() =>
                                  onChangeOrderProduct(index, index - 1)
                                }
                              />
                            )}
                          </div>
                          <div>
                            {index !== checkedProducts.length - 1 && (
                              <FontAwesomeIcon
                                className="text-middleGrey hover:text-primary cursor-pointer"
                                icon={faChevronCircleDown}
                                size="lg"
                                onClick={() =>
                                  onChangeOrderProduct(index, index + 1)
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-between text-left">
                          <div className="flex flex-row items-center">
                            <div className="mx-2 text-center">
                              <Emoji emoji={caProduct.emoji} size={36} />
                            </div>
                            <div className="mx-2 text-left ">
                              <label
                                htmlFor={caProduct.name}
                                className="product-label"
                              >
                                {caProduct.name}
                              </label>
                            </div>
                          </div>
                          <div className="mx-2 text-right">
                            <span className="font-satoshi">
                              {displayPrice(
                                caProduct.sellingPrice,
                                getCurrencyInfo(currentWorkspace.currency)
                                  .symbol,
                                { thousandSeparator: ' ' },
                              )}
                            </span>
                          </div>
                        </div>
                        <div className=" pr-4">
                          <Icon
                            className="text-middleGrey hover:text-red-500 cursor-pointer"
                            fontSize="small"
                            component={CancelIcon}
                            onClick={() => handleDeleteProduct(caProduct._id)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-end my-4">
            <button
              title={t('cancel')}
              className="btn rounded-3xl btn-primary-outlined"
              onClick={() => handleCancel()}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              disabled={
                !checkedProducts?.length ||
                !productsData?.length ||
                isLoadingCheckoutArea
              }
              title={t('confirm')}
              customClass={'btn rounded-3xl btn-primary flex'}
            >
              {!isLoadingCheckoutArea ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </SubmitButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewCheckoutArea;
