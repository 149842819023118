import React, { useEffect, useRef, useState } from 'react';
import Button from '../../button/button.component';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { displayPrice, getCurrencyInfo } from '../../../utils/currency';
import { useAuth } from '../../../contexts/authContext';
import { Emoji } from 'emoji-mart';
import AddProductModal from '../../modals/add-product-modal.component';
import EditProductModal from '../../modals/edit-product-modal.component';
import Validation from '../../../utils/validation';
import Input from '../../input/input.component';
import TextArea from '../../text-area/text-area.component';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Icon } from '@mui/material';
import _ from 'lodash';
import SubmitButton from '../../submit-button/submit-button.component';
import { useMutation, useQuery } from 'react-query';
import { getCheckoutPage, updateCheckoutPage } from '../../../api/onboarding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { CheckoutAreaPreview } from '../checkout-area/checkout-area-preview/checkout-area-preview.component';
import Loader from '../../loader/loader.component';
import { deleteProduct, updateProduct } from '../../../api/products';
import { Product } from '../../../types/product.model';
import toast from 'react-hot-toast';
import { createCheckoutAreaProduct } from '../../../api/checkoutAreas';
import { CheckoutAreaProduct } from '../../../types/checkout-area-product.model';
import SelectProductsModal from '../../modals/select-products-modal.component';
import useTrackOnboardingWithMixpanel from '../../../hooks/useTrackOnboardingWithMixpanel';

type InitCheckoutAreaProps = {
  readonly setActiveTab: Function;
  readonly activeTab: number;
};

function InitCheckoutArea({ activeTab, setActiveTab }: InitCheckoutAreaProps) {
  const { t } = useTranslation();
  const productsListRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      headline: '',
      description: '',
    },
  });

  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  useEffect(() => {
    trackOnboardingEvent('Onboarding - Step 3. CREATE_STORE - Start');
  }, []);

  const { currentWorkspace, currentUser } = useAuth();
  const currency = getCurrencyInfo(currentWorkspace?.currency);

  const [displayEditProductModal, setDisplayEditProductModal] =
    useState<boolean>(false);
  const [displayAddProductModal, setDisplayAddProductModal] =
    useState<boolean>(false);
  const [displaySelectProductsModal, setDisplaySelectProductsModal] =
    useState<boolean>(false);

  const [editedProduct, setEditedProduct] = useState<any>(null);

  const {
    data: checkoutArea,
    refetch: refetchCheckoutArea,
    isLoading: isLoadingCheckoutArea,
  } = useQuery(
    ['onboardingCheckoutArea'], // @ts-ignore
    () => getCheckoutPage(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentWorkspace,
      onSuccess: (data: any) => {
        reset({ headline: data.headline, description: data.description });
      },
    },
  );

  useEffect(() => {
    refetchCheckoutArea();
  }, [activeTab]);

  const { mutate: updateCurrentCheckoutPage, isLoading: isLoadingCreation } =
    useMutation(
      (data: any) =>
        updateCheckoutPage(currentUser.accessToken, currentWorkspace._id, data),
      {
        enabled: false,
        onSuccess: () => {
          setActiveTab(4);
          trackOnboardingEvent(`Onboarding - Step 3. CREATE_STORE - Success`);
        },
      },
    );

  const onFormSubmit = (data: any) => {
    const payload = {
      headline: data.headline,
      description: data.description,
    };
    handleOnSubmit(payload);
  };

  const handleOnSubmit = async (payload: any) => {
    await updateCurrentCheckoutPage(payload);
  };

  const handleOnSelectProductMethod = (product: any) => {
    if (!product) {
      setDisplayAddProductModal(true);
    } else {
      createProduct(product);
      setDisplaySelectProductsModal(false);
    }
  };

  useEffect(() => {
    if (!displayEditProductModal) {
      setEditedProduct(null);
    }
  }, [displayEditProductModal]);

  const editProduct = (product: any) => {
    setEditedProduct(product);
    setDisplayEditProductModal(true);
  };

  const addProduct = () => {
    setDisplaySelectProductsModal(true);
  };

  const onProductAdded = (product: any) => {
    if (!product) {
      setDisplayAddProductModal(false);
      return;
    }
    createProduct(product);
  };

  const { mutate: updateCurrentProduct } = useMutation(
    (data: Product) =>
      updateProduct(
        currentUser.accessToken,
        currentWorkspace._id,
        data._id,
        data,
      ),
    {
      onSuccess: () => {
        setDisplayEditProductModal(false);
        refetchCheckoutArea();
      },
      onError: (error: any) => {
        console.error(error);
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  const { mutate: createProduct } = useMutation(
    (data: CheckoutAreaProduct) =>
      checkoutArea &&
      createCheckoutAreaProduct(
        currentUser.accessToken,
        currentWorkspace._id,
        checkoutArea._id,
        data,
      ),
    {
      onSuccess: () => {
        setDisplayAddProductModal(false);
        refetchCheckoutArea();
      },
      onError: (error: any) => {
        console.error(error);
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  const onProductEdited = (product: any) => {
    updateCurrentProduct(product);
  };

  const onDeleteProduct = async (productId: string) => {
    await deleteCurrentProduct(productId);
  };

  const { mutate: deleteCurrentProduct } = useMutation(
    (productId: string) =>
      deleteProduct(currentUser.accessToken, currentWorkspace._id, productId),
    {
      onSuccess: () => {
        refetchCheckoutArea();
      },
      onError: (error: any) => {
        console.error(error);
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  const watchedHeadline = watch('headline');
  const watchedDescription = watch('description');

  return (
    <div className="flex">
      {displayAddProductModal && (
        <AddProductModal
          displayModal={displayAddProductModal}
          setDisplayModal={setDisplayAddProductModal}
          onSuccess={onProductAdded}
        />
      )}

      {displayEditProductModal && (
        <EditProductModal
          product={editedProduct}
          displayModal={displayEditProductModal}
          setDisplayModal={setDisplayEditProductModal}
          onSuccess={onProductEdited}
        />
      )}

      <SelectProductsModal
        displayProductTemplates={displaySelectProductsModal}
        setDisplayProductTemplates={setDisplaySelectProductsModal}
        onSelect={handleOnSelectProductMethod}
      />

      <form
        id="checkoutPage"
        className="pr-6"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <div className="p-4 rounded-3xl mb-3">
          <h2 className="text-left text-primary text-xl font-bold leading-12">
            {t('onboarding.checkoutAreas.setup')}
          </h2>
          <div className="text-left text-grey">
            {t('onboarding.checkoutAreas.setup_dsc')}
          </div>
          <div className="mb-16">
            {isLoadingCheckoutArea ? (
              <div className="w-full min-h-[500px] flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-2 mt-4 gap-4">
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        key="headline"
                        label={t('listings.headline')}
                        size="small"
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        value={value}
                        errors={[_.get(errors, 'headline.message')]}
                      />
                    )}
                    name={'headline'}
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <TextArea
                        key="description"
                        label={t('listings.description')}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        value={value}
                        errors={[_.get(errors, 'description.message')]}
                      />
                    )}
                    name={'description'}
                  />
                </div>
                <div className="border border-t w-full border-lightGrey mt-6"></div>
                <div className="mt-2">
                  <div className="mb-3 flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <h6 className="text-md font-bold text-left text-greyText">
                        {t('onboarding.products.select_products')}
                      </h6>
                      <div className="font-bold text-md text-grey">
                        {checkoutArea?.checkoutAreaProducts.length ===
                        0 ? null : (
                          <span>
                            ({checkoutArea?.checkoutAreaProducts.length})
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() => addProduct()}
                      className="cursor-pointer btn btn-primary-outlined"
                    >
                      {t('add')}
                      <FontAwesomeIcon className="ml-2" icon={faAdd} />
                    </div>
                  </div>

                  <div
                    ref={productsListRef}
                    className="grid grid-cols-3 overflow-auto bg-lightblue border rounded-xl border-lightGrey px-2 flex-grow"
                  >
                    {checkoutArea?.checkoutAreaProducts?.map(
                      (checkoutAreaProduct: any, index: number) => (
                        <div key={index.toString()}>
                          <div className="outline outline-1 rounded-xl outline-grey min-h-[80px] bg-white m-2">
                            <div className="flex justify-between items-center font-satoshi">
                              <div className="flex flex-row justify-between w-full items-center min-h-[180px]">
                                <div className="flex flex-1 flex-col justify-between p-3">
                                  <div className="flex justify-between items-center pb-2">
                                    <div className="flex items-center justify-center">
                                      {!checkoutAreaProduct.product
                                        .emoji ? null : (
                                        <Emoji
                                          emoji={
                                            checkoutAreaProduct.product.emoji
                                          }
                                          size={32}
                                        />
                                      )}
                                    </div>
                                    {displayPrice(
                                      checkoutAreaProduct.product.sellingPrice,
                                      currency.symbol,
                                      {
                                        thousandSeparator: ' ',
                                      },
                                    )}
                                  </div>
                                  <h3 className="w-full leading-[18px] text-sm font-mono text-[#333] text-left">
                                    {checkoutAreaProduct.product.name}
                                  </h3>
                                  <p className="text-textDesc text-xs text-left line-clamp">
                                    {checkoutAreaProduct.product.description}
                                  </p>
                                  <div className="flex items-center justify-evenly mt-2">
                                    <div
                                      onClick={() =>
                                        editProduct({
                                          ...checkoutAreaProduct.product,
                                          index: index,
                                        })
                                      }
                                      className="flex justify-center items-center w-10 h-10 hover:bg-gray-300 rounded-full text-gray-500 cursor-pointer"
                                    >
                                      <Icon
                                        className="text-primary"
                                        fontSize="small"
                                        component={Edit}
                                      />
                                    </div>
                                    <div
                                      onClick={() =>
                                        onDeleteProduct(
                                          checkoutAreaProduct.product._id,
                                        )
                                      }
                                      className="icon ml-3 flex items-center justify-center hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer"
                                    >
                                      <Icon
                                        className="h-10 w-10 text-red-500"
                                        fontSize="small"
                                        component={RemoveCircleOutline}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="fixed bottom-0 left-0 z-10 items-center py-1 border-t border-grey w-full bg-white">
            <div className="px-4 flex justify-center">
              <Button
                className="btn btn-primary-outlined"
                onClick={() => {
                  trackOnboardingEvent(
                    'Onboarding - Go back to step 2 IMPORT_PROPERTIES from Step 3 CREATE_STORE',
                  );
                  setActiveTab(2);
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: 15 }}
                  icon={faArrowLeft}
                />
                {t('previous')}
              </Button>
              <SubmitButton
                title={t('next')}
                customClass="btn btn-primary flex"
              >
                {!isLoadingCreation ? (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faArrowRight}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </SubmitButton>
            </div>
          </div>
        </div>
      </form>
      <div className="relative border-l-1 border-lightGrey">
        <div className="flex flex-col gap-3 sticky px-6 py-6 top-24">
          <div className="mt-3">
            <span className="px-3 py-2 bg-lightPrimary text-primary rounded-2xl">
              {t('preview')}
            </span>
          </div>
          <div className="shadow-xl relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
            <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
            <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
            <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
            <div className="rounded-[2rem] overflow-auto w-[272px] h-[572px] bg-white ">
              {isLoadingCheckoutArea ? (
                <Loader />
              ) : (
                <CheckoutAreaPreview
                  title={watchedHeadline}
                  description={watchedDescription}
                  checkoutAreaProducts={checkoutArea?.checkoutAreaProducts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitCheckoutArea;
