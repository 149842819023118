import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import Input from '../input/input.component';
import { useAuth } from '../../contexts/authContext';

import { useMutation } from 'react-query';
import SubmitButton from '../submit-button/submit-button.component';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { integrateBeds24 } from '../../api/channelManagers';

type IntegrateBeds24ModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccessIntegration: Function;
};

export const IntegrateBeds24Modal = ({
  displayModal,
  setDisplayModal,
  onSuccessIntegration,
}: IntegrateBeds24ModalProps) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentUser } = useAuth();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    // reset: resetForm,
  } = useForm();
  const backendErrors = '';

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const { mutate: integrate, isLoading } = useMutation(
    (code: any) =>
      integrateBeds24(currentUser.accessToken, currentWorkspace._id, code),
    {
      onSuccess: async () => {
        await onSuccessIntegration();
        setDisplayModal(false);
      },
      onError: (error: any) => {
        if (error.type === 'BEDS24.ERROR.AUTH.CODE.NOT_FOUND') {
          setError('code', {
            type: 'manual',
            message: t('errors.beds24.code_not_found'),
          });
        }
      },
    },
  );

  const submit = (data: Record<string, any>) => {
    const code = data.code;
    integrate(code);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[700px] lg:min-h-[550px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('beds24.integrate_title')}
              </h3>
            </div>
            <div className="px-10">
              <div className="py-6 text-left">
                <ul className="text-gray-600 flex flex-col gap-2 rounded-2xl border border-lightGrey p-6">
                  <li>
                    {t('beds24.tutorial.step_1')}
                    <a
                      className="bg-lightGrey rounded-2xl text-primary px-2 py-1 cursor-pointer"
                      href="https://beds24.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      beds24.com <span className="text-primary">🔗</span>
                    </a>
                  </li>
                  <li>{t('beds24.tutorial.step_2')}</li>
                  <li>{t('beds24.tutorial.step_3')}</li>
                  <li>{t('beds24.tutorial.step_4')}</li>
                  <li>{t('beds24.tutorial.step_5')}</li>
                  <li>{t('beds24.tutorial.step_6')}</li>
                </ul>
              </div>
              <div className="flex w-full gap-4 my-6">
                <div className="w-full">
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue=""
                      render={({ field: { onBlur, onChange, value } }: any) => (
                        <Input
                          type="text"
                          key="code"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label={`${capitalizeFirstLetter(
                            t('beds24.invitation_code'),
                          )} *`}
                          errors={[
                            _.get(errors, 'code.message'),
                            ...backendErrorsForAttribute('code'),
                          ]}
                        />
                      )}
                      name={'code'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('next')}
                disabled={isLoading}
                customClass="btn btn-primary rounded-3xl"
              />
            </div>
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};
