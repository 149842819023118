import { api } from '../utils/request';
import qs from 'qs';

export const getChannelManagersAvailableInCozyUp = async (
  authToken: string,
  filters?: {},
) => {
  try {
    const res = await api.get(`/channel-manager-types`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: { ...filters },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};
export const getChannelManagerIntegrations = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/channel-managers`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getCalryIntegrations = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/calry/get-integrations`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getCalryProperties = async (
  authToken: string,
  workspaceId: string,
  integrationAccountId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/calry/${integrationAccountId}/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const integrateBeds24 = async (
  authToken: string,
  workspaceId: string,
  code: string,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/beds24/integrate`,
      { code: code },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const updateMessageScheduler = async (
  authToken: string,
  workspaceId: string,
  channelManagerConnection: string,
  data: string,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/channel-manager-connections/${channelManagerConnection}/message-schedulers`,
      data,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getChannelManagerConnectionByHomeId = async (
  authToken: string,
  workspaceId: string,
  homeId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/homes/${homeId}/channel-manager-connections`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const integrateSmoobu = async (
  authToken: string,
  workspaceId: string,
  apiKey: string,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/smoobu/integrate`,
      { apiKey: apiKey },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const integrateSuperHote = async (
  authToken: string,
  workspaceId: string,
  data: {
    apiKey: string;
    websiteKey: string;
  },
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/superhote/integrate`,
      { apiKey: data.apiKey, websiteKey: data.websiteKey },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const integrateCalry = async (
  authToken: string,
  workspaceId: string,
  integrationDefinitionKey: string,
  redirectUrl: string,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/calry/integrate/${integrationDefinitionKey}`,
      {
        redirectUrl: redirectUrl,
      },

      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const requestBookingSyncAuth = async (
  authToken: string,
  workspaceId: string,
  redirectUri: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/booking-sync/request-auth`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          redirectUri: redirectUri,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const validateBookingSyncAuth = async (
  authToken: string,
  workspaceId: string,
  code: string,
  redirectUri: string,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/booking-sync/auth`,
      { code: code },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          redirectUri: redirectUri,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const validateHospitableAuth = async (
  authToken: string,
  workspaceId: string,
  code: string,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/channel-managers/hospitable/auth`,
      { code: code },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getBeds24Properties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/beds24/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getSuperHoteProperties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/superhote/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getSmoobuProperties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/smoobu/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getHospitableProperties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/hospitable/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const getBookingSyncProperties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/channel-managers/booking-sync/get-properties`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};
