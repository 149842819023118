import { api } from '../utils/request';

export const sendEmail = async (data: {
  to: [];
  templateId: number;
  params?: {};
}) => {
  try {
    const res = await api.post(`/sendinblue/email`, data);
    return res;
  } catch (err: any) {
    console.log(err);
  }
};
