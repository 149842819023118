import React, { useEffect } from 'react';
import './sign-up.styles.scss';
import SignUpForm from './sign-up-form/sign-up-form.component';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/back-button/back-button.component';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import Language from '../../components/language/language.component';

const SignUp = () => {
  const { currentUser, apiUser } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser && apiUser) {
      navigate('/');
    } else {
      trackMixpanelEvent('Sign up - Page view', {});
    }
  }, []);

  return (
    <div className="min-h-screen relative flex items-center ">
      <BackButton />
      <div className="flex w-full h-full">
        <div className="flex flex-1">
          <div className="relative mx-auto flex flex-col justify-center items-center mt-6">
            <a
              href="https://cozyup.com"
              className="logo-wrapper-account w-inline-block"
              style={{ borderRadius: 100 }}
            >
              <img
                src="https://cozyup-public.s3.eu-west-3.amazonaws.com/logo-square-clear-blue.png"
                loading="lazy"
                alt=""
                className="account-icon"
              />
            </a>

            <div className="flex flex-col items-center justify-center mb-6">
              <h5>{t('auth.welcome')}</h5>
              <p className="mt-4 font-semibold lg:text-lg text-[#5a6978]">
                {t('auth.sign-up-description')}
              </p>
              <Language />
            </div>
            <div className="mt-6 w-full px-6">
              <SignUpForm />
            </div>
            <div className="mt-24 mb-4 lg:mb-0 text-grey text-md flex justify-center w-full  text-center">
              {t('copyright', { year: currentYear })}
            </div>
          </div>
        </div>

        <div className="hidden lg:flex flex-1 h-full">
          <div className="register-blue-block w-full rounded-l-[32px]">
            <div className="w-layout-grid grid-account-features">
              <div className="card-register">
                <div>
                  <h5 className="club-subheading gradient-one salt-type text-3xl">
                    {t('auth.join-us')}
                  </h5>
                  <p className="body-medium-white text-gray-300 text-lg">
                    {t('auth.join-us-description')}
                  </p>
                </div>
              </div>
              <div className="card-register">
                <div>
                  <h5 className="club-subheading gradient-one salt-type text-3xl">
                    {t('auth.join-us2')}
                  </h5>
                  <p className="body-medium-white text-gray-300 text-lg">
                    {t('auth.join-us-description2')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
