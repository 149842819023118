import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../components/submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../utils/validation';
import { updateUser } from '../../../api/users';
import { useAuth } from '../../../contexts/authContext';
import { useMutation } from 'react-query';
import { User } from '../../../types/user.model';
import Input from '../../../components/input/input.component';
import { getBackendErrorsByAttribute } from '../../../utils/helpers';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { formatDateToLocaleDateString } from '../../../utils/date';
import {
  acceptAccountInvitation,
  declineAccountInvitation,
} from '../../../api/invitation';
import useGetInvitations from '../../../hooks/useGetInvitations';

type ProfilSettingsProps = {
  setSubRoute: any;
};

const ProfilSettings = ({ setSubRoute }: ProfilSettingsProps) => {
  const { t } = useTranslation();
  const { apiUser, currentUser, currentWorkspace, refreshAccounts } = useAuth();
  const [disabledProfile, setDisabledProfile] = React.useState(true);
  const backendErrors = '';
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSubRoute(t('header.profile'));
  }, []);

  const { mutate: updateCurrentUser } = useMutation(
    (data: User) =>
      apiUser?.data?._id &&
      updateUser(currentUser.accessToken, apiUser?.data?._id, data),
  );

  const { pendingInvitations, refetch: refetchInvitations } = useGetInvitations(
    { status: 'pending' },
  );

  const { mutate: declineInvit } = useMutation(
    (invitationId: string) =>
      declineAccountInvitation(
        currentUser.accessToken,
        currentWorkspace?._id,
        invitationId,
      ),
    {
      onSuccess: () => {
        refetchInvitations();
        refreshAccounts();
      },
    },
  );

  const { mutate: acceptInvit } = useMutation(
    (invitationId: string) =>
      acceptAccountInvitation(
        currentUser.accessToken,
        currentWorkspace?._id,
        invitationId,
      ),
    {
      onSuccess: () => {
        refetchInvitations();
        refreshAccounts();
      },
    },
  );

  const submitProfile = async (data: any) => {
    updateCurrentUser({
      firstName: data.firstname,
      lastName: data.lastname,
    });
    setDisabledProfile(true);
  };

  const handleCancelEditProfile = () => {
    reset();
    setDisabledProfile(true);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <div className="mt-6 mb-12 lg:m-0  flex flex-col items-center justify-center">
      <div className="mt-6 lg:mt-0 w-full">
        <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
          <h5 style={{ textAlign: 'left', margin: 0 }}>
            {t('settings.profile')}
          </h5>
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisabledProfile(false)}
          >
            {t('edit')}
          </div>
        </div>
        <form onSubmit={handleSubmit(submitProfile)}>
          <div className="lg:pr-4 lg:w-1/2">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={apiUser?.data?.email}
              render={({ field: { onChange, value } }: any) => (
                <Input
                  disabled={true}
                  key="email"
                  label={t('settings.email')}
                  size="small"
                  onChange={onChange}
                  value={value}
                  errors={[
                    _.get(errors, 'email.message'),
                    ...backendErrorsForAttribute('email'),
                  ]}
                />
              )}
              name={'email'}
            />
          </div>
          <div className="lg:flex justify-between">
            <div className="lg:pr-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={apiUser?.data?.firstName}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    disabled={disabledProfile}
                    key="firstname"
                    label={t('settings.firstname')}
                    size="small"
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'firstname.message'),
                      ...backendErrorsForAttribute('firstname'),
                    ]}
                  />
                )}
                name={'firstname'}
              />
            </div>
            <div className="lg:pl-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={apiUser?.data?.lastName}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    disabled={disabledProfile}
                    key="lastname"
                    label={t('settings.lastname')}
                    size="small"
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'lastname.message'),
                      ...backendErrorsForAttribute('lastname'),
                    ]}
                  />
                )}
                name={'lastname'}
              />
            </div>
          </div>
          {disabledProfile ? null : (
            <div className="flex justify-end my-4">
              <button
                title={t('cancel')}
                className="btn rounded-3xl btn-primary-outlined"
                onClick={() => handleCancelEditProfile()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary'}
                style={{ marginLeft: 8 }}
              />
            </div>
          )}
        </form>
        {!pendingInvitations?.length ? null : (
          <div>
            <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
              <h5 className="my-2">{t('settings.pending_invitation')}</h5>
            </div>

            <table className="cozyup table mt-2">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    From <FontAwesomeIcon icon={faSort} />
                  </th>
                  <th>
                    Account <FontAwesomeIcon icon={faSort} />
                  </th>
                  <th>
                    Role <FontAwesomeIcon icon={faSort} />
                  </th>
                  <th>
                    {t('created_at')} <FontAwesomeIcon icon={faSort} />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody className="mt-6">
                {pendingInvitations?.map((invitation: any, i: number) => (
                  <tr className="hover:shadow-none" key={i}>
                    <td>{i + 1}</td>
                    <td>{invitation.from.email}</td>
                    <td>{invitation.account.name}</td>
                    <td>{invitation.role}</td>
                    <td>
                      {formatDateToLocaleDateString(invitation?.createdAt)}
                    </td>
                    <td>
                      <span
                        className="cursor-pointer py-0.5 px-3 btn-ghost-danger text-sm font-semibold rounded-2xl"
                        onClick={() => declineInvit(invitation._id)}
                      >
                        {t('decline')}
                      </span>
                      <span
                        className="cursor-pointer py-0.5 px-3 btn-ghost-success text-sm font-semibold rounded-2xl ml-2"
                        onClick={() => acceptInvit(invitation._id)}
                      >
                        {t('accept')}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilSettings;
