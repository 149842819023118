import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../contexts/authContext';

import { useMutation } from 'react-query';
import { requestBookingSyncAuth } from '../../api/channelManagers';
import Button from '../button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-hot-toast';

type IntegrateBookingSyncModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  redirectUrl?: string;
};

type BookingSyncRequestAuthResponse = {
  url: string;
};

export const IntegrateBookingSyncModal = ({
  displayModal,
  setDisplayModal,
  redirectUrl,
}: IntegrateBookingSyncModalProps) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentUser } = useAuth();

  const REDIRECT_URI: string | undefined =
    redirectUrl || process.env.REACT_APP_BOOKING_SYNC_REDIRECT_URI;

  const handleBookingSyncClick = async () => {
    authRequestBookingSync();
  };

  const copyCode = (code: string) => {
    navigator.clipboard.writeText(code);
    toast.success(t('booking_sync.tutorial.code_copied'));
  };

  const { mutate: authRequestBookingSync, isLoading } = useMutation(
    () =>
      requestBookingSyncAuth(
        currentUser.accessToken,
        currentWorkspace._id,
        REDIRECT_URI as string,
      ),
    {
      onSuccess: (data: BookingSyncRequestAuthResponse) => {
        window.location.href = data.url;
      },
    },
  );

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[500px] lg:min-h-[400px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[200px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('booking_sync.integrate_title')}
              </h3>
            </div>
            <div className="px-10">
              <div className="py-6 text-left">
                <ul className="text-gray-600 flex flex-col gap-2 rounded-2xl border border-lightGrey p-6">
                  <li>
                    {t('booking_sync.tutorial.step_1')}{' '}
                    <a
                      className="bg-lightGrey rounded-2xl text-primary px-2 py-1 cursor-pointer"
                      href="https://app.bookingsync.com/dashboard"
                      target="_blank"
                      rel="noreferrer"
                    >
                      bookingsync.com <span className="text-primary">🔗</span>
                    </a>
                  </li>
                  <li>{t('booking_sync.tutorial.step_2')}</li>
                  <li>
                    {t('booking_sync.tutorial.step_3')}{' '}
                    <span
                      className="bg-lightGrey rounded-2xl px-2 py-1 cursor-pointer text-primary"
                      onClick={() => copyCode('2eec-32b6-6d9f-8e92')}
                    >
                      2eec-32b6-6d9f-8e92{' '}
                      <span className="text-primary">📋</span>
                    </span>
                  </li>

                  <li>{t('booking_sync.tutorial.step_4')}</li>
                  <li>{t('booking_sync.tutorial.step_5')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <Button
                disabled={isLoading}
                className="btn btn-primary rounded-3xl"
                onClick={handleBookingSyncClick}
              >
                {t('next')}
                {!isLoading ? null : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </Button>
            </div>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};
