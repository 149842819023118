import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import _ from 'lodash';
import SubmitButton from '../../../submit-button/submit-button.component';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const AddressCard = ({ home, updateHome }: any) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const backendErrors = '';

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const submit = (data: any) => {
    updateHome({ address: { ...data } });
    setDisabled(true);
  };

  const handleCancelEdit = () => {
    reset();
    setDisabled(true);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };
  return (
    <div className="card mb-4 lg:mr-4 mt-4 ">
      <div className="flex justify-between items-center">
        <h5 style={{ textAlign: 'left', margin: 0 }}>{t('address.address')}</h5>
        <div
          className="btn btn-warning rounded-xl"
          onClick={() => setDisabled(false)}
        >
          {t('edit')}
        </div>
      </div>
      <form onSubmit={handleSubmit(submit)} className="mt-4">
        <div className="flex justify-between">
          <div className="w-1/3">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={home?.address?.streetNumber}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={disabled}
                  key="streetNumber"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.street_number')}
                  errors={[
                    _.get(errors, 'streetNumber.message'),
                    ...backendErrorsForAttribute('streetNumber'),
                  ]}
                />
              )}
              name={'streetNumber'}
            />
          </div>
          <div className="pl-4 flex-1">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={home?.address?.street}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={disabled}
                  key="street"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.street')}
                  errors={[
                    _.get(errors, 'street.message'),
                    ...backendErrorsForAttribute('street'),
                  ]}
                />
              )}
              name={'street'}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/3">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={home?.address?.zipcode}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={disabled}
                  key="zipcode"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.zipcode')}
                  errors={[
                    _.get(errors, 'zipcode.message'),
                    ...backendErrorsForAttribute('zipcode'),
                  ]}
                />
              )}
              name={'zipcode'}
            />
          </div>

          <div className="pl-4 flex-1">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={home?.address?.city}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={disabled}
                  key="city"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.city')}
                  errors={[
                    _.get(errors, 'city.message'),
                    ...backendErrorsForAttribute('city'),
                  ]}
                />
              )}
              name={'city'}
            />
          </div>
        </div>
        <div className="flex">
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={home?.address?.country}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                disabled={disabled}
                key="city"
                size="small"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                label={t('address.country')}
                errors={[
                  _.get(errors, 'country.message'),
                  ...backendErrorsForAttribute('country'),
                ]}
              />
            )}
            name={'country'}
          />
        </div>
        {disabled ? null : (
          <div className="flex justify-end mt-6 mb-2">
            <button
              title={'Cancel'}
              className="btn rounded-3xl btn-primary-outlined"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => handleCancelEdit()}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              title={t('confirm')}
              customClass={'btn rounded-3xl btn-primary'}
              style={{ marginLeft: 8 }}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default AddressCard;
