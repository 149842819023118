import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { useAuth } from '../../contexts/authContext';
import { useQuery } from 'react-query';
import {
  getCalryIntegrations,
  getChannelManagerIntegrations,
} from '../../api/channelManagers';
import ChannelManagerLogo from '../channel-manager-logo/channel-manager-logo.component';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onChoose: Function;
};

export const SelectHomeImportModal = ({
  displayModal,
  setDisplayModal,
  onChoose,
}: Props) => {
  const { t } = useTranslation();
  const { currentUser, currentWorkspace } = useAuth();

  const handleOnChoose = (method: string) => {
    setDisplayModal(false);
    onChoose(method);
  };

  const { data: channelManagerIntegrations } = useQuery(
    ['channelManagerIntegrations', currentWorkspace],
    // @ts-ignore
    () =>
      getChannelManagerIntegrations(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const { data: calryIntegrations } = useQuery(
    ['calryIntegrationsKey', currentWorkspace],
    // @ts-ignore
    () => getCalryIntegrations(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative mx-4 flex flex-col justify-between w-full max-w-[700px] min-h-[500px] lg:min-h-[150px] bg-white mt-16 lg:mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div>
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-xl mb-4">
                {t('listings.method_add')}
              </h5>
            </div>
            <div className="md:px-10 justify-center items-center">
              <div className="flex w-full items-center justify-center gap-1">
                <div className="w-full md:flex flex-wrap py-3">
                  <div className="p-2 md:w-1/3">
                    <div
                      className="group flex flex-col px-4 justify-center items-center cursor-pointer border-1 border-lightGrey rounded-[32px] font-satoshi h-36    hover:border-checkoutPrimary border-[2px]  lg:filter lg:grayscale lg:hover:grayscale-0"
                      onClick={() => handleOnChoose('airbnb_method')}
                    >
                      <ChannelManagerLogo type="AIRBNB" />
                    </div>
                  </div>
                  {channelManagerIntegrations?.length > 0 &&
                    channelManagerIntegrations.map(
                      (integration: any, index: number) => (
                        <div className="p-2 md:w-1/3" key={index}>
                          <div
                            className="group flex flex-col px-4 justify-center items-center cursor-pointer border-1 border-lightGrey rounded-3xl font-satoshi h-36    hover:border-primary border-[2px]  lg:filter lg:grayscale lg:hover:grayscale-0"
                            onClick={() =>
                              handleOnChoose(
                                integration.channelManagerType?.name?.toLowerCase(),
                              )
                            }
                          >
                            <ChannelManagerLogo
                              type={integration.channelManagerType?.name?.toUpperCase()}
                            />
                          </div>
                        </div>
                      ),
                    )}
                  {calryIntegrations?.length > 0 &&
                    calryIntegrations.map((integration: any, index: number) => (
                      <div className="p-2 md:w-1/3" key={index}>
                        <div
                          className="group flex flex-col px-4 justify-center items-center cursor-pointer border-1 border-lightGrey rounded-3xl font-satoshi h-36    hover:border-primary border-[2px]  lg:filter lg:grayscale lg:hover:grayscale-0"
                          onClick={() =>
                            handleOnChoose(integration?.name?.toLowerCase())
                          }
                        >
                          <ChannelManagerLogo
                            type={'CALRY_' + integration?.name?.toUpperCase()}
                          />
                        </div>
                      </div>
                    ))}
                  <div className="p-2 md:w-1/3">
                    <div
                      className="group flex flex-col justify-center px-4 items-center cursor-pointer border-1 border-lightGrey rounded-[32px] font-satoshi h-36  hover:border-primary border-[2px] "
                      onClick={() => handleOnChoose('manual')}
                    >
                      <div className="w-2/5 h-2/5">
                        <FontAwesomeIcon
                          icon={faGear}
                          size="3x"
                          color="#d3d3d3"
                          className="group-hover:text-primary"
                        />
                      </div>
                      <p className="text-middleGrey font-medium group-hover:text-primary text-md">
                        {t('listings.add_manually')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};
