import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="mt-32">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        size={'8x'}
        className="text-primary mb-4"
      />
      <h1 className="text-6xl font-satoshiBold text-nightBlue font-bold mb-6">
        Woops!
      </h1>
      <h2 className="text-xl mb-8">{t('page_not_found')}</h2>
      <button
        onClick={() => navigate('/dashboard')}
        className="mt-8 btn btn-primary-outlined rounded-3xl"
      >
        {t('go_back')}
      </button>
    </div>
  );
};

export default PageNotFound;
