import axios from 'axios';
import i18n from 'i18next';

const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const timeout = 3 * 60 * 1000; // 3min

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Lang: i18n.language.split('-')[0],
};

export const api = axios.create({
  baseURL: `${API_URL}/api/${API_VERSION}`,
  timeout: timeout,
  headers: headers,
});
