import { api } from '../utils/request';

export const getAirbnbByHospitableProperties = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/platforms/hospitable-connect/get-properties/airbnb`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const fetchHasAirbnbByHospitableConnection = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/platforms/hospitable-connect/airbnb/has-connection`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    return false;
  }
};

export const getAirbnbByHospitableChannels = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/platforms/hospitable-connect/airbnb/channels`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const requestHospitableConnectAuth = async (
  authToken: string,
  accountId: string,
  redirectUri: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/platforms/hospitable-connect/request-auth`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          redirectUri: redirectUri,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};
