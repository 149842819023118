export const useRole = () => {
  const hasAuthorization = (
    userCurrentRole: string | null,
    roles: string[],
  ): boolean => {
    if (userCurrentRole) {
      return roles.filter(
        (role: string) => role.toLowerCase() === userCurrentRole.toLowerCase(),
      ).length > 0
        ? true
        : false;
    } else {
      return false;
    }
  };
  return {
    hasAuthorization,
  };
};
