import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';
import Button from '../button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLink,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';

type Beds24TutorialModalType = {
  displayModal: boolean;
  setDisplayModal: Function;
  requestIntegration: Function;
  isLoadingRequestIntegration: boolean;
};

export const Beds24TutorialModal = ({
  displayModal,
  setDisplayModal,
  requestIntegration,
  isLoadingRequestIntegration,
}: Beds24TutorialModalType) => {
  const { t } = useTranslation();

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[770px] lg:min-h-[630px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('beds24.before_beginning')}
              </h3>
            </div>
            <div className="px-6">
              <div className="py-6 text-left">
                <ul className="text-gray-600 flex flex-col ">
                  <li className="border border-lightGrey p-4 rounded-t-2xl ">
                    <span className="text-primary text-2xl mr-4">1.</span>
                    {t('beds24.tutorial.step_1')}
                    <a
                      className="ml-4 border border-primary rounded-xl text-primary px-3 py-1 cursor-pointer"
                      href="https://beds24.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      beds24.com{' '}
                      <FontAwesomeIcon
                        color="#7e82e5"
                        className="ml-2"
                        icon={faExternalLink}
                        size="1x"
                      />
                    </a>
                  </li>
                  <li className="border border-lightGrey p-4">
                    <span className="text-primary text-2xl mr-4">2.</span>
                    {t('beds24.tutorial.step_2')}
                  </li>
                  <li className="border border-lightGrey p-4">
                    <span className="text-primary text-2xl mr-4">3.</span>
                    {t('beds24.tutorial.step_3')}
                  </li>
                  <li className="border border-lightGrey p-4">
                    <span className="text-primary text-2xl mr-4">4.</span>{' '}
                    {t('beds24.tutorial.step_4')}
                  </li>
                  <li className="border border-lightGrey p-4">
                    <span className="text-primary text-2xl mr-4">5.</span>
                    {t('beds24.tutorial.step_5')}
                  </li>
                  <li className="border border-lightGrey p-4 rounded-b-2xl ">
                    <span className="text-primary text-2xl mr-4">6.</span>
                    {t('beds24.tutorial.step_6')}
                  </li>
                </ul>
                <div>{/* TODO: Ajouter lien vers le helpdesk */}</div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <Button
                disabled={isLoadingRequestIntegration}
                onClick={() => requestIntegration()}
                className="btn btn-primary rounded-3xl"
              >
                {t('beds24.im_ready')}
                {!isLoadingRequestIntegration ? null : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </Button>
            </div>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};
