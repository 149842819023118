import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum JobStatus {
  // eslint-disable-next-line no-unused-vars
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface JobPayload {
  type: string;
  id: string;
  status: JobStatus;
  callback: () => void;
}
export interface JobState {
  jobs: Record<string, JobStatus>;
}

const initialState: JobState = {
  jobs: {},
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJob: (state, action: PayloadAction<JobPayload>) => {
      state.jobs[action.payload.id] = action.payload.status;
    },
    removeJob: (state, action: PayloadAction<string>) => {
      delete state.jobs[action.payload];
    },
  },
});

export default jobSlice;
