import { HelpOutline } from '@mui/icons-material';
import { Icon, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { updateMessageScheduler } from '../../../../api/channelManagers';

import { useAuth } from '../../../../contexts/authContext';
import Loader from '../../../loader/loader.component';
import { ToggleInput } from '../../../toggle/toggle-input.component';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import { ChannelManagerConnection } from '../../../../types/channel-manager-connection.model';
import { AutomaticMessageScheduler } from '../../../../types/automatic-message-schedulers.model';
import AutomaticMessageItem from '../../../automatic-message-item/automatic-message-item.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import ChannelManagerLogo from '../../../channel-manager-logo/channel-manager-logo.component';

type HomeAutomationsProps = {
  channelManagerConnection: any;
  isLoading: boolean;
  isFetching: boolean;
  refresh: any;
};

const HomeAutomations: React.FC<HomeAutomationsProps> = ({
  channelManagerConnection,
  isLoading,
  isFetching,
  refresh,
}): React.ReactElement => {
  const [displayTutorial, setDisplayTutorial] = useState(false);
  const [connection, setConnection] = useState<ChannelManagerConnection>(
    channelManagerConnection,
  );

  const { t } = useTranslation();

  const { currentUser, currentWorkspace } = useAuth();

  const { mutate: mutateMessageScheduler } = useMutation(
    (data: any) =>
      updateMessageScheduler(
        currentUser.accessToken,
        currentWorkspace._id,
        channelManagerConnection._id,
        data,
      ),
    {
      onSuccess: () => {
        refresh();
      },
    },
  );

  const handleChangeMessageAutoState = () => {
    setConnection((prev: any) => {
      const updatedSchedulerState = {
        ...prev,
        automaticMessagesEnabled: !prev.automaticMessagesEnabled,
      };
      if (
        updatedSchedulerState.automaticMessagesEnabled === true &&
        channelManagerConnection?.channelManagerType?.name === 'SMOOBU'
      ) {
        setDisplayTutorial(true);
      }
      mutateMessageScheduler(updatedSchedulerState);
      return updatedSchedulerState;
    });
  };

  const copyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success(t('url_copied'));
  };

  useEffect(() => {
    setConnection(channelManagerConnection);
  }, [channelManagerConnection]);

  return (
    <>
      <div className="card p-6 block mb-4 lg:mr-4 mt-4">
        {isLoading && isFetching && !channelManagerConnection ? (
          <Loader />
        ) : (
          !isEmpty(channelManagerConnection) && (
            <>
              <AlertBeforeAction
                isVisible={displayTutorial}
                setIsVisible={setDisplayTutorial}
                title={t('smoobu.webhook.title')}
                successLabel={t('done')}
                cancelLabel={null}
                successAction={() => {
                  setDisplayTutorial(false);
                }}
              >
                <div className="flex flex-col gap-6 justify-start px-4 py-6 text-left">
                  <div className="text-md">
                    {t('smoobu.webhook.step_1')}{' '}
                    <div className="flex justify-center mt-3">
                      <a
                        className="bg-lightGrey rounded-2xl text-primary px-2 py-1 cursor-pointer text-[12px]"
                        href="https://login.smoobu.com/fr/settings/overview"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('smoobu.tutorial.settings_smoobu')}{' '}
                        <span className="text-primary">🔗</span>
                      </a>
                    </div>
                  </div>
                  <div className="text-md">{t('smoobu.webhook.step_2')}</div>
                  <div className="text-md">
                    {t('smoobu.webhook.step_3')}{' '}
                    <div className="mt-3">
                      <span
                        className="bg-lightGrey rounded-2xl px-2 py-1 cursor-pointer text-primary md:whitespace-nowrap text-[12px]"
                        onClick={() =>
                          copyUrl(
                            '     https://cozy-up-api-prod.herokuapp.com/api/v1.0/smoobu/webhook',
                          )
                        }
                      >
                        https://cozy-up-api-prod.herokuapp.com/api/v1.0/smoobu/webhook
                        <span className="text-primary ml-1">📋</span>
                      </span>
                    </div>
                  </div>
                </div>
              </AlertBeforeAction>

              <div className="mb-8 flex justify-start items-start">
                <div className="w-full flex items-end justify-between">
                  <h5 className="mr-3">
                    {t('listings.automations')}
                    <Tooltip
                      title={t('automatic_messages.enable_tooltip')}
                      className="mr-2"
                      enterTouchDelay={0}
                    >
                      <Icon
                        className="icon text-gray-300 cursor-pointer opacity-75 ml-1"
                        component={HelpOutline}
                      />
                    </Tooltip>
                  </h5>
                  <ToggleInput
                    enabled={connection?.automaticMessagesEnabled}
                    setEnabled={() => handleChangeMessageAutoState()}
                  />
                </div>
              </div>
              {connection?.automaticMessagesEnabled && (
                <>
                  <div className="flex gap-2 justify-between items-center p-2">
                    <div className="w-2/3" />
                    <div className="w-1/3 flex items-center justify-around gap-4 self-start">
                      <div className="flex justify-center items-center">
                        <div className="h-5 w-16 flex items-center mr-1">
                          <ChannelManagerLogo
                            type={connection.channelManagerType.name}
                          />
                        </div>
                      </div>
                      <div className="inline-flex items-center">
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="mr-1"
                          size="1x"
                        />
                        <span className="mr-1">SMS</span>
                      </div>
                    </div>
                  </div>
                  {connection?.automaticMessagesSchedulers?.length > 0 &&
                    connection?.automaticMessagesSchedulers
                      ?.sort((a: any, b: any) => {
                        // Priorité au type 'after_reservation'
                        if (
                          a.type === 'after_reservation' &&
                          b.type !== 'after_reservation'
                        ) {
                          return -1;
                        } else if (
                          a.type !== 'after_reservation' &&
                          b.type === 'after_reservation'
                        ) {
                          return 1;
                        }

                        // Tri par 'delay' si les types sont identiques
                        return a.delay - b.delay;
                      })
                      .map(
                        (
                          messageScheduler: AutomaticMessageScheduler,
                          index,
                        ) => (
                          <AutomaticMessageItem
                            index={index}
                            messageScheduler={messageScheduler}
                            key={messageScheduler._id}
                            mutateMessageScheduler={mutateMessageScheduler}
                            setConnection={setConnection}
                            // connection={connection}
                          />
                        ),
                      )}
                </>
              )}
            </>
          )
        )}
      </div>
    </>
  );
};

export default HomeAutomations;
