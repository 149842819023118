import React from 'react';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export const AirbnbConnectedAccount = ({ airbnbChannels }: any) => {
  const { t } = useTranslation();
  return (
    <div className="border-t border-grey mt-4">
      <div className="w-full">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg py-2 text-left text-md font-medium text-dark focus:outline-none ">
                  <span>
                    {t('connected_account_count', {
                      count: airbnbChannels.length,
                    })}
                  </span>

                  <FontAwesomeIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-primary`}
                    color="#b6b9ce"
                    icon={faChevronUp}
                    size="7x"
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="pb-2 pt-4 text-sm text-gray-500">
                  {airbnbChannels.map((channel: any, index: string) => (
                    <div
                      key={index}
                      className="p-3 rounded-2xl w-full flex items-center justify-between gap-2 border-2xl bg-lightGrey"
                    >
                      <div className="flex gap-3 items-center">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={channel.picture}
                          alt=""
                        />
                        <p>{channel.name}</p>
                      </div>
                      <div>
                        <span className="border border-green text-green rounded-3xl px-2 py-1">
                          {t('connected')}
                        </span>
                      </div>
                    </div>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
};
