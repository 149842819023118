import React from 'react';
import './submit-button.styles.scss';
import Loader from '../loader/loader.component';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  customClass?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const SubmitButton = ({
  title,
  customClass,
  disabled,
  children = null,
  isLoading = false,
  ...props
}: Props) => {
  return (
    <label
      htmlFor="submit-btn"
      className={`${customClass} ${disabled ? 'submit-disabled' : ''}`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <input
          id="submit-btn"
          style={{ color: 'red !important' }}
          type="submit"
          value={title}
          {...props}
          className="cursor-pointer"
        />
      )}
      <div>{children}</div>
    </label>
  );
};

SubmitButton.defaultProps = {
  customClass: 'btn btn-primary',
  disabled: false,
};

export default SubmitButton;
