import React from 'react';
import OnClickOut from '../../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';
import { getBackendErrorsByAttribute } from '../../../utils/helpers';
import Input from '../../input/input.component';
import { useAuth } from '../../../contexts/authContext';
import { useMutation } from 'react-query';
import SubmitButton from '../../submit-button/submit-button.component';
import Validation from '../../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { integrateSuperHote } from '../../../api/channelManagers';
import useLocalStorage from '../../../hooks/useLocalStorage';

type IntegrateSuperHoteModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccessIntegration: Function;
};

const IntegrateSuperHoteModal = ({
  displayModal,
  setDisplayModal,
  onSuccessIntegration,
}: IntegrateSuperHoteModalProps) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentUser } = useAuth();
  const [formData, setFormData] = useLocalStorage('superhoteFormData', {
    apiKey: '',
    websiteKey: '',
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: formData,
  });

  React.useEffect(() => {
    setValue('apiKey', formData.apiKey);
    setValue('websiteKey', formData.websiteKey);
  }, [formData, setValue]);

  const backendErrors = '';

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const { mutate: integrate, isLoading } = useMutation(
    (data: { apiKey: string; websiteKey: string }) =>
      integrateSuperHote(currentUser.accessToken, currentWorkspace._id, data),
    {
      onSuccess: async () => {
        await onSuccessIntegration();
        setDisplayModal(false);
        localStorage.removeItem('superhoteFormData'); // Clear the saved form data on success
      },
      onError: (error: any) => {
        if (error.message === 'Unauthorized') {
          setError('apiKey', {
            type: 'manual',
            message: t('errors.superhote.code_not_found'),
          });
        }
      },
    },
  );

  const submit = (data: Record<string, any>) => {
    console.log(data);
    setFormData(data); // Save form data to local storage
    integrate(data);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[600px] lg:min-h-[500px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="min-h-[300px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('superhote.integrate_title')}
              </h3>
            </div>
            <div className="px-10">
              <div className="py-6 text-left">
                <ul className="text-gray-600 flex flex-col gap-2 rounded-2xl border border-lightGrey p-6">
                  <li>{t('superhote.tutorial.step_1')}</li>
                  <li>{t('superhote.tutorial.step_2')}</li>
                  <li>{t('superhote.tutorial.step_3')}</li>
                  <li>{t('superhote.tutorial.step_4')}</li>
                  <li>{t('superhote.tutorial.step_5')}</li>
                  <li>{t('superhote.tutorial.step_6')}</li>
                </ul>
              </div>
              <div className="flex w-full gap-4 my-6">
                <div className="w-full">
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue=""
                      render={({ field: { onBlur, onChange, value } }: any) => (
                        <Input
                          type="text"
                          key="apiKey"
                          onBlur={onBlur}
                          onChange={(e: any) => {
                            onChange(e);
                            setFormData((prev: any) => ({
                              ...prev,
                              apiKey: e.target.value,
                            })); // Update local storage
                          }}
                          value={value}
                          label={`${t('superhote.invitation_code')} *`}
                          errors={[
                            _.get(errors, 'apiKey.message'),
                            ...backendErrorsForAttribute('apiKey'),
                          ]}
                        />
                      )}
                      name={'apiKey'}
                    />
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue=""
                      render={({ field: { onBlur, onChange, value } }: any) => (
                        <Input
                          type="text"
                          key="websiteKey"
                          onBlur={onBlur}
                          onChange={(e: any) => {
                            onChange(e);
                            setFormData((prev: any) => ({
                              ...prev,
                              websiteKey: e.target.value,
                            })); // Update local storage
                          }}
                          value={value}
                          label={`${t('superhote.website_key')} *`}
                          errors={[
                            _.get(errors, 'websiteKey.message'),
                            ...backendErrorsForAttribute('websiteKey'),
                          ]}
                        />
                      )}
                      name={'websiteKey'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('next')}
                disabled={isLoading}
                customClass="btn btn-primary rounded-3xl"
              />
            </div>
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};

export default IntegrateSuperHoteModal;
