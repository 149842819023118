import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { getOrderDetailsPublic } from '../../api/orders';
import { useQuery } from 'react-query';
import { Emoji } from 'emoji-mart';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import DefaultHomePicture from '../../assets/images/default-home-picture.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const CheckoutSuccess = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const orderId = searchParams.get('_order') || null;
  const { data } = useQuery(
    ['order'],
    () => orderId && getOrderDetailsPublic(orderId),
    {},
  );

  const hasValidationRequired =
    data?.order?.products.filter((product: any) => !product.isValidated)
      .length > 0;

  const isOrderNotConfirmed =
    data?.order?.status === 'pending' &&
    data?.order?.paymentStatus === 'unpaid' &&
    !hasValidationRequired;

  return (
    <div className="bg-lightGrey min-h-screen">
      <div className="container mx-auto md:py-36 max-w-screen-lg">
        {data && (
          <div className="md:card bg-white p-6 md:p-10">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-bold text-gray-900 text-lg text-left ">
                  {t('checkout_success.order')} #{data?.order?.orderId}
                </div>
                <div className="text-sm text-left text-grey">
                  {t('checkout_success.order_date')} :{' '}
                  {new Date(data?.order?.purchaseDate).toLocaleDateString() +
                    ' ' +
                    new Date(data?.order?.purchaseDate).toLocaleTimeString()}
                </div>
              </div>
              <a
                className="btn btn-checkout"
                target="_blank"
                href={data.payment.receiptUrl}
                rel="noreferrer"
              >
                {t('checkout_success.see_receipt')}
              </a>
            </div>
            <div className="border-b border-grey py-6">
              <div className="py-6">
                <FontAwesomeIcon
                  className="ml-2"
                  color="#50d05a"
                  icon={faCircleCheck}
                  size="5x"
                />
              </div>
              <h1 className="font-bold text-3xl leading-normal mb-6 tracking-tight">
                {t('checkout_success.order_received_title')}
              </h1>
              <p className="font-semibold text-center text-md text-gray-600 mb-4">
                {data.order.status === 'confirmed' &&
                  data.order.products.filter(
                    (product: any) => !product.isValidated,
                  ).length > 0 && (
                    <span className="text-orange">
                      {t('checkout_success.order_products_unavailable')}
                    </span>
                  )}
              </p>
            </div>
            <div className="py-6  gap-6 border-b border-grey">
              <div className="mb-2 text-left font-bold text-grey">
                {t('checkout_success.order_status')}
              </div>
              <div className="flex flex-col md:flex-row justify-between mt-6 gap-4 text-left">
                <div className="flex flex-col flex-1 items-center gap-2 rounded-2xl border-2 border-green opacity-60 py-4 px-3">
                  <div className="text-lg text-green font-bold">
                    <span className="text-lg font-bold">1.</span>{' '}
                    {t('checkout_success.order_received_status')} ✅
                  </div>
                  <p className="text-green">
                    {t('checkout_success.order_received_status_description')}
                  </p>
                </div>

                <div
                  className={`flex flex-col flex-1 items-center gap-2 rounded-2xl border-2  py-4 px-3 ${
                    data.order.status === 'confirmed' ||
                    data.order.status === 'cancelled' ||
                    isOrderNotConfirmed
                      ? 'border-green opacity-60'
                      : 'border-orange border-4'
                  }`}
                >
                  <div
                    className={`text-lg text-green-500 font-bold text-center ${
                      data.order.status === 'confirmed' ||
                      data.order.status === 'cancelled' ||
                      isOrderNotConfirmed
                        ? 'text-green'
                        : 'text-orange'
                    }`}
                  >
                    <span className="text-lg font-bold">2.</span>{' '}
                    {t('checkout_success.order_pending_status')}{' '}
                    {data.order.status === 'confirmed' ||
                    data.order.status === 'cancelled' ||
                    isOrderNotConfirmed
                      ? '✅'
                      : '⏳'}
                  </div>
                  <p
                    className={`${
                      data.order.status === 'confirmed' ||
                      data.order.status === 'cancelled' ||
                      isOrderNotConfirmed
                        ? 'text-green'
                        : 'text-orange'
                    }`}
                  >
                    {t('checkout_success.order_pending_status_description')}
                  </p>
                </div>
                {data.order.status === 'cancelled' ? (
                  <div
                    className={`flex flex-col flex-1 items-center gap-2 rounded-2xl border-4  py-4 px-3 border-red-500`}
                  >
                    <div className={`text-lg font-bold text-red-500`}>
                      <span className="text-lg font-bold ">3.</span>{' '}
                      {t('checkout_success.order_cancelled_status')} ❌
                    </div>
                    <p className="text-red-500">
                      {t('checkout_success.order_cancelled_status_description')}
                    </p>
                  </div>
                ) : (
                  <div
                    className={`flex flex-col flex-1 items-center gap-2 rounded-2xl   py-4 px-3 ${
                      data.order.status === 'confirmed' || isOrderNotConfirmed
                        ? 'border-green border-4'
                        : 'border-grey opacity-60 border-2'
                    }`}
                  >
                    <div
                      className={`text-lg font-bold ${
                        data.order.status === 'confirmed' || isOrderNotConfirmed
                          ? 'text-green '
                          : 'text-grey'
                      }`}
                    >
                      <span className="text-lg font-bold">3.</span>{' '}
                      {t('checkout_success.order_confirmed_status')}{' '}
                      {data.order.status === 'confirmed' || isOrderNotConfirmed
                        ? '✅'
                        : ''}
                    </div>
                    <p
                      className={
                        data.order.status === 'confirmed' || isOrderNotConfirmed
                          ? 'text-green'
                          : 'text-grey'
                      }
                    >
                      {t('checkout_success.order_confirmed_status_description')}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="py-6 flex gap-6 border-b border-grey">
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {' '}
                  {t('checkout_success.location')}
                </div>
                <div className="flex gap-4">
                  <div>
                    <img
                      className="w-20 h-20 object-cover rounded-2xl"
                      src={data.order.home.picture || DefaultHomePicture}
                      alt={data.order.home.name}
                    />
                  </div>
                  <div>
                    <div className="text-xl font-bold">
                      {data?.order?.home.name}
                    </div>
                    <div>
                      {t('checkout_success.arrival_date')} :{' '}
                      {new Date(data.order.deliveryDate).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-6 flex gap-6 border-b border-grey">
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {t('checkout_success.customer_informations')}
                </div>
                <div>{data?.order?.customer}</div>
                <div>{data?.order?.customerEmail}</div>
              </div>
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {t('checkout_success.payment_information')}
                </div>
                <div>{t('checkout_success.credit_card')}</div>
                <div className="uppercase">
                  {data?.payment?.paymentMethod.card.brand}
                </div>
                <div>**** {data?.payment?.paymentMethod.card.last4}</div>
              </div>
            </div>
            <div className="border-b border-grey pt-6">
              <div className="mb-2 font-bold text-grey text-left">
                {t('checkout_success.extras')}
              </div>
              <div>
                {data?.order?.products.map((product: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-start gap-3 justify-between mb-4 border-b border-lightGrey last:border-b-0 pb-4"
                  >
                    <div className="flex flex-col lg:flex-row items-start gap-4">
                      <div className="flex items-start">
                        {!product.item.emoji ? null : (
                          <Emoji emoji={product.item.emoji} size={36} />
                        )}
                      </div>
                      <div className="text-left">
                        <p className="font-semibold text-md md:text-lg">
                          {(data.order.status === 'confirmed' ||
                            data.order.status === 'cancelled') &&
                          !product.isValidated ? (
                            <del>
                              {product.item.translations?.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              ).length
                                ? product.item.translations.filter(
                                    (t: any) =>
                                      t.languageCode ===
                                      i18n.language.slice(0, 2),
                                  )[0].title
                                : product.item.name}
                            </del>
                          ) : (
                            <span>
                              {product.item.translations?.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              ).length
                                ? product.item.translations.filter(
                                    (t: any) =>
                                      t.languageCode ===
                                      i18n.language.slice(0, 2),
                                  )[0].title
                                : product.item.name}
                            </span>
                          )}
                        </p>
                        <div>
                          {product.isValidated ? (
                            <div className="text-green">
                              {t('checkout_success.extra_available')}
                            </div>
                          ) : (
                            <div className="text-orange">
                              {data.order.status === 'confirmed' ||
                              data.order.status === 'cancelled' ? (
                                <span className="text-red-500">
                                  {t('checkout_success.extra_unavailable')}
                                </span>
                              ) : (
                                <span className="text-orange">
                                  {t('checkout_success.waiting_validation')}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex items-start">
                          <p className="text-gray-500 text-sm flex gap-2">
                            <span>{product.quantity}</span>
                            <span>x</span>
                            {displayPrice(
                              product.item.sellingPrice,
                              getCurrencyInfo(data.order.currency).symbol,
                              { thousandSeparator: ' ' },
                            )}
                          </p>
                        </div>
                        {!product.isValidated ? null : (
                          <div>
                            {!product.instruction ? null : (
                              <div className="rounded-xl px-3 py-2 mt-3 bg-lightGrey">
                                <p className="font-bold italic mb-2">
                                  {t('checkout_success.instructions')}
                                </p>
                                <p>{product.instruction}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="font-semibold text-lg">
                      {(data.order.status === 'confirmed' ||
                        data.order.status === 'cancelled') &&
                      !product.isValidated ? (
                        <del>
                          {displayPrice(
                            product.item.sellingPrice * product.quantity,
                            getCurrencyInfo(data.order.currency).symbol,
                            { thousandSeparator: ' ' },
                          )}
                        </del>
                      ) : (
                        displayPrice(
                          product.item.sellingPrice * product.quantity,
                          getCurrencyInfo(data.order.currency).symbol,
                          { thousandSeparator: ' ' },
                        )
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="border-b border-grey py-6">
              <div className="flex flex-col gap-4">
                <div className="text-left flex gap-6 items-center justify-between">
                  <div className="mb-2 font-bold text-grey">
                    {t('checkout_success.subtotal')}
                  </div>
                  <div className="font-semibold text-lg">
                    {displayPrice(
                      data?.order?.totalAmount,
                      getCurrencyInfo(data.order.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </div>
                </div>
                <div className="text-left flex gap-6 items-center justify-between">
                  <div className="mb-2 font-bold text-grey">
                    {t('checkout_success.guest_fee')}
                  </div>
                  <div className="font-semibold text-lg">
                    {displayPrice(
                      data?.order?.amountFeeGuest,
                      getCurrencyInfo(data.order.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </div>
                </div>
                <div className="text-left flex gap-6 items-center justify-between">
                  <div className="mb-2 text-lg font-bold text-gray-900">
                    TOTAL
                  </div>
                  <div className="font-semibold text-lg">
                    {displayPrice(
                      data?.order?.totalAmount + data?.order?.amountFeeGuest,
                      getCurrencyInfo(data.order.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-6">
              <div className="flex flex-col gap-4">
                <div>
                  {t('checkout_success.order_question')}
                  <a
                    className="text-checkout"
                    href={`mailto:${data.order.support.email}?subject=CozyUp - ${data.order.home.name} - Order #${data.order.orderId} - Question`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.order.support.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSuccess;
