import React, { useEffect, useReducer, useRef, useState } from 'react';
import './landing.style.scss';
import DefaultHomePicture from '../../assets/images/default-home-picture.jpeg';
import Extra from '../../components/extra/extra.component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getHomeByCustomId, getHomeById } from '../../api/homes';
import { useQuery } from 'react-query';
import { Home } from '../../types/home.model';
import Cart from '../../components/cart/cart.component';
import ImagePreview from '../../components/image-popup/image-popup.component';
import { getCurrencyInfo } from '../../utils/currency';
import InputDayPicker from '../../components/input-day-picker/input-day-picker.component';
import { hasProductExpired } from '../../utils/date';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { checkoutInitialState, checkoutReducer } from './checkout.reducer';

const Checkout = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const previewMode = params.get('preview') === 'true';
  const { t, i18n } = useTranslation();
  const { homeId, customHomeId } = useParams();
  const navigate = useNavigate();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(undefined);
  const datePickerRef = useRef<any>(null);
  const [showDatepicker, setShowDatePicker] = React.useState<boolean>(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [state, dispatch] = useReducer(checkoutReducer, checkoutInitialState);
  const [home, setHome] = useState<Home>();

  const checkoutMetadata = {
    home_id: homeId,
    additional_home_id: customHomeId,
    workspace_id: home?.workspace?._id,
    address: home?.address,
    arrival_date: selectedDay?.toISOString(),
  };

  const handleEditArrivalDate = (day: Date) => {
    setSelectedDay(day);
    !previewMode &&
      !previewMode &&
      trackMixpanelEvent('Checkout - Edit arrival date', {
        ...checkoutMetadata,
        previous_date: selectedDay?.toISOString(),
        new_date: day?.toISOString(),
      });
  };

  useEffect(() => {
    if (home && !previewMode) {
      trackMixpanelEvent('Checkout - Page viewed', checkoutMetadata);
    }
  }, [home]);

  useEffect(() => {
    if (selectedDay) {
      state.products.map((p: any) => {
        if (hasProductExpired(selectedDay, p.preparationTimeInHours)) {
          dispatch({
            type: 'SET_QUANTITY_TO_ZERO',
            payload: {
              _id: p._id,
            },
          });
        }
      });
    }
  }, [selectedDay]);

  useQuery(
    ['homeKey'],
    // @ts-ignore
    () => handleGetHome(homeId, customHomeId),
    {
      enabled: !home,
      onSuccess: (data: any) => {
        if (data.status === 'archived') navigate('/page-not-found');
        setHome(data);
        initProducts(
          data?.checkoutArea?.checkoutAreaProducts
            .sort((a: any, b: any) => a.order - b.order)
            .map((p: any) => p.product),
        );
        setCurrency(getCurrencyInfo(data?.workspace?.currency));
      },
    },
  );

  /**
   *  Method to fetch home data with the proper API endpoints according to path used to display component
   *
   * getHomeById --> url/c/:homeId
   * getHomeByCustomId --> url/cid/:customId
   * @param homeId
   * @param customHomeId
   */
  const handleGetHome = (homeId: string, customHomeId: string) => {
    if (homeId) {
      return getHomeById(homeId);
    } else if (customHomeId) {
      return getHomeByCustomId(customHomeId);
    }
  };

  const initProducts = (products: any) => {
    products.forEach((p: any) => {
      return (p.quantity = 0);
    });
    dispatch({
      type: 'INIT_PRODUCTS',
      payload: {
        products: products,
      },
    });
  };

  if (home && home.name) {
    document.title = 'CozyUp - ' + home.name;
  }

  const [displayExtraPictures, setdisplayExtraPictures] = useState(false);
  const [currentPictures, setCurrentPictures] = useState<any[]>([]);

  const handleDisplayExtraPictures = (pictures: any[]) => {
    !previewMode &&
      trackMixpanelEvent('Checkout - Product View', {
        ...checkoutMetadata,
      });
    setCurrentPictures(pictures);
    setdisplayExtraPictures(true);
  };

  const onEditOrder = () => {
    !previewMode &&
      trackMixpanelEvent('Checkout - Edit Cart', {
        ...checkoutMetadata,
      });
    setIsProcessingPayment(false);
  };

  const handleIncrementQuantity = (
    productId: any,
    hasProductPreparationTime: boolean,
  ) => {
    if (!hasProductPreparationTime || selectedDay) {
      dispatch({
        type: 'INCREMENT_QUANTITY',
        payload: {
          _id: productId,
          ...checkoutMetadata,
        },
      });
    } else {
      scrollToInput();
      setShowDatePicker(true);
    }
  };

  const scrollToInput = () => {
    if (datePickerRef.current) {
      // Scrolls the screen to the input element
      datePickerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      // Sets focus on the input element
      datePickerRef.current.focus();
    }
  };

  const handleFocusDatePicker = () => {
    !previewMode &&
      trackMixpanelEvent('Checkout - Focus date picker', {
        ...checkoutMetadata,
      });
    scrollToInput();
    setShowDatePicker(true);
  };

  return (
    <div className="relative">
      {previewMode && (
        <div className="absolute z-50 top-0 right-0 p-2 h-10 bg-lightPrimary sticky">
          <span className="text-primary px-2 rounded">
            {t('checkout.preview_mode')}
          </span>
        </div>
      )}
      <ImagePreview
        isVisible={displayExtraPictures}
        setIsVisible={setdisplayExtraPictures}
        pictures={currentPictures}
      />
      <div className="landing-page">
        <div className="landing-background">
          <img
            className="background-image"
            src={home && (home?.picture || DefaultHomePicture)}
            alt="image-home"
          />
        </div>
        <div className="landing-page-wrapper">
          <div className="landing-header">
            <h1 className="checkout-title">
              {home?.translations.filter(
                (t: any) => t.languageCode === i18n.language.slice(0, 2),
              ).length
                ? home.translations.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  )[0].title
                : home?.headline}
            </h1>
            <p className="subtitle">
              {home?.translations.filter(
                (t: any) => t.languageCode === i18n.language.slice(0, 2),
              ).length
                ? home.translations.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  )[0].description
                : home?.description}
            </p>
          </div>
          <div className="items-start justify-between px-4 lg:flex mt-12">
            <div className="flex flex-col w-full lg:w-2/3 text-left lg:pr-16">
              <h2 className="extras-wrapper-title">
                {t('checkout.available_addons')}
              </h2>

              {!state && !state.products && !state.products.length ? null : (
                <div>
                  {state &&
                    state.products
                      .filter((p: any) => p.isAvailable)
                      .map((product: any, index: number) => (
                        <Extra
                          currency={currency}
                          callback={handleDisplayExtraPictures}
                          key={index}
                          title={product.name}
                          description={product.description}
                          price={product.sellingPrice}
                          icon={product.emoji}
                          quantity={product.quantity}
                          pictures={product.pictures}
                          validationRequired={product.validationRequired}
                          preparationTime={product.preparationTimeInHours}
                          translations={product.translations || []}
                          arrivalDate={selectedDay}
                          isProcessingPayment={isProcessingPayment}
                          showingDatePicker={showDatepicker}
                          focusSelectArrivalDate={() => handleFocusDatePicker()}
                          onIncrementQuantity={() =>
                            handleIncrementQuantity(
                              product._id,
                              !!product.preparationTimeInHours,
                            )
                          }
                          onDecrementQuantity={() =>
                            dispatch({
                              type: 'DECREMENT_QUANTITY',
                              payload: {
                                _id: product._id,
                                ...checkoutMetadata,
                              },
                            })
                          }
                        />
                      ))}
                </div>
              )}
            </div>
            <div className="w-full lg:w-1/3 lg:sticky flex flex-col top-10">
              <div className=" my-6 shadow py-6 px-6 flex flex-col ">
                <div className="text-left text-2xl">{home?.name}</div>
                <div className="relative mt-6 pb-3">
                  <InputDayPicker
                    datePickerRef={datePickerRef}
                    disabled={isProcessingPayment}
                    selectedDay={selectedDay}
                    setSelectedDay={handleEditArrivalDate}
                    showDatePicker={showDatepicker}
                    setShowDatePicker={setShowDatePicker}
                  />
                </div>
              </div>
              <div className="w-full mt-6">
                {!home ? null : (
                  <Cart
                    previewMode={previewMode}
                    currency={currency}
                    products={state.products}
                    home={home}
                    isProcessingPayment={isProcessingPayment}
                    arrivalDate={selectedDay}
                    onValidate={() => setIsProcessingPayment(true)}
                    onEditOrder={() => onEditOrder()}
                    focusDatePicker={handleFocusDatePicker}
                    onIncrementQuantity={(id: any) => {
                      dispatch({
                        type: 'INCREMENT_QUANTITY',
                        payload: {
                          _id: id,
                          ...checkoutMetadata,
                        },
                      });
                    }}
                    onDecrementQuantity={(id: any) =>
                      dispatch({
                        type: 'DECREMENT_QUANTITY',
                        payload: {
                          _id: id,
                          ...checkoutMetadata,
                        },
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="left-5 bottom-5 px-5 pb-5">
          <div className="footer-title">
            {t('powered_by')}
            <span
              className="logo-text"
              onClick={() => window.open('https://cozyup.com/', '_blank')}
            >
              &nbsp;CozyUp
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
