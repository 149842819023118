import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';

export const checkoutInitialState = {
  products: [],
};

export const checkoutReducer = (state: any, action: any) => {
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  switch (action.type) {
    case 'INCREMENT_QUANTITY': {
      trackMixpanelEvent('Checkout - Product - Add one to card', {
        ...action.payload,
        product_id: action.payload._id,
        cart: state.products
          .map((product: any) => {
            if (product._id === action.payload._id) {
              let newProduct = { ...product, category: 'extra' };
              newProduct.quantity++;
              product = newProduct;
            }
            return product;
          })
          .filter((product: any) => product.quantity > 0),
      });

      return {
        ...state,
        products: state.products.map((product: any) => {
          if (product._id === action.payload._id) {
            let newProduct = { ...product };
            newProduct.quantity++;
            product = newProduct;
          }
          return product;
        }),
      };
    }

    case 'DECREMENT_QUANTITY':
      trackMixpanelEvent('Checkout - Product - Remove one from cart', {
        ...action.payload,
        product_id: action.payload._id,
      });
      return {
        ...state,
        products: state.products.map((product: any) => {
          if (product._id === action.payload._id) {
            if (product.quantity >= 1) {
              let newProduct = { ...product };
              newProduct.quantity--;
              product = newProduct;
            }
          }
          return product;
        }),
      };

    case 'SET_QUANTITY_TO_ZERO':
      return {
        ...state,
        products: state.products.map((product: any) => {
          if (product._id === action.payload._id) {
            let newProduct = { ...product };
            newProduct.quantity = 0;
            product = newProduct;
          }
          return product;
        }),
      };
    case 'INIT_PRODUCTS':
      return {
        ...state,
        products: action.payload.products,
      };
    default:
      return state;
  }
};
