import React, { useState } from 'react';
import CustomLink from '../custom-link/custom-link.component';
import {
  faAngleDown,
  faCartArrowDown,
  faChartLine,
  faCircleDollar,
  faCirclePlay,
  faCog,
  faComments,
  faGraduationCap,
  faHeadset,
  faHome,
  faMoonStars,
  faPlug,
  faRectanglesMixed,
  faStore,
  faTag,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import WorkspaceInfo from '../workspace-info/workspace-info.component';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Language from '../language/language.component';
import OpenMenuButton from './open-menu/open-menu.button';
import Logo from '../logo/logo-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFolders,
  faHouseUser,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from '../../contexts/authContext';
import QuickStartMenu from '../quick-start-menu/quick-start-menu';
import QuickStartMobile from '../../pages/quick-start/QuickStartMobile';
import OnClickOut from '../../hooks/useOnClickOut';
import { WithPermissions } from '../with-permissions/with-permissions.component';
import Lottie from 'react-lottie';
import stars from '../../assets/lottie/stars.json';
import { useAppSelector } from '../../hooks/useStore';

const package_json = require('../../../package.json');

type MobileMenuProps = {
  displayOnboarding: boolean;
  setDisplayOnboarding: any;
};

const MobileMenu = ({
  displayOnboarding,
  setDisplayOnboarding,
}: MobileMenuProps) => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );
  const [displaySettingsMenu, setDisplaySettingsMenu] = useState(false);
  const [isAccountListOpen, setIsAccountListOpen] = useState(false);
  const appVersion = `CozyUp - v${package_json.version}`;
  const location = useLocation();
  const {
    accountsUser,
    currentAccount,
    updateCurrentAccount,
    updateCurrentWorkspace,
  } = useAuth();

  const changeAccount = (account: any) => {
    updateCurrentAccount(account);
    updateCurrentWorkspace(account.workspaces[0]);
  };

  React.useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  React.useEffect(() => {
    location.pathname.includes('settings')
      ? setDisplaySettingsMenu(true)
      : setDisplaySettingsMenu(false);
  }, [location]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: stars,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      {location.pathname === '/onboarding' ? null : (
        <div
          className="fixed top-0 z-50 w-full overflow-y-scroll"
          style={{ height: isMenuOpen ? '100%' : 56 }}
        >
          <div className="fixed w-screen h-[56px]">
            <div className="flex h-full items-center justify-between border-b-2 border-gray-100 bg-white">
              <OpenMenuButton
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
              />
              <Logo />
              <div className="mr-[20px]" />
            </div>
          </div>

          {!isMenuOpen ? null : (
            <div className="z-50 mt-[48px] bg-lightblue">
              <div className="flex flex-col min-h-screen shadow-lg bg-lightblue text-center ">
                {displaySettingsMenu ? (
                  <div>
                    <div
                      onClick={() => setDisplaySettingsMenu(false)}
                      className="mt-3 p-2 rounded-full"
                    >
                      <div className="flex justify-center items-center bg-white hover:bg-lightPrimary ml-3 p-3 rounded-full w-[36px] h-[36px] cursor-pointer">
                        <FontAwesomeIcon icon={faChevronLeft} size="1x" />
                      </div>
                    </div>
                    <div className="pt-[12px] mt-[12px] px-[6px]">
                      <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
                        <CustomLink
                          to="settings/account"
                          title={t('header.account')}
                          icon={faHouseUser}
                        />
                      </WithPermissions>
                      <CustomLink
                        to="settings/profile"
                        title={t('header.profile')}
                        icon={faUser}
                      />
                      <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
                        <CustomLink
                          to="settings/workspaces"
                          title={t('menu.workspaces')}
                          icon={faFolders}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="settings/referrals"
                          title={t('menu.referrals')}
                          icon={faUserGroup}
                        />
                      </WithPermissions>
                    </div>
                    <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
                      <div className="ml-[6px]">
                        <CustomLink
                          to="settings/billing"
                          title={t('menu.subscription')}
                          icon={faCircleDollar}
                        />
                      </div>
                    </WithPermissions>
                  </div>
                ) : (
                  <>
                    <div className="mt-4">
                      {accountsUser?.length > 1 && (
                        <div className="relative">
                          <OnClickOut
                            callback={() => setIsAccountListOpen(false)}
                          >
                            <div
                              className="flex items-center justify-between p-2 ml-2 bg-white rounded-[8px] min-w-[100px] mr-3"
                              onClick={() =>
                                setIsAccountListOpen((prev: boolean) => !prev)
                              }
                            >
                              <div className="text-left mx-3">
                                <span className="font-satoshi font-xs">
                                  {currentAccount?.name}
                                </span>
                              </div>
                              <div className="mr-1">
                                <FontAwesomeIcon icon={faAngleDown} size="lg" />
                              </div>
                              {isAccountListOpen && (
                                <div className="block absolute text-left top-12 right-0 z-50 min-w-[250px] max-w-[300px] py-2 mt-2 overflow-hidden bg-white rounded-xl shadow-lg">
                                  <div className="pl-3 pb-4 pt-2 border-b-[1px]">
                                    <span className="font-bold font-sans text-[18px]">
                                      {t('header.account')}
                                    </span>
                                  </div>
                                  <div className="mt-1">
                                    {accountsUser.map((acc: any) => (
                                      <div
                                        key={acc._id}
                                        onClick={() => changeAccount(acc)}
                                        className={`px-4 py-1 m-2 cursor-pointer p-1 rounded-[4px]
                        ${
                          currentAccount.name === acc.name
                            ? 'bg-lightPrimary'
                            : 'text-[14px]'
                        }`}
                                      >
                                        <div className="flex flex-row items-center ">
                                          <div className="flex flex-col ml-3">
                                            <h5 className="text-[14px] text-primary">
                                              {acc.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </OnClickOut>
                        </div>
                      )}
                      <WorkspaceInfo containerClass="w-full" />
                      <CustomLink
                        to="/dashboard"
                        title={t('menu.dashboard')}
                        icon={faRectanglesMixed}
                      />
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/analytics"
                          title={t('menu.analytics')}
                          icon={faChartLine}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={[
                          'ADMIN',
                          'MANAGER',
                          'OWNER',
                          'CLEANING_STAFF',
                        ]}
                      >
                        <CustomLink
                          to="/orders"
                          title={t('menu.orders')}
                          icon={faCartArrowDown}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/night-upsells"
                          title={t('menu.night_upsells')}
                          icon={faMoonStars}
                          hasNewBadge
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/homes"
                          title={t('menu.listings')}
                          icon={faHome}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/checkout-areas"
                          title={t('menu.checkout-areas')}
                          icon={faStore}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/products"
                          title={t('menu.products')}
                          icon={faTag}
                        />
                      </WithPermissions>
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <CustomLink
                          to="/channel-managers"
                          title={t('menu.applications')}
                          icon={faPlug}
                        />
                      </WithPermissions>
                      {/* SETTINGS BUTTON */}
                      <div
                        className="flex font-black font-satoshiBold items-center my-1 m-2 pl-2 pr-2 py-1 rounded-xl text-grey"
                        onClick={() => setDisplaySettingsMenu(true)}
                      >
                        <div className="link-icon my-1 mx-4 min-w-[16px]">
                          <FontAwesomeIcon icon={faCog} />
                        </div>
                        <span className="text-xs">{t('menu.settings')}</span>
                      </div>
                    </div>
                    <div className="mt-4 flex flex-col justify-between">
                      <WithPermissions rolesRequired={['OWNER']}>
                        <QuickStartMenu
                          setVisible={setDisplayOnboarding}
                          visible={displayOnboarding}
                        />
                        <QuickStartMobile
                          visible={displayOnboarding}
                          setVisible={setDisplayOnboarding}
                        />
                        <div
                          className="mb-3 w-4/5 mx-auto bg-white border-grey border-b-[1px]"
                          style={{ marginTop: displayOnboarding ? 0 : 12 }}
                        />
                      </WithPermissions>
                      <div>
                        <div className="mx-8">
                          <Language />
                        </div>
                        <div className="my-3 w-4/5 mx-auto bg-grey" />
                        <div className="flex flex-row">
                          <CustomLink
                            to="/tutorial"
                            title={t('menu.how-it-works')}
                            icon={faCirclePlay}
                          />
                          {onboardingStatus?.meta?.remainingOnboardingTasks >
                            0 && (
                            <div className="right-4">
                              <Lottie
                                isClickToPauseDisabled
                                options={defaultOptions}
                                height={48}
                                width={48}
                              />
                            </div>
                          )}
                        </div>
                        <CustomLink
                          to="https://academy.cozyup.com/"
                          title={t('menu.cozyup_course')}
                          icon={faGraduationCap}
                          external={true}
                          hasNewBadge
                        />
                        <CustomLink
                          to="https://cozyup.kampsite.co/"
                          title={t('menu.feedbacks')}
                          icon={faComments}
                          external={true}
                        />
                        <CustomLink
                          to="https://help.cozyup.com/"
                          title={t('menu.help-center')}
                          icon={faHeadset}
                          external={true}
                        />
                        <div className="py-2">
                          <p className="text-xs text-center text-gray-400">
                            {appVersion}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MobileMenu;
