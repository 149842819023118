import React, { useEffect } from 'react';
import Button from '../../../button/button.component';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { AirbnbConnectedAccount } from '../../../airbnb-connect/AirbnbConnect.component';
import AirbnbLogo from '../../../../assets/images/airbnb-logo.svg';
import Loader from '../../../loader/loader.component';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import useChannelManagersWithHandlers from '../../../../hooks/useChannelManagersWithHandlers';
import useTrackOnboardingWithMixpanel from '../../../../hooks/useTrackOnboardingWithMixpanel';
import ChannelManagerList from '../../../../containers/channel-manager-list/channel-manager-list.container';
import { classNames } from '../../../../utils/helpers';

const HOSPITBALE_REDIRECT_URL =
  process.env.REACT_APP_COZYUP_URL + '/onboarding?step=1';
const CALRY_REDIRECT_URL =
  process.env.REACT_APP_COZYUP_URL + '/onboarding?step=1';

type InitConnectionProps = {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  updateOnboarding: Function;
  airbnbChannels: any;
  channelManagerIntegrations: any;
  calryIntegrations: any;
  refreshIntegrations: Function;
  isLoading: boolean;
};

const InitConnection: React.FC<InitConnectionProps> = ({
  setActiveTab,
  airbnbChannels,
  channelManagerIntegrations,
  calryIntegrations,
  updateOnboarding,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { authRequestHospitableConnect, isLoadingRequestHospitableConnect } =
    useChannelManagersWithHandlers(
      CALRY_REDIRECT_URL,
      HOSPITBALE_REDIRECT_URL,
      true,
      {},
      setActiveTab,
    );

  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  useEffect(() => {
    trackOnboardingEvent('Onboarding - Step 1. CM_PMS_CONNECTION - Start');
  }, []);

  const handleGoToNextStep = () => {
    updateOnboarding({ step: 2 });
    trackOnboardingEvent(`Onboarding - Step 1. CM_PMS_CONNECTION - Success`);
    setActiveTab(2);
  };

  const hasIntegrations = () => {
    return (
      (airbnbChannels && airbnbChannels.length > 0) ||
      (channelManagerIntegrations && channelManagerIntegrations.length > 0) ||
      (calryIntegrations && calryIntegrations.length > 0)
    );
  };

  return (
    <div>
      <div className="p-4 rounded-3xl mb-3">
        <h2 className="text-left text-primary text-xl font-bold leading-12">
          {t('onboarding.connections.setup')}
        </h2>
        <div className="text-left text-grey">
          {t('onboarding.connections.setup_dsc')}
        </div>
        <div className="mt-8 pb-[70px]">
          <div>
            <div>
              <div className="mt-4 mb-3 border-b border-grey">
                <h2 className="pb-2 text-left text-md font-bold text-greyText">
                  {t('platforms')}
                </h2>
              </div>
              <div>
                <div className="flex flex-wrap">
                  <div className="m-2 w-1/6">
                    <div className="border border-grey rounded-2xl flex flex-col min-h-[120px] ">
                      <div className="flex-1 flex items-center justify-center p-3 min-h-[100px]">
                        <img
                          className="object-contain w-full max-h-[50px]"
                          src={AirbnbLogo}
                          alt="airbnb logo"
                        />
                      </div>
                      <div className="flex justify-end items-center border-t border-grey min-h-[4Opx]">
                        <div className="flex items-end px-2 py-1">
                          <Button
                            disabled={isLoadingRequestHospitableConnect}
                            onClick={authRequestHospitableConnect}
                            className="rounded-3xl px-2 py-1 flex gap-2 text-sm text-primary hover:opacity-50"
                          >
                            {airbnbChannels && airbnbChannels.length > 0 ? (
                              t('add_account')
                            ) : (
                              <div>
                                {t('connect')}
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  size="xs"
                                  className="ml-1"
                                />
                              </div>
                            )}
                            {!isLoadingRequestHospitableConnect ? null : (
                              <FontAwesomeIcon
                                style={{ marginLeft: 15 }}
                                icon={faSpinnerThird}
                                spin
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                      {airbnbChannels && airbnbChannels.length > 0 ? (
                        <AirbnbConnectedAccount
                          airbnbChannels={airbnbChannels}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 mb-3 border-b border-grey">
              <h2 className="pb-2 text-left text-md font-bold text-greyText">
                {t('menu.channel_managers_or_pms')}
              </h2>
            </div>
            <div className="flex flex-wrap">
              {isLoading ? (
                <Loader />
              ) : (
                <ChannelManagerList
                  calryRedirectUrl={CALRY_REDIRECT_URL}
                  hospitableRedirectUrl={HOSPITBALE_REDIRECT_URL}
                  fromOnboarding={true}
                  setOnboardingStep={setActiveTab}
                />
              )}
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 flex justify-center items-center py-1 border-t border-grey w-full bg-white">
          <div className="px-4">
            <Button
              disabled={false}
              className={classNames(
                hasIntegrations() ? 'btn btn-primary' : 'btn btn-ghost',
              )}
              onClick={() => handleGoToNextStep()}
            >
              {hasIntegrations() ? t('next') : t('skip')}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitConnection;
