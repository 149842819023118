import React, { useEffect, useState } from 'react';

import { DateRange, DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { dateFnslocales } from '../../utils/language';
import i18n from '../../i18n';

const currentMonth = new Date();

const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    color: white;
    background: #7e82e5;
  }
  .my-selected:hover:not([disabled]) { 
    background: #e3e4ff;
    color: #160042;
  }
  .my-today { 
    font-weight: bold !important;
    color: #ff3366 !important;
  }
`;

type InputRangeDatesProps = {
  onDateChange: Function;
};

function InputRangeDates({ onDateChange }: InputRangeDatesProps) {
  const defaultSelected: DateRange = {
    from: currentMonth,
    to: currentMonth,
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const { t } = useTranslation();

  useEffect(() => {
    if (range?.from && range?.to) onDateChange(range);
  }, [range]);

  let footer = <div></div>;
  if (range?.from) {
    if (!range.to) {
      footer = (
        <div className="mt-4 font-bold">
          {new Date(range.from).toLocaleDateString('fr')}{' '}
        </div>
      );
    } else if (range.to) {
      footer = (
        <p className="mt-4 font-bold">
          {new Date(range.from).toLocaleDateString('fr')} &rarr;{' '}
          {new Date(range.to).toLocaleDateString('fr')}
        </p>
      );
    }
  } else {
    footer = <div className="mt-4">{t('select-dates')}</div>;
  }
  return (
    <div>
      <style>{css}</style>
      <DayPicker
        id="input-range-date"
        mode="range"
        modifiersClassNames={{
          selected: 'my-selected',
          today: 'my-today',
        }}
        defaultMonth={currentMonth}
        selected={range}
        footer={footer}
        onSelect={setRange}
        locale={dateFnslocales[i18n.language]}
      />
    </div>
  );
}

export default InputRangeDates;
