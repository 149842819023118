import React, { createContext, useEffect, useState } from 'react';
import SideMenu from '../../components/side-menu/side-menu.component';
import MobileMenu from '../../components/mobile-menu/mobile-menu.component';
import MainContent from '../main-content/MainContent';
import { useMatch, useNavigate } from 'react-router-dom';
import QuickStart from '../quick-start/QuickStart';

import { useAuth } from '../../contexts/authContext';

import { useAppDispatch } from '../../hooks/useStore';
import {
  checkHasAirbnbConnection,
  fetchOnboardingStatus,
} from '../../redux/global/globalActions';

export const OnboardingContext = createContext({} as any);

const Layout = () => {
  const dispatch = useAppDispatch();

  const match = useMatch({ path: '/', end: true });
  const navigate = useNavigate();
  const [displayOnboarding, setDisplayOnboarding] = useState<boolean>(false);
  useEffect(() => {
    if (match) {
      navigate('dashboard');
    }
  }, []);

  const { currentWorkspace, currentAccount, currentUser } = useAuth();

  useEffect(() => {
    dispatch(
      fetchOnboardingStatus(currentUser.accessToken, currentWorkspace?._id),
    );
  }, [currentWorkspace]);
  useEffect(() => {
    dispatch(
      checkHasAirbnbConnection(currentUser.accessToken, currentAccount?._id),
    );
  }, [currentAccount]);

  return (
    <div className="h-full">
      <div className="relative">
        <div className="lg:hidden">
          <MobileMenu
            displayOnboarding={displayOnboarding}
            setDisplayOnboarding={setDisplayOnboarding}
          />
        </div>
        <div className="hidden lg:block fixed w-60 bg-base-300 h-screen">
          <SideMenu
            setDisplayOnboarding={setDisplayOnboarding}
            displayOnboarding={displayOnboarding}
          />
        </div>

        <MainContent />
        <QuickStart
          visible={displayOnboarding}
          setVisible={setDisplayOnboarding}
        />
      </div>
    </div>
  );
};

export default Layout;
