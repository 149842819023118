import { api } from '../utils/request';

export const getAdditionnalNightConfig = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/additionnal-nights-config`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const updateAdditionnalNightConfig = async (
  authToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/additionnal-nights-config`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};
