import {
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import React, {
  ReactChild,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { auth } from '../firebase';
import { useMutation } from 'react-query';
import {
  createUser,
  createUserFromInvitation,
  findUserByFirebaseId,
} from '../api/users';
import { findCustomerById } from '../api/stripe';
import { Account } from '../types/account.model';
import { Workspace } from '../types/workspace.model';
import { getAccountsByUser } from '../api/accounts';
import { fetchWorkspaceCurrentRole } from '../redux/global/globalActions';
import { useAppDispatch } from '../hooks/useStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWorkspaceAccessesByUserAndWorkspace } from '../api/workspaces';
import Loader from '../components/loader/loader.component';
import useTrackMixpanelEvent from '../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { handleFirebaseLoginError } from '../utils/errors';
import { CreateUser } from '../types/user.model';

const AuthContext = React.createContext({} as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactChild }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [currentUser, setCurrentUser] = useState<any | null>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [processingAction, setProcessingAction] = useState('');

  const isNewUser = useRef<boolean>(false);
  const priceLookupKey = useRef<string>('free_with_fees');
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [signUpError, setSignUpError] = useState<string | null>(null);
  const isRegisteringNewUser = useRef<boolean>(false);

  const signup = async ({ email, password, source, ref }: CreateUser) => {
    try {
      isRegisteringNewUser.current = true;
      isNewUser.current = true;
      setSignUpError(null);
      setProcessingAction('signup_actions.fetching_data');
      setProcessingAction('signup_actions.init_firebase');
      setProcessingAction('signup_actions.init_account');
      const response = await createUser({
        email: email,
        password: password,
        source: ref ? 'referral' : source ?? 'direct',
        ref: ref,
        price: priceLookupKey.current,
      });
      setProcessingAction('signup_actions.space_creation');
      trackMixpanelEvent('Sign up', {
        firebaseId: response?.firebaseUser?.uid,
        email: response?.user?.email,
        emailVerified: response?.firebaseUser?.emailVerified,
      });
      setProcessingAction('signup_actions.checking_data');
      await signInWithCustomToken(auth, response.token);
      return response;
    } catch (err: any) {
      if (err.type) {
        setSignUpError(t(handleFirebaseLoginError(err.type)));
      } else {
        setSignUpError(t('firebase_error.generic_error'));
      }
      setProcessingAction('');
      return signUpError;
    } finally {
      setProcessingAction('');
    }
  };

  const signupFromInvitation = async ({
    email,
    password,
    invitationId,
  }: CreateUser) => {
    try {
      isRegisteringNewUser.current = true;
      isNewUser.current = false;
      setSignUpError(null);
      setProcessingAction('signup_actions.fetching_data');
      setProcessingAction('signup_actions.init_firebase');
      setProcessingAction('signup_actions.init_account');
      const response = await createUserFromInvitation({
        email: email,
        password: password,
        price: priceLookupKey.current,
        invitationId: invitationId,
      });
      setProcessingAction('signup_actions.space_creation');
      trackMixpanelEvent('Sign up', {
        firebaseId: response?.firebaseUser?.uid,
        email: response?.user?.email,
        emailVerified: response?.firebaseUser?.emailVerified,
      });
      setProcessingAction('signup_actions.checking_data');
      await signInWithCustomToken(auth, response.token);
      return response;
    } catch (err: any) {
      if (err.type) {
        setSignUpError(t(handleFirebaseLoginError(err.type)));
      } else {
        setSignUpError(t('firebase_error.generic_error'));
      }
      setProcessingAction('');
      isNewUser.current = false;
      isRegisteringNewUser.current = false;
      return signUpError;
    } finally {
      setProcessingAction('');
      isNewUser.current = false;
      isRegisteringNewUser.current = false;
    }
  };

  const {
    mutate: signin,
    isLoading: isSigningIn,
    error: loginError,
  } = useMutation((props: { email: string; password: string }) => {
    // eslint-disable-next-line react/prop-types
    return signInWithEmailAndPassword(auth, props.email, props.password);
  });

  const logout = () => {
    setCurrentUser(null);
    return auth.signOut();
  };

  const {
    mutate: resetPassword,
    isLoading: isLoadingResetPassword,
    error: forgotPasswordError,
    isSuccess: isForgotPasswordSuccess,
    reset: resetForgotPasswordState,
  } = useMutation(
    (email: string) => {
      return sendPasswordResetEmail(
        auth,
        // eslint-disable-next-line react/prop-types
        email,
        // eslint-disable-next-line react/prop-types
      );
    },
    { cacheTime: 0 },
  );

  useEffect(() => {
    const asyncFunction = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        try {
          if (user) {
            const accessToken = await user.getIdToken(true);
            // @ts-ignore
            const us = { ...user, accessToken };
            setCurrentUser(us);
            await handleLoggedInUser(user);
          }
        } catch (err: any) {
          console.error('Signin error', err);
        } finally {
          setLoading(false);
        }
      });
      return unsubscribe;
    };

    asyncFunction();
  }, []);

  const handleLoggedInUser = async (user: any) => {
    try {
      mixpanel.identify(user.uid as string);
      trackMixpanelEvent('Sign in', { FIREBASE_ID: user.uid });
      await fetchApiUser();
      if (isNewUser.current) {
        navigate('/sign-up/registration-details');
      }
    } catch (e) {
      console.error('handleLoggedInUser: ', e);
      throw e;
    }
  };

  const {
    data: apiUser,
    mutate: fetchApiUser,
    isLoading: loadingApiUser,
  } = useMutation(
    () => {
      return findUserByFirebaseId(currentUser.accessToken, currentUser.uid);
    },
    {
      enabled: !!currentUser,
      onSuccess: (data: any) => {
        fetchUserStripeInfo({
          accessToken: currentUser.accessToken,
          customerId: data.data.customerId,
        });
        fetchAccounts();
      },
    },
  );

  const isFromIntegration = () => {
    if (
      location.pathname === '/integrations/booking-sync' ||
      location.pathname === '/integrations/hospitable'
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isFromIntegration()) {
      return;
    }
    if (apiUser && selectedAccount) {
      const isInvitedUser = apiUser?.data?.accounts?.length === 0;
      if (isInvitedUser) {
        return;
      }
      const hasCompleteRegistrationDetails =
        selectedAccount?.accountRegistrationDetails !== null;
      const hasOnboardingState = apiUser?.data?.onboardingStatus !== null;
      const hasCompleteOnboarding =
        apiUser?.data?.onboardingStatus?.completed === true;

      if (
        hasCompleteRegistrationDetails &&
        apiUser?.data?._id === selectedAccount?.owner?._id
      ) {
        if (apiUser && hasOnboardingState && !hasCompleteOnboarding) {
          navigate(`/onboarding?step=${apiUser?.data?.onboardingStatus?.step}`);
        }
      }
    }
  }, [apiUser, selectedAccount]);

  const {
    data: accountsUser,
    mutate: fetchAccounts,
    isLoading: loadingAccountsUser,
  } = useMutation(
    () => {
      return getAccountsByUser(currentUser.accessToken);
    },
    {
      enabled: !!currentUser,
      onSuccess: async (accounts: any) => {
        let localSelectedAccount = null;
        // Création d'une variable localAccount, car on n'a pas accès à la valeur selectedAccount après qu'on l'ai set dans ce scope ( useState non direct )
        const accountIdSaved = getLocalStorageAccount();
        const workspaceIdSaved = getLocalStorageWorkspace();
        if (accountIdSaved) {
          const savedAccount =
            accounts.filter((aU: Account) => aU._id === accountIdSaved)[0] ??
            accounts[0];
          onChangeAccount(savedAccount);
          localSelectedAccount = savedAccount;
        } else {
          onChangeAccount(accounts[0]);
          localSelectedAccount = accounts[0];
        }
        if (workspaceIdSaved) {
          const savedWorkspace =
            localSelectedAccount.workspaces.filter(
              (wA: Workspace) => wA._id === workspaceIdSaved,
            )[0] ?? localSelectedAccount.workspaces[0];
          await onChangeWorkspace(savedWorkspace);
        } else {
          await onChangeWorkspace(localSelectedAccount.workspaces[0]);
        }
      },
    },
  );

  const getLocalStorageWorkspace = () => {
    if (localStorage.getItem('savedWorkspaceId')) {
      const value = localStorage.getItem('savedWorkspaceId');
      return value;
    }
    return null;
  };

  const getLocalStorageAccount = () => {
    if (localStorage.getItem('savedAccountId')) {
      const value = localStorage.getItem('savedAccountId');
      return value;
    }
    return null;
  };

  const onChangeAccount = (acc: Account) => {
    localStorage.setItem('savedAccountId', acc._id);
    setSelectedAccount(acc);
  };
  const onChangeWorkspace = async (w: Workspace) => {
    localStorage.setItem('savedWorkspaceId', w._id);
    await getUserCurrentRole(w._id);
    setSelectedWorkspace(w);
  };

  const getUserCurrentRole = async (workspaceId: string) => {
    const roles = await getWorkspaceAccessesByUserAndWorkspace(
      currentUser.accessToken,
      workspaceId,
    );
    await dispatch(fetchWorkspaceCurrentRole(roles));
  };

  const {
    data: userStripeInformation,
    mutate: fetchUserStripeInfo,
    isLoading: loadingUserStripeInfo,
  } = useMutation(
    (params: any) => findCustomerById(params.accessToken, params.customerId),
    {
      enabled: !!currentUser && !!apiUser,
    },
  );

  const value = {
    currentUser,
    apiUser,
    accountsUser,
    userStripeInformation,
    loadingUserStripeInfo,
    fetchApiUser,
    signin,
    isSigningIn,
    loginError,
    forgotPasswordError,
    signup,
    processingAction,
    setSignUpError,
    signUpError,
    signupFromInvitation,
    isRegisteringNewUser,
    priceLookupKey,
    logout,
    resetPassword,
    isLoadingResetPassword,
    isForgotPasswordSuccess,
    resetForgotPasswordState,
    isAccountOwner: apiUser?.data?._id === selectedAccount?.owner?._id,
    currentWorkspace: selectedWorkspace,
    currentAccount: selectedAccount,
    updateCurrentWorkspace: onChangeWorkspace,
    updateCurrentAccount: onChangeAccount,
    refreshAccounts: fetchAccounts,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ||
      loadingApiUser ||
      loadingUserStripeInfo ||
      loadingAccountsUser ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
