import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import SignIn from './pages/signin/SignIn';
import Layout from './pages/layout/Layout';
import Orders from './pages/orders/Orders';
import Homes from './pages/homes/Homes';
import CheckoutAreas from './pages/checkout-areas/CheckoutAreas';
import Settings from './pages/settings/Settings';
import Products from './pages/products/Products';
import Profile from './pages/profile/Profile';
import RequireAuth from './components/require-auth/require-auth.component';
import Dashboard from './pages/dashboard/Dashboard';
import CheckoutSuccess from './pages/checkout-success/CheckoutSuccess';
import SignUp from './pages/signup/SignUp';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Checkout from './pages/checkout/Checkout';
import UserStatistics from './pages/admin-statistics/UserStatistics';

import Statistics from './pages/admin-statistics/Statistics';

import Tutorial from './pages/tutorial/tutorial';
import { useRole } from './hooks/useRole';
import { useAppSelector } from './hooks/useStore';
import Analytics from './pages/analytics/Analytics';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import Onboarding from './pages/onboarding/Onboarding';
import ChannelManagers from './pages/channel-managers/ChannelManagers';
import IntegrateBookingSync from './pages/integrate-booking-sync/IntegrateBookingSync';
import SignUpFromInvitation from './pages/signup/SignUpFromInvitation';
import RegistrationDetails from './pages/registration-details/RegistrationDetails';
import BookACall from './pages/book-a-call/BookACall';
import IntegrateHospitable from './pages/integrate-hospitable/integrateHospitable';
import Booking from './pages/booking/Booking';
import BookingSuccess from './pages/booking-success/BookingSuccess';
import NightUpsells from './pages/night-upsells/NightUpsells';

const Router = () => {
  const { hasAuthorization } = useRole();
  const userCurrentRole = useAppSelector((state) => state.global.currentRole);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route
            path="/onboarding"
            element={
              <div>
                <div className="z-60 fixed top-0 left-0 w-full h-screen overflow-auto ">
                  <Onboarding />
                </div>
              </div>
            }
          />
          <Route
            path="dashboard"
            element={
              hasAuthorization(userCurrentRole, [
                'MANAGER',
                'ADMIN',
                'OWNER',
                'CLEANING_STAFF',
              ]) ? (
                <Dashboard />
              ) : (
                <Navigate to="/not-found" />
              )
            }
          />

          <Route
            path="analytics"
            element={
              hasAuthorization(userCurrentRole, [
                'MANAGER',
                'ADMIN',
                'OWNER',
              ]) ? (
                <Analytics />
              ) : (
                <Navigate to="/not-found" />
              )
            }
          />
          <Route
            path="dashboard"
            element={
              hasAuthorization(userCurrentRole, [
                'MANAGER',
                'ADMIN',
                'OWNER',
              ]) ? (
                <Dashboard />
              ) : (
                <Navigate to="/not-found" />
              )
            }
          />
          <>
            <Route
              path="homes/*"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                ]) ? (
                  <Homes />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="checkout-areas/*"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                ]) ? (
                  <CheckoutAreas />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="products/*"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                ]) ? (
                  <Products />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="channel-managers"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                ]) ? (
                  <ChannelManagers />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
          </>

          <>
            <Route
              path="settings/*"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                  'CLEANING_STAFF',
                ]) ? (
                  <Settings />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="profile"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                  'CLEANING_STAFF',
                ]) ? (
                  <Profile />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="night-upsells"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                ]) ? (
                  <NightUpsells />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
            <Route
              path="orders"
              element={
                hasAuthorization(userCurrentRole, [
                  'MANAGER',
                  'ADMIN',
                  'OWNER',
                  'CLEANING_STAFF',
                ]) ? (
                  <Orders />
                ) : (
                  <Navigate to="/not-found" />
                )
              }
            />
          </>
          <Route
            path="integrations/booking-sync"
            element={<IntegrateBookingSync />}
          />
          <Route
            path="integrations/hospitable"
            element={<IntegrateHospitable />}
          />
          <Route path="tutorial" element={<Tutorial />} />
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route
          path="sign-up-from-invitation"
          element={<SignUpFromInvitation />}
        />
        <Route
          path="/sign-up/registration-details"
          element={
            <RequireAuth>
              <RegistrationDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/sign-up/book-a-call"
          element={
            <RequireAuth>
              <BookACall />
            </RequireAuth>
          }
        />
        <Route
          path="/onboarding/book-a-call"
          element={
            <RequireAuth>
              <BookACall />
            </RequireAuth>
          }
        />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="c/:homeId" element={<Checkout />} />
        <Route path="cid/:customHomeId" element={<Checkout />} />
        <Route path="checkout-success" element={<CheckoutSuccess />} />
        <Route path="booking" element={<Booking />} />
        <Route path="booking-success" element={<BookingSuccess />} />
        <Route
          path="admin/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="stats" element={<Statistics />} />
          <Route path="stats/user/:email" element={<UserStatistics />} />
        </Route>
        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default Router;
