import React from 'react';
import './checkout-alert.styles.scss';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description: string;
  isVisible: boolean;
  setIsVisible: any;
  reloadOnClose?: boolean;
}

const AlertBeforeAction = ({
  title,
  description,
  isVisible,
  setIsVisible,
  reloadOnClose,
}: Props) => {
  const { t } = useTranslation();
  const onClick = () => {
    setIsVisible(false);
    reloadOnClose && window.location.reload();
  };

  return !isVisible ? null : (
    <div className="modal-overlay">
      <div className="modal-wrapper">
        <OnClickOut callback={() => setIsVisible(false)} classname={'modal'}>
          <div className="modal-header">
            <h3 className="modal-title">{title}</h3>
          </div>
          <div className="modal-body">{description}</div>
          <div className="edit-submit-container">
            <button
              title={'Cancel'}
              className="checkout-submit-button"
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 24px',
              }}
              onClick={() => onClick()}
            >
              {t('cancel')}
            </button>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};

AlertBeforeAction.defaultProps = {
  successLabel: 'Confirm',
  cancelLabel: 'Cancel',
  reloadOnClose: false,
};

export default AlertBeforeAction;
