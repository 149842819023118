// booking/booking.utils.ts

import { addDays, isSameDay } from 'date-fns';

type Availability = {
  date: Date;
  basePriceFormatted: string;
  basePriceCents: number;
  priceWithDiscount: string;
  priceWithDiscountCents: number;
  currency: string;
};

export const parseAvailabilityDates = (availabilities: Availability[]) => {
  return availabilities.map((availability: Availability) => ({
    ...availability,
    date: availability.date,
  }));
};

/**
 * Check if current date is available for stay extension
 * Adding one day to availability date to match check-out date in calendar
 * @param date
 * @param availabilities
 */
export const isAvailableDate = (date: Date, availabilities: Availability[]) => {
  return (
    availabilities &&
    availabilities.find((availability: Availability) =>
      isSameDay(date, addDays(new Date(availability.date), 1)),
    )
  );
};
