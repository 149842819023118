import React, { useEffect, useState } from 'react';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';
import { ToggleInput } from '../../components/toggle/toggle-input.component';
import { Icon, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import DefaultHomePicture from '../../assets/images/default-home-picture.jpeg';
import { useMutation, useQuery } from 'react-query';
import {
  getAdditionnalNightConfig,
  updateAdditionnalNightConfig,
} from '../../api/additionnalNights';
import { useAuth } from '../../contexts/authContext';
import Validation from '../../utils/validation';
import SubmitButton from '../../components/submit-button/submit-button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import ChannelManagerLogo from '../../components/channel-manager-logo/channel-manager-logo.component';
import toast from 'react-hot-toast';
import {
  delayNotificationOptions,
  minNightsAvailableOptions,
} from './night-upsells.constantes';
// import useGetWorkspaceChannelManagerConnections from '../../hooks/useGetWorkspaceChannelManagerConnections';
import ChannelManagerList from '../../containers/channel-manager-list/channel-manager-list.container';
import NightUpsellsCalendarExample from '../../components/night-upsells/night-upsells-calendar-example/night-upsells-calendar-example.component';
import { classNames } from '../../utils/helpers';
import Loader from '../../components/loader/loader.component';
import { updateHome } from '../../api/homes';
import { Home } from '../../types/home.model';
import Select from '../../components/select/select.component';
import { getStripeConnectAccountByWorkspaceId } from '../../api/connectAccounts';
import { faStripe } from '@fortawesome/free-brands-svg-icons/faStripe';
import { isEmpty } from 'lodash';
import useGetChannelManagersConnectedToWorkspace from '../../hooks/useGetChannelManagersConnectedToWorkspace';

type AdditionnalNightsConfigCreateOrUpdate = {
  isAfterReservationEnabled: boolean;
  minAvailableNightsAfterReservation: number;
  offerAfterReservation: number;
  delayNotificationBeforeCheckOut: number;
  channelManagerConnections: string[];
};

const CALRY_REDIRECT_URL: string =
  process.env.REACT_APP_COZYUP_URL + '/night-upsells';
const HOSPITBALE_REDIRECT_URI: string =
  process.env.HOSPITBALE_REDIRECT_URI || '';

const NightUpsells: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const [channelManagerConnections, setChannelManagerConnections] = useState<
    any[]
  >([]);

  useQuery(
    ['stripeConnectAccount', currentUser, currentAccount],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentAccount,
      onSuccess: (data: any) => handleBankAccountOptions(data),
    },
  );

  const handleBankAccountOptions = (bankAccounts: any) => {
    const bankAccountOptions = bankAccounts?.map((ca: any) => {
      return { label: ca.name, key: ca._id };
    });
    setBankAccountOptions(bankAccountOptions);
  };

  const {
    data: additionalNightConfig,
    isLoading: isLoadingAdditionnalNightConfig,
    refetch,
  } = useQuery(
    ['additionnalNightsConfig'],
    // @ts-ignore
    () =>
      getAdditionnalNightConfig(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => {
        const currentChannelManagerConnections =
          data.config?.channelManagerConnections?.length > 0
            ? data.config.channelManagerConnections
            : [];
        setChannelManagerConnections([
          ...data.availableChannelManagerConnections.map((cm: any) => {
            return {
              ...cm,
              isSelected: false,
            };
          }),
          ...currentChannelManagerConnections.map((cm: any) => {
            return {
              ...cm,
              isSelected: true,
            };
          }),
        ]);
      },
    },
  );

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  // Set the form values when the additionalNightConfig is fetched
  useEffect(() => {
    if (additionalNightConfig) {
      setValue(
        'isNightUpsellActivated',
        additionalNightConfig?.config?.isAfterReservationEnabled,
      );
      setValue(
        'minAvailableNightsAfterReservation',
        additionalNightConfig?.config?.minAvailableNightsAfterReservation,
      );
      setValue(
        'delayNotificationBeforeCheckOut',
        additionalNightConfig?.config?.delayNotificationBeforeCheckOut,
      );

      setValue(
        'offerAfterReservation',
        additionalNightConfig?.config?.offerAfterReservation,
      );
    }
  }, [additionalNightConfig, setValue]);

  const gapSizeBetweenReservation = watch('minAvailableNightsAfterReservation');

  const delayNotificationBeforeCheckOut = watch(
    'delayNotificationBeforeCheckOut',
  );

  const nightPercentageDiscount = watch('offerAfterReservation');

  // const { workspaceChannelManagerConnection } =
  //   useGetWorkspaceChannelManagerConnections();

  const { channelManagersConnectedToWorkspace } =
    useGetChannelManagersConnectedToWorkspace();

  const handleCancelEdit = () => {
    reset({
      isNightUpsellActivated:
        additionalNightConfig?.config?.isAfterReservationEnabled,

      minAvailableNightsAfterReservation:
        additionalNightConfig?.config?.minAvailableNightsAfterReservation,

      delayNotificationBeforeCheckOut:
        additionalNightConfig?.config?.delayNotificationBeforeCheckOut,

      offerAfterReservation:
        additionalNightConfig?.config?.offerAfterReservation,
    });
    setDisabled(true);
  };

  const { mutate: update } = useMutation(
    (data: AdditionnalNightsConfigCreateOrUpdate) =>
      updateAdditionnalNightConfig(
        currentUser.accessToken,
        currentWorkspace._id,
        data,
      ),
    {
      onSuccess: () => {
        toast.success(t('night_upsells.update_success'));
        refetch();
      },
      onError: () => {
        toast.error(t('night_upsells.update_failed'));
      },
    },
  );

  const { mutate: patchHome } = useMutation(
    (data: Home) =>
      updateHome(currentUser.accessToken, currentWorkspace._id, data._id, data),
    {
      onSuccess: () => {
        refetch();
        toast.success(t('settings.stripe.stripe_updated'));
      },
      onError: () => {
        toast.error(t('settings.stripe.stripe_updated_failed'));
      },
    },
  );

  const toggleIsNightUpsellActivatedForHome = (cm: any) => {
    const updatedConnections = channelManagerConnections.map((connection) => {
      if (connection._id === cm._id) {
        return {
          ...connection,
          isSelected: !connection.isSelected,
        };
      }
      return connection;
    });
    // setChannelManagerConnections(updatedConnections);
    update({
      isAfterReservationEnabled:
        additionalNightConfig?.config?.isAfterReservationEnabled,
      minAvailableNightsAfterReservation:
        additionalNightConfig?.config?.minAvailableNightsAfterReservation,
      offerAfterReservation:
        additionalNightConfig?.config?.offerAfterReservation,
      delayNotificationBeforeCheckOut:
        additionalNightConfig?.config?.delayNotificationBeforeCheckOut,
      channelManagerConnections: updatedConnections
        .filter((cm: any) => cm.isSelected)
        .map((cm: any) => cm._id),
    });
  };

  const handleActivateConfig = () => {
    setValue('isNightUpsellActivated', true);
    update({ isAfterReservationEnabled: !disabled });
    setDisabled(false);
  };

  const submit = (data: any) => {
    const formPayload = {
      isAfterReservationEnabled: data.isNightUpsellActivated,
      minAvailableNightsAfterReservation:
        data.minAvailableNightsAfterReservation,
      offerAfterReservation: data.offerAfterReservation,
      delayNotificationBeforeCheckOut: data.delayNotificationBeforeCheckOut,
    };

    update(formPayload);
    setDisabled(true);
  };

  // @ts-ignore
  return (
    <TabWrapper>
      {/* Margin bottom so the submit buttons and the settings card don't overlap */}
      <div className="mb-24">
        <TabContentHeader
          title={t('menu.night_upsells')}
          description={t('menu.night_upsells_description')}
          rootPage="/additionnal-nights"
        />

        <TabContent>
          {isLoadingAdditionnalNightConfig ? (
            <div className="flex w-full h-full justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div>
              {!channelManagersConnectedToWorkspace?.length ? (
                <>
                  <div className="mt-10 mb-6 border-b border-grey">
                    <h2 className="pb-2 text-left text-lg font-bold text-grey">
                      {t('night_upsells.connect_channel_manager')}
                    </h2>
                  </div>
                  <div className="flex flex-row">
                    <ChannelManagerList
                      hospitableRedirectUrl={HOSPITBALE_REDIRECT_URI}
                      calryRedirectUrl={CALRY_REDIRECT_URL}
                      filterChannelManagers={{
                        isAdditionnalNightsActive: true,
                      }}
                    />
                  </div>
                </>
              ) : (
                <form onSubmit={handleSubmit(submit)}>
                  <div className="shadow-cozyup lg:card rounded-2xl px-2 lg:my-4">
                    <div className="flex justify-between items-center">
                      <h5 style={{ textAlign: 'left', margin: 0 }}>
                        {t('setup')}
                      </h5>
                      {additionalNightConfig?.config
                        ?.isAfterReservationEnabled ? (
                        <div
                          className={classNames(
                            disabled ? 'opacity-100' : 'opacity-50',
                            'btn btn-warning rounded-xl',
                          )}
                          onClick={() => setDisabled(false)}
                        >
                          {t('edit')}
                        </div>
                      ) : (
                        <div
                          className="btn btn-ghost-success rounded-xl"
                          onClick={() => {
                            handleActivateConfig();
                          }}
                        >
                          {t('activate')}
                        </div>
                      )}
                    </div>
                    {channelManagersConnectedToWorkspace && (
                      <div
                        className={classNames(
                          additionalNightConfig?.config
                            ?.isAfterReservationEnabled || !disabled
                            ? 'opacity-100'
                            : 'opacity-50',
                        )}
                      >
                        <div className="flex flex-col lg:flex-row">
                          <div className="lg:w-3/5 flex flex-col mt-4">
                            <div>
                              <div className="flex justify-between items-center w-full">
                                <div className="flex flex-row items-center text-md lg:text-lg font-satoshiBold text-left ">
                                  {t('night_upsells.enable')}
                                  <Tooltip
                                    title={t('night_upsells.enable_tooltip')}
                                    className="ml-1"
                                    enterTouchDelay={0}
                                  >
                                    <Icon
                                      className="icon text-gray-300 cursor-pointer opacity-75"
                                      component={HelpOutline}
                                    />
                                  </Tooltip>
                                </div>
                                <Controller
                                  control={control}
                                  name={'isNightUpsellActivated'}
                                  render={({ field: { onChange, value } }) => (
                                    <div
                                      className={classNames(
                                        disabled ? 'opacity-50' : 'opacity-100',
                                      )}
                                    >
                                      <ToggleInput
                                        enabled={value}
                                        setEnabled={onChange}
                                        disabled={disabled}
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="flex items-center text-md lg:text-lg text-left font-satoshiBold mt-4 mb-2">
                              {t('night_upsells.conditions')}
                              <Tooltip
                                title={t('night_upsells.conditions_tootlip')}
                                className="ml-1"
                                enterTouchDelay={0}
                              >
                                <Icon
                                  className="icon text-gray-300 cursor-pointer opacity-75"
                                  component={HelpOutline}
                                />
                              </Tooltip>
                            </div>
                            <div>
                              <div className="text-xs lg:text-md text-left flex flex-wrap items-center gap-2">
                                {t('night_upsells.condition_1_1')}
                                <Controller
                                  control={control}
                                  name={'minAvailableNightsAfterReservation'}
                                  rules={Validation.anyRequired}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="w-24 text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none bg-white border-gray-400 rounded-3xl  h-6 flex"
                                      disabled={disabled}
                                    >
                                      {minNightsAvailableOptions.map(
                                        ({
                                          label,
                                          value,
                                        }: {
                                          label: string;
                                          value: number;
                                        }) => (
                                          <option key={value} value={value}>
                                            {t(label, {
                                              count: value,
                                              totalNights: value,
                                            })}
                                          </option>
                                        ),
                                      )}
                                    </select>
                                  )}
                                />
                                {errors.minAvailableNightsAfterReservation && (
                                  <span>This field is required</span>
                                )}
                                <span>{t('night_upsells.condition_1_2')}</span>
                              </div>
                              <div className="my-4 text-xs lg:text-md text-left flex flex-wrap items-center gap-2">
                                <span>{t('night_upsells.condition_2_1')}</span>
                                <Controller
                                  control={control}
                                  name={'delayNotificationBeforeCheckOut'}
                                  rules={Validation.anyRequired}
                                  render={({ field }) => (
                                    <select
                                      {...field}
                                      className="w-24 text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none bg-white border-gray-400 rounded-3xl  h-6 flex"
                                      disabled={disabled}
                                    >
                                      {delayNotificationOptions.map(
                                        ({
                                          label,
                                          value,
                                        }: {
                                          label: string;
                                          value: number;
                                        }) => (
                                          <option key={value} value={value}>
                                            {t(label, {
                                              count: value,
                                              totalDays: value,
                                            })}
                                          </option>
                                        ),
                                      )}
                                    </select>
                                  )}
                                />
                                {errors.delayNotificationBeforeCheckOut && (
                                  <span>This field is required</span>
                                )}

                                <span>{t('night_upsells.condition_2_2')}</span>
                              </div>
                              <div className="my-4 text-xs lg:text-md text-left flex flex-wrap items-center gap-2">
                                <span>{t('night_upsells.condition_3_1')}</span>
                                <Controller
                                  control={control}
                                  rules={Validation.anyRequired}
                                  name={'offerAfterReservation'}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      className={classNames(
                                        disabled
                                          ? 'opacity-70 hover:border-0 hover:border-gray-400'
                                          : 'border opacity-100 focus:border-primary',
                                        'w-16 px-3 text-gray-700 border focus-visible:border-primary outline-none bg-white border-gray-400 rounded-3xl  h-6',
                                      )}
                                      disabled={disabled}
                                      type="number"
                                      min={0}
                                      max={100}
                                      onChange={onChange}
                                      value={value}
                                    />
                                  )}
                                />
                                {errors.offerAfterReservation && (
                                  <span>This field is required</span>
                                )}

                                <span>{t('night_upsells.condition_3_2')}</span>
                              </div>
                            </div>
                            <div className="flex flex-col justify-start">
                              <div className="flex items-center text-lg text-left font-satoshiBold mt-4 mb-2">
                                {t('night_upsells.notification')}
                                <Tooltip
                                  title={t(
                                    'night_upsells.notification_tooltip',
                                  )}
                                  className="ml-1"
                                  enterTouchDelay={0}
                                >
                                  <Icon
                                    className="icon text-gray-300 cursor-pointer opacity-75"
                                    component={HelpOutline}
                                  />
                                </Tooltip>
                              </div>
                              <p className="bg-lightGrey rounded-2xl p-6 text-left whitespace-break-spaces">
                                {t('night_upsells.notification_template')}
                              </p>
                            </div>
                          </div>
                          <div className="hidden lg:flex w-[1px] ml-5 bg-grey " />
                          <div className="lg:w-2/5 mt-4 lg:mt-0 flex flex-col justify-center items-center">
                            <NightUpsellsCalendarExample
                              gapSizeBetweenReservation={
                                gapSizeBetweenReservation
                              }
                              dayBeforeNotification={
                                delayNotificationBeforeCheckOut
                              }
                              nightPercentageDiscount={nightPercentageDiscount}
                              percentageFeeHost={
                                currentAccount.percentageFeeHost
                              }
                            />
                          </div>
                        </div>
                        {disabled ? null : (
                          <div className="flex justify-center w-full">
                            <button
                              title={t('cancel')}
                              className="btn rounded-3xl btn-primary-outlined bg-white"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              onClick={() => handleCancelEdit()}
                            >
                              {t('cancel')}
                            </button>
                            <SubmitButton
                              title={t('confirm')}
                              customClass={'btn rounded-3xl btn-primary flex'}
                              disabled={isLoadingAdditionnalNightConfig}
                            >
                              {!isLoadingAdditionnalNightConfig ? null : (
                                <FontAwesomeIcon
                                  style={{ marginLeft: 15 }}
                                  icon={faSpinnerThird}
                                  spin
                                />
                              )}
                            </SubmitButton>
                          </div>
                        )}
                        <div className="mb-4 lg:mr-4 mt-4">
                          <div className="flex justify-between items-center my-4">
                            <div className="flex items-center text-lg text-left font-satoshiBold mt-4 mb-2">
                              {t('night_upsells.active_properties')}
                              {` (${
                                channelManagerConnections?.filter(
                                  (cm: any) => cm.isSelected,
                                ).length
                              })`}
                              <Tooltip
                                title={t(
                                  'night_upsells.active_properties_tooltip',
                                )}
                                className="ml-1"
                                enterTouchDelay={0}
                              >
                                <Icon
                                  className="icon text-gray-300 cursor-pointer opacity-75"
                                  component={HelpOutline}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          {isLoadingAdditionnalNightConfig ? (
                            <Loader />
                          ) : (
                            <div className="bg-lightblue rounded-xl px-3 py-6 overflow-auto">
                              {channelManagerConnections
                                ?.filter((cm: any) => !isEmpty(cm))
                                ?.sort((a: any, b: any) =>
                                  a.home.name.localeCompare(b.home.name),
                                )
                                .map((cm: any) => (
                                  <div
                                    key={cm._id}
                                    className={`p-2 border rounded-xl border-grey bg-white my-1 w-full min-h-[40px] ${
                                      cm?.isSelected ? '' : 'bg-transparent'
                                    }`}
                                  >
                                    <div className="flex justify-between items-center w-full">
                                      <div className="flex justify-start items-center w-4/5">
                                        <div className="text-left flex items-center gap-4">
                                          <div className="w-28 h-18">
                                            <img
                                              className="h-full w-full rounded-xl object-cover"
                                              src={
                                                cm?.home?.picture ||
                                                DefaultHomePicture
                                              }
                                              alt={cm?.home?.name}
                                            />
                                          </div>
                                          <div className="flex flex-1 flex-col justify-start h-full">
                                            <div className="text-sm mb-2">
                                              {cm?.home?.name}
                                            </div>
                                            {cm?.channelManagerType?.name && (
                                              <div className="w-20">
                                                <ChannelManagerLogo
                                                  type={
                                                    cm?.channelManagerType.name
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="ml-10">
                                          {cm.home?.address?.city && (
                                            <div className="flex items-center justify-center bg-lightGrey rounded-full p-2">
                                              <FontAwesomeIcon
                                                icon={faLocationDot}
                                                className="text-gray-500 mr-1"
                                              />
                                              <p className="text-greyText lowercase first-letter:uppercase text-xs">
                                                {cm.home?.address?.city}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="flex items-center justify-center mr-10 w-1/3">
                                        <div>
                                          <FontAwesomeIcon
                                            // @ts-ignore
                                            icon={faStripe}
                                            size="3x"
                                            className="mr-2 text-[#6058F7]"
                                          />
                                        </div>
                                        <Select
                                          key={`stripeConnectAccount-${cm.home._id}`}
                                          onChange={(e: any) => {
                                            patchHome({
                                              _id: cm.home._id,
                                              nightUpsellStripeConnectAccount:
                                                e.target.value,
                                            });
                                          }}
                                          value={
                                            cm.home
                                              ?.nightUpsellStripeConnectAccount
                                              ?._id
                                          }
                                          style={{ marginBottom: 0 }}
                                          options={bankAccountOptions}
                                        />
                                      </div>
                                      <div className="mr-4">
                                        <ToggleInput
                                          enabled={cm.isSelected}
                                          setEnabled={() => {
                                            toggleIsNightUpsellActivatedForHome(
                                              cm,
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </div>
          )}
        </TabContent>
      </div>
    </TabWrapper>
  );
};

export default NightUpsells;
