import React from 'react';
import { useAuth } from '../../contexts/authContext';
import { Icon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

export const ListUsers = ({ users, onDelete }: any) => {
  const { currentAccount } = useAuth();
  const { t } = useTranslation();
  //TODO: This component cause error on render if we don't use user._id instead of user?._id
  //It shouldn't since the component is rendered only if object users isn't empty
  return (
    <div>
      {users && users?.length ? (
        <table className="cozyup table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('settings.name')}</th>
              <th>{t('settings.email')}</th>
              <th>{t('settings.workspace.workspace_other')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="mt-6">
            {users &&
              users.map((user: any, i: number) => (
                <tr key={user?._id} className="hover:shadow-none">
                  <td>{i + 1}</td>
                  <td>
                    {user?.firstName} {user?.lastName}
                  </td>
                  <td>{user?.email}</td>
                  <td>
                    {
                      user?.workspaceAccesses.filter(
                        (wA: any) =>
                          wA.workspace.account?._id === currentAccount?._id,
                      ).length
                    }
                  </td>
                  <td>
                    <div className="flex justify-end items-center">
                      <Tooltip title={`${t('delete')}`}>
                        <div
                          onClick={() => onDelete(user._id)}
                          className="icon w-fit hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                        >
                          <Icon
                            className="h-10 w-10 text-red-500"
                            fontSize="small"
                            component={DeleteIcon}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-sm font-semibold text-[#6B7194] opacity-20">
          {t('tables.empty-users')}
        </div>
      )}
    </div>
  );
};
