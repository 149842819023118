import React, { useState } from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../utils/helpers';
import Input from '../input/input.component';

// import { useMutation } from 'react-query';
import { useMutation } from 'react-query';
import { importFromAirbnb } from '../../api/homes';
import { useAuth } from '../../contexts/authContext';

import { Icon } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

import AirbnbLogo from '../../assets/images/airbnb-logo.svg';
import AirbnbStar from '../../assets/images/airbnb-star.svg';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccess: Function;
};

export const ImportAirbnbOnboardingModal = ({
  displayModal,
  setDisplayModal,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [isImported, setIsImported] = useState<boolean>(false);
  const [urlImport, setUrlImport] = useState<string>('');

  const [importError, setImportError] = useState<string>('');

  const {
    mutate: importProperty,
    data: propertyImported,
    isLoading: isLoadingProperty,
    reset: resetImport,
  } = useMutation(
    (propertyId: string) =>
      importFromAirbnb(currentUser.accessToken, propertyId),
    {
      onSuccess: () => {
        setIsImported(true);
      },
      onError: (error: any) => {
        setImportError(t(error.code || 'errors.unknown_error'));
      },
    },
  );

  const handleAddProperty = (property: any) => {
    const formattedProperty = {
      name: property.name,
      picture: property.imageUrl,
      customId: property?.customId || null,
      address: {
        street: property?.street,
        streetNumber: property?.streetNumber,
        zipcode: property?.zipcode,
        city: property.location,
        country: property?.country,
      },
    };

    onSuccess(formattedProperty);
    resetImport();
    setUrlImport('');
    setDisplayModal(false);
  };

  const getPropertyIdFromUrl = (url: any) => {
    const matches = url.match(/rooms\/(\d+)/) || url.match(/listings\/(\d+)/);
    return matches ? matches[1] : null;
  };

  const handleImportProperty = () => {
    setImportError('');
    const propertyId = getPropertyIdFromUrl(urlImport);
    if (!propertyId) return;
    importProperty(propertyId);
  };

  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleOnUrlChange = (e: any) => {
    const urlValue = e.target.value;
    setImportError('');
    setUrlImport(e.target.value);
    const airbnbUrlPattern =
      /^https:\/\/.*airbnb\.[a-z]{2,3}\/(rooms\/\d+|hosting\/listings\/\d+\/details)(\?.*)?$/;
    if (!urlValue) {
      setError('airbnbURL', {
        type: 'manual',
        message: t('errors.field_required'),
      });
    } else if (!airbnbUrlPattern.test(urlValue)) {
      setError('airbnbURL', {
        type: 'manual',
        message: t('errors.airbnb_url_invalid'),
      });
    } else {
      clearErrors('airbnbURL'); // Clear the error if the new value is valid
    }
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative w-[95%] flex flex-col justify-between max-w-[800px] min-h-[350px] lg:min-h-[150px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-[36px] mb-4">
                {t('listings.import_from_airbnb_header')}
              </h5>
            </div>
          </div>

          <div className="px-4 lg:px-10 py-10">
            {!propertyImported && (
              <div className="flex items-center">
                <div className="mt-6 flex-1">
                  <Controller
                    control={control}
                    render={() => (
                      <Input
                        type="text"
                        key="name"
                        value={urlImport}
                        helperText={t('listings.example_airbnb_link')}
                        onChange={handleOnUrlChange}
                        label={`${capitalizeFirstLetter(
                          t('listings.link_to_airbnb_listing'),
                        )}`}
                        errors={[_.get(errors, 'airbnbURL.message')]}
                      />
                    )}
                    name="airbnbURL"
                  />
                </div>
              </div>
            )}
            <div className="">
              <div className="text-red-600">{importError}</div>
              {propertyImported && (
                <div>
                  <div className="text-left mb-3">
                    <img
                      src={AirbnbLogo}
                      className="object-contain h-10 "
                      alt="Airbnb logo"
                    />
                  </div>
                  <div className="relative flex space-between w-full p-3 border border-checkoutPrimary rounded-2xl">
                    <div className="grid grid-cols-1 lg:flex gap-3 lg:flex-1">
                      <img
                        className="w-full lg:w-2/5 h-auto rounded-xl"
                        src={propertyImported?.imageUrl}
                        alt={propertyImported?.name}
                      />
                      <div className="w-full lg:w-3/5">
                        <h3 className="text-left text-[22px] lg:text-[26px] font-default text-[#222222]">
                          {propertyImported?.name}
                        </h3>
                        <div className="flex items-center justify-start font-semibold">
                          <img
                            className="w-2 h-2 mr-1"
                            src={AirbnbStar}
                            alt="Airbnb star"
                          />
                          <p className="text-left text-[10px] lg:text-[14px] font-default mr-1">
                            {propertyImported?.guestSatisfactionOverall}
                          </p>
                          {'•'}
                          <p className="text-left text-[10px] lg:text-[14px] font-default pl-1 underline">
                            {propertyImported?.visibleReviewCount}{' '}
                            {t('listings.reviews', {
                              count: parseInt(
                                propertyImported?.visibleReviewCount,
                              ),
                            })}
                          </p>
                          <span className="m-2">{'•'}</span>
                          <p className="text-left text-[10px] lg:text-[14px] font-default underline">
                            {propertyImported?.location}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-2 right-2 lg:top-1 lg:right-1">
                      <Icon
                        className="text-middleGrey hover:text-red-500 cursor-pointer"
                        fontSize="medium"
                        component={CancelIcon}
                        onClick={resetImport}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            {propertyImported ? (
              <div className="gap-2 flex justify-end">
                <button
                  disabled={!isImported}
                  onClick={() => {
                    handleAddProperty(propertyImported);
                  }}
                  className="btn btn-primary rounded-3xl"
                >
                  {t('add')}
                </button>
              </div>
            ) : (
              <div
                className="cursor-pointer btn btn-primary"
                onClick={handleImportProperty}
              >
                {t('import')}
                {!isLoadingProperty ? null : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </div>
            )}
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};
