import React, { useEffect } from 'react';
import '../../App.css';
import './forgot-password.styles.scss';
import { useForm } from 'react-hook-form';
import ForgotPasswordForm from './forgot-password-form/forgot-password-form.component';
import BackButton from '../../components/back-button/back-button.component';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const { currentUser, isForgotPasswordSuccess } = useAuth();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, []);

  const { t } = useTranslation();
  useForm();

  //TODO: Refactor design as in SignUp.tsx (create form, use input.component.tsx
  return (
    <div className="min-h-screen relative flex flex-col items-center justify-center">
      <BackButton />
      <div className="relative mx-auto lg:w-1/3 w-full px-6">
        <div className="flex flex-col">
          <a
            href="https://cozyup.com"
            className="logo-wrapper-account w-inline-block"
            style={{ borderRadius: 100 }}
          >
            <img
              src="https://cozyup-public.s3.eu-west-3.amazonaws.com/logo-square-clear-blue.png"
              loading="lazy"
              alt=""
              className="account-icon"
            />
          </a>
          <h5>{t('auth.forgot-password')}</h5>
          <p className="mt-6 text-left text-[#5a6978]">
            {isForgotPasswordSuccess
              ? t('auth.forgot-password-description-success')
              : t('auth.forgot-password-description')}
          </p>
          <div className="mt-6 w-full">
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
      <div className="text-grey text-md absolute flex justify-center w-full bottom-5 item-end text-center">
        {t('copyright', { year: currentYear })}
      </div>
    </div>
  );
};

export default ForgotPassword;
