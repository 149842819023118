import _ from 'lodash';

export const getBackendErrorsByAttributes = (
  backendErrors: any,
  attributes: any,
) =>
  _.filter(backendErrors, (error: any) =>
    attributes.includes(error.attribute),
  ).map((error: any) => error.localized.full_message);

export const getBackendErrorsByAttribute = (
  backendErrors: any,
  attribute: any,
) => {
  return getBackendErrorsByAttributes(backendErrors, [attribute]);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getDaysBetweenDateToNow = (dateTimestamp: number) => {
  const daysLeft =
    new Date(dateTimestamp * 1000).getTime() - new Date(Date.now()).getTime();
  return Math.round(daysLeft / (24 * 3600 * 1000));
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};
