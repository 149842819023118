import React, { useState } from 'react';

import Button from '../button/button.component';
import { useTranslation } from 'react-i18next';
import { Emoji } from 'emoji-mart';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSpinnerThird,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { Colors } from '../../assets/Colors';

type ValidateOrderProductsModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccess: Function;
  order: any;
  isLoadingValidation: boolean;
};

export default function ValidateOrderProductsModal({
  displayModal,
  setDisplayModal,
  onSuccess,
  order,
  isLoadingValidation,
}: ValidateOrderProductsModalProps) {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState(
    [...order.products].map((product) => ({
      ...product,
      isValidated: product.isValidationRequired,
      hasBeenChecked: product.item.validationRequired ? false : true,
    })),
  );
  const [error, setError] = useState('');

  const validateProduct = (index: number) => {
    // Mettre à jour la propriété isValidated du produit sélectionné
    const newProducts = [...products];
    newProducts[index].isValidated = true;
    newProducts[index].hasBeenChecked = true;
    setProducts(newProducts);
  };

  const unvalidateProduct = (index: number) => {
    // Mettre à jour la propriété isValidated du produit sélectionné
    const newProducts = [...products];
    newProducts[index].isValidated = false;
    newProducts[index].hasBeenChecked = true;
    setProducts(newProducts);
  };

  const sendValidatedProducts = () => {
    // Filtrer et envoyer les produits validés
    const notAllProductsChecked = products.filter(
      (product: any) => !product.hasBeenChecked,
    ).length;
    if (notAllProductsChecked) {
      setError(t('orders.require_validation'));
      return;
    }
    const validatedProducts = products
      .filter((product) => product.isValidated)
      .map((product) => product.item._id);
    onSuccess(validatedProducts);
  };

  return (
    <>
      {!displayModal ? null : (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
          <div className="fixed bg-white lg:bg-inherit top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
            <div className="relative flex flex-col justify-between w-full max-w-[900px] lg:min-h-[700px] bg-white lg:mt-16 mx-auto lg:rounded-[16px]">
              <div className="max-h-[100px]">
                <div className="border-b px-4 py-2">
                  <h3 className="text-left text-[22px]">
                    {t('orders.validation_order')}
                  </h3>
                  <p className="text-left text-[14px] font-satoshiRegular"></p>
                </div>
                <div className="px-2 py-10 lg:p-10">
                  <div className="hidden lg:flex mb-3 justify-start">
                    <span className="text-grey text-lg">
                      {t('orders.extra_count', { count: products.length })}
                    </span>
                  </div>
                  <div className="lg:h-[400px] overflow-y-auto lg:border rounded-2xl pt-4 pb-12 lg:py-6 ">
                    {products.map((product: any, index: number) => (
                      <div
                        key={index}
                        className="flex gap-4 lg:gap-0 lg:flex-row flex-col items-center justify-between mb-4 border-b pb-4 px-6"
                      >
                        <div className="flex w-full lg:w-3/4 flex-1 gap-3 lg:gap-8 flex-col md:flex-row items-center">
                          <div className="flex flex-col lg:flex-row lg:w-3/4 items-center gap-4 lg:gap-3">
                            <div>
                              {!product.item.emoji ? null : (
                                <Emoji emoji={product.item.emoji} size={36} />
                              )}
                            </div>
                            <div className="justify-center lg:text-left flex flex-col  gap-2">
                              <p className="font-semibold text-md md:text-lg">
                                {product.item.translations?.filter(
                                  (t: any) =>
                                    t.languageCode ===
                                    i18n.language.slice(0, 2),
                                ).length
                                  ? product.item.translations.filter(
                                      (t: any) =>
                                        t.languageCode ===
                                        i18n.language.slice(0, 2),
                                    )[0].title
                                  : product.item.name}
                                <span className="hidden">
                                  {product.item.validationRequired && (
                                    <span className="text-red-500 text-sm">
                                      *{t('orders.require_validation')}
                                    </span>
                                  )}
                                </span>
                              </p>
                              <p className="text-gray-500 text-sm lg:text-lg flex gap-2">
                                <span>{product.quantity}</span>
                                <span>x</span>
                                {displayPrice(
                                  product.item.sellingPrice,
                                  getCurrencyInfo(order.currency).symbol,
                                  { thousandSeparator: ' ' },
                                )}
                              </p>
                            </div>
                            <div className="lg:hidden">
                              {product.item.validationRequired && (
                                <div>
                                  <span className="text-red-500 text-sm">
                                    *{t('orders.require_validation')}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="md:w-1/4 flex justify-end">
                          {product.item.validationRequired ? (
                            <div>
                              <span>{t('orders.validate_extra_question')}</span>
                              <div className="flex items-center justify-center gap-3 mt-2">
                                <div
                                  className="w-10 h-10 flex items-center justify-center p-2 border rounded-md border-error cursor-pointer"
                                  style={{
                                    backgroundColor:
                                      !products[index].isValidated &&
                                      products[index].hasBeenChecked
                                        ? Colors.error
                                        : 'transparent',
                                  }}
                                  onClick={() => unvalidateProduct(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    color={
                                      !products[index].isValidated &&
                                      products[index].hasBeenChecked
                                        ? '#fff'
                                        : Colors.error
                                    }
                                  />
                                </div>
                                <div
                                  className="w-10 h-10 p-2 flex items-center justify-center border rounded-md border-green cursor-pointer"
                                  style={{
                                    backgroundColor:
                                      products[index].isValidated &&
                                      products[index].hasBeenChecked
                                        ? Colors.green
                                        : 'transparent',
                                  }}
                                  onClick={() => validateProduct(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    color={
                                      products[index].isValidated &&
                                      products[index].hasBeenChecked
                                        ? '#fff'
                                        : Colors.green
                                    }
                                  />
                                </div>
                              </div>
                              {
                                <div className="text-error text-xs mt-2">
                                  {products[index].hasBeenChecked ? null : (
                                    <p className="text-red-500 text-sm">
                                      {error}
                                    </p>
                                  )}
                                </div>
                              }
                            </div>
                          ) : (
                            <div className="px-3 self-center border rounded-full border-green">
                              <p className="text-green p-2">{t('validated')}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="fixed bg-white lg:bg-transparent bottom-0 lg:relative border-t px-4 py-4 gap-2 flex justify-end w-full lg:w-auto">
                <div className="gap-2 flex justify-end">
                  <Button
                    disabled={isLoadingValidation}
                    className="btn btn-primary-outlined rounded-3xl"
                    onClick={() => setDisplayModal(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    disabled={isLoadingValidation}
                    onClick={() => sendValidatedProducts()}
                    className="btn btn-primary rounded-3xl"
                  >
                    {t('orders.confirm-order')}
                    {!isLoadingValidation ? null : (
                      <FontAwesomeIcon
                        style={{ marginLeft: 15 }}
                        icon={faSpinnerThird}
                        spin
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
