import React, { useState } from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { formatDateToLocaleDateString } from '../../utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../pagination/pagination.component';
import SearchBar from '../search-bar/search-bar.component';
import Loader from '../loader/loader.component';

type WorkspaceOnboardingProps = {
  items: any;
  isLoading: boolean;
  setPage: Function;
};

export const WorkspaceOnboarding = ({
  items,
  isLoading,
  setPage,
}: WorkspaceOnboardingProps) => {
  const [searchText, setSearchText] = useState('');

  const handleIsSetup = (hasOne: boolean) => {
    return hasOne ? (
      <FontAwesomeIcon icon={faCheckCircle} color="green" />
    ) : (
      <FontAwesomeIcon icon={faTimesCircle} color="red" />
    );
  };

  const filterByAccountAndWorkspace = (onboardingStat: any) => {
    return (
      onboardingStat?.account?.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      onboardingStat?.title.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <div>
      <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
        <h5
          className="lg:text-[36px]"
          style={{
            textAlign: 'left',
          }}
        >
          Workspace onboardings
        </h5>
        <div className="w-1/3">
          <SearchBar
            searchText={searchText || ''}
            setSearchText={setSearchText}
          />
        </div>
      </div>
      <div className=" hidden md:flex">
        {!items || items?.metadata?.totalPages === 0 ? null : (
          <Pagination
            totalPagesCount={items?.metadata?.totalPages}
            currentPage={items?.metadata?.currentPage}
            totalItems={items?.metadata?.totalCount}
            onChangePage={setPage}
          />
        )}
      </div>
      <table className="cozyup table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Workspace</th>
            <th>Creation Date</th>
            <th>Homes</th>
            <th>CheckoutAreas</th>
            <th>Products</th>
            <th>Orders</th>
          </tr>
        </thead>
        <tbody className="mt-6">
          {!isLoading ? null : (
            <div className="flex justify-center items-center height-[150px]">
              <Loader />
            </div>
          )}
          {!items
            ? null
            : items?.data
                ?.filter((workspace: any) =>
                  filterByAccountAndWorkspace(workspace),
                )
                .sort(
                  (uA: any, uB: any) =>
                    new Date(uB.created_at).getTime() -
                    new Date(uA.created_at).getTime(),
                )
                .map((workspaceStats: any, index: number) => (
                  <tr
                    key={index}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_COZYUP_URL}/admin/stats/user/${workspaceStats?.account?.owner?.email}`,
                        '_blank',
                      )
                    }
                  >
                    <td>{workspaceStats?.account?.name}</td>
                    <td>{workspaceStats?.title}</td>
                    <td>
                      {formatDateToLocaleDateString(workspaceStats?.createdAt)}
                    </td>
                    <td className="w-[15%]">
                      {handleIsSetup(workspaceStats?.homes)}
                    </td>
                    <td className="w-[15%]">
                      {handleIsSetup(workspaceStats?.checkoutAreas)}
                    </td>
                    <td className="w-[15%]">
                      {handleIsSetup(workspaceStats?.products)}
                    </td>
                    <td className="w-[15%]">
                      {handleIsSetup(workspaceStats?.orders)}
                    </td>
                  </tr>
                ))}
        </tbody>
      </table>
      <div className=" hidden md:flex">
        {!items || items?.metadata?.totalPages === 0 ? null : (
          <Pagination
            totalPagesCount={items?.metadata?.totalPages}
            currentPage={items?.metadata?.currentPage}
            totalItems={items?.metadata?.totalCount}
            onChangePage={setPage}
          />
        )}
      </div>
    </div>
  );
};
