import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import useSocket from '../../hooks/useSocket';
import { useTranslation } from 'react-i18next';

type NotificationType = {
  name?: string;
  id: string;
};

const ToastManager = () => {
  const { onEvent } = useSocket();
  const { t } = useTranslation();
  useEffect(() => {
    onEvent('product:translation:init', (data: NotificationType) => {
      let toastProductId = data.id;
      toast.loading(
        `${t('toast.products.translation-init', { name: data.name })}`,
        {
          duration: 10000000,
          id: toastProductId,
        },
      );
      onEvent('product:translation:complete', (data: NotificationType) => {
        if (toastProductId === data.id) {
          toast.success(
            `${t('toast.products.translation-complete', { name: data.name })}`,
            {
              duration: 3000,
            },
          );
          toast.dismiss(toastProductId);
        }
      });

      onEvent('product:translation:error', (data: NotificationType) => {
        if (toastProductId === data.id) {
          toast.error(`${t('toast.products.translation-error')}`, {
            duration: 3000,
          });
          toast.remove(toastProductId);
        }
      });
    });

    onEvent('home:translation:init', (data: NotificationType) => {
      let toastHomeId = data.id;

      toast.loading(
        `${t('toast.homes.translation-init', { name: data.name })}`,
        {
          duration: 10000000,
          id: toastHomeId,
        },
      );

      onEvent('home:translation:complete', (data: NotificationType) => {
        if (toastHomeId === data.id) {
          toast.success(
            `${t('toast.homes.translation-complete', { name: data.name })}`,
            {
              duration: 3000,
            },
          );
          toast.dismiss(toastHomeId);
        }
      });

      onEvent('home:translation:error', (data: NotificationType) => {
        if (toastHomeId === data.id) {
          toast.error(`${t('toast.homes.translation-error')}`, {
            duration: 3000,
          });
          toast.remove(toastHomeId);
        }
      });
    });
  }, []);

  return null;
};

export default ToastManager;
