import React from 'react';

type OpenMenuButtonProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: Function;
};
const OpenMenuButton = ({ isMenuOpen, setIsMenuOpen }: OpenMenuButtonProps) => {
  return (
    <button
      className="text-gray-500 relative focus:outline-none bg-white w-[25px] ml-[20px]"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <div className="flex flex-col items-center">
        <span
          aria-hidden="true"
          className={
            'mx-4 mb-4  absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ' +
            (isMenuOpen ? 'rotate-45' : '-translate-y-1.5')
          }
        ></span>
        <span
          aria-hidden="true"
          className={
            'mx-4 mb-4  absolute  h-0.5 w-5 bg-current  transform transition duration-500 ease-in-out ' +
            (isMenuOpen ? 'opacity-0' : '')
          }
        ></span>
        <span
          aria-hidden="true"
          className={
            'mx-4 mb-4  absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out ' +
            (isMenuOpen ? '-rotate-45' : 'translate-y-1.5')
          }
        ></span>
      </div>
    </button>
  );
};

export default OpenMenuButton;
