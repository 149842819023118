import React from 'react';
import { useTranslation } from 'react-i18next';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from '../submit-button/submit-button.component';
import Input from '../input/input.component';
import _ from 'lodash';
import { CompanyDB } from '../../types/company.model';
import { ToggleInput } from '../toggle/toggle-input.component';

interface CompanyFormProps {
  title: string;
  item: CompanyDB;
  update: Function;
  setIsDisabled: Function;
  isDisabled: boolean;
}

const CompanyForm = ({
  title,
  item,
  update,
  setIsDisabled,
  isDisabled,
}: CompanyFormProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const handleCancelEditCompany = () => {
    reset();
    setIsDisabled(true);
  };

  const submitCompany = async (company: any) => {
    await update(company);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-6 w-full border-b py-2 border-gray-200">
        <h5
          style={{
            textAlign: 'left',
            margin: 0,
          }}
        >
          {title}
        </h5>
        <div
          className="btn btn-warning rounded-xl"
          onClick={() => setIsDisabled(false)}
        >
          {t('edit')}
        </div>
      </div>
      <form onSubmit={handleSubmit(submitCompany)}>
        <div className="flex">
          <div className="pr-4 w-1/2 lg:w-full">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.name || ''}
              render={({ field: { onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="name"
                  label={t('settings.company.company_name')}
                  size="small"
                  onChange={onChange}
                  value={value}
                  errors={[
                    _.get(errors, 'name.message'),
                    // ...backendErrorsForAttribute('workspace.title'),
                  ]}
                />
              )}
              name={'name'}
            />
          </div>
          <div className="w-1/2 lg:w-full">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.identifierId || ''}
              render={({ field: { onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="identifierId"
                  label={t('settings.company.registration_id')}
                  size="small"
                  onChange={onChange}
                  value={value}
                  errors={[
                    _.get(errors, 'identifierId.message'),
                    // ...backendErrorsForAttribute('workspace.title'),
                  ]}
                />
              )}
              name={'identifierId'}
            />
          </div>
        </div>
        <div>
          <Controller
            control={control}
            defaultValue={item?.vatId || ''}
            render={({ field: { onChange, value } }: any) => (
              <Input
                disabled={isDisabled}
                key="vatId"
                label={`${t('settings.company.tax_id')} (${t('optional')})`}
                size="small"
                onChange={onChange}
                value={value}
                errors={[
                  _.get(errors, 'vatId.message'),
                  // ...backendErrorsForAttribute('workspace.company.tva_id'),
                ]}
              />
            )}
            name={'vatId'}
          />
        </div>
        <div className="flex justify-between">
          <div className="w-1/3">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.address?.streetNumber || ''}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="address.streetNumber"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.street_number')}
                  errors={[
                    _.get(errors, 'address.streetNumber.message'),
                    // ...backendErrorsForAttribute('streetNumber'),
                  ]}
                />
              )}
              name={'address.streetNumber'}
            />
          </div>
          <div className="pl-4 flex-1">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.address?.street || ''}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="address.street"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.street')}
                  errors={[
                    _.get(errors, 'address.street.message'),
                    // ...backendErrorsForAttribute('street'),
                  ]}
                />
              )}
              name={'address.street'}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/3">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.address?.zipcode || ''}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="address.zipcode"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.zipcode')}
                  errors={[
                    _.get(errors, 'address.zipcode.message'),
                    // ...backendErrorsForAttribute('zipcode'),
                  ]}
                />
              )}
              name={'address.zipcode'}
            />
          </div>

          <div className="pl-4 flex-1">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={item?.address?.city || ''}
              render={({ field: { onBlur, onChange, value } }: any) => (
                <Input
                  disabled={isDisabled}
                  key="address.city"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  label={t('address.city')}
                  errors={[
                    _.get(errors, 'address.city.message'),
                    // ...backendErrorsForAttribute('city'),
                  ]}
                />
              )}
              name={'address.city'}
            />
          </div>
        </div>
        <div className="w-1/3 lg:w-full">
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={item?.address?.country || ''}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                disabled={isDisabled}
                key="address.country"
                size="small"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                label={t('address.country')}
                errors={[
                  _.get(errors, 'address.country.message'),
                  // ...backendErrorsForAttribute('country'),
                ]}
              />
            )}
            name={'address.country'}
          />
        </div>
        <div className="my-6 lg:w-1/3">
          <Controller
            control={control}
            name={'hasVat'}
            defaultValue={item?.hasVat || false}
            render={({ field: { onChange, value } }) => (
              <div className={`${isDisabled ? 'opacity-70' : ''}`}>
                <ToggleInput
                  label={t('settings.company.has_vat')}
                  enabled={value}
                  setEnabled={onChange}
                  disabled={isDisabled}
                />
              </div>
            )}
          />
        </div>

        {isDisabled ? null : (
          <div className="flex justify-end my-4">
            <button
              title={t('cancel')}
              className="btn rounded-3xl btn-primary-outlined"
              onClick={() => handleCancelEditCompany()}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              title={t('confirm')}
              customClass={'btn rounded-3xl btn-primary ml-2'}
            />
          </div>
        )}
      </form>
    </>
  );
};

export default CompanyForm;
