import { useQuery } from 'react-query';
import { getAccountInvitationsByUser } from '../api/users';
import { useAuth } from '../contexts/authContext';

const useGetInvitations = (params?: any) => {
  const { currentUser } = useAuth();
  const { data: pendingInvitations, refetch } = useQuery({
    queryKey: ['pendingInvitations', currentUser],
    queryFn: () => {
      return getAccountInvitationsByUser(currentUser.accessToken, params);
    },
  });

  return { pendingInvitations, refetch };
};

export default useGetInvitations;
