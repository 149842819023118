import { useCallback } from 'react';
import { Socket } from 'socket.io-client';
import { SocketServer } from '../socket';

const useSocket = () => {
  const socket: Socket = SocketServer;
  const sendEvent = useCallback(
    (event, data) => {
      if (socket) {
        socket.emit(event, data);
      }
    },
    [socket],
  );

  const onEvent = useCallback(
    (event: string, callback) => {
      if (socket) {
        socket.on(event, callback);
      }

      return () => {
        if (socket) {
          socket.off(event, callback);
        }
      };
    },
    [socket],
  );

  return {
    sendEvent,
    onEvent,
  };
};

export default useSocket;
