import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const CustomLink = ({
  to,
  title,
  icon,
  external,
  hasNewBadge,
  badgeTitle,
}: any) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  const linkWrapperClass =
    'relative group flex font-black font-satoshiBold items-center mx-2 my-1 pl-2 pr-2 py-1 rounded-xl hover:text-black ' +
    (match ? 'text-black bg-white ' : 'text-grey');
  const iconClass = `link-icon my-1 mx-4 min-w-[16px]  ${
    match
      ? 'text-primary group-hover:text-primary'
      : 'text-gray-300 group-hover:text-black'
  }`;

  return external ? (
    <a className={linkWrapperClass} href={to} target="_blank" rel="noreferrer">
      <div className={iconClass}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <span className="text-md">{title}</span>
      {hasNewBadge && (
        <div className="flex items-center justify-center ml-1 w-8 bg-black rounded-full">
          <span className="text-[10px] text-white">{badgeTitle ?? 'New!'}</span>
        </div>
      )}
    </a>
  ) : (
    <Link className={linkWrapperClass} to={to}>
      <div className={iconClass}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <span className="text-md">{title}</span>
      {hasNewBadge && (
        <div className="flex items-center justify-center ml-1 w-auto px-1 bg-black rounded-full">
          <span className="text-[10px] text-white">{badgeTitle ?? 'New!'}</span>
        </div>
      )}
    </Link>
  );
};

CustomLink.defaultProps = {
  external: false,
};

export default CustomLink;
