import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  buttonWrapperClass?: string;
}

const Button = ({
  loading = false,
  disabled = false,
  children = null,
  onClick = function () {
    return null;
  },
  className = '',
  buttonWrapperClass = '',
}: Props) => {
  return (
    <div className={buttonWrapperClass}>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${className} ${
          disabled ? 'cursor-default opacity-[0.5]' : ''
        }`}
      >
        {!loading ? null : (
          <FontAwesomeIcon
            style={{ marginRight: 15 }}
            icon={faSpinnerThird}
            spin
          />
        )}
        <div>{children}</div>
      </button>
    </div>
  );
};

export default Button;
