import React, { useState } from 'react';
import Validation from '../../../../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from '../../../../submit-button/submit-button.component';
import TextArea from '../../../../text-area/text-area.component';
import Select from '../../../../select/select.component';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../input/input.component';
import { useMutation, useQuery } from 'react-query';
import { Home } from '../../../../../types/home.model';
import {
  CheckoutArea,
  CheckoutAreaOptions,
} from '../../../../../types/checkout-area.model';
import { createHome } from '../../../../../api/homes';
import { useAuth } from '../../../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import { getCheckoutAreasByWorkspace } from '../../../../../api/checkoutAreas';
import { upload } from '../../../../../api/files';
import DefaultHomePicture from '../../../../../assets/images/default-home-picture.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { fetchOnboardingStatus } from '../../../../../redux/global/globalActions';
import { resizeImage } from '../../../../../utils/image';
import useTrackMixpanelEvent from '../../../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import useSocket from '../../../../../hooks/useSocket';
import { getStripeConnectAccountByWorkspaceId } from '../../../../../api/connectAccounts';

type AddHomeFormProps = {
  refreshHomes: Function;
};

const AddHomeForm = ({ refreshHomes }: AddHomeFormProps) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const [homePicture, setHomePicture] = useState(DefaultHomePicture);
  const [checkoutAreasOptions, setCheckoutAreasOptions] = useState<
    CheckoutAreaOptions[]
  >([]);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const dispatch = useAppDispatch();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const { sendEvent } = useSocket();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm();

  const { mutate: createNewHome } = useMutation(
    (data: Home) =>
      createHome(currentUser.accessToken, currentWorkspace._id, data),
    {
      onSuccess: (response: any) => {
        const homeId = response._id;
        trackMixpanelEvent('Home - Add manually success', mixpanelProperties);
        sendEvent('home:translation:start', {
          homeIds: [homeId],
          lang: i18n.language.slice(0, 2),
        });
        refreshHomes();
        dispatch(
          fetchOnboardingStatus(currentUser.accessToken, currentWorkspace._id),
        );
        navigate('/homes');
      },
      onError(e: any) {
        if (e.type === 'HOME.CUSTOM_ID_EXISTS') {
          setError('customId', { type: 'custom', message: t(e.message) });
        }
      },
    },
  );

  const handleCheckoutAreasOptions = (checkoutAreas: CheckoutArea[]) => {
    const caOptions = checkoutAreas?.map((ca: CheckoutArea) => {
      return { label: ca.name, key: ca._id };
    });
    setCheckoutAreasOptions(caOptions);
  };

  const handleOnChangePicture = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: any,
  ) => {
    if (e.target.files && e.target.files.length) {
      onChange(e.target.files);
      setHomePicture(URL.createObjectURL(e.target.files[0]));
    }
  };

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  useQuery(
    ['checkoutAreas'],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      onSuccess: (data: CheckoutArea[]) => {
        handleCheckoutAreasOptions(data);
      },
    },
  );

  const submit = async (data: any) => {
    let newHome = {
      name: data.name,
      headline: data.headline || null,
      description: data.description || null,
      picture: null,
      customId: data?.customId || null,
      address: {
        street: data.street,
        streetNumber: data.streetNumber,
        zipcode: data.zipcode,
        city: data.city,
        country: data.country,
      },
      checkoutArea: data.checkoutArea || null,
      stripeConnectAccount: data.stripeConnectAccount || null,
    };
    const hasPicture = data.picture && data.picture.length;
    if (hasPicture) {
      const resizedFile = await resizeImage(data.picture[0]);
      if (resizedFile) {
        const newPicture = resizedFile;
        const newPictureUploaded = await uploadNewPicture(newPicture);
        newHome.picture = newPictureUploaded.url;
      }
    }
    createNewHome(newHome);
  };

  useQuery(
    ['stripeConnectAccount', currentUser, currentAccount],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentAccount,
      onSuccess: (data: any) => handleBankAccountOptions(data),
    },
  );

  const handleBankAccountOptions = (bankAccounts: any) => {
    const bankAccountOptions = bankAccounts?.map((ca: any) => {
      return { label: ca.name, key: ca._id };
    });
    setBankAccountOptions(bankAccountOptions);
  };

  return (
    <div className="w-full">
      <form className="flex flex-col p-4" onSubmit={handleSubmit(submit)}>
        <div className="lg:w-2/3">
          <h5 className="mb-6" style={{ textAlign: 'left' }}>
            {t('listings.informations')}
          </h5>
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue=""
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="name"
                size="small"
                label={`${t('listings.name')} *`}
                onChange={onChange}
                value={value}
                helperText={t('listings.name_of_the_place')}
                errors={[_.get(errors, 'name.message')]}
              />
            )}
            name={'name'}
          />
          <div className="relative mb-6">
            <img
              className="rounded-xl h-52 w-full object-cover"
              src={homePicture}
            />

            <div className="absolute top-0 right-0">
              <div>
                <label
                  htmlFor="picture"
                  className="btn absolute top-0 right-0 opacity-75 text-gray-600 bg-gray-100"
                >
                  {t('edit')}
                </label>
              </div>
            </div>

            <Controller
              control={control}
              render={({ field: { onChange } }: any) => (
                <Input
                  style={{ display: 'none' }}
                  key="picture"
                  label=""
                  type="file"
                  accept="image/*"
                  id="picture"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangePicture(e, onChange)
                  }
                  errors={[_.get(errors, 'picture.message')]}
                />
              )}
              name={'picture'}
            />
          </div>
          <h5 className="mb-6" style={{ textAlign: 'left', marginTop: 8 }}>
            {t('address.address')}
          </h5>
          <div className="flex justify-between">
            <div className="w-1/5 lg:w-2/12">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="streetNumber"
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={`${t('address.street_number')} *`}
                    errors={[_.get(errors, 'streetNumber.message')]}
                  />
                )}
                name="streetNumber"
              />
            </div>
            <div className="pl-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="street"
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={`${t('address.street')} *`}
                    errors={[_.get(errors, 'street.message')]}
                  />
                )}
                name="street"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-1/5">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="zipcode"
                    size="small"
                    label={`${t('address.zipcode')} *`}
                    onChange={onChange}
                    value={value}
                    errors={[_.get(errors, 'zipcode.message')]}
                  />
                )}
                name="zipcode"
              />
            </div>
            <div className="pl-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="city"
                    size="small"
                    label={`${t('address.city')} *`}
                    onChange={onChange}
                    value={value}
                    errors={[_.get(errors, 'city.message')]}
                  />
                )}
                name="city"
              />
            </div>
          </div>
          <div className="w-full lg:w-2/5 mb-6">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue=""
              render={({ field: { onChange, value } }: any) => (
                <Input
                  key="country"
                  size="small"
                  label={`${t('address.country')} *`}
                  onChange={onChange}
                  value={value}
                  errors={[_.get(errors, 'country.message')]}
                />
              )}
              name="country"
            />
          </div>
          <h5 className="mb-6" style={{ textAlign: 'left', marginTop: 8 }}>
            {t('settings.stripe.account')}
          </h5>
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={currentWorkspace?.stripeConnectAccounts[0]?._id || ''}
            render={({ field: { onChange, value } }: any) => (
              <Select
                key="stripeConnectAccount"
                onChange={onChange}
                value={value}
                options={bankAccountOptions}
                errors={[_.get(errors, 'stripeConnectAccount.message')]}
              />
            )}
            name={'stripeConnectAccount'}
          />

          <h5 className="mb-6" style={{ textAlign: 'left', marginTop: 8 }}>
            {t('listings.checkout-page')}
          </h5>
          <Controller
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="headline"
                tooltip={t('listings.headline_tooltip')}
                label={`${t('listings.headline')} (${t('optional')})`}
                size="small"
                onChange={onChange}
                value={value}
                errors={[_.get(errors, 'headline.message')]}
              />
            )}
            name={'headline'}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }: any) => (
              <TextArea
                key="description"
                tooltip={t('listings.description_tooltip')}
                label={`${t('listings.description')} (${t('optional')})`}
                onChange={onChange}
                value={value}
                errors={[_.get(errors, 'description.message')]}
              />
            )}
            name={'description'}
          />
          {!checkoutAreasOptions.length ? (
            <div className="my-6">
              <div className="text-left">
                <span className="mt-6 text-[#6B7194]">
                  {`${t('listings.available_extras')} (${t('optional')})`}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-sm text-middleGrey">
                  {`${t('checkout-areas.no_checkout_areas')}`}
                </span>

                <button
                  title={'Cancel'}
                  className="btn rounded-3xl btn-primary-outlined ml-4"
                  onClick={() => navigate('/checkout-areas/new')}
                >
                  {t('add')}
                </button>
              </div>
            </div>
          ) : (
            <Controller
              control={control}
              render={({ field: { onChange, value } }: any) => (
                <Select
                  key="checkoutArea"
                  label={`${t('listings.available_extras')} (${t('optional')})`}
                  selectLabel={t('checkout-areas.select_store')}
                  tooltip={t('listings.checkout_area_tooltip')}
                  onChange={onChange}
                  value={value}
                  options={checkoutAreasOptions}
                  errors={[_.get(errors, 'checkoutArea.message')]}
                />
              )}
              name={'checkoutArea'}
            />
          )}
          <Controller
            control={control}
            rules={Validation.noSpace}
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="customId"
                tooltip={`${t('listings.custom_id_help')}`}
                size="small"
                label={`${t('listings.custom_id')} (${t('optional')})`}
                onChange={onChange}
                value={value}
                helperText={`${document.location.origin}/cid/${
                  value || t('listings.your_custom_url')
                }`}
                errors={[_.get(errors, 'customId.message')]}
              />
            )}
            name={'customId'}
          />
          <div className="flex justify-end">
            <button
              title={'Cancel'}
              className="btn rounded-3xl btn-primary-outlined"
              onClick={() => navigate('/homes')}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              title={t('confirm')}
              customClass="btn rounded-3xl btn-primary flex"
              disabled={isLoadingPicture}
            >
              {!isLoadingPicture ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </SubmitButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddHomeForm;
