import React, { RefObject, useRef, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import { useQuery } from 'react-query';

import { useAuth } from '../../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import {
  getFeeInvoiceDetail,
  getFeeInvoices,
  updateAccount,
} from '../../../../api/accounts';
import InvoicesTable from '../../../tables/invoices-table.component';
import CompanyForm from '../../../company-form/company-form.component';
import { useMutation } from 'react-query';
import { Account } from '../../../../types/account.model';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import Loader from '../../../loader/loader.component';

const Subscription: React.FC = () => {
  const { t } = useTranslation();
  const companyRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const { control } = useForm();
  const [disabledEditCompany, setDisabledEditCompany] = useState<boolean>(true);
  const [displayCompanyAlert, setDisplayCompanyAlert] =
    useState<boolean>(false);
  let { currentUser, userStripeInformation, currentAccount, refreshAccounts } =
    useAuth();

  const totalWorkspaces = currentAccount.workspaces.length;
  const totalUsers = currentAccount.guests.length;

  const {
    data: invoices,
    isLooading,
    isFetching,
  } = useQuery(
    ['feeInvoices', currentUser, currentAccount],
    () => getFeeInvoices(currentUser.accessToken, currentAccount._id),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  const { mutate: downloadInvoice } = useMutation(
    (monthYear: string) =>
      getFeeInvoiceDetail(
        currentUser.accessToken,
        currentAccount._id,
        monthYear,
      ),
    {
      onSuccess: (data: { invoiceDate: string; invoiceBase64: string }) => {
        let a = document.createElement('a');
        a.href = 'data:application/octet-stream;base64,' + data.invoiceBase64;
        a.download = `CU-${data.invoiceDate}.pdf`;
        a.click();
      },
    },
  );

  const handleOnDownloadInvoice = (monthYear: string) => {
    if (currentAccount.company) {
      downloadInvoice(monthYear);
    } else {
      setDisplayCompanyAlert(true);
    }
  };

  const handleCompanyEdit = () => {
    setDisabledEditCompany(false);
    companyRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const { mutate: updateCurrentAccount } = useMutation(
    (data: Account) =>
      updateAccount(currentUser.accessToken, currentAccount._id, data),
    {
      onSuccess: () => {
        refreshAccounts();
        setDisabledEditCompany(false);
      },
    },
  );

  const onUpdateCompany = (company: any) => {
    updateCurrentAccount({
      ...currentAccount,
      company,
    });
  };

  return !userStripeInformation?.subscriptions ? null : (
    <>
      <AlertBeforeAction
        title={t('settings.company.missing_info_title')}
        description={t('settings.company.missing_info_description')}
        successAction={() => handleCompanyEdit()}
        successLabel={'OK'}
        isVisible={displayCompanyAlert}
        setIsVisible={setDisplayCompanyAlert}
        cancelLabel={null}
      />
      <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
        <h5
          style={{
            textAlign: 'left',
          }}
        >
          {t('settings.subscription')}
        </h5>
      </div>
      <div className="flex gap-6">
        <Controller
          control={control}
          rules={Validation.anyRequired}
          defaultValue={
            userStripeInformation?.subscriptions?.data[0]?.plan?.nickname
          }
          render={({ field: { onChange, value } }: any) => (
            <Input
              disabled={true}
              key="plan"
              label={t('settings.plan')}
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
          name={'plan'}
        />
        <Controller
          control={control}
          rules={Validation.anyRequired}
          defaultValue={t(
            `settings.stripe.${userStripeInformation?.subscriptions?.data[0]?.status}`,
          )}
          render={({ field: { onChange, value } }: any) => (
            <Input
              disabled={true}
              key="account-status"
              label={t('settings.stripe.status')}
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
          name={'account-status'}
        />
      </div>
      <div className="flex gap-6">
        <Controller
          control={control}
          rules={Validation.anyRequired}
          defaultValue={totalWorkspaces}
          render={({ field: { onChange, value } }: any) => (
            <Input
              disabled={true}
              key="workspaces"
              label={t('settings.workspace.workspace', {
                count: totalWorkspaces,
              })}
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
          name={'workspaces'}
        />
        <Controller
          control={control}
          rules={Validation.anyRequired}
          defaultValue={totalUsers}
          render={({ field: { onChange, value } }: any) => (
            <Input
              disabled={true}
              key="users"
              label={t('settings.workspace.users', { count: totalUsers })}
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
          name={'users'}
        />
      </div>
      <div className="flex gap-6">
        <Controller
          control={control}
          rules={Validation.anyRequired}
          defaultValue={currentAccount.percentageFeeHost}
          render={({ field: { onChange, value } }: any) => (
            <Input
              disabled={true}
              key="percentageFeeHost"
              label={t('settings.workspace.percentage_fee_host')}
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
          name={'percentage_fee_host'}
        />
        <div className="w-full" />
      </div>
      <div ref={companyRef}>
        <CompanyForm
          title={t('settings.company.billing_information')}
          item={currentAccount?.company}
          update={onUpdateCompany}
          isDisabled={disabledEditCompany}
          setIsDisabled={setDisabledEditCompany}
        />
      </div>
      <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
        <h5
          style={{
            textAlign: 'left',
          }}
        >
          {t('settings.invoices.invoices')}
        </h5>
      </div>
      <div>
        {isLooading || isFetching ? (
          <Loader />
        ) : (
          <InvoicesTable
            invoices={invoices}
            handleOnDownload={handleOnDownloadInvoice}
          />
        )}
      </div>
    </>
  );
};

export default Subscription;
