// Importez les hooks et autres utilitaires nécessaires de React
import React, { useState } from 'react';
import { getReferals, sendReferralLink } from '../../api/accounts';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import Input from '../../components/input/input.component';
import Validation from '../../utils/validation';
import Button from '../../components/button/button.component';
import {
  faCheckCircle,
  faCopy,
  faMessage,
  faSend,
  faSpinnerThird,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import {
  displayTimeRemaining,
  formatDateToLocaleDateString,
} from '../../utils/date';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import SubmitButton from '../../components/submit-button/submit-button.component';
import { useTranslation } from 'react-i18next';

// Composant principal de la page de parrainage
const Referrals = () => {
  const { t } = useTranslation();
  const { currentUser, currentAccount } = useAuth();
  // État pour gérer le lien de parrainage
  const [referralLink] = useState(
    `${process.env.REACT_APP_COZYUP_URL}/sign-up?ref=${currentAccount?._id}`,
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const submitSendLink = (data: any) => {
    sendInvitation(data.email);
  };

  const { mutate: sendInvitation, isLoading: isLoadingSendInvitation } =
    useMutation(
      (email: string) =>
        sendReferralLink(currentUser.accessToken, currentAccount._id, email),
      {
        enabled: false,
        onSuccess: () => {
          toast.success(t('referrals.invitation_sent'));
        },
      },
    );

  const {
    data: referrals,
    // refetch,
    // isLoading,
  } = useQuery(
    ['products', currentUser, currentAccount],
    () => getReferals(currentUser.accessToken, currentAccount?._id),
    {
      enabled: !!currentUser && !!currentAccount,
      refetchOnWindowFocus: 'always',
    },
  );

  // Fonction pour copier le lien de parrainage dans le presse-papier
  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success('Lien copié !');
  };

  return (
    <div className="mt-6">
      <div className="flex gap-6 flex-col lg:flex-row ">
        <div className="border border-grey lg:mb-6 p-6 rounded-3xl flex-2 lg:w-2/3 lg:h-[400px]">
          <h2 className="text-2xl text-left font-semibold mb-4">
            {t('referrals.earn_with_referrals')}
          </h2>
          <div className="flex flex-col lg:flex-row gap-4 py-6">
            <div className="py-4 flex-1 flex flex-col gap-3 items-center">
              <div className="border rounded-full border-grey w-fit p-6">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faMessage}
                  style={{ fontSize: 32 }}
                />
              </div>
              <h3 className="font-bold text-lg">
                1. {t('referrals.invite_friends')}
              </h3>
              <p>{t('referrals.invite_friends_description')}</p>
            </div>
            <div className="py-4 flex-1 flex flex-col gap-3 items-center">
              <div className="border rounded-full border-grey w-fit p-6">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faUserPlus}
                  style={{ fontSize: 32 }}
                />
              </div>
              <h3 className="font-bold text-lg">
                2. {t('referrals.registration')}
              </h3>
              <p>{t('referrals.registration_description')}</p>
            </div>
            <div className="py-4 flex-1 flex flex-col gap-3 items-center">
              <div className="border rounded-full border-grey w-fit p-6">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faCheckCircle}
                  style={{ fontSize: 32 }}
                />
              </div>
              <h3 className="font-bold text-lg">3. {t('referrals.orders')}</h3>
              <p>
                {t('referrals.orders_description', {
                  currency: '$',
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-grey rounded-3xl flex-1 p-6 mb-4 flex flex-col lg:h-[400px]">
          {/* Section d'invitation */}
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="text-lg text-left font-semibold mb-4">
              {t('referrals.invite_friends')}
            </h2>
            <div className="flex items-center">
              <form
                onSubmit={handleSubmit(submitSendLink)}
                className="w-full flex"
              >
                <Controller
                  control={control}
                  rules={{
                    ...Validation.email,
                    ...Validation.anyRequired,
                  }}
                  defaultValue={''}
                  render={({ field: { onBlur, onChange, value } }: any) => (
                    <Input
                      type="email"
                      placeholder="john.doe@example.com"
                      key="email"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      errors={[_.get(errors, 'email.message')]}
                    />
                  )}
                  name={'email'}
                />

                <SubmitButton
                  title=""
                  disabled={isLoadingSendInvitation}
                  customClass="hover:opacity-90 btn btn-primary rounded-3xl flex items-center h-10 my-0"
                >
                  {!isLoadingSendInvitation ? (
                    <FontAwesomeIcon icon={faSend} />
                  ) : (
                    <FontAwesomeIcon icon={faSpinnerThird} spin />
                  )}
                </SubmitButton>
              </form>
            </div>
          </div>
          <div className="w-full border-t border-lightGrey pb-6"></div>

          {/* Section de partage du lien de parrainage */}
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="text-lg text-left font-semibold mb-4">
              {t('referrals.share_referral_link')}
            </h2>
            <div className="flex items-center ">
              <Input type="text" disabled={true} value={referralLink} />
              <Button
                onClick={copyReferralLink}
                className="hover:opacity-80 btn btn-ghost rounded-3xl mb-8"
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-6 flex-col lg:flex-row">
        <div className="border border-grey rounded-3xl flex-1 p-6">
          {/* Liste des filleuls avec statistiques */}
          <h2 className="text-lg text-left font-semibold mb-4">
            {t('referrals.your_referrals')} ({referrals?.length})
          </h2>
          <div className="h-full flex flex-col">
            {referrals && referrals.length > 0 ? (
              <div className="space-y-4">
                {referrals?.map((referral: any, index: number) => (
                  <ReferralItem key={index} referral={referral} />
                ))}
              </div>
            ) : (
              <div className="h-full flex-1 py-10 flex justify-center">
                <div className="flex-row my-5 lg:mt-10">
                  <FontAwesomeIcon
                    className="opacity-20 mb-4"
                    color="#b6b9ce"
                    icon={faUserPlus}
                    size="4x"
                  />
                  <div className="text-sm font-semibold text-[#6B7194] opacity-20">
                    {t('referrals.no_referrals')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;

const ReferralItem = ({ referral }: any) => {
  const { t } = useTranslation();
  const handleIsCheckOrNot = (hasOne: boolean) => {
    return hasOne ? (
      <FontAwesomeIcon icon={faCheckCircle} color="#50d05a" size="xl" />
    ) : (
      <FontAwesomeIcon icon={faCheckCircle} color="#b6b9ce" size="xl" />
    );
  };

  return (
    <div className="flex flex-col lg:flex-row gap-6 justify-between items-center py-6 px-2 lg:p-4 bg-lightblue  rounded-3xl">
      <div className="flex-1 flex flex-col lg:flex-row gap-8 justify-between items-center">
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <div className="ml-[12px]">
            <div className="bg-primary rounded-full w-12 h-12 flex items-center justify-center">
              <span className="text-lg uppercase text-white font-bold">
                {referral?.to?.email?.slice(0, 2)}
              </span>
            </div>
          </div>
          <div>
            <div className="text-left">
              {referral?.to?.firstName && referral?.to?.lastName ? (
                <span className=" first-letter:uppercase text-lg">
                  {referral?.to?.firstName} {referral?.to?.lastName}
                </span>
              ) : null}
            </div>
            <div className="text-left">
              <span className="font-bold text-md">{referral?.to?.email}</span>
            </div>
            <div className="text-left">
              <span className="font-bold text-grey text-sm">
                {t('referrals.signup_date')} :{' '}
                {formatDateToLocaleDateString(referral?.created_at)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex gap-4 items-center px-4">
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-grey text-xs">
              {t('referrals.registration')}
            </span>
          </div>
          <div>{handleIsCheckOrNot(true)}</div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-grey text-xs">{t('referrals.order')} 1</span>
          </div>
          <div>
            {handleIsCheckOrNot(referral.totalPaidOrdersInFirstMonth >= 1)}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-grey text-xs">{t('referrals.order')} 2</span>
          </div>
          <div>
            {handleIsCheckOrNot(referral.totalPaidOrdersInFirstMonth >= 2)}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <span className="text-grey text-xs">{t('referrals.order')} 3</span>
          </div>
          <div>
            {handleIsCheckOrNot(referral.totalPaidOrdersInFirstMonth >= 3)}
          </div>
        </div>
      </div>
      <div className="flex-1 flex gap-4 justify-end lg:justify-center">
        <div>
          {referral?.hasExpired && referral.totalPaidOrdersInFirstMonth < 3 ? (
            <span className="font-medium text-xs px-2 py-1 rounded-3xl text-grey ">
              {t('referrals.status.expired')}
            </span>
          ) : (
            ''
          )}

          {referral.totalPaidOrdersInFirstMonth >= 3 ? (
            <>
              {referral?.paymentStatus === 'paid' ? (
                <div>
                  <span className="font-medium px-2 py-1 rounded-3xl text-green ">
                    {t('referrals.status.completed_with_payment')}{' '}
                    {formatDateToLocaleDateString(referral?.paymentDate)} -{' '}
                    {displayPrice(
                      referral?.paidAmount,
                      getCurrencyInfo(referral?.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </span>
                </div>
              ) : (
                <span className="font-medium px-2 py-1  rounded-3xl text-green ">
                  {t('referrals.status.completed_waiting_payment')}
                </span>
              )}
            </>
          ) : (
            ''
          )}

          {referral?.hasExpired === false &&
          referral?.totalPaidOrdersInFirstMonth < 3 ? (
            <span className="font-medium px-2 py-1 rounded-3xl text-orange">
              {t('referrals.status.pending_and_expire_in')}
              {displayTimeRemaining(referral?.expirationDate)}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
