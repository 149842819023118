import React, { useState } from 'react';
import DefaultHomePicture from '../../assets/images/default-home-picture.jpeg';
import { useTranslation } from 'react-i18next';
import { Home } from '../../types/home.model';
import { LinkIcon } from '@heroicons/react/20/solid';
import { Icon } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

type ShareHomeCardProps = {
  home: any;
};

export const ShareHomeCard: React.FC<ShareHomeCardProps> = ({ home }: any) => {
  const { t } = useTranslation();
  const getCheckoutUrl = (home: Home, previewMode?: boolean) => {
    if (home.customId) {
      return (
        document.location.origin +
        `/cid/${home.customId}${previewMode ? '?preview=true' : ''}`
      );
    }
    return (
      document.location.origin +
      `/c/${home._id}${previewMode ? '?preview=true' : ''}`
    );
  };
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);
  const copyUrl = (e: any, home: Home) => {
    e.stopPropagation();
    if (!isCopiedUrl) {
      setIsCopiedUrl(true);
      navigator.clipboard.writeText(getCheckoutUrl(home));
    }
  };

  const previewCheckout = (
    e: React.SyntheticEvent<EventTarget>,
    home: Home,
  ) => {
    e.stopPropagation();
    window.open(getCheckoutUrl(home, true));
  };
  return (
    <div className="p-2 border rounded-xl border-grey bg-white my-1 w-full  min-h-[145px]">
      <div className="flex">
        <div className="w-[120px] h-[130px]">
          <img
            className="h-full w-full rounded-xl object-cover"
            src={home.picture || DefaultHomePicture}
            alt={home.name}
          />
        </div>
        <div className="flex flex-col items-start justify-between px-3 w-full text-left">
          <div>
            <h3 className="text-lg font-bold">{home.name}</h3>
            <small className="text-greyText uppercase">
              {home?.address?.city}
            </small>
          </div>
          {home?.checkoutArea?.checkoutAreaProducts.length && (
            <small className="text-greyText">
              {t('extra_count', {
                count: home?.checkoutArea?.checkoutAreaProducts.length,
              })}
            </small>
          )}
          <div className="flex items-end justify-end w-full">
            <a
              className="btn btn-ghost text-primary btn-small"
              onClick={(e: React.SyntheticEvent<EventTarget>) =>
                previewCheckout(e, home)
              }
            >
              <Icon
                style={{ marginRight: 4 }}
                fontSize="small"
                component={VisibilityIcon}
              />
              {t('preview')}
            </a>

            <button
              className={`btn rounded-xl btn-primary btn-small`}
              onClick={(e) => copyUrl(e, home)}
              onMouseLeave={() =>
                setTimeout(() => {
                  setIsCopiedUrl(false);
                }, 400)
              }
            >
              <Icon
                style={{ marginRight: 4 }}
                className=""
                fontSize="small"
                component={LinkIcon}
              />
              {isCopiedUrl ? t('listings.copied_url') : t('listings.copy_url')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
