import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/useStore';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../contexts/authContext';
import i18n from '../../i18n';
import { REDIRECT_PATH } from './QuickStart';

type Props = {
  visible: boolean;
  setVisible: any;
};

const QuickStartMobile = ({ visible, setVisible }: Props) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );

  const navigate = useNavigate();

  const handleRedirectToHelp = (currentTask: any) => {
    const browserLang = i18n.language.split('-')[0];
    const helpLang = i18n.languages.includes(browserLang) ? browserLang : 'en';
    window.open(currentTask?.helpUrl[helpLang], '_blank');
  };

  return !visible ? null : (
    <div className=" w-full bg-white flex-col text-left pb-3">
      <div className="flex flex-col grow overflow-auto relative h-full">
        <div className="grid gap-[8px] mx-[30px] mb-[30px]">
          <div className="flex text-primary text-[11px] uppercase font-satoshiBold font-bold leading-none mt-[20px]">
            {t('onboarding.steps')}
          </div>
          {/* BLOCK 1 - TO DO STEPS */}
          {!onboardingStatus?.data
            ? null
            : Object.entries(onboardingStatus.data)
                // .filter(([k]) => !onboardingStatus.data[k])
                .map((obj: any, index: number) => {
                  return (
                    <div
                      key={obj[0]}
                      className="border border-lightblue bg-lightblue rounded-[8px] flex items-stretch flex-col text-left relative py-[16px] px-[20px]"
                    >
                      <div
                        className={`gap-[8px] text-black items-center font-semibold flex ${
                          !obj[1] ? '' : 'opacity-50'
                        }`}
                      >
                        {!obj[1] ? (
                          index + 1 + ' - '
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color="#7e82e5"
                            className="z-10"
                          />
                        )}{' '}
                        {t(`onboarding.${obj[0]}.title`)}{' '}
                        {t(`onboarding.${obj[0]}.time`)}
                      </div>
                      {!obj[1] && (
                        <div>
                          <p className="text-[12px] text-grey mt-[4px] mx-0 mb-0">
                            {t(`onboarding.${obj[0]}.description`)}
                          </p>
                          <div className="flex justify-end items-end mt-[12px]">
                            <button
                              className="inline-block rounded-[32px] font-semibold border-1 border-primary border h-[32px] leading-1 py-[8px] px-[12px] cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                trackMixpanelEvent(
                                  `Onboarding - Open help ${obj[0]}`,
                                  {
                                    ...onboardingStatus?.meta,
                                    ...onboardingStatus?.data,
                                    workspace_id: currentWorkspace._id,
                                    account_id: currentAccount._id,
                                    account_name: currentAccount.name,
                                  },
                                );
                                // @ts-ignore
                                handleRedirectToHelp(REDIRECT_PATH[obj[0]]);
                              }}
                            >
                              <span className="h-full flex text-primary items-center justify-center whitespace-nowrap">
                                {t(`onboarding.help`)}
                              </span>
                            </button>
                            <button
                              className="ml-2 inline-block rounded-[32px] font-semibold bg-primary border-1 border-primary h-[32px] leading-1 py-[8px] px-[12px] cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                trackMixpanelEvent(
                                  `Onboarding - Start ${obj[0]}`,
                                  {
                                    ...onboardingStatus?.meta,
                                    ...onboardingStatus?.data,
                                    workspace_id: currentWorkspace._id,
                                    account_id: currentAccount._id,
                                    account_name: currentAccount.name,
                                  },
                                );
                                setVisible(false);
                                // @ts-ignore
                                {
                                  obj[0] === 'stripeConnect'
                                    ? navigate(
                                        `/settings/workspaces/${currentWorkspace._id}`,
                                      )
                                    : navigate(REDIRECT_PATH[obj[0]].path, {
                                        state: REDIRECT_PATH[obj[0]]?.state,
                                      });
                                  // @ts-ignore
                                }
                              }}
                            >
                              <span className="h-full flex text-white items-center justify-center whitespace-nowrap">
                                {t(`onboarding.start`)}
                              </span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
        </div>
      </div>
    </div>
  );
};

export default QuickStartMobile;
