import React, { useEffect, useState } from 'react';
import './full-calendar.styles.scss';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useTranslation } from 'react-i18next';
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import i18n from '../../../i18n';
import OrderModal from '../../modals/order-modal/order-modal.component';
import { Order } from '../../../types/order.model';
import { EventClickArg } from '@fullcalendar/core';
import { classNames } from '../../../utils/helpers';

interface CalendarEvent {
  title: string;
  start: string;
}

interface CalendarProps {
  events: CalendarEvent[];
  onDateChange?: Function;
  refresh?: boolean;
  setRefresh?: any;
}

const Calendar: React.FC<CalendarProps> = ({
  events,
  onDateChange,
  refresh,
  setRefresh,
}) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<Order>({} as Order);
  const [dateSetArg, setDateSetArg] = useState();
  const renderDayContent = (eventInfo: any, headerMode?: boolean) => {
    const { date } = eventInfo;
    const today = new Date();

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    const weekdayNames = [
      t('calendar.shortday.sun'),
      t('calendar.shortday.mon'),
      t('calendar.shortday.tue'),
      t('calendar.shortday.wed'),
      t('calendar.shortday.thu'),
      t('calendar.shortday.fri'),
      t('calendar.shortday.sat'),
    ];

    const weekdayName = weekdayNames[date.getDay()];
    const dayOfMonth = date.getDate();

    const dateClass = classNames(
      headerMode ? 'hidden' : 'text-xs lg:text-xl font-satoshiBold',
      'md:text-xl',
    );
    const dayClass = classNames(
      headerMode ? 'flex lg:hidden' : 'hidden lg:flex',
      'lg:ml-3 text-[10px]',
    );
    return (
      <>
        <div className="text-xs lg:text-xl text-left flex flex-row justify-start items-center top-1 left-1 border-lightblue border-0 rounded-2xl">
          <div
            className={dateClass}
            style={{ color: isToday ? '#ff3366' : '' }}
          >
            {dayOfMonth}
          </div>
          <div className={dayClass}>{weekdayName.toUpperCase()}</div>
        </div>
      </>
    );
  };

  //TODO: Move this in a props, calendar component shouldn't have specific logic
  const handleDisplayOrderModal = (info: EventClickArg) => {
    setCurrentOrder(info.event._def.extendedProps.order as Order);
    setDisplayModal(true);
  };

  useEffect(() => {
    if (refresh) {
      onDateChange && onDateChange(dateSetArg);
    }
  }, [refresh]);

  return (
    <div className="mt-4">
      <OrderModal
        order={currentOrder}
        setDisplayModal={setDisplayModal}
        displayModal={displayModal}
        setRefreshOrder={setRefresh}
      />
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          right: 'today prev,next',
          left: 'title',
        }}
        datesSet={(dateInfo) => {
          setDateSetArg(dateInfo as any);
          onDateChange && onDateChange(dateInfo);
        }}
        dayHeaderContent={(e) => renderDayContent(e, true)}
        dayCellContent={(e) => renderDayContent(e)}
        dayCellClassNames="border-[0px]"
        initialView="dayGridMonth"
        eventClick={(info) => handleDisplayOrderModal(info)}
        events={events}
        dayMaxEvents={true}
        displayEventTime={false}
        weekends={true}
        showNonCurrentDates={false}
        eventColor="#7e82e5"
        locale={i18n.language.includes('fr') ? frLocale : enLocale}
      />
    </div>
  );
};

export default Calendar;
