import React, { useState } from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import InputRangeDates from '../input-range-dates/input-range-dates.component';
import { DateRange } from 'react-day-picker';
import Button from '../button/button.component';
import { useTranslation } from 'react-i18next';

type Props = {
  setDisplayModal: Function;
  onSubmit: Function;
};

function SelectRangeDatesModal({ setDisplayModal, onSubmit }: Props) {
  const { t } = useTranslation();
  const [range, setRange] = useState<DateRange | undefined>();

  const onSetDates = (range: any) => {
    const { from, to } = range;
    if (from && to) {
      setRange(range);
    }
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative  flex flex-col justify-between w-full max-w-[400px] min-h-[500px] lg:min-h-[150px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div>
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-[36px] mb-4">
                {t('select-dates')}
              </h5>
            </div>
            <div className="md:px-10 justify-center items-center">
              <div className="my-6 border border-grey rounded-2xl flex justify-center ">
                <InputRangeDates onDateChange={onSetDates} />
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <Button
                disabled={!range?.from || !range?.to}
                onClick={() => onSubmit(range?.from, range?.to)}
                className="btn btn-primary rounded-3xl"
              >
                {t('download')}&nbsp;CSV
              </Button>
            </div>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
}

export default SelectRangeDatesModal;
