import i18n from '../i18n';

const Validation = {
  email: {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: i18n.t('errors.email_invalid'),
    },
  },
  phone: {
    pattern: {
      value: /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/,
      message: i18n.t('errors.phone_invalid'),
    },
  },
  password: {
    required: { value: true, message: i18n.t('errors.password_required') },
  },
  valideAirBnBUrl: {
    pattern: {
      value:
        /^https:\/\/.*airbnb\.[a-z]{2,3}\/(rooms\/\d+|hosting\/listings\/\d+\/details)(\?.*)?$/,
      message: i18n.t('errors.airbnb_url_invalid'), // Update the error message key as needed
    },
  },
  integer: {
    pattern: {
      value: /^[+]?\d*$/,
      message: i18n.t('errors.number_invalid'),
    },
  },
  price: {
    validate: {
      value: (value: string) =>
        /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/.test(value) ||
        i18n.t('errors.price_invalid'),
    },
  },
  greaterThanZero: {
    validate: {
      value: (value: any) =>
        +value >= 1 || `${i18n.t('validators.price_greater_than_one')}`,
    },
  },
  maxLength500: {
    validate: {
      value: (value: string) =>
        value.length <= 500 ||
        `${i18n.t('validators.comment_length_500_chars')}`,
    },
  },
  noSpace: {
    pattern: {
      value: /^\S+$/,
      message: i18n.t('errors.no_space_allowed'),
    },
  },
  isUrlValid: {
    pattern: {
      value: /^[a-zA-Z0-9_-]*[^?&]$/,
      message: i18n.t('errors.not_valid_url'),
    },
  },
  anyRequired: {
    required: { value: true, message: i18n.t('errors.field_required') },
  },
  any: {},
};

export default Validation;
