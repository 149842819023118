const qs = require('qs');

import { SortOptions } from '../types/generic-types';
import { api } from '../utils/request';

export const createOrder = async (workspaceId: string, data: any) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/orders`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );

    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const refundOrder = async (
  authToken: string,
  workspaceId: string,
  orderId: string,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/orders/${orderId}/refund`,
      {},
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getAllOrders = async (
  userToken: string,
  page: number,
  query: string,
  sortOptions: SortOptions,
) => {
  try {
    const res = await api.get(`/orders`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        sort: {
          direction: sortOptions.direction,
          field: sortOptions.field,
        },
        page,
        query,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

function formatDateToQuery(date: string) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1), // Les mois commencent à 0
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const generateCsv = async (
  userToken: string,
  workspaceId: string,
  startDate: string,
  endDate: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders/export-csv`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      responseType: 'blob',
      params: {
        startDate: formatDateToQuery(startDate),
        endDate: formatDateToQuery(endDate),
      },
    });
    return res.data;
  } catch (err: any) {
    throw err.response;
  }
};

export const getOrders = async (
  userToken: string,
  workspaceId: string,
  page: Number,
  query: string,
  sortOptions: SortOptions,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        sort: {
          direction: sortOptions.direction,
          field: sortOptions.field,
        },
        page,
        query,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getOrderDetailsPublic = async (orderId: string) => {
  try {
    const res = await api.get(`/public/orders/${orderId}`, {
      headers: {
        ...api.defaults.headers.common,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const confirmOrder = async (hostEmail: string, data: any) => {
  try {
    const res = await api.patch(
      `/orders/${data._id}/confirm`,
      { order: { ...data } },
      {
        headers: {
          ...api.defaults.headers.common,
        },
        params: {
          hostEmail: hostEmail,
        },
      },
    );

    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const validateOrder = async (
  userToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/orders/${data._id}/validate`,
      { order: { ...data } },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    return res;
  } catch (err: any) {
    throw err.response;
  }
};

export const getPaymentIntentSecret = async (data: any) => {
  try {
    const res = await api.post(
      `/orders/payment/secret`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    throw err.response;
  }
};

export const putInWaitingOrder = async (data: any) => {
  try {
    const res = await api.patch(
      `/orders/${data._id}/in-waiting`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );

    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const generateInvoice = async (
  userToken: string,
  orderId: string,
  language: string,
  currency: string,
) => {
  try {
    const res = await api.get(`/orders/${orderId}/invoice`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        language: language,
        currency: currency,
      },
    });

    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const checkDuplicatedOrder = async (
  workspaceId: string,
  homeId: string,
  filters: any,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/orders/home/${homeId}/check-duplicated`,
      {
        headers: {
          ...api.defaults.headers.common,
        },
        params: {
          ...filters,
        },
      },
    );

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
