import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CancelIcon from '@mui/icons-material/Cancel';
import { Icon } from '@mui/material';
import { Emoji } from 'emoji-mart';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCheckoutAreaById,
  updateCheckoutArea,
} from '../../../../api/checkoutAreas';
import { getProductsByWorkspace } from '../../../../api/products';
import { useAuth } from '../../../../contexts/authContext';
import { CheckoutAreaProduct } from '../../../../types/checkout-area-product.model';
import {
  CheckoutArea,
  NewCheckoutArea,
} from '../../../../types/checkout-area.model';
import { Product } from '../../../../types/product.model';
import { displayPrice, getCurrencyInfo } from '../../../../utils/currency';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import SubmitButton from '../../../submit-button/submit-button.component';

const CheckoutAreaDetails = ({ setSubRoute, setRefresh }: any) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState<any>([]);
  const { currentUser, apiUser, currentWorkspace } = useAuth();
  const backendErrors = '';

  useEffect(() => {
    setSubRoute(currentCheckoutArea?.name);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const {
    refetch,
    isLoading,
    data: currentCheckoutArea,
  } = useQuery(
    ['checkout-area', id],
    () =>
      // @ts-ignore
      getCheckoutAreaById(currentUser.accessToken, currentWorkspace._id, id),
    {
      enabled: !!id,
      onSuccess: (data: CheckoutArea) => {
        setSubRoute(data.name);
      },
    },
  );

  const { mutate: updateCurrentCheckoutArea } = useMutation(
    (data: CheckoutArea) =>
      id &&
      updateCheckoutArea(
        currentUser.accessToken,
        currentWorkspace._id,
        id,
        data,
      ),
    {
      onSuccess: () => {
        refetch();
        setCheckedProducts([]);
        setRefresh(true);
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const submit = async (data: any) => {
    updateCurrentCheckoutArea(data);
    setDisabled(true);
  };

  const handleCancelEdit = () => {
    reset();
    setDisabled(true);
  };

  const { data: productsData } = useQuery(
    ['products'],
    () => getProductsByWorkspace(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!apiUser,
    },
  );

  // Filter available products
  useEffect(() => {
    if (currentCheckoutArea && productsData) {
      const filteredAvailableProducts = productsData.filter(
        (availableP: Product) =>
          !currentCheckoutArea.checkoutAreaProducts.find(
            (selectedP: any) => availableP._id === selectedP.product._id,
          ),
      );
      setAvailableProducts(filteredAvailableProducts);
    }
  }, [productsData, currentCheckoutArea]);

  const handleSelect = (e: any) => {
    const productId = e.target.name;
    !checkedProducts.includes(productId)
      ? setCheckedProducts((prev: string[]) => [...prev, productId])
      : setCheckedProducts((prev: string[]) =>
          prev.filter((id: string) => id !== productId),
        );
  };

  const addProduct = () => {
    id &&
      updateCurrentCheckoutArea({
        products: [
          ...currentCheckoutArea.checkoutAreaProducts.map(
            (caProduct: CheckoutAreaProduct) => caProduct.product._id,
          ),
          ...checkedProducts,
        ],
      });
  };

  const handleDeleteProduct = (productId: string) => {
    const productsListUpdated = {
      products: [
        ...currentCheckoutArea.checkoutAreaProducts
          .map((caProduct: CheckoutAreaProduct) => caProduct.product._id)
          .filter((product: string) => product !== productId),
      ],
    };
    id && updateCurrentCheckoutArea(productsListUpdated);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const handleDuplicateCheckoutArea = () => {
    const newCheckoutArea: NewCheckoutArea = {
      name: `${currentCheckoutArea.name} (${t('copy')})`,
      products: currentCheckoutArea.checkoutAreaProducts.map(
        (caProduct: CheckoutAreaProduct) => caProduct.product,
      ),
    };
    navigate('/checkout-areas/new', {
      state: { checkoutArea: newCheckoutArea },
    });
  };

  const moveItemInArrayFromIndexToIndex = (
    array: string[],
    fromIndex: number,
    toIndex: number,
  ) => {
    const newArray = [...array];
    // remove `from` item and store it
    const f = newArray.splice(fromIndex, 1)[0];
    // insert stored item into position `to`
    newArray.splice(toIndex, 0, f);
    return newArray;
  };
  const onChangeOrderProduct = (oldIndex: number, newIndex: number) => {
    const checkoutAreaProducts = moveItemInArrayFromIndexToIndex(
      currentCheckoutArea.checkoutAreaProducts.map(
        (caP: CheckoutAreaProduct) => caP.product._id,
      ),
      oldIndex,
      newIndex,
    );

    updateCurrentCheckoutArea({
      ...currentCheckoutArea,
      products: checkoutAreaProducts,
    });
  };

  return isLoading ? null : (
    <div>
      <div className="mt-4 card">
        <div className="flex justify-between items-center">
          <h5 style={{ textAlign: 'left', margin: 0 }}>
            {t('checkout-areas.informations')}
          </h5>
          <div className="flex items-center">
            <div
              className="btn btn-primary-ghost rounded-xl"
              onClick={() => handleDuplicateCheckoutArea()}
            >
              {t('duplicate')}
            </div>
            <div
              className="btn btn-warning rounded-xl"
              onClick={() => setDisabled(false)}
            >
              {t('edit')}
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={currentCheckoutArea?.name}
            render={({ field: { onChange, value } }: any) => (
              <Input
                customClassName={`w-2/3 text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none py-4 px-3 bg-white border-gray-400 rounded-3xl mb-0 h-10 flex ${
                  disabled
                    ? ' opacity-50 hover:border-gray-400 pointer-events-none'
                    : ''
                }`}
                disabled={disabled}
                key="name"
                label={t('checkout-areas.name')}
                tooltip={t('checkout-areas.name_tooltip')}
                size="small"
                onChange={onChange}
                value={value}
                errors={[
                  _.get(errors, 'name.message'),
                  ...backendErrorsForAttribute('name'),
                ]}
              />
            )}
            name={'name'}
          />
          {disabled ? null : (
            <div className="flex justify-end my-4">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => handleCancelEdit()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary'}
              />
            </div>
          )}
        </form>
        <div className="mb-6 mt-6">
          <h5 style={{ textAlign: 'left', margin: 0 }}>
            {t('checkout-areas.products')}
          </h5>
        </div>
        {!productsData?.length ? (
          <div className="mt-10 flex items-center">
            <div className="mr-4">
              <span className="text-sm font-semibold text-[#6B7194]">
                {t('tables.empty-products')}
              </span>
            </div>
            <button
              onClick={() => navigate('/products')}
              className="btn btn-primary-outlined rounded-3xl"
            >
              {t('add')}
            </button>
          </div>
        ) : (
          <div className="lg:flex">
            <div className="lg:flex-1 w-full">
              <h2 className="lg:text-xl text-center lg:text-left font-bold my-4">
                {t('checkout-areas.available')}
              </h2>
              <div className="h-96 overflow-auto rounded-xl bg-white px-2 lg:px-0">
                {availableProducts?.map((product: Product) => (
                  <label
                    key={product._id}
                    htmlFor={product._id}
                    className="cursor-pointer justify-between flex items-center py-4 px-8 border hover:border-primary border-gray-200 bg-white my-2 rounded-2xl w-full lg:w-5/6"
                  >
                    <div className="flex flex-row items-center">
                      <input
                        type="checkbox"
                        id={product._id}
                        name={product._id}
                        disabled={false}
                        checked={checkedProducts.includes(product._id)}
                        onChange={(e) => e.target.checked}
                        onClick={handleSelect}
                      />

                      <div className="ml-4 mr-2 text-center">
                        <Emoji emoji={product.emoji} size={36} />
                      </div>
                      <div className="mx-2 text-left">
                        <span>
                          {product?.translations?.filter(
                            (t: any) =>
                              t.languageCode === i18n.language.slice(0, 2),
                          ).length
                            ? product.translations.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              )[0].title
                            : product?.name}
                        </span>
                      </div>
                    </div>
                    <div className="mx-2 text-right">
                      <span className="font-satoshi">
                        {displayPrice(
                          product.sellingPrice,
                          getCurrencyInfo(currentWorkspace.currency).symbol,
                          { thousandSeparator: ' ' },
                        )}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>
            <div className="my-4 lg:mx-4 h-1 w-full lg:h-96 lg:w-1 bg-gray-100" />
            <div className="lg:flex-1 w-full">
              <h2 className="lg:text-xl text-center lg:text-left font-bold my-4">
                {t('checkout-areas.selected')}
              </h2>
              <div className="h-96 overflow-auto rounded-xl bg-white px-2 lg:px-0">
                {currentCheckoutArea.checkoutAreaProducts
                  ?.sort(
                    (a: CheckoutAreaProduct, b: CheckoutAreaProduct) =>
                      a.order - b.order,
                  )
                  .map((caProduct: CheckoutAreaProduct, index: number) => (
                    <div key={index}>
                      <div className="flex items-center py-4  border border-gray-200 bg-white my-2 rounded-2xl w-full justify-between">
                        <div className="flex flex-col mx-4 justify-center items-center ">
                          <div>
                            {index !== 0 && (
                              <FontAwesomeIcon
                                className="text-middleGrey hover:text-primary cursor-pointer"
                                icon={faChevronCircleUp}
                                size="lg"
                                onClick={() =>
                                  onChangeOrderProduct(index, index - 1)
                                }
                              />
                            )}
                          </div>
                          <div>
                            {index !==
                              currentCheckoutArea.checkoutAreaProducts.length -
                                1 && (
                              <FontAwesomeIcon
                                className="text-middleGrey hover:text-primary cursor-pointer"
                                icon={faChevronCircleDown}
                                size="lg"
                                onClick={() =>
                                  onChangeOrderProduct(index, index + 1)
                                }
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-between text-left">
                          <div className="flex flex-row items-center">
                            <div className="mx-2 text-center">
                              <Emoji
                                emoji={caProduct.product.emoji}
                                size={36}
                              />
                            </div>
                            <div className="mx-2 text-left ">
                              <label
                                htmlFor={caProduct.product.name}
                                className="product-label"
                              >
                                {caProduct.product?.translations.filter(
                                  (t: any) =>
                                    t.languageCode ===
                                    i18n.language.slice(0, 2),
                                ).length
                                  ? caProduct.product.translations.filter(
                                      (t: any) =>
                                        t.languageCode ===
                                        i18n.language.slice(0, 2),
                                    )[0].title
                                  : caProduct?.product?.name}
                              </label>
                            </div>
                          </div>
                          <div className="mx-2 text-right">
                            <span className="font-satoshi">
                              {displayPrice(
                                caProduct.product.sellingPrice,
                                getCurrencyInfo(currentWorkspace.currency)
                                  .symbol,
                                { thousandSeparator: ' ' },
                              )}
                            </span>
                          </div>
                        </div>
                        <div className=" pr-4">
                          <Icon
                            className="text-middleGrey hover:text-red-500 cursor-pointer"
                            fontSize="small"
                            component={CancelIcon}
                            onClick={() =>
                              handleDeleteProduct(caProduct.product._id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end my-4">
          <SubmitButton
            disabled={!checkedProducts.length}
            title={`${t('add')} ${
              checkedProducts.length > 0 ? `(${checkedProducts.length})` : ''
            }`}
            onClick={addProduct}
            customClass={'btn btn-primary'}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckoutAreaDetails;
