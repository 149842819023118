import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  currentPage: number;
  totalPagesCount?: number;
  totalItems?: number;
  // perPage: number;
  onChangePage?: any;
  showPageNumber?: boolean;
}

const Pagination = ({
  currentPage,
  totalPagesCount,
  totalItems,
  // perPage,
  onChangePage,
  showPageNumber = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6 w-full">
        <div className="flex flex-1 items-center justify-between w-full">
          <div className="flex flex-col gap-3 lg:flex-row">
            <div>
              {totalItems} {t('results')}
            </div>{' '}
            <div>
              {' '}
              Page {currentPage} {t('on')} {totalPagesCount}
            </div>
          </div>
          {/* <div>
            <p className="text-sm text-gray-700">
              Showing
              <span className="font-medium">
                {perPage * currentPage - perPage}
              </span>
              to
              <span className="font-medium">{perPage * currentPage}</span>
              of
              <span className="font-medium">{totalItems}</span>
              results
            </p>
          </div> */}
          {totalPagesCount && totalPagesCount < 2 ? null : (
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm w-full"
                aria-label="Pagination"
              >
                <button
                  onClick={() => onChangePage(currentPage - 1)}
                  className={
                    'w-full relative sm:inline-flex items-center rounded-l-md  border border-grey  bg-white px-2 py-2 text-sm font-medium text-grey focus:z-20' +
                    (currentPage === 1
                      ? 'cursor-not-allowed opacity-50 hover:bg-none border-grey'
                      : 'hover:border-primary hover:text-white  hover:bg-primary')
                  }
                  disabled={currentPage === 1}
                >
                  <span className="sr-only">{t('previous')}</span>

                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {showPageNumber &&
                  [...Array(totalPagesCount)].map((e, i) => {
                    return (
                      <button
                        onClick={() => onChangePage(i + 1)}
                        key={i}
                        className={
                          'relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20' +
                          (i + 1 === currentPage
                            ? 'text-lightGrey font-bold bg-primary border-primary'
                            : 'text-grey border-grey bg-lightblue')
                        }
                      >
                        {i + 1}
                      </button>
                    );
                  })}

                <button
                  onClick={() => onChangePage(currentPage + 1)}
                  disabled={currentPage === totalPagesCount}
                  className={
                    'w-full relative inline-flex items-center rounded-r-md border border-grey  bg-white px-2 py-2 text-sm font-medium text-grey focus:z-20' +
                    (currentPage === totalPagesCount
                      ? 'cursor-not-allowed opacity-50 hover:bg-none border-grey'
                      : 'hover:border-primary hover:text-white hover:bg-primary')
                  }
                >
                  <span className="sr-only">{t('next')}</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </nav>
            </div>
          )}
        </div>
        <div className="flex flex-1 justify-end lg:justify-between sm:hidden">
          <button
            onClick={() => onChangePage(currentPage - 1)}
            className={
              'relative sm:inline-flex items-center rounded-l-md  border border-grey  bg-white px-2 py-2 text-sm font-medium text-grey  focus:z-20' +
              (currentPage === 1
                ? 'cursor-not-allowed opacity-50 hover:bg-none border-grey'
                : 'hover:border-primary hover:text-white  hover:bg-primary')
            }
            disabled={currentPage === 1}
          >
            {t('previous')}
          </button>
          <button
            onClick={() => onChangePage(currentPage + 1)}
            disabled={currentPage === totalPagesCount}
            className={
              'relative sm:inline-flex items-center rounded-r-md border border-grey hover:border-primary bg-white px-2 py-2 text-sm font-medium text-grey hover:text-white  hover:bg-primary focus:z-20' +
              (currentPage === totalPagesCount
                ? 'cursor-not-allowed opacity-50 hover:bg-none border-grey'
                : 'hover:border-primary hover:text-white hover:bg-primary')
            }
          >
            {t('next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
