import mixpanel, { Config } from 'mixpanel-browser';

const package_json = require('../package.json');

const options: Partial<Config> = {
  ignore_dnt: process.env.REACT_APP_IS_PRODUCTION === 'false',
  debug: process.env.REACT_APP_IS_PRODUCTION === 'false',
};

export const COZYUP_CLIENT = 'cozyup_web';
export const CLIENT_VERSION = `v${package_json.version}`;

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, options);
mixpanel.register({
  cozyup_client: COZYUP_CLIENT,
  cozyup_client_version: CLIENT_VERSION,
});

export default mixpanel;
