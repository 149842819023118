import React, { useRef, useState } from 'react';
import SubmitButton from '../submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../utils/validation';
import Select from '../select/select.component';
import PriceInput from '../price-input/PriceInput.component';
import { Emoji, Picker } from 'emoji-mart';
import OnClickOut from '../../hooks/useOnClickOut';
import colors from '../../styles/_colors.module.scss';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import Input from '../input/input.component';
import TextArea from '../text-area/text-area.component';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';
import { preparationTimeInHours } from '../../utils/date';

import { useMutation } from 'react-query';
import { upload } from '../../api/files';
import { resizeImage } from '../../utils/image';
import { getCurrencyInfo } from '../../utils/currency';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccess: Function;
};

function AddProductModal({ displayModal, setDisplayModal, onSuccess }: Props) {
  const [displayEmojiPicker, setDisplayEmojiPicker] = useState<boolean>(false);
  const { t } = useTranslation();
  const { currentAccount, currentWorkspace, currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  let currency = getCurrencyInfo(currentWorkspace?.currency).symbol;

  const isAvailableOptions = [
    { label: t('products.status.available'), key: true },
    { label: t('products.status.unavailable'), key: false },
  ];

  const requireValidationOptions = [
    { label: t('yes'), key: true },
    { label: t('no'), key: false },
  ];

  const handleCancelEdit = () => {
    setDisplayModal(false);
  };

  const [emoji, setEmoji] = useState<string>('grey_question');

  const submitAddProduct = async (data: any) => {
    setIsLoading(true);
    data.costPrice = Math.round(+data.costPrice * 100);
    data.sellingPrice = Math.round(+data.sellingPrice * 100);
    if (productPictures.length) {
      const picturesUploaded = await uploadPictures(productPictures);
      const pictures = picturesUploaded.map((p) => ({ url: p.url, order: 1 }));
      onSuccess({
        ...data,
        emoji,
        pictures,
      });
      setIsLoading(false);
    } else {
      onSuccess({
        ...data,
        emoji,
      });
      setIsLoading(false);
    }
  };

  const pickEmoji = (emoji: any) => {
    emoji?.id && setEmoji(emoji.id);
  };

  const [productPictures, setProductPictures] = useState<any[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  const handleOnChangePicture = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length) {
      const newPicture = {
        order: 1,
        file: e.target.files[0],
        localUrl: URL.createObjectURL(e.target.files[0]),
      };
      setProductPictures((pictures) => [...pictures, newPicture]);
    }
  };

  const uploadPictures = async (pictures: any[]) => {
    return await Promise.all(
      pictures.map(async (picture) => {
        const resizedFile = await resizeImage(picture.file);
        if (resizedFile) {
          return await uploadNewPicture(resizedFile);
        }
      }),
    );
  };

  const handleOnRemovePicture = async (indexToDelete: number) => {
    const copyPictures = productPictures.filter(
      (item, index) => index !== indexToDelete,
    );
    setProductPictures(copyPictures);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <div className="relative w-[95%] flex flex-col justify-between max-w-[800px] min-h-[350px] lg:min-h-[150px] max-h-[95%] overflow-y-auto bg-white mt-5 mx-auto rounded-[16px]">
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-2xl mb-4">
                {t('products.add_extra')}
              </h5>
            </div>
          </div>
          <div>
            <div className="lg:flex px-6 py-6">
              <div className="">
                <label className="text-left text-[#6B7194]">
                  {t('products.emoji')}
                </label>
                <div
                  className="relative p-10 mt-2 border border-gray-300 rounded-xl w-fit"
                  onClick={() => setDisplayEmojiPicker(!displayEmojiPicker)}
                >
                  <Emoji className="absolute" emoji={emoji} size={60} />
                </div>
                <OnClickOut callback={() => setDisplayEmojiPicker(false)}>
                  {!displayEmojiPicker ? null : (
                    <div className="absolute">
                      <Picker
                        showPreview={false}
                        showSkinTones={false}
                        set="apple"
                        color={colors.primary}
                        onClick={pickEmoji}
                      />
                    </div>
                  )}
                </OnClickOut>
              </div>
              <form
                id="addProduct"
                className="flex-1 w-full"
                onSubmit={handleSubmit(submitAddProduct)}
              >
                <div className="mt-6 lg:mt-0 lg:px-6 w-full">
                  <div>
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={''}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          key="name"
                          label={t('products.name')}
                          size="small"
                          onChange={onChange}
                          value={value}
                          errors={[_.get(errors, 'name.message')]}
                        />
                      )}
                      name={'name'}
                    />
                  </div>
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    defaultValue={''}
                    render={({ field: { onChange, value } }: any) => (
                      <TextArea
                        key="description"
                        label={t('products.description')}
                        onChange={onChange}
                        value={value}
                        errors={[_.get(errors, 'description.message')]}
                      />
                    )}
                    name={'description'}
                  />
                  <div>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }: any) => (
                        <TextArea
                          key="instruction"
                          label={`${t('products.instructions')}`}
                          onChange={onChange}
                          value={value}
                          tooltip={`${t('products.instructions_tooltip')}`}
                          errors={[_.get(errors, 'instruction.message')]}
                        />
                      )}
                      name={'instruction'}
                    />
                  </div>
                  <div className="flex justify-between">
                    <div className="pr-4 flex-1">
                      <Controller
                        control={control}
                        rules={{ ...Validation.anyRequired }}
                        defaultValue={0}
                        render={({ field: { onChange, value } }: any) => (
                          <PriceInput
                            key="costPrice"
                            label={`${t('products.cost_price')} (${currency})`}
                            size="small"
                            onChange={onChange}
                            value={value}
                            errors={[_.get(errors, 'costPrice.message')]}
                          />
                        )}
                        name={'costPrice'}
                      />
                    </div>
                    <div className="pl-4 flex-1">
                      <Controller
                        control={control}
                        rules={{
                          ...Validation.anyRequired,
                          ...Validation.price,
                          ...Validation.greaterThanZero,
                        }}
                        defaultValue={0}
                        render={({ field: { onChange, value } }: any) => (
                          <>
                            <PriceInput
                              key="sellingPrice"
                              label={`${t(
                                'products.selling_price',
                              )} (${currency})`}
                              size="small"
                              onChange={onChange}
                              value={value}
                              helperText={`${t('products.gain')} ${
                                value &&
                                (
                                  value -
                                  (value * currentAccount.percentageFeeHost) /
                                    100
                                ).toFixed(2)
                              }€`}
                              errors={[_.get(errors, 'sellingPrice.message')]}
                            />
                          </>
                        )}
                        name={'sellingPrice'}
                      />
                    </div>
                  </div>
                  <div className="lg:flex justify-between">
                    <div className="lg:pr-4 flex-1">
                      <Controller
                        control={control}
                        rules={Validation.anyRequired}
                        defaultValue={true}
                        render={({ field: { onChange, value } }: any) => (
                          <Select
                            key="isAvailable"
                            label={t('products.status.status')}
                            onChange={onChange}
                            value={value}
                            options={isAvailableOptions}
                            errors={[_.get(errors, 'isAvailable.message')]}
                          />
                        )}
                        name={'isAvailable'}
                      />
                    </div>
                    <div className="lg:pl-4 flex-1">
                      <Controller
                        control={control}
                        rules={{
                          ...Validation.anyRequired,
                          ...Validation.integer,
                        }}
                        defaultValue={0}
                        render={({ field: { onChange, value } }: any) => (
                          <Select
                            key="preparationTimeInHours"
                            label={`${t('products.preparation_time')}`}
                            onChange={onChange}
                            value={value}
                            options={preparationTimeInHours}
                            tooltip={`${t('products.preparation_time_dsc')}`}
                            errors={[
                              _.get(errors, 'preparationTimeInHours.message'),
                            ]}
                          />
                        )}
                        name={'preparationTimeInHours'}
                      />
                    </div>
                  </div>
                  <div className="lg:flex justify-between">
                    <div className="lg:pr-4 flex-1">
                      <Controller
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }: any) => (
                          <Select
                            key="validationRequired"
                            label={t('products.require_validation')}
                            onChange={onChange}
                            value={value}
                            tooltip={`${t(
                              'orders.require_validation_tooltip',
                            )}`}
                            options={requireValidationOptions}
                            errors={[
                              _.get(errors, 'validationRequired.message'),
                            ]}
                          />
                        )}
                        name={'validationRequired'}
                      />
                    </div>
                    <div className="flex-1"></div>
                  </div>

                  <div className="mb-6 flex flex-col justify-start">
                    <label className="text-left text-[#6B7194] mb-3">
                      {t('products.pictures')} ({productPictures.length}/5)
                    </label>
                    <div className="flex flex-col lg:flex-row w-full gap-2 flex-wrap">
                      {!productPictures.length
                        ? null
                        : productPictures.map((picture: any, index: number) => (
                            <div key={index} className="relative">
                              <img
                                className="rounded-3xl flex-1 h-48 w-full lg:w-48 object-cover"
                                src={picture.localUrl}
                              />
                              <a
                                className="absolute top-3 right-3 cursor-pointer h-8 w-8 border border-primary flex items-center justify-center bg-gray-50 rounded-full"
                                onClick={() => handleOnRemovePicture(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faMinus}
                                  color="#7282E5"
                                  size="lg"
                                />
                              </a>
                            </div>
                          ))}
                      {productPictures.length >= 5 ? null : (
                        <div
                          onClick={() => handleUploadClick()}
                          className="group flex flex-col justify-center items-center cursor-pointer shadow rounded-3xl font-satoshi h-48 w-full lg:w-48  hover:border-primary border-[2px] border-transparent"
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            size="3x"
                            color="#d3d3d3"
                            className="group-hover:text-primary"
                          />
                          <input
                            id="pictures"
                            key="pictures"
                            ref={inputRef}
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            onChange={handleOnChangePicture}
                          />
                          <p className="mt-3 text-middleGrey font-medium group-hover:text-primary text-[16px]">
                            {t('products.add_picture')}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <button
                      title={t('cancel')}
                      className="btn rounded-3xl btn-primary-outlined"
                      style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => handleCancelEdit()}
                    >
                      {t('cancel')}
                    </button>
                    <SubmitButton
                      title={t('confirm')}
                      customClass={'btn rounded-3xl btn-primary flex'}
                      disabled={isLoading || isLoadingPicture ? true : false}
                    >
                      {isLoading || isLoadingPicture ? (
                        <FontAwesomeIcon
                          style={{ marginLeft: 15 }}
                          icon={faSpinnerThird}
                          spin
                        />
                      ) : null}
                    </SubmitButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductModal;
