type MessageType = 'after_reservation' | 'before_arrival';

const messageTemplates = {
  fr: {
    after_reservation:
      'Bonjour [PRENOM_VOYAGEUR], <br/><br/>Nous avons hâte de vous accueillir à [NOM_LOGEMENT] 😊 <br/><br/>Sachez que vous pouvez personnaliser votre séjour avec nos services à la carte disponibles ici: </br></br>👉 [LIEN_BOUTIQUE_COZYUP] <br/><br/>À très vite! ☀️',
    before_arrival:
      'Bonjour [PRENOM_VOYAGEUR], <br/><br/>Plus que quelques heures avant votre arrivée à [NOM_LOGEMENT] 😊 </br></br>Pour rappel, c’est le dernier moment pour personnaliser votre séjour avec nos services à la carte! </br></br>Cliquez ici pour les consulter avant qu’il ne soit trop tard: <br/><br/>👉 [LIEN_BOUTIQUE_COZYUP] <br/><br/> À très vite! ☀️',
  },
  en: {
    after_reservation:
      'Hello [GUEST_FIRSTNAME], <br/><br/>We look forward to welcoming you to [PROPERTY_NAME] 😊 <br/><br/>Please know that you can customize your stay with our exclusive add-ons here: </br></br>👉 [COZYUP_STORE_URL] <br/><br/>See you soon! ☀️',
    before_arrival:
      "Hello [GUEST_FIRSTNAME], <br/><br/>You'll soon be arriving at [PROPERTYNAME] 😊 <br/><br/>As a reminder, this is your last chance to customize your stay with our exclusive add-ons! <br/><br/>Click here to check them out before it's too late: </br></br>👉 [COZYUP_STORE_URL] <br/><br/>See you very soon! ☀️",
  },
};

export const getMessageTemplate = (
  language: 'fr' | 'en',
  messageType: MessageType,
): string => {
  return messageTemplates[language][messageType];
};
