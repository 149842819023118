import React from 'react';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ProcessingActionProps = {
  message: string;
};

const ProcessingAction = ({ message }: ProcessingActionProps) => {
  return (
    <div className="flex flex-row justify-center items-center">
      <p className=" font-semibold text-[#5a6978]">{message}</p>
      <div className="ml-3">
        <FontAwesomeIcon
          style={{ marginRight: 15 }}
          icon={faSpinnerThird}
          className="animate-spin"
        />
      </div>
    </div>
  );
};

export default ProcessingAction;
