import React from 'react';

type Props = {
  children: React.ReactChild;
};

const TabContent = ({ children }: Props) => {
  return <div className="container-lg">{children}</div>;
};

export default TabContent;
