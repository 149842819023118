import React, { useEffect, useState } from 'react';
import { SelectHomeImportModal } from '../../modals/select-home-import-modal.component';
import { useAuth } from '../../../contexts/authContext';
import {
  getBeds24Properties,
  getBookingSyncProperties,
  getCalryProperties,
  getHospitableProperties,
  getSmoobuProperties,
  getSuperHoteProperties,
} from '../../../api/channelManagers';

import { useMutation, useQuery } from 'react-query';
import SelectBeds24PropertiesModal from '../../modals/select-beds24-properties-modal.component';
import { ImportAirbnbOnboardingModal } from '../../modals/import-airbnb-onboarding-modal.component';

import { AddManuallyHomeModal } from '../../modals/add-manually-home-modal.component';
import Button from '../../button/button.component';
import DefaultHomePicture from '../../../assets/images/default-home-picture.jpeg';
import { createCheckoutPage, createHomes } from '../../../api/onboarding';
import { deleteHome, getHomesByWorkspace } from '../../../api/homes';
import { t } from 'i18next';
import SelectBookingSyncPropertiesModal from '../../modals/select-bookingsync-properties-modal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import SelectHospitableConnectAirbnbPropertiesModal from '../../modals/select-hospitable-connect-airbnb-properties-modal.component';
import { SelectAirbnbMethodModal } from '../../modals/select-airbnb-method-modal.component';
import SelectSmoobuPropertiesModal from '../../modals/select-smoobu-properties-modal.component';
import SelectCalryPropertiesModal from '../../modals/select-calry-properties-modal.component';
import { HomeCard } from '../home/home-card/home-card.component';
import Loader from '../../loader/loader.component';
import { getAirbnbByHospitableProperties } from '../../../api/platforms';
import { OnboardingProductTemplates } from '../../../data/product-templates.data';
import useTrackOnboardingWithMixpanel from '../../../hooks/useTrackOnboardingWithMixpanel';
import SelectHospitablePropertiesModal from '../../modals/select-hospitable-properties-modal.component';
import ImportSuperHotePropertiesModal from '../../modals/import-superhote-properties-modal/import-superhote-properties-modal.component';

type Props = {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  updateOnboarding?: Function;
  channelManagerIntegrations: any;
  calryIntegrations: any;
  airbnbChannels: any;
};

const InitHomes = ({
  setActiveTab,
  channelManagerIntegrations,
  calryIntegrations,
  airbnbChannels,
}: Props) => {
  const { currentUser, currentWorkspace } = useAuth();

  const [calryIntegrationId, setCalryIntegrationId] = useState<string>('');

  const [displayChooseMethodImportModal, setDisplayChooseMethodImportModal] =
    useState<boolean>(false);

  const [
    displayImportAirbnbOnboardingModal,
    setDisplayImportAirbnbOnboardingModal,
  ] = useState<boolean>(false);

  const [
    displaySelectCalryPropertiesModal,
    setDisplaySelectCalryPropertiesModal,
  ] = useState<boolean>(false);

  const [
    displaySelectHospitablePropertiesModal,
    setDisplaySelectHospitablePropertiesModal,
  ] = useState<boolean>(false);

  const [
    displaySelectSmoobuPropertiesModal,
    setDisplaySelectSmoobuPropertiesModal,
  ] = useState<boolean>(false);

  const [
    displaySelectSuperHotePropertiesModal,
    setDisplaySelectSuperHotePropertiesModal,
  ] = useState<boolean>(false);

  const [displayAirbnbMethodModal, setDisplayAirbnbMethodModal] =
    useState<boolean>(false);

  const [
    displaySelectBookingSyncPropertiesModal,
    setDisplaySelectBookingSyncPropertiesModal,
  ] = useState<boolean>(false);

  const [
    displayImportHospitableConnectAirbnbModal,
    setDisplayImportHospitableConnectAirbnbModal,
  ] = useState<boolean>(false);

  const [displayAddManuallyHomeModal, setDisplayAddManuallyHomeModal] =
    useState<boolean>(false);

  const [
    displaySelectBeds24PropertiesModal,
    setDisplaySelectBeds24PropertiesModal,
  ] = useState(false);
  const [homes, setHomes] = useState<any[]>([]);

  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  useEffect(() => {
    trackOnboardingEvent('Onboarding - Step 2. IMPORT_PROPERTIES - Start');
  }, []);

  const { data: fetchedHomes, isLoading: isLoadingHomes } = useQuery(
    ['homes', currentWorkspace],
    // @ts-ignore
    () => getHomesByWorkspace(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => {
        if (data?.length === 0 && homes.length === 0) {
          calryIntegrations?.map((integration: any) => {
            fetchCalryProperties(integration.id);
          });
          if (airbnbChannels?.length > 0) {
            fetchAirbnbProperties();
          }
          fetchChannelManagerProperties();
        }
      },
    },
  );

  const {
    mutate: createCheckoutPageAction,
    isLoading: isLoadingCreationCheckoutArea,
  } = useMutation(
    (data: any) =>
      createCheckoutPage(currentUser.accessToken, currentWorkspace._id, data),
    {
      enabled: false,
      onSuccess: () => {
        setActiveTab(3);
      },
    },
  );

  useEffect(() => {
    const savedHomes = fetchedHomes?.map((home: any) => ({
      ...home,
      isNew: false,
    }));
    setHomes(savedHomes || []);
  }, [fetchedHomes]);

  const { mutate: create, isLoading: isLoadingCreationMultiple } = useMutation(
    (data: any) =>
      createHomes(currentUser.accessToken, currentWorkspace._id, data),
    {
      onSuccess: () => {
        const payload = {
          title: t('onboarding.default_headline'),
          description: t('onboarding.default_description'),
          products: OnboardingProductTemplates.map(
            (product: any, index: number) => ({
              ...product,
              order: index,
            }),
          ),
        };
        createCheckoutPageAction(payload);
      },
    },
  );

  const handleGoToNextStep = () => {
    const filterNewHomes = [...homes.filter((home: any) => home.isNew)];
    if (!filterNewHomes.length) {
      return setActiveTab(3);
    }
    create({ homes: filterNewHomes });
    trackOnboardingEvent(`Onboarding - Step 2. IMPORT_PROPERTIES - Success`);
  };

  const handleOnDeleteHome = (home: any) => {
    if (home.isNew) {
      const newHomes = homes.filter((h: any) => h !== home);
      setHomes(newHomes);
    } else {
      deleteHomeAction(home._id);
      const newHomes = homes.filter((h: any) => h._id !== home._id);
      setHomes(newHomes);
    }
  };

  const { mutate: deleteHomeAction } = useMutation(
    // @ts-ignore
    (id: string) =>
      deleteHome(currentUser.accessToken, currentWorkspace._id, id),
    {
      enabled: false,
    },
  );

  const handleNewHomes = (newHome: any) => {
    const newHomes = [...homes, { ...newHome, isNew: true }];
    setHomes(newHomes);
  };

  const onSelectedPropertiesSubmit = (properties: any) => {
    const newHomes = [
      ...homes,
      ...properties.map((property: any) => ({
        ...property,
        isNew: true,
        picture: property.picture || DefaultHomePicture,
      })),
    ];
    setHomes(newHomes);
    setDisplaySelectBeds24PropertiesModal(false);
    setDisplaySelectBookingSyncPropertiesModal(false);
    setDisplayImportHospitableConnectAirbnbModal(false);
    setDisplaySelectSmoobuPropertiesModal(false);
    setDisplaySelectCalryPropertiesModal(false);
    setDisplaySelectHospitablePropertiesModal(false);
    setDisplaySelectSuperHotePropertiesModal(false);
  };

  const { mutate: fetchCalryProperties, isLoading: isLoadingCalryProperties } =
    useMutation(
      (integrationAccountId: string) =>
        getCalryProperties(
          currentUser.accessToken,
          currentWorkspace._id,
          integrationAccountId,
        ),
      {
        onSuccess: (data: any) => {
          onImportProperties(data, null);
        },
      },
    );

  const {
    mutate: fetchAirbnbProperties,
    isLoading: isLoadingAirbnbProperties,
  } = useMutation(
    () =>
      getAirbnbByHospitableProperties(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'HOSPITABLE_CONNECT_AIRBNB');
      },
    },
  );

  const {
    mutate: fetchBeds24Properties,
    isLoading: isLoadingBeds24Properties,
  } = useMutation(
    () => getBeds24Properties(currentUser.accessToken, currentWorkspace._id),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'BEDS24');
      },
    },
  );

  const {
    mutate: fetchBookingSyncProperties,
    isLoading: isLoadingBookingSyncProperties,
  } = useMutation(
    () =>
      getBookingSyncProperties(currentUser.accessToken, currentWorkspace._id),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'BOOKING_SYNC');
      },
    },
  );

  const {
    mutate: fetchHospitableProperties,
    isLoading: isLoadingHospitableProperties,
  } = useMutation(
    () =>
      getHospitableProperties(currentUser.accessToken, currentWorkspace._id),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'HOSPITABLE');
      },
    },
  );

  const onImportProperties = (
    homes: any,
    channelManagerType: string | null,
  ) => {
    if (homes.length) {
      homes.map((property: any) => {
        const newHomes = [
          {
            ...property,
            channelManagerConnection: {
              channelManagerType: channelManagerType
                ? channelManagerType
                : `CALRY_${property.integrationName}`,
              channelManagerId: property.id,
            },

            isNew: true,
          },
        ];
        setHomes((prev: any) => [...prev, ...newHomes]);
      });
    }
  };

  const {
    mutate: fetchSmoobuProperties,
    isLoading: isLoadingSmoobuProperties,
  } = useMutation(
    () => getSmoobuProperties(currentUser.accessToken, currentWorkspace._id),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'SMOOBU');
      },
    },
  );

  const {
    mutate: fetchSuperHoteProperties,
    isLoading: isLoadingSuperHoteProperties,
  } = useMutation(
    () => getSuperHoteProperties(currentUser.accessToken, currentWorkspace._id),
    {
      onSuccess: (data: any) => {
        onImportProperties(data, 'SUPERHOTE');
      },
    },
  );

  const onMethodChoosed = (method: string) => {
    switch (method) {
      case 'beds24': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'BEDS24',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'booking_sync':
        setDisplaySelectBookingSyncPropertiesModal(true);
        break;
      case 'smoobu':
        setDisplaySelectSmoobuPropertiesModal(true);
        break;
      case 'superhote':
        setDisplaySelectSuperHotePropertiesModal(true);
        break;
      case 'hostaway': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'HOSTAWAY',
        );
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'lodgify': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'LODGIFY',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'lodgix': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'LODGIX',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'hostify': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'HOSTIFY',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'guesty': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'GUESTY',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'tokeet': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'TOKEET',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'uplisting': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'UPLISTING',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'fantasticstay': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) =>
            integration.name?.toUpperCase() === 'FANSTATICSTAY',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'ownerrez': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'OWNERREZ',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'avaibook': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'AVAIBOOK',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'hostfully': {
        const currentIntegration = calryIntegrations.filter(
          (integration: any) => integration.name?.toUpperCase() === 'HOSTFULLY',
        )[0];
        setCalryIntegrationId(currentIntegration?.id);
        setDisplaySelectCalryPropertiesModal(true);

        break;
      }
      case 'manual':
        setDisplayAddManuallyHomeModal(true);
        break;
      case 'airbnb_method':
        setDisplayAirbnbMethodModal(true);
        break;
      case 'airbnb_scrapper':
        setDisplayImportAirbnbOnboardingModal(true);
        break;
      case 'hospitable_connect_airbnb':
        setDisplayImportHospitableConnectAirbnbModal(true);
        break;
      default:
        break;
    }
  };

  const fetchChannelManagerProperties = () => {
    channelManagerIntegrations.map((integration: any) => {
      switch (integration.channelManagerType.name) {
        case 'BEDS24':
          fetchBeds24Properties();
          break;
        case 'BOOKING_SYNC':
          fetchBookingSyncProperties();
          break;
        case 'HOSPITABLE':
          fetchHospitableProperties();
          break;
        case 'SMOOBU':
          fetchSmoobuProperties();
          break;
        case 'SUPERHOTE':
          fetchSuperHoteProperties();
          break;
        default:
          break;
      }
    });
  };

  const isLoadingImportation =
    isLoadingBeds24Properties ||
    isLoadingCalryProperties ||
    isLoadingBookingSyncProperties ||
    isLoadingHospitableProperties ||
    isLoadingSmoobuProperties ||
    isLoadingAirbnbProperties ||
    isLoadingSuperHoteProperties;

  return (
    <div>
      <SelectHomeImportModal
        displayModal={displayChooseMethodImportModal}
        setDisplayModal={setDisplayChooseMethodImportModal}
        onChoose={onMethodChoosed}
      />

      <ImportAirbnbOnboardingModal
        displayModal={displayImportAirbnbOnboardingModal}
        setDisplayModal={setDisplayImportAirbnbOnboardingModal}
        onSuccess={(newHome: any) => handleNewHomes(newHome)}
      />
      <SelectAirbnbMethodModal
        displayModal={displayAirbnbMethodModal}
        setDisplayModal={setDisplayAirbnbMethodModal}
        onChoose={onMethodChoosed}
      />

      {displaySelectHospitablePropertiesModal && (
        <SelectHospitablePropertiesModal
          displayModal={displaySelectHospitablePropertiesModal}
          setDisplayModal={setDisplaySelectHospitablePropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          selectedProperties={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
        />
      )}

      {displaySelectCalryPropertiesModal && (
        <SelectCalryPropertiesModal
          displayModal={displaySelectCalryPropertiesModal}
          setDisplayModal={setDisplaySelectCalryPropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          selectedProperties={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
          calryIntegrationId={calryIntegrationId}
        />
      )}

      {displaySelectSmoobuPropertiesModal && (
        <SelectSmoobuPropertiesModal
          displayModal={displaySelectSmoobuPropertiesModal}
          setDisplayModal={setDisplaySelectSmoobuPropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          selectedProperties={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
        />
      )}

      {displaySelectSuperHotePropertiesModal && (
        <ImportSuperHotePropertiesModal
          displayModal={displaySelectSmoobuPropertiesModal}
          setDisplayModal={setDisplaySelectSmoobuPropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          selectedProperties={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
        />
      )}

      {displaySelectBeds24PropertiesModal && (
        <SelectBeds24PropertiesModal
          displayModal={displaySelectBeds24PropertiesModal}
          setDisplayModal={setDisplaySelectBeds24PropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          selectedPropertiesIds={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
        />
      )}
      {displaySelectBookingSyncPropertiesModal && (
        <SelectBookingSyncPropertiesModal
          displayModal={displaySelectBookingSyncPropertiesModal}
          setDisplayModal={setDisplaySelectBookingSyncPropertiesModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          isLoadingCreation={isLoadingCreationMultiple}
          selectedProperties={homes.map((home: any) =>
            home.channelManagerConnection?.channelManagerId?.toString(),
          )}
        />
      )}
      {displayImportHospitableConnectAirbnbModal === false ? null : (
        <SelectHospitableConnectAirbnbPropertiesModal
          displayModal={displayImportHospitableConnectAirbnbModal}
          setDisplayModal={setDisplayImportHospitableConnectAirbnbModal}
          onSelectedProperties={onSelectedPropertiesSubmit}
          isLoadingCreation={isLoadingCreationMultiple}
        />
      )}
      <AddManuallyHomeModal
        displayModal={displayAddManuallyHomeModal}
        setDisplayModal={setDisplayAddManuallyHomeModal}
        onSuccess={(newHome: any) => handleNewHomes(newHome)}
      />
      <div className="p-4 rounded-3xl mb-3">
        <div className="sticky top-0 z-10 bg-white">
          <div className="flex justify-between">
            <div>
              <h2 className="text-left text-primary text-xl font-bold leading-12">
                {t('onboarding.homes.setup')}
              </h2>
              <div className="text-left text-grey">
                {t('onboarding.homes.setup_dsc')}
              </div>
            </div>
            {homes.length > 0 && (
              <div className="flex justify-end">
                <button
                  className="btn btn-primary-outlined px-3 py-2 text-sm"
                  onClick={() => setDisplayChooseMethodImportModal(true)}
                >
                  {t('add')}
                  <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faAdd} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-end items-end my-3">
            <div className="font-bold text-grey text-xs">
              {homes?.length === 0 ? null : (
                <span>
                  {t('onboarding.homes.home', { count: homes.length })}
                </span>
              )}
            </div>
          </div>
          {isLoadingImportation || isLoadingHomes ? (
            <div className=" flex flex-col min-h-[400px] max-h-[400px] items-center justify-center overflow-auto bg-lightblue border rounded-xl border-lightGrey  p-3">
              <Loader />
              <span className="text-sm text-primary">
                {t('onboarding.importing_inprogress')}
              </span>
            </div>
          ) : (
            <>
              {!homes.length ? (
                <div className=" flex flex-col min-h-[300px] max-h-[500px] items-center justify-center overflow-auto bg-lightblue border rounded-xl border-lightGrey p-3">
                  <div>
                    <button
                      disabled={isLoadingImportation}
                      onClick={() => setDisplayChooseMethodImportModal(true)}
                      className="btn btn-primary"
                    >
                      {t('add')}
                      <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faAdd} />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-4 gap-3 overflow-auto bg-lightblue border rounded-xl border-lightGrey p-3 flex-grow">
                  {homes.map((home: any, index: number) => (
                    <div className="w-full" key={index}>
                      <HomeCard home={home} onDelete={handleOnDeleteHome} />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex justify-end items-end mt-3 mb-12">
          <div className="font-bold text-grey text-xs">
            {homes?.length === 0 ? null : (
              <span>{t('onboarding.homes.home', { count: homes.length })}</span>
            )}
          </div>
        </div>
        <div className="fixed bottom-0 left-0 items-center py-1 border-t border-grey w-full bg-white">
          <div className="px-4 flex justify-center">
            <Button
              className="btn btn-primary-outlined"
              onClick={() => {
                trackOnboardingEvent(
                  'Onboarding - Go back to step 1.CM_PMS_CONNECTION from Step 2. IMPORT_PROPERTIES',
                );
                setActiveTab(1);
              }}
            >
              <FontAwesomeIcon style={{ marginRight: 15 }} icon={faArrowLeft} />
              {t('previous')}
            </Button>
            <Button
              disabled={
                !homes.length ||
                isLoadingCreationMultiple ||
                isLoadingImportation ||
                isLoadingCreationCheckoutArea
              }
              className="btn btn-primary"
              onClick={() => handleGoToNextStep()}
            >
              {t('onboarding.next')}
              {!isLoadingCreationMultiple ? (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faArrowRight}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitHomes;
