import React, { useEffect, useState } from 'react';
import Validation from '../../../utils/validation';
import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../../utils/helpers';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/input/input.component';
import { useAuth } from '../../../contexts/authContext';
import SubmitButton from '../../../components/submit-button/submit-button.component';
import ErrorMessage from '../../../components/error-message/error-message.component';
import { handleFirebaseLoginError } from '../../../utils/errors';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  let {
    resetPassword,
    isLoadingResetPassword,
    isForgotPasswordSuccess,
    forgotPasswordError,
    resetForgotPasswordState,
  } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const backendErrors = '';
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset();
    resetForgotPasswordState();
  }, []);

  useEffect(() => {
    let message;
    if (forgotPasswordError?.code) {
      message = handleFirebaseLoginError(forgotPasswordError?.code);
      setErrorMessage(message);
    }
  }, [forgotPasswordError]);

  const submit = (data: any) => {
    resetPassword(data.email);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="forgot-password-form">
      <Controller
        control={control}
        rules={{ ...Validation.email, ...Validation.anyRequired }}
        defaultValue={''}
        render={({ field: { onBlur, onChange, value } }: any) => (
          <Input
            type="email"
            key="email"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            label={`${capitalizeFirstLetter(t('email'))} *`}
            errors={[
              _.get(errors, 'email.message'),
              ...backendErrorsForAttribute('email'),
            ]}
            disabled={isForgotPasswordSuccess}
          />
        )}
        name={'email'}
      />
      <ErrorMessage error={t(errorMessage)} displayDot={false} />
      <div className="submit-button-wrapper">
        {isForgotPasswordSuccess ? (
          <SubmitButton
            customClass="submit-button-input"
            title={t('auth.sign-up-login')}
            onClick={() => navigate('/sign-in')}
          />
        ) : (
          <SubmitButton
            title={t('auth.forgot-password-action')}
            customClass="submit-button-input"
            isLoading={isLoadingResetPassword}
            disabled={isLoadingResetPassword}
          />
        )}
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
