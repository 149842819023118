import React, { useState } from 'react';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import GraphComponent from '../../components/graph/graph.component';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import {
  getGlobalstats,
  getOrdersStats,
  getReferralsStats,
  getUsersStats,
} from '../../api/stats';
import {} from '../../utils/date';
import Filters from '../../components/filters/filters.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBar from '../../components/search-bar/search-bar.component';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import Pagination from '../../components/pagination/pagination.component';
import { getAllOrders } from '../../api/orders';
import Loader from '../../components/loader/loader.component';
import OrderRawAdmin from '../../components/_main/orders/order-raw-admin/order-raw-admin.component';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import { WorkspaceOnboarding } from '../../components/admin/workspace-onboarding.component';
import { ReferralsList } from '../../components/admin/referrals-list.component';
import { SortOptions } from '../../types/generic-types';

const Statistics = () => {
  const { t } = useTranslation();
  const { currentUser, apiUser } = useAuth();
  const currency = getCurrencyInfo('eur');
  const [rangeDate, setRangeDate] = useState('7_day');

  const dateFilters = [
    { label: t('filters.last_days', { days: 7 }), value: '7_day' },
    { label: t('filters.last_weeks', { weeks: 4 }), value: '28_day' },
    { label: t('filters.last_months', { months: 12 }), value: '12_month' },
  ];

  const { data: orderStats, isFetching: isFetchingOrderStats } = useQuery(
    ['statsKey', currentUser, apiUser, rangeDate],
    // @ts-ignore
    () =>
      getOrdersStats(
        currentUser.accessToken,
        rangeDate.split('_')[0],
        rangeDate.split('_')[1],
      ),
    {
      enabled: !!currentUser && !!apiUser,
    },
  );

  const [activeOnboardingPage, setActiveOnboardingPage] = useState(1);
  const { data: onboardingStats, isLoading: isLoadingOnboardingStats } =
    useQuery(
      ['onboardingStats', currentUser, activeOnboardingPage],
      // @ts-ignore
      () => getUsersStats(currentUser.accessToken, activeOnboardingPage),
      {
        enabled: !!currentUser,
      },
      { keepPreviousData: true },
    );

  const [activeReferralsPage, setActiveReferralsPage] = useState(1);
  const { data: referralsStats, isLoading: isLoadingReferralsStats } = useQuery(
    ['referralsStats', currentUser, activeReferralsPage],
    // @ts-ignore
    () => getReferralsStats(currentUser.accessToken, activeReferralsPage),
    {
      enabled: !!currentUser,
    },
    { keepPreviousData: true },
  );

  const { data: globalStats, isLoading: isLoadingGlobalStats } = useQuery(
    ['globalStats', currentUser],
    // @ts-ignore
    () => getGlobalstats(currentUser.accessToken),
    {
      enabled: !!currentUser,
    },
  );

  const [activeOrdersPage, setActiveOrdersPage] = useState(1);
  // TODO: Replace direction value with enum (ASC & DESC)
  const [activeSort, setActiveSort] = useState<SortOptions>({
    direction: 'DESC',
    field: 'purchaseDate',
  });

  const sortOrders = (field: string, direction: string) => {
    if (activeSort.field === field) {
      return activeSort.direction === 'ASC'
        ? setActiveSort({
            field: field,
            direction: 'DESC',
          })
        : setActiveSort({ field: field, direction: 'ASC' });
    }
    setActiveSort({
      field: field,
      direction: direction,
    });
  };

  const [searchedOrder, setSearchedOrder] = useState<string>('');

  const { data: orders, isFetching: isFetchingOrders } = useQuery(
    [
      'ordersKey',
      activeOrdersPage,
      currentUser,
      apiUser,
      activeSort,
      searchedOrder,
    ],
    // @ts-ignore
    () =>
      getAllOrders(
        currentUser.accessToken,
        activeOrdersPage,
        searchedOrder,
        activeSort,
      ),
    {
      enabled: !!currentUser && !!apiUser,
    },
    { keepPreviousData: true },
  );

  return (
    <div>
      <TabWrapper>
        <>
          <TabContentHeader
            title={t('Admin')}
            rootPage="/admin/stats"
            subRoute={'Statistics'}
          />
          {/* GLOBAL STATS*/}
          <TabContent>
            <>
              <div className="flex justify-between mb-6 w-full py-2 items-center">
                <h5
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Overview
                </h5>
              </div>
              <div className="flex flex-col justify-between mt">
                {/*STATISTICS - ROW 1 */}
                <div className="flex flex-1 gap-1 lg:gap-4 space-between justify-center lg:px-4 font-serif mb-[8px]">
                  <div className="card px-0 gap-0 basis-1/3 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className=" text-xl py-2 lg:text-3xl font-bold">
                      {isLoadingGlobalStats ? (
                        <FontAwesomeIcon size="1x" icon={faSpinnerThird} spin />
                      ) : !globalStats ? null : (
                        globalStats?.data?.totalUsers
                      )}
                    </div>
                    <div className="stat-title text-xs lg:text-sm">
                      Total Users
                    </div>
                  </div>
                  <div className="card px-0 gap-0 basis-1/3 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className=" text-xl py-2 lg:text-3xl font-bold">
                      {isLoadingGlobalStats ? (
                        <FontAwesomeIcon size="1x" icon={faSpinnerThird} spin />
                      ) : !globalStats ? null : (
                        globalStats?.data?.totalOrders
                      )}
                    </div>
                    <div className="stat-title text-xs lg:text-sm">
                      Total Orders
                    </div>
                  </div>
                </div>
                {/*STATISTICS - ROW 2*/}
                <div className="flex flex-1 gap-1 lg:gap-4 space-between justify-center lg:px-4 font-serif mb-[8px]">
                  <div className="card px-0 gap-0 basis-1/3 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className=" text-xl py-2 lg:text-3xl font-bold">
                      {isLoadingGlobalStats ? (
                        <FontAwesomeIcon size="1x" icon={faSpinnerThird} spin />
                      ) : !globalStats ? null : (
                        globalStats?.data?.totalActiveHomes
                      )}
                    </div>
                    <div className="stat-title text-xs lg:text-sm">
                      Active Listings
                    </div>
                  </div>
                  <div className="card gap-0 px-0 basis-1/3 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className=" text-xl py-2 lg:text-3xl font-bold">
                      {isLoadingGlobalStats ? (
                        <FontAwesomeIcon size="1x" icon={faSpinnerThird} spin />
                      ) : !globalStats ? null : (
                        displayPrice(+globalStats?.data?.totalSales, '€', {
                          thousandSeparator: ' ',
                        })
                      )}
                    </div>
                    <div className="text-xs lg:text-sm">Users Sales</div>
                  </div>
                </div>
                {/*STATISTICS - ROW 3*/}
              </div>
              {/* ORDERS STATS*/}
              <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
                <h5
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Orders Stats
                </h5>
              </div>
              <div className="lg:px-4 lg:flex flex-col-inverse lg:flex-row items-end mt-4">
                <div className="py-4 2xl:w-1/2 flex items-end xl:flex-1">
                  <Filters
                    filtersList={dateFilters}
                    active={rangeDate}
                    setActive={setRangeDate}
                  />
                </div>
              </div>
              <div>
                {!orderStats?.orders?.length ? null : (
                  <div className="w-full h-96 relative mt-10">
                    {!isFetchingOrderStats ? null : (
                      <FontAwesomeIcon
                        style={{ marginRight: 15 }}
                        icon={faSpinnerThird}
                        size={'5x'}
                        spin
                      />
                    )}
                    <GraphComponent
                      orders={orderStats?.orders}
                      unitType={rangeDate.split('_')[1]}
                      currency={currency}
                    />
                  </div>
                )}
              </div>
              {/* ORDERS LIST*/}
              <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
                <h5
                  className="lg:text-[36px]"
                  style={{
                    textAlign: 'left',
                  }}
                >
                  Orders
                </h5>
                <div className="w-1/3">
                  <SearchBar
                    searchText={searchedOrder || ''}
                    setSearchText={setSearchedOrder}
                  />
                </div>
              </div>
              <div className="flex">
                <Pagination
                  totalPagesCount={orders?.metadata?.totalPages}
                  currentPage={orders?.metadata?.currentPage}
                  totalItems={orders?.metadata?.totalCount}
                  onChangePage={setActiveOrdersPage}
                />
              </div>
              <table className="cozyup table py-4">
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('order_id', 'ASC')}
                    >
                      {'#'} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('user.email', 'ASC')}
                    >
                      {t('orders.concerges')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('home.name', 'ASC')}
                    >
                      {t('menu.listings')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('purchaseDate', 'ASC')}
                    >
                      {t('orders.purchase_date')}{' '}
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('deliveryDate', 'ASC')}
                    >
                      {t('orders.delivery_date')}{' '}
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('customer', 'ASC')}
                    >
                      {t('orders.customer')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('totalAmount', 'ASC')}
                    >
                      {t('orders.total_order')}{' '}
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('paymentStatus', 'ASC')}
                    >
                      {t('orders.status')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    {/*<th className="table-head">*/}
                    {/*  Fullfilement <FontAwesomeIcon icon={faSort} />*/}
                    {/*</th>*/}
                  </tr>
                </thead>
                {isFetchingOrders ? null : (
                  <tbody>
                    {!orders
                      ? null
                      : orders?.items?.map((order: any, index: number) => (
                          <OrderRawAdmin order={order} key={index} />
                        ))}
                  </tbody>
                )}
              </table>
              {!isFetchingOrders ? null : (
                <div className="flex justify-center items-center height-[150px]">
                  <Loader />
                </div>
              )}
              <WorkspaceOnboarding
                items={onboardingStats}
                setPage={setActiveOnboardingPage}
                isLoading={isLoadingOnboardingStats}
              />
              <ReferralsList
                items={referralsStats}
                setPage={setActiveReferralsPage}
                isLoading={isLoadingReferralsStats}
              />
            </>
          </TabContent>
        </>
      </TabWrapper>
    </div>
  );
};

export default Statistics;
