import React, { useCallback, useEffect, useState } from 'react';
import SubmitButton from '../../../submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../input/input.component';
import _ from 'lodash';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import TextArea from '../../../text-area/text-area.component';
import OnClickOut from '../../../../hooks/useOnClickOut';
import { Emoji, Picker } from 'emoji-mart';
import colors from '../../../../styles/_colors.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../../contexts/authContext';
import { getProductById, updateProduct } from '../../../../api/products';
import { NewProduct, Product } from '../../../../types/product.model';
import Validation from '../../../../utils/validation';
import Select from '../../../select/select.component';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { remove, upload } from '../../../../api/files';
import { resizeImage } from '../../../../utils/image';
import PriceInput from '../../../price-input/PriceInput.component';
import { preparationTimeInHours } from '../../../../utils/date';
import Button from '../../../button/button.component';

import { FlagIcons } from '../../../flag-icons/flag-icons.component';
import { useTranslation } from 'react-i18next';
import useSocket from '../../../../hooks/useSocket';
import { jobActions } from '../../../../redux/jobs/jobActions';
import { PRODUCT_TRANSLATION_ACTION } from '../../../../constantes/jobs';
import { JobStatus } from '../../../../redux/jobs/jobSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useStore';

interface IProductTranslation {
  title: string;
  description: string;
  languageCode: string;
  _id: string;
}

const ProductDetails = ({ setSubRoute, setRefresh, currency }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state) => state.jobs);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPictures, setCurrentPictures]: any[] = useState<any[]>([]);
  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false);
  const backendErrors = '';
  let { id } = useParams();
  const { i18n } = useTranslation();
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language.slice(0, 2),
  );
  const { sendEvent } = useSocket();

  const {
    refetch,
    isLoading: isLoadingProduct,
    isFetching,
    data: currentProduct,
  } = useQuery(
    ['currentProductKey', id],
    // @ts-ignore
    () => getProductById(currentUser.accessToken, currentWorkspace._id, id),
    {
      enabled: true,
      staleTime: Infinity,
      cacheTime: Infinity,

      onSuccess: (data: Product) => {
        setCurrentPictures([...data.pictures]);
        const nameSubRoute = data.translations.filter(
          (t: any) => t.languageCode === i18n.language.slice(0, 2),
        ).length
          ? data.translations.filter(
              (t: any) => t.languageCode === i18n.language.slice(0, 2),
            )[0].title
          : data.name;
        setSubRoute(nameSubRoute);
        setEmoji(data.emoji);
        reset();
      },
    },
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm(currentProduct);

  const isGeneratingTranslations =
    jobs.jobs[`${PRODUCT_TRANSLATION_ACTION}:${currentProduct?._id}`] ===
    JobStatus.IN_PROGRESS;

  useEffect(() => {
    refetch();
  }, [id]);

  useCallback(() => {
    if (
      currentProduct?.translations &&
      currentProduct?.translations.length === 1
    ) {
      setCurrentLanguage(currentProduct?.translations[0].languageCode);
    }
  }, [currentProduct]);

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  const { mutateAsync: removePicture } = useMutation((filename: string) =>
    remove(currentUser.accessToken, filename),
  );

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
  };

  const sendGenerateTranslationsEvent = () => {
    sendEvent('product:translation:start', {
      productIds: [id],
      lang: i18n.language.slice(0, 2),
    });
    dispatch(
      jobActions.addJob({
        type: PRODUCT_TRANSLATION_ACTION,
        id: `${PRODUCT_TRANSLATION_ACTION}:${id}`,
        status: JobStatus.IN_PROGRESS,
        callback: () => refetch(),
      }),
    );
  };

  const uploadPictures = async (pictures: any[]) => {
    return await Promise.all(
      pictures.map(async (picture) => {
        const resizedFile = await resizeImage(picture.file);
        if (resizedFile) {
          return await uploadNewPicture(resizedFile);
        }
      }),
    );
  };
  //@ts-ignore
  // eslint-disable-next-line no-unused-vars
  const checkIfProductPicturesUpdated = async (data: any) => {
    const newPictures =
      currentPictures.length &&
      currentPictures.filter((p: any) => p.new === true);
    if (newPictures && newPictures.length) {
      const basePictures = currentPictures.filter((p: any) => !('new' in p));
      const picturesUploaded = await uploadPictures(newPictures);
      const picturesToAdd = picturesUploaded.map((p) => ({
        url: p.url,
        order: 1,
      }));
      const joinedPictures = [...basePictures, ...picturesToAdd];
      updateCurrentProduct({
        ...data,
        emoji,
        isAvailable: data.isAvailable === 'true' || data.isAvailable === true,
        validationRequired:
          data.validationRequired === 'true' ||
          data.validationRequired === true,
        pictures: joinedPictures,
        hasToRegenerateTranslations: data.hasToRegenerateTranslations ?? false,
        currentLanguage: currentLanguage ?? null,
      });
      setDisabled(true);
      setIsLoading(false);
    } else {
      updateCurrentProduct({
        ...data,
        emoji,
        isAvailable: data.isAvailable === 'true' || data.isAvailable === true,
        validationRequired:
          data.validationRequired === 'true' ||
          data.validationRequired === true,
        pictures: currentPictures,
        hasToRegenerateTranslations: data.hasToRegenerateTranslations ?? false,
        currentLanguage: currentLanguage ?? null,
      });
      setDisabled(true);
      setIsLoading(false);
    }
  };

  const [emoji, setEmoji] = useState<string>();

  const { mutate: updateCurrentProduct, isLoading: isLoadingUpdate } =
    useMutation(
      (data: Product) =>
        id &&
        updateProduct(currentUser.accessToken, currentWorkspace._id, id, data),
      {
        onSuccess: () => {
          setCurrentPictures([]);
          setRefresh(true);
          refetch();
        },
      },
    );

  const submit = (data: any) => {
    setIsLoading(true);
    if (data.translations) {
      data.translations = Object.entries(data.translations).map(
        ([key, value]: [string, any]) => ({
          languageCode: key,
          title: value.name,
          description: value.description,
          _id: value._id,
        }),
      );
    }
    data.costPrice = Math.round(+data.costPrice * 100);
    data.sellingPrice = Math.round(+data.sellingPrice * 100);

    checkIfProductPicturesUpdated(data);
  };

  const handleAddedPicture = (file: any) => {
    if (!file) {
      return;
    }
    if (!currentPictures.length) {
      setCurrentPictures([
        {
          new: true,
          url: URL.createObjectURL(file),
          file: file,
        },
      ]);
    } else {
      setCurrentPictures((prevPictures: any[]) => [
        ...prevPictures,
        {
          new: true,
          url: URL.createObjectURL(file),
          file: file,
        },
      ]);
    }
  };

  const handleOnRemovePicture = async (indexToDelete: number) => {
    if (!('new' in currentPictures[indexToDelete])) {
      const filename = new URL(currentPictures[indexToDelete].url).pathname
        .split('/')
        .pop();
      await removePicture(filename);
      const copyPictures = currentPictures.filter(
        (item: any, index: number) => index !== indexToDelete,
      );
      setCurrentPictures(copyPictures);
      await updateCurrentProduct({
        pictures: copyPictures,
      });
      refetch();
    } else {
      const copyPictures = currentPictures.filter(
        (item: any, index: number) => index !== indexToDelete,
      );
      setCurrentPictures(copyPictures);
    }
  };

  const handleCancelEdit = () => {
    setCurrentPictures(currentProduct.pictures);
    reset();
    setDisabled(true);
  };

  const pickEmoji = (emoji: any) => {
    setEmoji(emoji.id);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const handleDuplicateProduct = () => {
    const newProduct: NewProduct = {
      emoji: currentProduct.emoji,
      name: `${currentProduct.name} (Copy)`,
      description: currentProduct.description,
      instruction: currentProduct.instruction || '',
      costPrice: currentProduct.costPrice,
      sellingPrice: currentProduct.sellingPrice,
      isAvailable: currentProduct.isAvailable,
      pictures: currentProduct.pictures,
      preparationTimeInHours: currentProduct.preparationTimeInHours,
    };
    navigate('/products/new', { state: { product: newProduct } });
  };

  const isAvailableOptions = [
    { label: t('products.status.available'), key: true },
    { label: t('products.status.unavailable'), key: false },
  ];

  const requireValidationOptions = [
    { label: t('yes'), key: true },
    { label: t('no'), key: false },
  ];

  return isLoadingProduct || isFetching ? null : (
    <div className="card mt-4">
      <div className="flex justify-between items-center mb-6">
        <h5 style={{ textAlign: 'left', margin: 0 }}>
          {t('products.informations')}:
        </h5>
        <div className="flex items-center">
          {currentProduct?.translations.length ? null : (
            <Button
              buttonWrapperClass="ai-button-wrapper mr-1"
              className={`ai-button ${
                isGeneratingTranslations ? '' : 'pointer'
              }`}
              disabled={isGeneratingTranslations}
              onClick={sendGenerateTranslationsEvent}
            >
              <span>{t('translate')}</span>
              {!isGeneratingTranslations ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </Button>
          )}
          <div
            className="btn btn-primary-ghost rounded-xl"
            onClick={() => handleDuplicateProduct()}
          >
            {t('duplicate')}
          </div>
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisabled(false)}
          >
            {t('edit')}
          </div>
        </div>
      </div>
      <div className="mt-6 w-full lg:flex">
        <div className="relative">
          <label className="text-left text-[#6B7194]">Emoji</label>
          <div
            className={`mt-2 p-10 border border-gray-300 rounded-xl w-fit ${
              disabled ? 'opacity-50 pointer-events-none' : ''
            }`}
            onClick={() => setDisplayEmojiPicker(!displayEmojiPicker)}
          >
            <Emoji emoji={emoji || 'grey_question'} size={60} />
          </div>
          <OnClickOut callback={() => setDisplayEmojiPicker(false)}>
            {!displayEmojiPicker || disabled ? null : (
              <div className={'top-0 left-0 lg:absolute lg:left-40'}>
                <Picker
                  showPreview={false}
                  showSkinTones={false}
                  set="apple"
                  color={colors.primary}
                  onClick={pickEmoji}
                />
              </div>
            )}
          </OnClickOut>
        </div>

        <div className="mt-6 lg:mt-0 lg:px-14 w-full">
          <div>
            {currentProduct?.translations.length > 1 ? (
              <div className="flex flex-wrap justify-start gap-6">
                {currentProduct.translations
                  .sort((a: any, b: any) => {
                    return b.dateCreated - a.dateCreated;
                  })
                  .map((lang: any) => (
                    <button
                      className={`rounded-full bg-gray-200 hover:opacity-50 px-3 py-1 cursor-pointer ${
                        lang.languageCode === currentLanguage
                          ? 'bg-lightPrimary hover:opacity-100 opacity-100'
                          : 'opacity-25'
                      }`}
                      onClick={() => changeLanguage(lang.languageCode)}
                      key={lang.id}
                    >
                      <FlagIcons country={lang.languageCode} />
                    </button>
                  ))}
              </div>
            ) : null}
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="mb-6">
              <div>
                {currentLanguage && currentProduct?.translations.length ? (
                  <div>
                    {currentProduct?.translations.map(
                      (productTranslation: IProductTranslation) => (
                        <div
                          className={`${
                            productTranslation.languageCode === currentLanguage
                              ? ''
                              : 'hidden'
                          }`}
                          key={productTranslation._id}
                        >
                          <Controller
                            control={control}
                            rules={Validation.anyRequired}
                            defaultValue={productTranslation?._id}
                            render={({ field: { value, onChange } }: any) => (
                              <Input
                                type="hidden"
                                key="_id"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                            name={
                              'translations.' +
                              productTranslation.languageCode.toString() +
                              '._id'
                            }
                          />
                          <Controller
                            control={control}
                            rules={Validation.anyRequired}
                            defaultValue={productTranslation?.title}
                            render={({ field: { onChange, value } }: any) => (
                              <Input
                                disabled={disabled}
                                key="name"
                                label={t('products.name')}
                                size="small"
                                onChange={onChange}
                                value={value}
                                errors={[
                                  _.get(errors, 'name.message'),
                                  ...backendErrorsForAttribute('name'),
                                ]}
                              />
                            )}
                            name={
                              'translations.' +
                              productTranslation.languageCode.toString() +
                              '.name'
                            }
                          />
                          <Controller
                            control={control}
                            rules={Validation.anyRequired}
                            defaultValue={productTranslation?.description}
                            render={({ field: { onChange, value } }: any) => (
                              <TextArea
                                disabled={disabled}
                                key="description"
                                label="Description"
                                onChange={onChange}
                                value={value}
                                errors={[
                                  _.get(errors, 'description.message'),
                                  ...backendErrorsForAttribute('description'),
                                ]}
                              />
                            )}
                            name={
                              'translations.' +
                              productTranslation.languageCode.toString() +
                              '.description'
                            }
                          />
                        </div>
                      ),
                    )}
                    {disabled ? null : (
                      <div className="mb-6">
                        <Controller
                          control={control}
                          defaultValue={false}
                          render={({ field: { value, ref, onChange } }) => (
                            <label
                              htmlFor="hasToRegenerateTranslations"
                              className="cursor-pointer flex gap-3"
                            >
                              <input
                                type="checkbox"
                                ref={ref}
                                id="hasToRegenerateTranslations"
                                value={value}
                                onChange={onChange}
                              />
                              {t('update_other_translations')}
                            </label>
                          )}
                          name={'hasToRegenerateTranslations'}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={currentProduct?.name}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          disabled={disabled}
                          key="name"
                          label={t('products.name')}
                          size="small"
                          onChange={onChange}
                          value={value}
                          errors={[
                            _.get(errors, 'name.message'),
                            ...backendErrorsForAttribute('name'),
                          ]}
                        />
                      )}
                      name={'name'}
                    />
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={currentProduct?.description}
                      render={({ field: { onChange, value } }: any) => (
                        <TextArea
                          disabled={disabled}
                          key="description"
                          label="Description"
                          onChange={onChange}
                          value={value}
                          errors={[
                            _.get(errors, 'description.message'),
                            ...backendErrorsForAttribute('description'),
                          ]}
                        />
                      )}
                      name={'description'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <Controller
                control={control}
                defaultValue={currentProduct?.instruction || ''}
                render={({ field: { onChange, value } }: any) => (
                  <TextArea
                    disabled={disabled}
                    key="instruction"
                    label={t('products.instructions')}
                    onChange={onChange}
                    value={value}
                    tooltip={`${t('products.instructions_tooltip')}`}
                    errors={[
                      _.get(errors, 'instruction.message'),
                      ...backendErrorsForAttribute('instruction'),
                    ]}
                  />
                )}
                name={'instruction'}
              />
            </div>
            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={{ ...Validation.anyRequired }}
                  defaultValue={(currentProduct?.costPrice / 100).toFixed(2)}
                  render={({ field: { onChange, value } }: any) => (
                    <PriceInput
                      disabled={disabled}
                      key="costPrice"
                      label={`${t('products.cost_price')} (${currency.symbol})`}
                      size="small"
                      onChange={onChange}
                      value={value}
                      errors={[
                        _.get(errors, 'costPrice.message'),
                        ...backendErrorsForAttribute('costPrice'),
                      ]}
                    />
                  )}
                  name={'costPrice'}
                />
              </div>
              <div className="lg:pl-4 flex-1">
                <Controller
                  control={control}
                  rules={{
                    ...Validation.anyRequired,
                    ...Validation.price,
                    ...Validation.greaterThanZero,
                  }}
                  defaultValue={(currentProduct?.sellingPrice / 100).toFixed(2)}
                  render={({ field: { onChange, value } }: any) => (
                    <PriceInput
                      disabled={disabled}
                      key="sellingPrice"
                      label={`${t('products.selling_price')} (${
                        currency.symbol
                      })`}
                      size="small"
                      helperText={`${t('products.gain')} ${
                        value &&
                        (
                          value -
                          (value * currentAccount.percentageFeeHost) / 100
                        ).toFixed(2)
                      }€`}
                      onChange={onChange}
                      value={value}
                      errors={[
                        _.get(errors, 'sellingPrice.message'),
                        ...backendErrorsForAttribute('sellingPrice'),
                      ]}
                    />
                  )}
                  name={'sellingPrice'}
                />
              </div>
            </div>
            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={currentProduct?.isAvailable}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      disabled={disabled}
                      key="isAvailable"
                      label={t('products.status.status')}
                      onChange={onChange}
                      value={value}
                      options={isAvailableOptions}
                      errors={[
                        _.get(errors, 'isAvailable.message'),
                        ...backendErrorsForAttribute('isAvailable'),
                      ]}
                    />
                  )}
                  name={'isAvailable'}
                />
              </div>
              <div className="lg:pl-4 flex-1">
                <Controller
                  control={control}
                  rules={{ ...Validation.anyRequired, ...Validation.integer }}
                  defaultValue={currentProduct?.preparationTimeInHours}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      disabled={disabled}
                      key="preparationTimeInHours"
                      label={`${t('products.preparation_time')}`}
                      onChange={onChange}
                      value={value}
                      options={preparationTimeInHours}
                      tooltip={`${t('products.preparation_time_dsc')}`}
                      errors={[
                        _.get(errors, 'preparationTimeInHours.message'),
                        ...backendErrorsForAttribute('preparationTimeInHours'),
                      ]}
                    />
                  )}
                  name={'preparationTimeInHours'}
                />
              </div>
            </div>
            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={currentProduct?.validationRequired.toString()}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      disabled={disabled}
                      key="validationRequired"
                      label={t('products.require_validation')}
                      onChange={onChange}
                      value={value}
                      tooltip={`${t('orders.require_validation_tooltip')}`}
                      options={requireValidationOptions}
                      errors={[
                        _.get(errors, 'validationRequired.message'),
                        ...backendErrorsForAttribute('validationRequired'),
                      ]}
                    />
                  )}
                  name={'validationRequired'}
                />
              </div>
              <div className="flex-1"></div>
            </div>

            {/*  PRODUCT PICTURES */}
            <div className="flex flex-col justify-start mt-5">
              <label className="text-left text-[#6B7194] mb-3">
                {t('products.pictures')} ({currentPictures.length}/5)
              </label>
              <div className="flex flex-col lg:flex-row w-full gap-2 flex-wrap">
                {!currentPictures.length
                  ? null
                  : currentPictures.map((picture: any, index: number) => {
                      return (
                        !!picture && (
                          <div key={index} className="relative">
                            <img
                              className="rounded-3xl flex-1 h-48 w-full lg:w-48 object-cover"
                              src={picture.url}
                            />
                            {disabled ? null : (
                              <a
                                className="absolute top-3 right-3 cursor-pointer border border-primary h-8 w-8 flex items-center justify-center bg-gray-50 rounded-full"
                                onClick={() => handleOnRemovePicture(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faMinus}
                                  color="#7282E5"
                                  size="lg"
                                />
                              </a>
                            )}
                          </div>
                        )
                      );
                    })}

                <div>
                  {currentPictures.length >= 5 ? null : (
                    <label
                      htmlFor="picture"
                      className={
                        'group flex flex-col justify-center items-center shadow rounded-3xl font-satoshi h-48 w-full lg:w-48  mb-[12px]  border-[2px] border-transparent ' +
                        (!disabled
                          ? 'hover:border-primary cursor-pointer '
                          : '')
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="3x"
                        color="#d3d3d3"
                        className={!disabled ? 'group-hover:text-primary' : ''}
                      />
                      <p
                        className={
                          'mt-3 text-middleGrey font-medium  text-[16px] ' +
                          (!disabled ? 'group-hover:text-primary' : '')
                        }
                      >
                        {t('products.add_picture')}
                      </p>
                    </label>
                  )}
                  <Controller
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        style={{ display: 'none' }}
                        disabled={disabled}
                        key="pictures"
                        label=""
                        type="file"
                        accept="image/*"
                        id="picture"
                        onChange={(e: any) => {
                          field.onChange(e.target.files);
                          handleAddedPicture(e.target.files[0]);
                        }}
                        errors={[
                          _.get(errors, 'pictures.message'),
                          ...backendErrorsForAttribute('pictures'),
                        ]}
                      />
                    )}
                    name={'pictures'}
                  />
                </div>
              </div>
            </div>
            {disabled ? null : (
              <div className="flex justify-end my-4">
                <button
                  title={'Cancel'}
                  className="btn rounded-3xl btn-primary-outlined"
                  onClick={() => handleCancelEdit()}
                >
                  {t('cancel')}
                </button>
                <SubmitButton
                  title={t('confirm')}
                  customClass={'btn rounded-3xl btn-primary flex'}
                  disabled={
                    isLoading || isLoadingPicture || isLoadingUpdate
                      ? true
                      : false
                  }
                >
                  {isLoading || isLoadingPicture || isLoadingUpdate ? (
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      icon={faSpinnerThird}
                      spin
                    />
                  ) : null}
                </SubmitButton>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
