import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Workspace } from '../../../../types/workspace.model';
import { getCurrencyInfo } from '../../../../utils/currency';

const WorkspaceList = ({ workspaces, setSubRoute }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setSubRoute(t('header.workspaces'));
  }, []);

  return (
    <div>
      <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
        <h5 style={{ textAlign: 'left', margin: 0 }}>
          {t('settings.workspace.workspace_other')}
        </h5>
        <div className="flex">
          <div className="flex h-full items-end justify-end">
            <div
              onClick={() => navigate('new')}
              className="btn btn-warning rounded-xl"
            >
              <div>{t('add')}</div>
            </div>
          </div>
        </div>
      </div>
      <table className="cozyup table mt-2">
        <thead>
          <tr>
            <th>#</th>
            <th>
              {t('settings.workspace.name')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {`#${t('settings.active_properties')}`}
              <FontAwesomeIcon icon={faSort} className="ml-1" />
            </th>
            <th>
              {'#'}
              {t('settings.users')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('settings.workspace.currency')}{' '}
              <FontAwesomeIcon icon={faSort} />
            </th>{' '}
            <th>
              {t('settings.stripe.account')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="mt-6">
          {workspaces &&
            workspaces.map((workspace: Workspace, i: number) => (
              <tr
                key={i}
                className="cursor-pointer"
                onClick={() => navigate(`${workspace._id}`)}
              >
                <td>{i + 1}</td>
                <td>{workspace.title}</td>
                <td>
                  {workspace.homes.filter((h) => h?.status === 'active').length}
                </td>
                <td>{workspace.workspaceAccesses.length}</td>
                <td>{getCurrencyInfo(workspace.currency).label}</td>
                <td>
                  {workspace?.stripeConnectAccounts?.length
                    ? workspace?.stripeConnectAccounts?.length
                    : '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkspaceList;
