import React, { useEffect, useState } from 'react';
// import TabContent from '../../components/tab-content/tab-content.component';
import InitHomes from '../../components/_main/onboarding/init-homes.component';
import InitCheckoutArea from '../../components/_main/onboarding/init-checkout-area.component';
import InitBankAccount from '../../components/_main/onboarding/init-bank-account.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCheck,
  faCircleCheck,
  faExclamationTriangle,
  faGlobePointer,
  faHeadset,
  faHouse,
  faMessageArrowUpRight,
  faPlug,
  faStore,
} from '@fortawesome/pro-regular-svg-icons';

import Button from '../../components/button/button.component';
import { useMutation, useQuery } from 'react-query';
import { updateOnboardingStatus } from '../../api/onboarding';
import { useAuth } from '../../contexts/authContext';
import { OnboardingStatusUpdate } from '../../types/onboarding.model';
import Lottie from 'react-lottie';
import confetti from '../../assets/lottie/confetti.json';
import { useTranslation } from 'react-i18next';
import InitConnection from '../../components/_main/onboarding/init-connection/init-connection.component';
import {
  getCalryIntegrations,
  getChannelManagerIntegrations,
} from '../../api/channelManagers';
import InitMessages from '../../components/_main/onboarding/init-messages.component';
import { getAirbnbByHospitableChannels } from '../../api/platforms';
import Loader from '../../components/loader/loader.component';
import Language from '../../components/language/language.component';
import { faStripe } from '@fortawesome/free-brands-svg-icons/faStripe';
import BookACall from '../book-a-call/BookACall';
import useTrackOnboardingWithMixpanel from '../../hooks/useTrackOnboardingWithMixpanel';
import { sendEmail } from '../../api/sendinblue';
import { Colors } from '../../assets/Colors';

const Onboarding = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStep = searchParams.get('step');
  const [displayAnimation, setDisplayAnimation] = useState<boolean>(false);
  const [displayBookACall, setDisplayBookACall] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState(false);
  const [allowResend, setAllowResend] = useState(false);
  const [countdown, setCountdown] = useState(30); // 30 seconds countdown
  const navigate = useNavigate();

  const { currentUser, currentWorkspace, currentAccount, apiUser } = useAuth();
  const [activeTab, setActiveTab] = React.useState<number>(
    (currentStep && parseInt(currentStep)) || 0,
  );

  if (apiUser?.data?.onboardingStatus?.completed) {
    navigate('/dashboard');
  }

  const { t } = useTranslation();

  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();
  const tabs: Tab[] = [
    {
      key: 'CM_PMS_CONNECTION',
      title: `1. ${t('onboarding.connections.step_title')}`,
      dsc: t('onboarding.connections.step_dsc'),
      tabIndex: 1,
      icon: faPlug,
    },
    {
      key: 'IMPORT_PROPERTIES',
      title: `2. ${t('onboarding.homes.homes')}`,
      dsc: t('onboarding.homes.setup'),
      tabIndex: 2,
      icon: faHouse,
    },
    {
      key: 'CREATE_STORE',
      title: `3. ${t('onboarding.checkoutAreas.checkout_page')}`,
      dsc: t('onboarding.checkoutAreas.setup'),
      tabIndex: 3,
      icon: faStore,
    },
    {
      key: 'AUTO_MESSAGES',
      title: `4. ${t('onboarding.automatic_messages.step_title')}`,
      dsc: t('onboarding.automatic_messages.step_dsc'),
      tabIndex: 4,
      icon: faMessageArrowUpRight,
    },
    {
      key: 'CONNECT_STRIPE',
      title: `5. ${t('onboarding.stripeConnect.bankaccount')}`,
      dsc: t('onboarding.stripeConnect.setup'),
      tabIndex: 5,
      icon: faStripe,
    },
  ];

  const { mutate: updateOnboarding } = useMutation(
    // @ts-ignore
    (data: OnboardingStatusUpdate) =>
      updateOnboardingStatus(
        currentUser.accessToken,
        currentWorkspace._id,
        data,
      ),
    {},
  );

  const {
    data: channelManagerIntegrations,
    refetch: refetchChannelManagerIntegrations,
    isLoading: isLoadingChannelManagerIntegrations,
  } = useQuery(
    ['channelManagerIntegrations', currentWorkspace],
    // @ts-ignore
    () =>
      getChannelManagerIntegrations(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentWorkspace && !!currentUser,
    },
  );

  const { data: airbnbChannels, refetch: refetchAirbnbChannels } = useQuery(
    ['airbnbChannels', currentAccount, currentUser],
    // @ts-ignore
    () =>
      getAirbnbByHospitableChannels(
        currentUser.accessToken,
        currentAccount._id,
      ),
    {
      enabled: !!currentAccount && !!currentUser,
    },
  );

  const {
    data: calryIntegrations,
    refetch: refetchCalryIntegrations,
    isLoading: isLoadingCalryIntegrations,
  } = useQuery(
    ['calryIntegrations', currentWorkspace?._id],
    // @ts-ignore
    () => getCalryIntegrations(currentUser?.accessToken, currentWorkspace?._id),
    {
      enabled: !!currentWorkspace && !!currentUser,
    },
  );

  const refreshIntegrations = () => {
    refetchCalryIntegrations();
    refetchAirbnbChannels();
    refetchChannelManagerIntegrations();
  };

  useEffect(() => {
    setSearchParams((params) => {
      params.set('step', activeTab.toString());
      return params;
    });
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 6 || activeTab === 0) {
      setDisplayAnimation(true);
    }
  }, [activeTab]);

  const { mutate: sendOnboardingEmail, isLoading: isSendingOnboardingEmail } =
    useMutation(
      (data: { to: []; templateId: number; params: {} }) => sendEmail(data),
      {
        onSuccess: () => {
          setEmailSent(true);
          setCountdown(30); // Reset countdown to 30 seconds
          const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(intervalId);
                setEmailSent(false);
                setAllowResend(true);
                return 0;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        },
      },
    );

  const handleSendEmail = (data: unknown) => {
    sendOnboardingEmail(data);
    setAllowResend(false); // Reset the resend option
  };

  return (
    <>
      {displayBookACall && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
          <div className="fixed top-0 left-0 z-50 w-full h-full overflow-x-hidden flex items-start">
            <div className="relative w-[95%] flex flex-col justify-between min-h-[350px] lg:min-h-[150px] max-h-[90%] overflow-auto bg-white mt-5 mx-auto rounded-[16px] pt-10">
              <button
                type="button"
                className="cursor-pointer absolute top-2 right-6"
                onClick={() => setDisplayBookACall(false)}
              >
                <span className="text-3xl text-[#000] hover:opacity-50">
                  &times;
                </span>
              </button>
              <BookACall />
            </div>
          </div>
        </div>
      )}
      <div className="md:hidden bg-lightPrimary flex flex-col justify-center items-center w-full min-h-screen p-12 text-primary text-lg">
        <div className="rounded-2xl shadow-lg p-4 bg-white">
          <span className="flex items-center justify-center h-full align-center font-black  text-nightBlue text-4xl font-satoshiBold mb-3">
            CozyUp
          </span>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            color="#7e82e5"
          />
          <h2 className="text-black mt-4 mb-2">
            {t('onboarding.desktop_only')}
          </h2>
          <p className="text-sm text-grey">
            {t('onboarding.desktop_only_desc')}
          </p>
          <Button
            className="btn btn-primary mt-8"
            disabled={isSendingOnboardingEmail || (emailSent && !allowResend)}
            onClick={() =>
              handleSendEmail({
                to: [{ email: currentUser.email }],
                templateId: 207,
                params: { defaultParam: 'defaultValue' },
              })
            }
          >
            <div className="flex flex-col text-md">
              {t('onboarding.continue_desktop')}
              {emailSent && (
                <div className="text-xs">
                  {t('email_countdown', { countdown: countdown })}
                </div>
              )}
            </div>
          </Button>
          {emailSent && (
            <p className="text-xs">
              {t('email_sent_check_inbox')}
              <FontAwesomeIcon
                icon={faCheck}
                className="ml-2"
                color={Colors.green}
              />
            </p>
          )}
        </div>
      </div>
      <div className="relative hidden md:flex flex-col items-center w-full bg-white mx-auto">
        {!displayAnimation ? null : (
          <div className="absolute h-full w-full z-100">
            <LottieConfetti
              displayAnimation={true}
              setDisplayAnimation={setDisplayAnimation}
            />
          </div>
        )}

        <div className="pt-3 px-6 min-w-[100vw] min-h-screen z-30">
          {activeTab !== 0 && activeTab !== 6 && (
            <div className=" flex flex-col lg:flex-row justify-between items-center mb-3 lg:mb-0 pb-">
              <div className="flex justify-between items-center w-full">
                <div className="flex justify-center items-center mr-2 text-xl">
                  {t('onboarding.welcome_to')} &nbsp;
                  <span className="h-full align-center font-black  text-nightBlue text-4xl font-satoshiBold">
                    CozyUp
                  </span>
                </div>
                <div className="flex items-center justify-center">
                  <div
                    className="cursor-pointer flex items-center justify-center rounded-full border-primary border-2 h-10 p-3 mr-4 hover:opacity-80"
                    onClick={() =>
                      trackOnboardingEvent(
                        `Onboarding - Help center clicked from Step ${activeTab}. ${tabs[activeTab].key}`,
                      )
                    }
                  >
                    <a
                      href="https://help.cozyup.com/"
                      target="_blank"
                      className="text-primary font-satoshi mr-2"
                      rel="noreferrer"
                    >
                      {t('menu.help-center')}
                    </a>
                    <FontAwesomeIcon icon={faGlobePointer} color="#190f34" />
                  </div>
                  <div className="cursor-pointer flex items-center justify-center rounded-full border-primary border-2 h-10 p-3 mr-4 hover:opacity-80">
                    <div
                      className="text-primary font-satoshi mr-2"
                      onClick={() => {
                        trackOnboardingEvent(
                          `Onboarding - Book onboarding call clicked from Step ${activeTab}. ${tabs[activeTab].key}`,
                        );
                        setDisplayBookACall(true);
                      }}
                    >
                      {t('menu.assistance_call')}
                    </div>
                    <FontAwesomeIcon icon={faHeadset} color="#190f34" />
                  </div>
                  <Language />
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="sticky top-0 pt-4 pb-3 bg-white z-10">
              <div>
                {activeTab !== 0 && activeTab !== 6 && (
                  <StepsTabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}
              </div>
            </div>

            <div className="">
              <div className="w-full">
                {activeTab === 0 && (
                  <OnboardingIntro
                    setActiveTab={setActiveTab}
                    updateOnboarding={updateOnboarding}
                  />
                )}
                {activeTab === 1 && (
                  <>
                    {isLoadingCalryIntegrations ||
                    isLoadingChannelManagerIntegrations ? (
                      <Loader />
                    ) : (
                      <InitConnection
                        setActiveTab={setActiveTab}
                        channelManagerIntegrations={channelManagerIntegrations}
                        calryIntegrations={calryIntegrations}
                        airbnbChannels={airbnbChannels}
                        updateOnboarding={updateOnboarding}
                        refreshIntegrations={refreshIntegrations}
                        isLoading={
                          isLoadingCalryIntegrations ||
                          isLoadingChannelManagerIntegrations
                        }
                      />
                    )}
                  </>
                )}
                {activeTab === 2 && (
                  <InitHomes
                    setActiveTab={setActiveTab}
                    channelManagerIntegrations={channelManagerIntegrations}
                    calryIntegrations={calryIntegrations}
                    airbnbChannels={airbnbChannels}
                  />
                )}
                {activeTab === 3 && (
                  <InitCheckoutArea
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}
                {activeTab === 4 && (
                  <InitMessages
                    updateOnboarding={updateOnboarding}
                    setActiveTab={setActiveTab}
                    channelManagerIntegrations={channelManagerIntegrations}
                    calryIntegrations={calryIntegrations}
                    airbnbChannels={airbnbChannels}
                    isLoading={
                      isLoadingCalryIntegrations ||
                      isLoadingChannelManagerIntegrations
                    }
                  />
                )}
                {activeTab === 5 && (
                  <InitBankAccount
                    updateOnboarding={updateOnboarding}
                    setActiveTab={setActiveTab}
                  />
                )}
                {activeTab === 6 && <OnboardingSuccess />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;

const OnboardingSuccess = () => {
  const navigate = useNavigate();
  const { fetchApiUser } = useAuth();
  const { t } = useTranslation();
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();
  useEffect(() => {
    trackOnboardingEvent('Onboarding - Completed - Page view');
  }, []);

  const onGoDashboard = () => {
    fetchApiUser();
    navigate('/dashboard');
    trackOnboardingEvent('Onboarding - Completed - Go to Dashboard clicked');
  };

  return (
    <div className="w-full h-screen flex justify-center items-center fixed left-0 top-0">
      <div className="z-60 rounded-3xl flex flex-col gap-6 border bg-white border-primary max-w-2xl">
        <div className=" px-6 py-3 flex items-center justify-between border-b border-primary bg-white rounded-t-3xl">
          <h1 className="text-3xl font-bold">
            {t('onboarding.completed_title')} 👌
          </h1>
          <Button className="btn btn-primary" onClick={() => onGoDashboard()}>
            {t('next')} <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </Button>
        </div>
        <div className="px-6 pb-6 rounded-3xl flex flex-col gap-6">
          <p className="text-md text-left text-gray-600">
            {t('onboarding.completed_dsc_1')}
          </p>
          <p className="text-md text-left text-gray-600">
            {t('onboarding.completed_dsc_2')}
          </p>
        </div>
      </div>
    </div>
  );
};

type LottieConfettiProps = {
  displayAnimation: boolean;
  setDisplayAnimation: Function;
};

const LottieConfetti = ({
  displayAnimation,
  setDisplayAnimation,
}: LottieConfettiProps) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return !displayAnimation ? null : (
    <Lottie
      style={{ zIndex: 0 }}
      isClickToPauseDisabled
      options={defaultOptions}
      height={'100%'}
      width={'100%'}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => setDisplayAnimation(false),
        },
      ]}
    />
  );
};

const OnboardingIntro = ({
  setActiveTab,
  updateOnboarding,
}: {
  setActiveTab: Function;
  updateOnboarding: Function;
}) => {
  const { t } = useTranslation();
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  const handleGoToNextStep = () => {
    updateOnboarding({ step: 1 });
    setActiveTab(1);
    trackOnboardingEvent('Onboarding - Intro success');
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="z-60 p-6 px-6 rounded-3xl flex flex-col gap-6 border bg-white border-primary max-w-2xl">
        <div className="absolute top-[-100px] right-0 z-20"></div>
        <h1 className="text-3xl font-bold">{t('onboarding.welcome')}</h1>
        <p className="text-md text-gray-600 text-left">
          {t('onboarding.welcome_dsc_1')}
        </p>
        <p className="text-md text-gray-600 text-left">
          {t('onboarding.welcome_dsc_2')}
        </p>
        <div>
          <Button
            // disabled={!disabledAnimation}
            className="btn btn-primary z-40"
            onClick={() => handleGoToNextStep()}
          >
            {t('onboarding.start')}
            <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};

type Tab = {
  key: string;
  title: string;
  dsc: string;
  tabIndex: number;
  icon: any;
};

type StepsTabsProps = {
  tabs: Tab[];
  activeTab: number;
  setActiveTab: any;
};

const StepsTabs = ({ tabs, activeTab, setActiveTab }: StepsTabsProps) => {
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  return (
    <div>
      <h2 className="sr-only">Steps</h2>
      <div>
        <ol className="z-20 bg-white shadow-lg grid grid-cols-1 divide-x divide-lightGrey overflow-hidden rounded-3xl border border-grey text-sm text-gray-500 sm:grid-cols-5">
          {tabs.map((t: Tab, index: number) => (
            <li
              key={index}
              onClick={() => {
                trackOnboardingEvent(
                  `Onboarding - Header - Go back to Step ${t.tabIndex}. ${
                    t.key
                  } from Step ${activeTab - 1}. ${tabs[activeTab - 1].key}`,
                );
                activeTab > t.tabIndex && setActiveTab(t.tabIndex);
              }}
              className={`relative flex items-center justify-center gap-2 py-3 px-2 ${
                activeTab === t.tabIndex ? ' text-gray-50 bg-primary' : ''
              } ${t.tabIndex === 1 ? 'lg:rounded-l-3xl' : ''} ${
                t.tabIndex === tabs.length ? 'lg:rounded-r-3xl' : ''
              } ${activeTab > t.tabIndex ? 'cursor-pointer' : ''}`}
            >
              <div className="mr-2">
                {activeTab <= t.tabIndex ? (
                  <FontAwesomeIcon color="primary" icon={t.icon} size="2x" />
                ) : (
                  <FontAwesomeIcon
                    color="#50d05a"
                    icon={faCircleCheck}
                    size="2x"
                  />
                )}
              </div>

              <p className="leading-none text-left">
                <strong className="block font-medium text-md">{t.title}</strong>
                <small className="mt-1 ">{t.dsc}</small>
              </p>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
