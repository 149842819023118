import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { getCalryProperties } from '../../api/channelManagers';
import SubmitButton from '../submit-button/submit-button.component';

import { useTranslation } from 'react-i18next';
import DefaultHomePicture from '../..../../../assets/images/default-home-picture.jpeg';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../loader/loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-regular-svg-icons';
import {
  CheckoutArea,
  CheckoutAreaOptions,
} from '../../types/checkout-area.model';
import { getCheckoutAreasByWorkspace } from '../../api/checkoutAreas';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Input from '../input/input.component';
import TextArea from '../text-area/text-area.component';
import Select from '../select/select.component';
import { getStripeConnectAccountByWorkspaceId } from '../../api/connectAccounts';
import Validation from '../../utils/validation';
import ChannelManagerLogo from '../channel-manager-logo/channel-manager-logo.component';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSelectedProperties: Function;
  selectedProperties?: string[];
  hasToHadCheckoutArea?: boolean;
  calryIntegrationId: string;
};

const SelectCalryPropertiesModal = ({
  displayModal,
  setDisplayModal,
  onSelectedProperties,
  selectedProperties,
  hasToHadCheckoutArea = false,
  calryIntegrationId,
}: Props) => {
  const { currentWorkspace, currentUser } = useAuth();
  const [checkedProperties, setCheckedProperties] = useState<any>([]);
  const [checkoutAreasOptions, setCheckoutAreasOptions] = useState<
    CheckoutAreaOptions[]
  >([]);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const handleSelect = (e: any) => {
    e.target.checked = e.target.checked === true ? false : true;
    const propertyId = e.target.name;
    !checkedProperties.includes(propertyId)
      ? setCheckedProperties((prev: any[]) => [...prev, propertyId])
      : setCheckedProperties((prev: any[]) =>
          prev.filter((id: any) => id !== propertyId),
        );
  };

  useQuery(
    ['checkoutAreas'],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      onSuccess: (data: CheckoutArea[]) => {
        data && handleCheckoutAreasOptions(data);
      },
    },
  );

  const handleCheckoutAreasOptions = (checkoutAreas: CheckoutArea[]) => {
    const caOptions = checkoutAreas?.map((ca: CheckoutArea) => {
      return { label: ca.name, key: ca._id };
    });
    setCheckoutAreasOptions(caOptions);
  };

  const submit = async (data: any) => {
    onSelectedProperties(
      calryProperties
        .filter((property: any) => checkedProperties.includes(property.id))
        .map((property: any) => {
          return {
            ...property,
            channelManagerConnection: {
              channelManagerType: `CALRY_${property.integrationName.toUpperCase()}`,
              channelManagerId: property.id,
            },
            headline: data.headline,
            description: data.description,
            checkoutArea: data.checkoutArea,
            stripeConnectAccount: data.stripeConnectAccount,
          };
        }),
    );
  };

  useQuery(
    ['stripeConnectAccount', currentUser, currentWorkspace],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => handleBankAccountOptions(data),
    },
  );

  const handleBankAccountOptions = (bankAccounts: any) => {
    const bankAccountOptions = bankAccounts?.map((ca: any) => {
      return { label: ca.name, key: ca._id };
    });
    setBankAccountOptions(bankAccountOptions);
  };

  const { data: calryProperties, isLoading } = useQuery(
    ['calryProperties', currentWorkspace],
    // @ts-ignore
    () =>
      getCalryProperties(
        currentUser.accessToken,
        currentWorkspace._id,
        calryIntegrationId,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => {
        setCheckedProperties(
          data
            .filter(
              (property: any) =>
                !selectedProperties?.includes(property.id.toString()),
            )
            .map((property: any) => property.id),
        );
      },
    },
  );

  return !displayModal ? null : (
    <form onSubmit={handleSubmit(submit)}>
      <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
        <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
          <div className="relative mx-4 flex flex-col justify-between w-full max-w-[800px] min-h-[500px] lg:min-h-[150px] bg-white mt-16 lg:mx-auto rounded-[16px]">
            <button
              type="button"
              className="modal-close-button"
              onClick={() => setDisplayModal(false)}
            >
              <span>&times;</span>
            </button>
            <div>
              <div className="border-b px-4 py-2">
                <h5 className="mt-2 leading-10 md:leading-none text-2xl mb-4">
                  {t('onboarding.select_properties')}
                </h5>
              </div>
              <div className="md:px-10 justify-center items-center">
                <div className="flex w-full items-center justify-center gap-4">
                  <div className="w-full grid grid-cols-1 justify-items-center justify-center md:flex md:flex-row gap-3 md:gap-6">
                    <div className="flex flex-col w-full bg-lightblue rounded-xl max-h-[400px] overflow-auto p-3 my-4">
                      {!isLoading ? (
                        <>
                          {!calryProperties?.filter(
                            (property: any) =>
                              !selectedProperties?.includes(property.id),
                          ).length ? (
                            <div className="h-[400px] flex flex-col items-center justify-center ">
                              <FontAwesomeIcon
                                className="opacity-20 mb-4"
                                color="#b6b9ce"
                                icon={faHome}
                                size="7x"
                              />
                              <div>
                                <span className="text-lg text-grey ">
                                  {t('onboarding.no_listing_found')}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {calryProperties
                                ?.filter(
                                  (property: any) =>
                                    !selectedProperties?.includes(property.id),
                                )
                                .map((property: any, index: number) => (
                                  <label
                                    key={index.toString()}
                                    htmlFor={property.id.toString()}
                                    className="checkbox-label cursor-pointer p-2 border rounded-xl bg-white my-2"
                                  >
                                    <div className="flex justify-start items-center gap-3">
                                      <input
                                        className="mx-3"
                                        type="checkbox"
                                        name={property.id}
                                        id={property.id}
                                        checked={checkedProperties.includes(
                                          property.id,
                                        )}
                                        onChange={(e) => e.target.checked}
                                        onClick={handleSelect}
                                      />
                                      <div className="text-left flex items-center gap-3">
                                        <div className="h-[100px] w-[130px]">
                                          <img
                                            className="h-full w-full object-cover rounded-xl"
                                            src={
                                              property.img || DefaultHomePicture
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <h3 className="text-md font-bold">
                                            {property.name}
                                          </h3>
                                          <span className="text-sm text-grey">
                                            {property.address.street}{' '}
                                            {property.address.zipCode}{' '}
                                            {property.address.city}{' '}
                                            {property.address.country}
                                          </span>
                                          <div className="w-12">
                                            <ChannelManagerLogo
                                              type={property.integrationName}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="flex justify-center items-center h-[450px]">
                          <Loader />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {hasToHadCheckoutArea && checkedProperties.length > 0 && (
                  <div>
                    <div className="mt-6">
                      <h5
                        className="mb-6"
                        style={{ textAlign: 'left', marginTop: 8 }}
                      >
                        {t('settings.stripe.account')}
                      </h5>
                      <Controller
                        control={control}
                        rules={Validation.anyRequired}
                        defaultValue={
                          currentWorkspace.stripeConnectAccounts[0]._id || ''
                        }
                        render={({ field: { onChange, value } }: any) => (
                          <Select
                            key="stripeConnectAccount"
                            onChange={onChange}
                            value={value}
                            options={bankAccountOptions}
                            errors={[
                              _.get(errors, 'stripeConnectAccount.message'),
                            ]}
                          />
                        )}
                        name={'stripeConnectAccount'}
                      />
                    </div>
                    <h5
                      className="mb-6"
                      style={{ textAlign: 'left', marginTop: 8 }}
                    >
                      {t('listings.checkout-page')}
                    </h5>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          key="headline"
                          tooltip={t('listings.headline_tooltip')}
                          label={`${t('listings.headline')} (${t('optional')})`}
                          size="small"
                          onChange={onChange}
                          value={value}
                          errors={[_.get(errors, 'headline.message')]}
                        />
                      )}
                      name={'headline'}
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }: any) => (
                        <TextArea
                          key="description"
                          tooltip={t('listings.description_tooltip')}
                          label={`${t('listings.description')} (${t(
                            'optional',
                          )})`}
                          onChange={onChange}
                          value={value}
                          errors={[_.get(errors, 'description.message')]}
                        />
                      )}
                      name={'description'}
                    />
                    {!checkoutAreasOptions.length ? (
                      <div className="my-6">
                        <div className="text-left">
                          <span className="mt-6 text-[#6B7194]">
                            {`${t('listings.available_extras')} (${t(
                              'optional',
                            )})`}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-sm text-middleGrey">
                            {`${t('checkout-areas.no_checkout_areas')}`}
                          </span>

                          <button
                            title={'Cancel'}
                            className="btn rounded-3xl btn-primary-outlined ml-4"
                            onClick={() => navigate('/checkout-areas/new')}
                          >
                            {t('add')}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                          <Select
                            key="checkoutArea"
                            label={`${t('listings.available_extras')} (${t(
                              'optional',
                            )})`}
                            selectLabel={t('checkout-areas.select_store')}
                            tooltip={t('listings.checkout_area_tooltip')}
                            onChange={onChange}
                            value={value}
                            options={checkoutAreasOptions}
                            errors={[_.get(errors, 'checkoutArea.message')]}
                          />
                        )}
                        name={'checkoutArea'}
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="border-t px-4 py-4 gap-2 flex justify-between items-center">
                <div className="font-bold text-grey px-6">
                  {checkedProperties.length === 0 ? null : (
                    <span>
                      {t('onboarding.homes.home', {
                        count: checkedProperties.length,
                      })}
                    </span>
                  )}
                </div>
                <div className="gap-2 flex justify-end">
                  <button
                    title={t('cancel')}
                    className="btn btn-primary-outlined rounded-3xl"
                    onClick={() => setDisplayModal(false)}
                  >
                    {t('cancel')}
                  </button>
                  <SubmitButton
                    disabled={!checkedProperties.length}
                    title={t('add')}
                    customClass="btn btn-primary rounded-3xl flex"
                  ></SubmitButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SelectCalryPropertiesModal;
