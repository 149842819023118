import React from 'react';
import './text-area.styles.scss';
import ErrorMessage from '../error-message/error-message.component';
import { Icon, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

type Size = 'small' | 'big';

export interface Props {
  error?: string;
  errors?: string[];
  placeholder?: string;
  textInputStyle?: any;
  labelTextStyle?: any;
  containerStyle?: any;
  isKeyboardInput?: boolean;
  disabled?: boolean;
  onChange?: any;
  tooltip?: string;
  value?: string;
  label?: string;
  size?: Size;
  color?: string;
  activeColor?: string;
  activeLabelColor?: string;
  onPress?: any;
  onFocus?: any;
  onBlur?: any;
}

const TextArea = ({
  error,
  errors,
  placeholder,
  disabled,
  value,
  size,
  color = 'primary',
  label,
  tooltip,
  onChange,
  ...props
}: any) => {
  const inputRef = React.useRef(null);

  return (
    <div className="text-area-container justify-between">
      <div className="flex items-center mb-[2px]">
        <label className="text-area-label">{label}</label>
        {tooltip && (
          <Tooltip title={tooltip} className="ml-1" enterTouchDelay={0}>
            <Icon
              className="icon text-gray-300 cursor-pointer"
              component={HelpOutline}
            />
          </Tooltip>
        )}
      </div>
      <textarea
        className={`text-area hover:border-${color} ${size} ${
          disabled ? 'disabled' : ''
        }`}
        disabled={disabled}
        style={{}}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={inputRef}
        {...props}
      />
      {error && <ErrorMessage key={error} error={error} />}
      {errors &&
        errors.map((err: string) => {
          if (err) {
            return <ErrorMessage key={err} error={err} />;
          }
        })}
    </div>
  );
};

export default TextArea;
