import React, { useEffect } from 'react';
import Button from '../../button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faBadgeCheck,
} from '@fortawesome/pro-regular-svg-icons';
import StripeLogo from '../../../assets/images/stripe-logo.svg';
import { useAuth } from '../../../contexts/authContext';
import { useMutation, useQuery } from 'react-query';
import { getStripeConnectAccountByWorkspaceId } from '../../../api/connectAccounts';
import { getStripeConnectSettingLink } from '../../../api/stripe';
import { useTranslation } from 'react-i18next';
import useTrackOnboardingWithMixpanel from '../../../hooks/useTrackOnboardingWithMixpanel';

type Props = {
  setActiveTab: Function;
  updateOnboarding?: Function;
};

function InitBankAccount({ setActiveTab, updateOnboarding }: Props) {
  const { currentUser, currentWorkspace } = useAuth();
  const [successConnect, setSuccessConnect] = React.useState(false);
  const { t } = useTranslation();
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  useEffect(() => {
    trackOnboardingEvent('Onboarding - Step 5. CONNECT_STRIPE - Start');
  }, []);

  const { data: stripeConnectAccounts } = useQuery(
    ['stripeConnectAccountQuery', currentUser, currentWorkspace],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const { mutate: redirectStripeConnect } = useMutation(
    // @ts-ignore
    (id: string) => {
      const options = {
        refreshUrl: window.location.href,
        returnUrl: window.location.href,
      };
      return getStripeConnectSettingLink(currentUser.accessToken, id, options);
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        window.location.replace(data.url);
      },
    },
  );

  const skipStep = () => {
    updateOnboarding &&
      updateOnboarding({
        step: 6,
        completed: true,
      });
    trackOnboardingEvent('Onboarding - Skipped Step 5. CONNECT_STRIPE');
    setActiveTab(6);
  };

  const setupStripe = () => {
    trackOnboardingEvent('Onboarding - Step 5. CONNECT_STRIPE - clicked');
    redirectStripeConnect(stripeConnectAccounts[0]?.stripeConnectId);
  };

  useEffect(() => {
    if (
      stripeConnectAccounts &&
      stripeConnectAccounts[0]?.stripeConnectInfo?.charges_enabled
    ) {
      setSuccessConnect(true);
      trackOnboardingEvent('Onboarding - Step 5. CONNECT_STRIPE - Success');
    }
  }, [stripeConnectAccounts]);

  return (
    <div className="p-4 rounded-3xl mb-3">
      <h2 className="text-left text-primary text-xl font-bold leading-12">
        {t('onboarding.stripeConnect.setup')}
      </h2>
      <div className="text-left text-grey">
        {t('onboarding.stripeConnect.setup_dsc')}
      </div>
      <div className="my-16 flex justify-center">
        <div className="card lg:w-2/3">
          <div className="text-left my-4">
            <img
              src={StripeLogo}
              className="object-contain max-h-10 "
              alt="Stripe logo"
            />
          </div>
          <div className="flex flex-col gap-6">
            <p className="text-left">
              {t('onboarding.stripeConnect.stripe_dsc')}
            </p>
          </div>
          <div className="mt-6 flex lg:flex-row flex-col gap-4 justify-between items-center">
            <div className="text-left">
              <span className="bg-lightGrey text-grey px-2 py-0.5 rounded-3xl">
                {stripeConnectAccounts?.length &&
                  stripeConnectAccounts[0]?.stripeConnectId}
              </span>
            </div>
            <div>
              {successConnect ? (
                <div className="flex gap-2 items-center mb-3">
                  <FontAwesomeIcon
                    color="#50d05a"
                    icon={faBadgeCheck}
                    size="2x"
                  />
                  <h6 className="font-bold">{t('connected')}</h6>
                </div>
              ) : (
                <Button className="btn btn-primary" onClick={setupStripe}>
                  {t('connect')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 flex justify-center items-center py-1 border-t border-grey w-full bg-white">
        <div className="px-4 flex justify-center items-center">
          <Button
            className="btn btn-primary-outlined"
            onClick={() => {
              trackOnboardingEvent(
                'Onboarding - Go back to step 4.AUTO_MESSAGES from Step 5. CONNECT_STRIPE',
              );
              setActiveTab(4);
            }}
          >
            <FontAwesomeIcon style={{ marginRight: 15 }} icon={faArrowLeft} />
            {t('previous')}
          </Button>

          {!successConnect ? (
            <Button
              onClick={() => skipStep()}
              className="text-primary btn btn-ghost rounded-2xl"
            >
              {t('skip')}
              <FontAwesomeIcon style={{ marginLeft: 15 }} icon={faArrowRight} />
            </Button>
          ) : (
            <Button onClick={() => skipStep()} className="btn btn-primary">
              {t('next')}
              <FontAwesomeIcon style={{ marginLeft: 15 }} icon={faArrowRight} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default InitBankAccount;
