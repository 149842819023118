import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import Input from '../../components/input/input.component';
import { useAuth } from '../../contexts/authContext';

import { useMutation } from 'react-query';
import SubmitButton from '../submit-button/submit-button.component';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { createStripeConnectAccount } from '../../api/connectAccounts';

type AddStripeConnectModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  refreshStripeConnectAccounts?: Function;
  workspaceId: string | undefined;
};

export const AddStripeConnectModal = ({
  displayModal,
  setDisplayModal,
  refreshStripeConnectAccounts,
  workspaceId,
}: AddStripeConnectModalProps) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetForm,
  } = useForm();
  const backendErrors = '';

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const {
    mutate: createConnectAccount,
    isLoading,
    reset: resetHook,
  } = useMutation(
    (data: any) =>
      workspaceId &&
      createStripeConnectAccount(currentUser.accessToken, workspaceId, data),
    {
      onSuccess: () => {
        setTimeout(() => {
          resetForm();
          resetHook();
          setDisplayModal(false);
        }, 1000);
        refreshStripeConnectAccounts && refreshStripeConnectAccounts();
      },
    },
  );

  const submit = (data: any) => {
    createConnectAccount(data);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[300px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('settings.company.add_account')}
              </h3>
              <p className="text-left text-[14px] font-satoshiRegular">
                {t('settings.company.add_account_description')}
              </p>
            </div>
            <div className="px-10">
              <div className="flex w-full gap-4 my-6">
                <div className="w-2/6">
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue=""
                      render={({ field: { onBlur, onChange, value } }: any) => (
                        <Input
                          type="text"
                          key="name"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label={`${capitalizeFirstLetter(
                            t('settings.account.name'),
                          )} *`}
                          errors={[
                            _.get(errors, 'name.message'),
                            ...backendErrorsForAttribute('name'),
                          ]}
                        />
                      )}
                      name={'name'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            <div className="gap-2 flex justify-end">
              <button
                title={t('cancel')}
                className="btn btn-primary-outlined rounded-3xl"
                onClick={() => setDisplayModal(false)}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('add')}
                disabled={isLoading}
                customClass="btn btn-primary rounded-3xl"
              />
            </div>
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};
