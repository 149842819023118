import React from 'react';
import './graph.styles.scss';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

const Graph = ({ orders, unitType, currency }: any) => {
  const formatedOrders = orders.map((o: any) => {
    return {
      ...o,
      totalamount: o.totalamount / 100,
      totalnetamount: o.totalnetamount / 100,
    };
  });
  const { t } = useTranslation();
  const monthNames = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];

  const dateFormatter = (item: any) => {
    let currentDate = new Date(item);
    if (unitType == 'month') return monthNames[currentDate.getMonth()];
    // if (unitType == 'hour') return currentDate.getHours() + 'h';
    else
      return (
        currentDate.getDate().toString() +
        ' ' +
        monthNames[currentDate.getMonth()]
      );
  };

  const graphDateFormatter = (item: any) => {
    let currentDate = new Date(item);
    if (unitType == 'month')
      return monthNames[currentDate.getMonth()].slice(0, 3);
    // if (unitType == 'hour') return currentDate.getHours() + 'h';
    else
      return (
        currentDate.getDate().toString() +
        ' ' +
        monthNames[currentDate.getMonth()].slice(0, 3)
      );
  };

  return (
    <ResponsiveContainer debounce={300}>
      <AreaChart
        style={{ zIndex: 0 }}
        width={730}
        height={250}
        data={formatedOrders}
        stackOffset="expand"
      >
        <defs>
          <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7E82E5" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#7E82E5" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorNet" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#CBCFFF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#CBCFFF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#cbcfffcd" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#cbcfffcd" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={unitType}
          tickFormatter={graphDateFormatter}
          domain={[0, 'auto']}
        />
        <YAxis
          type="number"
          domain={[0, 'auto']}
          allowDataOverflow={true}
          tickFormatter={(value) => value + currency.symbol}
        />
        <CartesianGrid vertical={false} />
        <Tooltip labelFormatter={dateFormatter} />
        <Area
          type="monotone"
          dataKey="totalnetamount"
          unit={currency.symbol}
          name={t('dashboard.total_revenues')}
          stroke="#7E82E5"
          fillOpacity={1}
          fill="url(#colorRevenue)"
        />
        <Area
          type="monotone"
          dataKey="totalamount"
          name={t('dashboard.net_revenues')}
          unit={currency.symbol}
          stroke="#CBCFFF"
          fillOpacity={1}
          fill="url(#colorNet)"
        />
        <Area
          type="monotone"
          dataKey="count"
          name={t('dashboard.total_sales')}
          stroke="#fe813c"
          fillOpacity={1}
          fill="url(#colorSales)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph;
