import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { getMessageTemplate } from '../../data/templates';
import { NotificationType } from '../../types/notification-type.model';
import { ChannelManagerConnection } from '../../types/channel-manager-connection.model';
import { AutomaticMessageScheduler } from '../../types/automatic-message-schedulers.model';

type AutomaticMessageItemProps = {
  index: number;
  messageScheduler: AutomaticMessageScheduler;
  mutateMessageScheduler: any;
  setConnection: any;
  // connection: ChannelManagerConnection;
};

const AutomaticMessageItem: React.FC<AutomaticMessageItemProps> = ({
  messageScheduler,
  mutateMessageScheduler,
  setConnection,
  // connection,
}) => {
  const { t, i18n } = useTranslation();
  const [isSmsEnabled, setIsSmsEnabled] = useState(
    messageScheduler?.notificationTypes?.includes(NotificationType.SMS) ??
      false,
  );
  const [isOtaEnabled, setIsOtaEnabled] = useState(
    messageScheduler?.notificationTypes?.includes(NotificationType.OTA) ??
      false,
  );

  const handleOnChangeNotificationType = (
    enabled: boolean,
    notificationType: NotificationType,
  ) => {
    setConnection((prev: ChannelManagerConnection) => {
      const updatedSchedulers = {
        ...prev,
        automaticMessagesSchedulers: prev.automaticMessagesSchedulers.map(
          (scheduler: AutomaticMessageScheduler) =>
            scheduler._id === messageScheduler._id
              ? {
                  ...scheduler,
                  notificationTypes: enabled
                    ? [...scheduler.notificationTypes, notificationType]
                    : scheduler.notificationTypes.filter(
                        (item: NotificationType) => item !== notificationType,
                      ),
                }
              : scheduler,
        ),
      };
      mutateMessageScheduler(updatedSchedulers);
      return updatedSchedulers;
    });
  };

  return (
    <Disclosure as="div">
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of an icon. */
        <>
          <Disclosure.Button className="w-full h-16 flex items-center justify-between rounded-lg text-left hover:text-primary hover:bg-lightGrey/10 text-sm font-medium focus:outline-none ">
            <div className="w-2/3 flex items-center justify-start">
              <FontAwesomeIcon
                icon={faChevronRight}
                className={
                  open
                    ? 'mr-2 text-black rotate-90 transform'
                    : 'mr-2 text-black'
                }
                size="1x"
              />
              <div className="w-full text-left">
                <span>
                  {t(
                    `automatic_messages.${messageScheduler.delay}-${messageScheduler.type}`,
                  )}
                </span>
              </div>
            </div>
            <div className="w-1/3 flex items-start justify-around gap-4 self-start">
              <div className="flex justify-center flex-col items-center">
                {/* TODO :  Handle logo display here */}
                {/*{index ? null : (*/}
                {/*  <div className="h-5 w-16 flex items-center mr-1">*/}
                {/*    <ChannelManagerLogo type={connection.channelManagerType.name} />*/}
                {/*  </div>*/}
                {/*)}*/}
                <label
                  className="relative flex cursor-pointer items-center rounded-full p-3"
                  htmlFor={`ota-checkbox-${messageScheduler._id}`}
                  data-ripple-dark="true"
                >
                  <input
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                    id={`ota-checkbox-${messageScheduler._id}`}
                    type="checkbox"
                    checked={isOtaEnabled}
                    onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsOtaEnabled(e.target.checked);
                      handleOnChangeNotificationType(
                        e.target.checked,
                        NotificationType.OTA,
                      );
                    }}
                  />
                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </label>
              </div>
              <div className="flex justify-center flex-col items-center">
                {/* TODO :  Handle logo display here */}
                {/*{index ? null : (*/}
                {/*  <div className="inline-flex items-center">*/}
                {/*    <FontAwesomeIcon icon={faPhone} className="mr-1" size="1x" />*/}
                {/*    <span className="mr-1">SMS</span>*/}
                {/*  </div>*/}
                {/*)}*/}
                <label
                  className="relative flex cursor-pointer items-center rounded-full p-3"
                  htmlFor={`sms-checkbox-${messageScheduler._id}`}
                  data-ripple-dark="true"
                >
                  <input
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                    id={`sms-checkbox-${messageScheduler._id}`}
                    type="checkbox"
                    checked={isSmsEnabled}
                    onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsSmsEnabled(e.target.checked);
                      handleOnChangeNotificationType(
                        e.target.checked,
                        NotificationType.SMS,
                      );
                    }}
                  />
                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </label>
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className="mt-2 text-sm text-white/50">
            <div
              className="rounded-2xl bg-lightblue p-3 my-2 text-left text-gray-600"
              dangerouslySetInnerHTML={{
                __html: getMessageTemplate(
                  i18n.language.slice(0, 2) === 'fr' ? 'fr' : 'en',
                  messageScheduler.type,
                ),
              }}
            />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AutomaticMessageItem;
