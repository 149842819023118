import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

import { useTranslation } from 'react-i18next';
import Pagination from '../pagination/pagination.component';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  displayTimeRemaining,
  formatDateToLocaleDateString,
} from '../../utils/date';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';

export const ReferralsList = ({ items, setPage, isLoading }: any) => {
  const { t } = useTranslation();
  const handleIsCheckOrNot = (hasOne: boolean) => {
    return hasOne ? (
      <FontAwesomeIcon icon={faCheckCircle} color="#50d05a" size="xl" />
    ) : (
      <FontAwesomeIcon icon={faCheckCircle} color="#b6b9ce" size="xl" />
    );
  };
  const columns = [
    {
      accessorKey: 'account.owner.email',
      header: 'From referral',
      cell: (props: any) => (
        <div>
          <p className="font-bold">{props.row.original.account.name ?? '-'}</p>
          <p className="font-bold">{props.getValue()}</p>
        </div>
      ),
    },
    {
      accessorKey: 'to.email',
      header: 'Referral',
      cell: (props: any) => (
        <div>
          <p className="font-bold">{props.getValue()}</p>
          <p className="text-grey">
            Register date :{' '}
            {formatDateToLocaleDateString(props.row.original.created_at)}
          </p>
        </div>
      ),
    },

    {
      accessorKey: 'totalPaidOrdersInFirstMonth',
      header: '# Orders',
      cell: (props: any) => (
        <>
          <div className="flex-1 flex gap-4 items-center px-4">
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-grey text-xs">
                  {t('referrals.order')} 1
                </span>
              </div>
              <div>{handleIsCheckOrNot(props.getValue() >= 1)}</div>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-grey text-xs">
                  {t('referrals.order')} 2
                </span>
              </div>
              <div>{handleIsCheckOrNot(props.getValue() >= 2)}</div>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <span className="text-grey text-xs">
                  {t('referrals.order')} 3
                </span>
              </div>
              <div>{handleIsCheckOrNot(props.getValue() >= 3)}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      accessorKey: '',
      header: 'Statut',
      cell: (props: any) => (
        <div className="flex-1 flex gap-4 justify-end lg:justify-center">
          <div>
            {props.row.original.hasExpired &&
            props.row.original.totalPaidOrdersInFirstMonth < 3 ? (
              <span className="font-medium text-xs px-2 py-1 rounded-3xl text-grey ">
                {t('referrals.status.expired')}
              </span>
            ) : (
              ''
            )}

            {props.row.original.totalPaidOrdersInFirstMonth >= 3 ? (
              <>
                {props.row.original?.paymentStatus === 'paid' ? (
                  <div>
                    <span className="font-medium px-2 py-1 rounded-3xl text-green ">
                      {t('referrals.status.completed_with_payment')}{' '}
                      {formatDateToLocaleDateString(
                        props.row.original?.paymentDate,
                      )}{' '}
                      -{' '}
                      {displayPrice(
                        props.row.original?.paidAmount,
                        getCurrencyInfo(props.row.original?.currency).symbol,
                        { thousandSeparator: ' ' },
                      )}
                    </span>
                  </div>
                ) : (
                  <span className="font-medium px-2 py-1  rounded-3xl text-green ">
                    {t('referrals.status.completed_waiting_payment')}
                  </span>
                )}
              </>
            ) : (
              ''
            )}

            {props.row.original?.hasExpired === false &&
            props.row.original?.totalPaidOrdersInFirstMonth < 3 ? (
              <span className="font-medium px-2 py-1 rounded-3xl text-orange">
                {t('referrals.status.pending_and_expire_in')}
                {displayTimeRemaining(props.row.original?.expirationDate)}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: items?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      {isLoading ? null : (
        <>
          <div className="flex justify-between mt-12 mb-6 w-full border-b py-2 border-gray-200 items-center">
            <h5
              className="lg:text-[36px]"
              style={{
                textAlign: 'left',
              }}
            >
              Referrals
            </h5>
          </div>
          <table className="cozyup table w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr className="tr  text-left" key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th className="th pl-3" key={header.id}>
                      {header.column.columnDef.header}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row: any) => (
                <tr className="bg-lightBlue text-left" key={row.id}>
                  {row.getVisibleCells().map((cell: any) => (
                    <td className="td" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            totalPagesCount={items?.metadata?.totalPages}
            currentPage={items?.metadata?.currentPage}
            totalItems={items?.metadata?.totalCount}
            onChangePage={setPage}
          />
        </>
      )}
    </>
  );
};
