// hooks/useChannelManagersConnectedToWorkspace.tsx

/**
 * This hook is used to get all channel managers connected to the current
 * workspace
 */
import { useQuery } from 'react-query';
import { useAuth } from '../contexts/authContext';
import {
  getCalryIntegrations,
  getChannelManagerIntegrations,
} from '../api/channelManagers';

const useGetChannelManagersConnectedToWorkspace = () => {
  const { currentUser, currentWorkspace } = useAuth();
  const CALRY_PREFIX = 'CALRY_';

  //TODO: Implement one request to get calry integrations and intern integrations
  const {
    data: internIntegrations,
    refetch: refetchInternIntegrations,
    isLoading: isLoadingInternIntegrations,
  } = useQuery(
    ['channelManagerIntegrations', currentWorkspace],
    // @ts-ignore
    () =>
      getChannelManagerIntegrations(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const {
    data: calryIntegrations,
    refetch: refetchCalryIntegrations,
    isLoading: isLoadingCalryIntegrations,
  } = useQuery(
    ['calryIntegrationsKey', currentWorkspace],
    // @ts-ignore
    () => getCalryIntegrations(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const isLoadingChannelManagerConnectedToWorkspace =
    isLoadingInternIntegrations || isLoadingCalryIntegrations;

  const refetchChannelManagersConnectedToWorkspace = () => {
    refetchInternIntegrations();
    refetchCalryIntegrations();
  };

  const channelManagersConnectedToWorkspace = [
    ...(internIntegrations || []),
    ...(calryIntegrations || []),
  ];

  const isChannelManagerConnected = (
    channelManagersConnectedToWorkspace: any[],
    channelManagerType: string,
  ) => {
    return channelManagersConnectedToWorkspace.filter(
      (cm: any) =>
        // Case for direction connection to CM/PMS
        cm.channelManagerType?.name === channelManagerType ||
        // Case for Calry connections
        CALRY_PREFIX + cm.name?.toUpperCase() === channelManagerType,
    ).length;
  };

  return {
    channelManagersConnectedToWorkspace,
    refetchChannelManagersConnectedToWorkspace,
    isLoadingChannelManagerConnectedToWorkspace,
    isChannelManagerConnected,
  };
};

export default useGetChannelManagersConnectedToWorkspace;
