import React, { Dispatch } from 'react';
import '../extra/extra.styles.scss';
import { Emoji } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from '../../utils/currency';

type Props = {
  product: any;
  index: number;
  setDisplayModal: Dispatch<boolean>;
  currency: any;
};

const ProductTemplate = ({
  product,
  index,
  setDisplayModal,
  currency,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToNewProduct = (product: any) => {
    setDisplayModal(false);
    navigate('/products/new', {
      state: {
        product: {
          ...product,
          name: t(`templates.${product.name}`),
          description: t(`templates.${product.description}`),
        },
      },
    });
  };

  const renderRanking = (index: number) => {
    switch (index) {
      case 1:
        return <Emoji emoji="first_place_medal" size={24} />;
      case 2:
        return <Emoji emoji="second_place_medal" size={24} />;
      case 3:
        return <Emoji emoji="third_place_medal" size={24} />;
      default:
        return <p>#{index}</p>;
    }
  };

  return (
    <>
      <div
        className="flex w-full justify-center items-center  cursor-pointer shadow rounded-[32px] font-satoshi min-h-[150px] mb-[12px] hover:border-primary border-[2px] border-transparent"
        onClick={() => handleNavigateToNewProduct(product)}
      >
        <div className="relative pl-2 pt-2 self-start">
          {renderRanking(index)}
        </div>
        <div className="emoji-container ml-3 sm:ml-0">
          {!product.emoji ? null : <Emoji emoji={product.emoji} size={52} />}
        </div>
        <div className="flex flex-col mx-[8px] w-full">
          <div className="flex justify-end mt-[8px]">
            <span className="capitalize w-fit mb-2 text-right py-0.5 px-3 text-xs font-semibold rounded-2xl bg-[#fffa0080] text-[#e7cf00]">
              {t('products.template')}
            </span>
          </div>
          <h3 className="text-[16px] w-full leading-[18px] font-mono text-[#333] text-left">
            {t(`templates.${product.name}`)}
          </h3>
          <p className="extra-description text-textDesc text-left my-[12px] line-clamp">
            {t(`templates.${product.description}`)}
          </p>
          <div className="w-full flex justify-start">
            <div className="flex w-1/2 price">
              {displayPrice(product.costPrice, currency.symbol, {
                thousandSeparator: ' ',
              })}
            </div>
            <div className="flex w-1/2 price">
              {displayPrice(product.sellingPrice, currency.symbol, {
                thousandSeparator: ' ',
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTemplate;
