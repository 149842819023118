import { api } from '../utils/request';
import { Home } from '../types/home.model';
import { SortOptions } from '../types/generic-types';
const qs = require('qs');

export const createHome = async (
  authToken: string,
  workspaceId: string,
  data: Home,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/homes`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const createMultipleHomes = async (
  authToken: string,
  workspaceId: string,
  data: Home[],
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/homes/multiple`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getHomes = async (authToken: string) => {
  try {
    const res = await api.get(`/homes`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getHomesByWorkspace = async (
  authToken: string,
  workspaceId: string,
  statusHome: string | null,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/homes`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        status: statusHome ? statusHome : null,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getHomesByWorkspacePaginated = async (
  userToken: string,
  workspaceId: string,
  page: Number,
  query: string,
  sortOptions: SortOptions,
  filters: { [key: string]: any },
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/homes`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        paginated: true,
        sort: {
          direction: sortOptions.direction,
          field: sortOptions.field,
        },
        filters,
        page,
        query,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getHomeById = async (homeId: string) => {
  try {
    const res = await api.get(`/homes/${homeId}`, {
      headers: {
        ...api.defaults.headers.common,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getHomeByCustomId = async (homeId: string) => {
  try {
    const res = await api.get(`/homes/customId/${homeId}`, {
      headers: {
        ...api.defaults.headers.common,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateHome = async (
  authToken: string,
  workspaceId: string,
  homeId: string,
  data: Home,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/homes/${homeId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};

export const patchStatusHome = async (
  authToken: string,
  workspaceId: string,
  homeId: string,
  status: any,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/homes/${homeId}`,
      { status: status },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteHome = async (
  authToken: string,
  workspaceId: string,
  homeId: string,
) => {
  try {
    const res = await api.delete(`/workspaces/${workspaceId}/homes/${homeId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const importFromAirbnb = async (
  authToken: string,
  propertyId: string,
) => {
  try {
    const res = await api.get(`/rapidapi/airbnb/${propertyId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response.data;
  }
};
