import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/authContext';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getAllCustomers } from '../../../api/workspaces';
import { displayPrice, getCurrencyInfo } from '../../../utils/currency';
import { formatDateToLocaleDateString } from '../../../utils/date';
import Loader from '../../loader/loader.component';
import Pagination from '../../pagination/pagination.component';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import useTrackMixpanelEvent from '../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import toast from 'react-hot-toast';
import SelectRangeDatesModal from '../../modals/select-range-dates-modal.component';
import Button from '../../button/button.component';
import { genereateCustomersCsv } from '../../../api/workspaces';

const MyCustomers = () => {
  const { t } = useTranslation();
  const { currentUser, apiUser, currentWorkspace, currentAccount } = useAuth();
  const currency = currentWorkspace?.currency;
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [activePage, setActivePage] = useState(1);
  const [displaySelectRangeDatesModal, setDisplaySelectRangeDatesModal] =
    useState(false);

  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const { data: customers, isLoading: isLoadingCustomers } = useQuery(
    ['ordersKey', activePage, currentUser, apiUser, currentWorkspace],
    // @ts-ignore
    () =>
      getAllCustomers(
        currentUser.accessToken,
        currentWorkspace._id,
        activePage,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      staleTime: Infinity,
      refetchOnWindowFocus: true,
    },
    { keepPreviousData: true },
  );

  const { mutate: exportCsv, isLoading: isLoadingCsv } = useMutation(
    (data: any) => {
      return genereateCustomersCsv(
        currentUser.accessToken,
        currentWorkspace._id,
        data.startDate,
        data.endDate,
      );
    },
    {
      onSuccess: async (response: any) => {
        // Créer un lien pour le téléchargement
        trackMixpanelEvent('Customers - export sccess', mixpanelProperties);
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;

        a.download = 'cozyup-customers.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      onError: (error: any) => {
        console.error('error', error);
        if (error.status === 404) {
          toast.error(t('customers.export_csv_customers_not_found'));
        } else {
          toast.error(t('errors.unknown_error'));
        }
      },
    },
  );

  const handleOnGenerateCsv = (startDate: string, endDate: string) => {
    trackMixpanelEvent(
      'Invoice - Export CSV error - No company info',
      mixpanelProperties,
    );
    setDisplaySelectRangeDatesModal(false);
    exportCsv({ startDate: startDate, endDate: endDate });
  };

  return (
    <div className="w-full rounded-2xl py-2 lg:px-6 shadow-lg lg:flex-grow mt-8">
      <div className="flex flex-col h-full p-0">
        <div className="flex flex-col items-start">
          <div className="flex justify-between w-full items-center">
            <h5 className="mx-2 lg:mx-0 my-5 text-left">
              {t('menu.my_customers')}
            </h5>
            {customers && customers?.metadata?.totalCount > 0 && (
              <Button
                disabled={isLoadingCsv}
                onClick={() => setDisplaySelectRangeDatesModal(true)}
                className="btn btn-primary-outlined flex"
              >
                {t('customers.export_csv')}
                {!isLoadingCsv ? null : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </Button>
            )}
          </div>
          {displaySelectRangeDatesModal && (
            <SelectRangeDatesModal
              setDisplayModal={setDisplaySelectRangeDatesModal}
              onSubmit={handleOnGenerateCsv}
            />
          )}

          <div className=" hidden md:flex w-full">
            {!customers || customers?.metadata?.totalPages === 0 ? null : (
              <Pagination
                totalPagesCount={customers?.metadata?.totalPages}
                currentPage={customers?.metadata?.currentPage}
                totalItems={customers?.metadata?.totalCount}
                onChangePage={setActivePage}
              />
            )}
          </div>
        </div>
        <table className="cozyup hidden lg:table py-4">
          <thead>
            <tr>
              <th className="cursor-pointer">{'#'}</th>
              <th className="cursor-pointer">{t('name')}</th>
              <th className="cursor-pointer">{t('email')}</th>
              <th className="cursor-pointer">{t('total')}</th>
              <th className="cursor-pointer">{t('last_order')}</th>
            </tr>
          </thead>
          {isLoadingCustomers ? (
            <Loader />
          ) : (
            <tbody>
              {!customers?.data
                ? null
                : customers?.data?.map((customer: any, index: number) => (
                    <tr
                      className="cursor-pointer bg-lightblue my-4 py-4 px-2 h-10 row hidden lg:table-row"
                      key={JSON.stringify(index)}
                    >
                      <td>{index + 1}</td>
                      <td className="first-letter:capitalize">
                        {customer.order_customer}
                      </td>
                      <td>{customer.email}</td>
                      <td>
                        {displayPrice(
                          customer.totalSpent,
                          getCurrencyInfo(currency).symbol,
                          { thousandSeparator: ' ' },
                        )}
                      </td>
                      <td>
                        {formatDateToLocaleDateString(customer.lastOrderDate)}
                      </td>
                    </tr>
                  ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
export default MyCustomers;
