import React, { useState } from 'react';
import OrderDetails from '../order-details/order-details.component';
import { formatDateToLocaleDateString } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';
import { displayPrice } from '../../../../utils/currency';

const OrderRawAdmin = ({ order }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <>
      <tr
        className="bg-lightblue my-4 py-4 px-2 h-10 row table-row "
        onClick={handleFilterOpening}
      >
        <td>{order.order_id}</td>
        <td>{order.workspace?.account?.owner?.email}</td>
        <td>{order.home.name}</td>
        <td>{formatDateToLocaleDateString(order.purchaseDate)}</td>
        <td>{formatDateToLocaleDateString(order.deliveryDate)}</td>
        <td>{order.customer}</td>
        <td>
          {displayPrice(order.totalAmount, order.currency, {
            thousandSeparator: ' ',
          })}
        </td>
        <td>
          {order.paymentStatus === 'paid' ? (
            <span className="capitalize py-0.5 px-3 text-xs bg-lightGreen  text-green font-semibold rounded-2xl">
              {t(`orders.${order.paymentStatus}`)}
            </span>
          ) : (
            ''
          )}
          {order.paymentStatus === 'unpaid' ? (
            <span className="capitalize py-0.5 px-3 text-xs bg-lightGrey text-grey font-semibold rounded-2xl">
              {t(`orders.${order.paymentStatus}`)}
            </span>
          ) : (
            ''
          )}
        </td>
      </tr>
      {!order.products || !isOpen ? null : (
        <OrderDetails order={order} currency={order.currency} />
      )}
    </>
  );
};

export default OrderRawAdmin;
