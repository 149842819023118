import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStripeConnectSettingLink } from '../../api/stripe';
import { useMutation } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { Icon, Tooltip } from '@mui/material';
import { Build, Delete, HelpOutline } from '@mui/icons-material';
import { updateConnectAccount } from '../../api/connectAccounts';
import AlertBeforeAction from '../alert-before-action/alert-before-action.component';

const checkStripeAccount = (stripeAccount: any, t: any) => {
  if (stripeAccount) {
    if (stripeAccount?.charges_enabled) {
      return (
        <Tooltip title={t('settings.stripe.account_currently_active')}>
          <div className="flex items-center">
            <div className="text-center h-full cursor-pointer rounded-3xl text-green bg-lightGreen px-4 py-1 w-fit font-semibold">
              <span className="mr-1">{t('settings.stripe.active')} </span>
              <Icon
                className="icon text-white opacity-75"
                component={HelpOutline}
              />
            </div>
          </div>
        </Tooltip>
      );
    }
    if (
      !stripeAccount.details_submitted ||
      stripeAccount.requirements?.disabled_reason == 'requirements.past_due'
    ) {
      return (
        <Tooltip title={t('settings.stripe.missing_info')}>
          <div className="flex items-center">
            <div className="text-center h-full rounded-3xl cursor-pointer text-red-900 bg-red-200 px-3 py-1 font-semibold flex items-center justify-center">
              <span className="mr-1">{t('settings.stripe.inactive')}</span>
              <Icon
                className="icon text-white opacity-75"
                component={HelpOutline}
              />
            </div>
          </div>
        </Tooltip>
      );
    }
    if (
      stripeAccount.requirements.disabled_reason ==
      'requirements.pending_verification'
    ) {
      return (
        <Tooltip title={t('settings.stripe.pending_verification')}>
          <div className="flex items-center">
            <div className="text-center h-full rounded-3xl cursor-pointer text-red-900 bg-red-200 px-3 py-1 font-semibold flex items-center justify-center">
              {t('settings.stripe.pending')}
              <Icon
                className="icon text-gray-300 cursor-pointer opacity-75"
                component={HelpOutline}
              />
            </div>
          </div>
        </Tooltip>
      );
    }
  } else {
    return (
      <Tooltip title={t('settings.stripe.unusable_dsc')}>
        <div className="flex items-center">
          <div className="text-center gap-2 h-full rounded-3xl cursor-pointer text-grey bg-lightGrey px-3 py-1 font-semibold flex items-center justify-center">
            {t('settings.stripe.unusable')}
            <Icon
              className="icon text-gray-300 cursor-pointer opacity-75 ml-1"
              component={HelpOutline}
            />
          </div>
        </div>
      </Tooltip>
    );
  }
};

export const ListConnectAccounts = ({
  connectAccounts,
  refreshStripeConnectAccounts,
}: any) => {
  const [currentConnectAccountId, setConnectAccountId] = useState('');
  const [displayDeactivateAlert, setDisplayDeactivateAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const { t } = useTranslation();
  const { currentUser, currentWorkspace, refreshAccounts } = useAuth();

  const { mutate: redirectStripeConnect } = useMutation(
    // @ts-ignore
    (id: string) =>
      getStripeConnectSettingLink(currentUser.accessToken, id, {
        refreshUrl: window.location.href,
        returnUrl: window.location.href,
      }),
    {
      enabled: false,
      onSuccess: (data: any) => {
        window.location.replace(data.url);
      },
    },
  );

  const handleDeactivateAccount = (
    event: any,
    connectAccountId: string,
    connectAccountName: string,
  ) => {
    event.stopPropagation();
    setAlertDescription(
      t('settings.stripe.delete_warning', {
        connectAccount: connectAccountName,
      }),
    );
    setConnectAccountId(connectAccountId);

    setDisplayDeactivateAlert(true);
  };

  const deleteClickedConnectAccount = async () => {
    await deactivateAccount(currentConnectAccountId);
    ``;
  };

  const { mutate: deactivateAccount } = useMutation(
    // @ts-ignore
    (id: string) =>
      updateConnectAccount(currentUser.accessToken, currentWorkspace._id, id, {
        status: 'archived',
      } as any),
    {
      onSuccess: () => {
        refreshStripeConnectAccounts();
        refreshAccounts();
      },
    },
  );

  return (
    <div>
      <AlertBeforeAction
        title={t('are_you_sure')}
        description={alertDescription}
        successAction={deleteClickedConnectAccount}
        isVisible={displayDeactivateAlert}
        setIsVisible={setDisplayDeactivateAlert}
      />
      {connectAccounts?.length ? (
        <table className="cozyup table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('settings.name')}</th>
              <th>{t('settings.workspace.nb_listing')}</th>
              <th>{t('settings.stripe.identifier')}</th>
              <th>{t('listings.status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="mt-6">
            {connectAccounts
              ?.filter((cA: any) => cA.status === 'active')
              .map((cA: any, i: number) => (
                <tr className="hover:shadow-none" key={cA._id}>
                  <td>{i + 1}</td>
                  <td>{cA?.name || '-'}</td>
                  <td>{cA.homes.length}</td>
                  <td>
                    <span className="rounded-3xl bg-lightGrey px-3 py-1 text-sm">
                      {cA?.stripeConnectId || '-'}
                    </span>
                  </td>
                  <td>
                    <div className="flex justify-center items-center">
                      {checkStripeAccount(cA.stripeConnectInfo, t)}
                    </div>
                  </td>
                  <td>
                    {!cA.stripeConnectInfo ? (
                      <div className="flex justify-end items-center">
                        <Tooltip title={`${t('delete')}`}>
                          <div
                            onClick={(e: any) =>
                              handleDeactivateAccount(e, cA._id, cA.name)
                            }
                            className="icon w-fit hover:bg-gray-300 rounded-full p-2 mx-2 text-red-500 cursor-pointer"
                          >
                            <Icon
                              className="h-10 w-10"
                              fontSize="small"
                              component={Delete}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="flex justify-end items-center">
                        {cA.stripeConnectInfo?.charges_enabled ? null : (
                          <div className="flex">
                            <Tooltip title={`${t('configure')}`}>
                              <div
                                onClick={() =>
                                  redirectStripeConnect(cA.stripeConnectId)
                                }
                                className="icon w-fit hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                              >
                                <Icon
                                  className="h-10 w-10"
                                  fontSize="small"
                                  component={Build}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title={`${t('delete')}`}>
                              <div
                                onClick={(e: any) =>
                                  handleDeactivateAccount(e, cA._id, cA.name)
                                }
                                className="icon w-fit hover:bg-gray-300 rounded-full p-2 mx-2 text-red-500 cursor-pointer"
                              >
                                <Icon
                                  className="h-10 w-10"
                                  fontSize="small"
                                  component={Delete}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="text-sm font-semibold text-[#6B7194] opacity-20">
          {t('tables.empty-bank-accounts')}
        </div>
      )}
    </div>
  );
};
