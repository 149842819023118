import { api } from '../utils/request';
import { Product } from '../types/product.model';

export const createProductByWorkspace = async (
  authToken: string,
  workspaceId: string,
  data: Product,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/products`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getProducts = async (userToken: string) => {
  try {
    const res = await api.get(`/products`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const getProductsByWorkspace = async (
  userToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/products`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getProductById = async (
  userToken: string,
  workspaceId: string,
  productId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/products/${productId}`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${userToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateProduct = async (
  authToken: string,
  workspaceId: string,
  productId: string,
  data: Product,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/products/${productId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteProduct = async (
  authToken: string,
  workspaceId: string,
  productId: string,
) => {
  try {
    const res = await api.delete(
      `/workspaces/${workspaceId}/products/${productId}`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res;
  } catch (err: any) {
    console.log(err);
  }
};
