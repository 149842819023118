import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button/button.component';
import Loader from '../loader/loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { NONE } from '../../constantes/channel-managers';
import { faLinkSlash } from '@fortawesome/pro-regular-svg-icons';
import { Colors } from '../../assets/Colors';

export const ChannelManagerItem = ({
  ChannelManagerType,
  onConnectClick,
  isConnected,
  isLoading,
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="m-2">
      <div className="border border-grey rounded-2xl flex flex-col min-h-[120px]">
        <div className="flex-1 flex items-center justify-center min-h-[100px] p-3">
          {ChannelManagerType?.logo === NONE ? (
            <div className="mt-2">
              <FontAwesomeIcon
                icon={faLinkSlash}
                className="text-4xl"
                color={Colors.grey}
              />
              <p className="text-grey font-satoshi mt-3">
                {t('onboarding.skip_step')}
              </p>
            </div>
          ) : (
            <img
              className="object-contain w-full max-h-[50px]"
              src={ChannelManagerType.logo}
              alt="channel manager logo"
            />
          )}
        </div>
        <div className="flex justify-end items-center border-grey border-t p-1 min-h-[4Opx]">
          {isLoading ? (
            <div className="flex w-full items-end justify-center">
              <Loader />
            </div>
          ) : (
            <div className="flex items-end">
              {isConnected ? (
                <div className="px-2 py-1 border border-green rounded-2xl text-green">
                  <p className="text-sm">{t('connected')}</p>
                </div>
              ) : (
                <Button
                  onClick={onConnectClick}
                  className="flex justify-center items-center text-primary text-sm hover:opacity-50 px-2 py-1"
                >
                  {ChannelManagerType.type === NONE ? t('skip') : t('connect')}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="xs"
                    className="mx-1"
                  />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
