import React, { useEffect, useState } from 'react';
import { getWorkspace, updateWorkspace } from '../../../../api/workspaces';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../../contexts/authContext';
import SubmitButton from '../../../submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Workspace } from '../../../../types/workspace.model';

import Select from '../../../select/select.component';
import { EditUsersWorkspace } from './edit-users-workspace.component';
import CompanyForm from '../../../company-form/company-form.component';
import { CURRENCIES } from '../../../../utils/currency';
import { ListConnectAccounts } from '../../../list-connect-accounts/ListConnectAccounts.component';
import { AddStripeConnectModal } from '../../../modals/add-stripe-connect-modal.component';
import { getStripeConnectAccountByWorkspaceId } from '../../../../api/connectAccounts';

const WorkspaceDetail = ({ setSubRoute, refreshWorkspaces }: any) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [disabledEditCompany, setDisabledEditCompany] = useState<boolean>(true);
  const { currentUser, currentAccount, refreshAccounts } = useAuth();
  const [displayAddStripeConnect, setDisplayAddStripeConnect] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    setSubRoute(t('header.workspaces'));
  }, []);

  const backendErrors = '';
  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const handleCancelEdit = () => {
    reset();
    setDisabled(true);
  };

  const { mutate: updateCurrentWorkspace } = useMutation(
    (data: Workspace) =>
      id && updateWorkspace(currentUser.accessToken, id, data),
    {
      onSuccess: () => {
        refetchWorkspace();
        refreshWorkspaces();
        refreshAccounts();
        setDisabledEditCompany(false);
      },
    },
  );

  const {
    data: stripeConnectAccountsData,
    isLoading: isLoadingSCA,
    refetch: refreshStripeConnectAccount,
  } = useQuery(
    ['stripeConnectAccount', currentUser, currentAccount],
    () =>
      id && getStripeConnectAccountByWorkspaceId(currentUser.accessToken, id),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  const onUpdateCompany = (company: any) => {
    updateCurrentWorkspace({
      ...workspaceDetails,
      company,
    });
  };

  const submit = (data: any) => {
    updateCurrentWorkspace({
      ...data,
    });
    setDisabled(true);
  };

  const {
    refetch: refetchWorkspace,
    isLoading,
    data: workspaceDetails,
  } = useQuery(
    ['workspace', id],
    // @ts-ignore
    () => getWorkspace(currentUser.accessToken, id),
    {
      enabled: !!id && !!currentAccount,
    },
  );

  return isLoading ? null : (
    <div className="flex flex-col gap-6">
      <div>
        <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
          <h5 style={{ textAlign: 'left', margin: 0 }}>{'Informations'}</h5>
          <div className="flex">
            <div
              className="btn btn-warning rounded-xl"
              onClick={() => setDisabled(false)}
            >
              {t('edit')}
            </div>
          </div>
        </div>
        <div className="mt-6 w-full lg:flex">
          <div className="mt-6 lg:mt-0 w-full">
            <form onSubmit={handleSubmit(submit)}>
              <div className="md:w-1/2">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={workspaceDetails?.title}
                  render={({ field: { onChange, value } }: any) => (
                    <Input
                      disabled={disabled}
                      key="title"
                      label={t('settings.name')}
                      size="small"
                      onChange={onChange}
                      value={value}
                      errors={[
                        _.get(errors, 'title.message'),
                        ...backendErrorsForAttribute('title'),
                      ]}
                    />
                  )}
                  name={'title'}
                />
              </div>
              <div className="md:w-1/2">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={workspaceDetails?.currency}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      disabled={disabled}
                      key="currency"
                      label={`${t('currency')}`}
                      selectLabel={`${t('select_currency')}`}
                      tooltip={`${t('currency_tooltip')}`}
                      onChange={onChange}
                      value={value}
                      options={CURRENCIES}
                      errors={[_.get(errors, 'currency.message')]}
                    />
                  )}
                  name={'currency'}
                />
              </div>

              {disabled ? null : (
                <div className="flex justify-end my-4">
                  <button
                    title={t('cancel')}
                    className="btn rounded-3xl btn-primary-outlined"
                    onClick={() => handleCancelEdit()}
                  >
                    {t('cancel')}
                  </button>
                  <SubmitButton
                    title="Confirm"
                    customClass={'btn rounded-3xl btn-primary'}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
          <h5 style={{ textAlign: 'left', margin: 0 }}>
            {t('settings.stripe.title')}
          </h5>
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisplayAddStripeConnect(true)}
          >
            {t('add')}
          </div>
        </div>
        <div className="mb-3">
          <AddStripeConnectModal
            displayModal={displayAddStripeConnect}
            setDisplayModal={setDisplayAddStripeConnect}
            refreshStripeConnectAccounts={refreshStripeConnectAccount}
            workspaceId={id}
          />
          {!isLoadingSCA && (
            <ListConnectAccounts
              connectAccounts={stripeConnectAccountsData}
              refreshStripeConnectAccounts={refreshStripeConnectAccount}
            />
          )}
        </div>
      </div>
      <CompanyForm
        title={t('settings.company.billing_information')}
        item={workspaceDetails?.company}
        isDisabled={disabledEditCompany}
        setIsDisabled={setDisabledEditCompany}
        update={onUpdateCompany}
      />
      <div className=" mt-4">
        <EditUsersWorkspace
          workspace={workspaceDetails}
          refresh={refetchWorkspace}
        />
      </div>
    </div>
  );
};

export default WorkspaceDetail;
