import React, { FC } from 'react';

const YoutubeDemo: FC = () => {
  return (
    <div className="w-full max-w-3xl lg:max-w-[900px]  h-[56.25vw] max-h-[560px] m-4 bg-white p-4 rounded-lg mx-0 lg:mx-12 shadow-xl">
      <iframe
        className="w-full h-full rounded-lg"
        src="https://www.youtube.com/embed/3m1X7g4WIfw"
        allowFullScreen
        title="CozyUp - Démarrage en 17min chrono ⏳"
      ></iframe>
    </div>
  );
};

export default YoutubeDemo;
