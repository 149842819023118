import React from 'react';
import Header from '../../components/header/header.component';
import TabLayout from '../../components/tab-layout/tab-layout.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useStore';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';

const MainContent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );
  const { isAccountOwner, currentWorkspace } = useAuth();
  const isSettingsScreen = location.pathname.includes('settings');

  return (
    <>
      {isSettingsScreen ? null : (
        <div className="lg:ml-[239px] hidden lg:block p-2 pb-0">
          <Header />
        </div>
      )}
      <div className="mt-20 lg:mt-0 lg:ml-[220px]">
        {location.pathname === '/onboarding' ? null : (
          <div className="mx-3 md:hidden">
            {isAccountOwner && onboardingStatus.data.stripeConnect < 1 && (
              <div
                onClick={() =>
                  navigate(`/settings/workspaces/${currentWorkspace._id}`)
                }
                className="cursor-pointer flex gap-6 text-left items-center px-4 py-2 border border-orange text-orange bg-lightOrange font-bold  rounded-xl w-fit"
              >
                <span className="mr-4 text-xl">&#9888;&#65039; </span>
                <span className="text-xs">
                  {t('settings.stripe.not-active', {
                    name: currentWorkspace.title,
                  })}
                </span>
                <div>
                  <div className="font-bold px-6 py-1 m-0 border rounded-xl border-orange text-xs">
                    {t('see')}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <TabLayout />
      </div>
    </>
  );
};

export default MainContent;
