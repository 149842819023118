import React, { useEffect, useState } from 'react';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import CheckoutAreaList from '../../components/_main/checkout-area/checkout-area-list/checkout-area-list.component';
import CheckoutAreaDetails from '../../components/_main/checkout-area/checkout-area-details/checkout-area-details.component';
import NewCheckoutArea from '../../components/_main/checkout-area/new-checkout-area/new-checkout-area.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { getCheckoutAreasByWorkspace } from '../../api/checkoutAreas';
import { useTranslation } from 'react-i18next';

const CheckoutAreas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, apiUser, currentWorkspace } = useAuth();
  const [subRoute, setSubRoute] = useState('');
  const [refreshCheckoutAreas, setRefreshCheckoutAreas] = useState(false);
  const match = useMatch({ path: 'checkout-areas', end: true });
  const headerProps = match
    ? {
        actionTitle: `+ ${t('checkout-areas.add_store')}`,
        callback: () => navigate('/checkout-areas/new'),
      }
    : null;

  const {
    data: checkoutAreasData,
    refetch: refetchCheckoutAreas,
    isLoading: isLoadingStores,
  } = useQuery(
    ['checkout-areas', currentUser, apiUser, currentWorkspace],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!apiUser && !!currentWorkspace,
    },
  );

  useEffect(() => {
    if (refreshCheckoutAreas) {
      refetchCheckoutAreas();
      setRefreshCheckoutAreas(false);
    }
  }, [refreshCheckoutAreas]);

  return (
    <TabWrapper>
      <>
        <TabContentHeader
          title={t('menu.checkout-areas')}
          description={t('menu.checkout-areas_description')}
          rootPage="/checkout-areas"
          subRoute={subRoute}
          {...headerProps}
        />
        <TabContent>
          <div className="home-tab-container">
            <Routes>
              <Route
                path=""
                element={
                  <CheckoutAreaList
                    setSubRoute={setSubRoute}
                    checkoutAreas={checkoutAreasData}
                    setRefresh={setRefreshCheckoutAreas}
                    isLoadingStores={isLoadingStores}
                  />
                }
              />
              <Route
                path=":id"
                element={
                  <CheckoutAreaDetails
                    setSubRoute={setSubRoute}
                    setRefresh={setRefreshCheckoutAreas}
                  />
                }
              />
              <Route
                path="new"
                element={
                  <NewCheckoutArea
                    setSubRoute={setSubRoute}
                    setRefresh={setRefreshCheckoutAreas}
                  />
                }
              />
            </Routes>
          </div>
        </TabContent>
      </>
    </TabWrapper>
  );
};

export default CheckoutAreas;
