import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';
import AddHomeManuallyForm from '../_main/home/add-home/add-home-form/add-manually-form.component';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onSuccess: Function;
};

export const AddManuallyHomeModal = ({
  displayModal,
  setDisplayModal,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    setDisplayModal(false);
    onSuccess(data);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative w-[95%] flex flex-col justify-between max-w-[800px] min-h-[350px] lg:min-h-[150px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-[36px] mb-4">
                {t('listings.add_manually')}
              </h5>
            </div>
          </div>

          <div className="px-4 lg:px-10 py-10">
            <AddHomeManuallyForm
              setDisplayModal={setDisplayModal}
              onSubmit={onSubmit}
            />
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};
