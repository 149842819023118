// Mock setting: Days before checkout to send a notification

export const EXAMPLE_RESERVATION_MOCK = {
  availabilities: [
    {
      date: new Date(2024, 2, 15),
      basePriceFormatted: '54.00€',
      basePriceCents: 100,
      priceWithDiscount: '45.90€',
      priceWithDiscountCents: 4590,
      currency: 'EUR',
    },
    {
      date: new Date(2024, 2, 16),
      basePriceFormatted: '54.00€',
      basePriceCents: 100,
      priceWithDiscount: '45.90€',
      priceWithDiscountCents: 4590,
      currency: 'EUR',
    },
    {
      date: new Date(2024, 2, 17),
      basePriceFormatted: '54.00€',
      basePriceCents: 100,
      priceWithDiscount: '45.90€',
      priceWithDiscountCents: 4590,
      currency: 'EUR',
    },
    // Add more dates as needed
  ],
  arrivalDate: new Date(2024, 2, 13),
  departureDate: new Date(2024, 2, 15),
  notificationDays: [
    // Assuming you calculate these based on your criteria
    new Date(2024, 2, 9),
  ],
};
