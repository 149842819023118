import { useQuery } from 'react-query';
import { getHomesByWorkspacePaginated } from '../api/homes';
import { SortOptions } from '../types/generic-types';
import { useAuth } from '../contexts/authContext';

const useGetHomes = (
  activePage: number,
  activeSort: SortOptions,
  searchedHome: string,
  activeFilters: { [key: string]: string },
) => {
  console.log('activeFilters', activeFilters);
  const { currentUser, apiUser, currentWorkspace } = useAuth();
  const {
    data: homes,
    refetch: refetchHomes,
    isLoading: isFetching,
  } = useQuery(
    [
      'homesKey',
      activePage,
      currentUser,
      apiUser,
      activeSort,
      searchedHome,
      currentWorkspace,
      activeFilters,
    ],
    // @ts-ignore
    () =>
      getHomesByWorkspacePaginated(
        currentUser.accessToken,
        currentWorkspace._id,
        activePage,
        searchedHome.toLowerCase(),
        activeSort,
        activeFilters,
      ),
    {
      enabled: !!currentUser && !!apiUser && !!currentWorkspace,
      refetchOnWindowFocus: true,
    },
    { keepPreviousData: true },
  );

  return { homes, refetchHomes, isFetching };
};

export default useGetHomes;
