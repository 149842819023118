import React from 'react';
import { useTranslation } from 'react-i18next';

export const ListInvitations = ({ invitations }: any) => {
  const { t } = useTranslation();

  return (
    <div>
      {invitations.length ? (
        <table className="cozyup table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('settings.sended_to')}</th>
              <th>{t('settings.workspace.workspace_other')}</th>
              <th>{t('role')}</th>
              <th>{t('listings.status')}</th>
            </tr>
          </thead>

          <tbody className="mt-6">
            {invitations.map((inv: any, i: number) => (
              <tr className="hover:shadow-none" key={inv._id}>
                <td>{i + 1}</td>
                <td>{inv?.to || 'undefined'}</td>
                <td>{inv.accountWorkspaces.length}</td>
                <td>{inv.role}</td>
                <td>
                  <div className="flex justify-center items-center">
                    <div className="text-center h-full rounded-3xl text-orange bg-lightOrange px-4 py-1 w-fit font-semibold">
                      {t(`invitation.${inv.status}`)}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-sm font-semibold text-[#6B7194] opacity-20">
          {t('tables.empty-pending-invitations')}
        </div>
      )}
    </div>
  );
};
