import imageCompression from 'browser-image-compression';

export const resizeImage = async (file: File) => {
  const imageFile = file;

  const options = {
    maxSizeMB: 0.8,
    maxWidthOrHeight: 1920,
  };
  try {
    return await imageCompression(imageFile, options);
  } catch (error) {
    console.error(error);
  }
};
