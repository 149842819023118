import React, { useEffect } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import './input-day-picker.styles.scss';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';
import { dateFnslocales } from '../../utils/language';
import i18n from '../../i18n';

interface Props {
  datePickerRef: any;
  selectedDay: Date | undefined;
  setSelectedDay: any;
  showDatePicker: boolean;
  setShowDatePicker: any;
  displayLabel?: boolean;
  disabled?: boolean;
}

const InputDayPicker = ({
  datePickerRef,
  selectedDay,
  setSelectedDay,
  showDatePicker,
  setShowDatePicker,
  displayLabel = true,
  disabled = false,
}: Props) => {
  const [stringDate, setStringDate] = React.useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDay) {
      let datepickerValue = selectedDay.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      setStringDate(datepickerValue);
      setShowDatePicker(false);
    }
  }, [selectedDay]);

  const displayCalendar = (value: boolean) => {
    if (disabled) return;
    setShowDatePicker(value);
  };

  return (
    <div className="checkout-day-picker relative">
      {displayLabel && (
        <div className="text-left ml-0">
          <label
            htmlFor="datepicker"
            className="font-bold mb-1 text-gray-700 block"
          >
            {t('checkout.select_arrival_date')}
          </label>
        </div>
      )}

      <div
        onClick={() => displayCalendar(!showDatePicker)}
        className="relative"
      >
        <input type="hidden" name="date" />
        <input
          ref={datePickerRef}
          type="text"
          readOnly
          value={stringDate}
          disabled={disabled}
          className={`${
            disabled
              ? ' opacity-50'
              : ' focus:border-checkoutPrimary hover:border-checkoutPrimary'
          } cursor-default w-full pl-4 pr-10 py-3 leading-none border border-gray-400 rounded-lg focus:outline-none focus:shadow-outline text-gray-600 font-medium`}
          placeholder={t('checkout.select_arrival_date_placeholder')}
        />

        <div className="absolute top-0 right-0 px-3 py-2">
          <svg
            className="h-6 w-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
      </div>

      {showDatePicker && (
        <>
          <OnClickOut callback={() => setShowDatePicker(false)}>
            <div className="absolute z-20 lg:top-14 lg:-translate-y-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-3xl shadow-lg border border-gray-200">
              <DayPicker
                mode="single"
                selected={selectedDay}
                onSelect={setSelectedDay}
                showOutsideDays
                fixedWeeks
                defaultMonth={selectedDay || new Date()}
                locale={dateFnslocales[i18n.language]}
                modifiersStyles={{
                  today: { color: '#ff3366' },
                }}
              />
            </div>
          </OnClickOut>
        </>
      )}
    </div>
  );
};

export default InputDayPicker;
