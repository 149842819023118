import React, { useState } from 'react';

import { Emoji } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import { generateInvoice } from '../../../../api/orders';
import { useAuth } from '../../../../contexts/authContext';
import { useQuery } from 'react-query';
import { faRight, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import { useNavigate } from 'react-router-dom';
import { displayPrice, getCurrencyInfo } from '../../../../utils/currency';
import { WithPermissions } from '../../../with-permissions/with-permissions.component';
import useTrackMixpanelEvent from '../../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';

import { Order } from '../../../../types/order.model';
import { calculateDaysRemaining } from '../../../../utils/date';

type OrderDetailsMobileTypeProps = {
  order: Order;
  currency: any;
  requestRefund?: Function;
  requestValidation?: Function;
};

const OrderDetailsMobile = ({
  order,
  currency,
  requestValidation,
  requestRefund,
}: OrderDetailsMobileTypeProps) => {
  const { t, i18n } = useTranslation();
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const navigate = useNavigate();
  const currentCompanyData = currentWorkspace?.company;
  const [displayNoCompanyAlert, setDisplayNoCompanyAlert] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [displayRefundAlert, setDisplayRefundAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');

  const { refetch: getInvoice } = useQuery(
    ['invoice'],
    () =>
      generateInvoice(
        currentUser.accessToken,
        order._id,
        i18n.language.split('-')[0],
        currency.key,
      ),
    {
      manual: true,
      enabled: false,
    },
  );

  const onRefundConfirm = async () => {
    requestRefund && (await requestRefund(order._id));
    setDisplayRefundAlert(false);
  };
  const alertRefund = () => {
    const currency = getCurrencyInfo(order.currency);
    setAlertDescription(
      t('orders.refund_alert', {
        orderId: order.order_id,
        amount: displayPrice(order.totalAmount, currency.symbol, {
          thousandSeparator: ' ',
        }),
        currency: currency.symbol,
      }),
    );

    setDisplayRefundAlert(true);
  };

  const mixpanelProperties = {
    home_id: order.home._id,
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const previewInvoice = async () => {
    if (!currentCompanyData) {
      trackMixpanelEvent(
        'Invoice - Preview error - No company info',
        mixpanelProperties,
      );
      return setDisplayNoCompanyAlert(true);
    }
    setIsLoadingPreview(true);
    const invoice = await getInvoice();
    let pdfWindow = window.open('');
    pdfWindow &&
      pdfWindow.document.write(
        '<html<head><title>' +
          `invoice_${order.order_id}_${order._id}.pdf` +
          '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>',
      );
    pdfWindow &&
      pdfWindow.document.write(
        "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(invoice.data.data) +
          "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>",
      );
    trackMixpanelEvent('Invoice - Preview', mixpanelProperties);
    setIsLoadingPreview(false);
  };

  const downloadInvoice = async () => {
    if (!currentCompanyData) {
      trackMixpanelEvent(
        'Invoice - Download error - No company info',
        mixpanelProperties,
      );
      return setDisplayNoCompanyAlert(true);
    }
    setIsLoadingDownload(true);
    const invoice = await getInvoice();
    let a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + invoice.data.data;
    a.download = `invoice_${order.order_id}_${order._id}.pdf`;
    a.click();
    trackMixpanelEvent('Invoice - Download', mixpanelProperties);
    setIsLoadingDownload(false);
  };

  return (
    <div className="!shadow-none">
      <AlertBeforeAction
        title={t('settings.company.missing_info_title')}
        description={t('settings.company.missing_info_description')}
        successLabel={t('complete')}
        successAction={() =>
          navigate(`/settings/workspaces/${currentWorkspace?._id}`)
        }
        isVisible={displayNoCompanyAlert}
        setIsVisible={setDisplayNoCompanyAlert}
      />
      {!displayRefundAlert ? null : (
        <AlertBeforeAction
          title={t('are_you_sure')}
          description={alertDescription}
          successAction={onRefundConfirm}
          isVisible={displayRefundAlert}
          setIsVisible={setDisplayRefundAlert}
        />
      )}

      <div className="py-2">
        <div className="flex flex-col text-left">
          <div className="flex flex-col gap-4 justify-center">
            <div className="text-xs">
              <div className="mr-2 font-bold underline text-[#160042]">
                {t('orders.order_id')}:
              </div>
              <div className="text-gray-600">{order._id}</div>
            </div>
          </div>
        </div>
        <div className="flex text-left mt-3 items-center">
          <div className="font-bold mr-2 text-xs text-[#160042]">
            {t('cozyup_fee')}:
          </div>
          <div>
            {displayPrice(order.amountFeeHost, currency.symbol, {
              notCents: false,
              thousandSeparator: ' ',
            })}
          </div>
        </div>
        {!order.comment ? null : (
          <div className="flex flex-col mt-4 text-left">
            <div className="font-bold mr-2 text-xs text-[#160042]">
              {t('orders.comment')}:
            </div>
            <p>{order.comment}</p>
          </div>
        )}
        {order.isStayExtension ? (
          <div className="mt-4">
            <div className="mb-2 font-bold text-black text-left">
              {t('booking_success.extended_stay')}
            </div>
            {order?.stayExtensions?.map((stayExtension: any) => (
              <div
                key={stayExtension._id}
                className="flex gap-4 justify-between w-full"
              >
                <div className="text-left flex flex-col gap-1">
                  <div className="flex gap-3">
                    <span className="">
                      {t('booking_success.origin_reservation')} :
                    </span>
                    <span className="text-weight-700">
                      {stayExtension.reservationId}
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <span className="">
                      {t('booking_success.new_reservation')} :
                    </span>
                    <span className="text-weight-700">
                      {stayExtension.newReservationId}
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <span className="">{t('booking_success.name')} :</span>
                    <span>{stayExtension.guestName}</span>
                  </div>
                  <div className="flex gap-3">
                    <span className="">
                      {t('booking_success.number_guests')} :
                    </span>
                    <span>{stayExtension.totalGuests}</span>
                  </div>
                </div>
                <div className="inline-flex gap-6 items-center text-lg">
                  <div>
                    <div className="text-left">
                      <span className="text-sm text-grey">
                        {' '}
                        {t('booking_success.arrival')} :
                      </span>
                    </div>
                    <div>
                      <span>
                        {new Date(
                          stayExtension.newArrivalDate,
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-left">
                      <span className="line-through text-sm text-grey">
                        {t('booking_success.departure')} :
                      </span>
                    </div>
                    <div>
                      <span className="line-through text-grey">
                        {new Date(
                          stayExtension.originalDepartureDate,
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <span>
                    <FontAwesomeIcon className="text-green" icon={faRight} />
                  </span>
                  <div>
                    <div className="text-left">
                      <span className="text-sm text-green">
                        {t('booking_success.new_departure')} :
                      </span>
                    </div>
                    <div>
                      <span>
                        {new Date(
                          stayExtension.newDepartureDate,
                        ).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {order.products?.map((product: any, index: number) => (
              <div
                key={index}
                className="flex mt-6 gap-4 justify-between w-full"
              >
                <div className="text-left flex items-start">
                  <Emoji
                    emoji={product.item.emoji || 'grey_question'}
                    size={25}
                  />
                </div>
                <div className="text-left flex flex-auto items-start flex-col">
                  <div className="text-left font-bold">
                    {(order.status === 'confirmed' && !product.isValidated) ||
                    order.status === 'cancelled' ? (
                      <span>
                        <del>
                          {product.item.translations?.filter(
                            (t: any) =>
                              t.languageCode === i18n.language.slice(0, 2),
                          ).length
                            ? product.item.translations.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              )[0].title
                            : product.item.name}
                        </del>
                      </span>
                    ) : (
                      <span>
                        {product.item.translations?.filter(
                          (t: any) =>
                            t.languageCode === i18n.language.slice(0, 2),
                        ).length
                          ? product.item.translations.filter(
                              (t: any) =>
                                t.languageCode === i18n.language.slice(0, 2),
                            )[0].title
                          : product.item.name}
                      </span>
                    )}
                  </div>
                  <div className="text-left flex items-center text-xs gap-2">
                    {product.quantity} x{' '}
                    {
                      <WithPermissions
                        rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                      >
                        <div className="text-left flex items-center text-xs">
                          {(order.status === 'confirmed' &&
                            !product.isValidated) ||
                          order.status === 'cancelled' ? (
                            <span>
                              <del>
                                {displayPrice(
                                  product.item.sellingPrice,
                                  currency.symbol,
                                  {
                                    thousandSeparator: ' ',
                                  },
                                )}
                              </del>
                            </span>
                          ) : (
                            <span>
                              {displayPrice(
                                product.item.sellingPrice,
                                currency.symbol,
                                {
                                  thousandSeparator: ' ',
                                },
                              )}
                            </span>
                          )}
                        </div>
                      </WithPermissions>
                    }
                  </div>
                </div>

                <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                  <div
                    className="text-center flex items-center justify-center"
                    style={{ width: '20%' }}
                  >
                    {product.isValidated
                      ? '✅'
                      : order.paymentStatus === 'waiting_validation'
                      ? '⏳'
                      : '❌'}
                  </div>
                </WithPermissions>
              </div>
            ))}
          </>
        )}
      </div>
      <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
        <div className="mt-4 border-t border-grey pt-4">
          {order.paymentStatus === 'waiting_validation' &&
            order.status === 'pending' && (
              <div className="flex gap-3 items-center flex-col justify-center">
                <span className="first-letter:uppercase py-1 border border-orange px-3 bg-lightOrange  text-orange font-semibold rounded-2xl">
                  <span className="ml-2">&#9888;&#65039; </span>
                  {calculateDaysRemaining(order.createdAt, 7)}{' '}
                </span>
                {requestValidation && (
                  <button
                    className="btn btn-primary w-full justify-center py-3"
                    onClick={() => requestValidation(order)}
                  >
                    {t('orders.validate-order')}
                  </button>
                )}
              </div>
            )}
          {order.paymentStatus === 'paid' && (
            <div className="flex justify-center flex-col">
              <div className="mb-4 flex justify-end">
                {requestRefund && (
                  <button
                    className="flex btn my-0 py-3 items-center justify-center rounded-2xl btn-primary-outlined w-full text-center"
                    onClick={() => alertRefund()}
                  >
                    {t('orders.refund')}
                  </button>
                )}
              </div>
              {!order.isStayExtension && (
                <>
                  <div className="ml-2 font-bold underline text-[#160042]">
                    <span className=""> {t('invoice')}</span>
                  </div>
                  <div className="flex">
                    <button
                      className="flex flex-1 btn py-3 items-center justify-center rounded-2xl btn-primary-outlined"
                      onClick={() => previewInvoice()}
                    >
                      {t('preview')}
                      {!isLoadingPreview ? null : (
                        <FontAwesomeIcon
                          style={{ marginLeft: 15 }}
                          icon={faSpinnerThird}
                          spin
                        />
                      )}
                    </button>
                    <button
                      className="flex flex-1 justify-center  items-center btn py-3 rounded-2xl btn-primary"
                      onClick={() => downloadInvoice()}
                    >
                      {t('download')}
                      {!isLoadingDownload ? null : (
                        <FontAwesomeIcon
                          style={{ marginLeft: 15 }}
                          icon={faSpinnerThird}
                          spin
                        />
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </WithPermissions>
    </div>
  );
};

export default OrderDetailsMobile;
