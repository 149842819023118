import React from 'react';
import OnboardingDesign from '../../assets/images/onboarding_design.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
// import { OnboardingContext } from '../layout/Layout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/useStore';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../contexts/authContext';
import i18n from '../../i18n';

type Props = {
  visible: boolean;
  setVisible: any;
};

export const REDIRECT_PATH: any = {
  checkoutAreas: {
    path: '/checkout-areas/new',
    helpUrl: {
      fr: 'https://help.cozyup.com/guide-de-demarrage/comment-creer-une-boutique',
      en: 'https://en.help.cozyup.com/guide-de-demarrage/comment-creer-une-boutique',
    },
  },
  homes: {
    path: '/homes',
    helpUrl: {
      fr: 'https://help.cozyup.com/guide-de-demarrage/comment-ajouter-un-logement',
      en: 'https://en.help.cozyup.com/guide-de-demarrage/comment-ajouter-un-logement',
    },
    state: {
      displayAddModal: true,
    },
  },
  orders: {
    path: '/homes',
    helpUrl: {
      fr: 'https://help.cozyup.com/guide-de-demarrage/ou-et-comment-proposer-son-lien-de-vente-dextra-cozyup',
      en: 'https://en.help.cozyup.com/guide-de-demarrage/ou-et-comment-proposer-son-lien-de-vente-dextra-cozyup',
    },
  },
  products: {
    path: '/products',
    helpUrl: {
      fr: 'https://help.cozyup.com/guide-de-demarrage/comment-ajouter-un-extra',
      en: 'https://en.help.cozyup.com/guide-de-demarrage/comment-ajouter-un-extra',
    },
    state: {
      displayTemplates: true,
    },
  },
  stripeConnect: {
    path: '/settings/workspaces',
    helpUrl: {
      fr: 'https://help.cozyup.com/guide-de-demarrage/compte-bancaire',
      en: 'https://en.help.cozyup.com/guide-de-demarrage/compte-bancaire',
    },
  },
};

const QuickStart = ({ visible, setVisible }: Props) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );

  const navigate = useNavigate();

  const R = 36;
  const C = Math.PI * R * 2;
  const P = ((100 - onboardingStatus?.meta?.progression) / 100) * C;

  const handleRedirectToHelp = (currentTask: any) => {
    const browserLang = i18n.language.split('-')[0];
    const helpLang = i18n.languages.includes(browserLang) ? browserLang : 'en';
    window.open(currentTask?.helpUrl[helpLang], '_blank');
  };

  return !visible ? null : (
    <div className="hidden lg:flex">
      <div className="fixed z-50 w-[450px] bg-white bottom-0 flex-col text-left top-0 left-[240px] border-r-[1px] border-grey">
        <div className="flex flex-col grow overflow-auto relative h-full">
          <div
            onClick={() => setVisible(false)}
            className="cursor-pointer absolute right-2 top-2 z-50 text-[24px] "
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-black right-0 text-[24px] opacity-75"
            />
          </div>
          {/*  IMAGES TOP RIGHT*/}
          <img
            src={OnboardingDesign}
            className="absolute top-0 right-0 w-[60%] h-auto"
          />

          {/*  BLOCK 1 - PROGRESS BAR & TITLES*/}
          <div className="relative grid grid-cols-2 grid-rows-1 gap-[16px] mt-[90px] mx-[30px]">
            <svg height={80} width={80}>
              <circle
                strokeWidth="8"
                stroke="#e3e4ff"
                fill="none"
                style={{
                  transition: 'stroke 100ms ease 0s',
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%',
                }}
                r={R}
                cx="40"
                cy="40"
              />
              <circle
                strokeWidth="8"
                strokeLinecap="round"
                strokeDasharray={C}
                strokeDashoffset={P.toString()}
                stroke="#7e82e5"
                fill="none"
                style={{
                  transform: 'rotate(-90deg)',
                  transformOrigin: '50% 50%',
                  transition:
                    'stroke-dashoffset 200ms ease 0s, stroke 100ms ease 0s',
                }}
                r={R}
                cx="40"
                cy="40"
              />
              <foreignObject height="100%" width="100%">
                <div className="absolute flex items-center justify-center h-full w-full text-[26px]">
                  {onboardingStatus?.meta?.remainingOnboardingTasks > 0 ? (
                    onboardingStatus?.meta?.remainingOnboardingTasks
                  ) : (
                    <FontAwesomeIcon icon={faCheck} color="#7e82e5" />
                  )}
                </div>
              </foreignObject>
            </svg>
            <div className="col-end-8">
              <h3 className="text-[26px] text-black">
                {t('onboarding.title')}
              </h3>
              <div className="text-greyText">{t('onboarding.description')}</div>
            </div>
          </div>
          {/*  BLOCK 2- STEPS */}
          <div className="grid gap-[8px] mx-[30px] mt-[8px] mb-[30px]">
            <div className="flex text-primary text-[11px] uppercase font-satoshiBold font-bold leading-none mt-[20px]">
              {t('onboarding.steps')} (10-15 min)
            </div>
            {/* BLOCK 2-1 - TO DO STEPS */}
            {!onboardingStatus.data
              ? null
              : Object.entries(onboardingStatus.data)
                  // .filter(([k]) => !onboardingStatus.data[k])
                  .map((obj: any, index: number) => {
                    return (
                      <div
                        key={obj[0]}
                        className="border border-lightblue bg-lightblue rounded-[8px] flex items-stretch flex-col text-left relative py-[16px] px-[20px]"
                      >
                        <div
                          className={`gap-[8px] text-black items-center font-semibold flex ${
                            !obj[1] ? '' : 'opacity-50'
                          }`}
                        >
                          {!obj[1] ? (
                            index + 1 + ' - '
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="#7e82e5"
                              className="z-10"
                            />
                          )}{' '}
                          {t(`onboarding.${obj[0]}.title`)}{' '}
                          {t(`onboarding.${obj[0]}.time`)}
                        </div>
                        {!obj[1] && (
                          <div>
                            <p className="text-[12px] text-grey mt-[4px] mx-0 mb-0">
                              {t(`onboarding.${obj[0]}.description`)}
                            </p>
                            <div className="flex justify-end items-end mt-[12px]">
                              <button
                                className="inline-block rounded-[32px] font-semibold border-1 border-primary border h-[32px] leading-1 py-[8px] px-[12px] cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  trackMixpanelEvent(
                                    `Onboarding - Open help ${obj[0]}`,
                                    {
                                      ...onboardingStatus?.meta,
                                      ...onboardingStatus?.data,
                                      workspace_id: currentWorkspace._id,
                                      account_id: currentAccount._id,
                                      account_name: currentAccount.name,
                                    },
                                  );
                                  // @ts-ignore
                                  handleRedirectToHelp(REDIRECT_PATH[obj[0]]);
                                }}
                              >
                                <span className="h-full flex text-primary items-center justify-center whitespace-nowrap">
                                  {t(`onboarding.help`)}
                                </span>
                              </button>
                              <button
                                className="ml-2 inline-block rounded-[32px] font-semibold bg-primary border-1 border-primary h-[32px] leading-1 py-[8px] px-[12px] cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setVisible(false);
                                  trackMixpanelEvent(
                                    `Onboarding - Start ${obj[0]}`,
                                    {
                                      ...onboardingStatus?.meta,
                                      ...onboardingStatus?.data,
                                      workspace_id: currentWorkspace._id,
                                      account_id: currentAccount._id,
                                      account_name: currentAccount.name,
                                    },
                                  );

                                  {
                                    obj[0] === 'stripeConnect'
                                      ? navigate(
                                          `/settings/workspaces/${currentWorkspace._id}`,
                                        )
                                      : navigate(REDIRECT_PATH[obj[0]].path, {
                                          state: REDIRECT_PATH[obj[0]]?.state,
                                        });
                                    // @ts-ignore
                                  }
                                }}
                              >
                                <span className="h-full flex text-white items-center justify-center whitespace-nowrap">
                                  {t(`onboarding.start`)}
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStart;
