import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

const ImagePreview = ({ isVisible, setIsVisible, pictures }: any) => {
  const { t } = useTranslation();
  const onClose = () => {
    setCurrentIndex(0);
    setIsVisible(false);
  };

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  const handleChangePicture = (index: number) => {
    if (index < 0 || index + 1 > pictures.length) {
      return;
    }
    setCurrentIndex(index);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        handleChangePicture(currentIndexRef.current - 1);
        break;
      case 'ArrowRight':
        handleChangePicture(currentIndexRef.current + 1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Attacher l'écouteur d'événements lors du montage du composant
    document.addEventListener('keydown', handleKeyDown);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Le tableau de dépendances vide signifie que cet effet ne s'exécute qu'au montage et au démontage

  return (
    <Transition show={isVisible} appear as={Fragment}>
      <Dialog onClose={() => onClose()} className="relative z-50 ">
        <div className="fixed inset-0 bg-[#000]/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex w-screen items-center justify-center lg:p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="bg-[#000] h-full w-full lg:w-[60%] lg:h-[100%] flex lg:rounded-xl flex-col">
              <div className="flex justify-between p-4">
                <div className="h-5 w-5"></div>
                <div className="text-white">
                  <span className="text-lg">
                    {currentIndex + 1} / {pictures.length}
                  </span>
                </div>

                <button type="button" className="text-white" onClick={onClose}>
                  <FontAwesomeIcon size="2x" icon={faClose} color="#fff" />
                </button>
              </div>
              <div className="flex h-full w-full items-center justify-center ">
                <div className="relative h-full w-full">
                  <div className="relative flex items-center w-full h-full">
                    <div className="hidden lg:flex justify-center items-center w-[50px]">
                      <div
                        className={
                          ' text-white cursor-pointer p-2 ' +
                          (currentIndex === 0 ? 'invisible' : 'visible')
                        }
                        onClick={() => handleChangePicture(currentIndex - 1)}
                      >
                        <FontAwesomeIcon
                          size="3x"
                          icon={faChevronLeft}
                          color="#A2A2A2"
                        />
                      </div>
                    </div>
                    {pictures.length && (
                      <div className="relative w-full h-auto flex flex-1 justify-center items-center">
                        {pictures.map((picture: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className={
                                'absolute top-0 left-0 w-full h-full flex items-center justify-center ' +
                                (index === currentIndex ? 'visible' : 'hidden')
                              }
                            >
                              <ImageLoader
                                src={picture.url}
                                alt={'extra-' + index}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <div className="hidden lg:flex justify-center items-center w-[50px]">
                      <div
                        className={
                          'text-white lg:text-primary  cursor-pointer p-2 ' +
                          (currentIndex === pictures.length - 1
                            ? 'invisible'
                            : 'visible')
                        }
                        onClick={() => handleChangePicture(currentIndex + 1)}
                      >
                        <FontAwesomeIcon
                          size="3x"
                          icon={faChevronRight}
                          color="#A2A2A2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full lg:hidden mb-10 gap-4">
                <div className="flex-1 flex justify-center items-center">
                  <div
                    className={
                      'ml-6 flex-1 text-white lg:text-primary cursor-pointer p-2 border rounded-3xl border-white ' +
                      (currentIndex === 0 ? 'hidden' : '')
                    }
                    onClick={() => handleChangePicture(currentIndex - 1)}
                  >
                    {t('previous')}
                  </div>
                </div>
                <div className="flex-1 flex justify-center items-center">
                  <div
                    className={
                      'mr-6 flex-1 text-white lg:text-primary  cursor-pointer p-2 border rounded-3xl border-white ' +
                      (currentIndex === pictures.length - 1 ? 'hidden' : '')
                    }
                    onClick={() => handleChangePicture(currentIndex + 1)}
                  >
                    {t('next')}
                  </div>
                </div>
              </div>
              <div className="lg:flex w-full hidden mb-10 px-32">
                {pictures.length && (
                  <div className="relative w-full h-[100px] flex flex-1 justify-center items-center gap-2">
                    {pictures.map((picture: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={`relative w-[100px] h-[100px] cursor-pointer flex items-center ${
                            index == currentIndex
                              ? 'border-2 border-checkout'
                              : ''
                          }`}
                          onClick={() => handleChangePicture(index)}
                        >
                          <ImageLoader
                            src={picture.url}
                            alt={'extra-' + index}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImagePreview;

const ImageLoader = ({ src, alt }: { src: string; alt: string }) => {
  // État pour suivre si l'image est chargée
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative">
      {/* Affichez le loader si l'image n'est pas encore chargée */}
      {!loaded && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
      {/* L'élément img avec un gestionnaire onLoad pour mettre à jour l'état */}
      <img
        src={src}
        alt={alt}
        width={'100%'}
        height={'100%'}
        className={`object-contain w-full h-auto max-w-[650px] ${
          loaded ? 'block' : 'hidden'
        }`}
        onLoad={() => setLoaded(true)} // Mettez à jour l'état lorsque l'image est chargée
      />
    </div>
  );
};
