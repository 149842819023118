import { loadStripe } from '@stripe/stripe-js';
import i18n from '../i18n';

const apiKey: any = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripeConfig = (stripeConnectAccountId: string) =>
  loadStripe(apiKey, {
    stripeAccount: stripeConnectAccountId,
    locale: i18n.language,
  });

export default stripeConfig;
