import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Workspace } from '../../../../types/workspace.model';
import { useMutation } from 'react-query';
import { revokeWorkspaceAccess } from '../../../../api/workspaces';
import { useAuth } from '../../../../contexts/authContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddWorkspaceAccessModal } from '../../../modals/add-workspace-access-modal.component';
import { Icon, Tooltip } from '@mui/material';

interface EditUsersWorkspaceProps {
  workspace: Workspace;
  refresh: any;
}

export const EditUsersWorkspace = ({
  workspace,
  refresh,
}: EditUsersWorkspaceProps) => {
  const { currentUser, currentAccount } = useAuth();
  const [displayAddWorkspaceAccessModal, setDisplayAddWorkspaceAccessModal] =
    useState(false);
  const { mutate: deleteWorkspaceAccess } = useMutation(
    (workspaceAccessId: string) =>
      revokeWorkspaceAccess(
        currentUser.accessToken,
        workspace._id,
        workspaceAccessId,
      ),
    {
      onSuccess: () => {
        refresh();
      },
    },
  );

  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-between mb-6 w-full border-b py-2 border-gray-200 items-center">
        <h5 style={{ textAlign: 'left', margin: 0 }}>{'Accès'}</h5>
        <div className="flex">
          <div className="flex h-full items-end justify-end">
            <div
              onClick={() => setDisplayAddWorkspaceAccessModal(true)}
              className="btn btn-warning rounded-xl"
            >
              <div>{t('add')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col ">
        <div className="w-full">
          <table className="cozyup hidden lg:table ">
            <thead>
              <tr>
                <th>#</th>
                <th>{t('settings.user')}</th>
                <th>{t('role')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="mt-6">
              {workspace &&
                workspace.workspaceAccesses.map((wA, index) => (
                  <tr className="hover:shadow-none" key={wA._id}>
                    <td>{index + 1}</td>
                    <td>{wA?.user.email}</td>
                    <td>{wA?.role}</td>
                    <td className="">
                      {currentAccount.owner._id === wA.user._id ? null : (
                        <Tooltip title={`${t('delete')}`}>
                          <div
                            onClick={() => deleteWorkspaceAccess(wA._id)}
                            className="icon hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                          >
                            <Icon
                              className="h-10 w-10 text-red-500"
                              fontSize="small"
                              component={DeleteIcon}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <AddWorkspaceAccessModal
          account={currentAccount}
          workspace={workspace}
          displayModal={displayAddWorkspaceAccessModal}
          setDisplayModal={setDisplayAddWorkspaceAccessModal}
          refreshWorkspace={refresh}
        />
      </div>
    </div>
  );
};
