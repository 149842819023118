import React from 'react';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';

const Profile = () => {
  return (
    <TabWrapper>
      <>
        <TabContentHeader title={'Profile'} rootPage="/profiles" />
        This is the Profile
      </>
    </TabWrapper>
  );
};

export default Profile;
