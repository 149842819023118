import React, { useState } from 'react';

import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContent from '../../components/tab-content/tab-content.component';
import Pagination from '../../components/pagination/pagination.component';

import {
  generateCsv,
  getOrders,
  refundOrder,
  validateOrder,
} from '../../api/orders';

import { useAuth } from '../../contexts/authContext';
import { useMutation, useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { formatDateToLocaleDateString } from '../../utils/date';
import { useTranslation } from 'react-i18next';
import OrderRaw from '../../components/_main/orders/order-raw/order-raw';
import Loader from '../../components/loader/loader.component';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import {
  faCartArrowDown,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { WithPermissions } from '../../components/with-permissions/with-permissions.component';
import Button from '../../components/button/button.component';
import SelectRangeDatesModal from '../../components/modals/select-range-dates-modal.component';
import toast from 'react-hot-toast';
import AlertBeforeAction from '../../components/alert-before-action/alert-before-action.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { Order } from '../../types/order.model';
import ValidateOrderProductsModal from '../../components/modals/validate-order-products-modal.component';
import OrderDetailsMobile from '../../components/_main/orders/order-details/order-details-mobile.component';
import CURowText from '../../components/_main/CURowtText/cu-row-text';
import OrderTypeEmoji from '../../components/_main/order-type-emoji/order-type-emoji.constante';
import { SortOptions } from '../../types/generic-types';

const Orders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('_orderId') || null;

  const { currentUser, apiUser, currentWorkspace, currentAccount } = useAuth();
  const currentCompanyData = currentWorkspace?.company;
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [searchedOrder, setSearchedOrder] = useState(orderId ? orderId : '');
  const [selectedRow, setSelectedRow] = useState<Number>(-1);
  const [selectedOrderToValidate, setSelectedOrderToValidate] =
    useState<Order | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [activeSort, setActiveSort] = useState<SortOptions>({
    direction: '',
    field: '',
  });

  const [
    displayValidateOrderProductsModal,
    setDisplayValidateOrderProductsModal,
  ] = useState(false);

  const [displaySelectRangeDatesModal, setDisplaySelectRangeDatesModal] =
    useState(false);
  const [displayNoCompanyAlert, setDisplayNoCompanyAlert] = useState(false);

  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const {
    data: orders,
    refetch: refetchOrder,
    isLoading: isFetching,
  } = useQuery(
    [
      'ordersKey',
      activePage,
      currentUser,
      apiUser,
      activeSort,
      searchedOrder,
      currentWorkspace,
    ],
    // @ts-ignore
    () =>
      getOrders(
        currentUser.accessToken,
        currentWorkspace._id,
        activePage,
        searchedOrder.toLowerCase(),
        activeSort,
      ),
    {
      enabled: !!currentUser && !!apiUser && !!currentWorkspace,
      staleTime: Infinity,
      refetchOnWindowFocus: true,
    },
    { keepPreviousData: true },
  );
  const headerProps = {
    displaySearchBar: true,
    searchText: searchedOrder,
    setSearchText: setSearchedOrder,
  };

  const requestRefund = (orderId: Order) => {
    refund(orderId);
  };

  const sortOrders = (field: string, direction: string) => {
    if (activeSort.field === field) {
      return activeSort.direction === 'ASC'
        ? setActiveSort({
            field: field,
            direction: 'DESC',
          })
        : setActiveSort({ field: field, direction: 'ASC' });
    }
    setActiveSort({
      field: field,
      direction: direction,
    });
  };

  const openExpandTable = (i: number) => {
    if (i == selectedRow) {
      return setSelectedRow(-1);
    }
    setSelectedRow(i);
  };

  const { mutate: exportCsv, isLoading: isLoadingCsv } = useMutation(
    (data: any) => {
      return generateCsv(
        currentUser.accessToken,
        currentWorkspace._id,
        data.startDate,
        data.endDate,
      );
    },
    {
      onSuccess: async (response: any) => {
        // Créer un lien pour le téléchargement
        trackMixpanelEvent('Invoice - Export CSV success', mixpanelProperties);
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;

        a.download = 'cozyup-orders.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      onError: (error: any) => {
        console.error('error', error);
        if (error.status === 404) {
          toast.error(t('orders.export_csv_orders_not_found'));
        } else {
          toast.error(t('errors.unknown_error'));
        }
      },
    },
  );

  const handleOnGenerateCsv = (startDate: string, endDate: string) => {
    if (!currentCompanyData) {
      trackMixpanelEvent(
        'Invoice - Export CSV error - No company info',
        mixpanelProperties,
      );
      return setDisplayNoCompanyAlert(true);
    }
    setDisplaySelectRangeDatesModal(false);
    exportCsv({ startDate: startDate, endDate: endDate });
  };

  const { mutate: refund } = useMutation(
    (orderId: string) =>
      refundOrder(currentUser.accessToken, currentWorkspace._id, orderId),
    {
      onSuccess: () => {
        refetchOrder();
      },
      onError: () => {
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  const requestValidation = (order: Order) => {
    setSelectedOrderToValidate(order);
    setDisplayValidateOrderProductsModal(true);
  };

  const validateCurrentOrder = (validatedProducts: any) => {
    if (selectedOrderToValidate) {
      validate({
        _id: selectedOrderToValidate._id,
        products: validatedProducts,
      });
    } else {
      console.error('No order selected to validate');
    }
  };

  const {
    mutate: validate,
    isLoading: isLoadingValidate,
    isSuccess: isSuccessValidate,
  } = useMutation(
    (data: any) =>
      validateOrder(currentUser.accessToken, currentWorkspace._id, data),
    {
      onSuccess: () => {
        setDisplayValidateOrderProductsModal(false);
        setSelectedOrderToValidate(null);
        refetchOrder();
      },
      onError: () => {
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  return (
    <>
      <TabWrapper>
        <>
          {!displayNoCompanyAlert ? null : (
            <AlertBeforeAction
              title={t('settings.company.missing_info_title')}
              description={t('settings.company.missing_info_description')}
              successLabel={t('complete')}
              successAction={() =>
                navigate(`/settings/workspaces/${currentWorkspace?._id}`)
              }
              isVisible={displayNoCompanyAlert}
              setIsVisible={setDisplayNoCompanyAlert}
            />
          )}
          <TabContentHeader
            title={t('menu.orders')}
            description={t('menu.orders_description')}
            rootPage="/orders"
            {...headerProps}
          />

          {displayValidateOrderProductsModal && (
            <ValidateOrderProductsModal
              displayModal={displayValidateOrderProductsModal}
              setDisplayModal={setDisplayValidateOrderProductsModal}
              onSuccess={validateCurrentOrder}
              isLoadingValidation={isLoadingValidate}
              order={selectedOrderToValidate}
            />
          )}

          <TabContent>
            <>
              {displaySelectRangeDatesModal && (
                <SelectRangeDatesModal
                  setDisplayModal={setDisplaySelectRangeDatesModal}
                  onSubmit={handleOnGenerateCsv}
                />
              )}
              <div className=" hidden md:flex">
                {!orders || orders?.metadata?.totalPages === 0 ? null : (
                  <Pagination
                    totalPagesCount={orders?.metadata?.totalPages}
                    currentPage={orders?.metadata?.currentPage}
                    totalItems={orders?.metadata?.totalCount}
                    onChangePage={setActivePage}
                  />
                )}
                <div>
                  {orders && orders?.metadata?.totalCount > 0 && (
                    <Button
                      disabled={isLoadingCsv}
                      onClick={() => setDisplaySelectRangeDatesModal(true)}
                      className="btn btn-primary-outlined flex"
                    >
                      {t('orders.export_csv')}
                      {!isLoadingCsv ? null : (
                        <FontAwesomeIcon
                          style={{ marginLeft: 15 }}
                          icon={faSpinnerThird}
                          spin
                        />
                      )}
                    </Button>
                  )}
                </div>
              </div>
              <table className="cozyup hidden lg:table py-4">
                <thead>
                  <tr>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('order_id', 'ASC')}
                    >
                      {'#'} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th className="cursor-pointer">Type</th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('home.name', 'ASC')}
                    >
                      {t('menu.listings')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('purchaseDate', 'ASC')}
                    >
                      {t('orders.purchase_date')}{' '}
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('deliveryDate', 'ASC')}
                    >
                      {t('orders.delivery_date')}{' '}
                      <FontAwesomeIcon icon={faSort} />
                    </th>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('customer', 'ASC')}
                    >
                      {t('orders.customer')} <FontAwesomeIcon icon={faSort} />
                    </th>
                    <WithPermissions
                      rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                    >
                      <th
                        className="cursor-pointer"
                        onClick={() => sortOrders('totalAmount', 'ASC')}
                      >
                        {t('orders.total_order')}{' '}
                        <FontAwesomeIcon icon={faSort} />
                      </th>
                    </WithPermissions>
                    <th
                      className="cursor-pointer"
                      onClick={() => sortOrders('paymentStatus', 'ASC')}
                    >
                      {t('orders.status')} <FontAwesomeIcon icon={faSort} />
                    </th>
                  </tr>
                </thead>
                {isFetching ? null : (
                  <tbody>
                    {!orders
                      ? null
                      : orders.items.map((order: any, index: number) => {
                          return (
                            <OrderRaw
                              order={order}
                              refresh={refetchOrder}
                              refund={refund}
                              key={index}
                              isLoadingValidationOrder={isLoadingValidate}
                              validateOrder={validate}
                              isSuccessValidateOrder={isSuccessValidate}
                              openOrderId={orderId && orderId}
                              requestValidation={requestValidation}
                              requestRefund={requestRefund}
                            />
                          );
                        })}
                  </tbody>
                )}
              </table>
              <div className="lg:hidden">
                <div className="flex flex-col mt-6">
                  {!orders
                    ? null
                    : orders.items.map((order: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className="rounded-xl border flex flex-col p-3 mb-6 bg-lightblue shadow-xl"
                          >
                            <div>
                              <div className="flex justify-between items-center">
                                <div>
                                  <span className="mx-1">
                                    #{order.order_id + 1}
                                  </span>
                                </div>

                                <div className="flex justify-center items-center gap-1">
                                  <OrderStatusRaw
                                    paymentStatus={order.paymentStatus}
                                  />
                                  <OrderTypeEmoji
                                    isStayExtension={order?.isStayExtension}
                                  />
                                </div>
                              </div>
                              <div className="mt-4 flex justify-start">
                                <span className="font-bold text-lg first-letter:capitalize text-left">
                                  {order.home.name}
                                </span>
                              </div>
                              <div className="my-4 text-xs text-left">
                                <CURowText
                                  label={t('orders.delivery_date') + ':'}
                                  value={formatDateToLocaleDateString(
                                    order.deliveryDate,
                                  )}
                                />
                                <CURowText
                                  label={t('orders.purchase_date') + ':'}
                                  value={formatDateToLocaleDateString(
                                    order.purchaseDate,
                                  )}
                                />
                                <CURowText
                                  label={t('orders.customer') + ':'}
                                  value={order.customer}
                                />
                                <CURowText
                                  label={t('orders.total_order') + ':'}
                                  value={displayPrice(
                                    order.totalAmount,
                                    getCurrencyInfo(order.currency).symbol,
                                    {
                                      thousandSeparator: ' ',
                                    },
                                  )}
                                />
                              </div>
                            </div>

                            <div
                              className="flex justify-center mt-2 cursor-pointer btn btn-primary-ghost"
                              onClick={() => openExpandTable(index)}
                            >
                              <span className="text-md font-semibold tracking-wide">
                                {selectedRow === index ? '-' : '+'}{' '}
                                {t('orders.details')}
                              </span>
                            </div>
                            {selectedRow !== index ? null : (
                              <OrderDetailsMobile
                                key={order._id}
                                currency={order.currency}
                                requestRefund={requestRefund}
                                order={order}
                                requestValidation={requestValidation}
                              />
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
              {!isFetching ? null : (
                <div className="flex justify-center items-center height-[150px]">
                  <Loader />
                </div>
              )}
              {!orders || orders?.metadata?.totalPages === 0 ? null : (
                <Pagination
                  totalPagesCount={orders?.metadata?.totalPages}
                  currentPage={orders?.metadata?.currentPage}
                  totalItems={orders?.metadata?.totalCount}
                  onChangePage={setActivePage}
                />
              )}
              <div className="bg-black text-white">{orders?.length}</div>

              {orders?.items?.length || isFetching ? null : (
                <div className="flex-row mt-10">
                  <FontAwesomeIcon
                    className="opacity-20 mb-4"
                    color="#b6b9ce"
                    icon={faCartArrowDown}
                    size="7x"
                  />
                  <div className="text-sm font-semibold text-[#6B7194] opacity-20">
                    {t('tables.empty-orders')}
                  </div>
                </div>
              )}
            </>
          </TabContent>
        </>
      </TabWrapper>
    </>
  );
};

type OrderStatusRawProps = {
  paymentStatus: string;
};

const OrderStatusRaw = ({ paymentStatus }: OrderStatusRawProps) => {
  const { t } = useTranslation();
  return (
    <>
      {paymentStatus === 'paid' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGreen  text-green font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
      {paymentStatus === 'refunded' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGrey  text-grey font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
      {paymentStatus === 'waiting_validation' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightOrange  text-orange font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}{' '}
          <span className="ml-4">&#9888;&#65039; </span>
        </span>
      )}
      {paymentStatus === 'unpaid' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGrey  text-grey font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
    </>
  );
};

export default Orders;
