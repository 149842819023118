import React from 'react';
import './select.styles.scss';
import ErrorMessage from '../error-message/error-message.component';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: string;
  errors?: string[];
  placeholder?: string;
  textInputStyle?: any;
  labelTextStyle?: any;
  selectLabel?: string;
  containerStyle?: any;
  disabled?: boolean;
  onChange?: any;
  value?: string;
  options: any[];
  label?: string;
  labelStyle?: string;
  activeColor?: string;
  activeLabelColor?: string;
  onPress?: any;
  onFocus?: any;
  onBlur?: any;
  tooltip?: string;
  helperText?: string;
}

const Select = ({
  error,
  errors,
  placeholder,
  disabled,
  label,
  value,
  options,
  style,
  labelStyle,
  selectLabel,
  onChange,
  helperText,
  tooltip,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const inputRef = React.useRef(null);

  return (
    <div className="flex flex-wrap flex-col mb-6 text-left" style={style}>
      <div className="w-full flex flex-row items-center mb-[2px]">
        <label
          className={`text-left ${labelStyle ? labelStyle : 'text-[#6B7194]'}`}
        >
          {label}
        </label>
        {tooltip && (
          <Tooltip
            title={tooltip}
            className="ml-1"
            enterTouchDelay={0}
            disableHoverListener={false}
          >
            <Icon
              className="icon text-gray-300 cursor-pointer opacity-75"
              component={HelpOutline}
            />
          </Tooltip>
        )}
      </div>
      <select
        disabled={disabled}
        className={`w-full text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none px-3 bg-white border-gray-400 rounded-3xl mb-0 h-10 flex ${
          disabled
            ? ' opacity-50 hover:border-gray-400 pointer-events-none'
            : ''
        }`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={inputRef}
        defaultValue={undefined}
        {...props}
      >
        <option value="">-- {selectLabel || t('select')} --</option>
        {options?.map((option: any) => (
          <option key={option.key} value={option.key}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <ErrorMessage key={error} error={error} />}
      {errors &&
        errors.map((err: string) => {
          if (err) {
            return <ErrorMessage key={err} error={err} />;
          }
        })}
      {!helperText || error || (errors && errors[0]) ? null : (
        <span className="text-left ml-2 mt-2 text-xs text-middleGrey font-semibold">
          {helperText}
        </span>
      )}
    </div>
  );
};

Select.defaultProps = {
  style: { width: '100%' },
};

export default Select;
