import React from 'react';
import { useTranslation } from 'react-i18next';
import useTrackMixpanelEvent from '../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../../contexts/authContext';
import { Home } from '../../../types/home.model';

type QRCodeModalProps = {
  home: Home;
  image: any;
  isModalOpen: boolean;
  setIsModalOpen: any;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({
  home,
  image,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { t } = useTranslation();
  const { currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);

  const mixpanelProperties = {
    home_id: home._id,
    home_name: home.name,
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  return !isModalOpen ? null : (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-[#0000007F] flex items-center justify-center">
      <div className="bg-white p-8 rounded-[16px] shadow-lg w-3/5 relative">
        <h1 className="font-satoshiBold text-2xl text-center mb-3">
          {t('listings.qr_code_title')}
        </h1>
        <p className="font-sans text-[16px] leading-6 font-semibold tracking-tighter text-greyText mb-8">
          {t('listings.qr_code_description')}
        </p>
        {image && (
          <img
            src={image}
            alt="Generated QR Code"
            style={{ borderRadius: 12 }}
          />
        )}

        <div className="mt-4 flex justify-center">
          <button
            type="button"
            className="btn rounded-3xl btn-primary-outlined"
            onClick={() => {
              trackMixpanelEvent('QR Code - Close modal', mixpanelProperties);
              setIsModalOpen(false);
            }}
          >
            {t('close')}
          </button>
          <button
            className="btn rounded-3xl btn-primary"
            onClick={() => {
              trackMixpanelEvent('QR Code - Download', mixpanelProperties);
              const a = document.createElement('a');
              a.href = image!;
              a.download = 'generated-image.png';
              a.click();
            }}
          >
            {t('download')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
