import { api } from '../utils/request';

export const createReservation = async (data: any) => {
  try {
    const res = await api.post(
      `/public/reservations`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const confirmReservation = async (data: any) => {
  try {
    const res = await api.patch(
      `/public/reservations`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getAvailabilities = async (
  reservationId: string | null,
  homeId: string | null,
) => {
  try {
    const res = await api.get(
      `/public/reservations/${reservationId}/get-availabilities`,
      {
        headers: {
          ...api.defaults.headers.common,
        },
        params: {
          homeId: homeId,
        },
      },
    );

    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};
