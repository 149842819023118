import { api } from '../utils/request';
import { Home } from '../types/home.model';
import { OnboardingStatusUpdate } from '../types/onboarding.model';

export const createHomes = async (
  authToken: string,
  workspaceId: string,
  data: Home[],
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/onboarding/homes`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const enableAutomaticMessages = async (
  authToken: string,
  workspaceId: string,
  data: {
    beforeArrivalEnabled: boolean;
    afterReservationEnabled: boolean;
    automaticMessageEnabled: boolean;
  },
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/onboarding/automatic-messages`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getCheckoutPage = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/onboarding/checkout-page`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createCheckoutPage = async (
  authToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/onboarding/checkout-page`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateCheckoutPage = async (
  authToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/onboarding/checkout-page`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateOnboardingStatus = async (
  authToken: string,
  workspaceId: string,
  data: OnboardingStatusUpdate,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/onboarding/update-status`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
