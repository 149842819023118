// night-upsells/night-upsells.constantes.ts
export const minNightsAvailableOptions = [
  {
    label: 'time.night_count',
    value: 1,
  },
  {
    label: 'time.night_count',
    value: 2,
  },
  {
    label: 'time.night_count',
    value: 3,
  },
];

export const delayNotificationOptions = [
  {
    label: 'time.day_count',
    value: 1,
  },
  {
    label: 'time.day_count',
    value: 2,
  },
  {
    label: 'time.day_count',
    value: 3,
  },
];
