export const Colors: { [key: string]: string } = {
  primary: '#7e82e5',
  primaryShadeHover: '#cbcfffcd',
  lightPrimary: '#e3e4ff',
  lightblue: '#F2F7FA',
  white: '#FFF',
  grey: '#b6b9ce',
  greyText: '#5a6978',
  greyTextDarker: '#333333',
  borderGrey: '#e5e8ec',
  middleGrey: '#d3d3d3',
  textDesc: '#a2a2a2',
  black: '#190f34',
  nightBlue: '#160145',
  lightGrey: '#f1f1f1',
  checkout: '#f36',
  lightOrange: '#fef0e8',
  error: '#e57373',
  orange: '#fe813c',
  lightGreen: '#c6f8b8',
  green: '#50d05a',
  lightYellow: '#f8edb8',
  yellow: '#d0b750',
  transparentWhite: '#ffffff0d',
  checkoutPrimary: '#ff3366',
};
