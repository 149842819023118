import { QrCode2Sharp } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import LinkIcon from '@mui/icons-material/Link';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Icon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getChannelManagerConnectionByHomeId } from '../../../../api/channelManagers';
import { remove, upload } from '../../../../api/files';
import {
  getHomeById,
  patchStatusHome,
  updateHome,
} from '../../../../api/homes';
import { useAuth } from '../../../../contexts/authContext';
import useQRCodeGenerator from '../../../../hooks/useQRCodeGenerator';
import { Home } from '../../../../types/home.model';
import { resizeImage } from '../../../../utils/image';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import QRCodeModal from '../../../modals/qr-code-modal/qr-code-modal.component';
import QRCodeCaneva from '../../../qrcode-caneva/qrcode-caneva.component';
import AddressCard from '../address-card/address-card.component';
import HomeCheckoutArea from '../home-checkout-area/home-checkout-area.component';
import HomeInformations from '../home-informations/home-informations.component';
import HomeAutomations from '../home-automations/home-automations.component';
import HomeStripeAccount from '../home-stripe-account/home-stripe-account.component';

type HomeDetailsProps = {
  setSubRoute: Function;
  refreshHomes: Function;
};
const HomeDetails: React.FC<HomeDetailsProps> = ({
  setSubRoute,
  refreshHomes,
}) => {
  const { t } = useTranslation();
  let { id } = useParams();
  const [disabledHomeInformations, setDisabledHomeInformations] =
    useState(true);
  const { currentUser, currentWorkspace } = useAuth();
  const [displayChangeStatusAlert, setDisplayChangeStatusAlert] =
    useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const { generateQRCode, imageSrc, isModalOpen, setIsModalOpen } =
    useQRCodeGenerator();

  const [customIdError, setCustomIdError] = useState<string | null>(null);
  const getCheckoutUrl = (home: Home, previewMode?: boolean) => {
    if (home.customId) {
      return (
        document.location.origin +
        `/cid/${home.customId}${previewMode ? '?preview=true' : ''}`
      );
    }
    return (
      document.location.origin +
      `/c/${home._id}${previewMode ? '?preview=true' : ''}`
    );
  };

  const {
    refetch,
    isLoading,
    isFetching,
    data: currentHome,
  } = useQuery(
    ['homes', id],
    // @ts-ignore
    () => getHomeById(id),
    {
      enabled: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data: Home) => {
        setSubRoute(data.name);
      },
    },
  );

  const {
    refetch: refetchChannelManagerConnection,
    isFetching: isFetchingChannelManagerConnection,
    isLoading: isLoadingChannelManagerConnection,
    data: channelManagerConnection,
  } = useQuery(
    ['channelManagerConnection', id], // @ts-ignore
    () =>
      getChannelManagerConnectionByHomeId(
        currentUser.accessToken,
        currentWorkspace._id,
        currentHome?._id,
      ),
    {
      enabled: !!id && !!currentHome,
    },
  );

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  const removeFile = async (filename: string) => {
    const pictureUploaded = await remove(currentUser.accessToken, filename);
    return pictureUploaded;
  };

  const { mutate: updateCurrentHome } = useMutation(
    (data: Home) =>
      id && updateHome(currentUser.accessToken, currentWorkspace._id, id, data),
    {
      onSuccess: () => {
        refetch();
        refreshHomes();
        setDisabledHomeInformations(true);
        setCustomIdError(null);
      },
      onError(e: any) {
        setCustomIdError(null);
        if (e.type === 'HOME.CUSTOM_ID_EXISTS') {
          setCustomIdError(e.message);
        }
      },
    },
  );

  const uploadPicture = async (picture: any) => {
    try {
      const file = await uploadNewPicture(picture);
      return file;
    } catch (e: any) {
      console.error(e);
    }
  };

  const removeOldPicture = async (picture: any) => {
    try {
      const oldPicture = picture.substring(picture.lastIndexOf('/') + 1);
      await removeFile(oldPicture);
    } catch (e) {
      console.error(e);
    }
  };

  const onHomeDetailsChange = async (data: any) => {
    if (data.picture) {
      const resizedFile = await resizeImage(data.picture[0]);
      if (resizedFile) {
        const fileUploaded = await uploadPicture(resizedFile);
        if (fileUploaded) {
          data.picture = fileUploaded.url;
        }
        if (currentHome.picture) {
          await removeOldPicture(currentHome.picture);
        }
        updateCurrentHome(data);
      } else {
        throw new Error('Error while resizing image');
      }
    } else {
      updateCurrentHome(data);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  const handleChangeStatus = async (action: string) => {
    switch (action) {
      case 'activate': {
        return changeStatusCurrentHome();
      }
      case 'archive': {
        setAlertDescription(
          t('listings.listing_action', {
            action: t(`listings.${action}`),
            home: currentHome.name,
          }),
        );
        return setDisplayChangeStatusAlert(true);
      }
      default:
        console.error('Unexpected behaviour');
    }
  };

  const changeStatusCurrentHome = async () => {
    const status = currentHome.status == 'archived' ? 'active' : 'archived';
    await patchStatusHome(
      currentUser.accessToken,
      currentWorkspace._id,
      currentHome._id,
      status,
    );
    refetch();
    refreshHomes();
  };
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);
  const copyUrl = (e: React.SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    if (!isCopiedUrl) {
      setIsCopiedUrl(true);
      navigator.clipboard.writeText(getCheckoutUrl(currentHome));
      setTimeout(() => {
        setIsCopiedUrl(false);
      }, 1000);
    }
  };

  return isLoading || isFetching ? null : (
    <div>
      <QRCodeModal
        home={currentHome}
        image={imageSrc}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <QRCodeCaneva
        url={getCheckoutUrl(currentHome)}
        homeName={currentHome.name}
      />
      <AlertBeforeAction
        title={t('are_you_sure')}
        description={alertDescription}
        successAction={changeStatusCurrentHome}
        isVisible={displayChangeStatusAlert}
        setIsVisible={setDisplayChangeStatusAlert}
      />
      {currentHome.checkoutArea ? null : (
        <div className="my-4 flex gap-6 text-left mr-6 items-center px-4 py-2 border border-orange text-orange bg-lightOrange font-bold  rounded-xl w-fit">
          <span className="text-xl">&#9888;&#65039; </span>
          {t('listings.no_checkoutarea')}
        </div>
      )}
      {currentHome.stripeConnectAccount ? null : (
        <div className="my-4 flex gap-6 text-left mr-6 items-center px-4 py-2 border border-orange text-orange bg-lightOrange font-bold  rounded-xl w-fit">
          <span className="text-xl">&#9888;&#65039; </span>
          {t('settings.stripe.not-linked')}
        </div>
      )}
      <div className="lg:flex">
        <div className="flex-1">
          <HomeInformations
            home={currentHome}
            updateHome={onHomeDetailsChange}
            isUpdateLoading={isLoadingPicture}
            disabled={disabledHomeInformations}
            setDisabled={setDisabledHomeInformations}
          />
          {channelManagerConnection?.channelManagerType
            ?.isAutomaticMessagesActive && (
            <HomeAutomations
              channelManagerConnection={channelManagerConnection}
              isFetching={isFetchingChannelManagerConnection}
              refresh={refetchChannelManagerConnection}
              isLoading={isLoadingChannelManagerConnection}
            />
          )}
          <AddressCard home={currentHome} updateHome={updateCurrentHome} />
        </div>
        <div className="flex-1">
          <HomeCheckoutArea
            home={currentHome}
            updateHome={updateCurrentHome}
            refresh={refetch}
            customIdError={customIdError}
          />
          <HomeStripeAccount
            home={currentHome}
            updateHome={updateCurrentHome}
            customIdError={customIdError}
          />
          <div className="card mb-4 lg:mr-4 mt-4">
            <div className="flex justify-between items-center mt-4">
              <h5 style={{ textAlign: 'left', margin: 0 }}>
                {t('listings.options')}
              </h5>
            </div>
            {currentHome?.status == 'active' ? (
              <div className="flex flex-col lg:flex-row mt-6 justify-around">
                <button
                  disabled={!currentHome.checkoutArea}
                  className={`flex-1 btn rounded-3xl btn-ghost ${
                    !currentHome.checkoutArea ? 'cursor-default opacity-50' : ''
                  }`}
                  onClick={() =>
                    window.open(getCheckoutUrl(currentHome, true), '_blank')
                  }
                >
                  <Icon
                    style={{ marginRight: 4 }}
                    fontSize="small"
                    component={VisibilityIcon}
                  />
                  {t('listings.preview')}
                </button>
                <button
                  disabled={!currentHome.checkoutArea}
                  className={`flex-1 btn rounded-3xl btn-ghost ${
                    !currentHome.checkoutArea ? 'cursor-default opacity-50' : ''
                  }`}
                  onClick={copyUrl}
                >
                  <Icon
                    style={{ marginRight: 4 }}
                    className="-rotate-45"
                    fontSize="small"
                    component={LinkIcon}
                  />
                  {isCopiedUrl
                    ? t('listings.copied_url')
                    : t('listings.copy_url')}
                </button>
                <button
                  disabled={!currentHome.checkoutArea}
                  className={`flex-1 btn rounded-3xl btn-ghost ${
                    !currentHome.checkoutArea ? 'cursor-default opacity-50' : ''
                  }`}
                  onClick={() => generateQRCode(currentHome)}
                >
                  <Icon
                    style={{ marginRight: 4 }}
                    fontSize="small"
                    component={QrCode2Sharp}
                  />
                  {t('listings.qr_code')}
                </button>
                <button
                  className="flex-1 rounded-3xl btn btn-ghost-danger capitalize"
                  onClick={() => handleChangeStatus('archive')}
                >
                  <Icon
                    style={{ marginRight: 4 }}
                    className="red"
                    fontSize="small"
                    component={ArchiveIcon}
                  />
                  {t('listings.archive')}
                </button>
              </div>
            ) : (
              <div className="flex mt-6 justify-start">
                <button
                  className="btn rounded-3xl btn-ghost-success capitalize"
                  onClick={() => handleChangeStatus('activate')}
                >
                  <Icon
                    style={{ marginRight: 4 }}
                    className=" red"
                    fontSize="small"
                    component={UnarchiveIcon}
                  />
                  {t('listings.activate')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDetails;
