import React, { useEffect } from 'react';
import AddHomeForm from './add-home-form/add-home-form.component';
import { useTranslation } from 'react-i18next';

const AddHome = ({ setSubRoute, refreshHomes }: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    setSubRoute(t('add'));
  }, []);

  return (
    <div className=" mt-4 py-4">
      <AddHomeForm refreshHomes={refreshHomes} />
    </div>
  );
};

export default AddHome;
