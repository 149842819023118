import globalSlice from './globalSlice';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { getOnboardingStatus } from '../../api/workspaces';
import { fetchHasAirbnbByHospitableConnection } from '../../api/platforms';

export const globalActions = globalSlice.actions;

export const fetchOnboardingStatus = (
  authToken: string,
  workspaceId: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    if (getState().global.onboardingStatus) {
      const response: any = await getOnboardingStatus(authToken, workspaceId);
      dispatch(
        globalActions.setOnboardingStatus({
          data: response?.data,
          meta: response?.meta,
        }),
      );
    }
  };
};

export const fetchWorkspaceCurrentRole = (
  roles: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    if (roles && roles[0]?.role) {
      await dispatch(globalActions.setCurrentRole(roles[0].role));
    } else {
      await dispatch(globalActions.setCurrentRole(null));
    }
  };
};

export const checkHasAirbnbConnection = (
  authToken: string,
  accountId: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    const response: any = await fetchHasAirbnbByHospitableConnection(
      authToken,
      accountId,
    );
    dispatch(globalActions.setHasAirbnbConnection(response));
  };
};
