import React from 'react';
import Loader from '../../components/loader/loader.component';
import { useAuth } from '../../contexts/authContext';
import { useQuery } from 'react-query';
import { validateHospitableAuth } from '../../api/channelManagers';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const IntegrateHospitable = () => {
  const { currentWorkspace, currentUser } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const authorizationCode = searchParams.get('code');

  const { isLoading, isError } = useQuery(
    ['channelManagerIntegrations', currentWorkspace],
    () =>
      authorizationCode &&
      validateHospitableAuth(
        currentUser.accessToken,
        currentWorkspace._id,
        authorizationCode,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: () => {
        navigate('/channel-managers');
      },
    },
  );

  if (isLoading)
    return (
      <div className="h-[400px]">
        <Loader />
      </div>
    );
  if (isError)
    return (
      <div className="mt-32">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          size={'8x'}
          className="text-primary mb-4"
        />
        <h1 className="text-6xl font-satoshiBold text-nightBlue font-bold mb-6">
          Woops!
        </h1>
        <h2 className="text-xl mb-8">{t('page_not_found')}</h2>
        <button
          onClick={() => navigate('/dashboard')}
          className="mt-8 btn btn-primary-outlined rounded-3xl"
        >
          {t('go_back')}
        </button>
      </div>
    );

  return <Loader />;
};

export default IntegrateHospitable;
