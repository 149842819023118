import React from 'react';
import '../../../../pages/checkout/landing.style.scss';
import DefaultHomePicture from '../../../../assets/images/default-home-picture.jpeg';
import { useTranslation } from 'react-i18next';
import { displayPrice } from '../../../../utils/currency';
import { Emoji } from 'emoji-mart';
import { Icon } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

type CheckoutAreaPreviewProps = {
  title: string;
  description: string;
  picture?: string;
  checkoutAreaProducts: any;
};
export const CheckoutAreaPreview: React.FC<CheckoutAreaPreviewProps> = ({
  title,
  description,
  checkoutAreaProducts,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-auto">
      <div className="landing-page">
        <div className="landing-background !h-[150px]">
          <img
            className="background-image object-contain"
            src={DefaultHomePicture}
            alt="image-home"
          />
        </div>
        <div className="">
          <div className="landing-header !mt-0 !py-2 !px-2">
            <h1 className="checkout-title !text-lg !mb-0 !pt-2">{title}</h1>
            <p className="subtitle !text-xs !mt-1 !mt-0">{description}</p>
          </div>
          <div className="items-start justify-between px-2 lg:flex">
            <div className="flex flex-col w-full text-left">
              <div className="flex self-end">
                <h2 className="text-md">{t('checkout.available_addons')}</h2>
              </div>

              <div>
                {checkoutAreaProducts?.map((cA: any, index: number) => (
                  <ExtraEdit key={index} product={cA.product} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="left-5 bottom-5 px-3 pb-5">
          <div className="footer-title text-xs">
            {t('powered_by')}
            <span
              className="font-satoshiBold text-lg text-black"
              onClick={() => window.open('https://cozyup.com/', '_blank')}
            >
              &nbsp;CozyUp
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExtraEdit = ({ product }: any) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="extra border-b last:border-none">
        <div className="pt-3 flex justify-between z-0 mb-1">
          {!product.emoji ? null : <Emoji emoji={product.emoji} size={32} />}
        </div>
        <div className="flex justify-between">
          <div className="description-wrapper !flex-row pb-1 !w-full">
            <div className="description-container !flex-row !text-sm !w-full !p-0">
              <div className="flex flex-col">
                <h3 className="mb-1 font">
                  {product.translations?.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  ).length
                    ? product.translations.filter(
                        (t: any) =>
                          t.languageCode === i18n.language.slice(0, 2),
                      )[0].title
                    : product.name}
                </h3>
                <p className="extra-description !mt-1 !text-xs text-grey">
                  {product.translations?.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  ).length
                    ? product.translations.filter(
                        (t: any) =>
                          t.languageCode === i18n.language.slice(0, 2),
                      )[0].description
                    : product.description}
                </p>
                {product?.validationRequired && (
                  <div className="flex items-end opacity-50">
                    <span className="text-checkoutPrimary text-xs">
                      *{t('checkout.if_available')}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="quantity-wrapper !flex !items-center !justify-between">
              <div className="price !text-sm">
                {displayPrice(product.sellingPrice, '€', {
                  thousandSeparator: ' ',
                })}
              </div>
              <div className="quantity-selector-wrapper !justify-end opacity-50 ">
                <Icon
                  fontSize="small"
                  className="quantity-icon minus !cursor-not-allowed !h-7 !w-7"
                  component={RemoveOutlinedIcon}
                />
                <div className="quantity !text-sm">{0}</div>
                <Icon
                  fontSize="small"
                  className="quantity-icon plus !cursor-not-allowed !h-7 !w-7"
                  component={AddOutlinedIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
