import React, { useState } from 'react';
import Validation from '../../../../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from '../../../../submit-button/submit-button.component';
import _ from 'lodash';
import Input from '../../../../input/input.component';
import { useMutation } from 'react-query';

import { useAuth } from '../../../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import { upload } from '../../../../../api/files';
import DefaultHomePicture from '../../../../../assets/images/default-home-picture.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

import { resizeImage } from '../../../../../utils/image';

type Props = {
  onSubmit: Function;
  setDisplayModal: Function;
};

const AddHomeManuallyForm = ({ onSubmit, setDisplayModal }: Props) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [homePicture, setHomePicture] = useState(DefaultHomePicture);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleOnChangePicture = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: any,
  ) => {
    if (e.target.files && e.target.files.length) {
      onChange(e.target.files);
      setHomePicture(URL.createObjectURL(e.target.files[0]));
    }
  };

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  const submit = async (data: any) => {
    let newHome = {
      name: data.name,
      picture: null,
      customId: data?.customId || null,
      // address: {
      //   street: data.street,
      //   streetNumber: data.streetNumber,
      //   zipcode: data.zipcode,
      //   city: data.city,
      //   country: data.country,
      // },
    };
    const hasPicture = data.picture && data.picture.length;
    if (hasPicture) {
      const resizedFile = await resizeImage(data.picture[0]);
      if (resizedFile) {
        const newPicture = resizedFile;
        const newPictureUploaded = await uploadNewPicture(newPicture);
        newHome.picture = newPictureUploaded.url;
      }
    }
    onSubmit(newHome);
  };

  return (
    <div className="w-full">
      <form className="flex flex-col p-4" onSubmit={handleSubmit(submit)}>
        <div className="w-full">
          {/* <h5 className="mb-6" style={{ textAlign: 'left' }}>
            {t('listings.informations')}
          </h5> */}
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue=""
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="name"
                size="small"
                label={`${t('listings.name')} *`}
                onChange={onChange}
                value={value}
                helperText={t('listings.name_of_the_place')}
                errors={[_.get(errors, 'name.message')]}
              />
            )}
            name={'name'}
          />
          <div className="relative mb-6">
            <img
              className="rounded-xl h-52 w-full object-cover"
              src={homePicture}
            />

            <div className="absolute top-0 right-0">
              <div>
                <label
                  htmlFor="picture"
                  className="btn absolute top-0 right-0 opacity-75 text-gray-600 bg-gray-100"
                >
                  {t('edit')}
                </label>
              </div>
            </div>

            <Controller
              control={control}
              render={({ field: { onChange } }: any) => (
                <Input
                  style={{ display: 'none' }}
                  key="picture"
                  label=""
                  type="file"
                  accept="image/*"
                  id="picture"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangePicture(e, onChange)
                  }
                  errors={[_.get(errors, 'picture.message')]}
                />
              )}
              name={'picture'}
            />
          </div>
          {/* <h5 className="mb-6" style={{ textAlign: 'left', marginTop: 8 }}>
            {t('address.address')}
          </h5>
          <div className="flex justify-between">
            <div className="w-1/5 lg:w-2/12">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="streetNumber"
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={`${t('address.street_number')} *`}
                    errors={[_.get(errors, 'streetNumber.message')]}
                  />
                )}
                name="streetNumber"
              />
            </div>
            <div className="pl-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="street"
                    size="small"
                    onChange={onChange}
                    value={value}
                    label={`${t('address.street')} *`}
                    errors={[_.get(errors, 'street.message')]}
                  />
                )}
                name="street"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-1/5">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="zipcode"
                    size="small"
                    label={`${t('address.zipcode')} *`}
                    onChange={onChange}
                    value={value}
                    errors={[_.get(errors, 'zipcode.message')]}
                  />
                )}
                name="zipcode"
              />
            </div>
            <div className="pl-4 flex-1">
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue=""
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="city"
                    size="small"
                    label={`${t('address.city')} *`}
                    onChange={onChange}
                    value={value}
                    errors={[_.get(errors, 'city.message')]}
                  />
                )}
                name="city"
              />
            </div>
          </div>
          <div className="w-full lg:w-2/5">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue=""
              render={({ field: { onChange, value } }: any) => (
                <Input
                  key="country"
                  size="small"
                  label={`${t('address.country')} *`}
                  onChange={onChange}
                  value={value}
                  errors={[_.get(errors, 'country.message')]}
                />
              )}
              name="country"
            />
          </div>

          <Controller
            control={control}
            rules={Validation.noSpace}
            render={({ field: { onChange, value } }: any) => (
              <Input
                key="customId"
                tooltip={`${t('listings.custom_id_help')}`}
                size="small"
                label={`${t('listings.custom_id')} (${t('optional')})`}
                onChange={onChange}
                value={value}
                helperText={`${document.location.origin}/cid/${
                  value || t('listings.your_custom_url')
                }`}
                errors={[_.get(errors, 'customId.message')]}
              />
            )}
            name={'customId'}
          /> */}
          <div className="flex justify-end">
            <button
              title={'Cancel'}
              className="btn rounded-3xl btn-primary-outlined"
              onClick={() => setDisplayModal(false)}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              title={t('confirm')}
              customClass="btn rounded-3xl btn-primary flex"
              disabled={isLoadingPicture}
            >
              {!isLoadingPicture ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </SubmitButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddHomeManuallyForm;
