import i18n from '../i18n';

export type ProductTemplateType = {
  emoji: string;
  name: string;
  description: string;
  costPrice: number | null;
  sellingPrice: number | null;
};

export const ProductTemplates: ProductTemplateType[] = [
  {
    emoji: '',
    name: '',
    description: '',
    costPrice: null,
    sellingPrice: null,
  },
  {
    emoji: 'mantelpiece_clock',
    name: 'late_checkout_title',
    description: 'late_checkout_desc',
    costPrice: 0,
    sellingPrice: 1500,
  },
  {
    emoji: 'alarm_clock',
    name: 'early_checkin_title',
    description: 'early_checkin_desc',
    costPrice: 0,
    sellingPrice: 1500,
  },
  {
    emoji: 'crescent_moon',
    name: 'late_checkin_title',
    description: 'late_checkin_desc',
    costPrice: 0,
    sellingPrice: 2000,
  },
  {
    emoji: 'clock12',
    name: 'combo_check_title',
    description: 'combo_check_desc',
    costPrice: 0,
    sellingPrice: 2500,
  },
  {
    emoji: 'bed',
    name: 'bedding_title',
    description: 'bedding_desc',
    costPrice: 600,
    sellingPrice: 1200,
  },
  {
    emoji: 'parking',
    name: 'parking_title',
    description: 'parking_desc',
    costPrice: 200,
    sellingPrice: 1000,
  },
  {
    emoji: 'dog',
    name: 'pet_food',
    description: 'pet_food_desc',
    costPrice: 0,
    sellingPrice: 1500,
  },
  {
    emoji: 'baby_symbol',
    name: 'baby_bed_title',
    description: 'baby_bed_desc',
    costPrice: 0,
    sellingPrice: 700,
  },
  {
    emoji: 'sponge',
    name: 'day_cleaning_title',
    description: 'day_cleaning_desc',
    costPrice: 0,
    sellingPrice: 2500,
  },
  {
    emoji: 'gift_heart',
    name: 'romantic_pack_title',
    description: 'romantic_pack_desc',
    costPrice: 1000,
    sellingPrice: 5000,
  },
  {
    emoji: 'champagne',
    name: 'champagne_title',
    description: 'champagne_desc',
    costPrice: 1500,
    sellingPrice: 2500,
  },
  {
    emoji: 'green_salad',
    name: 'appetizer_board_title',
    description: 'appetizer_board_desc',
    costPrice: 900,
    sellingPrice: 2300,
  },
  {
    emoji: 'croissant',
    name: 'breakfast_title',
    description: 'breakfast_desc',
    costPrice: 700,
    sellingPrice: 1500,
  },
];

export const OnboardingProductTemplates: ProductTemplateType[] = [
  {
    emoji: 'mantelpiece_clock',
    name: i18n.t('templates.late_checkout_title'),
    description: i18n.t('templates.late_checkout_desc'),
    costPrice: 0,
    sellingPrice: 1500,
  },
  {
    emoji: 'alarm_clock',
    name: i18n.t('templates.early_checkin_title'),
    description: i18n.t('templates.early_checkin_desc'),
    costPrice: 0,
    sellingPrice: 1500,
  },
  {
    emoji: 'crescent_moon',
    name: i18n.t('templates.late_checkin_title'),
    description: i18n.t('templates.late_checkin_desc'),
    costPrice: 0,
    sellingPrice: 2000,
  },
  {
    emoji: 'clock12',
    name: i18n.t('templates.combo_check_title'),
    description: i18n.t('templates.combo_check_desc'),
    costPrice: 0,
    sellingPrice: 2500,
  },
  {
    emoji: 'bed',
    name: i18n.t('templates.bedding_title'),
    description: i18n.t('templates.bedding_desc'),
    costPrice: 600,
    sellingPrice: 1200,
  },
  {
    emoji: 'parking',
    name: i18n.t('templates.parking_title'),
    description: i18n.t('templates.parking_desc'),
    costPrice: 200,
    sellingPrice: 1000,
  },
  {
    emoji: 'dog',
    name: i18n.t('templates.pet_food'),
    description: i18n.t('templates.pet_food_desc'),
    costPrice: 700,
    sellingPrice: 1500,
  },
  {
    emoji: 'baby_symbol',
    name: i18n.t('templates.baby_bed_title'),
    description: i18n.t('templates.baby_bed_desc'),
    costPrice: 0,
    sellingPrice: 700,
  },
  {
    emoji: 'sponge',
    name: i18n.t('templates.day_cleaning_title'),
    description: i18n.t('templates.day_cleaning_desc'),
    costPrice: 0,
    sellingPrice: 2500,
  },
  {
    emoji: 'gift_heart',
    name: i18n.t('templates.romantic_pack_title'),
    description: i18n.t('templates.romantic_pack_desc'),
    costPrice: 1000,
    sellingPrice: 5000,
  },
  {
    emoji: 'champagne',
    name: i18n.t('templates.champagne_title'),
    description: i18n.t('templates.champagne_desc'),
    costPrice: 1500,
    sellingPrice: 2500,
  },
  {
    emoji: 'green_salad',
    name: i18n.t('templates.appetizer_board_title'),
    description: i18n.t('templates.appetizer_board_desc'),
    costPrice: 900,
    sellingPrice: 2300,
  },
  {
    emoji: 'croissant',
    name: i18n.t('templates.breakfast_title'),
    description: i18n.t('templates.breakfast_desc'),
    costPrice: 700,
    sellingPrice: 1500,
  },
];
