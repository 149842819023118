import React from 'react';

interface Props {
  filtersList: Filter[];
  active: string;
  setActive: any;
}

interface Filter {
  label: string;
  value: any;
}

const Filters = ({ filtersList, active, setActive }: Props) => {
  const handleChange = (e: any) => {
    setActive(e.target.value);
  };

  return (
    <div className="flex justify-center items-center lg:block border rounded-2xl border-gray-300">
      {!filtersList?.length ? null : (
        <select
          value={active}
          onChange={handleChange}
          className="rounded-2xl px-4 py-2 after:p-4 border-r-8 border-transparent hover:outline hover:outline-primary focus:outline focus:outline-primary"
        >
          {filtersList.map((f) => (
            <option key={f.value} value={f.value}>
              {f.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default Filters;
