import React, { Fragment } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useStore';
import { lowerCase } from 'lodash';

type WorkspaceInfoProps = {
  containerClass?: string;
};
const WorkspaceInfo = ({ containerClass }: WorkspaceInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentRole = useAppSelector((state) => state.global.currentRole);

  const {
    currentWorkspace,
    currentAccount,
    updateCurrentWorkspace,
    isAccountOwner,
  } = useAuth();
  const [searchedWorkspace, setSearchedWorkspace] = React.useState<string>('');

  const handleSelectWorkspace = async (workspace: any) => {
    await updateCurrentWorkspace(workspace);
    navigate('dashboard');
  };

  return (
    <div className={containerClass}>
      <Listbox
        value={currentWorkspace}
        onChange={(workspace) => handleSelectWorkspace(workspace)}
      >
        <Listbox.Button className="flex items-center mx-3 bg-white min-h-[70px] my-[8px] rounded-xl border-grey ">
          <div className="ml-[12px]">
            <div className="bg-primary rounded-[8px] w-12 h-12 flex items-center justify-center">
              <span className="text-lg uppercase text-white font-bold">
                {currentWorkspace?.title?.slice(0, 2)}
              </span>
            </div>
          </div>
          <img className="workspace-avatar" />
          <div className="text-left w-full ml-[12px]">
            <div className="overflow-hidden text-[#6B7194] font-light text-[11px] w-full text-ellipsis uppercase tracking-wide truncate">
              {t('settings.workspace.workspace')}
            </div>
            <div className="overflow-hidden font-satoshiBold text-nightBlue text-ellipsis truncate w-28">
              {currentWorkspace?.title}
            </div>
            <span className="text-xs text-primary rounded-xl">
              {t(`role_list.${currentRole?.toLowerCase()}`)}
            </span>
          </div>
          <div>
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400 mx-[6px]"
              aria-hidden="true"
            />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-[215px] z-50 flex flex-col items-center  mx-3 bg-white rounded-xl border-[1px] border-grey">
            <div className="flex flex-col w-full">
              <div className="flex m-[12px] p-2 border border-gray-400 rounded-[4px] border-b ">
                <input
                  className="focus:outline-none w-full outline-none"
                  placeholder={`${t('search')}...`}
                  onChange={(e) => setSearchedWorkspace(e.target.value)}
                  value={searchedWorkspace}
                />
              </div>
              <div className="border border-b-0 border-grey"></div>
              <div className="max-h-[400px] overflow-auto">
                {currentAccount?.workspaces

                  ?.filter((workspace: any) =>
                    searchedWorkspace !== ''
                      ? lowerCase(workspace.title)?.includes(
                          lowerCase(searchedWorkspace),
                        )
                      : true,
                  )
                  ?.sort((a: any, b: any) => a.title.localeCompare(b.title))
                  ?.map((workspace: any, idx: number) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        `flex items-center cursor-pointer select-none py-2 pl-3 pr-9  ${
                          active ? 'hover:bg-lightblue' : ''
                        } ${
                          workspace.title === currentWorkspace.title
                            ? 'bg-lightblue'
                            : ''
                        }`
                      }
                      value={workspace}
                    >
                      <span
                        className={`flex flex-start truncate ${
                          workspace.title === currentWorkspace.title
                            ? 'font-bold'
                            : 'font-normal'
                        }`}
                      >
                        {workspace.title}
                      </span>

                      {workspace.title === currentWorkspace.title ? (
                        <span className="absolute right-0 flex items-center pr-4">
                          <CheckIcon
                            className="h-5 w-5 text-primary"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </Listbox.Option>
                  ))}
              </div>
              {isAccountOwner && (
                <div>
                  <div className="border border-b-0 border-grey"></div>
                  <Link
                    to="/settings/workspaces/new"
                    className="flex font-satoshiBold items-center cursor-pointer py-2 pl-3"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <p className="ml-2">{t('menu.add_workspace')}</p>
                  </Link>
                </div>
              )}
              <div className="border border-b-0 border-grey"></div>
              <Link
                to="/settings/workspaces"
                className="flex font-satoshiBold items-center cursor-pointer py-2 pl-3"
              >
                <FontAwesomeIcon icon={faCog} />
                <p className="ml-2">{t('menu.settings')}</p>
              </Link>
            </div>
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default WorkspaceInfo;
