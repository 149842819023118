import React, { useEffect, useRef, useState } from 'react';

import './dashboard.styles.scss';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useTranslation } from 'react-i18next';
import 'funnel-graph-js/dist/css/main.css';
import Calendar from '../../components/calendar/full-calendar/full-calendar.component';
import UpcomingOrders from '../../components/upcoming-orders/upcoming-orders.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import {
  getCalendarViewOrders,
  getPendingOrdersByWorkspace,
  getUpcomingOrdersByWorkspace,
} from '../../api/workspaces';
import { DatesSetArg } from '@fullcalendar/core';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from '@headlessui/react';
import PendingOrders from '../../components/pending-orders/pending-orders.component';
import { classNames } from '../../utils/helpers';

const Dashboard = () => {
  const { t } = useTranslation();
  const { currentUser, currentWorkspace } = useAuth();
  const [refresh, setRefresh] = useState(false);

  const {
    data: ordersByMonth,
    mutate: fetchOrdersByMonth,
    isLoading: isLoadingCalendarView,
  } = useMutation('ordersByMonth', (props: any) =>
    getCalendarViewOrders(
      currentUser.accessToken,
      currentWorkspace._id,
      // eslint-disable-next-line react/prop-types
      props?.year,
      // eslint-disable-next-line react/prop-types
      props?.month,
    ),
  );

  const {
    data: pendingOrders,
    isLoading: isLoadingPendingOrders,
    refetch: refetchPendingOrders,
    isFetching: isFetchingPendingOrders,
  } = useQuery(
    ['pendingOrdersData', currentWorkspace],
    () =>
      getPendingOrdersByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser,
    },
  );

  const {
    data: upcomingOrders,
    isLoading: isLoadingUpcomingOrders,
    refetch: refetchUpcomingOrders,
    isFetching: isFetchingUpcomingOrders,
  } = useQuery(
    ['upcomingOrdersData', currentWorkspace],
    () =>
      getUpcomingOrdersByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser,
    },
  );

  const handleOrdersRefresh = () => {
    refetchPendingOrders();
    refetchUpcomingOrders();
  };

  const categories = [
    {
      name: (
        <div className="flex justify-center items-center gap-1">
          {t('dashboard.pending_orders')}{' '}
          {pendingOrders && pendingOrders.length ? (
            <div className="text-white bg-red-500 w-4 h-4 text-xs rounded-full flex items-center justify-center">
              <span>{pendingOrders.length}</span>
            </div>
          ) : null}
        </div>
      ),
      content: (
        <PendingOrders
          ordersPending={pendingOrders}
          isLoading={isLoadingPendingOrders}
          isFetching={isFetchingPendingOrders}
        />
      ),
    },
    {
      name: (
        <div className="flex justify-center items-center gap-1">
          {t('dashboard.upcoming_orders')}{' '}
          {upcomingOrders && upcomingOrders.length ? (
            <div className="text-white bg-red-500 w-4 h-4 text-xs rounded-full flex items-center justify-center">
              <span>{upcomingOrders.length}</span>
            </div>
          ) : null}
        </div>
      ),
      content: (
        <UpcomingOrders
          upcomingOrders={upcomingOrders}
          isLoading={isLoadingUpcomingOrders}
          isFetching={isFetchingUpcomingOrders}
        />
      ),
    },
  ];

  useEffect(() => {
    setRefresh(true);
  }, [currentWorkspace]);

  const handleDateChange = (dateInfo: DatesSetArg) => {
    const params = {
      year: dateInfo.start.getFullYear(),
      month: dateInfo.start.getMonth() + 1,
    };
    fetchOrdersByMonth(params);
    setRefresh(false);
  };

  return (
    <div>
      <TabWrapper>
        <>
          <TabContentHeader
            title={t('menu.dashboard')}
            description={t('menu.dashboard_description')}
            rootPage="/dashboard"
          />
          <TabContent>
            <div className="lg:mt-8 gap-6 grid grid-cols-1 lg:flex">
              <div className="w-full lg:w-2/3">
                <div className="pt-4 px-4 card bg-white">
                  <div className="mb-6 flex justify-between items-center">
                    <h5 className="text-left">{t('dashboard.calendar')}</h5>
                    <FontAwesomeIcon
                      className="text-middleGrey hover:text-primary cursor-pointer"
                      icon={faArrowsRotate}
                      size="lg"
                      spin={isLoadingCalendarView}
                      onClick={() => setRefresh(true)}
                    />
                  </div>
                  <Calendar
                    refresh={refresh}
                    setRefresh={setRefresh}
                    events={ordersByMonth}
                    onDateChange={handleDateChange}
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/3 lg:flex-grow">
                <div className="card px-2 flex flex-col h-full">
                  <div className="p-3 flex justify-between items-center">
                    <h5 className="text-left">{t('dashboard.orders')}</h5>
                    <FontAwesomeIcon
                      className="text-middleGrey hover:text-primary cursor-pointer"
                      icon={faArrowsRotate}
                      size="lg"
                      spin={isLoadingCalendarView}
                      onClick={() => handleOrdersRefresh()}
                    />
                  </div>
                  <div className="h-full">
                    <OrderStatusTabs categories={categories} />
                  </div>
                </div>
              </div>
            </div>
          </TabContent>
        </>
      </TabWrapper>
    </div>
  );
};

export default Dashboard;

const OrderStatusTabs = ({ categories }: any) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabsRef = useRef<any>([]);
  const [highlightStyle, setHighlightStyle] = useState({});

  useEffect(() => {
    const tab = tabsRef.current[selectedTabIndex];
    setHighlightStyle({
      height: `calc(100% - 8px)`,
      width: tab.offsetWidth + 'px',
      marginTop: '4px',
      marginBottom: '4px',
      transform: `translateX(${tab.offsetLeft}px)`,
      transition: 'transform 300ms ease-in-out, width 300ms ease-in-out',
    });
  }, [selectedTabIndex]);

  return (
    <div className="w-full md:px-2 py-3 sm:px-0">
      <Tab.Group
        selectedIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}
      >
        <Tab.List className="flex rounded-2xl bg-lightGrey relative p-1">
          <div
            className="z-10 absolute bottom-0 left-0 bg-primary rounded-2xl"
            style={highlightStyle}
          />
          {categories.map((category: any, index: number) => (
            <Tab
              key={index}
              ref={(el) => (tabsRef.current[index] = el)}
              className={({ selected }: any) =>
                classNames(
                  'z-20 w-full py-4 rounded-2xl text-sm font-medium leading-5',
                  selected ? 'text-white font-bold' : 'text-primary ',
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2 py-2">
          {categories.map((cat: any, idx: number) => (
            <Tab.Panel
              key={idx}
              className={classNames('rounded-xl bg-white w-full')}
            >
              {cat.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
