import i18n from '../i18n';
import { isSameDay } from 'date-fns';

export const parseDateToLocalDate = (date: string) => {
  return date.split('T')[0];
};

export const formatDateToLocaleDateString = (date: any) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString('fr-FR', {
    // TODO - use I18n var for fr-FR, en-GB
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const calculateDaysRemaining = (
  dateCreation: string,
  daysInFuture: number,
) => {
  const dateDeCreation = new Date(dateCreation);
  const dateLimite = new Date(dateDeCreation);
  dateLimite.setDate(dateLimite.getDate() + daysInFuture);

  const maintenant = new Date();
  const differenceMs = dateLimite.getTime() - maintenant.getTime();

  if (differenceMs <= 0) {
    return 'Le délai de validation est expiré.';
  }

  const joursRestants = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const heuresRestantes = Math.floor(
    (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutesRestantes = Math.floor(
    (differenceMs % (1000 * 60 * 60)) / (1000 * 60),
  );
  // const secondesRestantes = Math.floor((differenceMs % (1000 * 60)) / 1000);

  return `${i18n.t('time.day', {
    count: joursRestants,
  })}, ${i18n.t('time.hour', {
    count: heuresRestantes,
  })} ${i18n.t('time.minute', {
    count: minutesRestantes,
  })} ${i18n.t('orders.to-validate-order')}`;
};

/**
 * Format date to Weekday Day month
 * @param date
 */
export const formatDateToWeekdayDayMonth = (date: any) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString(i18n.language, {
    weekday: 'long', // long-form weekday
    day: '2-digit', // 2-digit day
    month: 'short', // long-form month
  });
};

export const daysDifference = (date1Str: string, date2Str: string) => {
  // Parse the date strings into Date objects
  const date1 = new Date(date1Str);
  const date2 = new Date(date2Str);

  // Normalize the time part to avoid the issue of time difference
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(date2.getTime() - date1.getTime());

  // Calculate the number of days
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return dayDifference;
};

export const preparationTimeInHours = [
  { label: 'Aucun', key: 0 },
  { label: '4 heures avant arrivée', key: 4 },
  { label: '12 heures avant arrivée', key: 12 },
  { label: '1 jour avant arrivée', key: 24 },
  { label: '2 jours avant arrivée', key: 48 },
  { label: '3 jours avant arrivée', key: 72 },
  { label: '4 jours avant arrivée', key: 96 },
  { label: '5 jours avant arrivée', key: 120 },
  { label: '6 jours avant arrivée', key: 144 },
  { label: '7 jours avant arrivée', key: 168 },
];

export const hasProductExpired = (date: Date, hours: number) => {
  if (hours === 0) {
    return false;
  }
  const tempsPreparationEnMillisecondes = hours * 60 * 60 * 1000;

  // Récupérer la date actuelle en millisecondes
  const dateActuelle = new Date().getTime();

  // Convertir la date future en millisecondes
  const dateFutureEnMillisecondes = new Date(date).getTime();

  // Calculer le temps restant en millisecondes
  const tempsRestantEnMillisecondes =
    dateFutureEnMillisecondes - dateActuelle - tempsPreparationEnMillisecondes;

  if (tempsRestantEnMillisecondes <= 0) {
    return true;
  }
  return false;
};

export const displayExpirationDate = (date: Date, hours: number) => {
  // Convertir le temps de préparation en millisecondes
  const tempsPreparationEnMillisecondes = hours * 60 * 60 * 1000;

  // Récupérer la date actuelle en millisecondes
  const dateActuelle = new Date().getTime();

  // Convertir la date future en millisecondes
  const dateFutureEnMillisecondes = new Date(date).getTime();

  // Calculer le temps restant en millisecondes
  const tempsRestantEnMillisecondes =
    dateFutureEnMillisecondes - dateActuelle - tempsPreparationEnMillisecondes;

  // Convertir le temps restant en heures
  const tempsRestantEnJours =
    tempsRestantEnMillisecondes / (1000 * 60 * 60) / 24;
  const tempsRestantEnHeures = tempsRestantEnMillisecondes / (1000 * 60 * 60);
  const tempsRestantEnMinutes = tempsRestantEnMillisecondes / (1000 * 60);
  if (tempsRestantEnHeures <= 0) {
    return i18n.t('checkout.offer_expired');
  }
  if (tempsRestantEnHeures > 24) {
    return i18n.t('checkout.offer_expires_in', {
      count: Math.round(tempsRestantEnJours),
      unit: i18n.t('time.day', { count: Math.round(tempsRestantEnJours) }),
    });
  }
  if (tempsRestantEnHeures <= 1) {
    return i18n.t('checkout.offer_expires_in', {
      count: Math.round(tempsRestantEnMinutes),
      unit: i18n.t('time.minute', { count: Math.round(tempsRestantEnMinutes) }),
    });
  }
  return i18n.t('checkout.offer_expires_in', {
    count: Math.round(tempsRestantEnHeures),
    unit: i18n.t('time.hour', { count: Math.round(tempsRestantEnHeures) }),
  });
};

export const displayTimeRemaining = (futureDateStr: string) => {
  const currentDate = new Date();
  const futureDate = new Date(futureDateStr);

  // Conversion des dates en millisecondes et calcul de la différence
  const diffInMilliseconds = futureDate.getTime() - currentDate.getTime();

  // Vérifiez si la date future est déjà passée
  if (diffInMilliseconds < 0) {
    return 'La date future est déjà passée.';
  }

  // Convertir les millisecondes en jours, heures, minutes et secondes
  let remainingTime = diffInMilliseconds / 1000; // Convertir en secondes
  const days = Math.floor(remainingTime / (24 * 3600));
  remainingTime = remainingTime % (24 * 3600);
  const hours = Math.floor(remainingTime / 3600);
  remainingTime %= 3600;
  const minutes = Math.floor(remainingTime / 60);

  if (days > 1) {
    return i18n.t('time.day', { count: Math.round(days) });
  }
  if (hours <= 1) {
    return i18n.t('time.minute', { count: Math.round(minutes) });
  }
  return i18n.t('time.hour', { count: Math.round(hours) });
};

/**
 * Determines whether a specific date falls within a given range, inclusive of the start and end dates.
 * It considers dates to be in the same range if they are on the same day, ignoring time components.
 *
 * @param date - The date to check.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns `true` if the date is within the range (inclusive), otherwise `false`.
 */
export const isWithinRange = (
  date: Date,
  startDate: Date,
  endDate: Date,
): boolean => {
  return (
    (date >= startDate || isSameDay(date, startDate)) &&
    (date <= endDate || isSameDay(date, endDate))
  );
};
