import React, { useEffect, useRef, useState } from 'react';
import './cart.styles.scss';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Icon } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import stripeConfig from '../../services/stripe';

import { getPaymentIntentSecret } from '../../api/orders';
import { displayPrice } from '../../utils/currency';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import Button from '../button/button.component';
import ExtraCheckoutForm from '../extra-checkout-form/extra-checkout-form.component';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

const GUEST_FEE_PERCENTAGE: number = parseInt(
  process.env.REACT_APP_GUEST_FEES_PERCENTAGE as string,
);

type PaymentIntentCreateData = {
  products: any[];
  stripeConnectAccountId: string;
  description: string;
  currency: string;
  percentageFeeHost: number;
};

const Cart = ({
  previewMode,
  products,
  home,
  isProcessingPayment,
  onValidate,
  onEditOrder,
  onIncrementQuantity,
  onDecrementQuantity,
  currency,
  arrivalDate,
  focusDatePicker,
}: any) => {
  const { t, i18n } = useTranslation();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const checkoutRef = useRef<any>(null);
  const stripeConnectId =
    (home &&
      home.stripeConnectAccount &&
      home.stripeConnectAccount.stripeConnectId) ||
    null;
  const stripeCompleteConf = stripeConfig(stripeConnectId);
  const description = `commande pour : ${home.name}`;
  const [totalAmount, setTotalAmount] = useState(0);
  const checkoutMetadata = {
    home_id: home._id,
    workspace_id: home.workspace._id,
    additional_home_id: home.customId,
    address: home?.address,
    arrival_date: arrivalDate?.toISOString(),
  };

  const totalGuestFee =
    totalAmount * (GUEST_FEE_PERCENTAGE / 100) > 499
      ? 499
      : totalAmount * (GUEST_FEE_PERCENTAGE / 100);

  useEffect(() => {
    setTotalAmount(
      products.reduce(
        (sum: number, { sellingPrice, quantity }: any) =>
          sum + sellingPrice * quantity,
        0,
      ),
    );
  }, [products]);

  const validateCart = async () => {
    if (!arrivalDate) {
      focusDatePicker();
      return;
    }
    const data = {
      products: [...products.filter((product: any) => product.quantity > 0)],
      stripeConnectAccountId: stripeConnectId,
      description: description,
      currency: home.workspace.currency || 'eur',
      percentageFeeHost: home.workspace.account.percentageFeeHost,
    };

    !previewMode &&
      trackMixpanelEvent('Checkout - Cart validated', {
        ...checkoutMetadata,
        products: data.products,
        currency: data.currency,
        percentageFeeHost: data.percentageFeeHost,
      });

    createPaymentIntent(data);
  };

  const {
    data: paymentIntentSecret,
    mutate: createPaymentIntent,
    isLoading: isLoadingCreatePaymentIntent,
  } = useMutation(
    (data: PaymentIntentCreateData) => getPaymentIntentSecret(data),
    {
      onSuccess: () => {
        onValidate();
        if (checkoutRef.current) {
          checkoutRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
      onError: () => {
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  return (
    <div className="shadow pt-6 pb-3 w-full">
      <div>
        {!isProcessingPayment ? null : (
          <div className="flex justify-end pr-6">
            <button className="checkout-edit-button" onClick={onEditOrder}>
              {t('edit')}
            </button>
          </div>
        )}
        <div className="checkout-header block">
          <h2 className="checkout-title">{t('checkout.cart')}</h2>
        </div>
        <hr className="divider" style={{ marginLeft: 24, marginRight: 24 }} />
        <div className="px-6">
          <h3 className="headline">{t('checkout.addons')}</h3>
          {!products.length
            ? null
            : products.map((product: any) =>
                !(product.quantity > 0) ? null : (
                  <div key={product._id} className="product-container">
                    <div className="product-info">
                      <div className="product-quantity">
                        {isProcessingPayment ? null : (
                          <Icon
                            onClick={() => onDecrementQuantity(product._id)}
                            fontSize="small"
                            className="checkout-quantity-icon"
                            component={RemoveCircleOutlineOutlinedIcon}
                          />
                        )}
                        <div className="quantity">
                          {isProcessingPayment ? 'x' : ''}
                          {product.quantity}
                        </div>
                        {isProcessingPayment ? null : (
                          <Icon
                            onClick={() => onIncrementQuantity(product._id)}
                            fontSize="small"
                            className="checkout-quantity-icon"
                            component={AddCircleOutlineOutlinedIcon}
                          />
                        )}
                      </div>
                      <div className="product-name text-left">
                        {product?.translations.filter(
                          (t: any) =>
                            t.languageCode === i18n.language.slice(0, 2),
                        ).length
                          ? product.translations.filter(
                              (t: any) =>
                                t.languageCode === i18n.language.slice(0, 2),
                            )[0].title
                          : product?.name}
                      </div>
                    </div>
                    <div className="product-price">
                      {displayPrice(
                        product.quantity * product.sellingPrice,
                        currency.symbol,
                        {
                          thousandSeparator: ' ',
                        },
                      )}
                    </div>
                  </div>
                ),
              )}
        </div>
        <hr
          className="divider"
          style={{ marginLeft: 24, marginRight: 24, marginBottom: 12 }}
        />
        <div className="px-6 pt-4 pb-2 flex justify-between items-center ">
          <h3 className=" text-sm">{t('checkout.subtotal')}</h3>
          {!products.length ? null : (
            <div className=" text-sm">
              {displayPrice(totalAmount, currency.symbol, {
                thousandSeparator: ' ',
              })}
            </div>
          )}
        </div>
        {!isProcessingPayment || isLoadingCreatePaymentIntent ? null : (
          <div className="px-6 pb-4 pt-2 flex justify-between items-center text-[#a2a2a2]">
            <h3 className="text-sm">{t('checkout.platform_fee')}</h3>
            {!products.length ? null : (
              <div className="text-sm">
                {displayPrice(totalGuestFee, currency.symbol, {
                  thousandSeparator: ' ',
                })}
              </div>
            )}
          </div>
        )}
        <hr
          className="divider"
          style={{ marginLeft: 24, marginRight: 24, marginTop: 12 }}
        />
        <div className="px-6 total">
          <h3 className="headline">{t('checkout.total')}</h3>
          {!products.length ? null : (
            <div className="total-price">
              {/* {totalGuestFee} */}
              {displayPrice(
                totalAmount + (isProcessingPayment ? totalGuestFee : 0),
                currency.symbol,
                {
                  thousandSeparator: ' ',
                },
              )}
            </div>
          )}
        </div>
        <hr
          ref={checkoutRef}
          className="divider"
          style={{ marginLeft: 24, marginRight: 24 }}
        />

        {!isProcessingPayment ||
        isLoadingCreatePaymentIntent ||
        !paymentIntentSecret ? null : (
          <Elements
            stripe={stripeCompleteConf}
            options={{
              clientSecret: paymentIntentSecret?.clientSecret,
              appearance: { theme: 'flat' },
            }}
          >
            <ExtraCheckoutForm
              paymentIntentId={paymentIntentSecret?.paymentIntentId}
              homeId={home._id}
              checkoutMetadata={checkoutMetadata}
              workspace={home.workspace}
              products={products}
              arrivalDate={arrivalDate}
            />
          </Elements>
        )}
        <div className="submit-container">
          {previewMode ||
          isProcessingPayment ||
          isLoadingCreatePaymentIntent ? null : (
            <Button
              className="checkout-submit-button"
              disabled={
                products.filter((product: any) => product.quantity > 0)
                  .length === 0
              }
              onClick={async () => {
                await validateCart();
              }}
            >
              {t('checkout.confirm_cart')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
