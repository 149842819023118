import React, { useState } from 'react';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import './extra-checkout-form.styles.scss';
import { Controller, useForm } from 'react-hook-form';
import Input from '../input/input.component';
import TextArea from '../text-area/text-area.component';
import Button from '../button/button.component';
import Validation from '../../utils/validation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { createOrder } from '../../api/orders';
import { Order } from '../../types/order.model';
import { useMutation } from 'react-query';
import CheckoutAlert from '../checkout-alert/checkout-alert.component';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';

type ExtraCheckoutFormProps = {
  paymentIntentId: string;
  homeId: string;
  workspace: any;
  products: any[];
  arrivalDate: Date;
  checkoutMetadata: any;
};

const ExtraCheckoutForm = ({
  paymentIntentId,
  homeId,
  workspace,
  products,
  arrivalDate,
  checkoutMetadata,
}: ExtraCheckoutFormProps) => {
  let navigate = useNavigate();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [commentEnabled, setCommentEnabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [reload, setReload] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const backendErrors = '';
  const { t } = useTranslation();
  const workspaceId = workspace._id;
  const [paymentErrors, setPaymentErrors] = useState<string>('');
  const [paymentIsProcessing, setPaymentIsProcessing] =
    useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const { mutate: createNewOrder } = useMutation(
    (data: Order) => workspaceId && createOrder(workspaceId, data),
    {
      onSuccess: (response: any) => {
        if (response && response.data) {
          confirmStripePayment(response.data);
        } else {
          setPaymentIsProcessing(false);
          setReload(false);
          setAlertDescription(
            'Une erreur est survenue, merci de réessayer plus tard.',
          );
          setShowAlert(true);
        }
      },
      onError: (e: any) => {
        console.error(e);
        setReload(false);
        setAlertDescription(
          'Une erreur est survenue, merci de réessayer plus tard.',
        );
        setShowAlert(true);
      },
    },
  );

  const confirmStripePayment = async (data: any) => {
    try {
      const payment = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          receipt_email: data.customerEmail,
          payment_method_data: {
            billing_details: {
              email: data.customerEmail,
              name: data.customerName,
            },
          },
        },
      });

      if (payment.error) {
        // Show error to your customer (for example, payment details incomplete)
        setPaymentIsProcessing(false);
        setPaymentErrors(payment.error.message);
        return;
      } else {
        const cart = data?.products?.map(({ item, quantity }: any) => ({
          quantity,
          price: (item.sellingPrice / 100) * quantity,
          product_name: item.name,
        }));

        trackMixpanelEvent('Checkout - Payment success', {
          ...checkoutMetadata,
          delivery_date: data.deliveryDate,
          order_id: data._id,
          cart,
        });
        navigate(`/checkout-success?_order=${data?._id}`);
      }
    } catch (e) {
      console.error(e);
      setPaymentIsProcessing(false);
    }
  };

  const submit = async (data: any) => {
    setPaymentIsProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const payload = {
      products: [...products.filter((product: any) => product.quantity > 0)],
      deliveryDate: arrivalDate,
      customerEmail: data.email,
      status: 'pending',
      paymentStatus: 'unpaid',
      paymentIntentId: paymentIntentId,
      purchaseDate: Date.now(),
      home: homeId,
      customer: data.name,
      comment: data.comment || null,
    };

    trackMixpanelEvent('Checkout - Initiate payment', {
      ...checkoutMetadata,
      product: payload.products,
      delivery_date: payload.deliveryDate.toISOString(),
    });

    createNewOrder(payload);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <>
      <CheckoutAlert
        title={'Oups'}
        description={alertDescription}
        isVisible={showAlert}
        setIsVisible={setShowAlert}
        reloadOnClose={reload}
      />
      <div className="px-6">
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            rules={{ ...Validation.anyRequired }}
            defaultValue={''}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                type="text"
                key="name"
                size="small"
                customClassName={
                  'w-full text-gray-700 border hover:border-checkout focus:border-checkout focus-visible:border-checkout outline-none py-4 px-3 bg-white border-gray-400 rounded-lg mb-0 h-10 flex'
                }
                color={'checkout'}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('checkout.name')}
                value={value}
                label={`${capitalizeFirstLetter(t('full_name'))} `}
                errors={[
                  _.get(errors, 'name.message'),
                  ...backendErrorsForAttribute('name'),
                ]}
              />
            )}
            name={'name'}
          />
          <Controller
            control={control}
            rules={{ ...Validation.email, ...Validation.anyRequired }}
            defaultValue={''}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                type="email"
                key="email"
                size="small"
                color={'checkout'}
                onBlur={onBlur}
                customClassName={
                  'w-full text-gray-700 border hover:border-checkout focus:border-checkout focus-visible:border-checkout outline-none py-4 px-3 bg-white border-gray-400 rounded-lg mb-0 h-10 flex'
                }
                onChange={onChange}
                placeholder="example@mail.com"
                value={value}
                label={`${capitalizeFirstLetter(t('email'))} `}
                errors={[
                  _.get(errors, 'email.message'),
                  ...backendErrorsForAttribute('email'),
                ]}
              />
            )}
            name={'email'}
          />
          {!products.length ? null : (
            <div className="checkout-comment">
              {!commentEnabled ? (
                <div>
                  <button
                    className="comment-btn"
                    onClick={() => setCommentEnabled(true)}
                  >
                    {t('checkout.add_comment')}
                  </button>
                </div>
              ) : (
                <div>
                  <Controller
                    control={control}
                    rules={Validation.maxLength500}
                    defaultValue={''}
                    render={({ field: { onBlur, onChange, value } }: any) => (
                      <TextArea
                        key="comment"
                        size="small"
                        color={'checkoutPrimary'}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('checkout.add_comment_placeholder')}
                        value={value}
                        label={`${capitalizeFirstLetter(t('comment'))} `}
                        errors={[
                          _.get(errors, 'comment.message'),
                          ...backendErrorsForAttribute('comment'),
                        ]}
                      />
                    )}
                    name={'comment'}
                  />
                  {!commentEnabled ? null : (
                    <div className="comment-cancel">
                      <button
                        className="checkout-edit-button comment-cancel-wrapper "
                        onClick={() => {
                          setValue('comment', null);
                          setCommentEnabled(false);
                        }}
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <hr className="divider" style={{ marginLeft: 24, marginRight: 24 }} />

          <PaymentElement />

          <div>
            {!paymentErrors ? null : (
              <div className="payment-error">
                <p>{paymentErrors}</p>
              </div>
            )}
          </div>
          <Button
            className="checkout-submit-button"
            loading={paymentIsProcessing}
            disabled={!stripe || paymentIsProcessing}
          >
            {t('checkout.pay_now')}
          </Button>
        </form>
      </div>
    </>
  );
};

export default ExtraCheckoutForm;
