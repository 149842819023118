import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from '../../../contexts/authContext';
import { getWorkspacesByAccount } from '../../../api/workspaces';
import { useQuery } from 'react-query';
import WorkspaceList from '../../../components/_main/settings/workspace/workspace-list.component';
import WorkspaceDetail from '../../../components/_main/settings/workspace/workspace-detail.component';
import { AddWorkspace } from '../../../components/_main/settings/workspace/add-workspace.component';

type WorkspaceSettingsProps = {
  setSubRoute: any;
};

const WorkspaceSettings = ({ setSubRoute }: WorkspaceSettingsProps) => {
  const [refreshWorkspaces, setRefreshWorkspaces] = useState(false);
  const { currentUser, currentAccount } = useAuth();

  const { data: workspacesData, refetch: refetchWorkspaces } = useQuery(
    ['homes', currentUser, currentAccount._id],
    () => getWorkspacesByAccount(currentUser.accessToken, currentAccount._id),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  useEffect(() => {
    if (refreshWorkspaces) {
      refetchWorkspaces();
      setRefreshWorkspaces(false);
    }
  }, [refreshWorkspaces]);

  return (
    <Routes>
      <Route
        path=""
        element={
          <WorkspaceList
            workspaces={workspacesData}
            setSubRoute={setSubRoute}
          />
        }
      />
      <Route
        path="/:id"
        element={
          <WorkspaceDetail
            setSubRoute={setSubRoute}
            refreshWorkspaces={refetchWorkspaces}
          />
        }
      />
      <Route
        path="/new"
        element={
          <AddWorkspace
            setSubRoute={setSubRoute}
            refreshWorkspaces={refetchWorkspaces}
          />
        }
      />
    </Routes>
  );
};

export default WorkspaceSettings;
