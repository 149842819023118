import React, { useEffect, useState } from 'react';
import '../../components/extra/extra.styles.scss';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import TabContent from '../../components/tab-content/tab-content.component';
import ProductDetails from '../../components/_main/products/product-details/product-details.component';
import ProductsList from '../../components/_main/products/products-list/products-list.component';
import NewProduct from '../../components/_main/products/new-product/new-product.component';
import { useAuth } from '../../contexts/authContext';
import { useQuery } from 'react-query';
import { getProductsByWorkspace } from '../../api/products';
import { useTranslation } from 'react-i18next';
import OnClickOut from '../../hooks/useOnClickOut';
import {
  ProductTemplates,
  ProductTemplateType,
} from '../../data/product-templates.data';
import ProductTemplate from '../../components/product-template/product-template.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { getCurrencyInfo } from '../../utils/currency';

const Products = () => {
  const location: any = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [subRoute, setSubRoute] = useState<string>('');
  const [displayProductTemplates, setDisplayProductTemplates] = useState(
    location?.state?.displayTemplates || false,
  );
  const match = useMatch({ path: 'products', end: true });
  const headerProps = match
    ? {
        actionTitle: `+ ${t('add')}`,
        // Display popup with template extra
        callback: () => setDisplayProductTemplates(true),
        displaySuggestion: true,
      }
    : null;

  const [refresh, setRefresh] = useState<boolean>(false);

  const { currentUser, currentWorkspace } = useAuth();
  const currency = getCurrencyInfo(currentWorkspace?.currency);

  const {
    data: productsData,
    refetch: refreshProducts,
    isLoading: isLoadingProducts,
  } = useQuery(
    ['products', currentUser, currentWorkspace],
    () => getProductsByWorkspace(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
      refetchOnWindowFocus: 'always',
    },
  );

  useEffect(() => {
    if (refresh) {
      refreshProducts();
      setRefresh(false);
    }
  }, [refresh, refreshProducts]);

  return (
    <TabWrapper>
      <>
        {/*  Modal displayed so user can pick template product on creation
         or start from scratch*/}
        {displayProductTemplates && (
          <div className="modal-overlay bg-opacity-25">
            <div className="modal-wrapper">
              <OnClickOut
                classname="flex flex-col items-center w-[90%] lg:w-[80%] h-[80%] z-[100px] bg-white relative m-auto rounded-[16px] py-5 px-6 lg:px-10"
                callback={() => setDisplayProductTemplates(false)}
              >
                <button
                  type="button"
                  className="modal-close-button"
                  onClick={() => setDisplayProductTemplates(false)}
                >
                  <span className="text-[32px]">&times;</span>
                </button>
                <div className="flex flex-col modal-header">
                  <h5 className="leading-10 md:leading-none text-[36px] mb-4">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="text-primary mr-[8px]"
                      size="lg"
                    />
                    {t('templates.add_best_sellers')} 🎉
                  </h5>
                  <h3>{t('templates.add_best_sellers_description')}</h3>
                </div>

                <div className="px-[8px] grid grid-cols-1 lg:grid-cols-2 gap-4 items-start w-[100%] h-[100%] mt-5 pt-2 mb-2 overflow-auto">
                  <div
                    onClick={() => {
                      navigate('/products/new', {
                        state: { product: ProductTemplates[0] },
                      });
                      setDisplayProductTemplates(false);
                    }}
                    className=" m-[8px] group flex flex-col w-full justify-center items-center cursor-pointer shadow rounded-[32px] font-satoshi min-h-[150px] mb-[12px] hover:border-primary border-[2px] border-transparent"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="3x"
                      color="#d3d3d3"
                      className="group-hover:text-primary"
                    />
                    <p className="mt-3 text-middleGrey font-medium group-hover:text-primary text-[16px]">
                      {t('new')}
                    </p>
                  </div>
                  {ProductTemplates.filter((p, index) => index > 0).map(
                    (product: ProductTemplateType, index: number) => (
                      <div key={index.toString()}>
                        <ProductTemplate
                          index={index + 1}
                          product={product}
                          setDisplayModal={setDisplayProductTemplates}
                          currency={currency}
                        />
                      </div>
                    ),
                  )}
                </div>
              </OnClickOut>
            </div>
          </div>
        )}
        <TabContentHeader
          title={t('menu.products')}
          description={t('menu.products_description')}
          rootPage="/products"
          subRoute={subRoute}
          {...headerProps}
        />
        <TabContent>
          <div className="home-tab-container">
            <Routes>
              <Route
                path=""
                element={
                  <ProductsList
                    setSubRoute={setSubRoute}
                    products={productsData}
                    setRefresh={setRefresh}
                    currency={currency}
                    setDisplayProductTemplates={setDisplayProductTemplates}
                    isLoadingProducts={isLoadingProducts}
                  />
                }
              />
              <Route
                path=":id"
                element={
                  <ProductDetails
                    setSubRoute={setSubRoute}
                    setRefresh={setRefresh}
                    currency={currency}
                  />
                }
              />
              <Route
                path="new"
                element={
                  <NewProduct
                    currency={currency}
                    setSubRoute={setSubRoute}
                    setRefresh={setRefresh}
                  />
                }
              />
            </Routes>
          </div>
        </TabContent>
      </>
    </TabWrapper>
  );
};

export default Products;
