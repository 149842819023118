import React, { useEffect } from 'react';
import Subscription from '../../../components/_main/settings/subscription/subscription.component';
import { useTranslation } from 'react-i18next';

type BillingSettingsProps = {
  setSubRoute: any;
};

const BillingSettings = ({ setSubRoute }: BillingSettingsProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    setSubRoute(t('header.billing'));
  }, []);
  return <Subscription />;
};

export default BillingSettings;
