import React from 'react';
import { useRole } from '../../hooks/useRole';
import { useAppSelector } from '../../hooks/useStore';

type Props = {
  rolesRequired: string[];
  children?: React.ReactNode;
};

export const WithPermissions = ({ rolesRequired, children }: Props) => {
  const { hasAuthorization } = useRole();
  const userCurrentRole = useAppSelector((state) => state.global.currentRole);
  return (
    <>{hasAuthorization(userCurrentRole, rolesRequired) ? children : null}</>
  );
};
