import React, { useEffect } from 'react';
import '../../App.css';
import './sign-in.styles.scss';
import { useForm } from 'react-hook-form';
import SignInForm from './sign-in-form/sign-in-form.component';
import BackButton from '../../components/back-button/back-button.component';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import Language from '../../components/language/language.component';

const SignIn = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser]);

  const { t } = useTranslation();
  useForm();

  //TODO: Refactor design as in SignUp.tsx (create form, use input.component.tsx
  return (
    <div className="min-h-screen relative flex flex-col items-center justify-center">
      <BackButton />
      <div className="relative mx-auto ">
        <div className="flex flex-col">
          <a
            href="https://cozyup.com"
            target={'_blank'}
            className="logo-wrapper-account w-inline-block"
            style={{ borderRadius: 100 }}
            rel="noreferrer"
          >
            <img
              src="https://cozyup-public.s3.eu-west-3.amazonaws.com/logo-square-clear-blue.png"
              loading="lazy"
              alt=""
              className="account-icon"
            />
          </a>
          <div className="flex flex-col items-center justify-center mb-6">
            <h5>{t('auth.sign-in')}</h5>
            <p className="mt-4 font-semibold lg:text-lg text-[#5a6978]">
              {t('auth.sign-in-description')}
            </p>
            <div className="w-fil">
              <Language />
            </div>
          </div>
          <div className="w-full px-6 min-w-[300px]">
            <SignInForm />
          </div>
        </div>
      </div>
      <div className="text-grey text-md absolute flex justify-center w-full bottom-5 item-end text-center">
        {t('copyright', { year: currentYear })}
      </div>
    </div>
  );
};

export default SignIn;
