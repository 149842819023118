import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import France from '../../assets/images/flags/france.png';
import England from '../../assets/images/flags/england.png';
import i18n from '../../i18n';
import { classNames } from '../../utils/helpers';

const LANGUES: Langue[] = [
  {
    id: 'fr',
    value: 'fr',
    flag: France,
  },
  {
    id: 'en',
    value: 'en',
    flag: England,
  },
];

type Langue = {
  id: string;
  value: string;
  flag: any;
};

const Language = () => {
  const { t } = useTranslation();

  const selectDefault = () => {
    const defaultLang = LANGUES.find((item) => {
      return item.value === i18n.language;
    });
    return defaultLang ? defaultLang : LANGUES[1];
  };

  const [selected, setSelected] = useState<Langue | undefined>(selectDefault());

  const handleChange = async (e: any) => {
    const lang = LANGUES.find((item) => item.value === e);
    await i18n.changeLanguage(lang?.value);

    setSelected(lang);
  };

  // @ts-ignore
  return (
    <Listbox value={selected} onChange={handleChange}>
      {/* @ts-ignore*/}
      {({ open }) => (
        <div className="relative mt-2 mb-2 z-40">
          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
            <span className="flex items-center">
              <img
                src={selected?.flag}
                alt=""
                className="h-6 w-6 flex-shrink-0 rounded-[8px]"
              />
              <span className="ml-3 truncate">
                {t(`langues.${selected?.id}`)}
              </span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute  bg-lightblue z-50 mt-1 max-h-56 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {LANGUES.map((lang: Langue) => {
                return (
                  <Listbox.Option
                    key={lang.id}
                    className={(lang: any) =>
                      classNames(
                        lang?.value === i18n.language
                          ? 'text-white bg-indigo-600'
                          : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9 z-50',
                      )
                    }
                    value={lang.id}
                  >
                    <div className="flex items-center">
                      <img
                        src={lang?.flag}
                        alt=""
                        className="h-6 w-6 flex-shrink-0 rounded-[8px]"
                      />
                      <span
                        className={classNames(
                          lang?.value === i18n.language
                            ? 'font-semibold'
                            : 'font-normal',
                          'ml-3 truncate',
                        )}
                      >
                        {t(`langues.${lang?.id}`)}
                      </span>
                    </div>

                    {lang?.id === selected?.id ? (
                      <span
                        className={classNames(
                          lang?.id !== selected?.id
                            ? 'text-white'
                            : 'text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default Language;
