import { api } from '../utils/request';

export const declineAccountInvitation = async (
  authToken: string,
  workspaceId: string,
  invitationId: string,
) => {
  try {
    const res = await api.delete(
      `/users/account-invitations/${invitationId}/decline`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const acceptAccountInvitation = async (
  authToken: string,
  workspaceId: string,
  invitationId: string,
) => {
  try {
    const res = await api.patch(
      `/users/account-invitations/${invitationId}/accept`,
      {},
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export * as invitationApi from './invitation';
