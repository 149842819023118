import React, { CSSProperties } from 'react';
// import './input.styles.scss';
import ErrorMessage from '../error-message/error-message.component';
import { Icon, Tooltip } from '@mui/material';
import { CallToAction } from '../../types/CallToAction';
import { HelpOutline } from '@mui/icons-material';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

type Size = 'small' | 'big';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export interface Props {
  id?: string;
  error?: string;
  errors?: string[];
  placeholder?: string;
  textInputStyle?: any;
  labelTextStyle?: any;
  containerStyle?: any;
  disabled?: boolean;
  onChange?: any;
  type?: string;
  value?: string;
  label?: string;
  customClassName?: string;
  helperText?: string;
  activeColor?: string;
  activeLabelColor?: string;
  size?: Size;
  style?: CSSProperties;
  color: string;
  onPress?: any;
  onFocus?: any;
  onBlur?: any;
  onKeyDown?: any;
  accept?: any;
  tooltip?: string;
  cta?: CallToAction[];
  ref?: any;
}

const PriceInput = ({
  id,
  error,
  errors,
  placeholder,
  disabled,
  value,
  label,
  type,
  helperText,
  size,
  style,
  color,
  onChange,
  customClassName,
  tooltip,
  cta,
  ...props
}: Props) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  const inputRef = React.useRef(null);
  const className =
    customClassName ||
    `w-full text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none py-4 px-3 bg-white border-gray-400 rounded-3xl mb-0 h-10 flex ${color} ${size} ${
      disabled ? ' opacity-50 hover:border-gray-400 pointer-events-none' : ''
    }`;
  return (
    <div className="flex flex-wrap flex-col mb-6 text-left" style={style}>
      <div className="flex items-center justify-between mb-[2px]">
        <div className="text-left text-[#6B7194]">{label}</div>
        <div className="flex flex-row justify-center items-center">
          {tooltip && (
            <Tooltip title={tooltip} className="mr-2" enterTouchDelay={0}>
              <Icon
                className="icon text-gray-300 cursor-pointer opacity-75"
                component={HelpOutline}
              />
            </Tooltip>
          )}
          {!cta?.length ? null : (
            <div className="flex justify-center content-center">
              {cta.map((action: CallToAction, index: number) => (
                <div
                  key={index.toString()}
                  className="ml-[4px] bg-gray-100 hover:bg-gray-300 rounded-[4px] h-[28px] w-[28px] flex justify-center items-center"
                >
                  <Tooltip
                    title={action?.tooltip}
                    onClick={(e) => {
                      e.stopPropagation();
                      action.callback();
                    }}
                  >
                    <Icon
                      className="icon text-gray-500 cursor-pointer p-[3px] text-[43px]"
                      component={action.icon}
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <MaskedInput
        mask={currencyMask}
        inputMode={'numeric'}
        id={id}
        type={type}
        className={className}
        disabled={disabled}
        style={{}}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        ref={inputRef}
        {...props}
      />
      {!helperText || error || (errors && errors[0]) ? null : (
        <span className="text-left ml-2 mt-2 text-xs text-green font-semibold">
          {helperText}
        </span>
      )}
      {error && <ErrorMessage key={error} error={error} />}
      {errors &&
        errors.map((err: string) => {
          if (err) {
            return <ErrorMessage key={err} error={err} />;
          }
        })}
    </div>
  );
};

PriceInput.defaultProps = {
  size: 'big',
  style: { width: '100%' },
  color: 'default',
};

export default PriceInput;
