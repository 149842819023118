import React, { useEffect, useState } from 'react';
import { Icon, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  CheckoutArea,
  NewCheckoutArea,
} from '../../../../types/checkout-area.model';
import { formatDateToLocaleDateString } from '../../../../utils/date';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../../contexts/authContext';
import { deleteCheckoutArea } from '../../../../api/checkoutAreas';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { faStore } from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../loader/loader.component';
import { CheckoutAreaProduct } from '../../../../types/checkout-area-product.model';

const CheckoutAreaList = ({
  setSubRoute,
  checkoutAreas,
  setRefresh,
  isLoadingStores,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, currentWorkspace } = useAuth();
  const [currentCheckoutAreaId, setCurrentCheckoutAreaId] = useState('');
  const [displayDeleteAlert, setDisplayDeleteAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');

  // Set the current page submenu in the header
  useEffect(() => {
    setSubRoute('');
  }, []);

  const handleDelete = async (
    event: any,
    checkoutAreaId: string,
    checkoutAreaName: string,
  ) => {
    event.stopPropagation();
    setAlertDescription(
      t('checkout-areas.delete_warning', { checkoutArea: checkoutAreaName }),
    );
    setCurrentCheckoutAreaId(checkoutAreaId);
    setDisplayDeleteAlert(true);
  };

  const deleteClickedCheckoutArea = async () => {
    await deleteCheckoutArea(
      currentUser.accessToken,
      currentWorkspace._id,
      currentCheckoutAreaId,
    );
    setRefresh(true);
  };

  const handleDuplicateCheckoutArea = (
    event: React.MouseEvent<HTMLDivElement>,
    checkoutArea: CheckoutArea,
  ) => {
    event.stopPropagation();
    const newCheckoutArea: NewCheckoutArea = {
      name: `${checkoutArea.name} (${t('copy')})`,
      products: checkoutArea.checkoutAreaProducts.map(
        (caProduct: CheckoutAreaProduct) => caProduct.product,
      ),
    };
    navigate('/checkout-areas/new', {
      state: { checkoutArea: newCheckoutArea },
    });
  };

  return (
    <>
      <AlertBeforeAction
        title={t('are_you_sure')}
        description={alertDescription}
        successAction={deleteClickedCheckoutArea}
        isVisible={displayDeleteAlert}
        setIsVisible={setDisplayDeleteAlert}
      />
      <table className="cozyup hidden lg:table">
        <thead>
          <tr>
            <th>#</th>
            <th>
              {t('checkout-areas.name')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('checkout-areas.products')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('checkout-areas.creation_date')}{' '}
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('checkout-areas.last_update')}{' '}
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="mt-6">
          {checkoutAreas?.map((checkoutArea: CheckoutArea, i: number) => (
            <tr
              key={checkoutArea._id}
              className="cursor-pointer bg-lightblue my-4 py-4 px-2 h-10 row"
              onClick={() => navigate(`${checkoutArea._id}`)}
            >
              <td>{i + 1}</td>
              <td>{checkoutArea.name}</td>
              <td>{checkoutArea.checkoutAreaProducts.length}</td>
              <td>{formatDateToLocaleDateString(checkoutArea.createdAt)}</td>
              <td>
                {formatDateToLocaleDateString(checkoutArea.lastUpdatedAt)}
              </td>
              <td>
                <div className="flex justify-center">
                  <Tooltip title={`${t('duplicate')}`}>
                    <div
                      onClick={(e) =>
                        handleDuplicateCheckoutArea(e, checkoutArea)
                      }
                      className="icon hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                    >
                      <Icon
                        className="h-10 w-10 text-gray-500"
                        fontSize="small"
                        component={ContentCopyIcon}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title={`${t('delete')}`}>
                    <div
                      onClick={(e: any) =>
                        handleDelete(e, checkoutArea._id, checkoutArea.name)
                      }
                      className="icon hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                    >
                      <Icon
                        className=" h-10 w-10 text-red-500 "
                        fontSize="small"
                        component={DeleteIcon}
                      />
                    </div>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="lg:hidden">
        <div className="flex flex-col mt-6">
          {checkoutAreas?.map((checkoutArea: CheckoutArea, i: number) => (
            <div
              key={checkoutArea._id}
              onClick={() => navigate(`${checkoutArea._id}`)}
              className="rounded-xl border flex flex-col p-3 mb-6 bg-lightblue shadow-xl"
            >
              <div>
                <div className="flex mb-2 text-lg text-left font-bold">
                  <span className="mx-1">#{i + 1}</span>
                  <span className="mx-2 text-left first-letter:capitalize">
                    {checkoutArea.name}
                  </span>
                </div>
                <div className="my-4 text-left">
                  <div>
                    <span className="font-semibold">
                      {t('checkout-areas.products')} :{' '}
                    </span>
                    <span className="">
                      {checkoutArea.checkoutAreaProducts.length}
                    </span>
                  </div>
                  {/* <div>
                    <span className="font-semibold">Cost price : </span>
                    <span className="">{products.costPrice}€</span>
                  </div> */}
                </div>
              </div>

              <div className="flex justify-around mt-6">
                <Tooltip
                  title={`${t('duplicate')}`}
                  onClick={(e) => handleDuplicateCheckoutArea(e, checkoutArea)}
                >
                  <Icon
                    className="icon rounded-full h-10 w-10 text-gray-600 cursor-pointer"
                    fontSize="small"
                    component={ContentCopyIcon}
                  />
                </Tooltip>
                <Tooltip title={`${t('delete')}`}>
                  <Icon
                    className="iconrounded-full h-10 w-10 text-red-500 cursor-pointer"
                    fontSize="small"
                    component={DeleteIcon}
                    onClick={(e: any) =>
                      handleDelete(e, checkoutArea._id, checkoutArea.name)
                    }
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoadingStores && (
        <div className="flex justify-center items-center height-[150px]">
          <Loader />
        </div>
      )}
      {checkoutAreas?.length || isLoadingStores ? null : (
        <div className="mt-10">
          <div className="flex-row mb-10">
            <FontAwesomeIcon
              className="opacity-20 mb-4"
              color="#b6b9ce"
              icon={faStore}
              size="7x"
            />
            <div className="text-sm font-semibold text-[#6B7194] opacity-20">
              {t('tables.empty-stores')}
            </div>
          </div>
          <button
            onClick={() => navigate('/checkout-areas/new')}
            className="btn btn-primary-outlined rounded-3xl"
          >
            {t('add')}
          </button>
        </div>
      )}
    </>
  );
};

export default CheckoutAreaList;
