// pages/Booking.tsx

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CURowText from '../../components/_main/CURowtText/cu-row-text';
import { addDays, format, isSameDay } from 'date-fns';
import {
  DateRange,
  DayContent,
  DayContentProps,
  DayPicker,
} from 'react-day-picker';
import { isAvailableDate } from './booking.utils';
import { formatDateToWeekdayDayMonth, isWithinRange } from '../../utils/date';
import { classNames } from '../../utils/helpers';
import { getAvailabilities } from '../../api/reservation';
import { useQuery } from 'react-query';
import BookingCart from '../../components/_booking/booking-cart/booking-cart.component';
import Loader from '../../components/loader/loader.component';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { displayPrice } from '../../utils/currency';
import { dateFnslocales } from '../../utils/language';
import i18n from '../../i18n';

const css = `
  // .cu-outside:not([disabled]) { 
  //   border: none;
  //   color: green;
  //   background-color: green;
  // }
  // .cu-outside:hover:not([disabled]) { 
  //   border: none;
  //   background-color: #ff3366;
  // }
  .reservationInRange {
    background-color: #f0e68c; /* jaune clair */
  }
  
  .selectableDay {
    background-color: #add8e6; /* bleu clair */
  }
  .cu-selected:not([disabled]) { 
    border: none;
    color: white;
    background-color: #ff3366;
  }
  .cu-selected:hover:not([disabled]) { 
    border: none;
    background-color: #ff3366;
  }
  .cu-today { 
    font-weight: bold;
    color: #190f34;
    font-family: 'SatoshiBold', sans-serif;
  }
`;

const Booking = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const reservationId: string | null = searchParams.get('rId');
  const homeId: string | null = searchParams.get('hId');
  const [footer, setFooter] = useState<string>('');
  const [range, setRange] = useState<DateRange | undefined>();
  const [selectedAvailabilities, setSelectedAvailabilities] = useState<any>([]);
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);

  const { data, isLoading: isLoadingAvailabilities } = useQuery(
    ['extend-stay-availabilities', homeId, reservationId],
    () => getAvailabilities(reservationId, homeId),
    {
      enabled: !!homeId && !!reservationId,
      refetchOnWindowFocus: false,
      onSuccess: ({
        reservation,
      }: {
        reservation: any;
        home: any;
        availabilities: any;
      }) => {
        setRange({
          from: new Date(reservation.arrivalDate),
          to: new Date(reservation.departureDate),
        });
      },
    },
  );

  const checkoutMetadata = {
    booking_sale: true,
    home_id: data?.home._id,
    workspace_id: data?.home.workspace._id,
    additional_home_id: data?.home.customId,
    address: data?.home?.address,
  };

  useEffect(() => {
    trackMixpanelEvent('Booking - Page view', {
      ...checkoutMetadata,
      availabilities: data?.availabilities,
      currency: data?.currency,
      percentageFeeHost: data?.percentageFeeHost,
    });
  }, []);

  const maxDate =
    data?.availabilities?.length === 0
      ? false
      : new Date(
          data?.availabilities?.reduce((a: any, b: any) =>
            new Date(a.date) > new Date(b.date) ? a : b,
          ).date,
        );

  if (maxDate) {
    maxDate.setDate(maxDate.getDate() + 1);
  }

  const CustomDayComponent = (props: DayContentProps) => {
    const dateTime = format(props.date, 'yyyy-MM-dd');
    const { date } = props;

    const availabilities = data?.availabilities;

    // Check if the current date is available for extension
    const isDateAvailable = isAvailableDate(date, availabilities);
    const currentDateAvailability = availabilities?.find((a: any) => {
      // Add 1 day to the availability date to match picked date in calendar
      const adjustedDate = addDays(new Date(a.date), 1);
      return isSameDay(date, adjustedDate);
    });

    // Check if the date is within the booked and paid reservation range
    const isPaidReservationDate = isWithinRange(
      date,
      new Date(data?.reservation.arrivalDate),
      new Date(data?.reservation.departureDate),
    );

    return (
      <time dateTime={dateTime}>
        <div className="flex flex-col">
          <DayContent {...props} />

          {!isDateAvailable ? null : (
            <div className="flex justify-center items-center">
              <span
                className={classNames(
                  props?.activeModifiers?.selected ? 'text-white' : 'text-grey',
                  'text-[8px]',
                )}
              >
                {currentDateAvailability?.priceWithDiscountFormatted}
              </span>
            </div>
          )}
          {!isPaidReservationDate ? null : (
            <span className="text-white text-[8px]">{t('orders.paid')}</span>
          )}
        </div>
      </time>
    );
  };

  const handleOnSelectDate = (selectedRange: DateRange | undefined) => {
    if (isProcessingPayment) return;
    let caseType = '';

    if (selectedRange?.to && selectedRange?.from) {
      const selectedFromDate = selectedRange.from;
      const selectedToDate = selectedRange.to;

      if (
        isWithinRange(
          selectedToDate,
          data?.reservation?.departureDate,
          data?.reservation?.arrivalDate,
        ) ||
        isWithinRange(
          selectedFromDate,
          data?.reservation?.departureDate,
          data?.reservation?.arrivalDate,
        )
      ) {
        caseType = 'withinStay';
      } else if (isAvailableDate(selectedToDate, data?.availabilities)) {
        caseType = 'extendStay';
      } else {
        caseType = 'outsideRange';
      }
    }

    trackMixpanelEvent('Booking - Picked date in calendar ', {
      ...checkoutMetadata,
      availabilities: data?.availabilities,
      currency: data?.currency,
      percentageFeeHost: data?.percentageFeeHost,
      dayTypePicked: caseType,
    });

    let range;
    switch (caseType) {
      case 'withinStay':
        // If the guest selects a day within their current stay
        setRange({
          from: new Date(data?.reservation?.arrivalDate),
          to: new Date(data?.reservation?.departureDate),
        });
        setSelectedAvailabilities([]);
        setFooter('');
        break;
      case 'extendStay':
        // If the guest selects a day that extends their stay within available dates
        range = {
          from: new Date(data?.reservation?.arrivalDate),
          to: selectedRange?.to,
        };
        setRange(range);
        setFooter('');
        handleSetAvailabilitiesSelected(range);
        break;
      case 'outsideRange':
        // If the guest selects a day outside their stay and it's not an available date
        setRange({
          from: new Date(data?.reservation?.arrivalDate),
          to: new Date(data?.reservation?.departureDate),
        });
        setSelectedAvailabilities([]);
        setFooter(t('booking.day_not_pickable'));
        break;
      default:
        // Handle other cases or reset
        setFooter(''); // Reset or provide a relevant message if necessary
        setSelectedAvailabilities([]);
        break;
    }
  };

  const handleSetAvailabilitiesSelected = (selectedRange: DateRange) => {
    // Ensure we have a from and to date in the selected range
    let newAvailabilities = [];
    if (selectedRange.from && selectedRange.to) {
      newAvailabilities = data?.availabilities.filter((a: any) => {
        return isWithinRange(
          addDays(new Date(a.date), 1),
          //@ts-ignore
          selectedRange?.from,
          selectedRange?.to,
        );
      });
    }

    setSelectedAvailabilities(newAvailabilities);
  };

  const onEditOrder = () => {
    trackMixpanelEvent('Booking - Edit Cart', {
      ...checkoutMetadata,
    });
    setIsProcessingPayment(false);
  };

  return (
    <>
      <style>{css}</style>
      {isLoadingAvailabilities ? (
        <Loader />
      ) : (
        <div className="h-full w-full px-6 lg:px-20">
          <h1 className="font-satoshiBold text-left text-4xl text-black mt-2 lg:mt-[4vh]">
            {t('booking.hi', { name: data?.reservation?.firstName })},
          </h1>
          <div className=" lg:hidden h-56 mt-4">
            <img
              className="object-cover object-center w-full h-full rounded-2xl"
              src={
                data?.home?.picture ||
                'https://a0.muscache.com/pictures/miso/Hosting-39758327/original/60631c6a-7e69-407f-a5a5-31218d7906a8.jpeg'
              }
              alt={data?.home?.name}
            />
          </div>
          <div className="lg:hidden flex flex-row justify-center mt-4 pb-3 border-b-[1px] border-grey">
            <div className="w-1/2 text-left font-satoshi">
              <div className="text-black">{t('booking.check_in')}</div>
              <div className="text-grey first-letter:capitalize">
                {formatDateToWeekdayDayMonth(data?.reservation?.arrivalDate)}
              </div>
              <div className="text-grey">{data?.reservation?.checkIn}PM</div>
            </div>
            <div className="w-[1px] bg-grey my-1" />
            <div className="w-1/2 text-right font-satoshi">
              <div className="text-black">{t('booking.check_out')}</div>
              <div className="text-grey first-letter:capitalize">
                {formatDateToWeekdayDayMonth(data?.reservation?.departureDate)}
              </div>
              <div className="text-grey">{data?.reservation?.checkOut}AM</div>
            </div>
          </div>
          <div className="flex flex-col my-2 lg:hidden ">
            <h2 className="font-satoshiBold text-left text-xl lg:text-2xl text-black lg:pl-3 lg:pt-3 mb-2">
              {data?.home?.name}
            </h2>
            <p className="text-[#6b7194] text-md text-left lg:pl-3">
              {t('booking.description', {
                discount: data?.discountPercentage,
              })}
            </p>
          </div>
          <div className="flex items-start justify-between flex-col lg:flex-row lg:mt-4 gap-4">
            <div className="flex flex-col w-full lg:w-3/4 lg:gap-4">
              <div className="flex flex-col w-full gap-1 lg:shadow-cozyup rounded-2xl p-2">
                <div className="hidden lg:flex w-full h-56">
                  <img
                    className="object-cover object-center w-full h-full rounded-2xl"
                    src={
                      data?.home?.picture ||
                      'https://a0.muscache.com/pictures/miso/Hosting-39758327/original/60631c6a-7e69-407f-a5a5-31218d7906a8.jpeg'
                    }
                    alt={data?.home?.name}
                  />
                </div>
                <div className="flex flex-col lg:flex-row-reverse lg:gap-4">
                  <div className="w-full flex flex-col items-center lg:items-start justify-start lg:w-2/5 ">
                    <h2 className="font-satoshiBold text-left text-xl lg:text-2xl text-black lg:pl-3 lg:pt-3">
                      {t('booking.calendar_header')}
                    </h2>
                    {data?.isStayExtendable === false ? (
                      <div className="flex justify-center items-center w-full h-[200px]">
                        <span className="text-checkout">
                          {t('booking.not_extendable')}
                        </span>
                      </div>
                    ) : (
                      <DayPicker
                        mode="range"
                        selected={range}
                        onSelect={(e) => handleOnSelectDate(e)}
                        components={{
                          DayContent: CustomDayComponent,
                        }}
                        showOutsideDays
                        fixedWeeks
                        locale={dateFnslocales[i18n.language]}
                        modifiersClassNames={{
                          selected: 'cu-selected',
                          today: 'cu-today',
                          footer: 'text-red',
                        }}
                        footer={footer}
                      />
                    )}
                  </div>
                  <div className="lg:hidden bg-grey h-[2px] w-full my-6" />
                  <div className="flex flex-col w-full lg:w-3/5 text-left">
                    <div className="hidden lg:flex flex-col mb-4">
                      <h2 className="font-satoshiBold text-left text-xl lg:text-2xl text-black lg:pl-3 lg:pt-3 mb-2">
                        {data?.home?.name}
                      </h2>
                      <p className="text-[#6b7194] text-md lg:pl-3">
                        {t('booking.description', {
                          discount: data?.discountPercentage,
                        })}
                      </p>
                    </div>
                    <div className="flex flex-col w-full justify-start lg:p-3">
                      <h3 className="font-satoshiBold text-left text-md lg:text-lg text-black mb-2">
                        {t('booking.reservation_header')}
                      </h3>
                      <CURowText
                        label={t('booking.booking_id')}
                        labelClass="text-sm"
                        valueClass={'text-sm text-grey'}
                        value={
                          data?.reservation?.referenceId ||
                          data?.reservation.reservationId
                        }
                      />
                      <CURowText
                        label={t('booking.guest_name')}
                        labelClass="text-sm"
                        value={data?.reservation?.guestName}
                        valueClass={'text-sm text-grey'}
                      />
                      <div className="hidden lg:flex lg:flex-col">
                        <CURowText
                          label={t('booking.check_in_date')}
                          labelClass="text-sm"
                          value={formatDateToWeekdayDayMonth(
                            data?.reservation?.arrivalDate,
                          )}
                          valueClass={'text-sm text-grey'}
                        />
                        <CURowText
                          label={t('booking.check_out_date')}
                          labelClass="text-sm"
                          value={formatDateToWeekdayDayMonth(
                            data?.reservation?.departureDate,
                          )}
                          valueClass={'text-sm text-grey'}
                        />
                        <CURowText
                          label={t('booking.total_guests')}
                          labelClass="text-sm"
                          value={data?.reservation?.totalGuests}
                          valueClass={'text-sm text-grey'}
                        />
                        <CURowText
                          label={t('booking.total_paid')}
                          labelClass="text-sm"
                          value={displayPrice(
                            data?.reservation?.totalPrice,
                            '€',
                          )}
                          valueClass={'text-sm text-grey'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:hidden bg-grey h-[2px] w-full my-6" />
            <div className="mt-4 lg:mt-0 w-full lg:w-1/4 lg:sticky flex flex-col">
              <BookingCart
                currency={data?.home?.workspace?.currency || 'eur'}
                onEditOrder={onEditOrder}
                isProcessingPayment={isProcessingPayment}
                availibilities={data?.availibilities}
                reservationId={reservationId}
                home={data?.home}
                onValidate={() => setIsProcessingPayment(true)}
                availabilities={selectedAvailabilities}
                checkoutMetadata={checkoutMetadata}
                tripDates={range}
              />
            </div>
          </div>
          <div className="pb-5 lg:pb-0 lg:absolute lg:bottom-2">
            <div className="footer-title">
              {t('powered_by')}
              <span
                className="logo-text"
                onClick={() => window.open('https://cozyup.com/', '_blank')}
              >
                &nbsp;CozyUp
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
