import React, { useEffect, useState } from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import Input from '../../components/input/input.component';

// import { useMutation } from 'react-query';
import SubmitButton from '../submit-button/submit-button.component';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { createHome, importFromAirbnb } from '../../api/homes';
import { useAuth } from '../../contexts/authContext';
import Select from '../select/select.component';
import TextArea from '../text-area/text-area.component';
import { Icon } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CheckoutArea,
  CheckoutAreaOptions,
} from '../../types/checkout-area.model';
import { getCheckoutAreasByWorkspace } from '../../api/checkoutAreas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useAppDispatch } from '../../hooks/useStore';
import { fetchOnboardingStatus } from '../../redux/global/globalActions';
import { useNavigate } from 'react-router-dom';
import { Home } from '../../types/home.model';
import AirbnbLogo from '../../assets/images/airbnb-logo.svg';
import AirbnbStar from '../../assets/images/airbnb-star.svg';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import useSocket from '../../hooks/useSocket';
import { getStripeConnectAccountByWorkspaceId } from '../../api/connectAccounts';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  refetchHomes: Function;
};

export const ImportFromAirbnbModal = ({
  displayModal,
  setDisplayModal,
  refetchHomes,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isImported, setIsImported] = useState<boolean>(false);
  const [urlImport, setUrlImport] = useState<string>('');
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const [checkoutAreasOptions, setCheckoutAreasOptions] = useState<
    CheckoutAreaOptions[]
  >([]);
  const [importError, setImportError] = useState<string>('');

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetForm,
    setError,
    clearErrors,
  } = useForm();
  const { sendEvent } = useSocket();

  const backendErrors = '';
  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };
  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const handleCheckoutAreasOptions = (checkoutAreas: CheckoutArea[]) => {
    const caOptions = checkoutAreas?.map((ca: CheckoutArea) => {
      return { label: ca.name, key: ca._id };
    });
    setCheckoutAreasOptions(caOptions);
  };

  useQuery(
    ['checkoutAreas'],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      onSuccess: (data: CheckoutArea[]) => {
        handleCheckoutAreasOptions(data);
      },
    },
  );

  const { mutate: createNewHome, isLoading: isLoadingCreationHome } =
    useMutation(
      (data: Home) =>
        createHome(currentUser.accessToken, currentWorkspace._id, data),
      {
        onSuccess: (response: any) => {
          const homeId = response._id;
          trackMixpanelEvent(
            'Home - Create from AirBnB import success',
            mixpanelProperties,
          );
          sendEvent('home:translation:start', {
            homeIds: [homeId],
            lang: i18n.language.slice(0, 2),
          });
          dispatch(
            fetchOnboardingStatus(
              currentUser.accessToken,
              currentWorkspace._id,
            ),
          );
          setDisplayModal(false);
          refetchHomes();
          resetImport();
          navigate('/homes');
        },
        onError(e: any) {
          if (e.type === 'HOME.CUSTOM_ID_EXISTS') {
            setError('customId', { type: 'custom', message: t(e.message) });
          }
        },
      },
    );

  const {
    mutate: importProperty,
    data: propertyImported,
    isLoading: isLoadingProperty,
    reset: resetImport,
  } = useMutation(
    (propertyId: string) =>
      importFromAirbnb(currentUser.accessToken, propertyId),
    {
      onSuccess: () => {
        trackMixpanelEvent(
          'Home - Import from Airbnb success',
          mixpanelProperties,
        );
        setIsImported(true);
      },
      onError: (error: any) => {
        setImportError(t(error.code || 'errors.unknown_error'));
      },
    },
  );
  const getPropertyIdFromUrl = (url: any) => {
    const matches = url.match(/rooms\/(\d+)/) || url.match(/listings\/(\d+)/);
    return matches ? matches[1] : null;
  };

  const handleImportProperty = () => {
    setImportError('');
    trackMixpanelEvent('Home - Start import from AirBnB', mixpanelProperties);
    const propertyId = getPropertyIdFromUrl(urlImport);
    if (!propertyId) return;
    importProperty(propertyId);
  };

  useQuery(
    ['stripeConnectAccount', currentUser, currentAccount],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentAccount,
      onSuccess: (data: any) => handleBankAccountOptions(data),
    },
  );

  const handleBankAccountOptions = (bankAccounts: any) => {
    const bankAccountOptions = bankAccounts?.map((ca: any) => {
      return { label: ca.name, key: ca._id };
    });
    setBankAccountOptions(bankAccountOptions);
  };

  const handleOnUrlChange = (e: any) => {
    const urlValue = e.target.value;
    setImportError('');
    setUrlImport(e.target.value);
    const airbnbUrlPattern =
      /^https:\/\/.*airbnb\.[a-z]{2,3}\/(rooms\/\d+|hosting\/listings\/\d+\/details)(\?.*)?$/;
    if (!urlValue) {
      setError('airbnbURL', {
        type: 'manual',
        message: t('errors.field_required'),
      });
    } else if (!airbnbUrlPattern.test(urlValue)) {
      setError('airbnbURL', {
        type: 'manual',
        message: t('errors.airbnb_url_invalid'),
      });
    } else {
      clearErrors('airbnbURL'); // Clear the error if the new value is valid
    }
  };

  useEffect(() => {
    return () => {
      resetForm();
      resetImport();
    };
  }, []);

  const submit = async (data: any) => {
    let newHome = {
      name: propertyImported?.name,
      headline: data.headline || null,
      description: data.description || null,
      picture: propertyImported?.imageUrl,
      customId: data?.customId || null,
      address: {
        street: '',
        streetNumber: '',
        zipcode: '',
        city: propertyImported?.location,
        country: '',
      },
      checkoutArea: data.checkoutArea || null,
      stripeConnectAccount: data.stripeConnectAccount || null,
    };

    createNewHome(newHome);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative w-[95%] flex flex-col justify-between w-full max-w-[800px] min-h-[350px] lg:min-h-[150px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[100px]">
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-[36px] mb-4">
                {t('listings.import_from_airbnb_header')}
              </h5>
            </div>
          </div>

          <div className="px-4 lg:px-10 py-10">
            {!propertyImported && (
              <div className="flex items-center">
                <div className="mt-6 flex-1">
                  <Controller
                    control={control}
                    render={() => (
                      <Input
                        type="text"
                        key="name"
                        value={urlImport}
                        helperText={t('listings.example_airbnb_link')}
                        onChange={handleOnUrlChange}
                        label={`${capitalizeFirstLetter(
                          t('listings.link_to_airbnb_listing'),
                        )}`}
                        errors={[_.get(errors, 'airbnbURL.message')]}
                      />
                    )}
                    name="airbnbURL"
                  />
                </div>
              </div>
            )}
            <div className="">
              <div className="text-red-600">{importError}</div>
              {propertyImported && (
                <div>
                  <div className="text-left mb-3">
                    <img
                      src={AirbnbLogo}
                      className="object-contain h-10 "
                      alt="Airbnb logo"
                    />
                  </div>
                  <div className="relative flex space-between w-full p-3 border border-checkoutPrimary rounded-2xl">
                    <div className="grid grid-cols-1 lg:flex gap-3 lg:flex-1">
                      <img
                        className="w-full lg:w-2/5 h-auto rounded-xl"
                        src={propertyImported?.imageUrl}
                        alt={propertyImported?.name}
                      />
                      <div className="w-full lg:w-3/5">
                        <h3 className="text-left text-[22px] lg:text-[26px] font-default text-[#222222]">
                          {propertyImported?.name}
                        </h3>
                        <div className="flex items-center justify-start font-semibold">
                          <img
                            className="w-2 h-2 mr-1"
                            src={AirbnbStar}
                            alt="Airbnb star"
                          />
                          <p className="text-left text-[10px] lg:text-[14px] font-default mr-1">
                            {propertyImported?.guestSatisfactionOverall}
                          </p>
                          {'•'}
                          <p className="text-left text-[10px] lg:text-[14px] font-default pl-1 underline">
                            {propertyImported?.visibleReviewCount}{' '}
                            {t('listings.reviews', {
                              count: parseInt(
                                propertyImported?.visibleReviewCount,
                              ),
                            })}
                          </p>
                          <span className="m-2">{'•'}</span>
                          <p className="text-left text-[10px] lg:text-[14px] font-default underline">
                            {propertyImported?.location}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-2 right-2 lg:top-1 lg:right-1">
                      <Icon
                        className="text-middleGrey hover:text-red-500 cursor-pointer"
                        fontSize="medium"
                        component={CancelIcon}
                        onClick={resetImport}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {propertyImported && (
              <div className="flex w-full gap-4 mt-12">
                <div className="w-full">
                  <h5
                    className="mb-6"
                    style={{ textAlign: 'left', marginTop: 8 }}
                  >
                    {t('settings.stripe.account')}
                  </h5>
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    defaultValue={
                      currentWorkspace.stripeConnectAccounts[0]?._id || ''
                    }
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        key="stripeConnectAccount"
                        onChange={onChange}
                        value={value}
                        options={bankAccountOptions}
                        errors={[_.get(errors, 'stripeConnectAccount.message')]}
                      />
                    )}
                    name={'stripeConnectAccount'}
                  />
                  <div className="mb-6">
                    <h5 style={{ textAlign: 'left', margin: 0 }}>
                      {t('listings.checkout-page')}
                    </h5>
                  </div>
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={t('onboarding.default_headline')}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          key="headline"
                          tooltip={t('listings.headline_tooltip')}
                          label={t('listings.headline')}
                          size="small"
                          onChange={onChange}
                          value={value}
                          placeholder={t('listings.headline_placeholder')}
                          errors={[
                            _.get(errors, 'headline.message'),
                            ...backendErrorsForAttribute('headline'),
                          ]}
                        />
                      )}
                      name={'headline'}
                    />
                    <Controller
                      control={control}
                      defaultValue={t('onboarding.default_description')}
                      render={({ field: { onChange, value } }: any) => (
                        <TextArea
                          key="description"
                          tooltip={t('listings.description_tooltip')}
                          label={t('listings.description')}
                          onChange={onChange}
                          value={value}
                          errors={[
                            _.get(errors, 'description.message'),
                            ...backendErrorsForAttribute('description'),
                          ]}
                        />
                      )}
                      name={'description'}
                    />
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={
                        checkoutAreasOptions && checkoutAreasOptions[0]
                      }
                      render={({ field: { onChange, value } }: any) => (
                        <Select
                          key="checkoutArea"
                          label={`${t('listings.available_extras')}`}
                          selectLabel={`${t('checkout-areas.select_store')}`}
                          onChange={onChange}
                          value={value}
                          tooltip={`${t('listings.checkout_area_tooltip')}`}
                          options={checkoutAreasOptions}
                          errors={[
                            _.get(errors, 'checkoutArea.message'),
                            ...backendErrorsForAttribute('checkoutArea'),
                          ]}
                        />
                      )}
                      name={'checkoutArea'}
                    />
                    <Controller
                      control={control}
                      rules={(Validation.noSpace, Validation.isUrlValid)}
                      defaultValue={null}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          key="customId"
                          size="small"
                          label={`${t('listings.custom_id')} (${t(
                            'optional',
                          )})`}
                          tooltip={`${t('listings.custom_id_help')}`}
                          onChange={onChange}
                          value={value}
                          helperText={`${document.location.origin}/cid/${
                            value || t('listings.your_custom_url')
                          }`}
                          errors={[_.get(errors, 'customId.message')]}
                        />
                      )}
                      name={'customId'}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="border-t px-4 py-4 gap-2 flex justify-end">
            {propertyImported ? (
              <div className="gap-2 flex justify-end">
                <button
                  title={t('cancel')}
                  className="btn btn-primary-outlined rounded-3xl"
                  onClick={() => setDisplayModal(false)}
                >
                  {t('cancel')}
                </button>
                <SubmitButton
                  disabled={!isImported}
                  title={t('add')}
                  customClass="btn btn-primary rounded-3xl flex"
                >
                  {!isLoadingCreationHome ? null : (
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      icon={faSpinnerThird}
                      spin
                    />
                  )}
                </SubmitButton>
              </div>
            ) : (
              <div
                className="cursor-pointer btn btn-primary flex"
                onClick={handleImportProperty}
              >
                <span>{t('import')}</span>
                {!isLoadingProperty ? null : (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                )}
              </div>
            )}
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};
