// hooks/useChannelManagersWithHandlers.tsx

/**
 * This hook is used to get all channel managers available in CozyUp and return
 * the list with the onClick() handlers
 */

/**
 * This hook is used to get all channel managers available in CozyUp and return
 * the list with the onClick() handlers
 */
import React, { useState } from 'react';
import channelManagers, {
  AVAIBOOK,
  BEDS24,
  BOOKING_SYNC,
  FANTASTIC_STAY,
  GUESTY,
  HOSPITABLE,
  HOSTAWAY,
  HOSTFULLY,
  HOSTIFY,
  LODGIFY,
  LODGIX,
  NONE,
  OWNERREZ,
  SMOOBU,
  SUPERHOTE,
  TOKEET,
  UPLISTING,
} from '../constantes/channel-managers';
import { useMutation, useQuery } from 'react-query';
import {
  getChannelManagersAvailableInCozyUp,
  integrateCalry,
} from '../api/channelManagers';
import { requestHospitableConnectAuth } from '../api/platforms';
import { useAuth } from '../contexts/authContext';
import useTrackOnboardingWithMixpanel from './useTrackOnboardingWithMixpanel';
import { IntegrateBeds24Modal } from '../components/modals/integrate-beds24-modal.component';
import { IntegrateBookingSyncModal } from '../components/modals/integrate-bookingsync-modal.component';
import { IntegrateSmoobuModal } from '../components/modals/integrate-smoobu-modal.component';
import useGetChannelManagersConnectedToWorkspace from './useGetChannelManagersConnectedToWorkspace';
import IntegrateSuperHoteModal from '../components/modals/integrate-superhote-modal/integrate-superhote-modal.component';
import { useLocation } from 'react-router-dom';
import { Beds24TutorialModal } from '../components/tutorials/beds24-tutorial-modal.component';

/**
 * Hook to get all channel managers with their onClick handlers
 * @param calryRedirectUrl
 * @param hospitableRedirectUrl
 * @param fromOnboarding - To know if the call is from the onboarding or not
 * @param filterChannelManagers - To filter the channel managers
 * @param skipStep
 */
const useChannelManagersWithHandlers = (
  calryRedirectUrl?: string,
  hospitableRedirectUrl?: string,
  fromOnboarding?: boolean,
  filterChannelManagers?: {},
  skipOnboardingStep?: Function,
) => {
  const { currentAccount, currentUser, currentWorkspace } = useAuth();
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();
  // Define other states as necessary
  const [displayBeds24Modal, setDisplayBeds24Modal] = useState<boolean>(false);
  const [displayBeds24TutorialModal, setDisplayBeds24TutorialModal] =
    useState<boolean>(false);
  const [displaySmoobuModal, setDisplaySmoobuModal] = useState<boolean>(false);
  const [displaySuperhoteModal, setDisplaySuperhoteModal] =
    useState<boolean>(false);
  const [displayBookinSyncModal, setDisplayBookingSyncModal] =
    useState<boolean>(false);
  const [enhancedChannelManagers, setEnhancedChannelManagers] = useState<any>(
    [],
  );

  const { refetchChannelManagersConnectedToWorkspace } =
    useGetChannelManagersConnectedToWorkspace();

  const location = useLocation();

  const REDIRECT_URL =
    process.env.REACT_APP_COZYUP_URL + location.pathname + location.search;
  // Get all channel managers available in CozyUp
  useQuery(
    ['channelManagersAvailableInCozyUp'],
    () =>
      getChannelManagersAvailableInCozyUp(
        currentUser.accessToken,
        filterChannelManagers,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => {
        // Set the list of channel managers with their onClick handlers
        if (fromOnboarding) {
          data.push({ name: NONE, type: NONE });
        }
        setEnhancedChannelManagers(createchannelManagersList(data));
      },
    },
  );

  // Dynamically attach onClick handlers and channel manager type config to each
  // channel manager
  const createchannelManagersList = (data: any): any => {
    if (fromOnboarding) {
      data = [...data, { name: NONE, type: NONE }];
    }

    return channelManagers
      .filter(({ type }) => {
        const isAvailable = data?.some((cm: any) => cm.name === type);
        return isAvailable;
      })
      .map((manager: any) => {
        const backendConfig = data?.find((cm: any) => cm.name === manager.type);

        return {
          ...manager,
          backendConfig,
          onClick: () => {
            switch (manager.type) {
              case NONE:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect NONE - Clicked`,
                );
                // Skip onboarding to next step if no CM/PMS to connect
                if (skipOnboardingStep) {
                  skipOnboardingStep();
                }
                break;
              case BEDS24:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect BEDS24 - Clicked`,
                );
                setDisplayBeds24TutorialModal(true);
                break;
              case BOOKING_SYNC:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect BOOKING_SYNC - Clicked`,
                );
                setDisplayBookingSyncModal(true);
                break;
              case SMOOBU:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect SMOOBU - Clicked`,
                );
                setDisplaySmoobuModal(true);
                break;
              case LODGIFY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect LODGIFY - Clicked`,
                );
                requestCalryIntegration('lodgify');
                break;
              case HOSTAWAY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect HOSTAWAY - Clicked`,
                );
                requestCalryIntegration('hostaway');
                break;
              case OWNERREZ:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect OWNERREZ - Clicked`,
                );
                requestCalryIntegration('OwnerRez');
                break;
              case UPLISTING:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect UPLISTING - Clicked`,
                );
                requestCalryIntegration('Uplisting');
                break;
              case LODGIX:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect LODGIX - Clicked`,
                );
                requestCalryIntegration('Lodgix');
                break;
              case HOSTIFY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect TOKEET - Clicked`,
                );
                requestCalryIntegration('Hostify');
                break;
              case HOSTFULLY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect HOSTFULLY - Clicked`,
                );
                requestCalryIntegration('Hostfully');
                break;
              case AVAIBOOK:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect AVAIBOOK - Clicked`,
                );
                requestCalryIntegration('Avaibook');
                break;
              case GUESTY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect GUESTY - Clicked`,
                );
                requestCalryIntegration('Guesty');
                break;
              case FANTASTIC_STAY:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect FANTASTIC_STAY - Clicked`,
                );
                requestCalryIntegration('FantasticStay');
                break;
              case TOKEET:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect TOKEET - Clicked`,
                );
                requestCalryIntegration('Tokeet');
                break;
              case HOSPITABLE:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect HOSPITABLE - Clicked`,
                );
                authRequestHospitableConnect();
                break;
              case SUPERHOTE:
                trackOnboardingEvent(
                  `${
                    fromOnboarding ? 'Onboarding' : 'Application'
                  } - Connect SUPERHOTE - Clicked`,
                );
                setDisplaySuperhoteModal(true);
                break;
              default:
                console.warn('Click handler not defined for:', manager.name);
            }
          },
        };
      });
  };

  const {
    mutate: requestCalryIntegration,
    isLoading: isLoadingRequestCalryIntegration,
  } = useMutation(
    (integrationDefinitionKey: string) =>
      integrateCalry(
        currentUser.accessToken,
        currentWorkspace._id,
        integrationDefinitionKey,
        REDIRECT_URL,
      ),
    {
      onSuccess: (response: any) => {
        window.location.href = response.link;
      },
    },
  );

  const {
    mutate: authRequestHospitableConnect,
    isLoading: isLoadingRequestHospitableConnect,
  } = useMutation(
    () =>
      requestHospitableConnectAuth(
        currentUser.accessToken,
        currentAccount._id,
        REDIRECT_URL,
      ),
    {
      onSuccess: (response: any) => {
        window.location.href = response.data.return_url;
      },
    },
  );

  const Beds24ModalComponent = () => (
    <IntegrateBeds24Modal
      onSuccessIntegration={refetchChannelManagersConnectedToWorkspace}
      displayModal={displayBeds24Modal}
      setDisplayModal={setDisplayBeds24Modal}
    />
  );

  const BookingSyncModalComponent = () => (
    <IntegrateBookingSyncModal
      displayModal={displayBookinSyncModal}
      setDisplayModal={setDisplayBookingSyncModal}
      redirectUrl={
        process.env.REACT_APP_BOOKING_SYNC_REDIRECT_URI_FROM_ONBOARDING
      }
    />
  );

  const SmoobuModalComponent = () => (
    <IntegrateSmoobuModal
      displayModal={displaySmoobuModal}
      setDisplayModal={setDisplaySmoobuModal}
      onSuccessIntegration={refetchChannelManagersConnectedToWorkspace}
    />
  );

  const SuperhoteModalComponent = () => (
    <IntegrateSuperHoteModal
      displayModal={displaySuperhoteModal}
      setDisplayModal={setDisplaySuperhoteModal}
      onSuccessIntegration={refetchChannelManagersConnectedToWorkspace}
    />
  );

  const Beds24TutorialModalComponent = () => (
    <>
      {!displayBeds24TutorialModal ? null : (
        <Beds24TutorialModal
          displayModal={displayBeds24TutorialModal}
          setDisplayModal={setDisplayBeds24TutorialModal}
          requestIntegration={() => requestCalryIntegration('beds24')}
          isLoadingRequestIntegration={isLoadingRequestCalryIntegration}
        />
      )}
    </>
  );

  // Return both the modified list and the state setters if needed outside
  return {
    enhancedChannelManagers,
    Beds24ModalComponent,
    BookingSyncModalComponent,
    SmoobuModalComponent,
    SuperhoteModalComponent,
    requestCalryIntegration,
    isLoadingRequestCalryIntegration,
    authRequestHospitableConnect,
    isLoadingRequestHospitableConnect,
    Beds24TutorialModalComponent,
  };
};

export default useChannelManagersWithHandlers;
