import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/pro-solid-svg-icons';
import { Icon, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkIcon from '@mui/icons-material/Link';
import { Home, HomesListResponse } from '../../../../types/home.model';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import { useAuth } from '../../../../contexts/authContext';
import { patchStatusHome, updateHome } from '../../../../api/homes';
import { useTranslation } from 'react-i18next';
import { faHome, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../loader/loader.component';
import { QrCode2Sharp } from '@mui/icons-material';
import QRCodeCaneva from '../../../qrcode-caneva/qrcode-caneva.component';
import QRCodeModal from '../../../modals/qr-code-modal/qr-code-modal.component';
import useQRCodeGenerator from '../../../../hooks/useQRCodeGenerator';
import { useNavigate } from 'react-router-dom';
import { STORE_TRANSLATION_ACTION } from '../../../../constantes/jobs';
import { JobStatus } from '../../../../redux/jobs/jobSlice';
import getCountryFlag from 'country-flag-icons/unicode';
import i18n from '../../../../i18n';
import { jobActions } from '../../../../redux/jobs/jobActions';
import useSocket from '../../../../hooks/useSocket';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useStore';
import DefaultHomePicture from '../../../../assets/images/default-home-picture.jpeg';
import ChannelManagerLogo from '../../../channel-manager-logo/channel-manager-logo.component';
import { Switch } from '@headlessui/react';
import Select from '../../../select/select.component';
import { useMutation, useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { CheckoutArea } from '../../../../types/checkout-area.model';
import { getCheckoutAreasByWorkspace } from '../../../../api/checkoutAreas';
import { isEmpty } from 'lodash';
import Pagination from '../../../pagination/pagination.component';

type Props = {
  homes: HomesListResponse;
  refreshHomes: Function;
  setSubRoute: any;
  isLoadingHomes: boolean;
  status: string;
  addHome: Function;
  isGuestNotificationAvailable: boolean;
  sortHomes: Function;
  setActivePage: Function;
};

const HomesList = ({
  setSubRoute,
  homes,
  refreshHomes,
  isLoadingHomes,
  status,
  addHome,
  isGuestNotificationAvailable,
  sortHomes,
  setActivePage,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentHome, setCurrentHome] = useState<Home>({} as Home);
  const [currentHomeQRCode, setCurrentHomeQRCode] = useState<Home>();
  const { generateQRCode, imageSrc, isModalOpen, setIsModalOpen } =
    useQRCodeGenerator();
  const [checkoutAreasOptions, setCheckoutAreasOptions] = useState([]);
  const [displayChangeStatusAlert, setDisplayChangeStatusAlert] =
    useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const { currentUser, currentWorkspace } = useAuth();
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state) => state.jobs);
  const { sendEvent } = useSocket();

  const getCheckoutUrl = (home: Home, previewMode?: boolean) => {
    if (home.customId) {
      return (
        document.location.origin +
        `/cid/${home.customId}${previewMode ? '?preview=true' : ''}`
      );
    }
    return (
      document.location.origin +
      `/c/${home._id}${previewMode ? '?preview=true' : ''}`
    );
  };

  // Set the current page submenu in the header
  useEffect(() => {
    setSubRoute('');
  }, []);

  // Everytime a QRCode is clicked,  currentHomeQRCode is updated. Then we generate the QRCode with the appropriate
  // home name. If generateQRCode called on button clicked, async state update is outdated before generating caneva.
  useEffect(() => {
    if (currentHomeQRCode) {
      generateQRCode(currentHome);
    }
  }, [currentHomeQRCode]);

  const handleChangeStatus = async (event: any, home: Home, action: string) => {
    event.stopPropagation();
    setAlertDescription(
      t('listings.listing_action', {
        action: t(`listings.${action}`),
        home: home.name,
      }),
    );
    setCurrentHome(home);
    setDisplayChangeStatusAlert(true);
  };

  const changeStatusClickedHome = async () => {
    if (currentHome) {
      const status = currentHome.status == 'archived' ? 'active' : 'archived';
      await patchStatusHome(
        currentUser.accessToken,
        currentWorkspace._id,
        currentHome._id,
        status,
      );
      refreshHomes();
    }
  };

  const [isCopiedUrl, setIsCopiedUrl] = useState(false);
  const copyUrl = (e: React.SyntheticEvent<EventTarget>, home: Home) => {
    e.stopPropagation();
    if (!isCopiedUrl) {
      setIsCopiedUrl(true);
      navigator.clipboard.writeText(getCheckoutUrl(home));
    }
  };

  const previewCheckout = (
    e: React.SyntheticEvent<EventTarget>,
    home: Home,
  ) => {
    e.stopPropagation();
    window.open(getCheckoutUrl(home, true));
  };

  const sendGenerateTranslationsEvent = (id: string) => {
    sendEvent('home:translation:start', {
      homeIds: [id],
      lang: i18n.language.slice(0, 2),
    });

    dispatch(
      jobActions.addJob({
        type: STORE_TRANSLATION_ACTION,
        id: `${STORE_TRANSLATION_ACTION}:${id}`,
        status: JobStatus.IN_PROGRESS,
        callback: () => refreshHomes(),
      }),
    );
  };

  const handleCheckoutAreasOptions = (checkoutAreas: any) => {
    const caOptions = checkoutAreas?.map((ca: CheckoutArea) => {
      return { label: ca.name, key: ca._id };
    });
    setCheckoutAreasOptions(caOptions);
  };

  useQuery(
    ['checkoutAreas'],
    () =>
      getCheckoutAreasByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    { onSuccess: (data: any) => handleCheckoutAreasOptions(data) },
  );

  const { mutate: patchHome } = useMutation(
    (data: Home) =>
      updateHome(currentUser.accessToken, currentWorkspace._id, data._id, data),
    {
      onSuccess: () => {
        refreshHomes();
        toast.success(t('listings.checkout_area_updated'));
      },
      onError: () => {
        toast.error(t('listings.checkout_area_updated_failed'));
      },
    },
  );

  return (
    <>
      <AlertBeforeAction
        title={t('are_you_sure')}
        description={alertDescription}
        successAction={changeStatusClickedHome}
        isVisible={displayChangeStatusAlert}
        setIsVisible={setDisplayChangeStatusAlert}
      />

      <QRCodeModal
        home={currentHome}
        image={imageSrc}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      {/* CODE TO GENERATE THE QR CODE - HIDDEN IN FRONTEND*/}
      {currentHomeQRCode && (
        <QRCodeCaneva
          url={getCheckoutUrl(currentHomeQRCode)}
          homeName={currentHomeQRCode?.name}
        />
      )}
      {!homes || homes?.metadata?.totalPages === 0 ? null : (
        <Pagination
          totalPagesCount={homes?.metadata?.totalPages}
          currentPage={homes?.metadata?.currentPage}
          totalItems={homes?.metadata?.totalCount}
          onChangePage={setActivePage}
        />
      )}
      <table className="cozyup hidden lg:table">
        <thead>
          <tr>
            <th>📸</th>
            <th
              className="cursor-pointer"
              onClick={() => sortHomes('name', 'ASC')}
            >
              {t('listings.name')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>{t('address.city')}</th>
            <th>{t('listings.checkout_area')}</th>
            {isGuestNotificationAvailable && (
              <th>{t('listings.guest_notification')}</th>
            )}
            <th>{t('languages')}</th>
            <th
              className="cursor-pointer"
              onClick={() => sortHomes('status', 'ASC')}
            >
              {t('listings.status')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>{t('listings.share')}</th>
            <th />
            <th />
          </tr>
        </thead>
        {isLoadingHomes ? null : (
          <tbody className="mt-6">
            {homes?.items?.map((home: Home) => {
              const translatingHome =
                // @ts-ignore
                jobs.jobs[`${STORE_TRANSLATION_ACTION}:${home._id}`] ===
                JobStatus.IN_PROGRESS;
              const flags = home?.translations?.map((translation: any) => {
                return getCountryFlag(
                  translation.languageCode === 'en'
                    ? 'GB'
                    : translation.languageCode.toUpperCase(),
                );
              });
              return (
                <tr
                  key={home._id}
                  onClick={() => navigate(`${home._id}`)}
                  className={`cursor-pointer ${
                    home.checkoutArea ? '' : 'bg-amber-100'
                  }`}
                >
                  <td>
                    <div className="w-32 h-18 rounded-2xl">
                      <img
                        className="background-image rounded-2xl"
                        src={home && (home?.picture || DefaultHomePicture)}
                        alt="image-home"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="flex flex-col gap-2 text-left">
                      {home.name}
                      {home.channelManagerConnection?.channelManagerType
                        ?.name && (
                        <div className="relative w-24 flex items-cente flex-col justify-start">
                          <ChannelManagerLogo
                            type={
                              home.channelManagerConnection.channelManagerType
                                .name
                            }
                          />
                          <div className="absolute right-0 bottom-[-12px] border-green border-[1px] rounded-full text-green px-1 text-[8px] uppercase">
                            {t('connected')}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>{home?.address?.city}</td>
                  <td onClick={(e: any) => e.stopPropagation()}>
                    <Select
                      key={`stripeConnectAccount-${home._id}`}
                      onChange={(e: any) => {
                        patchHome({
                          _id: home._id,
                          checkoutArea: e.target.value,
                        });
                      }}
                      value={home?.checkoutArea?._id}
                      style={{
                        marginBottom: 0,
                        width: 130,
                      }}
                      options={checkoutAreasOptions}
                    />
                  </td>
                  {isGuestNotificationAvailable && (
                    <td className="opacity-50">
                      <div className=" h-full flex justify-center items-center">
                        {!isGuestNotificationAvailable ||
                        isEmpty(home.channelManagerConnection) ? (
                          'N/A'
                        ) : (
                          <Switch
                            checked={
                              home.channelManagerConnection
                                ?.automaticMessagesEnabled
                            }
                            className={`${
                              home.channelManagerConnection
                                ?.automaticMessagesEnabled
                                ? 'bg-green'
                                : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            onChange={() => console.warn('not implemented')}
                          >
                            <span
                              className={`${
                                home.channelManagerConnection
                                  ?.automaticMessagesEnabled
                                  ? 'translate-x-6'
                                  : 'translate-x-1'
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                        )}
                      </div>
                    </td>
                  )}
                  <td>
                    <div className="flex justify-center">
                      <Tooltip
                        title={
                          flags?.length > 0
                            ? flags.join(' ')
                            : translatingHome
                            ? t('translating')
                            : t('translate')
                        }
                        onClick={(e) => {
                          if (!home?.translations?.length) {
                            e.stopPropagation();
                            sendGenerateTranslationsEvent(home._id);
                          }
                        }}
                      >
                        <div className="hover:bg-gray-300 h-7 w-7 rounded-full flex items-center justify-center">
                          {translatingHome ? (
                            <FontAwesomeIcon icon={faSpinnerThird} spin />
                          ) : (
                            home?.translations?.length
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </td>

                  <td>
                    <span
                      className={`capitalize py-0.5 px-3  text-sm font-semibold rounded-2xl ${
                        home.status === 'active'
                          ? 'bg-lightGreen  text-green'
                          : 'bg-lightYellow text-yellow'
                      }`}
                    >
                      {t(`listings.${home.status}`)}
                    </span>
                  </td>
                  <td>
                    {home.status === 'archived' || !home.checkoutArea ? (
                      '_'
                    ) : (
                      <div className="flex items-center justify-center">
                        <Tooltip
                          title={`${
                            isCopiedUrl
                              ? t('listings.copied_url')
                              : t('listings.copy_url')
                          }`}
                        >
                          <div
                            className="hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer"
                            onClick={(e: React.SyntheticEvent<EventTarget>) => {
                              copyUrl(e, home);
                            }}
                            onMouseLeave={() =>
                              setTimeout(() => {
                                setIsCopiedUrl(false);
                              }, 400)
                            }
                          >
                            <Icon
                              className="h-10 w-10 -rotate-45  text-gray-500 "
                              fontSize="small"
                              component={LinkIcon}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title={`${t('listings.qr_code')}`}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentHomeQRCode(home);
                            }}
                            className=" hover:bg-gray-300 rounded-full p-2  text-gray-500 cursor-pointer"
                          >
                            <Icon
                              className="h-10 w-10 text-gray-500"
                              fontSize="small"
                              component={QrCode2Sharp}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                  <td>
                    {home.checkoutArea ? null : (
                      <Tooltip title={`${t('listings.no_checkoutarea')}`}>
                        <span className="cursor-pointer mr-4 text-xl">
                          &#9888;&#65039;{' '}
                        </span>
                      </Tooltip>
                    )}
                    {home.stripeConnectAccount ? null : (
                      <Tooltip title={`${t('settings.stripe.not-linked')}`}>
                        <span className="cursor-pointer mr-4 text-xl">
                          &#9888;&#65039;{' '}
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <td>
                    <div className="flex justify-end">
                      {home.status === 'archived' || !home.checkoutArea ? (
                        <div></div>
                      ) : (
                        <Tooltip title={`${t('listings.preview')}`}>
                          <div
                            onClick={(e) => {
                              previewCheckout(e, home);
                            }}
                            className=" hover:bg-gray-300 rounded-full p-2  text-gray-500 cursor-pointer"
                          >
                            <Icon
                              className="h-10 w-10 text-gray-500"
                              fontSize="small"
                              component={VisibilityIcon}
                            />
                          </div>
                        </Tooltip>
                      )}

                      {home.status === 'active' ? (
                        <Tooltip
                          title={`${t('listings.archive')}`}
                          classes={{ tooltip: 'capitalize' }}
                        >
                          <div
                            className=" hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer"
                            onClick={(e) =>
                              handleChangeStatus(e, home, 'archive')
                            }
                          >
                            <Icon
                              className="h-10 w-10 text-gray-500"
                              fontSize="small"
                              component={ArchiveIcon}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={`${t('listings.activate')}`}
                          classes={{ tooltip: 'capitalize' }}
                          onClick={(e) =>
                            handleChangeStatus(e, home, 'activate')
                          }
                        >
                          <div
                            className=" hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer"
                            onClick={(e) =>
                              handleChangeStatus(e, home, 'activate')
                            }
                          >
                            <Icon
                              className="h-10 w-10 text-gray-500"
                              fontSize="small"
                              component={UnarchiveIcon}
                            />
                          </div>
                        </Tooltip>
                      )}
                      <div className="hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer">
                        <Icon
                          className="h-10 w-10 text-gray-500"
                          fontSize="small"
                          component={MoreVertIcon}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="lg:hidden">
        <div className="flex flex-col mt-6">
          {homes?.items?.map((home: Home, i: number) => (
            <div
              key={home._id}
              onClick={() => navigate(`${home._id}`)}
              className={`rounded-xl border flex flex-col p-3 mb-6 bg-lightblue ${
                home.checkoutArea ? '' : 'bg-amber-100'
              } shadow-xl`}
            >
              <div>
                <div className="relative mb-2">
                  <img
                    className="rounded-xl h-52 w-full object-cover"
                    src={home && (home?.picture || DefaultHomePicture)}
                    alt="image-home"
                  />
                </div>
                <div className="flex justify-between mb-2 text-lg text-left font-bold">
                  <div>
                    <span className="mx-1">#{i + 1}</span>
                    <span className="mx-2 first-letter:capitalize">
                      {home.name}
                    </span>
                    {home.checkoutArea ? null : (
                      <Tooltip title={`${t('listings.no_checkoutarea')}`}>
                        <span className="cursor-pointer mr-4 text-xl">
                          &#9888;&#65039;{' '}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    <span
                      className={`capitalize py-0.5 px-3 text-xs font-semibold rounded-2xl ${
                        home.status === 'active'
                          ? 'bg-lightGreen  text-green'
                          : 'bg-lightYellow text-yellow'
                      }`}
                    >
                      {t(`listings.${home.status}`)}
                    </span>
                  </div>
                </div>
                <div className="my-4 text-left">
                  <div>
                    <span className="font-semibold">{t('address.city')}: </span>
                    <span className="">{home.address?.city}</span>
                  </div>
                  <div>
                    <span className="font-semibold">
                      {t('listings.checkout_area')}:{' '}
                    </span>
                    <span className="">{home?.checkoutArea?.name || '-'}</span>
                  </div>
                </div>
              </div>

              <div className="flex justify-around mt-6">
                {home.status === 'archived' || !home.checkoutArea ? (
                  ''
                ) : (
                  <Tooltip
                    title={`${t('listings.copy_url')}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(getCheckoutUrl(home));
                    }}
                  >
                    <Icon
                      className="icon -rotate-45 hover:bg-gray-300 rounded-full h-10 w-10 text-gray-500 cursor-pointer"
                      fontSize="small"
                      component={LinkIcon}
                    />
                  </Tooltip>
                )}
                {home.status === 'archived' || !home.checkoutArea ? null : (
                  <Tooltip
                    title={`${t('listings.preview')}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(getCheckoutUrl(home));
                    }}
                  >
                    <Icon
                      className="icon  hover:bg-gray-300 rounded-full h-10 w-10 text-gray-500 cursor-pointer"
                      fontSize="small"
                      component={VisibilityIcon}
                    />
                  </Tooltip>
                )}

                {home.status === 'active' ? (
                  <Tooltip
                    title={`${t('listings.archive')}`}
                    classes={{ tooltip: 'capitalize' }}
                    onClick={(e) => handleChangeStatus(e, home, 'archive')}
                  >
                    <Icon
                      className="icon  hover:bg-red-100 rounded-full h-10 w-10 text-red-500 cursor-pointer"
                      fontSize="small"
                      component={ArchiveIcon}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`${t('listings.activate')}`}
                    classes={{ tooltip: 'capitalize' }}
                    onClick={(e) => handleChangeStatus(e, home, 'activate')}
                  >
                    <Icon
                      className="icon  hover:bg-green-100 rounded-full h-10 w-10 text-green-500 cursor-pointer"
                      fontSize="small"
                      component={UnarchiveIcon}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {isLoadingHomes && (
        <div className="flex justify-center items-center height-[150px]">
          <Loader />
        </div>
      )}
      {homes?.items?.length || isLoadingHomes ? null : (
        <div className="mt-10">
          <div className="flex-row mb-10">
            <FontAwesomeIcon
              className="opacity-20 mb-4"
              color="#b6b9ce"
              icon={faHome}
              size="7x"
            />
            <div className="text-sm font-semibold text-[#6B7194] opacity-20">
              {t('tables.empty-homes')}
            </div>
          </div>
          {status !== 'archived' && (
            <button
              onClick={() => addHome()}
              className="btn btn-primary-outlined rounded-3xl"
            >
              {t('add')}
            </button>
          )}
        </div>
      )}
      {!homes || homes?.metadata?.totalPages === 0 ? null : (
        <Pagination
          totalPagesCount={homes?.metadata?.totalPages}
          currentPage={homes?.metadata?.currentPage}
          totalItems={homes?.metadata?.totalCount}
          onChangePage={setActivePage}
        />
      )}
    </>
  );
};

export default HomesList;
