import React, { useEffect, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import useTrackMixpanelEvent from '../../../hooks/useTrackMixPanelEvent';
import stripeConfig from '../../../services/stripe';
import { getPaymentIntentSecret } from '../../../api/orders';
import { displayPrice, getCurrencyInfo } from '../../../utils/currency';
import Button from '../../button/button.component';
import { format } from 'date-fns';
import NightUpsellCheckoutForm from '../../night-upsell-checkout-form/night-upsell-checkout-form.component';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

type PaymentIntentCreateData = {
  products: any[];
  stripeConnectAccountId: string;
  description: string;
  currency: string;
  percentageFeeHost: number;
};

const BookingCart = ({
  home,
  reservationId,
  isProcessingPayment,
  onValidate,
  onEditOrder,
  currency,
  availabilities,
  checkoutMetadata,
  tripDates,
}: any) => {
  const { t } = useTranslation();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const checkoutRef = useRef<any>(null);
  const currencySymbol = getCurrencyInfo(currency).symbol;
  const stripeConnectId =
    home?.nightUpsellStripeConnectAccount?.stripeConnectId || null;
  const stripeCompleteConf = stripeConfig(stripeConnectId);
  const description = `commande pour : ${home?.name}`;
  const [totalAmountWithDiscount, setTotalAmountWithDiscount] = useState(0);
  const discountAmount = availabilities?.reduce(
    (total: number, { basePriceCents, priceWithDiscountCents }: any) =>
      total + (basePriceCents - priceWithDiscountCents),
    0,
  );
  const totalAmount = availabilities?.reduce(
    (sum: number, { basePriceCents }: any) => sum + basePriceCents,
    0,
  );

  useEffect(() => {
    if (!availabilities?.length) return;
    const newAmountWithDiscount = availabilities.reduce(
      (sum: number, { priceWithDiscountCents }: any) =>
        sum + priceWithDiscountCents,
      0,
    );
    setTotalAmountWithDiscount(newAmountWithDiscount);
  }, [availabilities]);

  const validateCart = async () => {
    const data = {
      availabilities,
      stripeConnectAccountId: stripeConnectId,
      description: description,
      currency: home.workspace.currency || 'eur',
      percentageFeeHost: home.workspace.account.percentageFeeHost,
    };

    trackMixpanelEvent('Booking - Cart validated', {
      ...checkoutMetadata,
      availabilities: data.availabilities,
      currency: data.currency,
      percentageFeeHost: data.percentageFeeHost,
    });

    createPaymentIntent(data);
  };

  const {
    data: paymentIntentSecret,
    mutate: createPaymentIntent,
    isLoading: isLoadingCreatePaymentIntent,
  } = useMutation(
    (data: PaymentIntentCreateData) => getPaymentIntentSecret(data),
    {
      onSuccess: () => {
        onValidate();
        if (checkoutRef.current) {
          checkoutRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
      onError: () => {
        toast.error(t('errors.unknown_error'));
      },
    },
  );

  return (
    <div className="w-full flex flex-col lg:shadow-cozyup rounded-2xl">
      <div>
        {!isProcessingPayment ? null : (
          <div className="flex justify-end pr-6">
            <button className="checkout-edit-button" onClick={onEditOrder}>
              {t('edit')}
            </button>
          </div>
        )}
        <div className="">
          <h2 className="font-satoshiBold text-left text-xl lg:text-2xl text-black lg:pl-3 lg:pt-3">
            {t('booking.cart_header')}
          </h2>
        </div>
        <hr className="border-1 my-3 lg:mx-6" />
        <div className="lg:px-6">
          <h3 className="font-satoshiBold text-left text-md text-black ml-[10px] mb-1">
            {t('booking.your_trip')}
          </h3>
          <div className="product-container">
            <div className="product-info">
              <div className="product-name text-left">
                {t('booking.arrival_date')}
              </div>
            </div>
            <div className="product-price">
              {format(tripDates?.from, 'dd/MM/yyyy')}
            </div>
          </div>
          <div className="product-container">
            <div className="product-info">
              <div className="product-name text-left">
                {t('booking.departure_date')}
              </div>
            </div>
            <div className="product-price">
              {format(tripDates?.to, 'dd/MM/yyyy')}
            </div>
          </div>
        </div>
        {!availabilities?.length ? null : (
          <>
            <hr className="border-1 my-3 lg:mx-6" />
            <div>
              <div className="lg:px-6">
                <h3 className="font-satoshiBold text-left text-md text-black ml-[10px] mb-1">
                  {t('booking.details')}
                </h3>
                <div className="product-container">
                  <div className="product-info">
                    <div className="product-name text-left">
                      {`x${availabilities?.length} ${t('booking.night', {
                        count: availabilities?.length,
                      })}`}
                    </div>
                  </div>
                  <div className="product-price">
                    {displayPrice(totalAmount, currencySymbol, {
                      thousandSeparator: ' ',
                    })}
                  </div>
                </div>
              </div>
              <div className="lg:px-6">
                <div className="product-container">
                  <div className="product-info">
                    <div className="product-name text-left">
                      {t('discount')}
                    </div>
                  </div>
                  <div className="product-price !text-green">
                    -
                    {displayPrice(discountAmount, currencySymbol, {
                      thousandSeparator: ' ',
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <hr className="border-1 my-3 lg:mx-6" />
        <div className="lg:px-6 pt-4 pb-2 flex justify-between items-center ">
          <h3 className="font-satoshiBold text-left text-md text-black ml-[10px] mb-1">
            {t('checkout.subtotal')}
          </h3>
          {!availabilities?.length ? null : (
            <div className=" text-sm">
              {displayPrice(totalAmountWithDiscount, currencySymbol, {
                thousandSeparator: ' ',
              })}
            </div>
          )}
        </div>
        <div className="lg:px-6 pt-2 flex justify-between items-center text-[#a2a2a2] ml-[10px]">
          <h3 className="text-sm">{t('booking.booking_fee')}</h3>
          {!availabilities?.length ? (
            '-'
          ) : (
            <div className="text-sm">0{currencySymbol}</div>
          )}
        </div>
        <div className="lg:px-6 pb-4 pt-2 flex justify-between items-center text-[#a2a2a2] ml-[10px]">
          <h3 className="text-sm">{t('booking.cleaning_fee')}</h3>
          {!availabilities?.length ? (
            '-'
          ) : (
            <div className="text-sm">0{currencySymbol}</div>
          )}
        </div>
        <hr className="border-1 my-3 lg:mx-6" />
        <div className="lg:px-6 total">
          <h3 className="font-satoshiBold text-left text-lg text-md text-black ml-[10px] mb-1">
            {t('checkout.total')}
          </h3>
          {!availabilities?.length ? null : (
            <div className="total-price">
              {displayPrice(totalAmountWithDiscount, currencySymbol, {
                thousandSeparator: ' ',
              })}
            </div>
          )}
        </div>
        <hr ref={checkoutRef} className="border-1 my-3 lg:mx-6" />
        {!isProcessingPayment || isLoadingCreatePaymentIntent ? null : (
          <Elements
            stripe={stripeCompleteConf}
            options={{
              clientSecret: paymentIntentSecret?.clientSecret,
              appearance: { theme: 'flat' },
            }}
          >
            <NightUpsellCheckoutForm
              homeId={home._id}
              checkoutMetadata={checkoutMetadata}
              paymentIntentId={paymentIntentSecret?.paymentIntentId}
              reservationId={reservationId}
              workspace={home.workspace}
              availabilities={availabilities}
            />
          </Elements>
        )}

        <div className="mx-6 mb-3">
          {isProcessingPayment || isLoadingCreatePaymentIntent ? null : (
            <Button
              className="checkout-submit-button"
              disabled={!availabilities?.length}
              onClick={async () => {
                await validateCart();
              }}
            >
              {t('booking.cart_cta')}
            </Button>
          )}
        </div>
        <div className="text-greyText text-xs tracking-wide pb-2">
          {t('booking.cart_cta_tooltip')}
        </div>
      </div>
    </div>
  );
};

export default BookingCart;
