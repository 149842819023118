import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type OrderTypeEmojiProps = {
  isStayExtension: boolean;
};
const OrderTypeEmoji: React.FC<OrderTypeEmojiProps> = ({ isStayExtension }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t(isStayExtension ? 'menu.night_upsells' : 'menu.products')}
      enterTouchDelay={0}
    >
      <div className="flex justify-center items-center cursor-pointer">
        <div className="bg-lightPrimary w-6 h-6 p-[2px] rounded-full">
          {isStayExtension ? '🌜' : '🛍️'}
        </div>
      </div>
    </Tooltip>
  );
};

export default OrderTypeEmoji;
