import { Switch } from '@headlessui/react';
import React from 'react';

type ToggleInputProps = {
  label?: string | React.ReactElement;
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  disabled?: boolean;
};

export const ToggleInput = ({
  enabled,
  setEnabled,
  label,
  disabled,
  name,
}: ToggleInputProps) => {
  return (
    <div className="flex justify-between">
      {label && <span className="text-md text-[#6B7194]">{label}</span>}
      <Switch
        disabled={disabled}
        name={name}
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-green' : 'bg-gray-200'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );
};
