import React from 'react';

import { useTranslation } from 'react-i18next';
import { faCheck, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../hooks/useStore';

type Props = {
  visible: boolean;
  setVisible: any;
};

const QuickStartMenu = ({ setVisible, visible }: Props) => {
  const { t } = useTranslation();
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );

  const R = 16;
  const C = Math.PI * R * 2;
  const P = ((100 - onboardingStatus?.meta?.progression) / 100) * C;

  const containerStyle = `flex flex-row items-center justify-between group shrink-0 hover:bh-white lg:mb-3 ${
    visible ? 'text-black bg-white' : 'text-grey'
  }`;
  const counterStyle = `absolute flex items-center justify-center h-full group-hover:text-black w-full text-[14px] ${
    visible ? 'text-black' : 'text-grey'
  }`;
  const paragraphStyle = `h-4 mb-1 text-xs group-hover:text-black ${
    visible ? 'text-black' : 'text-grey'
  }`;

  return (
    // <OnClickOut callback={() => setVisible(false)}>
    <div className={containerStyle} onClick={() => setVisible(!visible)}>
      <div className="py-[9px] ml-2 px-4 cursor-pointer flex gap-[12px] items-center lg:bg-white rounded-3xl lg:hover:bg-primaryShadeHover">
        <div className="relative h-[40px] w-[40px]">
          <svg height={38} width={38}>
            <circle
              strokeWidth="6"
              stroke="#e3e4ff"
              fill="none"
              style={{
                transition: 'stroke 100ms ease 0s',
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%',
              }}
              r={R}
              cx="19"
              cy="19"
            />
            <circle
              strokeWidth="6"
              strokeLinecap="round"
              strokeDasharray={C}
              strokeDashoffset={P.toString()}
              stroke="#7e82e5"
              fill="none"
              style={{
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%',
                transition:
                  'stroke-dashoffset 200ms ease 0s, stroke 100ms ease 0s',
              }}
              r={R}
              cx="19"
              cy="19"
            />
            <foreignObject height="100%" width="100%">
              <div className={counterStyle}>
                {onboardingStatus?.meta?.remainingOnboardingTasks > 0 ? (
                  onboardingStatus?.meta?.remainingOnboardingTasks
                ) : (
                  <FontAwesomeIcon icon={faCheck} color="#7e82e5" />
                )}
              </div>
            </foreignObject>
          </svg>
        </div>
        <div className="flex flex-col items-start justify-around text-black">
          <div className="h-[16px] mb-[4px] text-[14px]">
            {t('onboarding.title')}
          </div>
          <div className={paragraphStyle}>
            {onboardingStatus?.meta?.remainingOnboardingTasks > 0
              ? `${onboardingStatus?.meta?.remainingOnboardingTasks} ${t(
                  'onboarding.remaining_tasks',
                  {
                    count:
                      onboardingStatus?.meta?.remainingOnboardingTasks || 1,
                  },
                )}`
              : t('onboarding.fully_completed')}
          </div>
        </div>
      </div>
      {visible && (
        <div className="flex lg:hidden">
          <div className="cursor-pointer z-50 text-[24px] mr-6 ">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-black right-0 text-[24px] opacity-75"
            />
          </div>
        </div>
      )}
    </div>
    // </OnClickOut>
  );
};

export default QuickStartMenu;
