import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Emoji } from 'emoji-mart';
import { useAuth } from '../../../../contexts/authContext';
import { deleteProduct, updateProduct } from '../../../../api/products';
import AlertBeforeAction from '../../../alert-before-action/alert-before-action.component';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { NewProduct, Product } from '../../../../types/product.model';
import { useTranslation } from 'react-i18next';
import {
  faCheck,
  faSpinnerThird,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import Loader from '../../../loader/loader.component';
import { displayPrice } from '../../../../utils/currency';
import { preparationTimeInHours } from '../../../../utils/date';
import getCountryFlag from 'country-flag-icons/unicode';
import useSocket from '../../../../hooks/useSocket';
import i18n from '../../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useStore';
import { jobActions } from '../../../../redux/jobs/jobActions';
import { JobStatus } from '../../../../redux/jobs/jobSlice';
import { PRODUCT_TRANSLATION_ACTION } from '../../../../constantes/jobs';
import { useMutation } from 'react-query';
import { Switch } from '@headlessui/react';
import { classNames } from '../../../../utils/helpers';
import CURowText from '../../CURowtText/cu-row-text';

const ProductsList = ({
  setSubRoute,
  products,
  setRefresh,
  currency,
  setDisplayProductTemplates,
  isLoadingProducts,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state) => state.jobs);
  const { currentUser, currentWorkspace } = useAuth();
  const [currentProductId, setCurrentProductId] = useState('');
  const [displayDeleteAlert, setDisplayDeleteAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');

  useEffect(() => {
    setSubRoute('');
  }, []);

  const { sendEvent } = useSocket();

  const handleDelete = async (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string,
    productName: string,
  ) => {
    event.stopPropagation();
    setAlertDescription(t('products.delete_warning', { product: productName }));
    setCurrentProductId(productId);
    setDisplayDeleteAlert(true);
  };

  const deleteClickedProduct = async () => {
    await deleteProduct(
      currentUser.accessToken,
      currentWorkspace._id,
      currentProductId,
    );
    setRefresh(true);
  };

  const handleDuplicateProduct = (
    event: React.MouseEvent<HTMLDivElement>,
    product: Product,
  ) => {
    event.stopPropagation();
    const newProduct: NewProduct = {
      emoji: product.emoji,
      name: `${product.name} (${t('copy')})`,
      description: product.description,
      costPrice: product.costPrice,
      sellingPrice: product.sellingPrice,
      isAvailable: product.isAvailable,
      pictures: product.pictures,
      preparationTimeInHours: product.preparationTimeInHours,
    };
    navigate('/products/new', { state: { product: newProduct } });
  };

  const sendGenerateTranslationsEvent = (id: string) => {
    sendEvent('product:translation:start', {
      productIds: [id],
      lang: i18n.language.slice(0, 2),
    });

    dispatch(
      jobActions.addJob({
        type: PRODUCT_TRANSLATION_ACTION,
        id: `${PRODUCT_TRANSLATION_ACTION}:${id}`,
        status: JobStatus.IN_PROGRESS,
        callback: () => setRefresh(true),
      }),
    );
  };

  const { mutate: updateCurrentProduct } = useMutation(
    (data: Product) =>
      updateProduct(
        currentUser.accessToken,
        currentWorkspace._id,
        data._id,
        data,
      ),
    {
      onSuccess: () => {
        setRefresh(true);
      },
    },
  );

  const setRequireValidation = (product: Product) => {
    updateCurrentProduct({
      ...product,
      validationRequired: product.validationRequired,
    });
  };

  return (
    <>
      <AlertBeforeAction
        title={t('are_you_sure')}
        description={alertDescription}
        successAction={deleteClickedProduct}
        isVisible={displayDeleteAlert}
        setIsVisible={setDisplayDeleteAlert}
      />
      <table className="cozyup hidden lg:table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t('products.emoji')}</th>
            <th>
              {t('products.name')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('products.selling_price')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('products.require_validation')}{' '}
              <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('products.preparation_time')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('languages')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th>
              {t('products.status.status')} <FontAwesomeIcon icon={faSort} />
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="mt-6">
          {products?.map((product: Product, i: number) => {
            const translatingProduct =
              // @ts-ignore
              jobs.jobs[`${PRODUCT_TRANSLATION_ACTION}:${product._id}`] ===
              JobStatus.IN_PROGRESS;
            const flags = product.translations.map((translation: any) => {
              return getCountryFlag(
                translation.languageCode === 'en'
                  ? 'GB'
                  : translation.languageCode.toUpperCase(),
              );
            });
            return (
              <tr
                className="cursor-pointer bg-lightblue"
                key={product._id}
                onClick={() => navigate(`${product._id}`)}
              >
                <td>{i + 1}</td>
                <td>
                  <Emoji emoji={product.emoji} size={36} />
                </td>
                <td>
                  {product.translations.filter(
                    (t: any) => t.languageCode === i18n.language.slice(0, 2),
                  ).length
                    ? product.translations.filter(
                        (t: any) =>
                          t.languageCode === i18n.language.slice(0, 2),
                      )[0].title
                    : product.name}
                </td>
                <td>
                  {displayPrice(product.sellingPrice, currency.symbol, {
                    thousandSeparator: ' ',
                  })}
                </td>
                <td className="flex items-center justify-center">
                  <div onClick={(e) => e.stopPropagation()}>
                    <Switch
                      checked={product.validationRequired}
                      className={`${
                        product.validationRequired ? 'bg-green' : 'bg-gray-200'
                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                      onChange={() => {
                        // Edit validationRequired property here so instant change in the UI
                        product.validationRequired =
                          !product.validationRequired;
                        // Create a new product object with the updated validationRequired property
                        const updatedProduct = {
                          ...product,
                          validationRequired: product.validationRequired,
                        };

                        // Call the original setRequireValidation function with the updated product
                        setRequireValidation(updatedProduct);
                      }}
                    >
                      <span
                        className={`${
                          product.validationRequired
                            ? 'translate-x-6'
                            : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                      />
                    </Switch>
                  </div>
                </td>
                <td>
                  {
                    preparationTimeInHours.find(
                      (p: any) => p.key === product.preparationTimeInHours,
                    )?.label
                  }
                </td>
                <td>
                  <div className="flex justify-center">
                    <Tooltip
                      title={
                        flags.length
                          ? flags.join(' ')
                          : translatingProduct
                          ? t('translating')
                          : t('translate')
                      }
                      onClick={(e) => {
                        if (!product?.translations?.length) {
                          e.stopPropagation();
                          sendGenerateTranslationsEvent(product._id);
                        }
                      }}
                    >
                      <div className="hover:bg-gray-300 h-7 w-7 rounded-full flex items-center justify-center">
                        {translatingProduct ? (
                          <FontAwesomeIcon icon={faSpinnerThird} spin />
                        ) : (
                          product.translations.length
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <span
                    className={`capitalize py-0.5 px-3 text-xs ${
                      product.isAvailable
                        ? 'bg-lightGreen text-green'
                        : 'bg-lightGrey text-grey'
                    } font-semibold rounded-2xl`}
                  >
                    {product.isAvailable
                      ? t('products.status.available')
                      : t('products.status.unavailable')}
                  </span>
                </td>
                <td>
                  <div className="flex justify-center">
                    <Tooltip title={`${t('duplicate')}`}>
                      <div
                        onClick={(e) => handleDuplicateProduct(e, product)}
                        className="hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                      >
                        <Icon
                          className="h-10 w-10 text-gray-500 "
                          fontSize="small"
                          component={ContentCopyIcon}
                        />
                      </div>
                    </Tooltip>

                    <Tooltip title={`${t('delete')}`}>
                      <div
                        onClick={(e) =>
                          handleDelete(e, product._id, product.name)
                        }
                        className="icon hover:bg-gray-300 rounded-full p-2 mx-2 text-gray-500 cursor-pointer"
                      >
                        <Icon
                          className="h-10 w-10 text-red-500"
                          fontSize="small"
                          component={DeleteIcon}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="lg:hidden">
        <div className="flex flex-col mt-6">
          {products?.map((product: Product, i: number) => (
            <div
              key={product._id}
              onClick={() => navigate(`${product._id}`)}
              className="rounded-xl border flex flex-col mb-2 bg-lightblue p-3 shadow-xl"
            >
              <div>
                <div className="flex flex-col justify-between">
                  <div className="flex justify-between items-start mt-2">
                    <Emoji emoji={product.emoji} size={28} />
                    <span
                      className={classNames(
                        product.isAvailable
                          ? 'bg-lightGreen  text-green'
                          : 'bg-lightGrey text-grey',
                        ' capitalize py-0.5 px-3 text-xs font-semibold rounded-2xl text-right',
                      )}
                    >
                      {product.isAvailable
                        ? t('products.status.available')
                        : t('products.status.unavailable')}
                    </span>
                  </div>
                </div>

                <div className="mt-2 text-left text-xs">
                  <div className="text-sm mb-1">
                    <span className="mr-1 font-satoshiBold text-black">
                      #{i + 1}
                    </span>
                    <span className="mx-2 mb-2 first-letter:capitalize text-left font-satoshiBold text-black">
                      {product.translations?.filter(
                        (t: any) =>
                          t.languageCode === i18n.language.slice(0, 2),
                      ).length
                        ? product.translations.filter(
                            (t: any) =>
                              t.languageCode === i18n.language.slice(0, 2),
                          )[0].title
                        : product.name}
                    </span>
                  </div>
                  <CURowText
                    label={t('products.cost_price')}
                    value={displayPrice(product.costPrice, currency.symbol, {
                      thousandSeparator: ' ',
                    })}
                  />
                  <CURowText
                    label={t('products.selling_price') + ':'}
                    value={displayPrice(product.costPrice, currency.symbol, {
                      thousandSeparator: ' ',
                    })}
                  />
                  <div className="w-full flex flex-row justify-between mb-1">
                    <div className="w-1/3 font-semibold text-xs text-greyTextDarker">
                      {t('products.require_validation') + ':'}
                    </div>
                    <div className="flex justify-center items-center h-6 w-6 rounded-full">
                      <FontAwesomeIcon
                        icon={product.validationRequired ? faCheck : faTimes}
                        color={
                          product.validationRequired ? '#50d05a' : '#ff3366'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-around mt-3">
                <Tooltip
                  title={`${t('duplicate')}`}
                  onClick={(e) => handleDuplicateProduct(e, product)}
                >
                  <Icon
                    className="icon rounded-full h-10 w-10 text-gray-600 cursor-pointer"
                    fontSize="small"
                    component={ContentCopyIcon}
                  />
                </Tooltip>
                <Tooltip
                  title={`${t('delete')}`}
                  onClick={(e) => handleDelete(e, product._id, product.name)}
                >
                  <Icon
                    className="icon rounded-full h-10 w-10 text-red-500 cursor-pointer"
                    fontSize="small"
                    component={DeleteIcon}
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoadingProducts && (
        <div className="flex justify-center items-center height-[150px]">
          <Loader />
        </div>
      )}
      {products?.length || isLoadingProducts ? null : (
        <div className="mt-10">
          <div className="flex-row mb-10">
            <FontAwesomeIcon
              className="opacity-20 mb-4"
              color="#b6b9ce"
              icon={faTag}
              size="7x"
            />
            <div className="text-sm font-semibold text-[#6B7194] opacity-20">
              {t('tables.empty-products')}
            </div>
          </div>
          <button
            onClick={() => setDisplayProductTemplates(true)}
            className="btn btn-primary-outlined rounded-3xl"
          >
            {t('add')}
          </button>
        </div>
      )}
    </>
  );
};

export default ProductsList;
