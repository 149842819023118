import React from 'react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

interface PropsType {
  country: string;
}

export const FlagIcons = ({ country }: PropsType) => {
  const countryUpperCase = country.toUpperCase();

  if (countryUpperCase === 'EN') {
    return <div>{getUnicodeFlagIcon('GB')}</div>;
  } else {
    return <div>{getUnicodeFlagIcon(countryUpperCase)}</div>;
  }
};
