import { SubscriptionUsage } from '../types/subscription-usage.model';

const qs = require('qs');
import { api } from '../utils/request';

const include = 'subscriptions';

export const createCustomer = async (email: string, referral?: string) => {
  try {
    const res = await api.post(
      `/stripe/customers`,
      { email, metadata: { referral: referral } },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res as any;
  } catch (err: any) {
    console.log(err);
  }
};

export const createSubscription = async (
  customerId: string,
  priceId: string,
) => {
  try {
    const res = await api.post(
      `/stripe/subscription`,
      { customerId, priceId },
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const getStripeConnectAccount = async (
  authToken: string,
  stripeConnectAccountId: string,
) => {
  try {
    const res = await api.get(`/stripe/connect/${stripeConnectAccountId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getStripeConnectSettingLink = async (
  authToken: string,
  stripeConnectAccountId: string,
  options: Record<string, string> = {},
) => {
  try {
    const res = await api.get(
      `/stripe/connect/${stripeConnectAccountId}/account-link`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          returnUrl: options.returnUrl,
          refreshUrl: options.refreshUrl,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const findCustomerById = async (
  authToken: string,
  customerId: string,
) => {
  try {
    const res = await api.get(`/stripe/customers/${customerId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        include,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getSubscriptionUsage = async (
  authToken: string,
  subscriptionId: string,
): Promise<SubscriptionUsage | any> => {
  try {
    const res = await api.get(`/stripe/usage/${subscriptionId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getPrices = async (lookupKeys: any) => {
  try {
    const res = await api.get(`/stripe/prices`, {
      headers: { ...api.defaults.headers.common },
      params: {
        lookupKeys: lookupKeys,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const createBillingPortal = async (
  authToken: string,
  customer: string,
  return_url: string,
) => {
  try {
    const res = await api.post(
      `/stripe/customers/billing-portal`,
      { customer, return_url },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getUpcomingInvoice = async (
  authToken: string,
  customerId: string,
) => {
  try {
    const res = await api.get(`/stripe/invoices/upcoming/${customerId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
