import React, { CSSProperties } from 'react';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './input-phone.styles.scss';
import ErrorMessage from '../error-message/error-message.component';
import { Icon, Tooltip } from '@mui/material';
import { CallToAction } from '../../types/CallToAction';
import { HelpOutline } from '@mui/icons-material';

type Size = 'small' | 'big';

type InputPhoneProps = {
  id?: string;
  error?: string;
  errors?: string[];
  placeholder?: string;
  textInputStyle?: any;
  labelTextStyle?: any;
  containerStyle?: any;
  disabled?: boolean;
  onChange?: any;
  type?: string;
  value?: string;
  label?: string;
  customClassName?: string;
  helperText?: string;
  activeColor?: string;
  activeLabelColor?: string;
  size?: Size;
  style?: CSSProperties;
  color?: string;
  onPress?: any;
  onFocus?: any;
  onBlur?: any;
  onKeyDown?: any;
  accept?: any;
  tooltip?: string;
  cta?: CallToAction[];
  ref?: any;
};

const InputPhone: React.FC<InputPhoneProps> = ({
  error,
  errors,
  disabled,
  value,
  label,
  helperText,
  placeholder,
  size,
  style,
  color,
  onChange,
  customClassName,
  tooltip,
  cta,
}) => {
  const inputRef = React.useRef(null);
  const className =
    customClassName ||
    `w-full text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none py-4 px-3 bg-white border-gray-400 rounded-3xl mb-0 h-10 flex ${color} ${size} ${
      disabled ? ' opacity-50 hover:border-gray-400 pointer-events-none' : ''
    }`;

  return (
    <div className="flex flex-wrap flex-col mb-6 text-left" style={style}>
      <div className="flex items-center justify-between mb-[2px]">
        <div className="text-left text-[#6B7194]">{label}</div>
        <div className="flex flex-row justify-center items-center">
          {tooltip && (
            <Tooltip title={tooltip} className="mr-2" enterTouchDelay={0}>
              <Icon
                className="icon text-gray-300 cursor-pointer opacity-75"
                component={HelpOutline}
              />
            </Tooltip>
          )}
          {!cta?.length ? null : (
            <div className="flex justify-center content-center">
              {cta.map((action: CallToAction, index: number) => (
                <div
                  key={index.toString()}
                  className="ml-[4px] bg-gray-100 hover:bg-gray-300 rounded-[4px] h-[28px] w-[28px] flex justify-center items-center"
                >
                  <Tooltip
                    title={action?.tooltip}
                    onClick={(e) => {
                      e.stopPropagation();
                      action.callback();
                    }}
                  >
                    <Icon
                      className="icon text-gray-500 cursor-pointer p-[3px] text-[43px]"
                      component={action.icon}
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* @ts-ignore */}
      <PhoneInput
        placeholder={placeholder}
        value={value}
        className={className}
        onChange={onChange}
        ref={inputRef}
        defaultCountry="FR"
      />
      {!helperText || error || (errors && errors[0]) ? null : (
        <span className="text-left ml-2 mt-2 text-xs text-middleGrey font-semibold">
          {helperText}
        </span>
      )}
      {error && <ErrorMessage key={error} error={error} />}
      {errors &&
        errors.map((err: string) => {
          if (err) {
            return <ErrorMessage key={err} error={err} />;
          }
        })}
    </div>
  );
};

export default InputPhone;

InputPhone.defaultProps = {
  size: 'big',
  style: { width: '100%' },
  color: 'default',
};
