import React, { useEffect, useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import { Emoji } from 'emoji-mart';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../contexts/authContext';

const BookACall = () => {
  const { t } = useTranslation();
  const { currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const navigate = useNavigate();

  const mixpanelProperties = {
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const [isEventBooked, setIsEventBooked] = useState(false);
  useCalendlyEventListener({
    onEventScheduled: () => {
      setIsEventBooked(true);
      trackMixpanelEvent(
        'Register - Book a call event booked',
        mixpanelProperties,
      );
    },
  });

  useEffect(() => {
    trackMixpanelEvent('Register - Book a call page view ', {});
  }, []);

  return (
    <div className="h-full md:min-h-screen overflow-scroll md:overflow-hidden relative flex bg-blue-50 lg:bg-white pt-4 lg:pt-0 md:items-center ">
      <div className="flex items-center justify-center w-full h-full">
        <div className="hidden lg:flex h-full w-2/5">
          <div className="bg-[#270074] h-[calc(100vh-96px)] my-12 w-full rounded-r-3xl flex flex-col items-center justify-center">
            <h1 className="w-full font-satoshiBold text-[52px] text-white text-center mb-6">
              CozyUp
            </h1>
            <p className="mx-8 mb-4 text-[18px] text-white leading-9">
              {t('register_form.book_call_description')}
            </p>
            <Emoji emoji="phone" size={52} />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <InlineWidget
            url="https://calendly.com/cozyup-inc/onboarding-inedit"
            styles={{
              width: window.innerWidth > 768 ? '100%' : '90%',
              height: isEventBooked
                ? window.innerHeight * 0.5
                : window.innerHeight * 0.9,
            }}
          />
          {!isEventBooked ? null : (
            <button
              className="group button-sign-up hover:opacity-95 rounded-full font-satoshi text-[16px] bg-[#270074] text-white tracking-wider mt-12 py-3 px-6 flex items-center justify-center transition duration-600"
              style={{ width: 'auto' }}
              onClick={() => navigate('/onboarding?step=0')}
            >
              {t('see_app')}
              <span className="hidden group-hover:flex transition ml-2 duration-600">
                {/* Adjust the width as needed */}
                <FontAwesomeIcon icon={faArrowRight} className="text-white" />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookACall;
