import React from 'react';
import { Link } from 'react-router-dom';

type LogoProps = {
  redirect?: string;
  containerClass?: string;
};

const Logo = ({ redirect = '/orders', containerClass }: LogoProps) => {
  return (
    <div className={containerClass}>
      <Link
        to={redirect}
        className="h-full align-center font-black text-nightBlue font- text-3xl font-satoshi"
      >
        CozyUp
      </Link>
    </div>
  );
};

export default Logo;
