import { de, enUS, es, fr, it, nl, ru } from 'date-fns/locale';

export const dateFnslocales: { [key: string]: any } = {
  de: de,
  en: enUS,
  es: es,
  fr: fr,
  it: it,
  nl: nl,
  ru: ru,
};
