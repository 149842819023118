import Beds24Logo from '../assets/images/beds24-logo.svg';
import BookingSyncLogo from '../assets/images/bookingsync-logo-new.png';
import SmoobuLogo from '../assets/images/smoobu-logo.png';
import LodgifyLogo from '../assets/images/lodgify-logo.svg';
import HostawayLogo from '../assets/images/hostaway-logo.svg';
import HospitableLogo from '../assets/images/hospitable-logo.svg';
import OwnerRezLogo from '../assets/images/ownerrez-logo.svg';
import UplistingLogo from '../assets/images/uplisting-logo.svg';
import LodgixLogo from '../assets/images/lodgix-logo.svg';
import HostifyLogo from '../assets/images/hostify-logo.svg';
import HostfullyLogo from '../assets/images/hostfully-logo.svg';
import AvaibookLogo from '../assets/images/avaibook-logo.svg';
import SuperhoteLogo from '../assets/images/superhote-logo.svg';
import GuestyLogo from '../assets/images/guesty-logo.svg';
import TokeetLogo from '../assets/images/tokeet-logo.svg';
import FantastiscStayLogo from '../assets/images/fantasticstay-logo.svg';

export const NONE = '0_NONE';
export const AVAIBOOK = 'CALRY_AVAIBOOK';
export const BEDS24 = 'CALRY_BEDS24';
export const BOOKING_SYNC = 'BOOKING_SYNC';
export const HOSPITABLE = 'HOSPITABLE';
export const HOSTAWAY = 'CALRY_HOSTAWAY';
export const HOSTFULLY = 'CALRY_HOSTFULLY';
export const HOSTIFY = 'CALRY_HOSTIFY';
export const LODGIFY = 'CALRY_LODGIFY';
export const LODGIX = 'CALRY_LODGIX';
export const OWNERREZ = 'CALRY_OWNERREZ';
export const SMOOBU = 'SMOOBU';
export const SUPERHOTE = 'SUPERHOTE';
export const UPLISTING = 'CALRY_UPLISTING';
export const GUESTY = 'CALRY_GUESTY';
export const FANTASTIC_STAY = 'CALRY_FANTASTIC_STAY';
export const TOKEET = 'CALRY_TOKEET';

const ChannelManagersList = [
  {
    logo: NONE,
    name: NONE,
    type: NONE,
    color: '#333',
  },
  {
    logo: Beds24Logo,
    name: 'Beds24',
    type: BEDS24,
    color: '#019CDF',
  },
  {
    logo: BookingSyncLogo,
    name: 'BookingSync',
    type: BOOKING_SYNC,
    color: '#159BD7',
  },
  {
    logo: SmoobuLogo,
    name: 'Smoobu',
    type: SMOOBU,
    color: '#3B4AFF',
  },
  {
    logo: LodgifyLogo,
    name: 'Lodgify',
    type: LODGIFY,
    color: '#FF3B59',
  },
  {
    logo: HostawayLogo,
    name: 'Hostaway',
    type: HOSTAWAY,
    color: '#FF6B0B',
  },
  {
    logo: OwnerRezLogo,
    name: 'OwnerRez',
    type: OWNERREZ,
    color: '#57AE57',
  },
  {
    logo: UplistingLogo,
    name: 'Uplisting',
    type: UPLISTING,
    color: '#4A4A4A',
  },
  {
    logo: LodgixLogo,
    name: 'Lodgix',
    type: LODGIX,
    color: '#0D47A1',
  },
  {
    logo: HostifyLogo,
    name: 'Hostify',
    type: HOSTIFY,
    color: '#157FFC',
  },
  {
    logo: HostfullyLogo,
    name: 'Hostfully',
    type: HOSTFULLY,
    color: '#2C2AA5',
  },
  {
    logo: AvaibookLogo,
    name: 'Avaibook',
    type: AVAIBOOK,
    color: '#14B4A3',
  },
  {
    logo: HospitableLogo,
    name: 'Hospitable',
    type: HOSPITABLE,
    color: '#EE3C6A',
  },
  {
    logo: SuperhoteLogo,
    name: 'SuperHote',
    type: SUPERHOTE,
    color: '#CC0100',
  },
  {
    logo: GuestyLogo,
    name: 'Guesty',
    type: GUESTY,
    color: '#071542',
  },
  {
    logo: FantastiscStayLogo,
    name: 'FantasticStay',
    type: FANTASTIC_STAY,
    color: '#35BD59',
  },
  {
    logo: TokeetLogo,
    name: 'Tokeet',
    type: TOKEET,
    color: '#01A99D',
  },
];

export default ChannelManagersList;
