import { api } from '../utils/request';

export const getConnectAccountsByAccount = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/stripe-connect-accounts?status=active`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createStripeConnectAccount = async (
  authToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/stripe-connect-accounts`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getStripeConnectAccountByAccount = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/stripe-connect-accounts`,

      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getStripeConnectAccountByWorkspaceId = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/stripe-connect-accounts?status=active`,

      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateConnectAccount = async (
  authToken: string,
  workspaceId: string,
  stripeConnectAccountId: string,
  data: any,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/stripe-connect-accounts/${stripeConnectAccountId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
