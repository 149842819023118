import { api } from '../utils/request';
import { WorkspaceInvitation } from '../types/workspace-invitation.model';
import { Workspace } from '../types/workspace.model';
import qs from 'qs';

export enum BestSellersOrderBy {
  // eslint-disable-next-line no-unused-vars
  SALES = 'sales',
  // eslint-disable-next-line no-unused-vars
  TURNOVER = 'turnover',
}

export const getOnboardingStatus = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/onboarding`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getCalendarViewOrders = async (
  authToken: string,
  workspaceId: string,
  year: number,
  month: number,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders/calendar`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        year,
        month,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getUpcomingOrdersByWorkspace = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders/upcoming`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getPendingOrdersByWorkspace = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/orders/pending`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getBestSellers = async (
  authToken: string,
  workspaceId: string,
  orderBy: string,
) => {
  try {
    const res = await api.get(
      `/workspaces/${workspaceId}/orders/best-sellers`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          orderBy,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createWorkspace = async (
  authToken: string,
  accountId: string,
  data: Workspace,
) => {
  try {
    const res = await api.post(
      `/accounts/${accountId}/workspaces`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getWorkspacesByAccount = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(`/accounts/${accountId}/workspaces`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getWorkspace = async (authToken: string, workspaceId: string) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateWorkspace = async (
  authToken: string,
  workspaceId: string,
  data: Workspace,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createWorkspaceAccess = async (
  authToken: string,
  workspaceId: string,
  data: any,
) => {
  try {
    const res = await api.post(
      `/workspaces/${workspaceId}/workspace-accesses`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const genereateCustomersCsv = async (
  authToken: string,
  workspaceId: string,
  startDate: string,
  endDate: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/customers/csv`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        startDate,
        endDate,
      },
      responseType: 'blob',
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
    throw err.response;
  }
};

export const getAllCustomers = async (
  userToken: string,
  workspaceId: string,
  page: number,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/customers`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        page,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateWorkspaceInvitationStatus = async (
  authToken: string,
  workspaceId: string,
  workspaceInvitationId: string,
  data: WorkspaceInvitation,
) => {
  try {
    const res = await api.patch(
      `/workspaces/${workspaceId}/workspace-invitations/${workspaceInvitationId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const revokeWorkspaceAccess = async (
  authToken: string,
  workspaceId: string,
  workspaceAccessId: string,
) => {
  try {
    const res = await api.delete(
      `/workspaces/${workspaceId}/workspace-accesses/${workspaceAccessId}`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getWorkspaceAccessesByUserAndWorkspace = async (
  authToken: string,
  workspaceId: string,
) => {
  try {
    const res = await api.get(`/workspaces/${workspaceId}/workspace-accesses`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
