import React from 'react';
import CustomLink from '../custom-link/custom-link.component';
import {
  faCartArrowDown,
  faChartLine,
  faCircleDollar,
  faCirclePlay,
  faCog,
  faComments,
  faGraduationCap,
  faHeadset,
  faHome,
  faMoonStars,
  faPlug,
  faRectanglesMixed,
  faStore,
  faTag,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import WorkspaceInfo from '../workspace-info/workspace-info.component';
import { useTranslation } from 'react-i18next';
import Language from '../language/language.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faFolders,
  faHouseUser,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import AppStoreLogo from '../../assets/images/app-store.png';

import QuickStartMenu from '../quick-start-menu/quick-start-menu';
import { WithPermissions } from '../with-permissions/with-permissions.component';
import Lottie from 'react-lottie';
import stars from '../../assets/lottie/stars.json';
import { useAppSelector } from '../../hooks/useStore';

const package_json = require('../../../package.json');

type Props = {
  displayOnboarding: boolean;
  setDisplayOnboarding: any;
};

const SideMenu = ({ displayOnboarding, setDisplayOnboarding }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [displaySettingsMenu, setDisplaySettingsMenu] = React.useState(true);
  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );
  const appVersion = `CozyUp - v${package_json.version}`;
  const location = useLocation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: stars,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  React.useEffect(() => {
    location.pathname.includes('settings')
      ? setDisplaySettingsMenu(true)
      : setDisplaySettingsMenu(false);
  }, [location]);

  return (
    <>
      <nav className="flex flex-col justify-between bg-lightblue pt-[12px] h-screen font-sans max-w-[240px] overflow-y-scroll">
        {displaySettingsMenu ? (
          <div>
            <div
              onClick={() => navigate('/orders')}
              className="mt-3 p-2 rounded-full"
            >
              <div className="flex justify-center items-center bg-white hover:bg-lightPrimary ml-3 p-3 rounded-full w-[36px] h-[36px] cursor-pointer">
                <FontAwesomeIcon icon={faChevronLeft} size="1x" />
              </div>
            </div>
            <div className="pt-[12px] mt-[12px] px-[6px]">
              <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
                <CustomLink
                  to="settings/account"
                  title={t('header.account')}
                  icon={faHouseUser}
                />
              </WithPermissions>
              <CustomLink
                to="settings/profile"
                title={t('header.profile')}
                icon={faUser}
              />

              <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
                <CustomLink
                  to="settings/workspaces"
                  title={t('menu.workspaces')}
                  icon={faFolders}
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="settings/referrals"
                  title={t('menu.referrals')}
                  icon={faUserGroup}
                />
              </WithPermissions>
            </div>
            <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
              <div className="ml-[6px]">
                <CustomLink
                  to="settings/billing"
                  title={t('menu.subscription')}
                  icon={faCircleDollar}
                />
              </div>
            </WithPermissions>
          </div>
        ) : (
          <div>
            <WorkspaceInfo />

            <div className="pt-[12px] px-[6px]">
              <CustomLink
                to="/dashboard"
                title={t('menu.dashboard')}
                icon={faRectanglesMixed}
              />
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/analytics"
                  title={t('menu.analytics')}
                  icon={faChartLine}
                />
              </WithPermissions>
              <WithPermissions
                rolesRequired={['ADMIN', 'MANAGER', 'OWNER', 'CLEANING_STAFF']}
              >
                <CustomLink
                  to="/orders"
                  title={t('menu.orders')}
                  icon={faCartArrowDown}
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/night-upsells"
                  title={t('menu.night_upsells')}
                  icon={faMoonStars}
                  badgeTitle="Bêta"
                  hasNewBadge
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/homes"
                  title={t('menu.listings')}
                  icon={faHome}
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/checkout-areas"
                  title={t('menu.checkout-areas')}
                  icon={faStore}
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/products"
                  title={t('menu.products')}
                  icon={faTag}
                />
              </WithPermissions>
              <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                <CustomLink
                  to="/channel-managers"
                  title={t('menu.applications')}
                  icon={faPlug}
                />
              </WithPermissions>
              <CustomLink
                to="/settings/profile"
                title={t('menu.settings')}
                icon={faCog}
              />
            </div>
          </div>
        )}
        {displaySettingsMenu ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              const userAgent = window.navigator.userAgent.toLowerCase();
              if (/android/.test(userAgent)) {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.cozyupmobile',
                  '_blank',
                );
              } else if (/iphone|ipad|ipod/.test(userAgent)) {
                window.open(
                  'https://apps.apple.com/us/app/cozyup-pro/id6450367491',
                  '_blank',
                );
              } else {
                // Default case for desktop or other OS, you can choose to do nothing or redirect to a default page
                window.open(
                  'https://apps.apple.com/us/app/cozyup-pro/id6450367491',
                  '_blank',
                );
                window.open(
                  'https://play.google.com/store/apps/details?id=com.cozyupmobile',
                  '_blank',
                );
              }
            }}
          >
            <img
              className="h-auto w-2/3 mb-4"
              src={AppStoreLogo}
              alt="App Store Logos"
            />
          </div>
        ) : (
          <div>
            <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
              <QuickStartMenu
                setVisible={setDisplayOnboarding}
                visible={displayOnboarding}
              />
            </WithPermissions>
            <div className="mx-3">
              <div>
                <div className="mb-3 h-px w-full bg-grey" />
                <Language />
                <div className="relative">
                  <CustomLink
                    to="/tutorial"
                    title={t('menu.how-it-works')}
                    icon={faCirclePlay}
                  />
                  {onboardingStatus?.meta?.remainingOnboardingTasks > 0 && (
                    <div className="absolute top-0 right-5">
                      <Lottie
                        isClickToPauseDisabled
                        options={defaultOptions}
                        height={48}
                        width={48}
                      />
                    </div>
                  )}
                </div>
                <CustomLink
                  to="https://academy.cozyup.com/"
                  title={t('menu.cozyup_course')}
                  icon={faGraduationCap}
                  external={true}
                  hasNewBadge
                />
                <CustomLink
                  to="https://cozyup.kampsite.co/"
                  title={t('menu.feedbacks')}
                  icon={faComments}
                  external={true}
                />
                <CustomLink
                  to="https://help.cozyup.com/"
                  title={t('menu.help-center')}
                  icon={faHeadset}
                  external={true}
                />
              </div>
              <div className="py-2">
                <p className="text-xs font-inter text-[#6b719461]">
                  {appVersion}
                </p>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default SideMenu;
