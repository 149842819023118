import React, { useState } from 'react';
import { Emoji } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import { WithPermissions } from '../../with-permissions/with-permissions.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRight, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '../../../contexts/authContext';
import useTrackMixpanelEvent from '../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { useMutation, useQuery } from 'react-query';
import { generateInvoice, refundOrder } from '../../../api/orders';
import { displayPrice, getCurrencyInfo } from '../../../utils/currency';
import AlertBeforeAction from '../../alert-before-action/alert-before-action.component';
import i18n from '../../../i18n';
import { useNavigate } from 'react-router-dom';
import OnClickOut from '../../../hooks/useOnClickOut';
import OrderTypeEmoji from '../../_main/order-type-emoji/order-type-emoji.constante';

type OrderModalProps = {
  order: any;
  displayModal: boolean;
  setDisplayModal: Function;
  setRefreshOrder?: any;
};

const OrderModal: React.FC<OrderModalProps> = ({
  order,
  displayModal,
  setDisplayModal,
  setRefreshOrder,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const currentCompanyData = currentWorkspace?.company;
  const [displayNoCompanyAlert, setDisplayNoCompanyAlert] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [displayRefundAlert, setDisplayRefundAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const { refetch: getInvoice } = useQuery(
    ['invoice'],
    () =>
      generateInvoice(
        currentUser.accessToken,
        order._id,
        i18n.language.split('-')[0],
        order.currency.key,
      ),
    {
      manual: true,
      enabled: false,
    },
  );

  const mixpanelProperties = {
    home_id: order.home?._id || order.home_id,
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const previewInvoice = async () => {
    if (!currentCompanyData) {
      trackMixpanelEvent(
        'Invoice - Preview error - No company info',
        mixpanelProperties,
      );
      return setDisplayNoCompanyAlert(true);
    }
    setIsLoadingPreview(true);
    const invoice = await getInvoice();
    let pdfWindow = window.open('');
    pdfWindow &&
      pdfWindow.document.write(
        '<html<head><title>' +
          `invoice_${order.order_id}_${order._id}.pdf` +
          '</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>',
      );
    pdfWindow &&
      pdfWindow.document.write(
        "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(invoice.data.data) +
          "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>",
      );
    trackMixpanelEvent('Invoice - Preview', mixpanelProperties);
    setIsLoadingPreview(false);
  };

  const { mutate: refund } = useMutation(
    (orderId: string) =>
      refundOrder(currentUser.accessToken, currentWorkspace._id, orderId),
    {
      onSuccess: () => {
        setRefreshOrder && setRefreshOrder(true);
      },
    },
  );

  const handleRefund = () => {
    setDisplayRefundAlert(true);
    setAlertDescription(
      t('orders.refund_alert', {
        orderId: order.order_id,
        amount: displayPrice(
          order.totalAmount,
          getCurrencyInfo(order.currency).symbol,
          {
            thousandSeparator: ' ',
          },
        ),
        currency: getCurrencyInfo(order.currency).symbol,
      }),
    );
  };

  const onRefundConfirm = async () => {
    await refund(order._id);
    setDisplayRefundAlert(false);
    setDisplayModal(false);
  };

  const downloadInvoice = async () => {
    if (!currentCompanyData) {
      trackMixpanelEvent(
        'Invoice - Download error - No company info',
        mixpanelProperties,
      );
      return setDisplayNoCompanyAlert(true);
    }
    setIsLoadingDownload(true);
    const invoice = await getInvoice();
    let a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + invoice.data.data;
    a.download = `invoice_${order.order_id}_${order._id}.pdf`;
    a.click();
    trackMixpanelEvent('Invoice - Download', mixpanelProperties);
    setIsLoadingDownload(false);
  };

  return !displayModal ? null : (
    <div className="modal-overlay z-30">
      <div className="modal-wrapper z-30">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] bg-lightblue min-h-[350px] mt-16 mx-auto rounded-[16px]"
        >
          <div className="p-4">
            <AlertBeforeAction
              title={t('are_you_sure')}
              description={alertDescription}
              successAction={onRefundConfirm}
              isVisible={displayRefundAlert}
              setIsVisible={setDisplayRefundAlert}
            />
            <AlertBeforeAction
              title={t('settings.company.missing_info_title')}
              description={t('settings.company.missing_info_description')}
              successLabel={t('complete')}
              successAction={() =>
                navigate(`/settings/workspaces/${currentWorkspace?._id}`)
              }
              isVisible={displayNoCompanyAlert}
              setIsVisible={setDisplayNoCompanyAlert}
            />
            <div className="relative flex flex-col">
              <div className="flex items-center justify-center mb-4 gap-2">
                <OrderTypeEmoji isStayExtension={order?.isStayExtension} />
                <span className="font-bold text-lg text-[#160042] text-left">
                  {t('checkout_success.order') + ' #' + order.order_id}
                </span>
              </div>
              <button
                type="button"
                className="modal-close-button"
                onClick={() => setDisplayModal(false)}
              >
                <span>&times;</span>
              </button>
              <div className="flex justify-between text-left">
                <div>
                  <div className="mr-2 font-bold underline text-[#160042]">
                    {t('orders.order_id')}:
                  </div>
                  <div className="text-gray-600">{order._id}</div>
                  <div className="mt-2 mr-2 font-bold underline text-[#160042]">
                    {t('settings.listing_one')}:
                  </div>
                  <div className="text-gray-600">
                    {order.home?.name || order.home}
                  </div>
                  <div className="mt-2 mr-2 font-bold underline text-[#160042]">
                    {t('orders.customer')}:
                  </div>
                  <div className="text-gray-600">{order.customer}</div>
                  <div className="mt-2 mr-2 font-bold underline text-[#160042]">
                    {t('email')}:
                  </div>
                  <div className="text-gray-600">{order.customerEmail}</div>
                </div>
                <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
                  {order.paymentStatus === 'paid' && (
                    <div>
                      <div className="my-4 flex justify-end">
                        <button
                          className="flex btn py-1 items-center rounded-2xl btn-primary-outlined"
                          onClick={() => handleRefund()}
                        >
                          {t('orders.refund')}
                        </button>
                      </div>
                      {!order?.isStayExtension && (
                        <>
                          <div className="ml-2 font-bold underline text-[#160042]">
                            <span className=""> {t('invoice')}</span>
                          </div>
                          <div className="flex">
                            <button
                              className="flex btn py-1 items-center rounded-2xl btn-primary-outlined"
                              onClick={() => previewInvoice()}
                            >
                              {t('preview')}
                              {!isLoadingPreview ? null : (
                                <FontAwesomeIcon
                                  style={{ marginLeft: 15 }}
                                  icon={faSpinnerThird}
                                  spin
                                />
                              )}
                            </button>
                            <button
                              className="flex  items-center btn py-1 rounded-2xl btn-primary"
                              onClick={() => downloadInvoice()}
                            >
                              {t('download')}
                              {!isLoadingDownload ? null : (
                                <FontAwesomeIcon
                                  style={{ marginLeft: 15 }}
                                  icon={faSpinnerThird}
                                  spin
                                />
                              )}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </WithPermissions>
              </div>
            </div>
            <div className="mt-2 flex text-left">
              <div className="font-bold mr-2 text-[#160042]">
                {t('cozyup_fee')}:
              </div>
              {displayPrice(
                order.amountFeeHost,
                getCurrencyInfo(order.currency).symbol,
                {
                  notCents: false,
                  thousandSeparator: ' ',
                },
              )}
            </div>
            {!order.comment ? null : (
              <div className="flex flex-col mt-4 text-left">
                <div className="font-bold mr-2 text-[#160042]">
                  {t('orders.comment')}:
                </div>
                <p>{order.comment}</p>
              </div>
            )}

            <br />
            {order.isStayExtension ? (
              <div>
                <div className="mb-2 font-bold text-black text-left">
                  {t('booking_success.extended_stay')}
                </div>
                {order?.stayExtensions?.map((stayExtension: any) => (
                  <div
                    key={stayExtension._id}
                    className="flex gap-4 justify-between w-full"
                  >
                    <div className="text-left flex flex-col gap-1">
                      <div className="flex gap-3">
                        <span className="">
                          {t('booking_success.origin_reservation')} :
                        </span>
                        <span className="text-weight-700">
                          {stayExtension.reservationId}
                        </span>
                      </div>
                      <div className="flex gap-3">
                        <span className="">
                          {t('booking_success.new_reservation')} :
                        </span>
                        <span className="text-weight-700">
                          {stayExtension.newReservationId}
                        </span>
                      </div>
                      <div className="flex gap-3">
                        <span className="">{t('booking_success.name')} :</span>
                        <span>{stayExtension.guestName}</span>
                      </div>
                      <div className="flex gap-3">
                        <span className="">
                          {t('booking_success.number_guests')} :
                        </span>
                        <span>{stayExtension.totalGuests}</span>
                      </div>
                    </div>
                    <div className="inline-flex gap-6 items-center text-lg">
                      <div>
                        <div className="text-left">
                          <span className="text-sm text-grey">
                            {t('booking_success.arrival')} :
                          </span>
                        </div>
                        <div>
                          <span>
                            {new Date(
                              stayExtension.newArrivalDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="text-left">
                          <span className="line-through text-sm text-grey">
                            {t('booking_success.departure')} :
                          </span>
                        </div>
                        <div>
                          <span className="line-through text-grey">
                            {new Date(
                              stayExtension.originalDepartureDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <span>
                        <FontAwesomeIcon
                          className="text-green"
                          icon={faRight}
                        />
                      </span>
                      <div>
                        <div className="text-left">
                          <span className="text-sm text-green">
                            {t('booking_success.new_departure')} :
                          </span>
                        </div>
                        <div>
                          <span>
                            {new Date(
                              stayExtension.newDepartureDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="flex">
                  <div
                    className=""
                    style={{
                      width: '15%',
                      fontWeight: 'bold',
                      color: '#160042',
                      textAlign: 'left',
                    }}
                  >
                    {t('orders.quantity')}
                  </div>
                  <div
                    style={{
                      width: '15%',
                      fontWeight: 'bold',
                      color: '#160042',
                      textAlign: 'left',
                    }}
                  >
                    {t('orders.emoji')}
                  </div>
                  <div
                    style={{
                      width: '40%',
                      fontWeight: 'bold',
                      color: '#160042',
                      textAlign: 'left',
                    }}
                  >
                    {t('orders.products')}
                  </div>
                  <WithPermissions
                    rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                  >
                    <div
                      style={{
                        width: '20%',
                        fontWeight: 'bold',
                        color: '#160042',
                        textAlign: 'left',
                      }}
                    >
                      {t('orders.prices')} (
                      {getCurrencyInfo(order.currency).symbol})
                    </div>
                  </WithPermissions>
                  <div
                    className="flex justify-center"
                    style={{
                      width: '20%',
                      fontWeight: 'bold',
                      color: '#160042',
                      textAlign: 'left',
                    }}
                  >
                    {t('orders.validation')}
                  </div>
                </div>
                {order.products?.map((product: any, index: number) => (
                  <div key={index} className="flex mt-6">
                    <div
                      className="text-left flex items-center"
                      style={{ width: '15%' }}
                    >
                      x{product.quantity}
                    </div>
                    <div
                      className="text-left flex items-center"
                      style={{ width: '15%' }}
                    >
                      <Emoji
                        emoji={product.item.emoji || 'grey_question'}
                        size={45}
                      />
                    </div>
                    <div
                      className="text-left flex items-center"
                      style={{ width: '40%' }}
                    >
                      {'isValidated' in product ? (
                        <>
                          {!product.isValidated ? (
                            <del>
                              {product.item.translations?.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              ).length
                                ? product.item.translations.filter(
                                    (t: any) =>
                                      t.languageCode ===
                                      i18n.language.slice(0, 2),
                                  )[0].title
                                : product.item.name}
                            </del>
                          ) : (
                            <span>
                              {product.item.translations?.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              ).length
                                ? product.item.translations.filter(
                                    (t: any) =>
                                      t.languageCode ===
                                      i18n.language.slice(0, 2),
                                  )[0].title
                                : product.item.name}
                            </span>
                          )}
                        </>
                      ) : (
                        <span>
                          {product.item.translations?.filter(
                            (t: any) =>
                              t.languageCode === i18n.language.slice(0, 2),
                          ).length
                            ? product.item.translations.filter(
                                (t: any) =>
                                  t.languageCode === i18n.language.slice(0, 2),
                              )[0].title
                            : product.item.name}
                        </span>
                      )}
                    </div>
                    <WithPermissions
                      rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}
                    >
                      <div
                        className="text-left flex items-center"
                        style={{ width: '20%' }}
                      >
                        {displayPrice(
                          product.item.sellingPrice,
                          getCurrencyInfo(order.currency).symbol,
                          {
                            thousandSeparator: ' ',
                          },
                        )}
                      </div>
                    </WithPermissions>
                    <div className="flex justify-center">
                      {'isValidated' in product ? (
                        <>
                          {product.isValidated
                            ? '✅'
                            : order.paymentStatus === 'waiting_validation'
                            ? '⏳'
                            : '❌'}
                        </>
                      ) : (
                        <span>✅</span>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};

export default OrderModal;
