import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Referrals from '../../referrals/Referrals';

type ReferralsSettingsProps = {
  setSubRoute: any;
};

const ReferralsSettings = ({ setSubRoute }: ReferralsSettingsProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    setSubRoute(t('header.referrals'));
  }, []);
  return <Referrals />;
};

export default ReferralsSettings;
