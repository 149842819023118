import React from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import AirbnbLogoVertical from '../../assets/images/airbnb-logo-vertical.png';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimneyUser, faKey } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  displayModal: boolean;
  setDisplayModal: Function;
  onChoose: Function;
};

export const SelectAirbnbMethodModal = ({
  displayModal,
  setDisplayModal,
  onChoose,
}: Props) => {
  const { t } = useTranslation();

  const handleOnChoose = (method: string) => {
    setDisplayModal(false);
    onChoose(method);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <div className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start">
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative mx-4 flex flex-col justify-between w-full max-w-[800px] min-h-[500px] lg:min-h-[150px] bg-white mt-16 lg:mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div>
            <div className="border-b px-4 py-2">
              <h5 className="mt-2 leading-10 md:leading-none text-xl lg:text-[36px] mb-4">
                <img src={AirbnbLogoVertical} alt="Airbnb" className="h-24" />
              </h5>
            </div>
            <div className="md:px-10 justify-center items-center">
              <div className="flex w-full items-center justify-center gap-2 my-6">
                <div className="w-full flex flex-col lg:flex-row flex-wrap">
                  <div className="p-4 lg:w-1/2">
                    <div
                      className="group flex flex-col px-4 justify-center items-center cursor-pointer shadow rounded-[32px] font-satoshi h-52   mb-[12px] hover:border-checkoutPrimary border-[2px] border-transparent lg:filter lg:grayscale lg:hover:grayscale-0"
                      onClick={() =>
                        handleOnChoose('hospitable_connect_airbnb')
                      }
                    >
                      <div>
                        <FontAwesomeIcon
                          className="text-checkoutPrimary mb-3"
                          //   color="#b6b9ce"
                          icon={faKey}
                          size="4x"
                        />
                      </div>
                      <div className="text-2xl group-hover:text-checkoutPrimary">
                        {t('listings.airbnb.owner')}
                      </div>

                      <p className="mt-6 text-grey">
                        {t('listings.airbnb.owner_dsc')}
                      </p>
                    </div>
                  </div>
                  <div className="p-4 lg:w-1/2">
                    <div
                      className="group flex flex-col px-4 justify-center items-center cursor-pointer shadow rounded-[32px] font-satoshi h-52   mb-[12px] hover:border-checkoutPrimary border-[2px] border-transparent lg:filter lg:grayscale lg:hover:grayscale-0"
                      onClick={() => handleOnChoose('airbnb_scrapper')}
                    >
                      <div>
                        <FontAwesomeIcon
                          className="text-checkoutPrimary mb-3"
                          //   color="#b6b9ce"
                          icon={faHouseChimneyUser}
                          size="4x"
                        />
                      </div>
                      <div className="text-2xl group-hover:text-checkoutPrimary">
                        {t('listings.airbnb.cohost')}
                      </div>

                      <p className="mt-6 text-grey">
                        {t('listings.airbnb.cohost_dsc')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OnClickOut>
      </div>
    </div>
  );
};
