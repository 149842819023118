import React, { useState } from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../contexts/authContext';

import { useMutation } from 'react-query';
import { createWorkspaceAccess } from '../../api/workspaces';
import SubmitButton from '../submit-button/submit-button.component';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { Workspace } from '../../types/workspace.model';
import { Account } from '../../types/account.model';
import Select from '../select/select.component';
import { User } from '../../types/user.model';
import { RolesList } from '../../constantes/user-roles';
import toast from 'react-hot-toast';

type AddWorkspaceAccessProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  workspace: Workspace;
  account: Account;
  refreshWorkspace: any;
};

export const AddWorkspaceAccessModal = ({
  displayModal,
  setDisplayModal,
  workspace,
  account,
  refreshWorkspace,
}: AddWorkspaceAccessProps) => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [currentRole, setCurrentRole] = useState('');
  const currentRoleDescription = RolesList.find(
    (role: any) => role.key === currentRole,
  )?.desc;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetForm,
    setError,
  } = useForm();

  const {
    mutate: createAccess,
    isLoading,
    isSuccess,
    reset: resetHook,
  } = useMutation(
    (data: any) =>
      createWorkspaceAccess(currentUser.accessToken, workspace._id, data),
    {
      onSuccess: () => {
        setTimeout(() => {
          resetForm();
          resetHook();
          refreshWorkspace();
          setCurrentRole('');
          setDisplayModal(false);
        }, 1000);
      },
      onError: (error: any) => {
        console.error('error', error);
        if (error.code === 'WorkspaceAccess.AlreadyExist') {
          toast.error(t('settings.workspace-access.user_already_added'));
        } else {
          toast.error(t('errors.unknown_error'));
        }
      },
    },
  );

  // TODO: Move to backend

  const usersAvailable = (account: Account, workspace: Workspace) => {
    const usersWithAccess = workspace.workspaceAccesses.map((uA) => {
      return uA.user._id;
    });
    const guests = account.guests;
    let usersWithNoAccess = guests.filter(
      (guest: User) => !usersWithAccess.includes(guest._id),
    );
    const usersOptions = usersWithNoAccess.map((u: any) => {
      return { label: u.email, key: u._id };
    });
    return usersOptions;
  };

  const submit = (data: any) => {
    const access = data;
    if (!currentRole) {
      return setError('role', {
        type: 'manual',
        message: t('errors.field_required'),
      });
    }
    createAccess(access);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[800px] min-h-[250px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div>
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('settings.workspace-access.title_add')}
              </h3>
              <p className="text-left text-[14px] font-satoshiRegular">
                {t('settings.workspace-access.desc_add')}
              </p>
            </div>
            <div className="px-10 justify-center items-center">
              <div className="flex w-full gap-4 my-6">
                <div className="w-1/2">
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      render={({ field: { onChange, value } }: any) => (
                        <Select
                          key="user"
                          label={t('settings.workspace-access.users')}
                          onChange={onChange}
                          value={value}
                          options={usersAvailable(account, workspace)}
                          errors={[_.get(errors, 'user.message')]}
                        />
                      )}
                      name={'user'}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex flex-col justify-start">
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      defaultValue={RolesList[0]}
                      render={({ field: { onChange, value } }: any) => (
                        <Select
                          key="role"
                          label={t('settings.workspace-access.role')}
                          onChange={(e: any) => {
                            onChange(e);
                            setCurrentRole(e.target.value);
                          }}
                          value={value}
                          options={RolesList}
                          errors={[_.get(errors, 'role.message')]}
                        />
                      )}
                      name={'role'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 flex justify-between">
            <p className="text-grey text-xs text-left">
              {currentRoleDescription}
            </p>
            {isSuccess ? (
              <span className="px-4 py-2 border border-green text-green bg-lightGreen rounded-3xl">
                {t('settings.workspace-access.add_user_success')}
              </span>
            ) : (
              <div className="gap-2 flex justify-end">
                <button
                  title={t('cancel')}
                  className="btn btn-primary-outlined rounded-3xl"
                  onClick={() => setDisplayModal(false)}
                >
                  {t('cancel')}
                </button>
                <SubmitButton
                  title={t('add')}
                  disabled={isLoading}
                  customClass="btn btn-primary rounded-3xl"
                />
              </div>
            )}
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};
