import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Emoji } from 'emoji-mart';
import { displayPrice, getCurrencyInfo } from '../../../utils/currency';
import { BestSellersOrderBy, getBestSellers } from '../../../api/workspaces';
import { useQuery } from 'react-query';
import { useAuth } from '../../../contexts/authContext';
import Loader from '../../loader/loader.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/pro-regular-svg-icons';

const BestSellers: React.FC = () => {
  const { t } = useTranslation();
  const { currentUser, currentWorkspace } = useAuth();
  const [orderBy, setOrderBy] = useState<BestSellersOrderBy>(
    BestSellersOrderBy.TURNOVER,
  ); // timesSold or totalRevenue

  const renderRanking = (idx: number) => {
    switch (idx) {
      case 0:
        return '🥇';
      case 1:
        return '🥈';
      case 2:
        return '🥉';
      default:
        return `#${idx + 1}`;
    }
  };

  const { data: bestSellers, isLoading: isLoadingBestSellers } = useQuery(
    ['connectAccounts', orderBy],
    // @ts-ignore
    () =>
      getBestSellers(currentUser.accessToken, currentWorkspace._id, orderBy),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  return (
    <div className="flex flex-col h-full p-0">
      <div className="flex items-center justify-between">
        <h5 className="mx-2 lg:mx-0 my-5 text-left">
          {t('analytics.best_sellers')}
        </h5>
        <div className="flex mx-2 lg:mx-0  my-5">
          <div
            onClick={() => setOrderBy(BestSellersOrderBy.SALES)}
            className={`rounded-full bg-gray-200 hover:opacity-50 px-3 py-1 cursor-pointer ${
              orderBy === BestSellersOrderBy.SALES
                ? 'bg-lightPrimary hover:opacity-100 opacity-100'
                : 'opacity-25'
            }`}
          >
            #
          </div>
          <div
            onClick={() => setOrderBy(BestSellersOrderBy.TURNOVER)}
            className={`ml-2 rounded-full bg-gray-200 hover:opacity-50 px-3 py-1 cursor-pointer ${
              orderBy === BestSellersOrderBy.TURNOVER
                ? 'bg-lightPrimary hover:opacity-100 opacity-100'
                : 'opacity-25'
            }`}
          >
            {getCurrencyInfo(currentWorkspace?.currency).symbol}
          </div>
        </div>
      </div>
      {!isLoadingBestSellers ? null : (
        <div className="flex h-screen w-full items-center justify-center">
          <Loader />
        </div>
      )}
      {bestSellers?.length || isLoadingBestSellers ? null : (
        <div className="flex-row mt-10">
          <FontAwesomeIcon
            className="opacity-20 mb-4"
            color="#b6b9ce"
            icon={faCartArrowDown}
            size="7x"
          />
          <div className="text-sm font-semibold text-[#6B7194] opacity-20">
            {t('tables.empty-orders')}
          </div>
        </div>
      )}
      {isLoadingBestSellers ? null : (
        <div className="flex-grow relative">
          <div className="lg:absolute lg:inset-0 h-full lg:overflow-auto p-4">
            {bestSellers?.map((product: any, index: number) => (
              <div
                key={index}
                className="relative flex items-center py-4 border border-gray-200 bg-white my-2 rounded-2xl w-full justify-between"
              >
                <div
                  className={`${
                    index < 3 ? 'text-[22px]' : 'text-[12px]'
                  } flex justify-center items-center w-7 h-7 absolute top-[-8px] left-[-8px] bg-lightblue rounded-full text-black text-center self-center`}
                >
                  {renderRanking(index)}
                </div>
                <div className="flex flex-1 items-center justify-between w-full">
                  <div className="mx-4 mr-2 w-2/12">
                    <Emoji emoji={product?.product_emoji} size={36} />
                  </div>
                  <div className="mx-2 w-6/12 overflow-hidden text-ellipsis whitespace-nowrap">
                    {product.product_name}
                  </div>
                  <div className="w-5/12">
                    <span className="font-black font-satoshi">
                      {orderBy === BestSellersOrderBy.SALES
                        ? `${product.sales_count} ${t('analytics.sold', {
                            count: parseInt(product.sales_count),
                          })}`
                        : displayPrice(
                            product?.total_turnover,
                            getCurrencyInfo(currentWorkspace?.currency).symbol,
                            {
                              thousandSeparator: ' ',
                            },
                          )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BestSellers;
