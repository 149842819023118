import React, { useEffect, useState } from 'react';
import OrderDetails from '../order-details/order-details.component';
import { formatDateToLocaleDateString } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';
import { displayPrice, getCurrencyInfo } from '../../../../utils/currency';
import { WithPermissions } from '../../../with-permissions/with-permissions.component';
import { useAuth } from '../../../../contexts/authContext';
import useTrackMixpanelEvent from '../../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import OrderTypeEmoji from '../../order-type-emoji/order-type-emoji.constante';

const OrderRaw = ({
  order,
  requestRefund,
  openOrderId,
  requestValidation,
}: any) => {
  const currency = getCurrencyInfo(order.currency);
  const { currentWorkspace, currentAccount } = useAuth();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (parseInt(openOrderId) === order.order_id) {
      setIsOpen(true);
    }
  }, [openOrderId]);

  const mixpanelProperties = {
    order_id: order.order_id,
    home_id: order.home._id,
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
    trackMixpanelEvent(
      `${!isOpen ? 'Order - Display details' : 'Order - Hide details'}`,
      mixpanelProperties,
    );
  };

  return (
    <>
      <tr
        className="cursor-pointer bg-lightblue my-4 py-4 px-2 h-10 row hidden lg:table-row"
        onClick={handleFilterOpening}
      >
        <td>{order.order_id}</td>
        <td>
          <OrderTypeEmoji isStayExtension={order?.isStayExtension} />
        </td>
        <td>{order.home.name}</td>
        <td>{formatDateToLocaleDateString(order.purchaseDate)}</td>

        <td>{formatDateToLocaleDateString(order.deliveryDate)}</td>
        <td>{order.customer}</td>
        <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
          <td>
            {displayPrice(order.totalAmount, currency.symbol, {
              thousandSeparator: ' ',
            })}
          </td>
        </WithPermissions>
        <td>
          <OrderStatusRaw paymentStatus={order.paymentStatus} />
        </td>
      </tr>
      {!order.products || !isOpen ? null : (
        <OrderDetails
          key={order._id}
          currency={currency}
          requestRefund={requestRefund}
          order={order}
          requestValidation={requestValidation}
        />
      )}
    </>
  );
};

type OrderStatusRawProps = {
  paymentStatus: string;
};

const OrderStatusRaw = ({ paymentStatus }: OrderStatusRawProps) => {
  const { t } = useTranslation();
  return (
    <>
      {paymentStatus === 'paid' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGreen  text-green font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
      {paymentStatus === 'refunded' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGrey  text-grey font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
      {paymentStatus === 'waiting_validation' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightOrange  text-orange font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}{' '}
          <span className="ml-4">&#9888;&#65039; </span>
        </span>
      )}
      {paymentStatus === 'unpaid' && (
        <span className="first-letter:uppercase py-0.5 px-3 text-xs bg-lightGrey  text-grey font-semibold rounded-2xl">
          {t(`orders.${paymentStatus}`)}
        </span>
      )}
    </>
  );
};

export default OrderRaw;
