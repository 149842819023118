import React from 'react';

interface Props {
  children: React.ReactChild;
}

const TabWrapper = ({ children }: Props) => {
  return (
    <div className="container-lg p-2 m-3 md:m-0 mt-0 items-start h-full max-w-[1192px] lg:mx-12">
      {children}
    </div>
  );
};

export default TabWrapper;
