import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import _ from 'lodash';
import DefaultHomePicture from '../../../../assets/images/default-home-picture.jpeg';
import SubmitButton from '../../../submit-button/submit-button.component';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

const HomeInformations = ({
  home,
  updateHome,
  isUpdateLoading,
  disabled,
  setDisabled,
}: any) => {
  const { t } = useTranslation();

  const backendErrors = '';
  const [homePicture, setHomePicture] = useState(
    home.picture ? home.picture : DefaultHomePicture,
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    resetField,
  } = useForm();

  const submit = (data: any) => {
    updateHome(data);
    resetField('picture');
  };

  const handleCancelEdit = () => {
    setHomePicture(home.picture);
    reset();
    setDisabled(true);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <div className="card mb-4 lg:mr-4 mt-4 ">
      <div className="flex justify-between items-center">
        <h5 style={{ textAlign: 'left', margin: 0 }}>
          {t('listings.informations')}
        </h5>
        <div
          className="btn btn-warning rounded-xl"
          onClick={() => setDisabled(false)}
        >
          {t('edit')}
        </div>
      </div>
      <form onSubmit={handleSubmit(submit)} className="mt-4">
        <div className="line-container">
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={home?.name}
            render={({ field: { onChange, value } }: any) => (
              <Input
                disabled={disabled}
                key="name"
                label={t('listings.name')}
                size="small"
                onChange={onChange}
                value={value}
                errors={[
                  _.get(errors, 'name.message'),
                  ...backendErrorsForAttribute('name'),
                ]}
              />
            )}
            name={'name'}
          />
        </div>
        <div className="text-left text-[#6B7194] mb-[2px]">Photo</div>
        <div className="relative mb-2">
          <img
            className="rounded-xl h-52 w-full object-cover"
            src={homePicture}
          />
          {disabled ? null : (
            <div className="absolute top-0 right-0">
              <div>
                <label
                  htmlFor="picture"
                  className="btn absolute top-0 right-0 opacity-75 text-gray-600 bg-gray-100"
                >
                  {t('edit')}
                </label>
              </div>
            </div>
          )}

          {disabled ? null : (
            <Controller
              control={control}
              render={({ field }: any) => (
                <Input
                  style={{ display: 'none' }}
                  disabled={disabled}
                  key="picture"
                  label=""
                  type="file"
                  accept="image/*"
                  id="picture"
                  onChange={(e: any) => {
                    field.onChange(e.target.files);
                    setHomePicture(URL.createObjectURL(e.target.files[0]));
                  }}
                  errors={[
                    _.get(errors, 'picture.message'),
                    ...backendErrorsForAttribute('picture'),
                  ]}
                />
              )}
              name={'picture'}
            />
          )}
        </div>
        {disabled ? null : (
          <div className="flex justify-end mt-6 mb-2">
            <button
              title={'Cancel'}
              className="btn rounded-3xl btn-primary-outlined"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => handleCancelEdit()}
            >
              {t('cancel')}
            </button>
            <SubmitButton
              title={t('confirm')}
              customClass={'btn rounded-3xl btn-primary flex'}
              disabled={isUpdateLoading}
            >
              {!isUpdateLoading ? null : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              )}
            </SubmitButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default HomeInformations;
