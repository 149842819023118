import React, { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOnClickOut(ref: any, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OnClickOut({ children, callback, classname, style }: any) {
  const wrapperRef = useRef(null);
  useOnClickOut(wrapperRef, callback);

  return (
    <div ref={wrapperRef} style={style} className={classname}>
      {children}
    </div>
  );
}

OnClickOut.defaultProps = {
  style: {},
  classname: '',
};

export default OnClickOut;
