import React from 'react';

const ErrorMessage = ({ error, displayDot }: any) => {
  if (Array.isArray(error)) {
    const moreThanOneError = error.length > 1;
    return (
      <div className="ml-4">
        {error.map((err) => {
          return (
            <span key={err} className="text-red-600 text-sm">
              {moreThanOneError ? '•' : ''} {err.localized.full_message}
            </span>
          );
        })}
      </div>
    );
  }
  return (
    <span className="ml-4 text-red-500 mt-2 text-xs">
      {displayDot ? '•' : ''} {error}
    </span>
  );
};

ErrorMessage.defaultProps = {
  displayDot: true,
};

export default ErrorMessage;
