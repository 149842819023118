import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { getOrderDetailsPublic } from '../../api/orders';
import { useQuery } from 'react-query';
// import { Emoji } from 'emoji-mart';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import DefaultHomePicture from '../../assets/images/default-home-picture.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const CheckoutSuccess = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const orderId = searchParams.get('_order') || null;
  const { data } = useQuery(
    ['order'],
    () => orderId && getOrderDetailsPublic(orderId),
    {},
  );

  return (
    <div className="bg-lightGrey min-h-screen">
      <div className="container mx-auto md:py-36 max-w-screen-lg">
        {data && (
          <div className="md:card bg-white p-6 md:p-10">
            <div className="flex justify-between items-center">
              <div>
                <div className="font-bold text-gray-900 text-lg text-left ">
                  {t('booking_success.order')} #{data?.order?.orderId}
                </div>
                <div className="text-sm text-left text-grey">
                  {t('booking_success.order_date')} :{' '}
                  {new Date(data?.order?.purchaseDate).toLocaleDateString() +
                    ' ' +
                    new Date(data?.order?.purchaseDate).toLocaleTimeString()}
                </div>
              </div>
              <a
                className="btn btn-checkout"
                target="_blank"
                href={data.payment.receiptUrl}
                rel="noreferrer"
              >
                {t('booking_success.see_receipt')}
              </a>
            </div>
            <div className="border-b border-grey py-6">
              <div className="py-6">
                <FontAwesomeIcon
                  className="ml-2"
                  color="#50d05a"
                  icon={faCircleCheck}
                  size="5x"
                />
              </div>
              <h1 className="font-bold text-3xl leading-normal mb-6 tracking-tight">
                {t('booking_success.order_received_title')}
              </h1>
            </div>

            <div className="py-6 flex gap-6 border-b border-grey">
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {' '}
                  {t('booking_success.location')}
                </div>
                <div className="flex gap-4">
                  <div>
                    <img
                      className="w-20 h-20 object-cover rounded-2xl"
                      src={data.order.home.picture || DefaultHomePicture}
                      alt={data.order.home.name}
                    />
                  </div>
                  <div>
                    <div className="text-xl font-bold">
                      {data?.order?.home.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-6 flex gap-6 border-b border-grey">
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {t('booking_success.customer_informations')}
                </div>
                <div>{data?.order?.customer}</div>
                <div>{data?.order?.customerEmail}</div>
              </div>
              <div className="flex-1 text-left">
                <div className="mb-2 font-bold text-grey">
                  {t('booking_success.payment_information')}
                </div>
                <div>{t('booking_success.credit_card')}</div>
                <div className="uppercase">
                  {data?.payment?.paymentMethod.card.brand}
                </div>
                <div>**** {data?.payment?.paymentMethod.card.last4}</div>
              </div>
            </div>
            <div className="border-b border-grey pt-6">
              <div className="mb-2 font-bold text-grey text-left">
                {t('booking_success.extended_stay')}
              </div>
              <div className="my-6">
                {data?.order?.stayExtensions.map((stayExtension: any) => (
                  <div
                    key={stayExtension._id}
                    className="flex gap-4 justify-between w-full flex-col lg:flex-row"
                  >
                    <div className="text-left flex flex-col gap-1">
                      <div className="flex gap-3">
                        <span className="">
                          {t('booking_success.reservation')} :
                        </span>
                        <span className="text-weight-700">
                          {stayExtension.reservationId}
                        </span>
                      </div>
                      <div className="flex gap-3">
                        <span className="">{t('booking_success.name')} :</span>
                        <span>{stayExtension.guestName}</span>
                      </div>
                      <div className="flex gap-3">
                        <span className="">
                          {t('booking_success.number_guests')} :
                        </span>
                        <span>{stayExtension.totalGuests}</span>
                      </div>
                    </div>
                    <div className="inline-flex gap-6 items-center text-sm lg:text-lg">
                      <div>
                        <div className="text-left">
                          <span className="text-sm text-grey">
                            {' '}
                            {t('booking_success.arrival')} :
                          </span>
                        </div>
                        <div>
                          <span>
                            {new Date(
                              stayExtension.newArrivalDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="text-left">
                          <span className="line-through text-sm text-grey">
                            {t('booking_success.departure')} :
                          </span>
                        </div>
                        <div>
                          <span className="line-through text-grey">
                            {new Date(
                              stayExtension.originalDepartureDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <span>
                        <FontAwesomeIcon
                          className="text-green"
                          icon={faRight}
                        />
                      </span>
                      <div>
                        <div className="text-left">
                          <span className="text-sm text-green">
                            {t('booking_success.new_departure')} :
                          </span>
                        </div>
                        <div>
                          <span>
                            {new Date(
                              stayExtension.newDepartureDate,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {data?.order?.products.map((product: any, index: number) => (
                  <div
                    key={index}
                    className="flex items-start gap-3 justify-between mb-4 border-b border-lightGrey last:border-b-0 pb-4"
                  >
                    <div className="flex flex-col lg:flex-row items-start gap-4">
                      <div className="flex items-start">
                        {!product.item.emoji ? null : (
                          <Emoji emoji={product.item.emoji} size={36} />
                        )}
                      </div>
                      <div className="text-left">
                        <p className="font-semibold text-md md:text-lg">
                          {(data.order.status === 'confirmed' ||
                            data.order.status === 'cancelled') &&
                          !product.isValidated ? (
                            <del>{product.item.name}</del>p
                          ) : (
                            <span>{product.item.name}</span>
                          )}
                        </p>
                        <div>
                          {product.isValidated ? (
                            <div className="text-green">
                              {t('booking_success.extra_available')}
                            </div>
                          ) : (
                            <div className="text-orange">
                              {data.order.status === 'confirmed' ||
                              data.order.status === 'cancelled' ? (
                                <span className="text-red-500">
                                  {t('booking_success.extra_unavailable')}
                                </span>
                              ) : (
                                <span className="text-orange">
                                  {t('booking_success.waiting_validation')}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex items-start">
                          <p className="text-gray-500 text-sm flex gap-2">
                            <span>{product.quantity}</span>
                            <span>x</span>
                            {displayPrice(
                              product.item.sellingPrice,
                              getCurrencyInfo(data.order.currency).symbol,
                              { thousandSeparator: ' ' },
                            )}
                          </p>
                        </div>
                        {!product.isValidated ? null : (
                          <div>
                            {!product.instruction ? null : (
                              <div className="rounded-xl px-3 py-2 mt-3 bg-lightGrey">
                                <p className="font-bold italic mb-2">
                                  {t('booking_success.instructions')}
                                </p>
                                <p>{product.instruction}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="font-semibold text-lg">
                      {(data.order.status === 'confirmed' ||
                        data.order.status === 'cancelled') &&
                      !product.isValidated ? (
                        <del>
                          {displayPrice(
                            product.item.sellingPrice * product.quantity,
                            getCurrencyInfo(data.order.currency).symbol,
                            { thousandSeparator: ' ' },
                          )}
                        </del>
                      ) : (
                        displayPrice(
                          product.item.sellingPrice * product.quantity,
                          getCurrencyInfo(data.order.currency).symbol,
                          { thousandSeparator: ' ' },
                        )
                      )}
                    </p>
                  </div>
                ))} */}
              </div>
            </div>
            <div className="border-b border-grey py-6">
              <div className="flex flex-col gap-4">
                <div className="text-left flex gap-6 items-center justify-between">
                  <div className="mb-2 font-bold text-grey">
                    {t('booking_success.subtotal')}
                  </div>
                  <div className="font-semibold text-lg">
                    {displayPrice(
                      data?.order?.stayExtensions[0].originalPriceCents,
                      getCurrencyInfo(data.order.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </div>
                </div>
                {data?.order?.stayExtensions[0].discountPercentage > 0 && (
                  <div className="text-left flex gap-6 items-center justify-between">
                    <div className="mb-2 font-bold text-grey">
                      {t('booking_success.discount')}
                    </div>
                    <div className="font-semibold text-md text-green">
                      -
                      {displayPrice(
                        data?.order?.stayExtensions[0].originalPriceCents -
                          data?.order?.stayExtensions[0].newPriceCents,
                        getCurrencyInfo(data.order.currency).symbol,
                        { thousandSeparator: ' ' },
                      )}
                    </div>
                  </div>
                )}
                <div className="text-left flex gap-6 items-center justify-between">
                  <div className="mb-2 text-lg font-bold text-gray-900">
                    TOTAL
                  </div>
                  <div className="font-semibold text-lg">
                    {displayPrice(
                      data?.order?.totalAmount,
                      getCurrencyInfo(data.order.currency).symbol,
                      { thousandSeparator: ' ' },
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-6">
              <div className="flex flex-col gap-4">
                <div>
                  {t('booking_success.order_question')}
                  <a
                    className="text-checkout"
                    href={`mailto:${data.order.support.email}?subject=CozyUp - ${data.order.home.name} - Order #${data.order.orderId} - Question`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data.order.support.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSuccess;
