import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './global/globalSlice';
import jobSlice from './jobs/jobSlice';
import { jobUpdateMiddleware } from './jobs/jobMiddleware';

export const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
    jobs: jobSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jobUpdateMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
