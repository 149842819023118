import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/authContext';

import { useMutation, useQuery } from 'react-query';
import Button from '../../button/button.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';

import { getHomesByWorkspace } from '../../../api/homes';
import { ShareHomeCard } from '../../share-home-card/share-home-card.component';
import { HelpOutline } from '@mui/icons-material';
import { ToggleInput } from '../../toggle/toggle-input.component';
import { Icon, Tooltip } from '@mui/material';
import ChannelManagerLogo from '../../channel-manager-logo/channel-manager-logo.component';
import Loader from '../../loader/loader.component';
import { enableAutomaticMessages } from '../../../api/onboarding';
import { getMessageTemplate } from '../../../data/templates';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import AlertBeforeAction from '../../alert-before-action/alert-before-action.component';
import useTrackOnboardingWithMixpanel from '../../../hooks/useTrackOnboardingWithMixpanel';

type InitMessagesProps = {
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  updateOnboarding?: Function;
  channelManagerIntegrations: any;
  calryIntegrations: any;
  isLoading: boolean;
  airbnbChannels: any;
};

const InitMessages = ({
  setActiveTab,
  channelManagerIntegrations,
  calryIntegrations,
  airbnbChannels,
  isLoading,
}: InitMessagesProps) => {
  const { currentUser, currentWorkspace } = useAuth();
  const [automaticMessageEnabled, setAutomaticMessageEnabled] =
    useState<boolean>(true);
  const [afterReservationEnabled, setAfterReservationEnabled] =
    useState<boolean>(true);
  const [beforeArrivalEnabled, setBeforeArrivalEnabled] =
    useState<boolean>(true);
  const { data: fetchedHomes } = useQuery(
    ['homes', currentWorkspace],
    // @ts-ignore
    () => getHomesByWorkspace(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );
  const [displayTutorial, setDisplayTutorial] = useState(false);
  const { t, i18n } = useTranslation();
  const { trackOnboardingEvent } = useTrackOnboardingWithMixpanel();

  useEffect(() => {
    trackOnboardingEvent('Onboarding - Step 4. AUTO_MESSAGES - Start');
  }, []);

  const hasAutomaticMessageFeature = () => {
    return (
      channelManagerIntegrations?.filter(
        (integration: any) =>
          integration.channelManagerType.isAutomaticMessagesActive,
      ).length > 0 ||
      calryIntegrations?.length > 0 ||
      airbnbChannels?.length > 0
    );
  };

  const handleGoToNextStep = () => {
    if (!automaticMessageEnabled && !hasAutomaticMessageFeature()) {
      setActiveTab(5);
    } else {
      const payload = {
        automaticMessageEnabled,
        afterReservationEnabled,
        beforeArrivalEnabled,
      };
      enableNotifications(payload);
    }
    trackOnboardingEvent(`Onboarding - Step 4. AUTO_MESSAGES - Success`);
  };

  const {
    mutate: enableNotifications,
    isLoading: isLoadingEnableAutomaticMessages,
  } = useMutation(
    (data: any) =>
      enableAutomaticMessages(
        currentUser.accessToken,
        currentWorkspace._id,
        data,
      ),
    {
      enabled: false,
      onSuccess: () => {
        setActiveTab(5);
      },
    },
  );

  const copyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success(t('url_copied'));
  };

  useEffect(() => {
    if (automaticMessageEnabled === false) {
      setAfterReservationEnabled(false);
      setBeforeArrivalEnabled(false);
    } else {
      setAfterReservationEnabled(true);
      setBeforeArrivalEnabled(true);
    }
  }, [automaticMessageEnabled]);

  useEffect(() => {
    if (!afterReservationEnabled && !beforeArrivalEnabled) {
      setAutomaticMessageEnabled(false);
    }
  }, [afterReservationEnabled, beforeArrivalEnabled]);

  return (
    <div>
      <AlertBeforeAction
        isVisible={displayTutorial}
        setIsVisible={setDisplayTutorial}
        title={t('smoobu.webhook.title')}
        successLabel={t('done')}
        cancelLabel={null}
        successAction={() => {
          setDisplayTutorial(false);
        }}
      >
        <div className="flex flex-col gap-6 justify-start px-4 py-6 text-left">
          <div className="text-md">
            {t('smoobu.webhook.step_1')}{' '}
            <div className="flex justify-center mt-3">
              <a
                className="bg-lightGrey rounded-2xl text-primary px-2 py-1 cursor-pointer text-[12px]"
                href="https://login.smoobu.com/fr/settings/overview"
                target="_blank"
                rel="noreferrer"
              >
                {t('smoobu.tutorial.settings_smoobu')}{' '}
                <span className="text-primary">🔗</span>
              </a>
            </div>
          </div>
          <div className="text-md">{t('smoobu.webhook.step_2')}</div>
          <div className="text-md">
            {t('smoobu.webhook.step_3')}{' '}
            <div className="mt-3">
              <span
                className="bg-lightGrey rounded-2xl px-2 py-1 cursor-pointer text-primary md:whitespace-nowrap text-[12px]"
                onClick={() =>
                  copyUrl(
                    '     https://cozy-up-api-prod.herokuapp.com/api/v1.0/smoobu/webhook',
                  )
                }
              >
                https://cozy-up-api-prod.herokuapp.com/api/v1.0/smoobu/webhook
                <span className="text-primary ml-1">📋</span>
              </span>
            </div>
          </div>
        </div>
      </AlertBeforeAction>

      <div className="p-4 rounded-3xl mb-3">
        <h2 className="text-left text-primary text-xl font-bold leading-12">
          {t('onboarding.automatic_messages.setup')}
        </h2>
        <div className="text-left text-grey">
          {t('onboarding.automatic_messages.setup_dsc')}
        </div>
        <div className="mt-4">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {hasAutomaticMessageFeature() === true && (
                <div className=" p-4 my-6 border border-lightGrey rounded-2xl">
                  <div className="flex gap-4 mb-6">
                    {channelManagerIntegrations
                      ?.filter(
                        (integration: any) =>
                          integration.channelManagerType
                            .isAutomaticMessagesActive,
                      )
                      .map((integration: any, index: number) => (
                        <div key={index}>
                          <div className="w-24">
                            <ChannelManagerLogo
                              type={integration.channelManagerType.name.toUpperCase()}
                            />
                          </div>
                          {integration.channelManagerType?.name.toUpperCase() ===
                            'SMOOBU' && (
                            <div className="my-4 px-4 rounded-lg text-orange bg-lightOrange border border-orange text-left flex items-center ">
                              <p>
                                {t(
                                  'onboarding.automatic_messages.smoobu_config',
                                )}
                              </p>
                              <button
                                className="btn btn-warning ml-4 border border-orange"
                                onClick={() => setDisplayTutorial(true)}
                              >
                                {t('see')}
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                    {calryIntegrations?.map(
                      (integration: any, index: number) => (
                        <div className="w-24" key={index}>
                          <ChannelManagerLogo
                            type={integration.name.toUpperCase()}
                          />
                        </div>
                      ),
                    )}
                    {airbnbChannels?.length > 0 && (
                      <div className="w-24">
                        <ChannelManagerLogo type={'AIRBNB'} />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between items-center w-full px-4 ">
                    <span className="text-lg font-satoshiBold">
                      {t('automatic_messages.enable')}
                      <Tooltip
                        title={t('automatic_messages.enable_tooltip')}
                        className="mr-2"
                        enterTouchDelay={0}
                      >
                        <Icon
                          className="icon text-gray-300 cursor-pointer opacity-75"
                          component={HelpOutline}
                        />
                      </Tooltip>
                    </span>
                    <ToggleInput
                      enabled={automaticMessageEnabled}
                      setEnabled={setAutomaticMessageEnabled}
                    />
                  </div>
                  {automaticMessageEnabled && (
                    <div className="flex flex-row gap-4 mt-6 ">
                      <div className="border border-primary rounded-2xl p-4 w-1/2">
                        <ToggleInput
                          enabled={afterReservationEnabled}
                          name={`2880-after_reservation`}
                          label={t(`automatic_messages.2880-after_reservation`)}
                          setEnabled={setAfterReservationEnabled}
                        />
                        <div
                          className={`${
                            !afterReservationEnabled && 'opacity-50'
                          }`}
                        >
                          <div className="flex justify-start">
                            <span className="text-grey text-xs italic">
                              {t(
                                'onboarding.automatic_messages.no_editable_template',
                              )}
                            </span>
                          </div>
                          <div className="rounded-2xl bg-lightblue p-6 my-2 text-left text-gray-600">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: getMessageTemplate(
                                  i18n.language.slice(0, 2) === 'fr'
                                    ? 'fr'
                                    : 'en',
                                  'after_reservation',
                                ),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <div className="border border-primary rounded-2xl p-4 w-1/2">
                        <ToggleInput
                          enabled={beforeArrivalEnabled}
                          name={`2880-before_arrival`}
                          label={t(`automatic_messages.2880-before_arrival`)}
                          setEnabled={setBeforeArrivalEnabled}
                        />
                        <div
                          className={`${!beforeArrivalEnabled && 'opacity-50'}`}
                        >
                          <div className="flex justify-start">
                            <span className="text-grey text-xs italic">
                              {t(
                                'onboarding.automatic_messages.no_editable_template',
                              )}
                            </span>
                          </div>
                          <div className="rounded-2xl bg-lightblue p-6 my-2 text-left text-gray-600">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: getMessageTemplate(
                                  i18n.language.slice(0, 2) === 'fr'
                                    ? 'fr'
                                    : 'en',
                                  'before_arrival',
                                ),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className=" p-4 my-6 border border-lightGrey rounded-2xl text-left">
            <span className="text-lg font-satoshiBold mb-2">
              {t('onboarding.automatic_messages.manual_configure_title')}
            </span>
            <p className="text-md text-left text-gray-600 mt-2">
              {t('onboarding.automatic_messages.manual_configure')}
            </p>
            <div className="flex justify-start my-2">
              <span className="text-grey text-sm">
                {t('your_checkout_areas')} {`(${fetchedHomes?.length})`}
              </span>
            </div>
            <div className="grid grid-cols-3 gap-3 max-h-[500px] overflow-auto bg-lightblue border rounded-xl border-lightGrey  p-3">
              {fetchedHomes &&
                fetchedHomes.map((home: any, index: number) => (
                  <ShareHomeCard home={home} key={index} />
                ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end my-3">
          {/* <div className="font-bold text-grey text-xs">
            {homes?.length === 0 ? null : (
              <span>{t('onboarding.homes.home', { count: homes.length })}</span>
            )}
          </div> */}
        </div>

        <div className="fixed bottom-0 left-0  items-center py-1 border-t border-grey w-full bg-white">
          <div className="px-4 flex justify-center">
            <Button
              className="btn btn-primary-outlined"
              onClick={() => {
                trackOnboardingEvent(
                  'Onboarding - Go back to step 3. CREATE_STORE from Step 4. AUTO_MESSAGES',
                );
                setActiveTab(3);
              }}
            >
              <FontAwesomeIcon style={{ marginRight: 15 }} icon={faArrowLeft} />
              {t('previous')}
            </Button>
            <Button
              disabled={isLoadingEnableAutomaticMessages}
              className="btn btn-primary"
              onClick={() => handleGoToNextStep()}
            >
              {t('onboarding.next')}
              {isLoadingEnableAutomaticMessages ? (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faSpinnerThird}
                  spin
                />
              ) : (
                <FontAwesomeIcon
                  style={{ marginLeft: 15 }}
                  icon={faArrowRight}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitMessages;
