import React, { useEffect, useState } from 'react';
import '../../../../styles/button.styles.scss';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import _ from 'lodash';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import SubmitButton from '../../../submit-button/submit-button.component';
import Select from '../../../select/select.component';

import { Home } from '../../../../types/home.model';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../contexts/authContext';
import { getStripeConnectAccountByWorkspaceId } from '../../../../api/connectAccounts';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

type HomeStripeAccountProps = {
  home: Home;
  updateHome: Function;
  customIdError: string | null;
};

const HomeStripeAccount: React.FC<HomeStripeAccountProps> = ({
  home,
  updateHome,
  customIdError,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const backendErrors = '';

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setError,
  } = useForm();

  useEffect(() => {
    if (!customIdError) return;
    setDisabled(false);
    setError('customId', { type: 'custom', message: t(customIdError) });
  }, [customIdError]);

  const submit = async (data: any) => {
    await updateHome(data);
    setDisabled(true);
  };

  const handleCancelEdit = () => {
    reset();
    setDisabled(true);
  };

  useQuery(
    ['stripeConnectAccount', currentUser, currentAccount],
    () =>
      getStripeConnectAccountByWorkspaceId(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentAccount,
      onSuccess: (data: any) => handleBankAccountOptions(data),
    },
  );

  const handleBankAccountOptions = (bankAccounts: any) => {
    const bankAccountOptions = bankAccounts?.map((ca: any) => {
      return { label: ca.name, key: ca._id };
    });
    setBankAccountOptions(bankAccountOptions);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <div className="card mb-4 lg:mr-4 mt-4 ">
      <div className="flex justify-between items-center">
        <h5 className="text-left m-0">{t('settings.stripe.account')}</h5>
        <div className="flex items-center">
          <div
            className="btn btn-primary-ghost rounded-xl"
            onClick={() =>
              navigate(`/settings/workspaces/${currentWorkspace._id}/`)
            }
          >
            {t('add')}
          </div>
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisabled(false)}
          >
            {t('edit')}
          </div>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            rules={Validation.anyRequired}
            defaultValue={home?.stripeConnectAccount?._id}
            render={({ field: { onChange, value } }: any) => (
              <Select
                disabled={disabled}
                label={`${t('listings.addons_stripe_account')}`}
                key="stripeConnectAccount"
                onChange={onChange}
                value={value}
                options={bankAccountOptions}
                errors={[
                  _.get(errors, 'stripeConnectAccount.message'),
                  ...backendErrorsForAttribute('stripeConnectAccount'),
                ]}
              />
            )}
            name={'stripeConnectAccount'}
          />

          {disabled ? null : (
            <div className="flex justify-end mt-6 mb-2">
              <button
                title={'Cancel'}
                className="btn rounded-3xl btn-primary-outlined"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => handleCancelEdit()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary'}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default HomeStripeAccount;
