import React from 'react';
import { Icon } from '@mui/material';
import DefaultHomePicture from '../../../../assets/images/default-home-picture.jpeg';
import { RemoveCircleOutline } from '@mui/icons-material';
import ChannelManagerLogo from '../../../channel-manager-logo/channel-manager-logo.component';

type HomeCardProps = {
  home: any;
  onDelete: Function;
};

export const HomeCard: React.FC<HomeCardProps> = ({ home, onDelete }: any) => {
  const handleOnDeleteHome = (home: any) => {
    onDelete(home);
  };
  return (
    <div className="p-2 border rounded-xl border-grey bg-white my-1 w-full min-h-[150px]">
      <div className="flex justify-between items-center">
        <div className="text-left flex items-center gap-4">
          <div className="w-[100px] h-[150px]">
            <img
              className="h-full w-full rounded-xl object-cover"
              src={home.picture || DefaultHomePicture}
              alt={home.name}
            />
          </div>
          <div className="flex flex-1 flex-col justify-between h-full gap-3">
            {home.channelManagerConnection?.channelManagerType && (
              <div className="w-20">
                <ChannelManagerLogo
                  type={
                    home.channelManagerConnection?.channelManagerType?.name ||
                    home.channelManagerConnection?.channelManagerType
                  }
                />
              </div>
            )}
            <span className="text-sm font-bold">{home.name}</span>
            <small className="text-greyText lowercase first-letter:uppercase text-xs">
              {home?.address?.city}
            </small>
          </div>
        </div>
        <div>
          <div
            onClick={() => handleOnDeleteHome(home)}
            className="icon ml-3 flex items-center justify-center hover:bg-gray-300 rounded-full p-2 text-gray-500 cursor-pointer"
          >
            <Icon
              className="h-10 w-10 text-red-500"
              fontSize="small"
              component={RemoveCircleOutline}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
