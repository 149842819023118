import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../utils/validation';
import Input from '../../../components/input/input.component';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SubmitButton from '../../../components/submit-button/submit-button.component';
import AddUserModal from '../../../components/modals/add-user-modal.component';
import { ListUsers } from '../../../components/list-users/ListUsers.component';
import { useAuth } from '../../../contexts/authContext';
import {
  deleteAccountGuest,
  getAccount,
  getAccountInvitations,
  updateAccount,
} from '../../../api/accounts';
import { useMutation, useQuery } from 'react-query';
import { ListInvitations } from '../../../components/list-invitations/ListInvitations.component';
import { Account } from '../../../types/account.model';

type AccountSettingsProps = {
  setSubRoute: any;
};

const AccountSettings = ({ setSubRoute }: AccountSettingsProps) => {
  const { t } = useTranslation();
  const { currentUser, currentAccount } = useAuth();
  const [disableEditAccount, setDisableEditAccount] = useState(true);
  const [displayAddUser, setDisplayAddUser] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const handleCancelEditAccount = () => {
    reset();
    setDisableEditAccount(true);
  };

  const {
    data: accountData,
    isLoading: isLoadingAccount,
    refetch: refetchAccount,
  } = useQuery(
    ['account', currentUser, currentAccount],
    () => getAccount(currentUser.accessToken, currentAccount._id),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  const {
    data: invitationsData,
    refetch: refetchInvitations,
    isLoading: isLoadingInvitation,
  } = useQuery(
    ['invitation', currentUser, currentAccount],
    () => getAccountInvitations(currentUser.accessToken, currentAccount._id),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  const { mutate: updateCurrentAccount } = useMutation(
    (data: Account) =>
      updateAccount(currentUser.accessToken, currentAccount._id, data),
    {
      onSuccess: () => {
        setDisableEditAccount(true);
      },
    },
  );

  const { mutate: deleteGuest } = useMutation(
    (userId: string) =>
      deleteAccountGuest(currentUser.accessToken, currentAccount._id, userId),
    {
      onSuccess: () => {
        refetchAccount();
      },
    },
  );

  const submitAccount = async (data: any) => {
    updateCurrentAccount(data);
  };

  useEffect(() => {
    setSubRoute(t('header.account'));
  }, []);

  return isLoadingAccount ? null : (
    <div className="mt-6 mb-12 lg:m-0  flex flex-col items-center justify-center">
      <div className="mt-6 lg:mt-0 w-full">
        <div className="flex justify-between mb-6 w-full border-b h-20 border-gray-200 items-center">
          <h5 className="my-2">{t('settings.details')}</h5>
          <div
            className="btn btn-warning rounded-xl"
            onClick={() => setDisableEditAccount(false)}
          >
            {t('edit')}
          </div>
        </div>
        <form onSubmit={handleSubmit(submitAccount)}>
          <div className="lg:pr-4 lg:w-1/2">
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={accountData?.name}
              render={({ field: { onChange, value } }: any) => (
                <Input
                  disabled={disableEditAccount}
                  key="name"
                  label={t('settings.account.name')}
                  size="small"
                  onChange={onChange}
                  value={value}
                  errors={[
                    _.get(errors, 'name.message'),
                    // ...backendErrorsForAttribute('email'),
                  ]}
                />
              )}
              name={'name'}
            />
          </div>
          {disableEditAccount ? null : (
            <div className="flex justify-end my-4">
              <button
                title={t('cancel')}
                className="btn rounded-3xl btn-primary-outlined"
                onClick={() => handleCancelEditAccount()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary ml-2'}
              />
            </div>
          )}
        </form>

        <div className="">
          <div className="flex mb-6 w-full border-b py-2 border-gray-200 justify-between items-center">
            <AddUserModal
              displayModal={displayAddUser}
              setDisplayModal={setDisplayAddUser}
              refreshInvitations={refetchInvitations}
            />
            <h5 style={{ textAlign: 'left', margin: 0 }}>
              {t('settings.users')}
            </h5>
            <div
              className="btn btn-warning rounded-xl"
              onClick={() => setDisplayAddUser(true)}
            >
              {t('invite')}
            </div>
          </div>
          <div className="mb-12">
            <ListUsers users={accountData?.guests} onDelete={deleteGuest} />
          </div>
        </div>
        <div className="">
          <div className="flex mb-6 w-full border-b py-2 border-gray-200 justify-between h-20 items-center">
            <h5 style={{ textAlign: 'left', margin: 0 }}>
              {'Invitations en attente'}
            </h5>
          </div>
          <div className="mb-12">
            {!isLoadingInvitation && invitationsData && (
              <ListInvitations invitations={invitationsData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
