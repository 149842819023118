export const CURRENCIES: any = [
  {
    label: 'EUR (€)',
    key: 'eur',
    symbol: '€',
  },
  {
    label: 'AUD ($)',
    key: 'aud',
    symbol: 'A$',
  },
  {
    label: 'USD ($)',
    key: 'usd',
    symbol: '$',
  },
  {
    label: 'GBP (£)',
    key: 'gbp',
    symbol: '£',
  },
  {
    label: 'CHF (CHF)',
    key: 'chf',
    symbol: 'CHF',
  },
  {
    label: 'MXN (Mex$)',
    key: 'mxn',
    symbol: 'Mex$',
  },
];

export const getCurrencyInfo = (currency: string) => {
  return (
    CURRENCIES.filter((c: any) => c.key === currency)[0] || { symbol: currency }
  );
};

export type PriceOptions = {
  notCents?: boolean;
  thousandSeparator?: string;
};

export function displayPrice(
  price: number,
  suffix?: string | false,
  options?: PriceOptions,
): string {
  const negative = Math.sign(price) === -1;
  price =
    options && options.notCents
      ? Math.abs(Math.round(price * 100))
      : Math.abs(Math.round(price));
  const int = Math.floor(price / 100);
  let intStr = '' + int;
  if (
    options === undefined ||
    options === null ||
    options.thousandSeparator !== ''
  ) {
    const separator =
      !options ||
      options.thousandSeparator === null ||
      options.thousandSeparator === undefined
        ? ''
        : options.thousandSeparator;
    let formattedInt = '';
    let part;
    for (let i = intStr.length; i > 0; i -= 3) {
      if (i >= 3) {
        part = intStr.substring(i - 3, i);
      } else if (i > 0) {
        part = intStr.substring(0, i);
      } else {
        part = null;
      }

      if (part !== null) {
        if (formattedInt.length > 0) {
          formattedInt = part + separator + formattedInt;
        } else {
          formattedInt = part;
        }
      }
    }
    intStr = formattedInt;
  }
  const frac = price % 100;
  let fracStr;
  const absFrac = Math.abs(frac);
  if (absFrac < 10) {
    fracStr = '0' + absFrac;
  } else {
    fracStr = absFrac.toString();
  }

  if (isNaN(int) || isNaN(parseInt(fracStr))) {
    return '-';
  } else {
    let suffixStr;
    if (suffix) {
      suffixStr = suffix;
    } else if (suffix === false) {
      suffixStr = '';
    } else {
      suffixStr = '€';
    }
    return (negative ? '-' : '') + intStr + ',' + fracStr + suffixStr;
  }
}
