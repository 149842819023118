import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OnClickOut from '../../hooks/useOnClickOut';
import { useAuth } from '../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faBell,
  faCog,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import AddUserModal from '../modals/add-user-modal.component';
import useGetInvitations from '../../hooks/useGetInvitations';
import { useAppSelector } from '../../hooks/useStore';
import { WithPermissions } from '../with-permissions/with-permissions.component';

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [isUserSettingsOpen, setIsUserSettingsOpen] = React.useState(false);
  const [isAccountListOpen, setIsAccountListOpen] = React.useState(false);
  const [displayAddUser, setDisplayAddUser] = React.useState(false);

  React.useState(false);

  const {
    accountsUser,
    currentUser,
    currentAccount,
    currentWorkspace,
    updateCurrentAccount,
    updateCurrentWorkspace,
    logout,
    isAccountOwner,
  } = useAuth();

  const onboardingStatus = useAppSelector(
    (state) => state.global.onboardingStatus,
  );

  const changeAccount = async (account: any) => {
    updateCurrentAccount(account);
    await updateCurrentWorkspace(account.workspaces[0]);
  };

  const { pendingInvitations } = useGetInvitations();

  return (
    <>
      {pathname !== '/onboarding' && (
        <div className="flex items-center justify-between ml-6 mt-3">
          <div>
            {isAccountOwner && onboardingStatus.data.stripeConnect < 1 && (
              <div
                onClick={() =>
                  navigate(`/settings/workspaces/${currentWorkspace._id}`)
                }
                className="cursor-pointer flex gap-6 text-left mr-6 items-center px-4 py-2 border border-orange text-orange bg-lightOrange font-bold  rounded-xl w-fit"
              >
                <span className="text-md">&#9888;&#65039; </span>
                <span className="text-xs">
                  {t('settings.stripe.not-active', {
                    name: currentWorkspace.title,
                  })}
                </span>
                <div>
                  <div className="font-bold px-6 py-1 m-0 border rounded-xl border-orange text-xs">
                    {t('see')}
                  </div>
                </div>
              </div>
            )}
          </div>
          <header className="hidden lg:flex flex-row justify-end items-center">
            <WithPermissions rolesRequired={['ADMIN', 'MANAGER', 'OWNER']}>
              <div
                className="cursor-pointer mr-3 px-4 py-2 pointer border-primary border text-primary text-xs rounded-3xl tracking-wide"
                onClick={() => navigate('/settings/referrals')}
              >
                <p>{t('referrals.cta', { currency: '$' })}</p>
              </div>
            </WithPermissions>
            <WithPermissions rolesRequired={['ADMIN', 'OWNER']}>
              <div
                className="flex items-center hover:bg-lightGrey p-2 cursor-pointer rounded-full mr-3"
                onClick={() => setDisplayAddUser((prevState) => !prevState)}
              >
                <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 18 }} />
              </div>
            </WithPermissions>
            <AddUserModal
              displayModal={displayAddUser}
              setDisplayModal={setDisplayAddUser}
            />
            <div className="relative">
              <OnClickOut callback={() => setIsNotificationOpen(false)}>
                <div
                  className="flex items-center pointer hover:bg-lightGrey p-2 cursor-pointer rounded-full mr-3"
                  onClick={() =>
                    setIsNotificationOpen((prev: boolean) => !prev)
                  }
                >
                  <div className="relative">
                    {pendingInvitations?.length > 0 && (
                      <div className="absolute right-[-3px] top-[-10px]">
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{ fontSize: 8 }}
                          className="text-primary"
                        />
                      </div>
                    )}
                    <FontAwesomeIcon icon={faBell} style={{ fontSize: 18 }} />
                  </div>
                </div>
                {isNotificationOpen && (
                  <div className="block absolute top-10 right-0 text-left z-50 min-w-[250px] max-w-[300px] py-2 mt-2 overflow-hidden bg-white rounded-xl shadow-lg">
                    <div className="pl-3 pb-4 pt-2 border-b-[1px]">
                      <span className="font-bold font-sans text-[18px]">
                        {t('header.notification')}
                      </span>
                    </div>
                    <div className="">
                      {pendingInvitations?.length > 0 ? (
                        pendingInvitations.map((invitation: any) => (
                          <div
                            key={invitation._id}
                            className="px-4 py-1 hover:bg-lightGrey cursor-pointer"
                            onClick={() => navigate('/settings/profile')}
                          >
                            <div className="flex flex-row items-center">
                              <div className="w-[25px]">
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  size="xs"
                                  className="text-primary"
                                />
                              </div>
                              <div className="flex flex-col ml-3">
                                <h5 className="text-[14px] text-[#333]">
                                  {t('invitation.new')}
                                </h5>
                                <p className="text-grey text-[12px]">
                                  {t('invitation.from', {
                                    from:
                                      invitation.from.firstName ??
                                      invitation.from.email,
                                    account: invitation.account.name,
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="font-satoshi text-[14px] px-4 my-2 text-center">
                          {t('invitation.empty')}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </OnClickOut>
            </div>
            <OnClickOut callback={() => setIsUserSettingsOpen(false)}>
              <div
                className="flex relative items-center pointer hover:bg-lightGrey p-2 cursor-pointer rounded-full mr-3"
                onClick={() => setIsUserSettingsOpen((prev: boolean) => !prev)}
              >
                <FontAwesomeIcon icon={faCog} style={{ fontSize: 18 }} />
              </div>
              <div className="relative">
                {isUserSettingsOpen && (
                  <div className="block absolute text-left top-0 right-0 z-50 min-w-[250px] max-w-[300px] pt-2 mt-2 overflow-hidden bg-white rounded-xl shadow-lg">
                    <div className="pl-3 pb-4 pt-2 border-b-[1px]">
                      <span className="font-bold font-sans text-[18px]">
                        {t('header.settings')}
                      </span>
                    </div>
                    <Link
                      to="settings/profile"
                      className="pl-3 py-2 pt-2 border-b-[1px] flex flex-row items-center hover:bg-lightGrey cursor-pointer"
                    >
                      <div className="bg-middleGrey rounded-full w-8 h-8 flex items-center justify-center">
                        <span className="text-md uppercase text-white font-bold">
                          {currentUser?.email?.slice(0, 2)}
                        </span>
                      </div>
                      <div className="ml-2">
                        <span className="font-satoshi font-xs">
                          {currentUser.email}
                        </span>
                      </div>
                    </Link>
                    <div className="px-3 py-2">
                      {isAccountOwner && (
                        <>
                          <div className="hover:bg-lightPrimary my-1 p-1.5 rounded-[4px]">
                            <Link
                              className="text-primary font-semibold"
                              to="settings/account"
                            >
                              {t('header.account')}
                            </Link>
                          </div>
                          <div className="hover:bg-lightPrimary my-1 p-1.5 rounded-[4px] cursor-pointer">
                            <Link
                              className="text-primary font-semibold"
                              to="/settings/workspaces"
                            >
                              {t('header.workspaces')}
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="px-3 py-2 border-t-[1px] border-lightGrey">
                      <div className="hover:bg-lightPrimary my-1 p-1.5 rounded-[4px]">
                        <Link
                          to="sign-in"
                          className="text-primary font-semibold"
                          onClick={logout}
                        >
                          {t('header.log_out')}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </OnClickOut>
            {/* Display account selector only if more than one account */}
            {accountsUser?.length > 1 && (
              <div className="relative">
                <OnClickOut callback={() => setIsAccountListOpen(false)}>
                  <div
                    className="flex items-center justify-between pointer hover:bg-lightGrey p-2 ml-2 cursor-pointer rounded-[8px] min-w-[100px] mr-3"
                    onClick={() =>
                      setIsAccountListOpen((prev: boolean) => !prev)
                    }
                  >
                    <div className="text-left mx-3">
                      <span className="font-satoshi font-xs">
                        {currentAccount?.name}
                      </span>
                    </div>
                    <div className="mr-1">
                      <FontAwesomeIcon icon={faAngleDown} size="lg" />
                    </div>
                    {isAccountListOpen && (
                      <div className="block absolute text-left top-12 right-0 z-50 min-w-[250px] max-w-[300px] py-2 mt-2 overflow-hidden bg-white rounded-xl shadow-lg">
                        <div className="pl-3 pb-4 pt-2 border-b-[1px]">
                          <span className="font-bold font-sans text-[18px]">
                            {t('header.account')}
                          </span>
                        </div>
                        <div className="mt-1">
                          {accountsUser.map((acc: any) => (
                            <div
                              key={acc._id}
                              onClick={() => changeAccount(acc)}
                              className={`px-4 py-1 m-2 cursor-pointer p-1 rounded-[4px]
                        ${
                          currentAccount.name === acc.name
                            ? 'bg-lightPrimary'
                            : 'text-[14px]'
                        }`}
                            >
                              <div className="flex flex-row items-center ">
                                <div className="flex flex-col ml-3">
                                  <h5 className="text-[14px] text-primary">
                                    {acc.name}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </OnClickOut>
              </div>
            )}
          </header>
        </div>
      )}
    </>
  );
};

export default Header;
