import React, { Dispatch, useEffect, useRef, useState } from 'react';
import SubmitButton from '../../../submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import _ from 'lodash';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import TextArea from '../../../text-area/text-area.component';
import { useLocation, useNavigate } from 'react-router-dom';
import 'emoji-mart/css/emoji-mart.css';
import { Emoji, Picker } from 'emoji-mart';
import colors from '../.././../../styles/_colors.module.scss';
import OnClickOut from '../../../../hooks/useOnClickOut';
import { useMutation } from 'react-query';
import { useAuth } from '../../../../contexts/authContext';
import { Product } from '../../../../types/product.model';
import { createProductByWorkspace } from '../../../../api/products';
import { useTranslation } from 'react-i18next';
import Select from '../../../select/select.component';
import { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faPlus,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { upload } from '../../../../api/files';
import { useAppDispatch } from '../../../../hooks/useStore';
import { fetchOnboardingStatus } from '../../../../redux/global/globalActions';
import { resizeImage } from '../../../../utils/image';
import PriceInput from '../../../price-input/PriceInput.component';
import { Tooltip } from '@mui/material';
import { preparationTimeInHours } from '../../../../utils/date';
import useSocket from '../../../../hooks/useSocket';
import useTrackMixpanelEvent from '../../../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { jobActions } from '../../../../redux/jobs/jobActions';
import { JobStatus } from '../../../../redux/jobs/jobSlice';

type Props = {
  setSubRoute: Dispatch<string>;
  setRefresh: Dispatch<boolean>;
  currency: any;
};

const isAvailableOptions = [
  { label: t('products.status.available'), key: true },
  { label: t('products.status.unavailable'), key: false },
];

const NewProduct = ({ setSubRoute, setRefresh, currency }: Props) => {
  const location: any = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { currentUser, currentWorkspace, currentAccount } = useAuth();

  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emoji, setEmoji] = useState<string>(
    location?.state?.product?.emoji || 'grey_question',
  );
  const dispatch = useAppDispatch();
  const { sendEvent } = useSocket();

  const backendErrors = '';
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSubRoute(t('add'));
  }, []);

  const { mutate: createNewProduct, isLoading: isLoadingCreateProduct } =
    useMutation(
      (data: Product) =>
        createProductByWorkspace(
          currentUser.accessToken,
          currentWorkspace._id,
          data,
        ),
      {
        onSuccess: (response: any) => {
          const productId = response.data._id;
          setRefresh(true);
          sendEvent('product:translation:start', {
            productIds: [productId],
            lang: i18n.language.slice(0, 2),
          });
          dispatch(
            jobActions.addJob({
              type: 'product:translation',
              id: `product:translation:${response.data._id}`,
              status: JobStatus.IN_PROGRESS,
              callback: () => setRefresh(true),
            }),
          );
          trackMixpanelEvent('Product - creation success', mixpanelProperties);
          dispatch(
            fetchOnboardingStatus(
              currentUser.accessToken,
              currentWorkspace._id,
            ),
          );
          navigate('/products');
        },
      },
    );

  const [productPictures, setProductPictures] = useState<any[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const { mutateAsync: uploadNewPicture, isLoading: isLoadingPicture } =
    useMutation((file: File) => upload(currentUser.accessToken, file));

  const handleOnChangePicture = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length) {
      const newPicture = {
        order: 1,
        file: e.target.files[0],
        localUrl: URL.createObjectURL(e.target.files[0]),
      };
      setProductPictures((pictures) => [...pictures, newPicture]);
    }
  };

  const handleOnRemovePicture = async (indexToDelete: number) => {
    const copyPictures = productPictures.filter(
      (item, index) => index !== indexToDelete,
    );
    setProductPictures(copyPictures);
  };

  const handleCancelEdit = () => {
    navigate('/products');
  };

  const uploadPictures = async (pictures: any[]) => {
    return await Promise.all(
      pictures.map(async (picture) => {
        const resizedFile = await resizeImage(picture.file);
        if (resizedFile) {
          return await uploadNewPicture(resizedFile);
        }
      }),
    );
  };

  const submit = async (data: any) => {
    setIsLoading(true);
    data.costPrice = Math.round(+data.costPrice * 100);
    data.sellingPrice = Math.round(+data.sellingPrice * 100);
    if (productPictures.length) {
      const picturesUploaded = await uploadPictures(productPictures);
      const pictures = picturesUploaded.map((p) => ({ url: p.url, order: 1 }));
      createNewProduct({
        ...data,
        validationRequired:
          data.validationRequired === 'true' ||
          data.validationRequired === true,
        emoji,
        pictures,
      });
      setIsLoading(false);
    } else {
      createNewProduct({
        ...data,
        validationRequired:
          data.validationRequired === 'true' ||
          data.validationRequired === true,
        emoji,
      });
      setIsLoading(false);
    }
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const pickEmoji = (emoji: any) => {
    emoji?.id && setEmoji(emoji.id);
  };

  const requireValidationOptions = [
    { label: t('yes'), key: true },
    { label: t('no'), key: false },
  ];

  return (
    <div className="card mt-4">
      <div className="mb-6 mt-2">
        <h5 style={{ textAlign: 'left', margin: 0 }}>
          {t('products.informations')}:
        </h5>
      </div>
      <div className="lg:flex">
        <div>
          <label className="text-left text-[#6B7194]">
            {t('products.emoji')}
          </label>
          <div
            className="relative p-10 mt-2 border border-gray-300 rounded-xl w-fit"
            onClick={() => setDisplayEmojiPicker(!displayEmojiPicker)}
          >
            <Emoji className="absolute" emoji={emoji} size={60} />
          </div>
          <OnClickOut
            callback={() => setDisplayEmojiPicker(false)}
            style={{ position: 'relative', top: 0, left: 0 }}
          >
            {!displayEmojiPicker ? null : (
              <div className="absolute">
                <Picker
                  // style={{ width: 'unset' }}
                  showPreview={false}
                  showSkinTones={false}
                  set="apple"
                  color={colors.primary}
                  onClick={pickEmoji}
                />
              </div>
            )}
          </OnClickOut>
        </div>
        <div className="mt-6 lg:mt-0 lg:px-14 w-full">
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={location?.state?.product?.name}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="name"
                    label={t('products.name')}
                    size="small"
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'name.message'),
                      ...backendErrorsForAttribute('name'),
                    ]}
                  />
                )}
                name={'name'}
              />
            </div>
            <Controller
              control={control}
              rules={Validation.anyRequired}
              defaultValue={location?.state?.product?.description}
              render={({ field: { onChange, value } }: any) => (
                <TextArea
                  key="description"
                  label={t('products.description')}
                  onChange={onChange}
                  value={value}
                  errors={[
                    _.get(errors, 'description.message'),
                    ...backendErrorsForAttribute('description'),
                  ]}
                />
              )}
              name={'description'}
            />
            <div>
              <Controller
                control={control}
                render={({ field: { onChange, value } }: any) => (
                  <TextArea
                    key="instruction"
                    label={t('products.instructions')}
                    onChange={onChange}
                    value={value}
                    tooltip={`${t('products.instructions_tooltip')}`}
                    errors={[
                      _.get(errors, 'instruction.message'),
                      ...backendErrorsForAttribute('instruction'),
                    ]}
                  />
                )}
                name={'instruction'}
              />
            </div>
            <div className="flex justify-between">
              <div className="pr-4 flex-1">
                <Controller
                  control={control}
                  rules={{ ...Validation.anyRequired }}
                  defaultValue={(
                    location?.state?.product?.costPrice / 100
                  ).toFixed(2)}
                  render={({ field: { onChange, value } }: any) => (
                    <PriceInput
                      key="costPrice"
                      label={`${t('products.cost_price')} (${currency.symbol})`}
                      size="small"
                      onChange={onChange}
                      value={value}
                      errors={[
                        _.get(errors, 'costPrice.message'),
                        ...backendErrorsForAttribute('costPrice'),
                      ]}
                    />
                  )}
                  name={'costPrice'}
                />
              </div>
              <div className="pl-4 flex-1">
                <Controller
                  control={control}
                  rules={{
                    ...Validation.anyRequired,
                    ...Validation.price,
                    ...Validation.greaterThanZero,
                  }}
                  defaultValue={(
                    location?.state?.product?.sellingPrice / 100
                  ).toFixed(2)}
                  render={({ field: { onChange, value } }: any) => (
                    <>
                      <PriceInput
                        key="sellingPrice"
                        label={`${t('products.selling_price')} (${
                          currency.symbol
                        })`}
                        size="small"
                        onChange={onChange}
                        value={value}
                        helperText={`${t('products.gain')} ${
                          value &&
                          (
                            value -
                            (value * currentAccount.percentageFeeHost) / 100
                          ).toFixed(2)
                        }€`}
                        errors={[
                          _.get(errors, 'sellingPrice.message'),
                          ...backendErrorsForAttribute('sellingPrice'),
                        ]}
                      />
                    </>
                  )}
                  name={'sellingPrice'}
                />
              </div>
            </div>
            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={true}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      key="isAvailable"
                      label={t('products.status.status')}
                      onChange={onChange}
                      value={value}
                      options={isAvailableOptions}
                      errors={[
                        _.get(errors, 'isAvailable.message'),
                        ...backendErrorsForAttribute('isAvailable'),
                      ]}
                    />
                  )}
                  name={'isAvailable'}
                />
              </div>
              <div className="lg:pl-4 flex-1">
                <Tooltip title={`${t('delete')}`}>
                  <Controller
                    control={control}
                    rules={{ ...Validation.anyRequired, ...Validation.integer }}
                    defaultValue={
                      location?.state?.product?.preparationTimeInHours || 0
                    }
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        key="preparationTimeInHours"
                        label={`${t('products.preparation_time')}`}
                        onChange={onChange}
                        value={value}
                        options={preparationTimeInHours}
                        tooltip={`${t('products.preparation_time_dsc')}`}
                        errors={[
                          _.get(errors, 'preparationTimeInHours.message'),
                          ...backendErrorsForAttribute(
                            'preparationTimeInHours',
                          ),
                        ]}
                      />
                    )}
                    name={'preparationTimeInHours'}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  defaultValue={''}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      key="validationRequired"
                      label={t('products.require_validation')}
                      onChange={onChange}
                      value={value}
                      tooltip={`${t('orders.require_validation_tooltip')}`}
                      options={requireValidationOptions}
                      errors={[
                        _.get(errors, 'validationRequired.message'),
                        ...backendErrorsForAttribute('validationRequired'),
                      ]}
                    />
                  )}
                  name={'validationRequired'}
                />
              </div>
              <div className="flex-1"></div>
            </div>

            <div className="mb-6 flex flex-col justify-start">
              <label className="text-left text-[#6B7194] mb-3">
                {t('products.pictures')} ({productPictures.length}/5)
              </label>
              <div className="flex flex-col lg:flex-row w-full gap-2 flex-wrap">
                {!productPictures.length
                  ? null
                  : productPictures.map((picture: any, index: number) => (
                      <div key={index} className="relative">
                        <img
                          className="rounded-3xl flex-1 h-48 w-full lg:w-48 object-cover"
                          src={picture.localUrl}
                        />
                        <a
                          className="absolute top-3 right-3 cursor-pointer h-8 w-8 border border-primary flex items-center justify-center bg-gray-50 rounded-full"
                          onClick={() => handleOnRemovePicture(index)}
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            color="#7282E5"
                            size="lg"
                          />
                        </a>
                      </div>
                    ))}
                {productPictures.length >= 5 ? null : (
                  <div
                    onClick={() => handleUploadClick()}
                    className="group flex flex-col justify-center items-center cursor-pointer shadow rounded-3xl font-satoshi h-48 w-full lg:w-48  hover:border-primary border-[2px] border-transparent"
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size="3x"
                      color="#d3d3d3"
                      className="group-hover:text-primary"
                    />
                    <input
                      id="pictures"
                      key="pictures"
                      ref={inputRef}
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={handleOnChangePicture}
                    />
                    <p className="mt-3 text-middleGrey font-medium group-hover:text-primary text-[16px]">
                      {t('products.add_picture')}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end my-4">
              <button
                title={t('cancel')}
                className="btn rounded-3xl btn-primary-outlined"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => handleCancelEdit()}
              >
                {t('cancel')}
              </button>
              <SubmitButton
                title={t('confirm')}
                customClass={'btn rounded-3xl btn-primary flex'}
                disabled={
                  isLoading || isLoadingPicture || isLoadingCreateProduct
                    ? true
                    : false
                }
              >
                {isLoading || isLoadingPicture || isLoadingCreateProduct ? (
                  <FontAwesomeIcon
                    style={{ marginLeft: 15 }}
                    icon={faSpinnerThird}
                    spin
                  />
                ) : null}
              </SubmitButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
