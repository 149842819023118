import { CreateUser, User } from '../types/user.model';

const qs = require('qs');

import { api } from '../utils/request';

export const createUser = async (data: CreateUser) => {
  try {
    const res = await api.post(
      `/users/`,
      { ...data },
      { ...api.defaults.headers.common },
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const createUserFromInvitation = async (data: CreateUser) => {
  try {
    const res = await api.post(
      `/users/from-invitation/${data.invitationId}`,
      { ...data },
      { ...api.defaults.headers.common },
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const findUserByFirebaseId = async (
  userToken: string,
  firebaseId: string,
) => {
  try {
    const res = await api.get(`/users/firebase-id/${firebaseId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res;
  } catch (err: any) {
    console.error(err);
  }
};

export const findUserByEmail = async (userToken: string, email: string) => {
  try {
    const res = await api.get(`/users/email/${email}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });
    return res;
  } catch (err: any) {
    console.error(err);
  }
};

export const checkUserExist = async (email: string) => {
  try {
    const res = await api.get(`/users/check-exist/${email}`, {
      headers: {
        ...api.defaults.headers.common,
      },
    });
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const updateUser = async (
  authToken: string,
  userId: string,
  data: User,
) => {
  try {
    const res = await api.patch(
      `/users/${userId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const getAccountInvitationsByUser = async (
  authToken: string,
  params?: any,
) => {
  try {
    const res = await api.get(`/users/account-invitations/list`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
      ...params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};
