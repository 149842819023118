import React, { Suspense } from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { store } from './redux';
import { Provider } from 'react-redux';
import Router from './Router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './contexts/authContext';
import './mixpanel';
import { Toaster } from 'react-hot-toast';
import ToastManager from './components/toast-manager/toast-manager.component';

const App = () => {
  const { t } = useTranslation();
  const queryClient = new QueryClient();

  const cozyUpTheme = createTheme({
    typography: {
      fontFamily: 'Inter',
      fontSize: 14,
    },
  });
  return (
    <Suspense fallback={`${t('loading')}`}>
      <ToastManager />
      <Toaster
        toastOptions={{
          // Define default options
          duration: 10000,
        }}
        position="bottom-center"
      />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={cozyUpTheme}>
            <AuthProvider>
              <Router />
            </AuthProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
