import React, { useEffect, useState } from 'react';

import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import HomesList from '../../components/_main/home/homes-list/homes-list.component';
import HomeDetails from '../../components/_main/home/home-details/home-details.component';
import AddHome from '../../components/_main/home/add-home/add-home.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useMutation, useQuery } from 'react-query';
import { createMultipleHomes } from '../../api/homes';
import { useAuth } from '../../contexts/authContext';
import Filters from '../../components/filters/filters.component';
import { useTranslation } from 'react-i18next';
import { SelectHomeImportModal } from '../../components/modals/select-home-import-modal.component';
import { ImportFromAirbnbModal } from '../../components/modals/import-from-airbnb-modal.component';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { IntegrateBeds24Modal } from '../../components/modals/integrate-beds24-modal.component';
import SelectBeds24PropertiesModal from '../../components/modals/select-beds24-properties-modal.component';
import {
  getCalryIntegrations,
  getChannelManagerIntegrations,
  integrateCalry,
} from '../../api/channelManagers';
import { Home } from '../../types/home.model';
import SelectBookingSyncPropertiesModal from '../../components/modals/select-bookingsync-properties-modal.component';
import { IntegrateBookingSyncModal } from '../../components/modals/integrate-bookingsync-modal.component';
import SelectHospitableConnectAirbnbPropertiesModal from '../../components/modals/select-hospitable-connect-airbnb-properties-modal.component';
import { useAppSelector } from '../../hooks/useStore';
import { requestHospitableConnectAuth } from '../../api/platforms';
import { SelectAirbnbMethodModal } from '../../components/modals/select-airbnb-method-modal.component';
import SelectSmoobuPropertiesModal from '../../components/modals/select-smoobu-properties-modal.component';
import { IntegrateSmoobuModal } from '../../components/modals/integrate-smoobu-modal.component';
import SelectCalryPropertiesModal from '../../components/modals/select-calry-properties-modal.component';
import SelectHospitablePropertiesModal from '../../components/modals/select-hospitable-properties-modal.component';
import { checkIfChannelManagerIntegrationWithAutoMessagesAvailable } from '../../utils/channel-manager';
import ImportSuperHotePropertiesModal from '../../components/modals/import-superhote-properties-modal/import-superhote-properties-modal.component';
import IntegrateSuperHoteModal from '../../components/modals/integrate-superhote-modal/integrate-superhote-modal.component';
import useGetHomes from '../../hooks/useGetHomes';
import { SortOptions } from '../../types/generic-types';

const Homes = () => {
  const location: any = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [hasAutomaticMessagesAvailable, setHasAutomaticMessagesAvailable] =
    useState(false);
  const homeFilters = [
    { label: t('filters.active'), value: 'active' },
    { label: t('filters.archived'), value: 'archived' },
    { label: t('filters.all'), value: '' },
  ];

  const [statusHome, setStatusHome] = useState('active');
  const [displayImportAirbnbModal, setDisplayImportAirbnbModal] =
    useState<boolean>(false);

  const [displayBookinSyncModal, setDisplayBookingSyncModal] =
    useState<boolean>(false);

  const [displayIntegrateBeds24Modal, setDisplayIntegrateBeds24Modal] =
    useState<boolean>(false);
  const [displayIntegrateSuperHoteModal, setDisplayIntegrateSuperHoteModal] =
    useState<boolean>(false);
  const [
    displaySelectBeds24PropertiesModal,
    setDisplaySelectBeds24PropertiesModal,
  ] = useState<boolean>(false);
  const [
    displaySelectSuperHotePropertiesModal,
    setDisplaySelectSuperHotePropertiesModal,
  ] = useState<boolean>(false);
  const [displayIntegrateSmoobuModal, setDisplayIntegrateSmoobuModal] =
    useState<boolean>(false);
  const [
    displaySelectSmoobuPropertiesModal,
    setDisplaySelectSmoobuPropertiesModal,
  ] = useState<boolean>(false);

  const [
    displaySelectHospitablePropertiesModal,
    setDisplaySelectHospitablePropertiesModal,
  ] = useState<boolean>(false);

  const [
    displaySelectCalryPropertiesModal,
    setDisplaySelectCalryPropertiesModal,
  ] = useState<boolean>(false);

  const [
    displayImportHospitableConnectAirbnbModal,
    setDisplayImportHospitableConnectAirbnbModal,
  ] = useState<boolean>(false);

  const [displayAirbnbMethodModal, setDisplayAirbnbMethodModal] =
    useState<boolean>(false);

  const [
    displaySelectBookingSyncPropertiesModal,
    setDisplaySelectBookingSyncPropertiesModal,
  ] = useState<boolean>(false);

  const [calryIntegrationId, setCalryIntegrationId] = useState<string>('');

  const match = useMatch({ path: 'homes', end: true });

  const [searchedHome, setSearchedHome] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [activeSort, setActiveSort] = useState<SortOptions>({
    direction: '',
    field: '',
  });

  const sortHomes = (field: string, direction: string) => {
    if (activeSort.field === field) {
      return activeSort.direction === 'ASC'
        ? setActiveSort({
            field: field,
            direction: 'DESC',
          })
        : setActiveSort({ field: field, direction: 'ASC' });
    }
    setActiveSort({
      field: field,
      direction: direction,
    });
  };

  const {
    homes: homesData,
    refetchHomes,
    isFetching: isLoadingHomes,
  } = useGetHomes(activePage, activeSort, searchedHome, {
    status: statusHome,
  });

  const headerProps = match
    ? {
        actionTitle: `+ ${t('add')}`,
        callback: () => {
          handleAddHome();
        },
        displaySearchBar: true,
        searchText: searchedHome,
        setSearchText: setSearchedHome,
      }
    : null;

  const handleAddHome = () => {
    trackMixpanelEvent('Home - Click on add home button', mixpanelProperties);
    setDisplayImportHomeMethod(true);
  };

  const [subRoute, setSubRoute] = useState('');
  const { currentUser, currentWorkspace, currentAccount } = useAuth();
  const [displayImportHomeMethod, setDisplayImportHomeMethod] =
    useState<boolean>(location?.state?.displayAddModal || false);

  const [searchParams] = useSearchParams();

  const fromHospitableConnectAirbnb = searchParams.get(
    'fromHospitableConnectAirbnb',
  );

  const fromCalry = searchParams.get('fromCalry');

  useEffect(() => {
    if (fromHospitableConnectAirbnb && fromHospitableConnectAirbnb == 'true') {
      setDisplayImportHospitableConnectAirbnbModal(true);
    }
  }, [fromHospitableConnectAirbnb]);

  useEffect(() => {
    if (fromCalry && fromCalry == 'true') {
      setDisplayImportHomeMethod(true);
    }
  }, [fromCalry]);

  const mixpanelProperties = {
    workspace_id: currentWorkspace._id,
    account_id: currentAccount._id,
    account_name: currentAccount.name,
  };

  const hasAirbnbConnection = useAppSelector(
    (state) => state.global.hasAirbnbConnection,
  );

  const handleSelectedProperties = (properties: any[]) => {
    createHomes({ homes: properties });
    setDisplaySelectBeds24PropertiesModal(false);
    setDisplaySelectBookingSyncPropertiesModal(false);
    setDisplayImportHospitableConnectAirbnbModal(false);
    setDisplaySelectSmoobuPropertiesModal(false);
    setDisplaySelectCalryPropertiesModal(false);
    setDisplaySelectHospitablePropertiesModal(false);
    setDisplaySelectSuperHotePropertiesModal(false);
    refetchHomes();
  };

  const { mutate: createHomes, isLoadingCreationMultiple } = useMutation(
    (data: Home[]) =>
      createMultipleHomes(currentUser.accessToken, currentWorkspace._id, data),
    {
      onSuccess: () => {
        setTimeout(() => {
          refetchHomes();
        }, 1000);
      },
    },
  );

  const REDIRECT_URI_HOSPITABLE_CONNECT: string =
    process.env.REACT_APP_HOSPITABLE_CONNECT_AIRBNB_REDIRECT_URI_FROM_HOMES ||
    '';

  const { mutate: authRequestHospitableConnect } = useMutation(
    () =>
      requestHospitableConnectAuth(
        currentUser.accessToken,
        currentAccount._id,
        REDIRECT_URI_HOSPITABLE_CONNECT,
      ),
    {
      onSuccess: (response: any) => {
        window.location.href = response.data.return_url;
      },
    },
  );

  const { data: channelManagerIntegrations } = useQuery(
    ['channelManagerIntegrations', currentWorkspace],
    // @ts-ignore
    () =>
      getChannelManagerIntegrations(
        currentUser.accessToken,
        currentWorkspace._id,
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
      onSuccess: (data: any) => {
        const hasCMIntAutoMesagesAvailable =
          checkIfChannelManagerIntegrationWithAutoMessagesAvailable(data);
        setHasAutomaticMessagesAvailable(
          hasCMIntAutoMesagesAvailable || hasAirbnbConnection,
        );
      },
    },
  );

  const { data: calryIntegrations } = useQuery(
    ['calryIntegrationsKey', currentWorkspace],
    // @ts-ignore
    () => getCalryIntegrations(currentUser.accessToken, currentWorkspace._id),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const {
    mutate: requestCalryIntegration,
    // isLoading: isLoadingRequestCalryIntegration,
  } = useMutation(
    (integrationDefinitionKey: string) =>
      integrateCalry(
        currentUser.accessToken,
        currentWorkspace._id,
        integrationDefinitionKey,
        process.env.REACT_APP_COZYUP_URL + '/homes?fromCalry=true',
      ),
    {
      onSuccess: (response: any) => {
        window.location.href = response.link;
      },
    },
  );

  const handleCreationMethod = (method: string) => {
    switch (method) {
      case 'beds24':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'BEDS24',
          ).length
        ) {
          requestCalryIntegration('beds24');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'BEDS24',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'bookingSync':
        if (
          channelManagerIntegrations &&
          !channelManagerIntegrations.filter(
            (integration: any) =>
              integration.channelManagerType.name === 'BOOKING_SYNC',
          ).length
        ) {
          setDisplayBookingSyncModal(true);
        } else {
          setDisplaySelectBookingSyncPropertiesModal(true);
        }
        break;
      case 'smoobu':
        if (
          channelManagerIntegrations &&
          !channelManagerIntegrations.filter(
            (integration: any) =>
              integration.channelManagerType.name === 'SMOOBU',
          ).length
        ) {
          setDisplayIntegrateSmoobuModal(true);
        } else {
          setDisplaySelectSmoobuPropertiesModal(true);
        }
        break;
      case 'superhote':
        if (
          channelManagerIntegrations &&
          !channelManagerIntegrations.filter(
            (integration: any) =>
              integration.channelManagerType.name === 'SUPERHOTE',
          ).length
        ) {
          setDisplayIntegrateSuperHoteModal(true);
        } else {
          setDisplaySelectSuperHotePropertiesModal(true);
        }
        break;
      case 'hospitable':
        if (
          channelManagerIntegrations &&
          !channelManagerIntegrations.filter(
            (integration: any) =>
              integration.channelManagerType.name === 'HOSPITABLE',
          ).length
        ) {
          window.location.href = `${process.env.REACT_APP_HOSPITABLE_AUTH_URL}?client_id=${process.env.REACT_APP_HOSPITABLE_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HOSPITABLE_CONNECT_AIRBNB_REDIRECT_URI}&response_type=code`;
        } else {
          setDisplaySelectHospitablePropertiesModal(true);
        }
        break;
      case 'hostaway':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'HOSTAWAY',
          ).length
        ) {
          requestCalryIntegration('hostaway');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'HOSTAWAY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'lodgify':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'LODGIFY',
          ).length
        ) {
          requestCalryIntegration('lodgify');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'LODGIFY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'avaibook':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'AVAIBOOK',
          ).length
        ) {
          requestCalryIntegration('avaibook');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'AVAIBOOK',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'fantasticstay':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'FANTASTICSTAY',
          ).length
        ) {
          requestCalryIntegration('fantasticstay');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'FANTASTICSTAY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'guesty':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'GUESTY',
          ).length
        ) {
          requestCalryIntegration('guesty');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'GUESTY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'hostfully':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'HOSTFULLY',
          ).length
        ) {
          requestCalryIntegration('hostfully');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'HOSTFULLY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'hostify':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'HOSTIFY',
          ).length
        ) {
          requestCalryIntegration('hostify');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'HOSTIFY',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'lodgix':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'LODGIX',
          ).length
        ) {
          requestCalryIntegration('lodgix');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'LODGIX',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'ownerrez':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'OWNERREZ',
          ).length
        ) {
          requestCalryIntegration('ownerrez');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'OWNERREZ',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'tokeet':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'TOKEET',
          ).length
        ) {
          requestCalryIntegration('tokeet');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) => integration.name?.toUpperCase() === 'TOKEET',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'uplisting':
        if (
          calryIntegrations &&
          !calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'UPLISTING',
          ).length
        ) {
          requestCalryIntegration('uplisting');
        } else {
          const currentIntegration = calryIntegrations.filter(
            (integration: any) =>
              integration.name?.toUpperCase() === 'UPLISTING',
          )[0];
          setCalryIntegrationId(currentIntegration?.id);
          setDisplaySelectCalryPropertiesModal(true);
        }
        break;
      case 'manual':
        trackMixpanelEvent('Home - Add manually', mixpanelProperties);
        setDisplayImportHomeMethod(false);
        navigate('/homes/new');
        break;
      case 'airbnb_method':
        setDisplayAirbnbMethodModal(true);
        break;
      case 'airbnb_scrapper':
        trackMixpanelEvent('Home - Import from AirBnB', mixpanelProperties);
        setDisplayImportAirbnbModal(true);
        break;

      case 'hospitable_connect_airbnb':
        trackMixpanelEvent(
          'Home - Import from Hospitable COnnect AirBnB',
          mixpanelProperties,
        );
        if (hasAirbnbConnection) {
          setDisplayImportHospitableConnectAirbnbModal(true);
        } else {
          authRequestHospitableConnect();
        }
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (refreshHomes) {
  //     refetchHomes();
  //     setRefreshHomes(false);
  //   }
  // }, [refreshHomes]);

  return (
    <TabWrapper>
      <>
        <SelectHomeImportModal
          displayModal={displayImportHomeMethod}
          setDisplayModal={setDisplayImportHomeMethod}
          onChoose={handleCreationMethod}
        />
        {displayImportHospitableConnectAirbnbModal === false ? null : (
          <SelectHospitableConnectAirbnbPropertiesModal
            displayModal={displayImportHospitableConnectAirbnbModal}
            setDisplayModal={setDisplayImportHospitableConnectAirbnbModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
            isLoadingCreation={isLoadingCreationMultiple}
          />
        )}
        <SelectAirbnbMethodModal
          displayModal={displayAirbnbMethodModal}
          setDisplayModal={setDisplayAirbnbMethodModal}
          onChoose={handleCreationMethod}
        />
        <ImportFromAirbnbModal
          displayModal={displayImportAirbnbModal}
          setDisplayModal={setDisplayImportAirbnbModal}
          refetchHomes={refetchHomes}
        />
        <IntegrateBookingSyncModal
          displayModal={displayBookinSyncModal}
          setDisplayModal={setDisplayBookingSyncModal}
        />
        <IntegrateBeds24Modal
          displayModal={displayIntegrateBeds24Modal}
          setDisplayModal={setDisplayIntegrateBeds24Modal}
          onSuccessIntegration={() =>
            setDisplaySelectBeds24PropertiesModal(true)
          }
        />
        <IntegrateSmoobuModal
          displayModal={displayIntegrateSmoobuModal}
          setDisplayModal={setDisplayIntegrateSmoobuModal}
          onSuccessIntegration={() =>
            setDisplaySelectSmoobuPropertiesModal(true)
          }
        />
        <IntegrateSuperHoteModal
          displayModal={displayIntegrateSuperHoteModal}
          setDisplayModal={setDisplayIntegrateSuperHoteModal}
          onSuccessIntegration={() =>
            setDisplaySelectSuperHotePropertiesModal(true)
          }
        />
        {displaySelectBeds24PropertiesModal && (
          <SelectBeds24PropertiesModal
            displayModal={displaySelectBeds24PropertiesModal}
            setDisplayModal={setDisplaySelectBeds24PropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
          />
        )}
        {displaySelectHospitablePropertiesModal && (
          <SelectHospitablePropertiesModal
            displayModal={displaySelectHospitablePropertiesModal}
            setDisplayModal={setDisplaySelectHospitablePropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
          />
        )}
        {displaySelectSmoobuPropertiesModal && (
          <SelectSmoobuPropertiesModal
            displayModal={displaySelectSmoobuPropertiesModal}
            setDisplayModal={setDisplaySelectSmoobuPropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
          />
        )}
        {displaySelectSuperHotePropertiesModal ? (
          <ImportSuperHotePropertiesModal
            displayModal={displaySelectSuperHotePropertiesModal}
            setDisplayModal={setDisplaySelectSuperHotePropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
          />
        ) : null}
        {displaySelectCalryPropertiesModal && (
          <SelectCalryPropertiesModal
            displayModal={displaySelectCalryPropertiesModal}
            setDisplayModal={setDisplaySelectCalryPropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
            calryIntegrationId={calryIntegrationId}
          />
        )}

        {displaySelectBookingSyncPropertiesModal && (
          <SelectBookingSyncPropertiesModal
            displayModal={displaySelectBookingSyncPropertiesModal}
            setDisplayModal={setDisplaySelectBookingSyncPropertiesModal}
            onSelectedProperties={handleSelectedProperties}
            hasToHadCheckoutArea={true}
            isLoadingCreation={isLoadingCreationMultiple}
          />
        )}
        <TabContentHeader
          title={t('menu.listings')}
          description={t('menu.listings_description')}
          rootPage="/homes"
          subRoute={subRoute}
          {...headerProps}
        />
        <TabContent>
          <Routes>
            <Route
              path=""
              element={
                <div>
                  <div className="flex justify-start mt-6 mb-2">
                    <Filters
                      filtersList={homeFilters}
                      active={statusHome}
                      setActive={setStatusHome}
                    />
                  </div>
                  <HomesList
                    setSubRoute={setSubRoute}
                    homes={homesData}
                    status={statusHome}
                    refreshHomes={refetchHomes}
                    isLoadingHomes={isLoadingHomes}
                    addHome={handleAddHome}
                    isGuestNotificationAvailable={hasAutomaticMessagesAvailable}
                    sortHomes={sortHomes}
                    setActivePage={setActivePage}
                  />
                </div>
              }
            />
            <Route
              path=":id"
              element={
                <HomeDetails
                  setSubRoute={setSubRoute}
                  refreshHomes={refetchHomes}
                />
              }
            />
            <Route
              path="new"
              element={
                <AddHome
                  setSubRoute={setSubRoute}
                  refreshHomes={refetchHomes}
                />
              }
            />
          </Routes>
        </TabContent>
      </>
    </TabWrapper>
  );
};

export default Homes;
