import { Middleware } from '@reduxjs/toolkit';
import { SocketServer } from '../../socket';

export const jobUpdateMiddleware: Middleware =
  (jobSlice) => (next) => (action) => {
    // Let the action be reduced first
    let result = next(action);

    // After reducing, check if there are updates from the backend
    // This could be based on certain action types or another criterion you define
    if (action.type === 'jobs/addJob') {
      const eventComplete = `${action.payload.type}:complete`;
      const eventError = `${action.payload.type}:error`;
      // Fetch updates from the backend or listen to an event
      SocketServer.on(eventComplete, (data: any) => {
        // Dispatch an action to update the Redux store based on the update received
        jobSlice.dispatch({
          type: 'jobs/removeJob',
          payload: `${action.payload.type}:${data.id}`,
        });

        action.payload.callback();
      });

      SocketServer.on(eventError, (data: any) => {
        // Dispatch an action to update the Redux store based on the update received
        jobSlice.dispatch({
          type: 'jobs/removeJob',
          payload: `${action.payload.type}:${data.id}`,
        });

        action.payload.callback();
      });
    }

    return result;
  };
