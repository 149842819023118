import React from 'react';
import { ChannelManagerItem } from '../../components/channel-manager-item/channel-manager-item.component';
import useChannelManagersWithHandlers from '../../hooks/useChannelManagersWithHandlers';
import useGetChannelManagersConnectedToWorkspace from '../../hooks/useGetChannelManagersConnectedToWorkspace';

type ChannelManagerListType = {
  calryRedirectUrl: string;
  hospitableRedirectUrl: string;
  fromOnboarding?: boolean;
  filterChannelManagers?: {};
  setOnboardingStep?: Function;
};
const ChannelManagerList: React.FC<ChannelManagerListType> = ({
  filterChannelManagers,
  calryRedirectUrl,
  hospitableRedirectUrl,
  fromOnboarding,
  setOnboardingStep,
}) => {
  // Hook to get all channel managers with their onClick handlers
  const {
    enhancedChannelManagers,
    Beds24ModalComponent,
    BookingSyncModalComponent,
    SmoobuModalComponent,
    SuperhoteModalComponent,
    Beds24TutorialModalComponent,
  } = useChannelManagersWithHandlers(
    calryRedirectUrl,
    hospitableRedirectUrl,
    fromOnboarding,
    filterChannelManagers,
    () => setOnboardingStep && setOnboardingStep(2),
  );

  const {
    channelManagersConnectedToWorkspace,
    isChannelManagerConnected,
    isLoadingChannelManagerConnectedToWorkspace,
  } = useGetChannelManagersConnectedToWorkspace();

  return (
    <>
      <Beds24ModalComponent />
      <BookingSyncModalComponent />
      <SmoobuModalComponent />
      <SuperhoteModalComponent />
      <Beds24TutorialModalComponent />
      {channelManagersConnectedToWorkspace &&
        enhancedChannelManagers
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((channelManager: any, index: number) => (
            <div key={index} className="w-1/2 md:w-1/4">
              <ChannelManagerItem
                ChannelManagerType={channelManager}
                onConnectClick={channelManager.onClick}
                isLoading={isLoadingChannelManagerConnectedToWorkspace}
                isConnected={isChannelManagerConnected(
                  channelManagersConnectedToWorkspace,
                  channelManager.type,
                )}
              />
            </div>
          ))}
    </>
  );
};

export default ChannelManagerList;
