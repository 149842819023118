import { api } from '../utils/request';

export const upload = async (authToken: string, file: any) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await api.post(`/files`, formData, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const remove = async (authToken: string, filename: any) => {
  try {
    const res = await api.delete(`/files/${filename}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
