import { SelectOption } from '../../types/generic-types';
import i18n from '../../i18n';
import { IOption } from '../../components/multiselect/multiselect.component';

export const PROPERTY_MANAGEMENT_SYSTEM: SelectOption[] = [
  { key: 'none', label: i18n.t('none') },
  { key: 'amenitiz', label: 'amenitiz' },
  { key: 'apaleo', label: 'apaleo' },
  { key: 'avaibook', label: 'avaibook' },
  { key: 'avantio', label: 'avantio' },
  { key: 'beds24', label: 'beds24' },
  { key: 'bookandpay', label: 'bookandpay' },
  { key: 'ciaobooking', label: 'ciaobooking' },
  { key: 'cirrus', label: 'cirrus' },
  { key: 'cloudbeds', label: 'cloudbeds' },
  { key: 'cubilis', label: 'cubilis' },
  { key: 'direct', label: 'direct' },
  { key: 'eviivo', label: 'eviivo' },
  { key: 'guesty', label: 'guesty' },
  { key: 'hospitable', label: 'hospitable' },
  { key: 'hostaway', label: 'hostaway' },
  { key: 'hostex', label: 'hostex' },
  { key: 'hostfully', label: 'hostfully' },
  { key: 'hostify', label: 'hostify' },
  { key: 'hostub', label: 'hostub' },
  { key: 'icnea', label: 'icnea' },
  { key: 'iGMS', label: 'iGMS' },
  { key: 'jurny', label: 'jurny' },
  { key: 'lodgify', label: 'lodgify' },
  { key: 'lodgix', label: 'lodgix' },
  { key: 'mytourist', label: 'mytourist' },
  { key: 'newbook', label: 'newbook' },
  { key: 'ownerez', label: 'ownerez' },
  { key: 'rental-ninja', label: 'rental ninja' },
  { key: 'smily', label: 'smily' },
  { key: 'smoobu', label: 'smoobu' },
  { key: 'streamline', label: 'streamline' },
  { key: 'superhote', label: 'superhote' },
  { key: 'tokeet', label: 'tokeet' },
  { key: 'travelnet', label: 'travelnet' },
  { key: 'uplisting', label: 'uplisting' },
  { key: 'your.rentals', label: 'your.rentals' },
  { key: 'zeevou', label: 'zeevou' },
  { key: 'other', label: i18n.t('other') },
];

export const PROPERTY_TYPES: IOption[] = [
  {
    value: 'premium_accommodation',
    label: i18n.t('register_form.category_option_premium_accommodation'),
  },
  {
    value: 'tourist_accommodation',
    label: i18n.t('register_form.category_option_tourist_accommodation'),
  },
  {
    value: 'love_room_romantic_getaway',
    label: i18n.t('register_form.category_option_love_room_romantic_getaway'),
  },
  {
    value: 'spa',
    label: i18n.t('register_form.category_option_spa'),
  },
  {
    value: 'professional_rental',
    label: i18n.t('register_form.category_option_professional_rental'),
  },
  {
    value: 'family_friendly_rental',
    label: i18n.t('register_form.category_option_family_friendly_rental'),
  },
  {
    value: 'moutain_chalet',
    label: i18n.t('register_form.category_option_moutain_chalet'),
  },
  {
    value: 'other',
    label: i18n.t('other'),
  },
];

export const USER_COMPANY_ROLES: SelectOption[] = [
  { key: 'owner', label: i18n.t('register_form.role_option_owner') },
  { key: 'director', label: i18n.t('register_form.role_option_director') },
  { key: 'manager', label: i18n.t('register_form.role_option_manager') },
  { key: 'agent', label: i18n.t('register_form.role_option_agent') },
  { key: 'other', label: i18n.t('other') },
];

export const COMPANY_NB_OF_PROPERTIES: SelectOption[] = [
  { key: '1-5', label: '1-5' },
  { key: '6-20', label: '6-20' },
  { key: '21-50', label: '21-50' },
  { key: '51-100', label: '51-100' },
  { key: '101+', label: '101+' },
];

export const COMPANY_NB_OF_EMPLOYEES: SelectOption[] = [
  { key: 'none', label: i18n.t('none') },
  { key: '0-5', label: '1-5' },
  { key: '6-20', label: '6-20' },
  { key: '21-50', label: '21-50' },
  { key: '51-100', label: '51-100' },
  { key: '101+', label: '101+' },
];
