import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state

export interface GlobalState {
  onboardingStatus: any;
  currentRole: string | null;
  hasAirbnbConnection: boolean;
}

// Define the initial state using that type
const initialState: GlobalState = {
  onboardingStatus: {
    data: {},
    meta: {},
  },
  hasAirbnbConnection: false,
  currentRole: null,
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOnboardingStatus: (state, action: PayloadAction<any>) => {
      state.onboardingStatus = action.payload;
    },
    setCurrentRole: (state, action: PayloadAction<any>) => {
      state.currentRole = action.payload;
    },
    setHasAirbnbConnection: (state, action: PayloadAction<any>) => {
      state.hasAirbnbConnection = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default globalSlice;
