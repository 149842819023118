import React from 'react';
import { Path, useNavigate } from 'react-router-dom';
import SearchBar from '../search-bar/search-bar.component';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description?: string;
  rootPage?: string | Partial<Path>;
  subRoute?: string;
  actionTitle?: string;
  callback?: any;
  displaySearchBar?: boolean;
  displaySuggestion?: boolean;
  searchText?: string;
  setSearchText?: any;
  onKeyDown?: any;
  className?: string;
}

const TabContentHeader = ({
  title,
  description,
  rootPage,
  subRoute,
  actionTitle,
  callback,
  displaySearchBar,
  displaySuggestion,
  searchText,
  setSearchText,
  onKeyDown,
  className,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={`${
        className ||
        'justify-between lg:flex lg:border-b-2 border-gray-50 lg:pt-2 lg:pb lg:mb-2 lg:h-20 items-center'
      }`}
    >
      <div className="">
        <ul className="flex text-2xl lg:text-[36px] tracking-wide font-sans text-left lg:text-left leading-[50px] font-semibold">
          <li className={'lg:text-black whitespace-nowrap cursor-pointer'}>
            <a onClick={() => rootPage && navigate(rootPage)}>{title}</a>
          </li>
          {!subRoute ? null : (
            <div className="inline-flex w-[180px] md:w-96">
              <span className="mx-1"> &gt; </span>
              <li className="text-primary text-left truncate hover:text-clip hover:overflow-visible">
                {subRoute}
              </li>
            </div>
          )}
        </ul>
        <p className="mb-2 text-grey text-left text-sm">{description}</p>
      </div>
      <div className="flex items-end justify-end mt-6 w-full lg:w-auto lg:mt-0 ">
        {!displaySearchBar ? null : (
          <SearchBar
            searchText={searchText || ''}
            setSearchText={setSearchText}
            onKeyDown={onKeyDown}
          />
        )}
        {!displaySuggestion ? null : (
          <a
            className="btn btn-warning mb-0 mt-0"
            href="https://cozyup.com/blog/a5b556c9-aaa3-4ce9-bbcd-0c7ce1b84e08"
            target="_blank"
            rel="noreferrer"
          >
            {t('suggestions')}
          </a>
        )}
        {!actionTitle ? null : (
          <button
            className="btn btn-primary mb-0 mt-0"
            style={{ marginLeft: 8 }}
            onClick={callback}
          >
            {actionTitle}
          </button>
        )}
      </div>
    </div>
  );
};

TabContentHeader.defaultProps = {
  subRoute: '',
};

export default TabContentHeader;
