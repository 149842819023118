import React from 'react';
import { DayContent, DayContentProps, DayPicker } from 'react-day-picker';
import { addDays, format, isAfter, isSameDay, subDays } from 'date-fns';
import {
  isAvailableDate,
  parseAvailabilityDates,
} from '../../../pages/booking/booking.utils';
import { classNames } from '../../../utils/helpers';
import { Icon, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { EXAMPLE_RESERVATION_MOCK } from './night-upsells-example.constantes';
import { useAuth } from '../../../contexts/authContext';
import { getCurrencyInfo } from '../../../utils/currency';
import { isWithinRange } from '../../../utils/date';
import CURowText from '../../_main/CURowtText/cu-row-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { dateFnslocales } from '../../../utils/language';
import i18n from '../../../i18n';

const css = `
  .cu-disabled {
    color: #FFF;
    opacity: 1 !important;
    background-color: orange;
  }
  .cu-selected:not([disabled]) { 
    border: none;
    color: white;
    background-color: #ff3366;
  }
  .cu-selected:hover:not([disabled]) { 
    border: none;
    background-color: #ff3366;
  }
  .cu-today { 
    font-weight: bold;
    color: #000
  }
  .rdp-tfoot {
    font-size: 12px;
    color: #ff3366;
    margin-top: 0.5rem;
    font-style : italic;
  }
`;

type NightUpsellsCalendarExampleProps = {
  gapSizeBetweenReservation: any;
  dayBeforeNotification: number;
  nightPercentageDiscount: number;
  percentageFeeHost: number;
};
const NightUpsellsCalendarExample: React.FC<
  NightUpsellsCalendarExampleProps
> = ({
  gapSizeBetweenReservation,
  dayBeforeNotification,
  nightPercentageDiscount,
  percentageFeeHost,
}) => {
  const { t } = useTranslation();
  const { currentWorkspace } = useAuth();
  const currency = getCurrencyInfo(currentWorkspace?.currency).symbol;
  const [footerMessage, setFooterMessage] = React.useState('');
  const secondStayDay = addDays(
    EXAMPLE_RESERVATION_MOCK.departureDate,
    parseInt(gapSizeBetweenReservation) + 1,
  );
  const nightPriceWithDiscount =
    100 * (1 - nightPercentageDiscount / 100) + currency;

  // Inside NightUpsellsCalendarExample
  const CustomDayComponent = (props: DayContentProps) => {
    const { date } = props;
    const dateTime = format(date, 'yyyy-MM-dd');
    const currentDateAvailability =
      EXAMPLE_RESERVATION_MOCK.availabilities.find((a: any) => {
        // Add 1 day to the availability date to match picked date in calendar
        const adjustedDate = addDays(new Date(a.date), 1);
        return isSameDay(date, adjustedDate);
      });

    const isDateAvailable =
      isAvailableDate(date, EXAMPLE_RESERVATION_MOCK.availabilities) &&
      !isAfter(date, secondStayDay);
    const isSecondStayDay = isSameDay(date, secondStayDay);
    const isPaidReservationDate = isWithinRange(
      date,
      EXAMPLE_RESERVATION_MOCK.arrivalDate,
      EXAMPLE_RESERVATION_MOCK.departureDate,
    );
    const isNotificationDay = isSameDay(
      date,
      subDays(EXAMPLE_RESERVATION_MOCK.departureDate, dayBeforeNotification),
    );
    const isCozyUpAnniversary = isSameDay(date, new Date(2024, 2, 31));

    return (
      <time dateTime={dateTime}>
        <DayContent {...props} />
        {!isDateAvailable || isPaidReservationDate || isSecondStayDay ? null : (
          <div className="flex justify-center items-center">
            <span
              className={classNames(
                props?.activeModifiers?.selected ? 'text-white' : 'text-grey',
                'text-[8px]',
              )}
            >
              {currentDateAvailability &&
                (
                  currentDateAvailability?.basePriceCents *
                  (1 - nightPercentageDiscount / 100)
                ).toFixed(2) + currency}
            </span>
          </div>
        )}
        {(isPaidReservationDate && !isNotificationDay) || isSecondStayDay ? (
          <div className="text-[12px]">🛌</div>
        ) : null}
        {isNotificationDay && <div className="text-[12px]">📱🔔</div>}
        {isCozyUpAnniversary && <div className="text-[12px]">🎂</div>}
      </time>
    );
  };

  const handleDayClick = (date: Date) => {
    const availabilities = parseAvailabilityDates(
      EXAMPLE_RESERVATION_MOCK.availabilities,
    );
    // TODO: Also check it's beore the second stay day
    const isDateAvailable = isAvailableDate(date, availabilities);
    const isCozyUpAnniversary = isSameDay(date, new Date(2024, 2, 31));
    const isPaidReservationDate = isWithinRange(
      date,
      EXAMPLE_RESERVATION_MOCK.arrivalDate,
      EXAMPLE_RESERVATION_MOCK.departureDate,
    );

    const isNotificationDay = isSameDay(
      date,
      subDays(EXAMPLE_RESERVATION_MOCK.departureDate, dayBeforeNotification),
    );
    if (isDateAvailable) {
      setFooterMessage(
        t('night_upsells.calendar.discount_night_footer', {
          percentage: nightPercentageDiscount,
          price: nightPriceWithDiscount,
          currency: currency,
        }),
      );
    } else if (isNotificationDay) {
      setFooterMessage(t('night_upsells.calendar.notification_day'));
    } else if (isPaidReservationDate) {
      setFooterMessage(t('night_upsells.calendar.booked_day'));
    } else if (isCozyUpAnniversary) {
      setFooterMessage(t('night_upsells.calendar.cozyup_anniversary'));
    } else {
      setFooterMessage('');
    }
  };

  return (
    <>
      <style>{css}</style>
      <div className="w-full max-w-[280px] flex flex-col justify-center items-center text-xl font-satoshiBold">
        <div className="flex items-center">
          {t('example')}
          <Tooltip
            title={t('night_upsells.example_tooltip')}
            className="ml-1"
            enterTouchDelay={0}
          >
            <Icon
              className="icon text-gray-300 cursor-pointer opacity-75"
              component={HelpOutline}
            />
          </Tooltip>
        </div>
        <div className="flex flex-col w-full">
          <div>
            <CURowText
              labelClass="text-xs text-black font-inter"
              label={t('night_upsells.night_base_price')}
              valueClass="line-through text-xs font-inter"
              value={100 + currency}
            />
            <CURowText
              labelClass="text-xs text-black font-inter"
              label={t('night_upsells.night_price_with_discount')}
              valueClass="text-xs font-inter"
              value={
                (100 * (1 - nightPercentageDiscount / 100)).toFixed(2) +
                currency
              }
            />
          </div>
          <div className="mt-3">
            <CURowText
              labelClass="text-xs text-grey font-inter"
              label={
                t('night_upsells.cozyup_fee_host') +
                ' (' +
                percentageFeeHost +
                '%)'
              }
              valueClass="text-xs text-grey font-inter"
              value={`-${+(
                100 *
                (1 - nightPercentageDiscount / 100) *
                (percentageFeeHost / 100)
              ).toFixed(2)}${currency}`}
            />

            <CURowText
              labelClass="text-xs text-grey font-inter whitespace-nowrap"
              label={t('night_upsells.cozyup_fee_guest') + ' (0%)'}
              valueClass="text-xs text-grey font-inter"
              value={'-' + 0 + currency}
            />
          </div>
          <div className="mt-3">
            <CURowText
              labelClass="text-xs text-green font-inter"
              label={t('night_upsells.you_receive')}
              valueClass="text-xs font-inter text-green"
              value={
                (
                  100 * (1 - nightPercentageDiscount / 100) -
                  100 *
                    (1 - nightPercentageDiscount / 100) *
                    (percentageFeeHost / 100)
                ).toFixed(2) + currency
              }
            />
          </div>
        </div>
        <div className="flex items-start justify-center gap-2 mt-4">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              icon={faCircle}
              style={{ fontSize: 12 }}
              color="#ff3366"
            />
            <p className="text-sm ml-1">{t('night_upsells.stay', { id: 1 })}</p>
          </div>
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              icon={faCircle}
              style={{ fontSize: 12 }}
              color="orange"
            />
            <p className="text-sm ml-1">{t('night_upsells.stay', { id: 2 })}</p>
          </div>
        </div>
      </div>
      <DayPicker
        mode="range"
        selected={{
          from: EXAMPLE_RESERVATION_MOCK.arrivalDate,
          to: EXAMPLE_RESERVATION_MOCK.departureDate,
        }}
        components={{
          DayContent: CustomDayComponent,
        }}
        showOutsideDays
        fixedWeeks
        locale={dateFnslocales[i18n.language]}
        onDayClick={handleDayClick}
        disabled={secondStayDay}
        month={new Date(2024, 2, 1)}
        modifiersStyles={{
          today: { color: '#ff3366' },
        }}
        modifiersClassNames={{
          disabled: 'cu-disabled',
          selected: 'cu-selected',
          today: 'cu-today',
        }}
        footer={footerMessage}
      />
    </>
  );
};

export default NightUpsellsCalendarExample;
