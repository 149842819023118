import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../contexts/authContext';
import SubmitButton from '../../../submit-button/submit-button.component';
import { Controller, useForm } from 'react-hook-form';
import { getBackendErrorsByAttribute } from '../../../../utils/helpers';
import Validation from '../../../../utils/validation';
import Input from '../../../input/input.component';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { Workspace } from '../../../../types/workspace.model';
import Select from '../../../select/select.component';
import { getAccount } from '../../../../api/accounts';
import ErrorMessage from '../../../error-message/error-message.component';
import MultiSelect from '../../../multiselect/multiselect.component';
import { useNavigate } from 'react-router-dom';
import { createWorkspace } from '../../../../api/workspaces';
import { CURRENCIES } from '../../../../utils/currency';

export const AddWorkspace = ({ setSubRoute, refreshWorkspaces }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentUser, currentAccount, refreshAccounts } = useAuth();
  const [guestsOptions, setGuestsOptions] = useState<any[]>([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    setSubRoute(`${t('header.workspaces')}`);
  }, []);

  const backendErrors = '';
  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const { mutate: createWorkspaceByAccount, isLoading } = useMutation(
    (data: Workspace) =>
      createWorkspace(currentUser.accessToken, currentAccount._id, data),
    {
      onSuccess: () => {
        refreshWorkspaces();
        refreshAccounts();
        navigate('/settings/workspaces');
      },
    },
  );

  const submit = (data: any) => {
    const workspace = data;
    workspace.guestUsers = data.guestUsers?.map((u: any) => u.key);
    createWorkspaceByAccount({
      ...workspace,
    });
    reset();
  };

  const handleGuestsOptions = (guests: any[]) => {
    const guestsOptions = guests.map((ca: any) => {
      return { label: ca.email, key: ca._id };
    });
    setGuestsOptions(guestsOptions);
  };

  useQuery(
    ['account', currentAccount._id],
    // @ts-ignore
    () => getAccount(currentUser.accessToken, currentAccount._id),
    {
      enabled: !!currentAccount._id,
      onSuccess: (data: any) => {
        handleGuestsOptions(data.guests);
      },
    },
  );

  return isLoading ? null : (
    <div className="card my-4 pt-6 md:w-2/3 ">
      <div className="flex justify-between items-center mb-6">
        <h5 style={{ textAlign: 'left', margin: 0 }} className="text-xl">
          {t('menu.add_workspace')}
        </h5>
      </div>
      <div className="mt-6 w-full lg:flex">
        <div className="mt-6 lg:mt-0  w-full">
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <Controller
                control={control}
                rules={Validation.anyRequired}
                defaultValue={''}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    key="title"
                    label={t('settings.name')}
                    size="small"
                    onChange={onChange}
                    value={value}
                    errors={[
                      _.get(errors, 'title.message'),
                      ...backendErrorsForAttribute('title'),
                    ]}
                  />
                )}
                name={'title'}
              />
            </div>

            <div className="lg:flex justify-between">
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  rules={Validation.anyRequired}
                  render={({ field: { onChange, value } }: any) => (
                    <Select
                      key="currency"
                      label={`${t('currency')}`}
                      selectLabel={`${t('select_currency')}`}
                      tooltip={`${t('currency_tooltip')}`}
                      onChange={onChange}
                      value={value}
                      options={CURRENCIES}
                      errors={[_.get(errors, 'currency.message')]}
                    />
                  )}
                  name={'currency'}
                />
              </div>
              <div className="lg:pr-4 flex-1">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }: any) => (
                    <div>
                      <MultiSelect
                        label={t('settings.users')}
                        options={guestsOptions}
                        onChange={onChange}
                        value={value}
                        primaryColor=""
                        disabled={false}
                      />
                      {_.get(errors, 'guestUsers.message') && (
                        <ErrorMessage
                          key={_.get(errors, 'guestUsers.message')}
                          error={_.get(errors, 'guestUsers.message')}
                        />
                      )}
                    </div>
                  )}
                  name={'guestUsers'}
                />
              </div>
            </div>

            <div className="flex justify-end my-4">
              <SubmitButton
                title="Confirm"
                customClass={'btn rounded-3xl btn-primary'}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
