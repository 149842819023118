export const checkIfChannelManagerIntegrationWithAutoMessagesAvailable = (
  channelManagerIntegrations: unknown[],
): boolean =>
  channelManagerIntegrations?.some(
    (integration: any) =>
      integration.isActive &&
      integration.channelManagerType.isAutomaticMessagesActive,
  );

export const checkIfChannelManagerIntegrationWithNightUpsellsAvailable = (
  channelManagerIntegrations: unknown[],
): boolean =>
  channelManagerIntegrations?.some(
    (integration: any) =>
      integration.isActive &&
      integration.channelManagerType.isAdditionnalNightsActive,
  );
