import React, { useState } from 'react';
import OnClickOut from '../../hooks/useOnClickOut';
import { useTranslation } from 'react-i18next';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import Input from '../../components/input/input.component';
import MultiSelect from '../multiselect/multiselect.component';
import { useAuth } from '../../contexts/authContext';

import { useMutation } from 'react-query';
import { createAccountInvitations } from '../../api/accounts';
import SubmitButton from '../submit-button/submit-button.component';
import Validation from '../../utils/validation';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import ErrorMessage from '../error-message/error-message.component';
import { RolesList } from '../../constantes/user-roles';
import Select from '../select/select.component';

type AddUserModalProps = {
  displayModal: boolean;
  setDisplayModal: Function;
  refreshInvitations?: Function;
};

const AddUserModal = ({
  displayModal,
  setDisplayModal,
  refreshInvitations,
}: AddUserModalProps) => {
  const { t } = useTranslation();
  const { currentAccount, currentUser } = useAuth();
  const workspacesOptions = currentAccount?.workspaces?.map((w: any) => ({
    value: w._id,
    label: w.title,
  }));

  const [currentRole, setCurrentRole] = useState('');
  const currentRoleDescription = RolesList.find(
    (role: any) => role.key === currentRole,
  )?.desc;

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetForm,
  } = useForm();
  const backendErrors = '';

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  const {
    mutate: createInvitations,
    isLoading,
    isSuccess,
    isError,
    error,
    reset: resetHook,
  } = useMutation(
    (data: any) =>
      createAccountInvitations(
        currentUser.accessToken,
        currentAccount._id,
        data,
      ),
    {
      onSuccess: () => {
        if (refreshInvitations) {
          refreshInvitations();
        }
        setTimeout(() => {
          resetForm();
          resetHook();
          setDisplayModal(false);
        }, 1000);
      },
    },
  );

  const submit = (data: any) => {
    const invitation = data;
    invitation.accountWorkspaces = data.accountWorkspaces.map(
      (w: any) => w.value,
    );
    invitation.role = data.role;
    createInvitations(invitation);
  };

  return !displayModal ? null : (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#0000007F]">
      <form
        onSubmit={handleSubmit(submit)}
        className="fixed top-0 left-0 z-50 w-full h-full overflow-y-auto overflow-x-hidden flex items-start"
      >
        <OnClickOut
          callback={() => setDisplayModal(false)}
          classname="relative flex flex-col justify-between w-full max-w-[900px] min-h-[350px] bg-white mt-16 mx-auto rounded-[16px]"
        >
          <button
            type="button"
            className="modal-close-button"
            onClick={() => setDisplayModal(false)}
          >
            <span>&times;</span>
          </button>
          <div className="max-h-[600px] md:max-h-[300px]">
            <div className="border-b px-4 py-2">
              <h3 className="text-left text-[22px]">
                {t('invitation.invit_title')}
              </h3>
              <p className="text-left text-[14px] font-satoshiRegular">
                {t('invitation.invit_description')}
              </p>
            </div>
            <div className="px-10 justify-center items-center">
              <div className="md:flex w-full gap-4 my-6">
                <div className="w-full md:w-2/6">
                  <div className="flex flex-col justify-center">
                    <Controller
                      control={control}
                      rules={{ ...Validation.email, ...Validation.anyRequired }}
                      defaultValue=""
                      render={({ field: { onBlur, onChange, value } }: any) => (
                        <Input
                          type="email"
                          key="email"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          label={`${capitalizeFirstLetter(t('email'))} *`}
                          errors={[
                            _.get(errors, 'to.message'),
                            ...backendErrorsForAttribute('email'),
                          ]}
                        />
                      )}
                      name={'to'}
                    />
                  </div>
                </div>
                <div className="w-full md:w-2/6">
                  <div className="flex flex-col justify-start">
                    {/* <div className="text-left text-[#6B7194]">{t('role')}</div> */}
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue={RolesList[0]}
                      render={({ field: { onChange, value } }: any) => (
                        <Select
                          key="role"
                          label={'Role'}
                          onChange={(e: any) => {
                            onChange(e);
                            setCurrentRole(e.target.value);
                          }}
                          value={value}
                          options={RolesList}
                          errors={[_.get(errors, 'role.message')]}
                        />
                        // <div>
                        //   <select
                        //     value={value}
                        //     onChange={onChange}
                        //     className="w-full text-gray-700 border hover:border-primary focus:border-primary focus-visible:border-primary outline-none px-3 bg-white border-gray-400 rounded-3xl mb-0 h-10 flex"
                        //   >
                        //     {RolesList.map((f: any, index: number) => (
                        //       <option key={index} value={f.value}>
                        //         {t(`role_list.${f.value.toLowerCase()}`)}
                        //       </option>
                        //     ))}
                        //   </select>
                        //   {_.get(errors, 'role.message') && (
                        //     <ErrorMessage
                        //       key={_.get(errors, 'role.message')}
                        //       error={_.get(errors, 'role.message')}
                        //     />
                        //   )}
                        // </div>
                      )}
                      name={'role'}
                    />
                  </div>
                </div>

                <div className="w-full mt-6 md:mt-0 md:w-3/6">
                  <div className="flex flex-col justify-centerr">
                    <div className="text-left text-[#6B7194]">
                      {t('settings.workspace.workspace_other')}
                    </div>
                    <Controller
                      control={control}
                      rules={{ ...Validation.anyRequired }}
                      defaultValue={[]}
                      render={({ field: { onChange, value } }: any) => (
                        <div>
                          <MultiSelect
                            options={workspacesOptions}
                            onChange={onChange}
                            value={value}
                            primaryColor=""
                          />
                          {_.get(errors, 'accountWorkspaces.message') && (
                            <ErrorMessage
                              key={_.get(errors, 'accountWorkspaces.message')}
                              error={_.get(errors, 'accountWorkspaces.message')}
                            />
                          )}
                        </div>
                      )}
                      name="accountWorkspaces"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t px-4 py-4 gap-2 md:flex justify-between">
            <p className="text-grey text-xs text-left">
              {currentRoleDescription}
            </p>
            {isError && (
              <span className="px-4 py-2 flex items-center border-red-500 text-red-500 rounded-3xl">
                {t(error.message)}
              </span>
            )}
            <div className="flex justify-end">
              {isSuccess ? (
                <span className="px-4 py-2 border border-green text-green bg-lightGreen rounded-3xl">
                  {t('invitation.invit_sent')}
                </span>
              ) : (
                <div className="gap-2 flex justify-end">
                  <button
                    title={t('cancel')}
                    className="btn btn-primary-outlined rounded-3xl"
                    onClick={() => setDisplayModal(false)}
                  >
                    {t('cancel')}
                  </button>
                  <SubmitButton
                    title={t('invitation.send_invit')}
                    disabled={isLoading}
                    customClass="btn btn-primary rounded-3xl"
                  />
                </div>
              )}
            </div>
          </div>
        </OnClickOut>
      </form>
    </div>
  );
};

export default AddUserModal;
