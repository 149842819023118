import { faPlus, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingProductTemplates } from '../../data/product-templates.data';
import { Emoji } from 'emoji-mart';
import { displayPrice } from '../../utils/currency';
import { useAuth } from '../../contexts/authContext';
import OnClickOut from '../../hooks/useOnClickOut';

type SelectProductsModalType = {
  displayProductTemplates: boolean;
  setDisplayProductTemplates: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect: Function;
};

const SelectProductsModal = ({
  displayProductTemplates,
  setDisplayProductTemplates,
  onSelect,
}: SelectProductsModalType) => {
  const { t } = useTranslation();
  const { currentWorkspace } = useAuth();

  return (
    <div>
      {displayProductTemplates && (
        <div className="modal-overlay bg-opacity-25">
          <div className="modal-wrapper">
            <OnClickOut
              classname="flex flex-col items-center w-[90%] lg:w-[80%] h-[80%] z-[100px] bg-white relative m-auto rounded-[16px] py-5 px-6 lg:px-10"
              callback={() => setDisplayProductTemplates(false)}
            >
              <button
                type="button"
                className="modal-close-button"
                onClick={() => setDisplayProductTemplates(false)}
              >
                <span className="text-[32px]">&times;</span>
              </button>
              <div className="flex flex-col modal-header">
                <h5 className="leading-10 md:leading-none text-[36px] mb-4">
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="text-primary mr-[8px]"
                    size="lg"
                  />
                  {t('templates.add_best_sellers')} 🎉
                </h5>
                <h3>{t('templates.add_best_sellers_description')}</h3>
              </div>

              <div className="px-[8px] grid grid-cols-1 lg:grid-cols-2 gap-4 items-start w-[100%] h-[100%] mt-5 pt-2 mb-2 overflow-auto">
                <div
                  onClick={() => {
                    onSelect(null);
                    setDisplayProductTemplates(false);
                  }}
                  className=" m-[8px] group flex flex-col w-full justify-center items-center cursor-pointer shadow rounded-[32px] font-satoshi min-h-[150px] mb-[12px] hover:border-primary border-[2px] border-transparent"
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="3x"
                    color="#d3d3d3"
                    className="group-hover:text-primary"
                  />
                  <p className="mt-3 text-middleGrey font-medium group-hover:text-primary text-[16px]">
                    {t('new')}
                  </p>
                </div>
                {OnboardingProductTemplates.filter((p, index) => index > 0).map(
                  (productTemplate: any, index: number) => (
                    <div key={index.toString()}>
                      <ProductTemplate
                        index={index + 1}
                        product={productTemplate}
                        currency={currentWorkspace.currency}
                        onSelect={onSelect}
                      />
                    </div>
                  ),
                )}
              </div>
            </OnClickOut>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectProductsModal;

type ProductType = {
  product: any;
  index: number;
  currency: { symbol: string };
  onSelect: Function;
};

const ProductTemplate = ({
  product,
  index,
  currency,
  onSelect,
}: ProductType) => {
  const { t } = useTranslation();

  const renderRanking = (index: number) => {
    switch (index) {
      case 1:
        return <Emoji emoji="first_place_medal" size={24} />;
      case 2:
        return <Emoji emoji="second_place_medal" size={24} />;
      case 3:
        return <Emoji emoji="third_place_medal" size={24} />;
      default:
        return <p>#{index}</p>;
    }
  };

  return (
    <>
      <div
        className="flex w-full justify-center items-center  cursor-pointer shadow rounded-[32px] font-satoshi min-h-[150px] mb-[12px] hover:border-primary border-[2px] border-transparent"
        onClick={() => onSelect(product)}
      >
        <div className="relative pl-2 pt-2 self-start">
          {renderRanking(index)}
        </div>
        <div className="emoji-container ml-3 sm:ml-0">
          {!product.emoji ? null : <Emoji emoji={product.emoji} size={52} />}
        </div>
        <div className="flex flex-col mx-[8px] w-full">
          <div className="flex justify-end mt-[8px]">
            <span className="capitalize w-fit mb-2 text-right py-0.5 px-3 text-xs font-semibold rounded-2xl bg-[#fffa0080] text-[#e7cf00]">
              {t('products.template')}
            </span>
          </div>
          <h3 className="text-[16px] w-full leading-[18px] font-mono text-[#333] text-left">
            {product.name}
          </h3>
          <p className="extra-description text-textDesc text-left my-[12px] line-clamp">
            {product.description}
          </p>
          <div className="w-full flex justify-start">
            <div className="flex w-1/2 price">
              {displayPrice(product.costPrice, currency.symbol, {
                thousandSeparator: ' ',
              })}
            </div>
            <div className="flex w-1/2 price">
              {displayPrice(product.sellingPrice, currency.symbol, {
                thousandSeparator: ' ',
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
