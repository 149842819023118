export const handleFirebaseLoginError = (errCode: string) => {
  switch (errCode) {
    case 'auth/user-not-found':
    case 'auth/email-already-in-use':
    case 'auth/email-already-exists':
      return 'firebase_error.check_email';
    case 'auth/wrong-password':
    case 'auth/invalid-email':
      return 'firebase_error.wrong_credentials';
    case 'auth/weak-password':
      return 'firebase_error.weak_password';
    default:
      return 'firebase_error.generic_error';
  }
};
