import React, { useState } from 'react';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import '../extra-checkout-form/extra-checkout-form.styles.scss';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/input/input.component';
import Button from '../../components/button/button.component';
import Validation from '../../utils/validation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Order } from '../../types/order.model';
import { useMutation } from 'react-query';
import CheckoutAlert from '../checkout-alert/checkout-alert.component';

import {
  capitalizeFirstLetter,
  getBackendErrorsByAttribute,
} from '../../utils/helpers';
import { confirmReservation, createReservation } from '../../api/reservation';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';

type NightUpsellCheckoutFormProps = {
  paymentIntentId: string;
  homeId: string;
  workspace: any;
  availabilities: any[];
  reservationId: string;
  checkoutMetadata: any;
};

const NightUpsellCheckoutForm = ({
  paymentIntentId,
  homeId,
  workspace,
  availabilities,
  reservationId,
  checkoutMetadata,
}: NightUpsellCheckoutFormProps) => {
  let navigate = useNavigate();
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const [showAlert, setShowAlert] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [reload, setReload] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const backendErrors = '';
  const { t } = useTranslation();
  const workspaceId = workspace._id;
  const [paymentErrors, setPaymentErrors] = useState<string>('');
  const [paymentIsProcessing, setPaymentIsProcessing] =
    useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { mutate: createNewReservation } = useMutation(
    (data: Order) => workspaceId && createReservation(data),
    {
      onSuccess: (response: any) => {
        if (response) {
          confirmStripePayment(response);
        } else {
          setPaymentIsProcessing(false);
          setReload(false);
          setAlertDescription(
            'Une erreur est survenue, merci de réessayer plus tard.',
          );
          setShowAlert(true);
        }
      },
      onError: (e: any) => {
        console.error(e);
        setReload(false);
        setAlertDescription(
          'Une erreur est survenue, merci de réessayer plus tard.',
        );
        setShowAlert(true);
      },
    },
  );

  const { mutate: confirmCurrentReservation } = useMutation(
    (data: any) => confirmReservation(data),
    {
      onSuccess: (response: any) => {
        trackMixpanelEvent('Booking - Payment success ', checkoutMetadata);
        navigate(`/booking-success?_order=${response?.order?._id}`);
      },
      onError: (e: any) => {
        console.error(e);
        setReload(false);
        setAlertDescription(
          'Une erreur est survenue, merci de réessayer plus tard.',
        );
        setShowAlert(true);
      },
    },
  );

  const confirmStripePayment = async (data: any) => {
    try {
      const payment = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: 'if_required',
        confirmParams: {
          receipt_email: data.customerEmail,
          payment_method_data: {
            billing_details: {
              email: data.customerEmail,
              name: data.customerName,
            },
          },
        },
      });

      if (payment.error) {
        // Show error to your customer (for example, payment details incomplete)
        setPaymentIsProcessing(false);
        setPaymentErrors(payment.error.message);
        return;
      } else {
        const paymentIntentId = payment.paymentIntent.id;
        const payload = {
          orderId: data._id,
          paymentIntentId: paymentIntentId,
        };
        confirmCurrentReservation(payload);
      }
    } catch (e) {
      console.error(e);
      setPaymentIsProcessing(false);
    }
  };

  const submit = async (data: any) => {
    setPaymentIsProcessing(true);
    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      // Stripe.js has not yet loaded.
      return;
    }

    const payload = {
      availabilities: [...availabilities],
      customerEmail: data.email,
      paymentIntentId: paymentIntentId,
      purchaseDate: Date.now(),
      homeId: homeId,
      reservationId: reservationId,
      customer: data.name,
      workspaceId: workspaceId,
    };

    await createNewReservation(payload);
  };

  const backendErrorsForAttribute = (attribute: string) => {
    return getBackendErrorsByAttribute(backendErrors, attribute);
  };

  return (
    <>
      <CheckoutAlert
        title={'Oups'}
        description={alertDescription}
        isVisible={showAlert}
        setIsVisible={setShowAlert}
        reloadOnClose={reload}
      />
      <div className="px-6">
        <form onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            rules={{ ...Validation.anyRequired }}
            defaultValue={''}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                type="text"
                key="name"
                size="small"
                customClassName={
                  'w-full text-gray-700 border hover:border-checkout focus:border-checkout focus-visible:border-checkout outline-none py-4 px-3 bg-white border-gray-400 rounded-lg mb-0 h-10 flex'
                }
                color={'checkout'}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('checkout.name')}
                value={value}
                label={`${capitalizeFirstLetter(t('full_name'))} `}
                errors={[
                  _.get(errors, 'name.message'),
                  ...backendErrorsForAttribute('name'),
                ]}
              />
            )}
            name={'name'}
          />
          <Controller
            control={control}
            rules={{ ...Validation.email, ...Validation.anyRequired }}
            defaultValue={''}
            render={({ field: { onBlur, onChange, value } }: any) => (
              <Input
                type="email"
                key="email"
                size="small"
                color={'checkout'}
                onBlur={onBlur}
                customClassName={
                  'w-full text-gray-700 border hover:border-checkout focus:border-checkout focus-visible:border-checkout outline-none py-4 px-3 bg-white border-gray-400 rounded-lg mb-0 h-10 flex'
                }
                onChange={onChange}
                placeholder="example@mail.com"
                value={value}
                label={`${capitalizeFirstLetter(t('email'))} `}
                errors={[
                  _.get(errors, 'email.message'),
                  ...backendErrorsForAttribute('email'),
                ]}
              />
            )}
            name={'email'}
          />
          <hr className="divider" style={{ marginLeft: 24, marginRight: 24 }} />

          <PaymentElement />

          <div>
            {!paymentErrors ? null : (
              <div className="payment-error">
                <p>{paymentErrors}</p>
              </div>
            )}
          </div>
          <Button
            className="checkout-submit-button"
            loading={paymentIsProcessing}
            disabled={!stripe || paymentIsProcessing}
          >
            {t('checkout.pay_now')}
          </Button>
        </form>
      </div>
    </>
  );
};

export default NightUpsellCheckoutForm;
