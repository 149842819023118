import React, { useEffect, useRef, useState } from 'react';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import GraphComponent from '../../components/graph/graph.component';
import TabContent from '../../components/tab-content/tab-content.component';
import Filters from '../../components/filters/filters.component';
import { useQuery } from 'react-query';
import {
  getFunnelConversion,
  getOrdersStatsByWorkspace,
} from '../../api/stats';
import { useAuth } from '../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import { displayPrice, getCurrencyInfo } from '../../utils/currency';
import FunnelGraph from 'funnel-graph-js';
import 'funnel-graph-js/dist/css/main.css';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import BestSellers from '../../components/_analytics/best-sellers/best-sellers.component';
import Loader from '../../components/loader/loader.component';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons';
import MyCustomers from '../../components/_analytics/my-customers/my-customers.component';

const Analytics = () => {
  const { t } = useTranslation();
  const { currentUser, currentWorkspace } = useAuth();
  const [rangeDate, setRangeDate] = useState('7_day');
  const [rangeDateFunnel, setRangeDateFunnel] = useState('7_day');
  const funnelContainerRef = useRef<any>(null);
  const currency = getCurrencyInfo(currentWorkspace?.currency);
  const dateFiltersRevenues = [
    { label: t('filters.last_days', { days: 7 }), value: '7_day' },
    { label: t('filters.last_weeks', { weeks: 4 }), value: '28_day' },
    { label: t('filters.last_months', { months: 12 }), value: '12_month' },
  ];

  const dateFiltersFunnel = [
    { label: t('filters.last_days', { days: 7 }), value: '7_day' },
    { label: t('filters.last_days', { days: 30 }), value: '30_day' },
    { label: t('filters.last_months', { months: 3 }), value: '3_month' },
    { label: t('filters.last_months', { months: 6 }), value: '6_month' },
    { label: t('filters.last_months', { months: 12 }), value: '12_month' },
  ];

  const { data: stats } = useQuery(
    ['statsKey', currentUser, currentWorkspace, rangeDate],
    // @ts-ignore
    () =>
      getOrdersStatsByWorkspace(
        currentUser.accessToken,
        currentWorkspace._id,
        rangeDate.split('_')[0],
        rangeDate.split('_')[1],
      ),
    {
      enabled: !!currentUser && !!currentWorkspace,
    },
  );

  const {
    data: funnelConversion,
    isLoading: isLoadingFunnelConversion,
    isFetching: isFetchingFunnelConversion,
  } = useQuery(
    ['funnelConversion', rangeDateFunnel, currentUser, currentWorkspace],
    () =>
      getFunnelConversion(
        currentUser.accessToken,
        currentWorkspace._id,
        rangeDateFunnel.split('_')[0],
        rangeDateFunnel.split('_')[1],
      ),
    { enabled: !!currentUser && !!currentWorkspace },
  );

  useEffect(() => {
    if (!funnelConversion) return;
    // Clear previous graph content
    if (funnelContainerRef.current) {
      funnelContainerRef.current.innerHTML = '';
    }
    const graph = new FunnelGraph({
      container: '.Funnel',
      gradientDirection: 'horizontal',
      data: {
        labels: [
          t('analytics.page_view', {
            count: funnelConversion.pageView?.count,
          }),
          t('analytics.add_to_cart', {
            count: funnelConversion.addToCart?.count,
          }),
          t('analytics.payment_success', {
            count: funnelConversion.paymentSuccess?.count,
          }),
        ],
        colors: ['#7e82e5', '#cbcfffcd'],
        values: [
          funnelConversion.pageView?.count,
          funnelConversion.addToCart?.count,
          funnelConversion.paymentSuccess?.count,
        ],
      },
      displayPercent: true,
      direction: 'horizontal',
      width: window.innerWidth > 768 ? 600 : window.innerWidth - 100,
      height: window.innerWidth > 768 ? 350 : window.innerWidth - 100 * 1.7,
    });

    graph.draw();
  }, [rangeDateFunnel, funnelConversion]);

  return (
    <div>
      <TabWrapper>
        <>
          <TabContentHeader
            title={t('menu.analytics')}
            description={t('menu.analytics_description')}
            rootPage="/analytics"
          />
          <TabContent>
            <>
              <div className="lg:mt-8 gap-6 grid grid-cols-1 lg:flex">
                <div className="w-full lg:w-1/3 grid grid-cols-2 lg:grid-cols-2 gap-1 lg:gap-4 px-4 lg:px-0 font-serif">
                  <div className="py-2 shadow-lg px-0 gap-0 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className="text-xl py-2 lg:text-3xl font-bold">
                      {!stats
                        ? null
                        : displayPrice(stats.totalRevenue, currency.symbol, {
                            thousandSeparator: ' ',
                          })}
                    </div>
                    <div className="stat-title text-xs lg:text-sm">
                      {t('dashboard.net_revenues')}
                    </div>
                  </div>
                  <div className="rounded-2xl py-2 shadow-lg gap-0 px-0 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className="text-xl py-2 lg:text-3xl font-bold">
                      {!stats
                        ? null
                        : stats.totalCountSales
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </div>
                    <div className="text-xs lg:text-sm">
                      {t('dashboard.total_sales')}
                    </div>
                  </div>
                  <div className="rounded-2xl py-2 shadow-lg gap-0 px-0 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className="text-xl py-2 lg:text-3xl font-bold">
                      {!stats
                        ? null
                        : displayPrice(stats.totalSales, currency.symbol, {
                            thousandSeparator: ' ',
                          })}
                    </div>
                    <div className="text-xs lg:text-sm">
                      {t('dashboard.total_revenues')}
                    </div>
                  </div>
                  <div className="rounded-2xl py-2 shadow-lg px-0 gap-0 flex flex-col rounded-2xl border-gray-100 lg:border-gray-100 border justify-center text-center items-center">
                    <div className="text-xl py-2 lg:text-3xl font-bold">
                      {!stats
                        ? null
                        : displayPrice(
                            stats.averageOrderAmount,
                            currency.symbol,
                            {
                              thousandSeparator: ' ',
                            },
                          )}
                    </div>
                    <div className="stat-title text-xs lg:text-sm">
                      {t('dashboard.average_cart')}
                    </div>
                  </div>
                </div>
                <div className="grid flex-col w-full lg:w-2/3 rounded-2xl py-2 px-6 shadow-lg">
                  <div className="mb-5 lg:mb-0 flex items-center justify-between">
                    <h5 className="lg:m-5 text-left">
                      {t('analytics.sales_revenue')}
                    </h5>
                    <Filters
                      filtersList={dateFiltersRevenues}
                      active={rangeDate}
                      setActive={setRangeDate}
                    />
                  </div>
                  {!stats?.orders?.length ? null : (
                    <div className="h-96 mt-2">
                      <GraphComponent
                        orders={stats?.orders}
                        unitType={rangeDate.split('_')[1]}
                        currency={currency}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-8 gap-6 grid grid-cols-1 lg:flex">
                <div className="rounded-2xl py-2 lg:px-6 shadow-lg w-full lg:w-2/3">
                  <div className="mb-5 lg:mb-0 flex items-center justify-between">
                    <h5 className="mx-2 lg:mx-0 my-5 text-left">
                      {t('analytics.funnel_conversion')}
                    </h5>
                    <div className="mx-2 lg:mx-0 my-5">
                      <Filters
                        filtersList={dateFiltersFunnel}
                        active={rangeDateFunnel}
                        setActive={setRangeDateFunnel}
                      />
                    </div>
                  </div>
                  {(isLoadingFunnelConversion ||
                    isFetchingFunnelConversion) && (
                    <div className="flex justify-center items-center height-[435px]">
                      <Loader />
                    </div>
                  )}
                  {!(
                    isEmpty(funnelConversion) ||
                    isLoadingFunnelConversion ||
                    funnelConversion?.pageView?.count === '0'
                  ) ? null : (
                    <div
                      className="flex-row my-5 lg:mt-10 px-4"
                      style={{
                        height:
                          window.innerWidth > 768
                            ? 350
                            : window.innerWidth - 100 * 1.7,
                      }}
                    >
                      <FontAwesomeIcon
                        className="opacity-20 mb-4"
                        color="#b6b9ce"
                        icon={faChartMixed}
                        size="7x"
                      />
                      <div className="text-sm font-semibold text-[#6B7194] opacity-20">
                        {t('analytics.empty_funnel_conversion')}
                      </div>
                    </div>
                  )}
                  {!isLoadingFunnelConversion && (
                    <div
                      className="lg:p-4"
                      style={{
                        display:
                          isEmpty(funnelConversion) ||
                          funnelConversion?.pageView?.count === '0'
                            ? 'none'
                            : 'block',
                        width:
                          window.innerWidth > 768
                            ? 700
                            : window.innerWidth - 50,
                      }}
                    >
                      {/* div that is used by funnel-graph-js */}
                      <div ref={funnelContainerRef} className="Funnel" />
                    </div>
                  )}
                </div>
                <div className="w-full lg:w-1/3 rounded-2xl py-2 lg:px-6 shadow-lg lg:flex-grow">
                  <BestSellers />
                </div>
              </div>
              <MyCustomers />
            </>
          </TabContent>
        </>
      </TabWrapper>
    </div>
  );
};

export default Analytics;
