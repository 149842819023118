import React from 'react';
import './back-button.styles.scss';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <a onClick={() => navigate(-1)} className="div-block-524 shadow-harsh">
      <img
        src="https://assets.website-files.com/5beab1239ac88487c3a6608f/60dd21a30677b085efdeec85_Chevron%20White.svg"
        loading="lazy"
        alt=""
      />
    </a>
  );
};

export default BackButton;
