import React, { useState } from 'react';
import TabWrapper from '../../components/tab-wrapper/tab-wrapper.component';
import TabContentHeader from '../../components/content-header/tab-content-header.component';
import TabContent from '../../components/tab-content/tab-content.component';
import { useTranslation } from 'react-i18next';
import AirbnbLogo from '../../assets/images/airbnb-logo.svg';
import Button from '../../components/button/button.component';

import { useQuery } from 'react-query';
import { useAuth } from '../../contexts/authContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAirbnbByHospitableChannels } from '../../api/platforms';
import { AirbnbConnectedAccount } from '../../components/airbnb-connect/AirbnbConnect.component';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import useChannelManagersWithHandlers from '../../hooks/useChannelManagersWithHandlers';
import ChannelManagerList from '../../containers/channel-manager-list/channel-manager-list.container';

const CALRY_REDIRECT_URL: string =
  process.env.REACT_APP_COZYUP_URL + '/channel-managers';
const HOSPITBALE_REDIRECT_URI: string =
  process.env.HOSPITBALE_REDIRECT_URI || '';
const ChannelManagers = () => {
  const { t } = useTranslation();
  const { currentUser, currentAccount } = useAuth();
  useState<boolean>(false);

  const { data: airbnbChannels } = useQuery(
    ['airbnbChannels', currentAccount, currentUser],
    // @ts-ignore
    () =>
      getAirbnbByHospitableChannels(
        currentUser.accessToken,
        currentAccount._id,
      ),
    {
      enabled: !!currentUser && !!currentAccount,
    },
  );

  const { authRequestHospitableConnect, isLoadingRequestHospitableConnect } =
    useChannelManagersWithHandlers(CALRY_REDIRECT_URL, HOSPITBALE_REDIRECT_URI);

  return (
    <>
      <TabWrapper>
        <>
          <TabContentHeader
            title={t('menu.applications')}
            description={t('menu.channel_managers_description')}
            rootPage="/channel-managers"
          />

          <TabContent>
            <div>
              <div>
                <div className="mt-10 mb-6 border-b border-grey">
                  <h2 className="pb-2 text-left text-lg font-bold text-grey">
                    {t('platforms')}
                  </h2>
                </div>
                <div>
                  <div className="flex flex-wrap">
                    <div className="m-3 w-1/2 md:w-1/4">
                      <div className="border border-grey rounded-2xl p-3 flex flex-col">
                        <div className="flex-1 flex items-center justify-center">
                          <img
                            className="object-contain"
                            src={AirbnbLogo}
                            alt="airbnb logo"
                          />
                        </div>
                        <div className="flex justify-end items-center">
                          <div className="flex items- mt-4">
                            <Button
                              disabled={isLoadingRequestHospitableConnect}
                              onClick={authRequestHospitableConnect}
                              className="btn-primary-outlined rounded-3xl px-2 py-1 flex gap-2"
                            >
                              {airbnbChannels && airbnbChannels.length > 0
                                ? t('add_account')
                                : t('connect_account')}
                              {!isLoadingRequestHospitableConnect ? null : (
                                <FontAwesomeIcon
                                  style={{ marginLeft: 15 }}
                                  icon={faSpinnerThird}
                                  spin
                                />
                              )}
                            </Button>
                          </div>
                        </div>
                        {airbnbChannels && airbnbChannels.length > 0 ? (
                          <AirbnbConnectedAccount
                            airbnbChannels={airbnbChannels}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 mb-6 border-b border-grey">
                <h2 className="pb-2 text-left text-lg font-bold text-grey">
                  {t('menu.channel_managers')}
                </h2>
              </div>
              <div className="flex flex-wrap">
                <ChannelManagerList
                  calryRedirectUrl={CALRY_REDIRECT_URL}
                  hospitableRedirectUrl={HOSPITBALE_REDIRECT_URI}
                />
              </div>
            </div>
          </TabContent>
        </>
      </TabWrapper>
    </>
  );
};

export default ChannelManagers;
