import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: any;
}

const RequireAuth = ({ children }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      navigate('/sign-in');
    }
  }, []);

  return <>{!currentUser ? null : children}</>;
};

export default RequireAuth;
