const package_json = require('../../package.json');

const useTrackMixpanelEvent = (mixpanel: any) => {
  const trackMixpanelEvent = (eventName: string, params: any) => {
    const cozyup_client = 'cozyup_web';
    const cozyup_client_version = `v${package_json.version}`;

    mixpanel.track(eventName, {
      cozyup_client,
      cozyup_client_version,
      ...params,
    });
  };

  return {
    trackMixpanelEvent,
  };
};

export default useTrackMixpanelEvent;
