import { AccountInvitation } from '../types/account-invitation.model';
import { Account } from '../types/account.model';
import { api } from '../utils/request';

export const createAccount = async (authToken: string, data: Account) => {
  try {
    const res = await api.post(
      `/accounts`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getReferals = async (userToken: string, accountId: string) => {
  try {
    const res = await api.get(`/accounts/${accountId}/referals/list`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const getFeeInvoices = async (userToken: string, accountId: string) => {
  try {
    const res = await api.get(`/accounts/${accountId}/invoices`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const getFeeInvoiceDetail = async (
  userToken: string,
  accountId: string,
  monthYear: string,
) => {
  try {
    const res = await api.get(`/accounts/${accountId}/invoice`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
      params: {
        monthYear,
      },
    });

    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const sendReferralLink = async (
  userToken: string,
  accountId: string,
  email: string,
) => {
  try {
    const res = await api.post(
      `/accounts/${accountId}/referals/send-referral-link`,
      { email },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const getAccountsByUser = async (userToken: string) => {
  try {
    const res = await api.get(`/users/accounts/list`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteAccountGuest = async (
  userToken: string,
  accountId: string,
  userId: string,
) => {
  try {
    const res = await api.delete(`/accounts/${accountId}/guest/${userId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getAccount = async (userToken: string, accountId: string) => {
  try {
    const res = await api.get(`/accounts/${accountId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${userToken}`,
      },
    });

    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateAccount = async (
  authToken: string,
  accountId: string,
  data: Account,
) => {
  try {
    const res = await api.patch(
      `/accounts/${accountId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const deleteAccount = async (authToken: string, accountId: string) => {
  try {
    const res = await api.delete(`/accounts/${accountId}`, {
      headers: {
        ...api.defaults.headers.common,
        Authorization: `Bearer ${authToken}`,
      },
    });
    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const saveUserAccountRegistrationDetails = async (
  authToken: string,
  accountId: string,
  userId: string,
  data: Account,
) => {
  try {
    const res = await api.post(
      `/accounts/${accountId}/registration-details/user/${userId}`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const createAccountInvitations = async (
  authToken: string,
  accountId: string,
  data: any,
) => {
  try {
    const res = await api.post(
      `/accounts/${accountId}/account-invitations`,
      data,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    throw err.response.data;
  }
};

export const getAccountInvitations = async (
  authToken: string,
  accountId: string,
) => {
  try {
    const res = await api.get(
      `/accounts/${accountId}/account-invitations?status=pending`,
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const getAccountInvitationById = async (accountInvitationId: string) => {
  try {
    const res = await api.get(
      `/public/account-invitations/${accountInvitationId}`,
      {
        headers: {
          ...api.defaults.headers.common,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

export const updateAccountInvitationStatus = async (
  authToken: string,
  accountId: string,
  accountInvitationId: string,
  data: AccountInvitation,
) => {
  try {
    const res = await api.patch(
      `/accounts/${accountId}/account-invitations/${accountInvitationId}/update-status`,
      { ...data },
      {
        headers: {
          ...api.defaults.headers.common,
          Authorization: `Bearer ${authToken}`,
        },
      },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};
