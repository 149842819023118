import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTrackMixpanelEvent from '../../hooks/useTrackMixPanelEvent';
import mixpanel from 'mixpanel-browser';
import { Controller, useForm } from 'react-hook-form';
import Validation from '../../utils/validation';
import Input from '../../components/input/input.component';
import _ from 'lodash';
import InputPhone from '../../components/input-phone/input-phone.component';
import SubmitButton from '../../components/submit-button/submit-button.component';
import Select from '../../components/select/select.component';
import {
  COMPANY_NB_OF_EMPLOYEES,
  COMPANY_NB_OF_PROPERTIES,
  PROPERTY_MANAGEMENT_SYSTEM,
  PROPERTY_TYPES,
  USER_COMPANY_ROLES,
} from './registration-details.constante';
import { Emoji } from 'emoji-mart';
import MultiSelect from '../../components/multiselect/multiselect.component';
import Language from '../../components/language/language.component';
import { useMutation } from 'react-query';
import { useAuth } from '../../contexts/authContext';
import { saveUserAccountRegistrationDetails } from '../../api/accounts';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';

const RegistrationDetails = () => {
  const { trackMixpanelEvent } = useTrackMixpanelEvent(mixpanel);
  const { apiUser, currentUser, currentAccount } = useAuth();
  const [businessType, setBusinessType] = useState('self_employed');
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    // getValues,
    // setValue,
  } = useForm();

  useEffect(() => {
    trackMixpanelEvent('Register - Account details form view ', {});
  }, []);

  const { mutate: saveRegistrationDetails, isLoading } = useMutation(
    (data: any) =>
      saveUserAccountRegistrationDetails(
        currentUser.accessToken,
        currentAccount?._id,
        apiUser?.data?._id,
        data,
      ),
    {
      onSuccess: (data: any) => {
        trackMixpanelEvent('Register - Account details form submitted', {
          ...data?.account,
        });
        const redirectPath = data?.account?.subscribedToCoachingOffer
          ? '/sign-up/book-a-call'
          : '/onboarding?step=0';
        navigate(redirectPath);
      },
    },
  );

  const submit = (data: any) => {
    data.isSelfEmployed = businessType === 'self_employed';
    data.coachingOffer = false;
    saveRegistrationDetails(data);
  };

  return (
    <div className="h-full md:min-h-screen overflow-scroll md:overflow-hidden relative flex bg-blue-50 lg:bg-white pt-4 lg:pt-0 md:items-center overflow-scroll">
      <div className="flex items-center justify-center w-full h-full">
        <div className="hidden lg:flex h-full w-4/5">
          <div className="bg-[#270074] h-[calc(100vh-96px)] my-12 w-full rounded-r-3xl flex flex-col items-center justify-center">
            {/*<div className="w-layout-grid grid-account-features">*/}
            <h1 className="w-full font-satoshiBold text-[52px] text-white text-center mb-6">
              CozyUp
            </h1>
            <p className="mx-8 mb-4 text-[18px] text-white leading-9">
              {t('register_form.account_creation_description')}
            </p>
            <Emoji emoji="rocket" size={52} />
          </div>
        </div>
        <div className="flex w-full justify-center items-start">
          <div className="flex flex-col w-full rounded-2xl">
            <div className="flex flex-wrap mb-4 mt-4 mx-8 lg:mx-20 justify-center md:justify-between items-center">
              <p className="font-semibold text-3xl text-black">
                {t('register_form.account_creation')}
              </p>
              <Language />
            </div>
            <div className="w-full">
              <form
                onSubmit={handleSubmit(submit)}
                className="mt-4 mx-8 lg:mx-20 flex flex-col items-center"
              >
                <div className="flex flex-row flex-wrap md:flex-nowrap w-full md:gap-4 justify-between">
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        key="firstname"
                        label={t('register_form.firstname')}
                        size="small"
                        onChange={onChange}
                        value={value}
                        errors={[_.get(errors, 'firstname.message')]}
                      />
                    )}
                    name={'firstname'}
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Input
                        key="lastname"
                        label={t('register_form.lastname')}
                        size="small"
                        onChange={onChange}
                        value={value}
                        errors={[_.get(errors, 'lastname.message')]}
                      />
                    )}
                    name={'lastname'}
                  />
                </div>

                <div className="flex justify-start items-center mb-2">
                  <div className="text-left text-[#6B7194] mb-2">
                    {t('register_form.you_are')}
                  </div>
                </div>
                <div className="flex gap-4 mb-6 w-full">
                  <div
                    className={`flex-1 flex justify-center items-center p-2 border border-primary rounded-3xl cursor-pointer ${
                      businessType === 'self_employed'
                        ? 'bg-primary text-white'
                        : 'bg-white text-primary'
                    }`}
                    onClick={() => setBusinessType('self_employed')}
                  >
                    <input
                      type="radio"
                      name="businessType"
                      className="hidden"
                      value="self_employed"
                      checked={businessType === 'self_employed'}
                      onChange={() => setBusinessType('self_employed')}
                    />
                    <label
                      htmlFor="self-employed"
                      className="cursor-pointer select-none"
                    >
                      {t('register_form.self_employed')}
                    </label>
                  </div>
                  <div
                    className={`flex-1 flex justify-center items-center border border-primary p-2 cursor-pointer rounded-3xl ${
                      businessType === 'enterprise'
                        ? 'bg-primary text-white'
                        : 'bg-white text-primary'
                    }`}
                    onClick={() => setBusinessType('enterprise')}
                  >
                    <input
                      type="radio"
                      name="businessType"
                      className="hidden"
                      value="enterprise"
                      checked={businessType === 'enterprise'}
                      onChange={() => setBusinessType('enterprise')}
                    />

                    <label
                      htmlFor="enterprise"
                      className="cursor-pointer select-none"
                    >
                      {t('register_form.enterprise')}
                    </label>
                  </div>
                </div>
                <Transition
                  show={businessType === 'enterprise'}
                  className="w-full"
                  enter="transition ease-out duration-300"
                  enterFrom="opacity-0 -translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-300"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-4"
                >
                  <div className="flex flex-row w-full flex-wrap md:flex-nowrap md:gap-4 justify-evenly">
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      render={({ field: { onChange, value } }: any) => (
                        <Input
                          key="companyName"
                          label={t('register_form.company_name')}
                          size="small"
                          onChange={onChange}
                          value={value}
                          errors={[_.get(errors, 'companyName.message')]}
                        />
                      )}
                      name={'companyName'}
                    />
                    <Controller
                      control={control}
                      rules={Validation.anyRequired}
                      render={({ field: { onChange, value } }: any) => (
                        <Select
                          key="role"
                          label={t('register_form.role')}
                          onChange={onChange}
                          value={value}
                          options={USER_COMPANY_ROLES}
                          errors={[_.get(errors, 'role.message')]}
                        />
                      )}
                      name={'role'}
                    />
                  </div>
                </Transition>
                <div className="flex flex-row w-full flex-wrap md:flex-nowrap md:gap-4 justify-evenly">
                  <Controller
                    control={control}
                    rules={{
                      ...Validation.anyRequired,
                      ...Validation.phone,
                    }}
                    render={({ field: { onChange, value } }: any) => (
                      <InputPhone
                        key="phoneNumber"
                        label={t('register_form.phone_number')}
                        size="small"
                        onChange={onChange}
                        value={value}
                        errors={[_.get(errors, 'phoneNumber.message')]}
                      />
                    )}
                    name={'phoneNumber'}
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        key="channel_manager"
                        label={t('register_form.channel_manager')}
                        onChange={onChange}
                        value={value}
                        options={PROPERTY_MANAGEMENT_SYSTEM}
                        errors={[_.get(errors, 'channelManager.message')]}
                      />
                    )}
                    name={'channelManager'}
                  />
                </div>
                <div className="flex flex-row w-full flex-wrap md:flex-nowrap md:gap-4">
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        key="numberOfProperties"
                        label={t('register_form.nb_properties')}
                        onChange={onChange}
                        value={value}
                        options={COMPANY_NB_OF_PROPERTIES}
                        errors={[_.get(errors, 'numberOfProperties.message')]}
                      />
                    )}
                    name={'numberOfProperties'}
                  />
                  <Controller
                    control={control}
                    rules={Validation.anyRequired}
                    render={({ field: { onChange, value } }: any) => (
                      <Select
                        key="numberOfEmployees"
                        label={t('register_form.nb_employee')}
                        onChange={onChange}
                        value={value}
                        options={COMPANY_NB_OF_EMPLOYEES}
                        errors={[_.get(errors, 'numberOfEmployees.message')]}
                      />
                    )}
                    name={'numberOfEmployees'}
                  />
                </div>
                <div className="flex flex-row w-full flex-wrap md:flex-nowrap md:gap-4 justify-evenly">
                  <Controller
                    control={control}
                    rules={{
                      ...Validation.anyRequired,
                      ...Validation.phone,
                    }}
                    render={({ field: { onChange, value } }: any) => (
                      <MultiSelect
                        key="propertyType"
                        containerStyle="w-full h-auto flex flex-wrap flex-1 mb-6"
                        label={t('register_form.property_type')}
                        options={PROPERTY_TYPES}
                        onChange={onChange}
                        value={value}
                        primaryColor=""
                        errors={[_.get(errors, 'propertyType.message')]}
                      />
                    )}
                    name={'propertyType'}
                  />
                </div>
                <p>{_.get(errors, 'coachingOffer.message')}</p>
                <div className="w-full mt-8">
                  <SubmitButton
                    title={t('confirm')}
                    isLoading={isLoading}
                    onClick={handleSubmit(submit)}
                    customClass={'submit-button-input'}
                  />
                </div>
              </form>
              <div className="relative pt-6 mb-4 right-0 bottom-0 left-0 text-grey text-sm text-center">
                {t('copyright', { year: currentYear })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationDetails;
